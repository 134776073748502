import { useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useOutPatientMedicalHistory } from './useOutPatientMedicalHistory';
import { dateConvert } from '../../Utils/ConvertDate';



export const useSeenPatients = (actions) =>{
    const [searchValue, setSearchValue] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [data, setData] = useState({patients:[], total_count:0, status:'idle'})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
  
    const {status} = data;
    const {start_date, end_date} = state;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;


    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isError = isSubmitted === 'reject';

    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';


    useEffect(() => {
        if (start_date !== '' && end_date !== '') {
            const formData = new FormData();
            formData.append('user_roles_id', user_roles_id);
            formData.append('search', searchValue);
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            setData({...data, status:'pending'})
            axios.post(`${config.smsUrl}/cdoctor/patients_worked_on_by_doctor/${activePage}`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const patients = !dt.patient_seen_list ? [] : dt.patient_seen_list;
                const count = !dt.total_count ? 0 : dt.total_count;
                setData({...data, status:'success',patients,total_count:count })
            }).catch(err => {
                logoutErrorMessage(err,null,actions)
                setData({...data, status:'error'})
            })
        }
    }, [activePage, searchValue]);



    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };




    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };

    const handleChange = (event) =>{
        const {value, name} = event.target
        setState({...state, [name]:value})
    }
    


    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('user_roles_id', user_roles_id);
        formData.append('search', searchValue);
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        setSubmitted(true)
        if (start_date !== '' && end_date !== '') {
            setIsSubmitted('pending')
            setData({...data, status:'pending'})
            axios.post(`${config.smsUrl}/cdoctor/patients_worked_on_by_doctor/${activePage}`, formData).then(res => {
                setIsSubmitted('resolved')
                const data = res.data;
                const dt = !data ? {} : data;
                const patients = !dt.patient_seen_list ? [] : dt.patient_seen_list;
                const count = !dt.total_count ? 0 : dt.total_count;
                setData({...data, status:'success',patients,total_count:count })
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsSubmitted('rejected')
                setData({...data, status:'error'})
            });
        }
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };



    return {searchValue, activePage, handleSearch,  handleChangeNextPage,handleSubmit,
         closeSnackbar,  isLoading, isSuccess, isRejected,submitted,state, data,handleChange,
        isPending, isResolved, isError}
}