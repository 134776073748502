import React from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import {TableRow, TableCell} from '@material-ui/core';
import {useExpenses} from './hooks/useExpenses';
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import SmsSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../Utils/Loader";
import {formatDate} from "../../Utils/ConvertDate";
import {DateTimeFilter} from "../../Utils/ReusableComponents/DateTimeFilter";
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {NewExpense} from "./NewExpense";
import {NewExpenseItem} from "./NewExpenseItem";
import {formatAmount} from "../../Utils/formatNumbers";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";

const headData = [{id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'expense', numeric: false, disablePadding: false, label: 'Expense'},
    {id: 'receiptNo', numeric: false, disablePadding: false, label: 'Receipt No.'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];

const components = [
    {label: 'Expenses', path: '/accounting/expenses'},
    {label: 'Expense Items', path: '/accounting/expense-items'},
]

const Expenses = ({actions, snackbar}) => {
    const {expenses, state, submitted, isPending,  isLoading, isSuccess,
        isError,handleSubmit, handleChange, closeSnackbar, expenseProps} = useExpenses(actions);
    const {start_date, end_date} = state;
    const {openExpense, handleCloseExpense, handleOpenExpense, itemProps} = expenseProps;

    const {openDialog, handleOpenDialog, handleCloseDialog} = itemProps;

    const {openBar, type, message} = snackbar

    const {isBlocking, isBlockDialog, setIsBlockDialog, handleResetForm} = expenseProps


    const emptyRows = 10 - Math.min(10, expenses.length);

    const reusableTable = (
        <MuiPaper>
              <StickyHeadTable {...{width:'100%'
            ,data:expenses, columns:headData.filter(item=>Boolean(item))}}>
            {isLoading ? <TableRow><TableCell align='center' colSpan={headData.length}><LoadingGif/></TableCell></TableRow>:null}
            {isSuccess ? expenses.length > 0 ?
                <>
                    {expenses.map((row) => {
                        return (
                            <TableRow  tabIndex={-1} key={row.expense_id}>
                                <CustomTableCell>{formatDate(row.date)}</CustomTableCell>
                                <CustomTableCell>{row.expense_type_name}</CustomTableCell>
                                <CustomTableCell>{row.receipt_no}</CustomTableCell>
                                <CustomTableCell>{formatAmount(row.amount)}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {emptyRows > 0 && (
                        <TableRow style={{ height: 43 * emptyRows }}>
                            <TableCell colSpan={headData.length} />
                        </TableRow>
                    )}
                </>:<TableRow><TableCell align='center'  colSpan={headData.length}>No records found</TableCell></TableRow>:null}
            {isError ? <TableRow><TableCell align='center'  colSpan={headData.length}>The server did not return a valid response</TableCell></TableRow>:null}
        </StickyHeadTable>
        </MuiPaper>
    )
    return (
        <div className='journals'>
            <SubHeader title="Accounting" subTitle="Expenses">
                <FontAwesomeIcon icon={faMoneyBill}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <RightAlignedContainer>
                <div className='mt-3 mx-3'>
                    <button className='btn btn-sm sms-info-btn' onClick={handleOpenExpense}>Record Expense</button>
                </div>
            </RightAlignedContainer>
            <CustomDialog open={openExpense} handleClose={handleCloseExpense} title='Record Expense' maxWidth='lg'>
                <RightAlignedContainer>
                    <div className='mb-2'>
                        <button className='btn btn-sm sms-info-btn' onClick={()=>handleOpenDialog('')}>Quick Add Expense Item</button>
                    </div>
                </RightAlignedContainer>
                <NewExpense {...expenseProps}/>
            </CustomDialog>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title='New Expense Item' maxWidth='sm'>
                <NewExpenseItem {...itemProps}/>
            </CustomDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <SmsSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
            <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
            {reusableTable}
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);