/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import * as colors from '../../../styles/colors';
import Card from '@material-ui/core/Card'
import { formatDate } from '../../Utils/ConvertDate';
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {FooterCell, HeadCell, InpatientBillRow, TotalCell} from "./InpatientBillRow";
import TableBody from "@material-ui/core/TableBody";
import { ItemsTable } from '../../Utils/Templates/PrintTemplate';
import {thUppercase} from "../../../styles/tableStyles";
import { coloredInvoice } from '../../../styles/tableStyles';
import {useInPatientBillingReceipt} from "./hooks/useInPatientBillingReceipt";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";


const textWeight = css`
    font-weight:600;
`


const titleText = css`
    margin: 0;
    color: ${colors.darkBlue001};
    font-size: 15px;
    
`


function InpatientBillInvoice(props) {
    const { state,  
        patient, items, exchangeRate, currencies,
        currency, handleChangeExchangeRate, handleChangeCurrency} = useInPatientBillingReceipt(props, true);

    const isBaseCurrency = currency === 'USD'
    const currency_symbol = !isBaseCurrency && exchangeRate ? currency : 'USD'

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key: 'rate',value:`Rate`},
        {key: 'amount',value:`Amount`}]

    const {total_bill, currency_total_bill} = state;
    const {componentRef, handlePrint, fontSize}  = usePrint('Current visit Information')

    const patientDetails = (
        <tbody>
            <tr>
                <td css={[textWeight, thUppercase]} ><p css={[titleText]}>Invoice To:</p></td>
                <td css={[textWeight, thUppercase]}><p css={[titleText]}>Invoice Details:</p></td>
            </tr>
            <tr>
                <td ><span>Name: </span> <span css={[textWeight]}>{patient.billed_to ? patient.billed_to : ''}</span></td>
                <td ><span>Date: </span> <span css={[textWeight]}>{patient.date_created ?  formatDate(patient.date_created ):''}</span></td>
            </tr>
            <tr>
                <td ><span>Card No.:</span> <span css={[textWeight]}>{patient.card_number ? patient.card_number : ''}</span></td>
                <td ><span>Patient Name: </span> <span css={[textWeight]}>{`${!patient.patient_firstname ? "" : patient.patient_firstname} 
                                    ${!patient.patient_lastname ? "" : patient.patient_lastname}`}
                </span> </td>
            </tr>
            <tr>
                <td><span>Account Holder: </span> <span css={[textWeight]}>{patient.account_name}</span></td>
                <td><span>Contact: </span> <span css={[textWeight]}>{patient.phone_no}</span></td>
            </tr>
        </tbody>
    )

    const itemDetails = (
        <TableContainer >
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(<HeadCell style={{width: index === 0 ? '500px':'300px'}} key={item.key}>{item.value}</HeadCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <InpatientBillRow key={row.id} row={row} headData={headData}/>
                    ))}
                    <TableRow>

                        <FooterCell></FooterCell>
                        <FooterCell></FooterCell>
                        <FooterCell >Total Bill</FooterCell>
                        <FooterCell>{total_bill}</FooterCell>
                    </TableRow>

                    {/*<TableRow>

                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell >Total Bill Due({currency_symbol})</TotalCell>
                        <TotalCell>{isBaseCurrency ?  due_amount: currency_due_amount}</TotalCell>
                    </TableRow>*/}
                </TableBody>
            </Table>
        </TableContainer>
    )

      return (
          <div>
        <PageTitle title="Billing Invoice"/>
        <SubHeader title="Bill Details" subTitle="Bill Details">
            <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        <MiniCenteredDiv>
             <RightAlignedContainer>
              <Button variant='orange' onClick={handlePrint}>Print</Button>
            </RightAlignedContainer>
            <Card className='mt-2'>
                {/*<div style={{padding:'8px 10px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>*/}
                {/*    <RightAlignedContainer>*/}
                {/*        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />*/}
                {/*        { currency !== 'USD' ? <FxRate currency={currency !== 'USD' && currency}  value={exchangeRate}  handleChangeRate={handleChangeExchangeRate} />: null}*/}
                {/*    </RightAlignedContainer>*/}
                {/*</div>*/}
                <div ref={componentRef} style={{padding:10}}>
                    <style>
                        {fontSize()}
                    </style>
                    <PrintTemplate title='INVOICE' {...{patientDetails,itemDetails}}/>
                </div>
            </Card>
        </MiniCenteredDiv>
        </div>
      )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}


export default connect(mapStateToProps, mapDispatchToProps)(InpatientBillInvoice);