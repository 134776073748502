import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useStockList} from "./hooks/useStockList";
import { Link } from "react-router-dom";
import { FullScreenDialog } from "../../Utils/Dialogs/FullScreenDialog";
import ReceiveStock from "../../Pharmacy/ReceiveStock";
import { StockAdjustment } from "../stock/StockAdjustment";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import { Container } from "../../DoctorsModule/doctorStyles";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {useExcelReports} from "../../Utils/ReusableComponents/useExcelExport";
import {StockAdjustmentUpload} from "../stock/StockAdjustmentUpload";
 
const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'generic_name', numeric: false, disablePadding: false, label: 'Generic Name'},
    {id: 'strength', numeric: false, disablePadding: false, label: 'Strength'},
    {id: 'unit', numeric: false, disablePadding: false, label: 'Unit'},
    {id: 'sellPrice', numeric: false, disablePadding: false, label: 'Sell Price'},
    {id: 'purchasePrice', numeric: false, disablePadding: false, label: 'Last Purchase Price'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];

const adjustmentHeadData = [{name:'Product', isRequired:true},{name:'Stock', isRequired:false},
{name:'Quantity', isRequired:true},{name:'Adjustment Type', isRequired:true},{name:'Total Stock', isRequired:false},{name:'Action', isRequired:false}]


export const StockList = (props) => {
    const {
        searchValue, order, orderBy, isLoading, stockList, activePage, total_count,
        closeSnackbar, handleSearch, handleChangePage, handleRequestSort,
        handleOpenDelete, handleCloseDelete, openDelete, handleDelete, item, isPending,
        openDialog, handleCloseDialog, stockProps, handleOpenDialog,  adjustmentProps, setupRequired, changeActiveStatus, activeStatus
    } = useStockList(props)
    const { isBlocking, setIsBlockDialog,  handleResetForm,isBlockDialog } = stockProps

    const {actions} = props

    const {status} = item;

    const stocks = !stockList ? [] : stockList;
    const {openBar, type, message} = props.snackbars;

    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;

    const adjust_stock = permission.adjust_stock ?  permission.adjust_stock : {}




    const excelObj = {file_name:'Stock List', url:'cpharmacy/store_stock_excel', params:{ category_id:+activeStatus}}

    const {exportExcel} = useExcelReports(actions, excelObj)


    const {openDialog:openAdjustment, handleOpenDialog:handleOpenAdjustment,
        handleCloseDialog:closeAdjustment, openStockDialog, setOpenStockDialog,
        setOpenDialog, isCsv, setIsCsv, location} = adjustmentProps

    const uploadStockAdjustment = (
        <StockAdjustmentUpload {...{openStockDialog, actions,setOpenStockDialog,setOpenDialog,isSubmitted:isCsv,
            setIsSubmitted:setIsCsv, location}}/>
    )
 
    return (
        <div data-testid="stock-list" className='journals'>
            <PageTitle title="Stock"/>
            <SubHeader title="Inventory" subTitle="Stock">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <FullScreenDialog open={openDialog} handleClose={handleCloseDialog} title="Receive Stock" maxWidth="lg">
                        <ReceiveStock {...stockProps}/>
            </FullScreenDialog>

            <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                text="product" title={`${status === 1 ? 'Deactivate':'Activate'} Product`}>
                    <button className={`btn btn-sm ${status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`} disabled={isPending}
                    onClick={handleDelete}>{status === 1 ? 'Deactivate':'Activate'}</button>
                    <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>

            <FullScreenDialog open={openAdjustment} handleClose={closeAdjustment} title='Adjust Stock'>
                <StockAdjustment {...{...adjustmentProps, adjustmentUpload:uploadStockAdjustment,
                headData:adjustmentHeadData, fileName:'Stock.csv', user:'Added By'}}/>
            </FullScreenDialog>
            <Container>
                <div className='general-ledger-header'>
                    <div className="row">
                        <div className="col-lg-6"/>
                        <div className="col-lg-6">
                        <RightAlignedContainer>
                            {setupRequired ?
                                <Link to='/add_initial_stock'>
                                    <button type="button"  className="btn sms-btn btn-sm mr-4">Add Initial Stock</button>
                                </Link>
                                :null}
                            <button type="button" id="receive-stock-btn" onClick={handleOpenDialog} className="btn sms-info-btn btn-sm mr-4">Receive Stock</button>

                            {(adjust_stock.create || adjust_stock.read || adjust_stock.delete || adjust_stock.update) ?
                                <button type="button" onClick={()=>handleOpenAdjustment('store')} className="btn sms-gray-btn btn-sm">Adjust Stock</button> : null}
                            {/* <div className="col-lg-4">
                                <DataExportButton data={allRecords} title='Stock List' csvRef={csvRef}
                                            exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                                </div> */}
                        </RightAlignedContainer>
                        </div>
                    </div>
                </div>

                <div className='general-ledger-header inpatient-billing-header mb-3'>
                    <a className={`billing-span btn btn-sm ${activeStatus === 1 ? 'active-billing-span':''}`} 
                    onClick={()=>changeActiveStatus(1)}>Medicine</a>
                    <a className={`billing-span btn btn-sm ${activeStatus === 2 ? 'active-billing-span':''}`} 
                    onClick={()=>changeActiveStatus(2)}>Medical Equipment</a>
                    <a className={`billing-span btn btn-sm ${activeStatus === 3 ? 'active-billing-span':''}`} 
                    onClick={()=>changeActiveStatus(3)}>Sundries</a>
                    <a className={`billing-span btn btn-sm ${activeStatus === 4 ? 'active-billing-span':''}`} 
                    onClick={()=>changeActiveStatus(4)}>Lab Reagents</a>
                </div>
                
                <div className="mui-tables">
                    <RightAlignedContainer>
                        <button className='btn btn-sm sms-info-btn mb-2' onClick={exportExcel}>Export Excel</button>
                    </RightAlignedContainer>
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                    <CustomTable title="Stock List" term={searchValue} records={10} activePage={activePage}
                                headData={headData} handler={handleSearch} total_count={total_count}
                                handleRequestSort={handleRequestSort} data={stocks} colSpan={5} order={order}
                                orderBy={orderBy} handleChangeNextPage={handleChangePage} customPage>
                        {isLoading ? <TableBody><TableRow>
                            <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                        </TableRow></TableBody> : stocks.length > 0 ?
                            <TableBody>
                                {stableSort(stocks, getComparator(order, orderBy))
                                    .slice(0, 10)
                                    .map((item, index) => {
                                        const count = ((activePage - 1) * 10) + index + 1;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'>
                                                <TableCell>{count}</TableCell>
                                                <TableCell><Link to={{pathname:`/reports/productlog/stores/${item.product_id}`}}>{item.product_name}{item.country_of_origin? ` - ${item.country_of_origin}`: null}</Link></TableCell>
                                                <TableCell>{item.generic_name}</TableCell>
                                                <TableCell>{item.strenght}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell>{item.price}</TableCell>
                                            
                                                <TableCell>{item.rate}</TableCell>
                                                <TableCell data-testid="store_stock">{item.stock}</TableCell>
                                                <TableCell>
                                                    <button onClick={() => handleOpenDelete(item)}
                                                        className={`btn btn-sm ${item.status === 1 ? 'sms-gray-btn' : 'sms-info-btn'}`}>
                                                        {item.status === 1 ? 'Deactivate':item.status === 0 ?  'Activate':''}
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                <TableRow>
                                    <TableCell colSpan="8" align="right"><strong>Total Count: {total_count}</strong></TableCell>
                                </TableRow>
                            </TableBody>
                            : <TableBody><TableRow>
                                <TableCell colSpan="8" align="center">No records found</TableCell>
                            </TableRow></TableBody>}
                    </CustomTable>
                </div>
                </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(StockList);
