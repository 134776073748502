import React from "react";
import {useTheme} from "@material-ui/styles";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage} from "../Utils/styledComponents";
import ReusablePrescriptionDetails from "./ReusablePrescription";


const returnHeadData = [{name:'Drug Name'},{name:'Quantity Dispensed'},
{name:'Quantity Used'},{name:'Balance'}, 
{name:'Choosen Batch ID(s)', sub_headings: ["Batch ID", "Dispensed Quantity", "Quantity to Return"]},
{name:'Quantity Returned'}]
const ReturnPrescription = ({ 
    state,submitted,
    medicineReturn,  isPendingReturn,
    submittedReturn, handleSubmitReturn, handleChangeBatchReturnQuantity

    }) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    return (
        <form onSubmit={handleSubmitReturn} id="submit-prescription" className="mt-2" autoComplete="off">
            <ReusablePrescriptionDetails {...{headData:returnHeadData,text:'Receive Balances',state, isFillPrescription:true}}>
                <tbody>
                    {medicineReturn.length > 0 ? medicineReturn.map((row, index) => {
                        return (
                            <tr key={row.product_id}>
                                <td>{row.product_name}</td>
                                <td>{row.quantity_given}</td>
                                <td>{row.quantity_administered}</td>
                                <td>{row.quantity_given - row.quantity_administered}</td>
                                <td >
                                    <table>
                                        <tbody>
                                            {row.batches?.map((btch, btch_idx) => (
                                                <tr key={btch_idx}>
                                                    <td style={{width:'30%'}}>
                                                        {btch.batch_id}
                                                    </td>                                         
                                                    <td style={{width:'30%'}}>
                                                        {btch.quantity}
                                                    </td>   
                                                    <td style={{width:'40%'}}>
                                                        <TextField submitted={submitted} type="number" value={btch.quantity_returned} style={{fontSize:'11px'}}
                                                        onChange={(e) => handleChangeBatchReturnQuantity(e, btch, btch_idx, index)}/>
                                                    </td>                                                                                 
                                                </tr>
                                            ))}  
                                        </tbody>
                                    </table>
                                </td>
                                <td>
                                    {row.quantity_returned}
                                    {/*<TextField submitted={submitted} type="number" value={row.quantity_returned}
                                        onChange={(e) => handleChangeReturnQuantity(e, row.product_id)}/> */}
                                    {submittedReturn && (row.quantity_returned < 0)  ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                    {submittedReturn && (
                                        row.quantity_returned > (row.quantity_given - row.quantity_administered)
                                    ) ?
                                    <ErrorMessage>Quantity Returned is greater than balance</ErrorMessage> :
                                    null}
                                </td>
                             
                            </tr>
                        )
                    }): 
                    <tr>
                        <td colSpan={returnHeadData.length} align="center"
                        className={palette.type === 'dark' && 'dark-theme-color'}>No drugs
                        given
                        </td>
                    </tr>}
                </tbody> 
            </ReusablePrescriptionDetails>
            <button type="submit" className="btn btn-sm sms-btn px-4" id="submit-btn"
                        disabled={isPendingReturn}>{isPendingReturn ? 'Saving...' : 'Save'}</button>

        </form>

     
    )
};

export  {ReturnPrescription};
