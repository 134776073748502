import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from '@material-ui/core';
import {useTheme} from "@material-ui/core/styles";
import React from 'react'
import ReactToPrint from 'react-to-print'
import { SubHeader } from '../../Containers/SubHeader'
import { HospitalAddress } from '../Utils/HospitalAddress'
import PageTitle from '../Utils/smsTitle'
import {splitNotes} from "../Radiology/RadiologyReport";
import Info from '@material-ui/icons/Info'
import useDischargedPatientDetails from './CustomHooks/useDischargedPatientDetails'
import {titleCase} from "../Users/addUser";
import {convertGmtTime, dateStringConvert, convertDateStrokeTime } from "../Utils/ConvertDate";
import { TableHeading } from "../DoctorsModule/ReferralNote/ReusableTreatmentTable";
import { BackButton } from '../Utils/Buttons/DataExportationButton';
import { borderlessTableFooter } from '../../styles/tableStyles';
import { GetTriageDetails, getMetricValues } from '../DoctorsModule/ReusableTreatmentHistory';

const DischargedPatientDetails = (props) => {
    const theme = useTheme();
    const {details:patient_details, componentRef} = useDischargedPatientDetails(props)
    
    const obj = patient_details ? patient_details : {}
    const details = !obj.patient_info ? {} : obj.patient_info;
    const diagnosis = !obj.diagnosis ? [] : obj.diagnosis;
    const procedure = !obj.procedure ? [] : obj.procedure;
    const lab = !obj.lab ? [] : obj.lab
    const scan = !obj.scan ? [] : obj.scan;
    const findings = !obj.findings ? [] : obj.findings;
    const prescription = !obj.prescription ? [] : obj.prescription;
    const bed_transfer = !obj.bed_transfer ? [] : obj.bed_transfer;
    const bed_assignment = !obj.bed_assignment ? [] : obj.bed_assignment;
    const discharge_info = !obj.discharge_info ? [] : obj.discharge_info;
    const triage_details = !obj.triage ? [] : obj.triage;
    
    const {metrics_titles, percentage_metrics} = GetTriageDetails(triage_details);

    return (
      <div>
          <PageTitle title="Patient Details"/>
          <SubHeader title="Patient details" subTitle="Patient admission details">
            <FontAwesomeIcon icon={Info}/>
          </SubHeader>
          <BackButton to='/dischargelist' text='Discharged Patients' data-testid="discharged-patient-details-back-button"/>
          <section className="general-lab-report">
              <div className="text-right">
                  <ReactToPrint content={() => componentRef.current} trigger={() => <button className="btn sms-amber-btn mb-2">Print</button>}/>
              </div>

              <Card className="p-2 lab-report" ref={componentRef} id="prescription-print">

                <table className='table table-sm table-borderless prescription-table'>

                  <thead>
                    <tr>
                      <td>
                        <HospitalAddress/>
                        <div className="page-header-space"></div>
                      </td>
                    </tr>
                  </thead>
                    
                  <table className="table table-bordered table-sm  bill-receipt-table">
                      {Object.keys(details).length > 0 ? 
                        <tr>
                            <td className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                <p>Patient Name<br/><strong>{`${!details.first_name ? "" : details.first_name} ${!details.last_name ? "" : details.last_name}`}</strong></p>
                            </td> 
                            <td className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                <p>Date of Birth<br/><strong>{details.dob}</strong></p>
                            </td>
                            <td className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                <p>Gender<br/><strong>{titleCase(details.gender)}</strong></p>
                            </td>
                            <td className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                <p>Patient Number<br/><strong>{details.patient_number}</strong></p>
                            </td>
                        </tr> 
                      : null }
                  </table>
                
                  <h4 align="center" style={{textDecoration: "underline double"}}>Discharge Details</h4>
                  <div className="mt-5 mb-5">
                      {discharge_info.length > 0 ? 
                        discharge_info.map((item, index) => {
                            return (
                              <div key={index}>
                                  <div style={{display: "flex", justifyContent: 'space-evenly',alignItems: 'center', paddingBottom: "20px"}}>
                                    <span className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                        <p>Discharge Date:  <strong>{dateStringConvert(item.discharge_date)}</strong></p>
                                    </span> 
                                    <span className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                        <p>Followup date: <strong>{dateStringConvert(item.followup_date)}</strong></p>
                                    </span>
                                  </div>
                                  <div> 
                                    <div className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                      {item.diagnosis ? 
                                       <>
                                       <TableHeading title='DIAGNOSIS'/>
                                       <table className="table table-bordered prescription-table table-sm mb-0">
                                       <p style={{paddingBottom: "5px", }}>{item.diagnosis}</p>
                                       </table>
                                       </>
                                      : null}
                                      {item.investigations? 
                                      <>
                                      <TableHeading title='INVESTIGATIONS'/>
                                      <table className="table table-bordered prescription-table table-sm mb-0">
                                      <p style={{paddingBottom: "5px", }}>{item.investigations}</p>
                                      </table>
                                      </>
                                      : null}
                                      {item.notes ? 
                                      <>
                                      <TableHeading title='NOTES'/>
                                      <table className="table table-bordered prescription-table table-sm mb-0">
                                      <p style={{paddingBottom: "5px", }}>{item.notes}  </p> 
                                      </table>
                                      </> : null}
                                      {item.recommendations ? 
                                      <>
                                      <TableHeading title='RECOMMENDATIONS'/>
                                      <table className="table table-bordered prescription-table table-sm mb-0">
                                      <p style={{paddingBottom: "5px", }}>{item.recommendations} </p>
                                      </table>
                                      </> : null}
                                      </div>
                                  </div>
                              </div>
                        )}) 
                      : ""}
                  </div>
                    
                  <h4 align="center" style={{textDecoration: "underline double"}}>Admission Details</h4>

                  {triage_details && triage_details.length > 0 ?
                    <div>
                        <TableHeading title='Triage' colSpan={2}/>
                        <table className="table table-bordered profile-table mt-1"> 
                            <thead>
                                <tr>
                                    <td rowSpan={2} style={{textAlign:'center'}}><strong>Date</strong></td>
                                    <td colSpan={metrics_titles.length} style={{textAlign:'center'}}><strong>Metrics</strong></td>
                                </tr>
                                <tr>
                                    {metrics_titles.map((it) => (
                                        <td key={it.metric_id} style={{textAlign:'center'}}>
                                        <p><strong>{`${it.metric_name} ${it.uom ? `(${it.uom})` : ''}`}</strong></p>
                                        </td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {triage_details.map((element, index) => (
                                    <tr key={index}>
                                        <td style={{width:'20%', textAlign:'center'}}>
                                        <p>
                                        {convertDateStrokeTime(element.metric_date)}
                                        </p>
                                        </td>
                                        {metrics_titles.map((it) => (
                                        getMetricValues(element.data, it.metric_name, percentage_metrics)
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table> 
                    </div>
                  : null}

                  {diagnosis.length > 0 ? 
                    <>
                      <TableHeading title='DIAGNOSIS'/>
                      {/*<table className="table table-bordered prescription-table table-sm mb-0 assignment-table">
                            *<tr> 
                              <td className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px",}}><strong>Diagnosis</strong></td>
                              <td className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px", fontSize: "400"}}> <strong>Notes</strong> </td>
                            </tr>*/}

                            <table className="table table-bordered profile-table mt-1">
                            {diagnosis.some(item => item.diagnosis_code !== null) ?
                              <tr>
                                  <td><p>Disease</p></td>
                                  <td><p>ICD code</p></td>
                              </tr> : null}
                              {diagnosis.filter(item=>!(item.diagnosis === null && item.diagnosis_code === null)).map(item => (
                                  <tr key={item.diagnosis_id}>
                                      <td><p>{item.diagnosis}</p></td>
                                      <td><p>{item.diagnosis_code}</p></td>
                                  </tr>
                              ))}
                              {diagnosis.some(item => item.notes !== null) ?
                                <tr>
                                  <td colSpan={2}><p><strong>Notes</strong></p></td>
                              </tr> : null}
                              {diagnosis.filter(item=>!(item.diagnosis !== null)).map(item => (
                                  <tr key={item.diagnosis_id}>
                                      <td colSpan={2}>{splitNotes(item.notes)}</td>
                                  </tr>
                              ))}
                            </table>
                            {/*diagnosis.map((d, i)=>{
                                return(

                                  <tr key={i}>
                                    <td className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px"}}> {d.diagnosis}</td>
                                    <td className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px"}}>{d.notes}</td>
                                  </tr>
                                )
                            })
                        </table>*/}
                    </> : null
                  }

                  {procedure.length > 0 ? 
                    <div className="table table-borderless prescription-table table-sm mb-0">
                      <TableHeading title='PROCEDURE'>
                        <table className="table table-bordered prescription-table table-sm mb-0">
                        {procedure.map((d, i)=>{
                            return(
                              <div key={i} > 
                                <p className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px"}}>{d.procedure_name}</p>
                              </div>
                            )
                        })}
                        </table>
                      </TableHeading>
                    </div>: null
                  }

                  {lab.length > 0 ?
                    <div className="table table-borderless prescription-table table-sm mb-0">
                      <TableHeading title='LAB'>
                        <table className="table table-bordered prescription-table table-sm mb-0">
                        {lab.map((d, i)=>{
                            return(
                              <div key={i} > 
                                <p className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px"}}>{d.hospital_test_name}</p>
                              </div>
                            )
                        })}
                        </table>
                      </TableHeading>
                    </div> : null
                  }

                  {findings.length > 0 ? 
                    <div className="table table-borderless prescription-table table-sm mb-0">
                      <TableHeading title='FINDINGS'>
                        <table className="table table-bordered prescription-table table-sm mb-0">
                          {findings.map((d, i)=>{
                              return(
                              <div key={i} > 
                                <p className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px"}}>{d.findings}</p>
                              </div>
                              )
                            })}
                        </table>
                      </TableHeading>
                    </div> : null
                  }

                  {scan.length > 0 ?
                    <>
                      <TableHeading title='SCAN'>
                        <table className="table table-bordered prescription-table table-sm mb-0">
                            <tr> 
                              <td className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px",}}><strong>Name</strong></td>
                              <td className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px", fontSize: "400"}}> <strong>Purpose</strong> </td>
                            </tr>
                            {scan.map((d, i)=>{
                                return(
                                  <tr key={i} > 
                                    <td className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px", fontSize: "bold"}}>{d.hospital_scan_name}</td>
                                    <td className={theme.palette.type === 'dark' && 'dark-theme-color'} style={{padding:"5px", fontSize: "bold"}}> {d.purpose}</td>
                                  </tr>
                                )
                            })}
                        </table>
                      </TableHeading>
                    </> : null
                  }

                  {bed_assignment.length > 0 ?
                    <div>

                    <TableHeading title='BED ASSIGNMENT'/>
                        <table className="table table-bordered prescription-table table-sm mb-0 assignment-table">
                            <thead className='discharge-table'>
                              <tr className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                  <td><p><strong>Ward</strong></p></td>
                                  <td><p><strong>Room</strong></p></td>
                                  <td><p><strong>Bed Number</strong></p></td>
                                  <td><p><strong>Date Assigned</strong></p></td>
                                  <td><p><strong>Assigned By</strong></p></td>
                                  <td><p><strong>End Date</strong></p></td>
                              </tr>
                            </thead>
                            <tbody>
                            {bed_assignment.map((d, i)=>{
                              return(
                                    <tr key={i} className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                        <td><p>{d.ward}</p></td>
                                        <td><p>{d.room}</p></td>
                                        <td><p>{d.bed_number}</p></td>
                                        <td><p>{convertGmtTime(d.assign_date)}</p></td>
                                        <td><p>{d.assigned_by}</p></td>
                                        <td><p>{convertGmtTime(d.end_date)}</p></td>
                                    </tr>
                              )
                            })}
                            </tbody>
                        </table>
                    </div>
                    : null
                  }

                  {bed_transfer.length > 0 ?
                    <>
                      <TableHeading title='BED TRANSFER'/>
                        <table className="table table-bordered prescription-table table-sm mb-0 assignment-table">
                        <thead>
                            <tr className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                <td><p><strong>Date</strong></p></td>
                                <td><p><strong>From Bed</strong></p></td>
                                <td><p><strong>To Bed</strong></p></td>
                                <td><p><strong>Reason</strong></p></td>
                            </tr>
                          </thead>
                          {bed_transfer.map((d, i)=>{
                            return(
                              <> 
                                <tbody>
                                  <tr className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                      <td><p>{convertGmtTime(d.date_transfered)}</p></td>
                                      <td><p>{d.from_bed}</p></td>
                                      <td><p>{d.to_bed}</p></td>
                                      <td><p>{d.reason}</p></td>
                                  </tr>
                                </tbody>
                              </>
                            )
                          })}
                        </table>
                    </> : null  
                  }

                  {prescription.length > 0 ?
                    <>
                      <TableHeading title='PRESCRIPTION'/>
                        <table className="table table-bordered prescription-table table-sm mb-0 assignment-table">
                        <thead>
                            <tr className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                <td><p><strong>Dosage</strong></p></td>
                                <td><p><strong>Intake Time</strong></p></td>
                                <td><p><strong>Instructions</strong></p></td>
                                <td><p><strong>Route</strong></p></td>
                                <td><p><strong>Quantity</strong></p></td>
                            </tr>
                          </thead>
                          {prescription.map((d, i)=>{
                              return(
                              <> 
                                <tbody>
                                  <tr className={theme.palette.type === 'dark' && 'dark-theme-color'}>
                                      <td><p>{d.dosage}</p></td>
                                      <td><p>{d.intake_time}</p></td>
                                      <td><p>{d.instructions}</p></td>
                                      <td><p>{d.route}</p></td>
                                      <td><p>{d.quantity}</p></td>
                                  </tr>
                                </tbody>
                              </>
                              )
                          })}
                        </table>
                    </> : null
                  }

                  <tfoot  css={[borderlessTableFooter]} className="mpeke-footer">
                      <tr>
                          <td className=" mpeke-footer text-small mpeke-footer-text">Powered by Mpeke HMIS Marascientific&copy;</td>
                      </tr>
                  </tfoot> 

                </table>

              </Card>
          </section>
      </div>
    )
}

export default DischargedPatientDetails