import React from 'react'
import {alpha, makeStyles, withStyles} from '@material-ui/core/styles';
import {TreeView, TreeItem} from "@material-ui/lab";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../actions/snackbarActions'
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {ReusableNewCOA} from "./NewCOAccount";
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import {v4 as uuidv4} from 'uuid';
import {useChartOfAccounts} from "./hooks/useChartOfAccounts";
import { Container, RightAlignedContainer } from '../../Utils/styledComponents';
import {NoTransaction} from "./ReusableJournalTable";
import COATable from './COATable';
import { AccountingDrawer } from '../../Utils/AccountingUtils/AccountingDrawer';
import { usePrint } from '../../Utils/Templates/usePrint';
import {LoadingGif} from "../../Utils/Loader";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        maxWidth: 400,
    },
    icon: {
        fill: '#605e5e'
    },
    settingsIcon: {
        width: '1.3em',
        fontSize: '2rem'
    }
});

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    label: {
        fontSize: 14
    }
}))((props) => <TreeItem {...props} />);

export const ReusableCOA = ({coa}) => {
    const classes = useStyles();
    const renderTree = (nodes) => (
        <StyledTreeItem key={uuidv4()} nodeId={nodes.id === 'root' ? nodes.id : uuidv4()}
                        label={nodes.name ? nodes.name : nodes.head_name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
        </StyledTreeItem>
    );
    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<FolderOpenIcon/>}
            defaultExpanded={['root']}
            defaultExpandIcon={<FolderIcon classes={{root: classes.icon}}/>}
            defaultEndIcon={<FolderOpenIcon/>}

        >
            {renderTree(coa)}
        </TreeView>
    )
}

const ChartOfAccounts = ({actions, snackbar}) => {

    const {closeSnackbar, openDialog, handleOpenDialog, handleCloseDialog,  coaProps,
        allAccounts, isLoading, isError, isSuccess} = useChartOfAccounts(actions);
    const {openBar, type, message} = snackbar;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const chart_of_accounts = !permission.chart_of_accounts ? {} : permission.chart_of_accounts;
    const {isPending, handleSubmit} = coaProps;
    const {componentRef} = usePrint("ChartOfAccounts");
    const {isBlocking, isBlockDialog,setIsBlockDialog,  handleResetForm} = coaProps
    return (
        <div className='journals'>
            <PageTitle title="Chart of Accounts"/>
            <SubHeader title="Accounting" subTitle="Chart of Accounts">
                <FontAwesomeIcon icon={faMoneyBill}/>
            </SubHeader>
            <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
            <AccountingDrawer open={openDialog} title='Create Account' handleClose={handleCloseDialog}>
                <form onSubmit={handleSubmit} autoComplete="off" className="general-card" name="new-acc-form">
                    <ReusableNewCOA {...coaProps}/>
                    <div style={{marginLeft:'15px'}}>
                        <button type="submit" className="btn btn-sm sms-info-btn" data-testid="submit-new-account-coa" 
                            disabled={isPending}>{isPending ? 'Submitting...' : 'Submit'}</button>
                    </div>
                </form>
            </AccountingDrawer>

            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <Container>
                <RightAlignedContainer>
                    {chart_of_accounts.create ? <div className="text-right">
                            <button type='button' className='btn btn-sm sms-info-btn mb-2'
                                data-testid="new-account-coa" onClick={handleOpenDialog}>New Account</button>
                        </div> : null}
                </RightAlignedContainer>
                <div ref={componentRef}>
                    {isLoading ? <div className='text-center mt-5'>
                        <LoadingGif/>
                    </div>:null}
                    {isSuccess ? allAccounts.length > 0 ?
                        <COATable  {...{data:allAccounts}}/>: <NoTransaction text='accounts'/>:null}
                    {isError ? <div className="mt-3 text-center">The server did not return a valid response</div>:null}
                </div>
                
            </Container>
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartOfAccounts);
