import React from "react";
import TextField from "../../Utils/FormInputs/TextField";
import {ErrorMessage} from "../../Utils/styledComponents";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Label from "../../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form";


function NewExpenseItem({debitLedger, debitLedgers, creditLedger, creditLedgers, expenseItem, submitted,
                            isPending,  handleChangeExpenseItem, handleChangeCreditLedger, handleChangeDebitLedger,
                             submitNewExpense}){
    return (
        <form onSubmit={submitNewExpense} autoComplete='off'>
            <Form.Group>
                <Label type name='Expense Item'/>
                <TextField  {...{submitted}} value={expenseItem} type="text" name="expenseItem"
                            onChange={handleChangeExpenseItem} placeholder="Enter expense item"/>
                {(submitted && !expenseItem) ? <ErrorMessage>Expense item is required</ErrorMessage>:null}
            </Form.Group>
            <Form.Group>
                <Label  name='Debit Ledger'/>
                <CustomSelect value={debitLedger} options={debitLedgers}
                              onChange={handleChangeDebitLedger}/>
            </Form.Group>
            <Form.Group>
                <Label  name='Credit Ledger'/>
                <CustomSelect value={creditLedger} options={creditLedgers}
                              onChange={handleChangeCreditLedger}/>
            </Form.Group>

            <button type='submit' disabled={isPending}  className='btn btn-sm sms-btn'>{isPending ? 'Submitting...':'Submit'}</button>
        </form>
    )
}
export {NewExpenseItem}