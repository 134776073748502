import React from 'react';
import RevenueGenerated from "./RevenueGenerated";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history"
import { BackButton } from '../../Utils/Buttons/DataExportationButton';


const  RevenuePerProcedure = () =>{
    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Service'},
        {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
    ];
    const components = [
    {label: 'Sales by Service', path: '/reports/revenuebyprocedure'},
    {label: 'Sales by Product', path: '/accounting/productsales'},
    ]
    return (
        <RevenueGenerated headData={headData} title='Revenue by Service' component='procedure'
                          urls={{revenue_url:'procedural_revenue',data_url:'procedural_revenue_time_periods'}}>
            <div style={{margin: '10px'}}>
                <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
            </div>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/> 
        </RevenueGenerated>
    )
}
export default RevenuePerProcedure;