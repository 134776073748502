import {useEffect, useState, useRef} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { formatDateTime } from "../../Utils/ConvertDate";
import {exportPDF} from "../../Utils/downloadPDF"
import { useFillPrescription } from "./UseFillPrescription";
import {titleCase} from "../../Users/addUser";
import { useReturnMedicine } from "./useReturnMedicine";

function usePrescriptionList(actions, component) {
    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [allRecords, setAllRecords] = useState([])
    const [prescriptions, setPrescriptions] = useState([]);
    const [status, setStatus] = useState('idle')
    const [total_count, setTotalCount] = useState(0)
    const [state, setState] = useState({start_date:'', end_date:''})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const csvRef = useRef();
    const {patient_type, prescriptionProps, openDialog, handleOpenDialog,
         handleCloseDialog, isResolved} = useFillPrescription(actions, component);

    const {returnProps, isResolvedReturned} = useReturnMedicine(actions);

    const {handleCloseDialog:handleCloseReturnDialog, handleOpenDialog:handleOpenReturnDialog, openDialog:returnDialog} = returnProps;

    const url = {
        'out-patient':1,
        'in-patient':2,
    }
    const route = {
        'out-patient':`prescription_list/${activePage}`,
        'in-patient':`prescription_list/${activePage}`,
        'previous':'previous_filled_prescription_list'
    }


    const {start_date, end_date} = state;
    const retrievePrescriptions = () =>{
        const formData = new FormData();
        const searchValue = term ? titleCase(term) : ''
        if(component === 'previous'){
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('start', activePage);
            formData.append('search', searchValue);
        }else{
            formData.append('search', searchValue);
            formData.append('patient_type', url[component]);
        }
        setStatus('pending')
        axios.post(`${config.smsUrl}/cpharmacy/${route[component]}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            let prescription = []
            let count = 0;
            if(component === 'previous'){
                const dataArr = !dt.data ? [] : dt.data;
                prescription = dataArr.map(item=>({
                    ...item, patient_name:`${item.patient_first_name ? item.patient_first_name : ''} ${item.patient_last_name ? item.patient_last_name : ''}`,
                    doctor_name: `${item.doctor_first_name ? item.doctor_first_name : ''} ${item.doctor_last_name ? item.doctor_last_name : ''}`
                }))
                count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
            }else{
                const dataArr = !dt.presc_list ? [] : dt.presc_list;
                prescription = dataArr.map(item=>({
                    ...item, patient_name:`${item.patient_firstname ? item.patient_firstname : ''} ${item.patient_lastname ? item.patient_lastname : ''}`,
                    doctor_name: `${item.doctor_firstname ? item.doctor_firstname : ''} ${item.doctor_lastname ? item.doctor_lastname : ''}`
                }))
                count = !dt.total_count ? 0 : dt.total_count;
            }
            
            setPrescriptions(prescription)
            setTotalCount(count)
            setStatus('success')
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
            setStatus('rejected')
        })
    }

    useEffect(()=>{
        setActivePage(1)
    },[component, patient_type])


    useEffect(() => {
        retrievePrescriptions()
        // let interval;
        // if(component !== 'previous'){
        //     interval = setInterval(()=>{
        //         retrievePrescriptions()
        //     }, 10000);
        // }
        // return () => {
        //     clearInterval(interval)
        // }
    }, [term, activePage, isResolved, component, isResolvedReturned])


    const retrieveAllRecords = () => {
        return axios.get(`${config.smsUrl}/cpharmacy/retrieve_all_prescriptions`)
    }

    const handler = (event) => {
        setTerm(event.target.value)
    };


    const handleChangeActivePage = (value) => {
        setActivePage(value)
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const downloadPdf = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.stock ? [] : dt.stock;
            const itemList = items.map(item => ({
                date_created: formatDateTime(item.date_created),
                patient_number: item.patient_number,
                patient_name: `${item.patient_firstname} ${item.patient_lastname}`,
                doctor_name: `${item.doctor_firstname} ${item.doctor_lastname}`
            }))
            exportPDF("Prescription List", [["SL", "Date", "Patient No.", "Patient Name",
                    "Doctor Name"]],
                itemList.map((item, index) => [index += 1, item.date_created, item.patient_number,
                    item.patient_name, item.doctor_name
                ]),
                "Prescription List .pdf");
            setAllRecords(itemList);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })

    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.stock ? [] : dt.stock;
            setAllRecords(items);
            csvRef.current.link.click()
        }).catch((err) => {
            errorMessages(err, null, actions);
        })
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setStatus('pending');
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('start', activePage);
            formData.append('search', term);
            axios.post(`${config.smsUrl}/cpharmacy/previous_filled_prescription_list`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                const arr = dt.data ? dt.data : []
                const count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
                const  prescription = arr.map(item=>({
                    ...item, patient_name:`${item.patient_first_name ? item.patient_first_name : ''} ${item.patient_last_name ? item.patient_last_name : ''}`,
                    doctor_name: `${item.doctor_first_name ? item.doctor_first_name : ''} ${item.doctor_last_name ? item.doctor_last_name : ''}`
                }))
                setPrescriptions(prescription)
                setTotalCount(count);
                setStatus('success')
                setIsSubmitted('resolved')
            }).catch((err) => {
                logoutErrorMessage(err, null, actions);
                setStatus('error')
                setIsSubmitted('rejected')
            })
        }
    }


    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';

    const isPendingForm =  isSubmitted === 'pending';
    const isResolvedForm = isSubmitted === 'success';
    const isRejectedForm = isSubmitted === 'error';

    return {term, activePage, allRecords, prescriptions, status,total_count, handler,
    handleChangeActivePage,closeSnackBar, downloadPdf,downloadCsv, isLoading, isSuccess, isRejected,
    returnProps, handleCloseReturnDialog, handleOpenReturnDialog, isResolvedReturned, returnDialog,
    patient_type, prescriptionProps, openDialog, handleOpenDialog, handleCloseDialog,state, submitted, isPendingForm,isResolvedForm, handleChange,
handleSubmit, isRejectedForm}
}

export {usePrescriptionList}