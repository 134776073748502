/** @jsxImportSource @emotion/react */
import {jsx,css} from '@emotion/react'
import React, {useEffect, useState} from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate} from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import { formatDigits } from '../../Utils/formatNumbers';
import { titleCase } from '../../Users/addUser';
import Card from '@material-ui/core/Card';
import { history } from '../../../Helpers/history';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import {InpatientBillRow, HeadCell, FooterCell, TotalCell} from "./InpatientBillRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import {formatDate} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";


const textWeight = css`
    font-weight:600;
`



function ViewInpatientBill(props) {
    const {patient_admission_id, visit_id, pay_status} = props.match.params;
    const {actions} = props


    const [state, setState] = useState({
        patient_number: "", first_name: '', last_name: '',phone_no:'',
        address: '', dob: '', gender: '', admission_date: '', discharge_date: '', total_days: ''
    });

    const [totals, setTotals] = useState({total_amount:0,bed_amount:0,total_product:0,due_amount:0,
        balance:0, net_total:0,tax_amount:0, discount_amount:0})
    const [billDetails, setBillDetails] = useState({});
    const [total_advance, setTotalAdvance] = useState(0);
    const [items, setItems] = useState([])
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currencyTotal, setCurrencyTotal] = useState(0)
    const [currency, setCurrency] = useState('USD')
    const [currencies, setCurrencies] = useState([])
    const [receiptTotals, setReceiptTotals] = useState({total_amount_paid:0, remaining_balance:0, non_currency_amount_paid:0,
    non_currency_remaining_balance:0})
    const [paidReceipts, setPaidReceipts] = useState([])


    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('patient_admission_id', patient_admission_id);
        formData.append('pay_status', pay_status);
        formData.append('invoice_number', '');
        axios.post(`${config.smsUrl}/cbilling/get_inpatient_bill`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                setBillDetails(data)
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });

    }, [])

    const calculateRemainingBalance = (arr=[], ex_rate=0) =>{

        const totalPaid = arr.reduce((a, item) => {
            return +(a + item.amount_paid)
        }, 0);

        const totalBalance = arr[arr.length-1] ? arr[arr.length-1] : {}
        const balance = totalBalance.balance ? totalBalance.balance : 0

        const non_currency_amount = ex_rate ? totalPaid / ex_rate : totalPaid
        const non_currency_balance = ex_rate ? balance / ex_rate : balance


        setReceiptTotals({total_amount_paid: totalPaid, remaining_balance: balance,
            non_currency_remaining_balance: non_currency_balance, non_currency_amount_paid: non_currency_amount})
    }


    useEffect(() => {
        const {patient_info, admission_bill,   receipts} = billDetails;
        const p_info = !patient_info ? [] : patient_info;
        const pt_info = !p_info[0] ? {} : p_info[0];
        const service_bill = !admission_bill ? [] : admission_bill;
        // const advance_bill = !advance ? [] : advance;
        const receps = receipts ?? [];
        const {
            admission_date, discharge_date, p_address, p_dob, p_first_name,
            p_gender, p_last_name, patient_number, phone_no
        } = pt_info;
        setState({
            patient_number, first_name: p_first_name, last_name: p_last_name, address: p_address, dob: p_dob,
            gender: p_gender, admission_date, discharge_date, phone_no
        })
        const serviceMedArr = service_bill.filter(prod => prod.product_id || prod.service_id).map(item=>{
            return {
                bill_id: item.bill_id,
                name: item.service_id ? item.service_name : item.product_id ? item.product_name : '',
                rate: item.rate,
                currency_rate: exchangeRate ?  +item.rate / +exchangeRate : item.rate,
                quantity: !item.quantity ? 0 : item.quantity,
                currency_total: exchangeRate ? (+item.rate * +item.quantity) / +exchangeRate : +item.rate * +item.quantity,
                amount: item.rate * (!item.quantity ? 0 : item.quantity),
            }
        })

        const bedArr = service_bill.filter(bd => bd.bed_assignment_id).map(item=>{
            return {
                bill_id: item.bill_id,
                name: `${item.ward_name} -> ${item.room_name} -> ${item.bed_number}`,
                quantity: `${item.quantity} day${item.quantity > 1 ? 's': ''}`,
                rate: item.rate,
                bed_quantity:item.quantity,
                currency_rate: exchangeRate ?  +item.rate / +exchangeRate : item.rate,
                currency_total: exchangeRate ? (+item.rate * +item.quantity) / +exchangeRate : +item.rate * +item.quantity,
                amount: item.rate * +item.quantity,
            }
        })
        const arr = [{name:'Services and Medication', id:'services_and_medication', open:true, values:serviceMedArr},
            {name:'Bed Payment', id:'bed_payment',open:true, values:bedArr}]


        setItems(arr)

        const totalAdvance = receps.reduce((a, item) => {
            return +(a + item.amount_paid)
        }, 0);
        setPaidReceipts(receps)
        calculateRemainingBalance(receps, exchangeRate)

        calculateTotal(serviceMedArr, bedArr, exchangeRate)

        setTotalAdvance(totalAdvance)

    }, [billDetails])




    const calculateTotal = (servicesArr, bed, rate = 0) => {

        //usd total amount
        const totalBedBill = bed.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);


        const totalService = servicesArr.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        // non usd total amount
        const currency_service_total = servicesArr.reduce((a, item) => {
            const base_total =  ( rate ?  +item.rate / rate : +item.rate) * +item.quantity
            return a + +base_total
        }, 0)


        const currency_bed_total = bed.reduce((a, item) => {
            const base_total =  ( rate ?  +item.rate / rate : item.rate) * +item.bed_quantity
            return a + +base_total
        }, 0)

        const non_currency_total_amt = totalService + totalBedBill
        const currency_total_amount =  currency_bed_total + currency_service_total
        const amt = total_advance - (non_currency_total_amt);
        const bal = amt < 0 ? 0 : amt;
        setTotals({...totals, total_amount: non_currency_total_amt, balance: bal})
        setCurrencyTotal(currency_total_amount)
    }

    const calculateAmount = (rate) => {
        const [ {values:services},{values:bedBill}] = items
        const proceduresArr = services.map(item=>({...item,
            currency_rate: +rate ?  +item.rate / +rate : item.rate, currency_total: (+rate ?  +item.rate / +rate : item.rate) * +item.quantity,
            amount:+item.rate * +item.quantity
        }))

        const bedArr = bedBill.map(item=>({...item,
            currency_rate: +rate ?  +item.rate / +rate : item.rate, currency_total: (+rate ?  +item.rate / +rate : item.rate) * +item.bed_quantity,
            amount:+item.rate * +item.bed_quantity
        }))

        const arr = [{name:'Services and Medication', id:'services_and_medication', open:true, values:proceduresArr},
            {name:'Bed Payment', id:'bed_payment',open:true, values:bedArr}]
       setItems(arr)
    }




    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)
        setExchangeRate(0)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        const [{values:services},{values:bedBill}] = items
        setExchangeRate(event.target.value)
        calculateAmount(+event.target.value)
        calculateTotal(services,  bedBill, +event.target.value)
        calculateRemainingBalance(paidReceipts, +event.target.value)
    }


    const {total_amount} = totals
    const {patient_number, first_name, last_name, address,admission_date,
        phone_no} = state;
    const patient_name = `${first_name ? titleCase(first_name) : ''} ${last_name ? titleCase(last_name) : ''}`;
    const handleOnAfterPrint = () => {
        history.push('/patientbillinglist');
    }

    const { total_amount_paid,non_currency_amount_paid, non_currency_remaining_balance, remaining_balance} = receiptTotals

    const isBaseCurrency = currency === 'USD'

    const currency_symbol = !isBaseCurrency && exchangeRate ? currency : 'USD'

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
         {key: 'rate',value:`Rate`}, {key: 'amount',value:`Amount`}]

    const {componentRef, handlePrint,  fontSize}  = usePrint(`InPatient_Bill_${patient_number}`, handleOnAfterPrint);

    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Admission Date: </span> <span css={[textWeight]}>{formatDate(admission_date)}</span> </td>
            <td><span>Patient Name: </span> <span css={[textWeight]}>{patient_name}</span> </td>

        </tr>
        <tr>
            <td><span>Patient Number: </span> <span css={[textWeight]}>{patient_number}</span></td>
            <td><span>Contact: </span> <span css={[textWeight]}>{phone_no}</span></td>
        </tr>
        <tr>
            <td><span>Admission No: </span> <span css={[textWeight]}>{patient_admission_id}</span></td>
            <td><span>Address:</span> <span css={[textWeight]}>{address}</span></td>
        </tr>
        </tbody>
    )

    const itemDetails = (
        <TableContainer >
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(<HeadCell style={{width: index === 0 ? '500px':'300px'}} key={item.key}>{item.value}</HeadCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <InpatientBillRow key={row.id} row={row} headData={headData}/>
                    ))}
                    <TableRow>

                        <FooterCell></FooterCell>
                        <FooterCell></FooterCell>
                        <FooterCell >Total Bill</FooterCell>
                        <FooterCell>{formatDigits(total_amount)}</FooterCell>
                    </TableRow>

                    <TableRow>

                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell >Amount Received</TotalCell>
                        <TotalCell>{total_amount_paid}</TotalCell>
                    </TableRow>

                    <TableRow>

                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell>Balance</TotalCell>
                        <TotalCell>{remaining_balance}</TotalCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )


    return (
        <div>
            <PageTitle title="Billing Receipt"/>
            <SubHeader title="Bill Details" subTitle="Bill Details">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <MiniCenteredDiv>
            <BackButton to='/patientbillinglist' text='Billing List' data-testid="view-inpatient-bill-back-button"/>
                <RightAlignedContainer>
                <Button variant='orange' onClick={handlePrint}>Print</Button>
                </RightAlignedContainer>
                <Card className='mt-2'>
                    {/*<div style={{padding:'8px 10px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>*/}
                    {/*    <RightAlignedContainer>*/}
                    {/*        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />*/}
                    {/*        { currency !== 'USD' ? <FxRate currency={currency !== 'USD' && currency}  value={exchangeRate}  handleChangeRate={handleChangeExchangeRate} />: null}*/}
                    {/*    </RightAlignedContainer>*/}
                    {/*</div>*/}
                    <div ref={componentRef} style={{padding:10}}>
                        <style>
                            {fontSize()}
                        </style>
                        <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )
}

export default ViewInpatientBill;