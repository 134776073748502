import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useLabResultsReport = (actions, test_results_id) =>{
    const [resultDetails, setResultDetails] = useState({});
    const [params,setParams] = useState([]);
    const [id,setId] = useState({approvedBy_id:'',user_roles_id:'',approvedBy:''})
    const{approvedBy_id,user_roles_id,approvedBy} = id
// console.log(approvedBy)
    const retrieveLabResults = () =>{
        if(!test_results_id){
            return;
        }
        const formData = new FormData();
        formData.append('test_results_id',test_results_id);
        formData.append('approvedBy_id',approvedBy_id);
        formData.append('user_roles_id',user_roles_id);
        axios.post(`${config.smsUrl}/claboratory/get_lab_results_details_by_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patient_info = !dt.patient_info ? {} : dt.patient_info;
            const parameters = !dt.params ? [] : dt.params;
            console.log(parameters)
            setResultDetails(patient_info);
            setParams(parameters)
        }).catch(err => {
            logoutErrorMessage(err,null,actions);
        })
    }

    useEffect(() => {
        retrieveLabResults()
    }, [test_results_id]);

    return {resultDetails, params,retrieveLabResults}
}