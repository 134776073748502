import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage } from "../Utils/styledComponents";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import InputGroup from "react-bootstrap/InputGroup";


function AssignBed({state, submitted, rooms, wards,bedList, ward_name, room_name, bed_name, handleChangeRoom,
    handleChangeWard, handleChangeBed, handleChange,handleSubmit,patient_admission_id, patient_name, isPending, currencyDetails}) {
    const {description,status} = state;
    const { cost, currency_symbol} = currencyDetails
    return (
            <form autoComplete='off' onSubmit={handleSubmit} >
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label name="Admission No."  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>

                        <TextField type="text" value={patient_admission_id} disabled/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label name="Patient Name"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <TextField disabled={true}   value={patient_name}
                                   name="patient_name" type="text"  />
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Ward" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomSelect id="ward-name" submitted={submitted} options={wards} onChange={handleChangeWard} value={ward_name} dataTest="ward_name"/>
                        {(submitted && !ward_name) && <ErrorMessage>Ward is required</ErrorMessage>}
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Room Name" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomSelect id="room-name" submitted={submitted} options={rooms} onChange={handleChangeRoom} value={room_name} dataTest="room_name"/>
                        {(submitted && !room_name) && <ErrorMessage>Room name is required</ErrorMessage>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Room Cost"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <InputGroup>
                            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>
                            <TextField  type="number" value={cost} style={{pointerEvents:'none'}}/>
                        </InputGroup>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Bed Number" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomSelect id="bed-number" {...{submitted}} options={bedList} onChange={handleChangeBed} value={bed_name} dataTest="room"/>
                        {(submitted && !bed_name) && <ErrorMessage>Bed number is required</ErrorMessage>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Description"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <ResizableTextarea value={description} rows={2}
                                      onChange={handleChange} name="description"/>
                    </Col>
                </Form.Group>

                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                        <Label  name="Status" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <Form.Check type="radio" inline label="Active" name="status" value="1"  checked={status === "1"}
                                    onChange={handleChange} id="active"/>
                        <Form.Check type="radio" inline label="Inactive" name="status" value="0" checked={status === "0"}
                                    onChange={handleChange} id="inactive"/>
                        {(submitted && !status) && <ErrorMessage>Status is required</ErrorMessage>}
                    </Col>
                </Form.Group>
                <button type="submit" data-testid="assign-bed" className="btn btn-sm sms-btn px-4" disabled={isPending}>
                    {isPending ? "Saving...":"Save"}</button>
            </form>
    )
}

export {AssignBed};
