import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from '../../../../Helpers/env';
import {errorMessages} from "../../../../Helpers/ErrorMessages";


export const useNewCOAccount = (actions, balance_date=null) =>{
    const [accountType, setAccountType] = useState('');
    const [state, setState] = useState({account_name:'', account_code:'', description:'',
        isSubAccount:false, opening_balance:0, balance_date: balance_date ? balance_date : '', isTransactionAccount:false});
    const [parentAccount,setParentAccount] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [defaultAccounts, setDefaultAccount] = useState([]);
    const [subAccounts, setSubAccounts] = useState([]);
    const [entryType, setEntryType] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [accountCategories, setAccountCategories] = useState([])
    const [category, setCategory] = useState({category_id:''});
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState('')

    useEffect(()=>{
        axios.get(`${config.smsUrl}/accounting/chart/accounting_categories`).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            const items = arr.map(item=>({
                value:`${item.id}-${item.head_code}`,
                label:`${item.head_code} - ${item.head_name}`
            }));
            const category = arr[0] ? arr[0] : {};
            const _id = category.id ? category.id : '';
            setCategory({...category, category_id:_id})
            setDefaultAccount(items)
            setAccountCategories(arr)
        },err=>console.log(err))
    },[])


    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            console.log(err)
        })
    }, []);

    const retrieveSubAccount = (id) =>{
        const formData = new FormData()
        formData.append('account_category', id)
        axios.post(`${config.smsUrl}/accounting/chart/parent_accounts`, formData).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            const items = arr.map(item=>({
                value:item.head_code,
                label:`${item.head_code} - ${item.head_name}`
            }))
            setSubAccounts(items)
        },err=>console.log(err))
    }

    const handleChangeAccountType = (value) =>{
        setAccountType(value)
        const val = value ? value : {};
        const item = val.value ? val.value : '';
        const id = item.split('-')[0]
        retrieveSubAccount(id)
        setIsBlocking(true)
    }

    const handleChangeState = (event) =>{
        const {value,checked,type,name} = event.target;
        const val = type === 'checkbox' ? checked : value
        setState({...state,[name]:val})
        setIsBlocking(true)
    }

    const handleChangeParentAccount = (value) =>{
        setParentAccount(value)
        setIsBlocking(true)
    }

    const handleChangeEntryType = (value) =>{
        setEntryType(value)
        setIsBlocking(true)
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpenDialog(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }

    //Change currency
    const handleChangeCurrency = (value) =>{
        setCurrency(value)
    }

    const clearForm = () => {
        setAccountType('');
        setParentAccount('');
        setEntryType('');
        setCurrency('');
        setState(
            {account_name:'', account_code:'', description:'',
            isSubAccount:false, opening_balance:0, balance_date: balance_date ? balance_date : '', isTransactionAccount:false}
        )
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        const {account_name, opening_balance, balance_date, isTransactionAccount} = state;
        const isTransaction = isTransactionAccount ? 1 : 0
        setSubmitted(true);
        const formData = new FormData();
        const val = accountType ? accountType : {};
        const item = val.value ? val.value : '';
        const category_id = item.split('-')[1]
        const entry_type = entryType ? entryType : {};
        const entry_value = entry_type.value ? entry_type.value : '';
        const currency_type = currency ? currency : {};
        const currency_value = currency_type.value ? currency_type.value : ''
        formData.append('account_category',category_id);
        formData.append('account_name', account_name);
        formData.append('parent_account_code', parentAccount?.value);
        formData.append('opening_balance', opening_balance ? opening_balance : 0);
        formData.append('entry_type', entry_value);
        formData.append('as_of', balance_date);
        formData.append('is_transaction', isTransaction);
        formData.append('currency', 3);
        setIsBlocking(false)
        const isTransact =  (entry_value && balance_date) || (!entry_value && balance_date);
        if (accountType && account_name  && ((isTransactionAccount && isTransact) || !isTransactionAccount)){
            setIsSubmitted('pending')
            // const url = isSubAccount && parentAccount.value ? 'sub_accounts/add' : 'parent_accounts/add'
            axios.post(`${config.smsUrl}/accounting/chart/sub_accounts/add`, formData).then(()=>{
                actions.snackbarActions.snackSuccess('Account created successfully')
                setIsSubmitted('resolved')
                clearForm();
                setSubmitted(false)
                setOpenDialog(false)
            }).catch(err=>{
                errorMessages(err, null,actions);
                setIsSubmitted('rejected')
            })
        }
    }

    const handleResetForm = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setEntryType('')
        setAccountType('')
        setParentAccount('')
        setState({account_name:'', account_code:'', description:'',
            isSubAccount:false, opening_balance:0})
        setSubmitted(false)
    }


    const entryTypes = [{value: 'DEBIT', label: 'DEBIT'}, {value: 'CREDIT', label: 'CREDIT'}]

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const coaProps = {accountType, defaultAccounts, handleChangeAccountType, submitted, handleChangeState,
        subAccounts, parentAccount, handleChangeParentAccount,handleSubmit,handleResetForm,
        entryType, handleChangeEntryType, entryTypes,isBlocking, isBlockDialog,
        handleChangeCurrency, currencies, currency,
        isPending, isRejected, isResolved,state, setOpenDialog,setIsBlockDialog, setIsBlocking
    }

    return { openDialog, handleOpenDialog, handleCloseDialog,  coaProps, isResolved, 
        accountCategories, setAccountCategories, category, setCategory
    }
}