import  {useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {titleCase} from "../../../Users/addUser";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import { v4 as uuidv4 } from 'uuid';

const useReusablePrescription = (obj) => {
    const {actions, visit_id, setIsBlocking} = obj
    const initialPrescState = [{
        drug_name: '', dosage: '', disabled: false,generic_name:'', stock:'',
        number_of_days: 0, key_information: '', rate: 0, date: '', invoice_no: '',
        product_id: '', showDrug: false,  frequency: '', isError: false,
        prescription_detial_id: '', bill_id: '', isEdited: false, status:0,currency:'',
        currency_symbol:'', uuid : uuidv4()
    }]
    const initialOthers = [{
        other_medicine: '', dosage: '', disabled: false, quantity:0,
        number_of_days: 0, key_information: '', frequency: '', rate: 0
    }]
    const [submitted, setSubmitted] = useState(false);
    const [prescription, setPrescription] = useState(initialPrescState);
    const [others, setOthers] = useState(initialOthers);
    const [drugs, setDrugs] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [other, setOther] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [cashItems, setCashItems] = useState([])
    const [product_id, setProductId] = useState('')
    const [alert, setAlert] = useState({open:false, message:'', isExpired:false})


    const handleChangeOther = (event) => {
        setOther(event.target.checked)
        setIsBlocking(true)
    }

    const retrieveDrugs = (eventTxt, idx) => {
        const arr = prescription.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('product_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? [] : resp;
                        if (data[0] === "No Product Found") {
                            setDrugs([{value: '', label: 'No medicine found'}])
                        } else {
                            const arr = data.map(item=>({value:item.value, label: `${item.label}-${item.generic_name}-${item.country_of_origin}`}))
                            setDrugs(arr);
                        }
                    }).catch(error => {
                        // logoutErrorMessage(error, null, actions)
                    });
                    return {...item, drug_name: eventTxt, showDrug: true, isError: false, }
                }
                return {...item, drug_name: eventTxt, showDrug: false, isError: false}
            }
            return item
        })
        setAlert({...alert, open: false})
        setPrescription(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const clickDrug = (event, idx) => {
        let arr = prescription.map((item, index)=>{
            if (index === idx){
                return {...item, drug_name:event.innerText, product_id:event.value, generic_name:event.generic_name,
                    isError: false, showDrug: false}
            }
            return item
        })
        const formData = new FormData();
        formData.append('product_id', event.value);
        formData.append('location', 2); // 2 for pharmacy
        formData.append('to_store', 'Pharmacy');
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/get_medicinedetails_byproduct_id`, formData).then(res => {
                const resp = res.data;
                const data = !resp ? {} : resp;
                const {quantity} = data
                setAlert({...alert, open: data.isExpired, message: event.innerText, isExpired: data.isExpired})
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id: +visit_id, item_id:+event.value, item_type:'product'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    arr = arr.map((item, index)=>{
                        if (index === idx){
                            return {...item, rate:  dataObj.cost ?? 0,status: quantity,...dataObj,isExpired:data.isExpired,
                                currency: dataObj.currency_id, currency_symbol: dataObj.currency, stock:quantity}
                        }
                        return item
                    })
                    setPrescription(arr)
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setProductId(event.value)
                        setOpenConfirmation(true)
                    }
                    setIsSubmitted('clickedDrug');
                }).catch(error => {
                    errorMessages(error, null, actions)
                });
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
        setIsBlocking(true)
    }

    const handleCloseAlert = () =>{
        setAlert({...alert, open: false})
    }

    const addItemsToReceipt = () =>{
        const arr = prescription.filter(item=>item.product_id === product_id)
        setCashItems(arr)
        setOpenConfirmation(false)
    }

    const handleCloseConfirmation = () =>{
        const arr = prescription.filter(item=>item.product_id !== product_id)
        setPrescription(arr)
        setOpenConfirmation(false)
    }

    const handleCancelConfirmation = () =>{
        setOpenConfirmation(false)
    }

    const isDrugClicked = isSubmitted === 'clickedDrug';


    const drugProps = {submitted, prescription, drugs, others, setOther, setPrescription, setOthers, setSubmitted,
        isSubmitted, retrieveDrugs, clickDrug, handleChangeOther, other, setIsSubmitted, setDrugs,
        initialOthers, initialPrescState, openConfirmation, addItemsToReceipt,isDrugClicked,
        handleCloseConfirmation, handleCancelConfirmation, alert, setAlert, handleCloseAlert}


    return {drugProps}
}
export {useReusablePrescription}