import {useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import { convertPhone } from "../../../Utils/convertPhone";
import { useAddAssign } from "../../../BedManager/CustomHooks/useAddAssign";
import {useAddPrescription} from "../../../DoctorsModule/Prescription/hooks/useAddPrescription";
import {titleCase} from "../../../Users/addUser";

export const useAddAdmission = (actions, patient_number, nurseAdmission=false, match) =>{
    const [state, setState] = useState({admission_date: dateConvert(),
        first_name: "", last_name: "",  address: '', nin: ''
    });
    const [p_number, setPatientNumber] = useState(patient_number ? patient_number : '');
    const [showNumber, setShowNumber] = useState(false);
    const [patientNumbers, setPatientNumbers] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [printDialog, setOpenPrintDialog] = useState(false);
    const [ids, setIds] = useState({patient_admission_id: '', visit_id: match.visit_id, patient_name:''});
    const [patient_name, setPatientName] = useState('');
    const [contact, setContact] = useState('')
    const [relationship, setRelationship] = useState('')
    const {assignProps, openDialog, setOpenDialog} = useAddAssign(actions,ids)
    const [search_parameter, setSearchParameter] = useState('patient_no')
    const [provider_id, setProviderId] = useState('')
    const [isProvider, setIsProvider] = useState(null)
    const [providerDetails, setProviderDetails] = useState({account_name:'', card_number:''})
    const [providers, setProviders] = useState([])
    const [isOnInsurance, setIsOnInsurance] = useState(false)
    const [addReferral, setAddReferral] = useState(false)
    const [openNewInsurance, setOpenNewInsurance] = useState(false)
    const [new_insurance, setNewInsurance] = useState('')
    const [newProviderDetails, setNewProviderDetails] = useState({account_name:'', card_number:''})
    const [submitProvider, setSubmitProvider] =useState(false)
    const [referralDialog, setReferralDialog] = useState(false);
    const [referrals, setReferrals] = useState({
        referral_date: dateConvert(), referral_number: "",
        visit_date: dateConvert(), diagnosis: "", reason: "", notes: "",
        from_hospital: "", patient_history: "", treatment: ""
    });
 
    const [openPrint, setOpenPrint] = useState(false);

    const [company_name,setCompanyName]= useState(null);
    const [departments, setDepartments] = useState([]);

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const department_id = !_token.department_id ? "" : _token.department_id;

    const [findings, setAdmissionNotes] = useState('');
      
    const {addProps} = useAddPrescription(actions, match);

    const {prescription, other, others} = addProps;

    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const provArr = arr.map(item=>({value:`${item.provider_id}-${item.provider_type}-${item.provider_name}`, label:item.provider_name}))
            setProviders(provArr);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cuser/view_company_info`)
            .then((response) => {
                const data= response.data? response.data:{}
                setCompanyName(data.company_name);
            }).catch(err=>{
                errorMessages(err, null, actions)
        });
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])


    const fetchPatientDetails = (patientNumber) =>{
        const formData = new FormData()
        formData.append('patient_number', patientNumber);
        axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formData)
            .then(res => {
                const data = res.data;
                const details = !data ? {} : data;
                const {first_name, last_name} = details;
                const provider_id = details.provider_id ? `${details.provider_id}-${details.provider_type}-${details.provider_name}` : ''
                const provider_name = details.provider_id ? details.provider_name : ''
                setProviderId({value:provider_id, label:provider_name})
                setIsProvider(provider_id)
                setProviderDetails({account_name: details.account_name ? details.account_name : '',
                    card_number: details.card_number ? details.card_number : ''})
                setPatientName(`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`)
            }).catch(error => {
            errorMessages(error,null, actions)
        })
    }

    useEffect(()=>{
        if (!patient_number){
            return;
        }

         fetchPatientDetails(patient_number)
        setShowNumber(false);

        if (ids.visit_id) {
            const formData2 = new FormData();
            formData2.append('visit_id', ids.visit_id);
            axios.post(`${config.smsUrl}/cdoctor/retrieve_exam_notes`, formData2).then(res => {
                const data = res.data;
                const dt = !data ? [] : data;
                const not = dt[dt.length-1] ? dt[dt.length-1] : {};
                const arr = not.notes ? not.notes : ''
                setAdmissionNotes(arr);
            }).catch(err => {
                errorMessages(err, null, actions)
            })
        }
    },[ patient_number]);

    const handleChangeReferral = (event) => {
        const {name, value} = event.target;
        setReferrals({...referrals, [name]: value})
    };


    const handleCloseReferral = () => {
        setOpenPrint(false)
    };

  
    const handleResetReferral = () => {
        setReferrals({
            referral_date: '', referral_number: "",
            visit_date: "", diagnosis: "", reason: "", notes: "",
            from_hospital: "", patient_history: "", treatment: ""
        });
        setSubmitted(false)
    };
    const handleCloseSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeRelationship = value =>{
        setRelationship(value)
    }


    const handleChangeProvider = (value) =>{
        setProviderId(value)
        setNewProviderDetails({account_name: '', card_number: ''})
    }

    const handleChangeProviderDetails = (event) =>{
        const {name, value} = event.target
        setProviderDetails({...providerDetails, [name]:value})
    }

    const handleChangeNewProvider = (value) =>{
        setNewInsurance(value)
        setProviderDetails({account_name: '', card_number: ''})
    }

    const handleChangeNewProviderDetails = (event) =>{
        const {name, value} = event.target
        setNewProviderDetails({...newProviderDetails, [name]:value})
    }

    const handleChangeIsInsurance = (event) =>{
        setIsOnInsurance(event.target.checked)
    }

    const handleAddReferral = (event) =>{
        setAddReferral(event.target.checked)
    }

    const handleOpenNewInsurance = () =>{
        setOpenNewInsurance(true)
    }

    const handleCloseNewInsurance = () =>{
        setOpenNewInsurance(false)

    }

    const handleSubmitProvider = (event) =>{
        event.preventDefault()
        const prov = new_insurance ? new_insurance : {}
        const prov_value = prov.value ? prov.value : ''
        const [id, provider_type] = prov_value.split('-')
        const isInsurance = provider_type === 'Insurance Company'
        const {account_name, card_number} = newProviderDetails
        const formData = new FormData()
        formData.append('patient_number', p_number)
        formData.append('provider_id',  id)
        formData.append('account_name', account_name ? account_name:'')
        formData.append('card_number', card_number ? card_number: '')
        setSubmitProvider(true)
        const isProviderRequired = (isInsurance && account_name && card_number) || (!isInsurance && account_name)
        
        if(new_insurance && isProviderRequired){
            setIsSubmitted('pendingInsurance')
            axios.post(`${config.smsUrl}/cpatient/patient_insurance_detail/add`, formData).then((res) => {
                setIsSubmitted('resolvedInsurance')
                setOpenNewInsurance(false)
                fetchPatientDetails(p_number)
            }).catch((err) => {
                setIsSubmitted('rejectedInsurance');
                errorMessages(err, null, actions)
            });
        }
    }

    const handleChangePatientNumbers = (event) => {
        setPatientNumber(event.target.value);
        const formData = new FormData();
        if (event.target.value !== '') {
            const patient_no = search_parameter === 'patient_no' ? event.target.value.toUpperCase() : ''
            const patient_name = search_parameter === 'patient_name' ? titleCase(event.target.value) : ''
            const phone_no = search_parameter === 'phone_no' ? event.target.value : ''
            formData.append('patient_number', patient_no);
            formData.append('name', patient_name);
            //formData.append('last_name', '');
            formData.append('phone_no', phone_no);
            axios.post(`${config.smsUrl}/cadmission/autosearch_non_admission`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    if (data[0] === "No Patient Found") {
                        setPatientNumbers([{value: '', label: 'No patient found'}])
                    } else {
                        const arr = data.map(item => ({
                            value: item.patient_number,
                            label: `(${item.patient_number}) ${item.first_name} ${item.last_name} - ${item.phone_no}`
                        }))
                        setPatientNumbers(arr)
                    }
                }).catch(error => {
               errorMessages(error,null, actions)
            });
            setShowNumber(true);
        } else {
            setShowNumber(false);
            setProviderId('')
            setProviderDetails({account_name: '', card_number: ''})
            setPatientName('')
        }

    };

    const handleChangeSearchParam = (event) =>{
        setSearchParameter(event.target.value)
        setPatientNumber('')
        setPatientNumber('')
        setPatientName('')
        setShowNumber(false);
    }

    const handleClickPatientNumber = (event) => {
        setPatientNumber(event.value);
        fetchPatientDetails(event.value)
        setShowNumber(false);
    };

    const handleReset = () => {
        setState({
            admission_date: "",
            first_name: "",
            last_name: "",
            address: '',
            nin: ''
        });
        setContact('');
        setPatientNumber('');
        setRelationship('');
        setState({admission_date: dateConvert(),
            first_name: "", last_name: "",  address: '', nin: ''
        });
    };

    const handleClosePrintDialog = () => {
        setOpenPrintDialog(false)
    };

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleSubmit = event => {
        event.preventDefault();
        const {admission_date, first_name,  address, nin} = state;
        const {account_name, card_number} = providerDetails
        const [id, provider_type] = provider_id?.value?.split('-')
        const {
           diagnosis,treatment, patient_history, from_hospital
        } = referrals;
         const{visit_id} = ids
         const record_incoming_referral = JSON.parse(sessionStorage.getItem(`record_incoming_referral_${p_number}`));
         const is_referred = record_incoming_referral !== null;
         const referral_data = (addReferral && (from_hospital && diagnosis)) || !addReferral

        const data ={
            patient_number: p_number,
            visit_id: visit_id,
            patient_type: 2,
            department_id: department_id,
            first_name: first_name,
            relationship: relationship ? relationship.value : '',
            phone_no: contact ?  convertPhone(contact):contact,
            address: address,
            nin: nin,
            user_roles_id: user_roles_id,
            provider_id: +id,
            is_on_insurance: isOnInsurance ? 1 : 0,
            account_name: account_name,
            card_number: card_number,
            findings: findings,
            products: prescription.filter(item => Boolean(item)).filter(drug => drug.product_id),
            other_products:others ? others.filter(item=>Boolean(item)).filter(drug => drug.other_medicine || drug.dosage || drug.price || drug.instructions ||
                drug.frequency || drug.number_of_days) : [],
            is_referred: addReferral, 
            referral_data: addReferral === false ? '' : {
                referred_from : from_hospital,
                referred_to: company_name,
                referral_type: "incoming",
                visit_date: '',
                diagnosis: diagnosis,
                reason: patient_history,
                treatment_given: treatment,
                user_role_id: ''
            }
        }
     

        console.log('rel-date-p_number-contact-address',`${relationship}-${admission_date}-${p_number}-${contact}-${address}`)

        console.log('rel-date-p_number-contact-address',`${relationship}-${admission_date}-${p_number}-${contact}-${address}`)

        setSubmitted(true);
        if (p_number && admission_date && contact && first_name && relationship && address && referral_data) {

            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cadmission/add_admission`, data).then(res => {
                setIsSubmitted('resolved');
                const data = res.data;
                const dt = !data ? {} : data;
                if(dt.message === "Already Exists"){
                    actions.snackbarActions.snackError("Patient already admitted");
                }else{
                    const patient_admission_id = !dt.patient_admission_id ? "" : dt.patient_admission_id;
                    const visit_id = !dt.visit_id ? "" : dt.visit_id;
                    setIds({patient_admission_id,visit_id, patient_name})
                    actions.snackbarActions.snackSuccess("Patient admission registered successfully");
                    if(nurseAdmission){
                        setOpenDialog(true)
                    }else{
                        setOpenPrintDialog(true)
                    }
                  
                }

                handleReset();
                setSubmitted(false);
            }).catch(err => {
                setSubmitted(false);
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };

    const handleReferralDialog = (e) => {
        e.preventDefault();
        setReferralDialog(!referralDialog)
    }

    const handleClick = () => {
        const {patient_admission_id, visit_id} = ids
        history.push(`/admissiontemplate/${patient_admission_id}/${visit_id}`)
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    // hooks for the admission notes
    const handleChangeNotes = (event) => {
        const _note = event.target.value;
        setAdmissionNotes(_note);
    }

    const isPending = isSubmitted === 'pending';

    const isPendingProvider = isSubmitted === 'pendingInsurance'

    const providerProps = {submitted,provider_id,handleChangeProvider,providers,handleChangeProviderDetails,
        providerDetails}

    const newProviderProps = {submitted:submitProvider,provider_id:new_insurance,handleChangeProvider:handleChangeNewProvider,
        providers,handleChangeProviderDetails:handleChangeNewProviderDetails,
        providerDetails:newProviderDetails}

    const referralProps = {submitted,isSubmitted,company_name, handleChangeReferral,
        handleCloseSnackbar,handleResetReferral,openPrint,referrals,handleCloseReferral}

    const admissionProps ={state, p_number, showNumber, patientNumbers, submitted, addProps, search_parameter, handleChangeSearchParam,
        patient_name, handleChange, handleChangePatientNumbers, handleClickPatientNumber, handleReset,patient_number,
         handleSubmit, contact,setContact, isPending, relationship, handleChangeRelationship,
        findings, handleChangeNotes,isProvider, provider_id, providers, providerDetails, handleChangeProviderDetails,
        handleChangeProvider, isOnInsurance, addReferral, handleAddReferral, handleChangeIsInsurance,providerProps, openNewInsurance, handleCloseNewInsurance,isPendingProvider,
        handleSubmitProvider, handleOpenNewInsurance, newProviderProps, handleReferralDialog,referralDialog}

    return {admissionProps, printDialog,handleClosePrintDialog, handleSubmit, handleClick, closeSnackbar,setPatientNumber,userObj,referralProps,
        token, _token,department_id,user_roles_id,ids, setState, assignProps, handleCloseDialog, openDialog}
}