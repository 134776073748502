import * as actions from "./billingActions"



export function billingServicesReducer(state, action) {
    const {procedureData} = state

    switch (action.type) {

        case actions.RETRIEVE_SERVICES:{
            return {...state, procedureData:action.services, invoicesPaid:action.services}
        }

        case actions.CHANGE_SERVICE_CURRENCY_RATE : {
            const arr = procedureData.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value * +action.exchangeRate : 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate: action.exchangeRate ?  +action.value * +action.exchangeRate : 0, currency_rate: action.value,
                        subTotal:base_total, isRate:true, currency_total: +action.value * +item.quantity}
                }
                return item
            })
            const procedures = arr.filter(item=>!item.isExcluded)

            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id).map(item=>({...item, rate: +item.rate}));

            action.calculateTotal(procedures,action.products, action.exchangeRate,action?.bedBill)
            return {...state, procedureData:arr, invoicesEdited:edited,invoicesPaid:unedited}
        }

        case actions.CHANGE_SERVICE_RATE: {
            const arr = procedureData.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value / +action.exchangeRate: 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate:action.value, currency_rate: action.exchangeRate ?  +action.value / +action.exchangeRate : 0,
                        subTotal: +item.quantity * +action.value, isRate:true, currency_total:base_total}
                }
                return item
            })
            const procedures = arr.filter(item=>!item.isExcluded)

            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id).map(item=>({...item, rate: +item.rate}));

            action.calculateTotal(procedures,action.products, action.exchangeRate,action?.bedBill)
            return {...state, procedureData:arr, invoicesEdited:edited,invoicesPaid:unedited}
        }

        case actions.DELETE_SERVICE: {
            const removed = procedureData.map(item =>{
                if(item.bill_id === action.bill_id){
                    return {...item, isExcluded:!item.isExcluded}
                }
                return item
            })
            const items = removed.map(item=>({...item,quantity: item.isExcluded ? 0 : item.quantity,
                subTotal:item.isExcluded ? 0 : item.subTotal})).filter(item => !item.isExcluded)

            const paidInvoices = removed.filter(item => !item.isExcluded && !item.isRate)
            const removedItems = removed.filter(item=>item.isExcluded)

            action.calculateTotal(items,action.products, action.exchangeRate,action?.bedBill)
            return {...state, procedureData:removed, invoicesPaid:paidInvoices, invoicesRemoved:removedItems}
        }

        case actions.CALCULATE_SERVICE_AMOUNT:{
            const proceduresArr = procedureData.map(item=>({...item,
                currency_rate: +action.rate ?  +item.rate / +action.rate : 0, currency_total: (+action.rate ?  +item.rate / +action.rate : 0) * +item.quantity,
                subTotal:+item.rate * +item.quantity
            }))
            return {...state, procedureData:proceduresArr}
        }

        default: {
            throw new Error(`Unsupported action type: ${action.type}`)
        }

    }

}