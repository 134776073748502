import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";

export const useBillingReceipt = (props) => {
    const [receipt, setReceipt] = useState({});
    const [services, setServices] = useState([]);
    const [state, setState] = useState({
        receipt_no: '', payment_type: '',date_created:'',
        payment_method:'', currency_id:'',
    });
    const [total_amount, setTotalAmount] = useState({currency_total:0, usd_total:0,
        currency_paid_amount:0,paid_amount: 0, due_amount: 0,currency_due_amount:0})
    const [cashier, setReceivedBy] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('USD')
    const [currencies, setCurrencies] = useState([])
    const {visit_id, receipt_number} = props.match.params;
    const {actions} = props;

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        const numbers = receipt_number.split('-')
        axios.post(`${config.smsUrl}/cbilling/view_bill_receipt`, {visit_id:+visit_id,receipt_numbers:numbers}).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const info = !all_data.patient_info ? {} : all_data.patient_info;
            const arr = !all_data.bill ? {} : all_data.bill;
            const user = arr.recevied_by ? arr.recevied_by:[];
            const [cashier_name] = user;

            const invoices = arr.invoices ?? [];
            let invoicesArr = [];
              invoices.forEach(item=>{
               invoicesArr = [...invoicesArr,...item]
            })
            const {balance,total_paid, pay_type,date,tota_paid_in_ssp} = arr;

            const obj = invoicesArr[0] ? invoicesArr[0] : {};
            const exchange_rate = obj.currency_rate ? obj.currency_rate : 0;

            const current_currency = obj.currency_id === 1 ? 'USD' : 'SSP'
            setCurrency(current_currency)


            setReceipt(info);
            setExchangeRate(exchange_rate)
            setReceivedBy(cashier_name)
            setState({receipt_no: receipt, payment_type: pay_type, currency_id: obj.currency_id, date_created: date,})
            convertList(exchange_rate, total_paid, tota_paid_in_ssp, invoicesArr,balance)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);

    const totalCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.usd_total)
        }, 0)
    }

    const totalNonCurrency = (arr=[]) =>{
        return arr.reduce((a, item) => {
            return (+a + item.currency_total)
        }, 0)
    }

    const convertList = (ex_rate,paidAmount,currency_paid_amt,arr=[],balance=0) =>{
        const paid_amt = currency_paid_amt ? currency_paid_amt : paidAmount / ex_rate
        const currency_balance = ex_rate ?  balance / ex_rate :  balance

        const invArr = arr.map(item=>{
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0
            const total = rate * qty
            return {
                ...item,
                item_name: item.service_id ? item.service_name : item.product_id ? item.product_name : '',
                currency_rate: ex_rate ?  item.rate / ex_rate : item.rate,
                usd_rate:item.rate,
                usd_total : total,
                currency_total: (ex_rate ?  item.rate / ex_rate : item.rate) *  (!item.quantity ? 0 : item.quantity),
        }})

        const t_amount = totalCurrency(invArr)
        const t_currency = totalNonCurrency(invArr)

        setTotalAmount({usd_total: t_amount, currency_total: t_currency,
            currency_paid_amount: paid_amt, currency_due_amount: currency_balance,
            paid_amount: paidAmount, due_amount: balance,})

        setServices(invArr);
    }

    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)
        // setExchangeRate(0)

    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setExchangeRate(event.target.value)
        const {paid_amount, due_amount, currency_paid_amount,} = total_amount
        convertList(+event.target.value, paid_amount, currency_paid_amount , services, due_amount)

    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {receipt, services, state,  total_amount, cashier,closeSnackbar,
        exchangeRate, currencies, currency, handleChangeExchangeRate, handleChangeCurrency}
}