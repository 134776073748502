import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {formatAmount, formatDigits} from "../../Utils/formatNumbers";


const headData = [
    {id: 'item', numeric: false, disablePadding: false, label: 'Item'},
    {id: 'batch_id', numeric: false, disablePadding: false, label: 'Batch#'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity'},
    {id: 'rate', numeric: false, disablePadding: false, label: 'Rate'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];

const ProductsPurchased = ({rowsPerPage=10,  page=0, products=[]}) => {
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);
    return (
        <MuiPaper>
               <StickyHeadTable {...{width:'100%', columns:headData.filter(item=>Boolean(item))}}>
            {products.length > 0 ?
                <>
                    {products.map((row, index) => {
                        const rate = row.rate ? row.rate : 0;
                        const quantity  = row.quantity ? row.quantity : 0
                        return (
                            <TableRow  tabIndex={-1} key={index}>
                                <CustomTableCell>{row.product_name}</CustomTableCell>
                                <CustomTableCell>{row.batch_id}</CustomTableCell>
                                <CustomTableCell>{quantity}</CustomTableCell>
                                <CustomTableCell>{rate}</CustomTableCell>
                                <CustomTableCell>{formatDigits(row.total_amount)}</CustomTableCell>
                            </TableRow>
                        );
                    })}
                    {/*{emptyRows > 0 && (*/}
                    {/*    <TableRow style={{ height: 43 * emptyRows }}>*/}
                    {/*        <TableCell colSpan={headData.length} />*/}
                    {/*    </TableRow>*/}
                    {/*)}*/}
                </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>}
        </StickyHeadTable>
        </MuiPaper>
     
    )
};

export {ProductsPurchased};
