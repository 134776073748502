import React, {useState} from "react";
import {PatientInformation} from "../PatientInfo/PatientInformation";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../Utils/smsTitle";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import FormGroup, {FormTextArea, ReusablePhoneInput} from "../../Utils/FormInputs/FormGroup";
import Label from "../../Utils/FormInputs/Label";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import { useAddAdmission } from "../../Visit/NewAdmission/hooks/useAddAdmission";
import { usePatientDetails } from "../ReferralNote/usePatientDetails";
import { titleCase } from "../../Users/addUser";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {CenteredContainer, ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import Textarea, {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import { FormLabel } from "../../Utils/FormInputs/Label";
import {NewPrescriptionTable} from "../Prescription/NewPrescription";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {ProviderForm} from "../../Visit/NewVisit/NewVisit";
import TextField from "../../Utils/FormInputs/TextField";

const style = {
    margin: '5px 20px'
}

export const DoctorAdmissionForm = ({admissionProps}) =>{

    const {state,  submitted, handleChange, handleSubmit, contact, setContact, isPending, relationship,
        handleChangeRelationship, findings, handleChangeNotes, addProps,
        isProvider,   isOnInsurance, handleChangeIsInsurance,providerProps,handleOpenNewInsurance} = admissionProps;

    // removed the nin
    const { first_name, address} = state;

    const headData = [{item:'Drug Name', toolTip:'Enter drug name', isRequired:true},{item:'Dosage',  toolTip:'Enter dosage'},
    {item:'Frequency',  toolTip:'Enter frequency'},{item:'No. of Days',  toolTip:'Enter number of days'},
    {item:'Instructions',  toolTip:'Enter instructions'},{item:'Action'}]


    const [isAddPrescription, setAddPrescription]= useState(false);
    
    const handlePrescriptionToggle = (event) => {
        setAddPrescription(event.target.checked);
    };



    return(
        <form onSubmit={handleSubmit} autoComplete="off">
       
            {/*<div className="col-md-10">*/}
            <div>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Label name="Guardian(care taker)  Name" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <TextField onChange={handleChange} name="first_name" value={titleCase(first_name)} type="text"
                                   submitted={submitted}
                        />
                        {(submitted && !first_name) &&
                            <ErrorMessage>Guardian name is required</ErrorMessage>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Label name="Guardian(care taker) Contact"  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <ReusablePhoneInput submitted={submitted} contact={contact} setContact={setContact} required/>
                    </Col>
                </Form.Group>
            </div>

            <div>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Label name="Guardian(care taker) Address" type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <ResizableTextarea onChange={handleChange} placeholder='Write address...' name="address" value={address}
                                           submitted={submitted} rows={2}
                        />
                        {(submitted && !address) &&
                            <ErrorMessage>Guardian address is required</ErrorMessage>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Label name="Relationship"  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <CustomSelect value={relationship} onChange={handleChangeRelationship}options={[{value:'Mother',label:'Mother'},{value:'Father',label:'Father'},
                            {value:'Wife',label:'Wife'},{value:'Husband',label:'Husband'},
                            {value:'Sister',label:'Sister'},{value:'Brother',label:'Brother'},
                            {value:'Spouse',label:'Spouse'},{value:'Guardian',label:'Guardian'},
                            {value:'Other',label:'Other'}]}/>
                    </Col>
                </Form.Group>
                {isOnInsurance && isProvider ?
                    <ProviderForm {...{...providerProps, isDisabled:true, labelSm:"3", colSm:"8"}}/> :  null}
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    {isOnInsurance && !isProvider ? <RightAlignedContainer>
                        <button type='button' onClick={handleOpenNewInsurance} className='btn btn-sm sms-info-btn mb-2'>Add Provider</button>
                    </RightAlignedContainer>:null}
                    {isOnInsurance && isProvider ? <RightAlignedContainer>
                        <button type='button' onClick={handleOpenNewInsurance} className='btn btn-sm sms-info-btn mb-2'>Change Provider</button>
                    </RightAlignedContainer> : null}
                </Col>
                <Form.Group className="switch-admission-prescription">
                    <Label name="Do you want to use Insurance?" className="label-admission-prescription" style={{paddingLeft:'15px'}}/>
                    <text className="label-admission-prescription">No</text>
                    <Form.Check type="switch" id="custom-admission-prescription-switch" defaultChecked={isOnInsurance} onChange={handleChangeIsInsurance}
                                className="toggle-admission-prescription"/>
                    <text className="label-admission-prescription">Yes</text>
                </Form.Group>
                {/*<Form.Group className="switch-admission-prescription">*/}
                {/*    <Label name="Do you want to use Insurance?" className="label-admission-prescription"/>*/}
                {/*    <text className="label-admission-prescription">No</text>*/}
                {/*    <Form.Check type="switch" id="custom-switch" defaultChecked={isOnInsurance} onChange={handleChangeIsInsurance}*/}
                {/*                className="toggle-admission-prescription"/>*/}
                {/*    <text className="label-admission-prescription">Yes</text>*/}
                {/*</Form.Group>*/}
            </div>
                {/* <div className="col-lg-6">
                    <Form.Group as={Row}>
                        <Label name="Guardian Contact" sm="3" xl="2" column type/>
                        <Col sm={6} xl={8} xs={6}>
                            <ReusablePhoneInput submitted={submitted} contact={contact} setContact={setContact} required/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Label name="Relationship" column sm="4" xl="2" xs="6" type/>
                        <Col sm={6} xl={8} xs={6}>
                            <CustomSelect value={relationship} onChange={handleChangeRelationship}options={[{value:'Mother',label:'Mother'},{value:'Father',label:'Father'},
                                {value:'Wife',label:'Wife'},{value:'Husband',label:'Husband'},
                                {value:'Sister',label:'Sister'},{value:'Brother',label:'Brother'},
                                {value:'Spouse',label:'Spouse'},{value:'Guardian',label:'Guardian'},
                                {value:'Other',label:'Other'}]} submitted={submitted} className="dropdown-front"/>
                            {submitted && !relationship.value ? <ErrorMessage>Relationship is required</ErrorMessage>:null}
                        </Col>
                    </Form.Group>
                </div> */}
           

            <div>
                <div className="switch-admission-prescription">
                <Label name="Do you want to add a prescription?" className="label-admission-prescription" style={{paddingLeft:'15px'}}/>
                <text className="label-admission-prescription">No</text>
                <Form.Check type="switch" id="custom-switch" defaultChecked={isAddPrescription} onChange={handlePrescriptionToggle} className="toggle-admission-prescription"/>
                <text className="label-admission-prescription">Yes</text>
                </div>
                {isAddPrescription ?
                    <NewPrescriptionTable {...addProps}/>
                : null}
            </div>

            <div>
            <Form.Group as={Row}>
                <Form.Group as={Col}>
                    <FormLabel className="pl-0" name='Admission Notes' isRequired toolTip='Enter Admission Notes Here '/>
                    {submitted && (findings.trim().length === 0) ? <ErrorMessage>Please enter admission notes</ErrorMessage>:null}
                    <ResizableTextarea submitted={submitted}  rows="5" value={findings} name="notes" onChange={handleChangeNotes}/>
                </Form.Group>
            </Form.Group>
            </div>


        <button type="submit" className="btn btn-sm sms-btn px-4" disabled={isPending}
                    data-testid="submitAdmissionForm">
                {isPending ? "Saving..." : "Save"}</button>
    </form>)
}
 
const DoctorAdmission = ({actions, snackbars, match:{params}}) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const {patient_number, visit_id, labrequest_id, scan_request_id, patient_type, patient_admission_id} = params

    // added parameter for the admission notes
    const {admissionProps, closeSnackbar, printDialog, handleClosePrintDialog, handleClick} = useAddAdmission(actions, patient_number, false, params)
    const {patient} = usePatientDetails(actions, patient_number, '')
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    const {first_name, last_name, Age, gender, address, phone_no} = patient;
    const arr = doctorRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type, patient_admission_id)

    const {providerProps, openNewInsurance, handleCloseNewInsurance,isPendingProvider,
        handleSubmitProvider, handleOpenNewInsurance, newProviderProps} =  admissionProps
    return (
        <div className="journals">
            <PageTitle title="Patient Admission"/>
            <SubHeader title="Doctor" subTitle="Patient Admission" doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <PrintDialog message="Admission Form" openDialog={printDialog} handleClose={handleClosePrintDialog}
                             text="Would you like to view and print this admission form" handleClick={handleClick}
                             handleCancel={handleClosePrintDialog}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                <PatientInformation phone={phone_no} gender={gender} age={Age ? Age : ''} patient_name={`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`}
                                    address={address} patient_number={patient_number}/>
                <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type}
                        message={message} duration={3500}/>
                <CustomDialog open={openNewInsurance} handleClose={handleCloseNewInsurance} title='Add Provider' isContentOverflow={true}>
                    <form onSubmit={handleSubmitProvider}>
                        <ProviderForm {...newProviderProps}/>
                        <CenteredContainer>
                            <button type='submit' disabled={isPendingProvider} className='btn btn-sm sms-btn'>{isPendingProvider ? 'Saving...' : 'Save'}</button>
                        </CenteredContainer>
                    </form>
                </CustomDialog>
                <div className="mt-2 px-3">
                    <DoctorAdmissionForm admissionProps={admissionProps} {...{providerProps,handleOpenNewInsurance}}/>
                </div>
            </ReusableDoctorTabs>

        </div>

    )
};

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorAdmission);
