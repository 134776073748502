/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {usePrescriptionReport} from "./hooks/usePrescriptionReport";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import {titleCase} from "../../Users/addUser";
import {dateStringConvert} from "../../Utils/ConvertDate";
import {ItemsTable, PrintTemplate} from "../../Utils/Templates/PrintTemplate";
import {coloredThead, table, tMargin} from "../../../styles/tableStyles";
import * as colors from "../../../styles/colors"
import {usePrint} from "../../Utils/Templates/usePrint";
import Card from "@material-ui/core/Card";

const heading = css`
  padding: 0.2rem;
`

const headingText = css`
    font-weight: 700;
`

const headData = [{key:'drug_name',value:'Drug Name'}, {key:'dosage',value:'Dosage'}, {key:'frequency',value:'Frequency'}, {key:'no_of_days',value:'Number of Days'},
{key:'instructions',value:'Instructions'}]
const PrescriptionReport = ({actions, snackbars, match:{params}}) => {
    const {patient_number, visit_id, labrequest_id, scan_request_id,
        patient_type,patient_admission_id, prescription_id,activity_id}=params
    
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const {openBar, type, message} = snackbars;
    const {prescription, drugs, closeSnackbar, others} = usePrescriptionReport(actions, prescription_id)
    const other_meds = others ? others : []
    const sundries = drugs.filter(d=>  d.category_name === 'Sundries')
    const presc = drugs.filter(d=> d.category_name === 'Medicine')

    const patient_details = !prescription ? {} : prescription;
    const {componentRef, handlePrint,  fontSize}  = usePrint(`Report`)

    const patientDetails = (
        <tbody>
            <tr>
                <td><span>Patient
                    Name: <strong>{titleCase(`${!patient_details.patient_firstname ? "" : patient_details.patient_firstname} 
                            ${!patient_details.patient_lastname ? "" : patient_details.patient_lastname}`)}</strong></span></td>
                <td>
                    <span>Date: <strong>{patient_details.begin_datetime ? dateStringConvert(patient_details.begin_datetime):''}</strong></span></td>
            </tr>
            <tr>
                <td><span>Age: <strong>{patient_details.age}</strong></span></td>
                <td>
                    <span>Address: <strong>{titleCase(!patient_details.address ? "" : patient_details.address)}</strong></span>
                </td>
            </tr>
            <tr>
                <td><span>Gender: <strong>{titleCase(!patient_details.gender ? "" : patient_details.gender)}</strong></span></td>
                <td/>
            </tr>
        </tbody>
    )

    const itemDetails = (
        <ItemsTable {...{headData}}>
            <tbody>
            {presc.length > 0 ? presc.map((drug, index) => (
                <tr key={index}>
                    <td><span>{drug.product_name}</span></td>
                    <td><span>{drug.dosage}</span></td>
                    <td><span>{drug.frequency}</span></td>
                    <td><span>{drug.number_of_days}</span></td>
                    <td><span>{drug.key_information}</span></td>
                </tr>
            )):null}

            {other_meds.map((drug, index) => (
                <tr key={index}>
                    <td><span>{drug.other_medicine}</span></td>
                    <td><span>{drug.dosage}</span></td>
                    <td><span>{drug.frequency}</span></td>
                    <td><span>{drug.number_of_days}</span></td>
                    <td><span>{drug.key_information}</span></td>
                </tr>
            ))}
            {sundries.length > 0 ?  <>
            <tr>
                <td css={[heading,coloredThead]} colSpan={headData.length}><span css={[headingText]}>Sundries</span></td>
            </tr>
            {sundries.map((drug, index) => (

                <tr key={index}>

                    <td><span>{drug.product_name}</span></td>

                </tr>
            ))}
            </>:null}
            </tbody>
        </ItemsTable>
    )
    const footer = (
            <table css={[table, tMargin]}>
                <tr>
                    <td>
                        <span>Prescribed By</span></td>
                    <td align="center">
                        <span>Signature &amp; Stamp </span></td>
                </tr>
                <tr>
                    <td width="350px" ><span
                        className={`doctor-stamp  mt-3 `}
                        id="doctor-stamp"><strong>{doctor_name}</strong></span></td>
                    <td width="350px" align="right" ><span
                        className={`doctor-stamp  mt-3`}
                        id="doctor-stamp"/></td>
                </tr>
            </table>
    )

    return (
        <div data-testid="doc-prescription-report">
            <PageTitle title="Prescription"/>
            <SubHeader title="Doctor" subTitle="Patient Prescription">
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <div className="patient-prescription-report">
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <BackButton to={`/doctorprescription/${patient_number}/${visit_id}/${activity_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}`} text='Doctor Prescription'/>
                <div className="text-right">
                    <button onClick={handlePrint} className="btn sms-amber-btn px-3 mb-2">Print</button>
                </div>
                <Card className='p-3'>
                    <div className="lab-report" ref={componentRef}>
                        <style>
                            {fontSize()}
                        </style>
                        <PrintTemplate title='Patient Prescription' {...{patientDetails,itemDetails, footer}}/>
                    </div>
                </Card>

            </div>

        </div>
    )
};
function mapStateToProps(state) {
    return {snackbars:state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions:{snackbarActions:bindActionCreators(snackbarActions,dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionReport);
