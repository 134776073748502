/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React, {useEffect, useState} from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button,  MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import * as colors from '../../../styles/colors';
import {formatDate} from '../../Utils/ConvertDate';
import Card from '@material-ui/core/Card'
import { LoadingGif } from '../../Utils/Loader';
import {coloredInvoice, textWeight, thUppercase} from "../../../styles/tableStyles";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";


const titleText = css`
    margin: 0;
    color: ${colors.darkBlue001};
    font-size: 15px;
    
`


const borderless = css`
   border-bottom: 0;
`

const tableMargin = css`
    margin-bottom: 0;
`

function BilledInvoicesReceipt({ match}) {
    const {visit_id, provider_id} = match.params;
    const [receipt, setReceipt] = useState({});
    const [items, setItems] = useState([]);
    const [total_amount, setTotalAmount] = useState({total_base_amount:0, total_non_base_amount:0})
    const [status, setStatus] = useState('idle')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [currency, setCurrency] = useState('USD')
    const [currencies, setCurrencies] = useState([])
    const [invoiceItems, setInvoiceItems] = useState([])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.currency_symbol}`, label:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            // logoutErrorMessage(err, null, actions)
        })
    }, []);


    const reduceAmounts = (arr = []) =>{
        return arr.reduce((a, item) => {
            return a + item.amount
        }, 0)
    }

    const convertList = (receiptsArr, ex_rate) =>{
        const arr = receiptsArr.map((item) => {
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0
            return {
                ...item,
                name:item.service_id ? item.service_name : item.product_id ? item.product_name :
                    item.bed_assignment_id ? `${item.ward_name} -> ${item.room_name} -> ${item.bed_number}` : '',
                quantity:item.bed_assignment_id ? `${item.quantity} day${item.quantity > 1 ? 's' : ''}` : item.quantity,
                usd_rate:rate,
                other_currency_rate : ex_rate ? rate / ex_rate : rate,
                usd_total:rate * qty,
                currency_total: (ex_rate ? rate / ex_rate : rate) * qty
            }
        })

        const base_total_amount = reduceAmounts(arr.map(item=>({amount:item.usd_total})))
        const non_base_total_amount = reduceAmounts(arr.map(item=>({amount:item.currency_total})))

        setItems(arr)
        setTotalAmount({total_base_amount: base_total_amount,
            total_non_base_amount: non_base_total_amount})
    }


    useEffect(() => {
        setStatus('pending')
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('provider_id', provider_id);
        axios.post(`${config.smsUrl}/cbilling/view_visit_invoices`,formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const info = !all_data.patient_info ? {} : all_data.patient_info;
            const invoices = all_data.bill ?? [];
            const obj = invoices[0] ? invoices[0] :{};
            const exchange_rate = obj.currency_rate ? obj.currency_rate : 0
            const current_currency = obj.currency_id === 1 ? 'USD' : 'SSP'
            setReceipt({...info, ...obj});
            setExchangeRate(exchange_rate)
            convertList(invoices, exchange_rate)
            setInvoiceItems(invoices)
            setStatus('resolved')
            setCurrency(current_currency)
        }).catch(e => {
            setStatus('rejected')
        })
    }, []);



    //Change currency
    const handleChangeCurrency = (e) =>{
        setCurrency(e.target.value)
        setExchangeRate(0)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setExchangeRate(event.target.value)
        convertList(invoiceItems, +event.target.value)
    }

    const isPending = status === 'pending'
    const isResolved = status === 'resolved'
    const isRejected = status === 'rejected'

    const {total_non_base_amount, total_base_amount} = total_amount


    const isBaseCurrency = currency === 'USD'
    const currency_symbol = !isBaseCurrency && exchangeRate ? currency : 'USD'

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Rate`}, {key:'amount',value:`Amount`}]

    const {componentRef, handlePrint, fontSize}  = usePrint(`InvoiceReceipt${receipt.patient_number}`)
    const patientDetails = (
        <tbody>
        <tr>
            <td css={[textWeight, thUppercase]} ><p css={[titleText]}>Invoice To:</p></td>
            <td css={[textWeight, thUppercase]}><p css={[titleText]}>Invoice Details:</p></td>
        </tr>
        <tr>
            <td ><span>Name: </span> <span css={[textWeight]}>{receipt.billed_to ? receipt.billed_to : ''}</span></td>
            <td ><span>Date: </span> <span css={[textWeight]}>{receipt.date_created ?  formatDate(receipt.date_created ):''}</span></td>

        </tr>
        <tr>
            <td><span>Account Holder: </span> <span css={[textWeight]}>{receipt.account_name}</span></td>
            <td ><span>Patient Name: </span> <span css={[textWeight]}>{`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                ${!receipt.patient_lastname ? "" : receipt.patient_lastname}`}
            </span> </td>
        </tr>
        <tr>
            <td ><span>Member Number:</span> <span css={[textWeight]}>{receipt.card_number}</span></td>
            <td><span>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>
        </tr>
        </tbody>
    )
    const itemDetails = (
        <ItemsTable {...{headData, coloredTableHead:coloredInvoice}}>
            <tbody>
            {items.map((item) => {
                const qty = item.quantity ? item.quantity : 0;
                const rate = item.rate ? item.rate : 0
                return (
                    <tr key={item.bill_id}>
                        <td><span>{item.name}</span></td>
                        <td><span>{item.quantity}</span></td>
                        <td><span>{item.usd_rate}</span></td>
                        <td><span>{item.usd_total}</span></td>
                    </tr>
                )
            })}

            <tr>
                <td  colSpan={headData.length -1} align='right'><span css={[textWeight]}>Total Bill</span></td>
                <td><span css={[textWeight]}>{total_base_amount}</span></td>
            </tr>
            </tbody>
        </ItemsTable>
    )
    return (
        <div>
            <PageTitle title="Invoice"/>
            <SubHeader title="Invoice" subTitle="Invoice">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            {isPending ? <LoadingGif/>:null}
            {isResolved ?
                <MiniCenteredDiv>
                    <BackButton to='/invoicedbills' text='Invoiced Bills' data-testid="billed-invoices-receipt-back-button"/>
                    <RightAlignedContainer>
                        <Button variant='orange' onClick={handlePrint}>Print</Button>
                    </RightAlignedContainer>
                    <Card className='mt-2'>
                        {/*<div style={{padding:'8px 30px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>*/}
                        {/*    <RightAlignedContainer>*/}
                        {/*        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />*/}
                        {/*        { currency !== 'USD' ? <FxRate currency={currency !== 'USD' && currency}  value={exchangeRate}  handleChangeRate={handleChangeExchangeRate} />: null}*/}
                        {/*    </RightAlignedContainer>*/}
                        {/*</div>*/}
                        <div ref={componentRef}  style={{padding:'20px'}} className="requisition-receipt">
                            <style>
                                {fontSize()}
                            </style>
                            <PrintTemplate title='INVOICE' {...{patientDetails,itemDetails, bioBordered:borderless, bioMargin:tableMargin}}/>
                        </div>
                    </Card>
                </MiniCenteredDiv> : null}
            {isRejected ? <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                <p>The server did not return a valid response</p>
            </div>:null}
        </div>
    )
}

export default BilledInvoicesReceipt