import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export const useGeneralLabReport = (props) =>{
    const {patient_number, lab_request_id} = props.match.params;
    const [report, setReport] = useState({});
    const [labTests,setLabTests] = useState([]);

    useEffect(()=>{
        const {actions} = props;
        const formData = new FormData();
        formData.append('labrequest_id', lab_request_id);
        axios.post(`${config.smsUrl}/claboratory/get_patient_lab_report_by_id`, formData).then(res=>{
            const data = res.data;
            const dt = !data ? {} : data;
            const rp = !dt.patient_info ? {} : dt.patient_info;
            const tests = !dt.tests ? [] : dt.tests;
            const arr = tests.map(item=>({...item, open:true}))
            setReport(rp);
            setLabTests(arr)
        }).catch(err=>{
            logoutErrorMessage(err,null, actions)
        })
    },[]);

    const handleOpenItem = (index) =>{
        const openedArr = labTests.map((item, idx)=>{
            if(idx === index){
                return {...item, open:!item.open}
            }else {
                return item
            }

        })
        setLabTests(openedArr)
    }

    const closeSnackbar = () =>{
        const {actions} = props;
        actions.snackbarActions.hideSnackbar()
    };
     return {patient_number, report, labTests, closeSnackbar, lab_request_id, handleOpenItem}
}