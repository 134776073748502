import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {useTheme} from "@material-ui/core/styles";

export function ReferralTextField({value,onChange,dataTest,submitted,labelName,name,htmlFor,id, labelSm="4", colSm="6",
  type,...rest}) {
  return(
      <Form.Group as={Row}  >
        <Label column sm={labelSm} name={labelName} htmlFor={htmlFor} type className="sms-form-label"/>
        <Col sm={colSm}>
          <TextField type={type} name={name} value={value} id={id}  onChange={onChange} dataTest={dataTest} {...rest}/>
          {submitted && !value && (
              <div className="help-block">{labelName} is required</div>
          )}
        </Col>
      </Form.Group>
  )
}

export function ReferralTextInput({value,onChange,dataTest,labelName,name,type,colSm,labelSm, submitted,required, disabled=false}) {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
  return(
      <Form.Group as={Row}  >
        <Form.Label column sm={labelSm}  className="sms-form-label">{labelName}{required && <span className="ml-1 help-block">*</span>}</Form.Label>
        <Col sm={colSm}>
          <Form.Control type={type} className={`borderless-form-control ${palette.type === "dark" && 'dark-borderless-form-control form-control-dark'}`} name={name} value={value}
                        onChange={onChange} dataTest={dataTest} disabled={disabled}/>
            {((submitted && !value) && <div className="help-block">{labelName} is required</div>)}
        </Col>
      </Form.Group>
  )
}
export function ReferralSelectInput({labelName,name,onChange,options,value,dataTest}) {
  return(
      <Form.Group as={Row}>
        <Form.Label column sm="4"  className="sms-form-label">{labelName}</Form.Label>
        <Col sm="6">
          <SelectInput onChange={onChange} name={name} value={value} options={options}
                       defaultOption="Select Department..." dataTest={dataTest}/>
        </Col>
      </Form.Group>
  )
}

export const BorderlessTextInput = ({value,onChange,dataTest,name,type}) =>{
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return(
        <Form.Control type={type} className={`borderless-form-control ${palette.type === "dark" && 'dark-borderless-form-control form-control-dark'}`} name={name} value={value}
                      onChange={onChange} dataTest={dataTest} />
    )
}

export function ReferralTextArea({labelName,submitted,required,onChange,name,value,dataTest,...props}){
  return(
      <Form.Group>
        <Form.Label column sm="4"  className="sms-form-label">{labelName}{required && <span className="ml-1 help-block">*</span>}</Form.Label>
        {/* <Label name={labelName}>{labelName}</Label> */}
        <Textarea onChange={onChange} name={name} value={value} dataTest={dataTest} {...props}/>
        {((submitted && !value) && <div className="help-block">{labelName} is required</div>)}
      </Form.Group>
  )
}

