import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";

export default function useDischargedPatientDetails(props){
    const {actions} = props
    const [details, setDetails] = useState({});
    const componentRef = useRef();
    const { visit_id} = props.match.params;

    useEffect(() => {
      const formData = new FormData();
      formData.append('visit_id', visit_id)
      axios.post(`${config.smsUrl}/cvisit/view_patient_admission_info`, formData).then(res => {
        const data = res.data;
        const all_data = !data ? {} : data;
        setDetails(all_data)
    }).catch(e => {
        logoutErrorMessage(e, null, actions)
    })
    
    }, [visit_id])

    return {componentRef, details, }
    
}