import React, {useEffect, useState} from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { Link } from "react-router-dom";
import { FullScreenDialog } from "../../Utils/Dialogs/FullScreenDialog";
import { Container } from "../../DoctorsModule/doctorStyles";
import {LoadingGif} from "../../Utils/Loader";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import {useReceiveDispatchLabStock} from "./hooks/useReceiveDispatchLabStock";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import { RequisitionForm } from "../../Inventory/stock/AddRequisition";
import {useAddRequisition} from "../../Inventory/stock/hooks/useAddRequisition";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {FormField, useReceiveStock} from "./hooks/useReceiveStock";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import Form from "react-bootstrap/Form"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Label from "../../Utils/FormInputs/Label";
import {useStockAdjustment} from "../../Inventory/stock/hooks/useStockAdjustment";
import {StockAdjustmentUpload} from "../../Inventory/stock/StockAdjustmentUpload";
import {StockAdjustment} from "../../Inventory/stock/StockAdjustment";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'unit', numeric: false, disablePadding: false, label: 'Unit'},
    {id: 'sellPrice', numeric: false, disablePadding: false, label: 'Sell Price'},
    {id: 'purchasePrice', numeric: false, disablePadding: false, label: 'Last Purchase Price'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'},
];

const adjustmentHeadData = [
    {name:'Product', isRequired:true},{name:'Stock', isRequired:false},
    {name:'Quantity', isRequired:true},{name:'Adjustment Type', isRequired:true},
    {name:'Total Stock', isRequired:false},{name:'Action', isRequired:false}
];


const LabStockList = (props) => {
    const {actions} = props

    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [stockList, setList] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [total_count, setCount] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [receive_from, setReceiveFrom] = useState('purchase')

    const isPurchase = receive_from === 'purchase'

    const {receiveStock, isBlocking, setIsBlockDialog, handleResetForm, isResolved} = useReceiveStock({actions, setOpenDialog,to_store:'Labs'})
    const {adjustmentProps, isResolved:isResolvedAdjustment, isCsvResolved} = useStockAdjustment(actions)

    const handleChangeReceiveFrom = (event) =>{
        setReceiveFrom(event.target.value)
    }


    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    }



    const {receiveStorePharmacy,isResolvedDispatch} = useReceiveDispatchLabStock({actions, setOpenDialog,to_store:'Labs'})

    const {requisitionProps, openEmmergency, setOpenEmmergency,
        openDialog:openPrintDialog, handleCancel, handleClick:handleClickPrint,
        handleCloseDialog:handleClosePrintDialog} = useAddRequisition(actions, 'lab')


    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        formData.append('search', searchValue)
        formData.append('page', activePage)
        formData.append('to_store', 'Labs')
        formData.append('category_id', '')
        formData.append('to_destination',2)
        axios.post(`${config.smsUrl}/cpharmacy/store_stock`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const st = !dt.stock ? [] : dt.stock;
            const count = !dt.total_count ? 0 : dt.total_count;
            setCount(count)
            setList(st);
            setLoading('success');
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error');
        })
    }, [activePage,searchValue, isResolvedDispatch, isResolved, isCsvResolved, isResolvedAdjustment]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value)
    };

    const handleChangePage = (page) =>{
        setActivePage(page)
    }



    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleCloseEmergencyDialog = () =>{
        setOpenEmmergency(false)
    }

    const handleOpenEmergencyDialog = () =>{
        setOpenEmmergency(true)
    }

    const {openDialog:openAdjustment, handleOpenDialog:handleOpenAdjustment,
        handleCloseDialog:closeAdjustment, openStockDialog, setOpenStockDialog,
        setOpenDialog:setOpenAdjustment, isCsv, setIsCsv, location} = adjustmentProps

    const uploadStockAdjustment = (
        <StockAdjustmentUpload {...{openStockDialog, actions,setOpenStockDialog,setOpenDialog:setOpenAdjustment,isSubmitted:isCsv,
            setIsSubmitted:setIsCsv, location}}/>
    )

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const stocks = !stockList ? [] : stockList;
    const {openBar, type, message} = props.snackbars;

    return (
        <div data-testid="lab-stock-list" className='journals'>
            <PageTitle title="Lab Stock"/>
            <SubHeader title="Laboratory" subTitle='Lab Stock'>
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>

            <FullScreenDialog open={openEmmergency} handleClose={handleCloseEmergencyDialog} title="Make Requisition">
                <RequisitionForm {...{...requisitionProps, department:'Lab'}}/>
            </FullScreenDialog>

            <FullScreenDialog open={openAdjustment} handleClose={closeAdjustment} title='Adjust Stock'>
                <StockAdjustment {...{...adjustmentProps, adjustmentUpload:uploadStockAdjustment,
                    headData:adjustmentHeadData, fileName:'Stock.csv', user:'Added By'}}/>
            </FullScreenDialog>
            <PrintDialog openDialog={openPrintDialog} handleClose={handleClosePrintDialog} message="Print Requisition Note"
                text="Would you like to print this requisition note" handleClick={handleClickPrint} handleCancel={handleCancel}/>

            <FullScreenDialog open={openDialog} handleClose={handleCloseDialog} title="Receive Stock" maxWidth="lg">
                <FormField>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Label name="Receive From:"  htmlFor="receive_from"/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <SelectInput name='receive_from' value={receive_from} onChange={handleChangeReceiveFrom}
                                         options={[{value:'purchase', text:'Purchase'},
                                             {value:'store_pharmacy', text:'Store'}]} id='receive_from'/>
                        </Col>
                    </Form.Group>
                </FormField>
                {isPurchase ? receiveStock :  receiveStorePharmacy}
            </FullScreenDialog>

            <Container>
                <div className="mui-tables">
                    <RightAlignedContainer>
                        <button type="button" onClick={handleOpenEmergencyDialog} className="btn  btn-sm sms-btn mb-2 small-btn mr-2">Make Requisition</button>
                        <button className='btn btn-sm sms-info-btn mb-2 mr-2' id="receive-store-stock" onClick={handleOpenDialog}>Receive stock</button>
                        <button type="button" onClick={()=>handleOpenAdjustment('Lab')}
                                className="btn sms-gray-btn  small-btn mb-1 btn-sm">Adjust Stock</button>
                    </RightAlignedContainer>
                    <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                    <CustomTable title="Stock List" term={searchValue} records={10} activePage={activePage}
                                 headData={headData} handler={handleSearch} total_count={total_count}
                                 data={stocks} colSpan={5}
                                 handleChangeNextPage={handleChangePage} customPage>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                            </TableRow>: null}
                            {isSuccess ? stocks.length > 0 ?
                                <>
                                    {stocks.slice(0, 10)
                                        .map((item, index) => {
                                            const count = ((activePage - 1) * 10) + index + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'>
                                                    <TableCell>{count}</TableCell>
                                                    <TableCell><Link to={{pathname:`/reports/productlog/lab/${item.product_id}`}}>{item.product_name}{item.country_of_origin? ` - ${item.country_of_origin}`: null}</Link></TableCell>
                                                    {/*<TableCell>{item.generic_name}</TableCell>*/}
                                                    {/*<TableCell>{item.strenght}</TableCell>*/}
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{item.price}</TableCell>

                                                    <TableCell>{item.rate}</TableCell>
                                                    <TableCell>{item.stock}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    <TableRow>
                                        <TableCell colSpan={headData.length} align="right"><strong>Total Count: {total_count}</strong></TableCell>
                                    </TableRow>
                                </>:<TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>: null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>: null}
                        </TableBody>
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(LabStockList);
