import React, { useState } from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {SubHeader} from "../../Containers/SubHeader";
import {faWheelchair} from "@fortawesome/free-solid-svg-icons/faWheelchair";
import CustomTable, { useHiddenColumns } from "../Utils/Tables/CustomTable";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import SimpleMenu from "../Utils/Menu/SimpleMenu";
import MenuItem from '@material-ui/core/MenuItem';
import {Link} from "react-router-dom";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {history} from "../../Helpers/history";
import {titleCase} from "../Users/addUser";
import { usePatientList } from "./CustomHooks/usePatientList";
import { useTheme } from "@material-ui/core/styles";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { PatientForm } from "./PatientForm";
import { RightAlignedContainer, PatientContainer, Container } from "../Utils/styledComponents";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import {LoadingGif} from "../Utils/Loader";
import {CsvDialog} from "../Utils/Dialogs/CsvDialog";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";

const patientListRows = [
    {id: 'id', numeric: false, disablePadding: false, label: 'Sl', hidden:false},
    {id: 'pnumber', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'number', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_number', numeric: false, disablePadding: false, label: 'NOK phone', hidden:false},
    {id: 'address', numeric: false, disablePadding: false, label: 'Address', hidden:false},
    {id: 'gender', numeric: false, disablePadding: false, label: 'Gender', hidden:false},
    {id: 'dob', numeric: false, disablePadding: false, label: 'Age', hidden:false},
    {id: 'bloodgroup', numeric: false, disablePadding: false, label: 'Blood Group', hidden:false},
    // {id: 'provider', numeric: false, disablePadding: false, label: 'Provider', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false},
];

const PatientDetails  = ({actions, snackbars, match:{params}}) => {

    const {data,activePage,patient_id,term,openMenu,handler,handleChangeActivePage,handleOpenSimpleMenu, isRegister,
        handleCloseSimpleMenu,closeSnackbar,handleClick,patientProps,openDialog, handleCloseSimpleOpenDialog,
        handleRegisterPatientButton, handleCloseDialog, uploadProps} = usePatientList(actions)
    const {patients, loading, total_count} = data;
    const all_patients = !patients ? [] : patients;
    const {openBar, type, message} = snackbars;
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;
    const patient_list = !permission.patient_list ? {} : permission.patient_list;
    const {theme} = useTheme();
    const them = theme ? theme : {};
    const palette = them.palette ? them.palette : {};

    const pts = all_patients.map((patient, index) => ({
        ...patient,
        count: ((activePage - 1) * 10) + (index + 1),
        patient_number: patient.patient_number,
        patient_name: `${patient.first_name} ${patient.last_name}`,
        phone_no: patient.phone_no,
        address: patient.address,
        gender: patient.gender,
        age: patient.age,
        blood_type: patient.blood_type ? (patient.blood_type).toUpperCase() : '',
        // provider_name: patient.provider_name
    }))
    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'
    const {component} = params;
    const isReception = component === 'reception';
    const {isSubmitting,p_number, openVisit, handleClickVisit, handleCloseVisit} = patientProps;

    const {handleOpenCsvDialog} = uploadProps
    const {isBlocking, isBlockDialog, setIsBlockDialog,  handleResetForm} = patientProps
   

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(patientListRows);

    return (
        <div id="patient-list">
            <PageTitle title="Patient List"/>
            <SubHeader title="Patient" subTitle="Patient List">
                <FontAwesomeIcon icon={faWheelchair}/>
            </SubHeader>
            <div className="mui-tables">
                <PrintDialog
                    handleClick={handleClickVisit}
                    handleClose={handleCloseVisit}
                    openDialog={openVisit}
                    message="Create Visit"
                    text={`Would you like to create a visit for this patient ${p_number}`}
                    handleCancel={handleCloseVisit}
                />

                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />

                <CustomDialog open={openDialog} handleClose={handleCloseDialog} title={(isRegister === false) ? 'Update Patient Details' : 'Register Patient'}
                              maxWidth="lg" isContentOverflow={false} isPaperStyle={true}>
                    <PatientContainer>
                        <PatientForm {...{...patientProps, isRegister}}>
                            <RightAlignedContainer>
                            <button type="submit" disabled={isSubmitting} 
                            className="btn btn-sm sms-btn">{(isSubmitting && (isRegister === true)) ? 'Registering...' :
                            (isSubmitting && (isRegister === false)) ? 'Updating...' :
                            ((isRegister === false) && !isSubmitting) ? 'Update Patient' : 'Register Patient'}</button>
                            </RightAlignedContainer>
                        </PatientForm>
                    </PatientContainer>
                </CustomDialog>

                <CsvDialog {...{...uploadProps, file:"/csv/patients_edited.csv", title:'Upload Patients'}}/>
                <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>

                <Container>
                {isReception ? <RightAlignedContainer>
                        <div className="mb-2">
                            <button onClick={handleRegisterPatientButton} id="register-patient" className="btn sms-info-btn btn-sm mr-4">Register Patient</button>
                            <button onClick={handleOpenCsvDialog} id="upload-patients" className="btn sms-info-btn btn-sm">Upload Patients
                            </button>
                        </div>
                    </RightAlignedContainer>: null}
                    <CustomTable headData={headCells} title="Patients" data={pts} 
                        term={term} handler={handler} colSpan={patientListRows.length} customPage records={10} total_count={total_count}
                        handleChangeNextPage={handleChangeActivePage} activePage={activePage} searchPlaceHolder="Search Patient Name or Phone Number"
                        handleHeadCells={handleHeadCells} all_hidden={all_hidden} handleAllHeadCells={handleAllHeadCells}
                    >
                    
                            <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={patientListRows.length} align="center">
                                    <LoadingGif/>
                                </TableCell>
                            </TableRow>:null}
                                {isSuccess ? pts.length > 0 ?  pts.slice(0, 10)
                                    .map((patient, index) => {
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return ( 
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={patient.patient_number}
                                                className='mui-table-row'>
                                                <TableCell id={labelId} scope="row" padding="default">
                                                    <span>{patient.count}</span>
                                                </TableCell>
                                                <TableCell hidden={headCells[1].hidden}>
                                                    <span>{patient.patient_number}</span>
                                                </TableCell>
                                                <TableCell
                                                    hidden={headCells[2].hidden}
                                                    onClick={() => handleClick(patient.patient_number)}>
                                                    <span>
                                                        {patient.patient_name}
                                                    </span>
                                                </TableCell>
                                                <TableCell  hidden={headCells[3].hidden}>
                                                    <span>{patient.phone_no}</span>
                                                </TableCell>
                                                <TableCell  hidden={headCells[4].hidden}>
                                                    <span>{patient.kin_phone_no}</span>
                                                </TableCell>

                                                <TableCell hidden={headCells[5].hidden}>
                                                    <span>{titleCase(patient.address)}</span>
                                                </TableCell>
                                                <TableCell hidden={headCells[6].hidden}>
                                                    <span>{titleCase(patient.gender)}</span>
                                                </TableCell>
                                                <TableCell hidden={headCells[7].hidden}>
                                                    <span>{patient.age}</span>
                                                </TableCell>
                                                <TableCell hidden={headCells[8].hidden}>
                                                    {patient.blood_type}
                                                </TableCell>
                                                {/*<TableCell hidden={headCells[9].hidden}>*/}
                                                {/*    {patient.provider_name}*/}
                                                {/*</TableCell>*/}
                                                <TableCell hidden={headCells[9].hidden}>
                                                    {isReception ?  <MoreHorizIcon
                                                        onClick={e => handleOpenSimpleMenu(e, patient.patient_number)}
                                                        data-test="open-menu"/>:
                                                        <button onClick={()=>history.push(`/patient-history/${patient.patient_number}`)}
                                                                type="button" className="btn btn-sm sms-info-btn">View History</button>}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }):<TableRow>
                                    <TableCell colSpan={patientListRows.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                                {isError ? <TableRow>
                                <TableCell colSpan={patientListRows.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>:null}
                            </TableBody>
                    </CustomTable>
                </Container> 

                <SimpleMenu anchorEl={openMenu} handleClose={handleCloseSimpleMenu}>
                    <Link to={{pathname: `/newvisit/${patient_id}`}} style={{
                        textDecoration: "none",
                        color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                    }}><MenuItem className="mui-menu-item">New Visit</MenuItem></Link>
                        <Link to={{pathname: `/admissionform/${patient_id}`}} style={{
                        textDecoration: "none",
                        color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                    }}><MenuItem className="mui-menu-item">New Admission</MenuItem></Link>
                    <Link id='new_surgery' to={{pathname: `/surgeryvisit/${patient_id}`}} style={{
                        textDecoration: "none",
                        color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                    }}><MenuItem className="mui-menu-item">New Surgery</MenuItem></Link>
                    {patient_list.create ? <Link to={{pathname: `/patientProfile/${patient_id}`}}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                                                    }}><MenuItem className="mui-menu-item">View
                        Profile</MenuItem></Link>:null}
                    {patient_list.update ? <MenuItem onClick={()=>handleCloseSimpleOpenDialog(patient_id)} className="mui-menu-item">Edit
                        Profile</MenuItem>:null}
                </SimpleMenu>
            </div>
        </div>
    );
    }


function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
          
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
