import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import axios from 'axios';
import { config } from '../../../Helpers/env';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../../actions/snackbarActions'
import SmsSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import { Container } from '../../Utils/styledComponents';
import { ReusableRouteTabs } from '../../Utils/Dialogs/ReusableTabs';
import { history } from '../../../Helpers/history';
import {extractDate, getActualMonthValue, convDate} from '../../Utils/ConvertDate';
import TextField from '../../Utils/FormInputs/TextField';
import Calendar from "rc-year-calendar";
import styled from "@emotion/styled/macro";
import * as mq from "../../../styles/media-queries";
import { useAccountingPeriod } from './hooks/useAccoutingPeriod';

const StyledTextField = styled(TextField)({
    [mq.medium]:{
      width:'200px'
    },
    [mq.large]:{
        width:'200px'
    },
    [mq.small]:{
        width:'165px'
    },
    borderRadius:'0.7rem', 
});

const formatMonthYear = (date) => {
    return date.getFullYear() + "/" + getActualMonthValue(date.getMonth());
} 

const findEndFinancialDate = (date) => {

    const dateValue = new Date(date);
    let endd = new Date(dateValue.setMonth(dateValue.getMonth() + 12));
    endd = new Date(endd.setDate(endd.getDate() - 1));
    return endd;    
}

const AccountingPeriod = ({ actions, snackbar, isPopup=false, handleRouteChange=null}) => {
    const components = [
        {label: 'Accounting Period', path: '/accounting/setup'},
        {label: 'Suppliers', path: '/accounting/setupledger/suppliers'},
        {label: 'Customers', path: '/accounting/setupledger/customers'},
        {label: 'Products', path: '/accounting/setupproducts'},
        {label: 'Services', path: '/accounting/setupservices'},
    ]

    const {openBar, type, message} = snackbar;

    const {
        handleSubmit, handleClickedDate, submitted, isSubmitted, start_date, end_date, closeSnackbar,
        acc_period_present, isPending, dataSource
    } = useAccountingPeriod({actions, isPopup, handleRouteChange});

    return (
        <div>
            {isPopup ? 
                null : 
                <div className='journals'>
                    <PageTitle title="Accounting Period"/>
                    <SubHeader title="Accounting period" subTitle="Accounting period">
                        <FontAwesomeIcon icon={faMoneyBill}/>
                    </SubHeader>
                </div>
            }

            <SmsSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
            <Container>

                {isPopup ? 
                    null : <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                }

                {acc_period_present ? 
                    <h5 style={{paddingLeft:"15px",paddingTop:"10px", color:'black'}}>Your accounting period is shown below</h5>
                : null}

                <form autoComplete='off' onSubmit={handleSubmit} className="form-responsive">
                    <Form.Group as={Col} xs="4" sm="3" xl="2" className="flex-grow-zero">
                        <Label name='Accounting period'/>
                        <StyledTextField type='text' name='accounting_period'  value="12 months" disabled/> 
                    </Form.Group>
                    <Form.Group as={Col} xs="4" sm="3" xl="2" className="flex-grow-zero">
                        <Label name='Start date'/>
                        <StyledTextField type='text' name='start_date'  value={start_date} disabled/> 
                    </Form.Group>
                    <Form.Group as={Col} xs="4" sm="3" xl="2" className="flex-grow-zero">
                        <Label name='End date'/>
                        <StyledTextField type='text'  name='end_date' value={end_date} disabled/>
                    </Form.Group>
                    {!acc_period_present ?
                        <Form.Group as={Col} xs={2} xl="2" style={{marginTop:'30px'}}>
                            <button disabled={isPending} style={{marginLeft:'16px'}} type='submit' className='btn btn-sm sms-info-btn'>Submit</button>
                        </Form.Group>
                        :
                        null
                    }
                </form>    

                {!acc_period_present && isPending ?
                    <h5 style={{paddingLeft:"15px",paddingTop:"10px", color:'red'}}>Please select the start date for your accounting period</h5>
                    : !acc_period_present && !isPending ?
                    <h5 style={{paddingLeft:"15px",paddingTop:"10px", color:'grey'}}>Please select the start date for your accounting period</h5>
                    : null
                }

                {!acc_period_present ?
                    <div>
                        <Row>
                            <Col>
                                <Calendar displayHeader={true} dataSource={dataSource} onDayClick={(event) => handleClickedDate(event)}/>
                            </Col>             
                        </Row>    
                    </div>
                    :  null
                }

            </Container>

        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingPeriod);