import React from "react";
import {titleCase} from "../../Users/addUser";
import {useTheme} from "@material-ui/core/styles";
import {ItemIcon} from "../../Utils/Lists/CollapsibleList";
import Form from 'react-bootstrap/Form';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../../Utils/FormInputs/Label";
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {usePatientInformation} from "./hooks/usePatientInformation";

export const PatientInformation = ({patient_number, children,title, isResolved=false, ...props}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {patient, handleOpen, handleOpenDialog, open, openDialog} = usePatientInformation(patient_number, isResolved)

    const {patient_name, age, gender, phone, address} = patient
     
    return (
            <div className={`patient-information  ${palette.type === 'dark' && 'dark-patient-information'} `} {...props}>
                <h6 className='patient-personal-details'>{title ? title :  'PERSONAL DETAILS'}</h6>
                <div className="row ">
                    <div className="col-md-4"  style={{margin:20}}>
                        <p>Patient Name: <strong>{titleCase(patient_name)}</strong></p>
                        <p>Age: <strong>{age}</strong></p>
                    </div>
                    <div className="col-md-4">
                        <p>Gender: <strong>{titleCase(gender)}</strong></p>
                        <p  style={{padding:10}}>Phone: <strong>{phone}</strong></p>
                    </div>
                    <div className="col-md-4">
                        <p>Address: <strong>{titleCase(address)}</strong></p>
                    </div>
                    
               </div>
                {children}
                <div className="col-md-4 myclass">
                   <div >
                    <div className="collapse-container">
                      <ItemIcon onClick={handleOpen} >
                          {open ? <ExpandMoreIcon style={{fontSize:'1.1rem', fontWeight:'400'}}/>: <NavigateNextIcon style={{fontSize:'1.1rem', fontWeight:'400'}}/>}
                      </ItemIcon>
                      <span style={{fontSize:'14px',fontWeight:700,paddingLeft:'6px',paddingRight:'6px'}}>Chronic Illness</span>
                    </div>
                   
          
                  <Collapse in={open} timeout="auto" name="Chronic Illness" unmountOnExit>
                  <Row>
                      <Col sm={6}>
                        <div >
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Cardiac Disease"
                              checked={patient?.chronic_illness?.cardiac_disease}
                              name="cardiac_disease"
                  
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Sickle Cell Disease"
                              checked={patient?.chronic_illness?.sickle_cell_disease}
                              name="sickle_cell_disease"
                              
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Kidney Disease"
                              checked={patient?.chronic_illness?.kidney_disease}
                              name="kidney_disease"
                            
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Epilepsy (seizures)"
                              checked={patient?.chronic_illness?.epilepsy}
                              name="epilepsy"
                            
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Hypertension"
                              checked={patient?.chronic_illness?.hypertension}
                              name="hypertension"
                            
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Diabetes"
                              checked={patient?.chronic_illness?.diabetes}
                              name="diabetes"
                            
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="TB"
                              checked={patient?.chronic_illness?.tb}
                              name="tb"
                              
                            />
                          </Col>
                          {patient?.chronic_illness?.other_chronic_illness ?
                          <Col>
                            <Label
                              name="Others"
                              className="label-admission-prescription"
                            />
                            <Col>
                            
                            <p>Other Chronic illness: <strong>{patient?.chronic_illness?.other_chronic_illness}</strong>
                              </p>
                                    
                            </Col>
                          </Col>
                            : null    } 
                        </div>
                      </Col>
                    </Row>
                  </Collapse> 
                </div> 
                <div>
                  <div className="collapse-container">
                    <ItemIcon onClick={handleOpenDialog}>
                        {openDialog ? <ExpandMoreIcon style={{fontSize:'1.1rem', fontWeight:'400'}}/>: <NavigateNextIcon style={{fontSize:'1.1rem', fontWeight:'400'}} />}
                    </ItemIcon>         
                    <span style={{fontSize:'14px',fontWeight:700,paddingLeft:'6px',paddingRight:'6px'}}>Allergies</span>
                  </div>
                    {/* <CollapsibleList name="Allergies"  open={openDialog} handleOpen={()=>{setOpenDialog(!openDialog)}} >
                     */}
                  <Collapse in={openDialog} timeout="auto" name="Allergies" unmountOnExit>
                  <Col sm={6}>
                    <strong>
                      {!patient.allergies ? "" : patient.allergies}
                    </strong>
                  </Col>
                  </Collapse>
                  </div>
                </div>
        </div>
        
    )
};


