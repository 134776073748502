import React from "react";
import {titleCase} from "../Users/addUser";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label, { FormLabel }  from "../Utils/FormInputs/Label";
import ClearIcon from '@material-ui/icons/Clear';
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import TextField from "../Utils/FormInputs/TextField";
import {makeStyles} from '@material-ui/core/styles';
import { ErrorMessage } from "../Utils/styledComponents";
import { CustomInput, CustomResizableTextarea } from "../Inventory/CustomInput";
import { useTheme } from "@emotion/react";

const useStyles = makeStyles(() => ({
    root: {
        width: '.8em',
    }
}));

const Triage = ({
    handleChangeVitals, handleChangeWeight,submitted, handleChange, patientComponent, triageList, 
    handleChangeHeight, bmi, weight_metric_name, height_metric_name, triage,  removeMetric, children,isResolved,setIsBlocking,
    otherMetric, others, handleChangeOthersMetrics, optionalMetrics, handleChangeOptional, chronic_illness, handleMedicalIllness,
                    handleOtherMedicals,other_medicals,handleHasAllergies,allergies,handleChangeAllergies,hasAllergies,
}) => {
    const theme = useTheme();
    const them  = theme ? theme : {};
    const palette = them.palette ? them.palette : {};

    const {cardiac_disease, kidney_disease, hypertension, tb,
        sickle_cell_disease, epilepsy, diabetes, other_medical_illness} = chronic_illness;
    const classes = useStyles()
    return (
        <div style={{width: '100%'}}>
            {patientComponent ? patientComponent : null}
            <div  className="mt-4">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name="Weight(kgs)" htmlFor='weight' isRequired
                                           toolTip="Weight" type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                            <TextField submitted={submitted} type='number' id='weight' value={weight_metric_name} onChange={handleChangeWeight} autoComplete="off"/>
                            {(submitted && !weight_metric_name) &&
                                <ErrorMessage>Weight(kgs) is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name="Height(cms)" htmlFor='height'
                                           toolTip="Enter height" isRequired/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <TextField submitted={submitted} type='number' id='height' value={height_metric_name} onChange={handleChangeHeight} autoComplete="off"/>
                                {(submitted && !height_metric_name) &&
                                 <ErrorMessage>Height(cms) is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name="BMI" htmlFor='bmi'
                                           toolTip="Body mass weight"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <TextField value={bmi} id='bmi' disabled/>
                            </Col>
                        </Form.Group>
                    </div>
                    {triage.map((triage, index) => (<div className="col-md-6" key={index}>
                        <Form.Group as={Row} key={triage.metric_id}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name={titleCase(triage.metric_name)} htmlFor={`triage-metric_value-${index}`}
                                           isRequired={!(triage.metric_id === 6 || triage.metric_id === 5)} toolTip="Temperature"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <TextField submitted={submitted && !(triage.metric_id === 6 || triage.metric_id === 5)} type='number' name={triage.name}
                                            id={`triage-metric_value-${index}`}
                                            onChange={(e) => handleChange(e, index)}
                                            value={triage.metric_value} autoComplete="off"/>
                                   {(submitted && !triage.metric_value && !(triage.metric_id === 6 || triage.metric_id === 5)) &&
                                 <ErrorMessage>Temperature is  required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                    </div>))}
                </div>
                <div className="row">
                    {triageList.map((triage, index) => (<div className="col-md-6" key={index}>
                        <Form.Group as={Row} key={triage.metric_id}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name={titleCase(triage.metric_name)} htmlFor={`triage-metric_name-${index}`}
                                           isRequired={!(triage.metric_id === 6 || triage.metric_id === 5)} toolTip={`Enter ${triage.metric_name}`}/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <TextField submitted={submitted && !(triage.metric_id === 6 || triage.metric_id === 5)} type='text' name={triage.name}
                                            id={`triage-metric_name-${index}`}
                                            onChange={(e) => handleChangeVitals(e, index)}
                                            value={triage.metric_value} autoComplete="off"/>
                                {(submitted && !triage.metric_value && !(triage.metric_id === 6 || triage.metric_id === 5)) &&
                                <ErrorMessage>{`${triage.metric_name} is required`}</ErrorMessage>}
                            </Col>
                        </Form.Group>

                    </div>))}
                </div>
                {optionalMetrics.length > 0 ? <div className="row">
                    {optionalMetrics.map(triage => (<div className="col-md-6" key={triage.metric_id}>
                        <Form.Group as={Row} key={triage.metric_id}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <FormLabel name={titleCase(triage.metric_name)}
                                           toolTip={`Enter ${triage.metric_name}`}/>
                            </Col>
                            <Col xs={10} sm={10} md={10} lg={6} xl={6}>

                                <TextField type='text' name={triage.name} autoComplete="off"
                                        onChange={(e) => handleChangeOptional(e, triage.metric_id)}
                                        value={triage.metric_value}/>
                            </Col>
                            <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                <button type="button" onClick={() => removeMetric(triage.metric_id)}
                                        className='btn btn-sm sms-btn-dismiss'
                                        style={{padding: '0 1px', borderRadius: '50px'}}><ClearIcon
                                    classes={{root: classes.root}}/></button>
                            </Col>
                        </Form.Group>
                    </div>))}
                </div> :  null}
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Label name="Add Metric" htmlFor='add_metric' />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                <CustomSelect value={otherMetric}
                                              onChange={handleChangeOthersMetrics} options={others}
                                              id='addmetric'/>
                            </Col>
                        </Form.Group>
                    </div>
                </div>
                <h6
            className={`visit-heading ${
                palette.type === "dark" && "dark-visit-heading"
            }`}
        >
            <strong>Chronic diseases and allergy information</strong>
        </h6>

        <div className='row'>
            <div className='col-lg-6'>
                <h6>Chronical Diseases</h6>
                <Row>
                    <Col  xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div>

                            <Col>
                                <Form.Check type="checkbox" label="Cardiac Disease" checked={cardiac_disease} name="cardiac_disease" onChange={handleMedicalIllness}/>
                            </Col>
                            <Col>
                                <Form.Check type="checkbox" label="Sickle Cell Disease" checked={sickle_cell_disease} name="sickle_cell_disease" onChange={handleMedicalIllness}/>
                            </Col>
                            <Col>
                                <Form.Check type="checkbox" label="Kidney Disease" checked={kidney_disease} name="kidney_disease" onChange={handleMedicalIllness} />
                            </Col>
                            <Col>
                                <Form.Check type="checkbox" label="Epilepsy (seizures)"  checked={epilepsy} name="epilepsy" onChange={handleMedicalIllness}/>
                            </Col>
                            <Col>
                                <Form.Check type="checkbox" label="Hypertension"  checked={hypertension} name="hypertension" onChange={handleMedicalIllness}/>
                            </Col>
                            <Col>
                                <Form.Check type="checkbox" label="Diabetes"  checked={diabetes} name="diabetes" onChange={handleMedicalIllness}/>
                            </Col>
                            <Col>
                                <Form.Check type="checkbox" label="TB"  checked={tb} name="tb" onChange={handleMedicalIllness}/>
                            </Col>
                            <Col>
                                <Label name="Others(Specify)" className="label-admission-prescription"/>
                            </Col>

                        </div>

                        <Col>
                            <CustomResizableTextarea name='chronic_illness' setIsBlocking={setIsBlocking} rows={2} inputValue={chronic_illness.other_chronic_illness}
                                                     reset={isResolved} id='chronic_illness'/>
                            {/*<ResizableTextarea rows={2} value={chronic_illness.other_chronic_illness} onChange={handleOtherMedicals}/>*/}
                        </Col>
                    </Col>

                </Row>
            </div>
            <div className='col-lg-6'>
                <h6>Allergies</h6>
                <Label name="Do you have any allergies?" className="label-admission-prescription"/>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Form.Check
                        inline
                        type="radio"
                        name="allergy"
                        className="form__label"
                        label="Yes"
                        value="yes"
                        checked={ hasAllergies === true }
                        onChange={handleHasAllergies}
                    />
                    <Form.Check
                        inline
                        type="radio"
                        name="allergy"
                        className="form__label"
                        label="No"
                        value="no"
                        checked={ hasAllergies === false}
                        onChange={handleHasAllergies}
                    />
                    {
                        hasAllergies?

                            <CustomResizableTextarea name='allergies' setIsBlocking={setIsBlocking} rows={2} inputValue={allergies}
                                                     reset={isResolved} id='allergies'/>

                            :null}
                </Col>
            </div>
        </div>
            {children}
        </div>
        </div>
    )
};

export default Triage;
