import {useEffect, useState} from "react";
import {dateConvert,dateStrokeConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {history} from "../../../Helpers/history";

export const useAddAssign = (actions, match={}) => {
    const [state, setState] = useState({assign_date: dateConvert(), discharge_date: '', description: '', status: '1'});
    const [submitted, setSubmitted] = useState(false);
    const [rooms, setRooms] = useState([]);
    const [wards, setWards] = useState([]);
    const [bedList, setBeds] = useState([]);
    const [ward_name, setWard] = useState('');
    const [room_name, setRoom] = useState('');
    const [bed_name, setBed] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [bedAssign, setBedAssign] = useState({})
    const [currencyDetails, setCurrencyDetails] = useState({currency_id:'',currency_symbol:'', cost:0, is_provider:0, is_provided:0})
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [patient_room_id, setPatientRoomId] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const {patient_admission_id, visit_id, patient_name,bed_assignment_id, bill_id} = match;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    useEffect(() => {
        if(!bed_assignment_id){
            return;
        }
        const formData = new FormData();
        formData.append('bed_assignment_id', bed_assignment_id);
        axios.post(`${config.smsUrl}/cbedmanager/bed_assign_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setBedAssign(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [bed_assignment_id]);

    const retrieveWards = (value) =>{
        const formData = new FormData();
        formData.append('ward_id', value);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_room_by_ward_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.room ? [] : dt.room;
            const arr = list.map(rm => ({label: rm.name, value: rm.room_id}))
            setRooms(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const retrieveRooms = (value) =>{
        const formData = new FormData();
        formData.append('room_id', value);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_bed_by_room_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cst = !dt.cost ? {} : dt.cost;
            const ct = !cst.charge ? 0 : cst.charge;
            const list = !dt.bed ? [] : dt.bed;
            const arr = list.map(bd => ({label: bd.bed_number, value: bd.bed_id}))
            //setCost(ct)
            setBeds(arr);
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    useEffect(() => {
        if(!bedAssign.word_name){
            return
        }
        const bd = !bedAssign ? {} : bedAssign;
        const {bed_id, bed_number, ward_id, word_name, room_id, room_name:name, assign_date} = bd;
        setState({assign_date:assign_date ? dateStrokeConvert(assign_date):'',status:'1'});
        setWard({value: ward_id, label: word_name});
        setRoom({value: room_id, label: name});
        setBed({value: bed_id, label: bed_number})
        retrieveWards(ward_id)
        retrieveRooms(room_id)
    }, [bedAssign]);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cbedmanager/getAllWards`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.wards ? [] : dt.wards;
            const arr = list.map(wd => ({label: wd.name, value: wd.ward_id}))
            setWards(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);




    const handleChangeWard = (value) => {
        setWard(value);
        setRoom('')
        setBed('')
        retrieveWards(value.value)
        setIsBlocking(true)

    };

    const handleChangeRoom = (value) => {
        setRoom(value);
        setBed('')
        retrieveRooms(value.value)

        const formData = new FormData();
        formData.append('room_id', value.value);
        axios.post(`${config.smsUrl}/cbedmanager/retrieve_bed_by_room_id`, formData).then(res => {
            const resp = res.data;
            const dt = !resp ? {} : resp;
            axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id: +visit_id, item_id: +value.value, item_type:'room'}).then(data => {
                const resObj = data.data;
                const dataObj = !resObj.data ? {} : resObj.data;
                setCurrencyDetails({...dataObj, currency_symbol: dataObj.currency})
                if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                    setPatientRoomId(value.value)
                    setOpenConfirmation(true)
                }
                const list = !dt.bed ? [] : dt.bed;
                const arr = list.map(bd => ({label: bd.bed_number, value: bd.bed_id}))
                setBeds(arr);
            }).catch(error => {
                errorMessages(error, null, actions)
            });

        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
        setIsBlocking(true)

    };

    const handleCloseConfirmation = () =>{
        if(patient_room_id === room_name?.value){
            setRoom({value:null, label: null})
            setBeds([])
            setCurrencyDetails({...currencyDetails, cost: 0})
        }
        setOpenConfirmation(false)
    }

    const handleBillRoom = () =>{
        setOpenConfirmation(false)
    }


    const handleChangeBed = (value) => {
        setBed(value)
        setIsBlocking(true)
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        setIsBlocking(true)
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const {discharge_date, description} = state;
        const {currency_id, is_provided, is_provider, cost} = currencyDetails
        const addDetails = {rate: cost, quantity: 1, currency_id, is_provided, is_provider}
        const editDetails = {rate: cost, quantity: 1, bill_id, currency_id, is_provided, is_provider}
        const billDetails = bed_assignment_id ? editDetails : addDetails
        const params = {visit_id, patient_admission_id, bill_details: {...billDetails}, bill_type: 1,
            bed_id: bed_name.value, discharge_date: discharge_date, user_roles_id, description: description ? description : ''
        }
        setIsBlocking(false)
        if (ward_name && room_name && bed_name){
            setIsSubmitted('pending')
            const editParams = {...params,bed_assignment_id, bill_id}
            const allParams = bed_assignment_id ? editParams : params
            const url = bed_assignment_id ? 'bed_assign_update' : 'assign_bed'
            axios.post(`${config.smsUrl}/cbedmanager/${url}`, {...allParams}).then(() => {
               actions.snackbarActions.snackSuccess('Bed assigned successfully');
                setIsSubmitted('resolved')
                history.push('/bedassignlist');
                setOpenDialog(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';



    const assignProps = {state, submitted, rooms, wards,bedList, ward_name, room_name, bed_name, currencyDetails, handleChangeRoom,isSubmitted,
        handleChangeWard, handleChangeBed, handleChange,handleSubmit, patient_admission_id, patient_name, isPending, openConfirmation,
    handleCloseConfirmation,handleBillRoom}


    return {assignProps, openDialog, setOpenDialog, isResolved}
}