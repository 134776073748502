import React from "react";
import Form from "react-bootstrap/Form";
import {useTheme} from "@material-ui/core/styles";
import {titleCase} from "../../Users/addUser";
import {useDoctorDiagnosis} from "./hooks/useDoctorDiagnosis";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import { FormLabel, ToolTip } from "../../Utils/FormInputs/Label";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import * as colors from "../../../styles/colors";
import {RightAlignedContainer} from "../../Utils/styledComponents";

// This component was created to create a Diagnosis component that is used in the
// Surgery module when recording details for an ongoing surgery
export const DiagnosisComponent = ({diagProps}) => {
    const {state, isSubmitted,  handleChangeState, handleSubmitNotes, status,
        handleChangeStatus,  allCodes, codes, retrieveAllCodes, removeRow, getDetails,
        diagnosisSubmitted:submitted, showDiagnosis, disease, error, handleContinue, handleReset
    } = diagProps;
    const {notes, isDisabled} = state;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const val = status === 'disease' ? disease :   disease.toUpperCase();
    const isDisease = status === 'disease';
    const is_codes = codes.filter(item => item.diagnosis && item.code);
    return (
        <form autoComplete="off" onSubmit={handleSubmitNotes} className='mt-2'>
        {(submitted && !(notes || disease)) &&
        <div className="alert alert-danger text-center">Please enter notes or diagnosis to proceed</div>}
        <div className="patient-triage mt-1">
            <div className="row">
                <div className="col-md-10 searchable">
                    <Form.Group as={Row}>
                        <Label name="Search by:" sm={2} column/>
                        <Col sm={4}>
                            <Form.Check inline value="disease" onChange={handleChangeStatus}
                                        name="status" label="Disease" type="radio"
                                        checked={status === 'disease'} className="form__label"/>
                            <Form.Check inline value="code" onChange={handleChangeStatus}
                                        name="status" label="ICD code" type="radio" className="form__label"/>
                        </Col>
                        <Col sm={5}>
                            <Form.Control value={disease ? val : ''} type="text" disabled={isDisabled}
                                          name="disease_name" data-testid="disease_name"
                                          className={`form__control  ${palette.type === "dark" && 'form-control-dark'}`}
                                          onChange={retrieveAllCodes}
                                          placeholder={`${status === 'disease' ? 'Enter keyword' : 'Enter ICD Code'}`}/>
                            {error && <div
                                className="help-block">{`Please enter a valid ${status === 'disease' ? 'keyword' : 'code'}`}</div>}
                            {showDiagnosis &&
                            <Form.Control as="select" className="drug-ul diagnosis-ul" multiple>
                                {allCodes.map((val, idx) =>{
                                    console.log(val.code)
                                    return (
                                    <option key={idx} value={val.code} className="drug-li"
                                            data-testid="lab-tests"
                                            onClick={() => getDetails({
                                                value: val.code,
                                                innerText: val.diagnosis
                                            })}>
                                        {val.code ?  `${val.code} - ${titleCase(val.diagnosis)}` : val.diagnosis}</option>
                                )})}
                            </Form.Control>}
                        </Col>
                        <Col sm={1}>
                            <ToolTip toolTip={isDisease ? 'Enter disease name according to the ICD Standard' : 'Enter ICD code'}/>
                        </Col>

                    </Form.Group>
                </div>
            </div>
            <div className="table-responsive">
                <table
                    className={`table table-sm table-bordered billing-invoice-table procedure-table ${palette.type === 'dark' &&
                    'dark-table dark-table-bordered'}`}>
                    <thead>
                    <th className={palette.type === 'dark' && 'dark-theme-color'}>Disease</th>
                    <th className={palette.type === 'dark' && 'dark-theme-color'}>ICD code</th>
                    <th width="80px">Action</th>
                    </thead>
                    <tbody>
                    {codes.filter(item=>item.code).map((test, indx) => (
                        <tr key={indx}>
                            <td>{titleCase(test.diagnosis)}</td>
                            <td className={palette.type === 'dark' && 'dark-theme-color'}>{test.code}</td>
                            <td>
                                <div className="btn-group">
                                    {!isDisabled
                                        ?
                                        <button onClick={() => removeRow(indx)} type="button"
                                            className="btn btn-sm sms-btn-dismiss"
                                            data-testid="remove-test-name">
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                        : null
                                    }
                                    
                                </div>
                            </td>
                        </tr>
                    ))}

                    </tbody>
                </table>
            </div>
            <Form.Group>
                <FormLabel className="pl-0" name='Diagnosis Notes' isRequired  toolTip='Capture additional diagnosis notes here'/>
                <ResizableTextarea   value={notes} name="notes"
                              onChange={handleChangeState} disabled={isDisabled}
                              id="notes"
                />
            </Form.Group>
        </div>
        {(notes || (is_codes.length > 0)) && isDisabled ? <>
            <button type="button" className="btn sms-info-btn mt-2 ml-3" onClick={handleContinue}>Continue</button>
            {/*<button type="button" onClick={handleReset} 
                    className="btn sms-btn mt-2 ml-3"> Add New Notes</button>*/}</>:
            <button type="submit" disabled={isSubmitted === 'pending'}
                        className="btn sms-btn mt-2 ml-3">{isSubmitted === 'pending' ? "Saving" : "Save Notes"}</button>
        }
        </form>
    )
}

const NewDiagnosis = ({actions,  match}) => {
    const {patient_number, visit_id} = match
    const {state, isSubmitted,  handleChangeState, handleSubmitNotes, status,
        handleChangeStatus,  allCodes, codes, retrieveAllCodes, removeRow, getDetails,
        submitted, showDiagnosis, disease, error, handleContinue,
        isNew, handleCreateNewDisease, handleAddNewDiagnosis, cancelNewDiagnosis
    } = useDoctorDiagnosis(patient_number, actions, visit_id)
    const {notes, isDisabled} = state;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const val = status === 'disease' ? disease :   disease.toUpperCase();
    const isDisease = status === 'disease';
    const is_codes = codes.filter(item => item.diagnosis && item.code);
    const isMissingDisease = allCodes.filter(item=>item.code === '')
    const newDiagnosis = (
        <div className='mt-2'>
            <ResizableTextarea value={disease} rows={2} onChange={retrieveAllCodes}/>
            <RightAlignedContainer>
                <button type='button' onClick={handleAddNewDiagnosis} className='btn btn-sm small-btn sms-info-btn mr-2 mt-2'>Add</button>
                <button type='button' onClick={cancelNewDiagnosis} className='btn btn-sm small-btn sms-gray-btn mr-2 mt-2'>Cancel</button>
            </RightAlignedContainer>
        </div>
    )

    const newDisease = (
        <ul>
            {allCodes.map((val, idx) => {
                const missingDisease = (
                    <p style={{margin:'0.65rem 0'}}>No results found for <strong>{`"${disease}"`}</strong>
                        <span style={{display:'inline-block', textDecoration:'underline', color:colors.blue, marginLeft:'5px'}}>Create {disease}</span></p>
                )
                return(
                    <li style={{listStyle:'none'}} key={idx} value={val.code} className="drug-li"
                        data-testid="lab-tests"
                        onClick={handleCreateNewDisease}>{missingDisease}</li>

                )})}
        </ul>
    )
    return (
        <form autoComplete="off" onSubmit={handleSubmitNotes} className='mt-2'>
            {(submitted && !(notes || disease)) &&
            <div className="alert alert-danger text-center">Please enter notes or diagnosis to proceed</div>}
            <div className="patient-triage mt-1">
                <div className="row">
                    <div className="col-md-10 searchable">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label name="Search by:"/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                <Form.Check inline value="disease" onChange={handleChangeStatus}
                                            name="status" label="Disease" type="radio"
                                            checked={status === 'disease'} className="form__label"/>
                                <Form.Check inline value="code" onChange={handleChangeStatus}
                                            name="status" label="ICD code" type="radio" className="form__label"/>
                            </Col>
                            <Col xs={11} sm={11} md={11} lg={5} xl={5}>
                                {isNew ? newDiagnosis : <>
                                <Form.Control value={disease ? val : ''} type="text" disabled={isDisabled}
                                              name="disease_name" data-testid="disease_name"
                                              className={`form__control  ${palette.type === "dark" && 'form-control-dark'}`}
                                              onChange={retrieveAllCodes}
                                              placeholder={`${status === 'disease' ? 'Enter keyword' : 'Enter ICD Code'}`}/>
                                {error && <div
                                    className="help-block">{`Please enter a valid ${status === 'disease' ? 'keyword' : 'code'}`}</div>}
                                </>}
                                {showDiagnosis &&
                                    <>
                                        {isMissingDisease.length > 0 ?
                                            newDisease
                                           : <Form.Control as="select" className="drug-ul diagnosis-ul" multiple>
                                                {allCodes.map((val, idx) => {
                                                    return(
                                                            <option key={idx} value={val.code} className="drug-li"
                                                                    data-testid="lab-tests"
                                                                    onClick={() => getDetails({
                                                                        value: val.code,
                                                                        innerText: val.diagnosis
                                                                    })}>{`${val.code} - ${titleCase(val.diagnosis)}`}</option>
                                                    )})}
                                            </Form.Control>
                                        }
                                    </>}

                            </Col>
                            {!isNew ? <Col xs={1} sm={1} md={1} lg={1} xl={1}>
                                <ToolTip toolTip={isDisease ? 'Enter disease name according to the ICD Standard' : 'Enter ICD code'}/>
                            </Col>: null}

                        </Form.Group>
                    </div>
                </div>
                <div className="table-responsive">
                    <table
                        className={`table table-sm table-bordered billing-invoice-table procedure-table ${palette.type === 'dark' &&
                        'dark-table dark-table-bordered'}`}>
                        <thead>
                        <th className={palette.type === 'dark' && 'dark-theme-color'}>Disease</th>
                        <th className={palette.type === 'dark' && 'dark-theme-color'}>ICD code</th>
                        <th width="80px">Action</th>
                        </thead>
                        <tbody>
                        {codes.filter(item=>item.diagnosis).map((test, indx) => (
                            <tr key={indx}>
                                <td>{titleCase(test.diagnosis)}</td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}>{test.code}</td>
                                <td>
                                    <div className="btn-group">
                                        {!isDisabled
                                            ?
                                            <button onClick={() => removeRow(indx)} type="button"
                                                className="btn btn-sm sms-btn-dismiss"
                                                data-testid="remove-test-name">
                                                <FontAwesomeIcon icon={faTrash}/>
                                            </button>
                                            : null
                                        }
                                        
                                    </div>
                                </td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </div>
                <Form.Group>
                    <FormLabel className="pl-0" name='Diagnosis Notes' isRequired  toolTip='Capture additional diagnosis notes here'/>
                    <ResizableTextarea   value={notes} name="notes"
                                  onChange={handleChangeState} disabled={isDisabled}
                                  id="notes"
                    />
                </Form.Group>
            </div>
            {(notes || (is_codes.length > 0)) && isDisabled ? <>
                <button type="button" className="btn sms-info-btn mt-2 ml-3" onClick={handleContinue}>Continue</button>
                {/*<button type="button" onClick={handleReset} 
                        className="btn sms-btn mt-2 ml-3"> Add New Notes</button>*/}</>:
                <button type="submit" disabled={isSubmitted === 'pending'}
                            className="btn sms-btn mt-2 ml-3">{isSubmitted === 'pending' ? "Saving" : "Save Notes"}</button>
            }
        </form>

    )
};


export default NewDiagnosis;
