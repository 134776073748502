/** @jsxImportSource @emotion/react */
import React from "react";
import {HospitalAddress} from "../HospitalAddress";
import {
    table,
    textWeight,
    borderedCell,
    smallTable,
    tableMargin,
    detailsTable,
    borderlessTableFooter,
    thUppercase,
    coloredHead,
    coloredThead,
    heading,
    paddingCell,
    tableBordered
} from '../../../styles/tableStyles'

const PrintTemplate = ({title,patientDetails,patientNotes, guardianDetails,itemDetails, footer, dischargeDetails,
    admissionNotes=null,prescriptionDetails=null, bioBordered=borderedCell, bioMargin=tableMargin}) => {
    return (
        <div className='table-responsive'>
            <table css={[table]}>
                <thead>
                    <tr>
                        <td>
                        <HospitalAddress/>
                        <div className="page-header-space"></div>
                        </td>
                    </tr>
                </thead>

          <table css={[table, smallTable]}>
            <tbody>
              <tr>
                <td css={[textWeight, coloredHead, thUppercase]} align="center">
                  <p css={[heading]}>
                    <strong>{title}</strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table
            css={[
              table,
              detailsTable,
              bioBordered,
              smallTable,
              paddingCell,
              bioMargin,
            ]}
          >
            {patientDetails}
          </table>
          {guardianDetails}
          {dischargeDetails}
          {admissionNotes}
          {itemDetails}
          {patientNotes}
          {prescriptionDetails}
          <tfoot css={[borderlessTableFooter]} className="mpeke-footer">
            <tr>
              <td className=" mpeke-footer text-small mpeke-footer-text">
                Powered by Mpeke HMIS Marascientific&copy;
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
};

function ItemsTable({children,headData=[],coloredTableHead= coloredThead,testId=""}) {
    return(
        <table css={[table,detailsTable, tableMargin, tableBordered]} data-testid={testId}>
        <thead>
            <tr>
            {headData.map((item, index)=>(
                
                <th css={[textWeight]} key={index}><span><strong>{item.value}</strong></span></th>
            ))}
            </tr>
        </thead>
        {children}
    </table>
    )
}

export {PrintTemplate,ItemsTable}

