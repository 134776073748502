import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {useFileUploader} from "../../../PatientRecords/CustomHooks/useFileUploader";

const useInvoiceProviders = (actions, isCreateInvoice=false) =>{
    const providerTypes = [
        // {value:'Insurance Company', text:'Insurance Company'},
        {value:'Individuals', text:'Individuals'},
        {value:'Organisation', text:'Organisation'},
    ];
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [state, setState] = useState({provider_name:'', provider_type:''});
    const [providers, setProviders] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [contact, setContact] = useState('');
    const [debitLedger, setDebitLedger] = useState('')
    const [creditLedger, setCreditLedger] = useState('')
    const [accounts, setAccounts] = useState([]);
    const [providerOptions, setProviderOptions] = useState([]);
    const [isEditProvider, setEditProvider] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const obj = {url:'cbilling/insurance_providers/upload_bulk', download_url:'cbilling/provider_csv', file_name:'Providers'}
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)
    const formRef = useRef()

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const [currency, setCurrency] = useState('');
    const [currencies, setCurrencies] = useState([]); 



    useEffect(() => {
        if(!isCreateInvoice){
            return
        }
        axios.get(`${config.smsUrl}/cbilling/insurance_providers/list`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            setProviderOptions(arr);
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
        })
    }, [isCreateInvoice, isResolved]);
    

    useEffect(() => {
        if(isCreateInvoice){
            return
        }
        setLoading('pending');
        axios.get(`${config.smsUrl}/cbilling/insurance_providers`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            setProviders(arr);
            setLoading('success')
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
            setLoading('error')
        })
    }, [isCreateInvoice, isResolved, isResolvedUpload]);

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const handleChangeDebitLedger = (value) =>{
        setDebitLedger(value)
        setIsBlocking(true)
    }

    const handleChangeCreditLedger = (value) =>{
        setCreditLedger(value)
        setIsBlocking(true)
    }

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeCurrency = (event) => {
        setCurrency(event.target.value)
        setIsBlocking(true)
    }

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value});
        setIsBlocking(true)
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpenDialog(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        const {provider_name, provider_type} = state;
        setSubmitted(true)
        const credit = creditLedger ? creditLedger : {};
        const credit_account = credit.value ? credit.value : '';
        const currency_id = parseInt(currency.split('-')[0]);
        const debit = debitLedger ? debitLedger : {};
        const debit_account = debit.value ? debit.value : '';
        setIsBlocking(false)
        if(provider_name && provider_type){
            setIsSubmitted('pending')
            const formData = new FormData(formRef.current);
            formData.append('contact', contact ? contact : '');
            formData.append('provider_name', provider_name);
            formData.append('provider_category', provider_type);
            formData.append('credit_account', credit_account);
            formData.append('debit_account', debit_account);
            formData.append('currency_id', 3);
            axios.post(`${config.smsUrl}/cbilling/insurance_providers/add`, formData).then(()=>{
                actions.snackbarActions.snackSuccess('Provider added successfully')
                setIsSubmitted('resolved')
                setOpenDialog(false)
                setState({provider_name:'', provider_type:''})
                setSubmitted(false)
                setCurrency('')
                setContact(undefined)
            }).catch(err=>{
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }

    const handleResetForm = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setState({provider_name:'', provider_type:''})
        setSubmitted(false)
        setCurrency('')
        setContact(undefined)
    }

    const handleUpdateProvider = () => {
        // api call to save the provider
        setState({provider_name:'', provider_type:''});
        setContact('');
        handleCloseDialog();
    }

    const handleEditProvider = (item) => {
        setState(item);
        setContact(item.contact);
        setEditProvider(true);
        handleOpenDialog();
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const providerProps = {
        state, handleChange, handleSubmit, contact, setContact, isPending, isRejected, isResolved, handleChangeCurrency,
        providerTypes, submitted,  creditLedger, debitLedger, accounts, currencies, currency,
        handleChangeCreditLedger, handleChangeDebitLedger, handleUpdateProvider, isEditProvider,
        isBlocking,isBlockDialog,setOpenDialog,setIsBlockDialog,handleResetForm
    }

    return {providerProps,providers,searchValue, page, rowsPerPage,isLoading, isEditProvider,
        isSuccess, isError,openDialog,providerOptions,handleSearch, handleChangePage, handleEditProvider,
        handleChangeRowsPerPage, closeSnackbar, handleOpenDialog, handleCloseDialog, uploadProps}
}
export {useInvoiceProviders}