import React from "react";
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors"



const P = styled.p({
    margin:0,
    fontSize:'15px',
  })
  
  
const StyledUser = styled(P)({
    borderRight: `0.73800128698349px solid ${colors.muted001}`,
    ':last-of-type':{
      borderRight:0
    },
    padding:'0 7px'
  })

const StyledContainer = styled.div({
    display:'flex'
})

export const PatientDetails = ({patient_number, patient_name, age, gender}) =>{
      return(
        <StyledContainer>
            <StyledUser>Patient No: <strong>{patient_number}</strong></StyledUser>
            <StyledUser>Patient Name: <strong>{patient_name}</strong></StyledUser>
            {age ? <StyledUser>Age: <strong>{age ? age : ''}</strong></StyledUser>:null}
            {gender ? <StyledUser>Gender: <strong>{gender ? gender : ''}</strong></StyledUser>:null}
      </StyledContainer>
      )
  }


