import React, {useEffect} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {Container} from "../../Utils/styledComponents"
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {CustomTableCell, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../Utils/Loader";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { CustomTablePagination, EnhancedSearchTableToolbar } from "../../Utils/Tables/CustomTable";


const headData = [
    {id: 'no', numeric: false, disablePadding: false, label: 'No.'},
    {id: 'customer_name', numeric: false, disablePadding: false, label: 'Customer Name'},
    {id: 'total_debt', numeric: false, disablePadding: false, label: 'Total Debt'},
];

const AccountReceivables = ({actions, snackbars, match:{params}}) => {
    const [data, setData] = React.useState({debtors:[], loading:'idle'});
    const [state, setState] = React.useState({start_date:'', end_date:''});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [submitted, setSubmitted] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = React.useState('idle');
    const {debtors, loading} = data
    const {component} = params
    const isCustomer = component === 'customer';
    const [activePage, setActivePage] = React.useState(1);
    const [term, setTerm] = React.useState('');
    const {start_date, end_date} = state

    const isResolved = isSubmitted === 'resolved';

    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/cbilling/debtors`, {
            params: {
                page:activePage,
                search:term
        }}).then(res => {
            const response = res.data;
            const arr = response ? response : [];
            const debt_arr = arr.length > 0 ? arr[0].provider_debt : []; 
            setData({debtors: debt_arr, loading: 'success'})
        }).catch(err => {
            errorMessages(err, null, actions);
            setData({...data, loading: 'error'});
        })
    }, [isResolved, activePage, term])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setActivePage(newPage+1);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        setActivePage(1);
    };

    const handleChange = (event) =>{
        const {value, name} = event.target;
        setState({...state,[name]:value})
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };

    const handleSubmit = (event) =>{
        event.preventDefault()
        setSubmitted(true)
        if (start_date && end_date){
            setIsSubmitted('resolved')
        }
    }

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'

    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, debtors.length - page * rowsPerPage);

    const handler = (event) => {
        setTerm(event.target.value)
    }

    const pagination = (
        <CustomTablePagination
            colSpan={3}
            activePage={activePage}
            page={page}
            rowsPerPage={rowsPerPage}
            count={debtors}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );

    const search_bar = (
        // <RightAlignedContainer>
            <EnhancedSearchTableToolbar
            term={term} handler={handler}
            />
        // </RightAlignedContainer>
    )

    return (
        <div>
            <PageTitle title="Customer Receivables"/>
            <SubHeader title="Reports" subTitle="Customer Receivables">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <Container>
                {!isCustomer ?
                    <div className='mb-3'>
                        <BackButton to='/reports/generalreports' text='General Reports'/>
                    </div>:null}

                {/* <div className="general-ledger-header mb-3">
                    <Grid container spacing={0}>
                        <Grid item xs={6} md={8}>
                            <form onSubmit={handleSubmit}>
                                <Form.Group as={Row} style={{marginBottom: '-1%'}}>
                                    <Col sm={4}>
                                        <Form.Group as={Row}>

                                            <Label name='From' column sm={2} style={{textAlign: 'right'}}/>
                                            <Col sm={10}>
                                                <TextField type='date' value={from_date} onChange={handleChange}
                                                           name='from_date' submitted={submitted}/>
                                                {submitted && !from_date ? <div className='help-block'>From date is required</div>:null}
                                            </Col>
                                        </Form.Group>
                                    </Col>

                                    <Col sm={4}>
                                        <Form.Group as={Row}>
                                            <Label name='To' column sm={2}/>
                                            <Col sm={10}>
                                                <TextField type='date' name="to_date" value={to_date}
                                                           onChange={handleChange} submitted={submitted}/>
                                                {submitted && !to_date ? <div className='help-block'>To date is required</div>:null}
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                        <button type='submit' className='btn btn-sm pharmacy-btn-outline'>Filter</button>
                                    </Col>
                                </Form.Group>
                            </form>
                        </Grid>

                        <Grid item xs={6} md={4}>
                        </Grid>
                    </Grid>
                </div> */}
                <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
                <StickyHeadTable {...{width:'100%',data:debtors, columns:headData.filter(item=>Boolean(item)), pagination, search_bar}}>
                    {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                    {isSuccess ? debtors.length > 0 ?
                        <>
                            {debtors.map((row, index) => {
                                const count = ((activePage - 1) * 10) + index + 1;
                                return (
                                    <TableRow  tabIndex={-1} key={index}>
                                        <CustomTableCell>{count}</CustomTableCell>
                                        <CustomTableCell><Link to={{pathname:`/invoicereportdetails/${row.customer_id}/${row.provider_name}`}}>
                                            {row.provider_name}</Link></CustomTableCell>
                                        <CustomTableCell>{row.total}</CustomTableCell>
                                        {/* <CustomTableCell>{row.amount_paid}</CustomTableCell>
                                        <CustomTableCell>{row.total_debt - row.amount_paid}</CustomTableCell> */}
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 43 * emptyRows }}>
                                    <TableCell colSpan={headData.length} />
                                </TableRow>
                            )}
                        </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                    {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                </StickyHeadTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountReceivables);
