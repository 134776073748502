import React, {useEffect, useState} from 'react';
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import CustomTable, {useHiddenColumns} from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import * as billingActions from "../../../actions/BillingActions/BillingActions";
import {connect} from "react-redux";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useTheme} from '@material-ui/core';
import PageTitle from '../../Utils/smsTitle';
import {SubHeader} from '../../../Containers/SubHeader';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCoins} from '@fortawesome/free-solid-svg-icons/faCoins';
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import { formatDateTime} from "../../Utils/ConvertDate";
import {titleCase} from "../../Users/addUser";

const billingHeadData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient Number', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'patient_type', numeric: false, disablePadding: false, label: 'Patient Type', hidden:false},
    {id: 'provider_name', numeric: false, disablePadding: false, label: 'Provider Name', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false}
];

const PaidInvoices = (props) => {
    const {actions} = props;
    const theme = useTheme();
    const {palette} = theme;
    const {openBar, type, message} = props.snackbars;
    const [term, setTerm] = useState('')
    const [activePage, setActivePage] = useState(1)
    const [data, setData] = useState({billingList:[], total_count:0, status:'idle'})
    const {billingList, total_count, status} = data


    useEffect(() => {
        const formData = new FormData();
        const search = term ? titleCase(term) : ''
        formData.append('search', search);
        setData({...data, status: 'loading'})
        axios.post(`${config.smsUrl}/cbilling/list_of_invoiced_visit/${activePage}`, formData).then(res => {
            const data = res.data;
            const bills = !data ? {} : data;
            const all_bills = !bills.invoiced_visits ? [] : bills.invoiced_visits;
            const t_count = !bills.total_count ? 0 : bills.total_count;
            setData({...data, status: 'success', billingList: all_bills, total_count: t_count})
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
            setData({...data, status: 'error'})
        })
    }, [activePage, term])


    const handler = (event) => {
        setTerm(event.target.value)
    }

    const handleChangeActivePage = (val) => {
        setActivePage(val)
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const components = [{label: 'Out-patient', path: '/paymentList'},
        {label: 'In-patient', path: '/patientbillinglist'},
        {label: 'Invoiced Bills', path: '/invoicedbills'},
      
    ]

    const isLoading = status === 'loading'
    const isSuccess = status === 'success'
    const isError = status === 'error'

    const all_bills = billingList.map((bill, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...bill,
            count,
            date:formatDateTime(bill.begin_datetime),
            patient_name: titleCase(`${!bill.patient_firstname ? "" : bill.patient_firstname} 
             ${!bill.patient_lastname ? "" : bill.patient_lastname}`),
            patient_type:bill.patient_type===1 ? 'Out patient':'In patient',
            action: (
                <>
                    <Link to={{pathname: `/billedinvoice/${bill.visit_id}/${bill.patient_type}/${bill.insurance_id}`}}
                          style={{
                              textDecoration: "none",
                              color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                          }}>
                        <button className="btn btn-sm sms-info-btn mr-3">View
                        </button>
                    </Link>

                    <Link to={{pathname: `/reverseinvoice/${bill.visit_id}/${bill.patient_type}/${bill.insurance_id}`}}
                          style={{
                              textDecoration: "none",
                              color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                          }}>
                        <button className="btn btn-sm sms-btn mr-1">Reverse
                        </button>
                    </Link>
                </>
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(billingHeadData);
    return (
        <div className='journals'>
            <PageTitle title="Debtors"/>
            <SubHeader title="Billing" subTitle="Debtors">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
                <MainSnackbar handleCloseBar={closeSnackBar} message={message} open={openBar} variant={type}/>
                <CustomTable title="" colSpan={headCells.length}
                             data={all_bills} {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                             headData={headCells}
                             handler={handler} activePage={activePage}
                             handleChangeNextPage={handleChangeActivePage} total_count={total_count}
                             records={10} customPage term={term}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">
                                    <img src="/images/smsloader.gif"
                                         alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? all_bills.length > 0 ?  all_bills.slice(0, 10)
                                .map((bill, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={bill.patient_number}
                                            className='mui-table-row'>
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{bill[head.id]}</TableCell>
                                            ))}
                                            {/*<TableCell>{count}</TableCell>*/}
                                            {/*<TableCell component="th" id={labelId} scope="row"*/}
                                            {/*           padding="default"*/}
                                            {/*           className='mui-table-cell'>*/}
                                            {/*    <span>{formatDate(bill.begin_datetime)}</span>*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell>*/}
                                            {/*    <span>{bill.patient_number}</span>*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell>*/}
                                            {/*    <span>{`${bill.patient_firstname ? bill.patient_firstname : ''} ${bill.patient_lastname ? bill.patient_lastname : ''}`}</span>*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell>{bill.phone_no}</TableCell>*/}
                                            {/*<TableCell>{bill.kin_phone_no}</TableCell>*/}
                                            {/*<TableCell>*/}
                                            {/*    <span>{bill.patient_type===1 ? 'Out patient':'In patient'}</span>*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell>*/}
                                            {/*    <span>{bill.provider_name}</span>*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell>*/}
                                            {/*    <Link to={{pathname: `/billedinvoice/${bill.visit_id}/${bill.patient_type}/${bill.insurance_id}`}}*/}
                                            {/*          style={{*/}
                                            {/*              textDecoration: "none",*/}
                                            {/*              color: palette.type === "dark" ? "#fff" : "#3d3d3d"*/}
                                            {/*          }}>*/}
                                            {/*        <button className="btn btn-sm sms-info-btn mr-3">View*/}
                                            {/*        </button>*/}
                                            {/*    </Link>*/}

                                            {/*    <Link to={{pathname: `/reverseinvoice/${bill.visit_id}/${bill.patient_type}/${bill.insurance_id}`}}*/}
                                            {/*          style={{*/}
                                            {/*              textDecoration: "none",*/}
                                            {/*              color: palette.type === "dark" ? "#fff" : "#3d3d3d"*/}
                                            {/*          }}>*/}
                                            {/*        <button className="btn btn-sm sms-btn mr-1">Reverse*/}
                                            {/*        </button>*/}
                                            {/*    </Link>*/}
                                            {/*</TableCell>*/}
                                        </TableRow>
                                    );
                                }) :<TableRow>

                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>

                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> :null}

                        </TableBody>
                </CustomTable>

            </div>
        </div>
    )
}

function mapStateToProps(state) {
    const {snackbar, billing} = state
    return {
        snackbars: snackbar, billing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            billingActions: bindActionCreators(billingActions, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaidInvoices)