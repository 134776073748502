import React, {useState, useEffect} from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import {useRefundReceipt} from "./hooks/useRefundReceipt";
import * as colors from '../../../styles/colors';
import { formatDateTime } from '../../Utils/ConvertDate';
import { titleCase } from '../../Users/addUser';
import Card from '@material-ui/core/Card'
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import axios from 'axios';
import { config } from "../../../Helpers/env";
import { history } from '../../../Helpers/history';
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import Form from "react-bootstrap/Form";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";

const RefundReceipt = (props) => {

    const {visit_id, active_page, patient_number} = props.match.params;
    const {state,totals, handleChangeCurrency, exchangeRate,
        handleChangeExchangeRate, currency, currencies} = useRefundReceipt(visit_id, active_page)

    const {currency_refund_amount, currency_amount_paid, total_refund, amount_paid} = totals

    const [personalDetails, setPatientDetails] = useState({});

    useEffect(() => {
      const formD = new FormData();
      formD.append('patient_number', patient_number);
      
      axios.post(`${config.smsUrl}/cdoctor/get_patient_details`, formD).then(res => {
          const data = res.data;
          const getPatientDetails = !data ? {} : data;
          setPatientDetails(getPatientDetails);
      }).catch(err => {
          logoutErrorMessage(err, null);
      })
    }, [patient_number]);

  const handleOnAfterPrint = () => {
    history.push('/paymentList');
  }
  
  const {componentRef, handlePrint, fontSize}  = usePrint(`Refund_Receipt_${patient_number}`, handleOnAfterPrint);

    const isBaseCurrency = currency === 'USD'
    const currency_symbol = !isBaseCurrency && exchangeRate ? currency : 'USD'

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Rate`}, {key:'amount_paid',value:`Amount Paid`},
        {key:'refund',value:`Refund`}]
 
  const patientDetails = (
    <tbody>
      <tr>
          <td colSpan={4}><span>Date: </span> <strong>{formatDateTime(new Date())}</strong></td>
      </tr>
      <tr>
          <td><span>Patient Number: </span> <strong>{personalDetails.patient_number}</strong></td>
          <td><span>Patient Name: </span> <strong>{`${!personalDetails.first_name ? "" : personalDetails.first_name} 
          ${!personalDetails.last_name ? "" : personalDetails.last_name}`}</strong> </td>
      </tr>
      <tr>
        <td><span>Age: </span> <strong>{personalDetails.Age}</strong></td>
        <td><span>Gender:</span> <strong>{titleCase(personalDetails.gender)}</strong></td>
      </tr>
      <tr>
        <td><span>Contact: </span> <strong>{personalDetails.phone_no}</strong></td>
        <td><span>Address:</span> <strong>{personalDetails.address}</strong></td>
      </tr>
    </tbody>
  )

  var totalRefund = 0;
  var totalPaid = 0;

  // does not show the ones not being refunded? or ask about this?
  const itemDetails = (
    <ItemsTable {...{headData}}>
      <tbody>
      {state.map((item) => {
            const qty = item.quantity ? item.quantity : 0;
            const rate = item.rate ? item.rate : 0;
            const refund = item.refund_amount ? item.refund_amount : 0;
            totalPaid = totalPaid + (qty *rate);
            totalRefund = totalRefund + refund;

            return (
              <tr key={item.bill_id}>
                  <td><span>{item.service_id ? item.service_name : item.product_id ? item.product_name : ''}</span></td>
                  <td><span>{item.quantity}</span></td>
                  <td><span>{item.usd_rate}</span></td>
                  <td><span>{item.usd_total}</span></td>
                  <td><span>{item.usd_refund_amount}</span></td>
              </tr>
            )

        })}

          <tr style={{borderTop:`1px solid ${colors.text00}`}}>
              <td colSpan={headData.length-2} align='right'><strong>Total</strong></td>
              <td><strong>{amount_paid}</strong></td>
              <td><strong>{total_refund}</strong></td>
          </tr>

      </tbody>
    </ItemsTable>
  )

  return (
    <div>
      <PageTitle title="Billing Receipt"/>
      <SubHeader title="Billing" subTitle="Billing receipt">
          <FontAwesomeIcon icon={faCoins}/>
      </SubHeader>
      <MiniCenteredDiv>
          <BackButton to='/paymentList' text='Billing List' data-testid="refund-receipt-back-button"/>
          <RightAlignedContainer>
            <Button variant='orange' onClick={handlePrint}>Print</Button>
          </RightAlignedContainer>
          <Card className='mt-2'>
             {/*<div style={{padding:'8px 60px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>*/}
             {/*    <RightAlignedContainer>*/}
             {/*        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />*/}
             {/*        { currency !== 'USD' ? <FxRate currency={currency !== 'USD' && currency}  value={exchangeRate}  handleChangeRate={handleChangeExchangeRate} />: null}*/}
             {/*    </RightAlignedContainer>*/}
             {/* </div>*/}
              <div ref={componentRef} style={{padding:10}} className="print-receipt">
                  <style>
                      {fontSize()}
                  </style>
                <PrintTemplate title='REFUND RECEIPT' {...{patientDetails, itemDetails}}/>
              </div>
          </Card>
      </MiniCenteredDiv>
    </div>
  )
}

export default RefundReceipt;