import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {useHiddenColumns} from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../Utils/smsTitle";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {useVisitList} from "./hooks/useVisitList";
import { formatDateTime } from "../../Utils/ConvertDate";
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import Triage from "../../Triage/Triage"
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {Container} from "../../Utils/styledComponents";
import ReusableTabs, {TabPanel} from "../../Utils/Dialogs/ReusableTabs";
import { Link } from "react-router-dom";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";


const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'Sl', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'type', numeric: false, disablePadding: false, label: 'Visit Type',hidden:false},
    {id: 'item_status', numeric: false, disablePadding: false, label: 'Status',hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];

const VisitList = ({actions, snackbar}) => {
    const {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, openDialog,
        visitList,  handleOpenDialog, handleCloseDialog,
        triageProps,  ids, renderService, renderPrescription,cancelProps,
         isLoading, isSuccess, isError,tabValue,handleChangeTabValue
    }= useVisitList(actions)

    const {handleSubmitTriage, isPending:isPendingTriage} = triageProps
    const { patient_number, patient_name, age, gender, screen} = ids
    const {openBar, type, message} = snackbar;
    const {visits,total_count} = visitList;



    const components = [{label: 'New Visit', path: '/newvisit'},
    {label: 'Visit List', path: '/visitlist'}, {label: 'Consultation List', path: '/consultationlist'}]
    const isTriage = screen === 'triage';
    const isService = screen === 'service';


    const title = isTriage ? 'Take Vitals' : tabValue === 0 ? 'Add Service' : tabValue === 1 ? 'Add Prescription':''

    const {openCancel, handleCancel, handleOpenCancel, handleCloseCancel} = cancelProps

  
    const visitsArr = visits.map(item=>{
        const v_type = {
            "1" : "New Visit" ,
            "2" :  "Follow-up"
        }
        const status = {
            0:(<span className="text-pending">Visit created</span>),
            1:( <span className='text-success'>Vitals taken</span>),
            2:(<span className='text-success'>Request added</span>),
            3:<span className='text-success'>Treatment ongoing</span>
        }

        const action = {
            0:(
                <>
                    <button onClick={()=>handleOpenDialog(item, 'triage')}
                            className="btn btn-sm sms-info-btn mr-2 mb-2">Triage</button>
                    <button onClick={()=>handleOpenDialog(item,'service')}
                            className="btn btn-sm sms-info-btn mr-2 mb-2">Add request</button>
                    <button onClick={()=>handleOpenCancel(item.visit_id)}
                            className="btn btn-sm sms-gray-btn mb-2">Cancel Visit</button>
                </>

            ),
            1:( <button onClick={()=>handleOpenDialog(item,'service')}
                        className="btn btn-sm sms-info-btn mr-2 mb-2">Add request</button>),
            2:(<button onClick={()=>handleOpenDialog(item,'service')}
                       className="btn btn-sm sms-info-btn mr-2 mb-2">Add request</button>),
            3:(<button onClick={()=>handleOpenDialog(item,'service')}
                       className="btn btn-sm sms-info-btn mr-2 mb-2">Add request</button>)
        }
        return {
            ...item,
            count:item.count,
            date:formatDateTime(item.date),
            type: v_type[item['visit_type']],
            item_status:status[item['status']],
            action:(
                <>
                    {item.referal_status === 'TRUE' ?
                        <Link to ={`/viewreferal/${item.visit_id}`} >
                            <button className="btn btn-sm sms-info-btn mr-1">View Referral</button> </Link>:null}
                    {action[item.status]}
                </>
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);



    const tabs = ['Service Request', 'Prescription Request']
    return (
        <div>
            <PageTitle title="Visit List"/>
            <SubHeader title="Visit list" subTitle="Visit List">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <DeleteDialog message='cancel'
                          openDialog={openCancel} handleClose={handleCloseCancel}
                          text="visit" title='Cancel Visit'>
                <button className=' btn sms-info-btn btn-sm' onClick={handleCancel}>Cancel Visit</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseCancel}>No</button>
            </DeleteDialog>
    

            <CustomDialog title={title} open={openDialog} handleClose={handleCloseDialog} maxWidth="lg"  isContentOverflow={false}>
                {isTriage ? <form onSubmit={handleSubmitTriage}>
                    <Triage {...{...triageProps,patient_number,
                            patient:{patient_name,age, gender}, handleSubmitTriage}}/>
                    <button type="submit" disabled={isPendingTriage} className="btn btn-sm sms-btn">{isPendingTriage ? 'Saving....' : 'Save Triage'}</button>
                </form>: null}
                {isService ? <ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>
                            <TabPanel value={tabValue} index={0}>
                                {renderService}
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                {renderPrescription}
                            </TabPanel>
                        </ReusableTabs>: null}
            </CustomDialog>
            <Container>
                <MiniHeader title='Visit List'/>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <CustomTable  headData={headCells} handler={handleSearch} term={searchValue}
                             data={visitsArr} colSpan={headCells.length} customPage records={10} activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={total_count}
                              {...{ all_hidden, handleAllHeadCells, handleHeadCells}}>
                   
                        <TableBody>
                        {isLoading ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow> :null}
                            {isSuccess ?  visitsArr.length > 0 ? visitsArr.slice(0, 10).map(item => {

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.visit_id}
                                            className='mui-table-row'>

                                            {headCells.map((head)=>(
                                                <TableCell  key={head.id} hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ?   <TableRow>
                            <TableCell colSpan={headCells.length} align="center">The server did not return a successful response</TableCell>
                        </TableRow> :null }
                        </TableBody>
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(VisitList);
