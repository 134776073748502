import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Badge } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {Link} from 'react-router-dom';

export function TabPanel({children, value, index, ...other}) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        marginBottom:'10px'

        // backgroundColor: theme.palette.background.paper,
    },
    verticalRoot:{
        flexGrow:1,
        display:'flex'
    },
    tabRoot:{
        textDecoration:'none !important',
        textTransform:'capitalize',
        [theme.breakpoints.up('sm')]: {
            minWidth: '80px',
        },
       
    },
    tabs:{
        borderRight:`1px solid ${theme.palette.divider}`,
    },
    muiTab:{
        borderBottom:'1px solid #918f8f'
    },
    muiWrapper:{
        display:'inline-flex',
        flexDirection:'row',
        alignItems:'initial'
    },
    muiBadge:{
        marginLeft:'8px'
    }
}));

export default function ReusableTabs({value, handleChange, tabHeading, children}) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                classes={{root: classes.muiTab}}
            >
                {tabHeading.map((item,index)=>(
                    <Tab label={item} {...a11yProps(index)}   classes={{root:classes.tabRoot}}/>
                ))}
            </Tabs>
            {children}
        </div>
    );
}


export  function ReusableRouteTabs({value,  tabHeading=[]}) {

    const classes = useStyles();

    return (
        <div className={classes.root}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    classes={{root: classes.muiTab}}
                >
                    {tabHeading.map((item,index)=>{
                        const badge = (
                            <>
                                {item.label} {item['badge'] ? <Badge classes={{root:classes.muiBadge}} badgeContent={item.badge_count} color="secondary"/>: null}
                            </>

                        )
                        return(
                            <Tab key={index} label={badge} {...a11yProps(index)} component={Link} to={item.path} data-testid={"tab-index-"+index}
                                 classes={{root:classes.tabRoot, wrapper:classes.muiWrapper}} value={item.path}/>
                        // item['badge'] ?
                        //     <div style={{display:'flex', marginRight:'30px'}}>
                        //         <Tab key={index} label={item.label} {...a11yProps(index)} component={Link} to={item.path}
                        //              classes={{root:classes.tabRoot}} value={item.path}/>
                        //         <p style={{margin:'0px'}}><Badge badgeContent={item.badge_count} color="secondary"/></p>
                        //     </div>
                        //     :
                        //     <Tab key={index} label={item.label} {...a11yProps(index)} component={Link} to={item.path}
                        //          classes={{root:classes.tabRoot}} value={item.path}/>

                    )})}
                </Tabs>
        </div>
    );
}


