import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import TextField from "../../Utils/FormInputs/TextField";
import Label from "../../Utils/FormInputs/Label";
import PageTitle from "../../Utils/smsTitle";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import {CenteredContainer, Container, RightAlignedContainer} from "../../Utils/styledComponents";
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import styled from "@emotion/styled/macro";
import * as mq from "../../../styles/media-queries";
import {useNewVisit} from "./hooks/useNewVisit"
import { ErrorMessage } from "../../Utils/styledComponents";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import RecordIncomingReferral from "../../DoctorsModule/ReferralNote/RecordIncomingReferral";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import { ReferralTextArea } from '../../DoctorsModule/ReferralNote/MainReferralNote';
import { ReferralTextInput } from "../../DoctorsModule/ReferralNote/MainReferralNote";
import { ReferralTextField } from "../../DoctorsModule/ReferralNote/MainReferralNote";



export const StyledDiv = styled.div({
    width: "80%",
    margin: "0 auto",
    marginTop:'20px',
    [mq.medium]:{
      width:'1080px',
    }
});

export const ProviderForm = ({submitted,provider_id,handleChangeProvider,providers,providerDetails,handleChangeProviderDetails,
    isDisabled=false, labelSm='', colSm=''
                          }) =>{
    const {account_name, card_number} = providerDetails
    const provider = provider_id?.value?.split('-')[1];
    const isInsurance = provider === 'Insurance Company'

    const lbSm = labelSm ? labelSm : "3"
    const clSm = colSm ? colSm : "9"

    return(
        <>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={lbSm} xl={lbSm}>
                    <Label name="Provider"  type/>
                </Col>

                <Col xs={12} sm={12} md={12} lg={clSm} xl={clSm}>
                    <CustomSelect submitted={submitted} value={provider_id} name="provider_id"
                                  onChange={handleChangeProvider} options={providers}
                                  defaultOption="Select..." isClearable isDisabled={isDisabled}/>
                    {submitted && !provider_id ? <ErrorMessage>This is a required field</ErrorMessage>:null}

                </Col>
            </Form.Group>
            {provider_id?.value ? <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={lbSm} xl={lbSm}>
                    <Label name="Account Name"  type/>
                </Col>

                <Col  xs={12} sm={12} md={12} lg={clSm} xl={clSm}>
                    <TextField  disabled={isDisabled} submitted={submitted} value={account_name} name="account_name" onChange={handleChangeProviderDetails}/>
                    {submitted && !account_name ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                </Col>
            </Form.Group>:null}

            {provider_id?.value && isInsurance ?
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={lbSm} xl={lbSm}>
                        <Label name="Card Number"  type/>
                    </Col>
                    <Col  xs={12} sm={12} md={12} lg={clSm} xl={clSm}>
                        <TextField disabled={isDisabled} submitted={submitted} type='text' value={card_number} name="card_number" onChange={handleChangeProviderDetails}/>
                        {submitted && !card_number ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                    </Col>
                </Form.Group>
                : null }
        </>
    )
}


const NewVisit = ({actions, match:{params},  snackbar}) => {
    const {patient_number} = params;
    const  {p_number,showNumber,patientNumbers,patientDetails,submitted, visit_type,
        handleVisitTypeChange,handleClickPatientNumber,handleSubmitVisit,handleCloseSnackbar,
        handleChangePatientNumbers,isPending, search_parameter, handleChangeSearchParam,referralProps,
        isAccumulatedBill, handleChangeAccumulatedBill, patient_no, openNewInsurance, handleCloseNewInsurance,
        isOnInsurance, handleChangeIsInsurance, isProvider, providerProps,isPendingProvider,
        handleSubmitProvider, handleOpenNewInsurance,newProviderProps, isBlocking,referralDialog,
        setReferralDialog,setIsBlocking} = useNewVisit(actions, patient_number);


    const {first_name, last_name, phone_no} = patientDetails;

    const {addReferral, handleAddReferral, referrals, handleChangeReferral, company_name} = referralProps

    const {referral_date,  from_hospital, visit_date, patient_history, diagnosis, treatment} = referrals;

    const {openBar, type, message} = snackbar;
    const components = [{label: 'New Visit', path: '/newvisit'},
    {label: 'Visit List', path: '/visitlist'}, {label: 'Consultation List', path: '/consultationlist'}]


    const path_url = '/newvisit' || `/newvisit/${patient_number}`



    const searchLabel = {
        'patient_no':'Patient No.',
        'patient_name':'Patient Name',
        'phone_no':'Phone No.'
    }



    

    const handleReferralDialog = () => {
      
        setReferralDialog(false)
        
    }

    return (
        <div>
            <PageTitle title='New Visit'/>
            <SubHeader title="Visit" subTitle='New Visit'>
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />
            <CustomDialog title={"Record Inward Referral"} open={referralDialog} handleClose={handleReferralDialog} maxWidth="lg"  isContentOverflow={false}>
                <RecordIncomingReferral patient_name={`${first_name} ${last_name}`}  phone_no={phone_no} actions={actions}
                    patient_number={patient_no} snackbars={snackbar} handleReferralDialog={handleSubmitVisit}/>
            </CustomDialog>

            <CustomDialog open={openNewInsurance} handleClose={handleCloseNewInsurance} title='Add Provider' isContentOverflow={true}>
                <form onSubmit={handleSubmitProvider}>
                    <ProviderForm {...newProviderProps}/>
                    <CenteredContainer>
                        <button type='submit' disabled={isPendingProvider} className='btn btn-sm sms-btn'>{isPendingProvider ? 'Saving...' : 'Save'}</button>
                    </CenteredContainer>
                </form>
            </CustomDialog>
            <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={handleCloseSnackbar}/>
            <Container>
                <MiniHeader title='New Visit'/>
                <ReusableRouteTabs value={path_url} tabHeading={components}/>
                <StyledDiv>
                    <form  id="submit_visit_form" autoComplete="off">
                        <div className="row">
                            <div className="col-lg-8">
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label  name="Search by:" type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                                        <Form.Check type="radio" inline label="Patient No." name="search_parameter" value="patient_no"
                                                    checked={search_parameter === "patient_no"} 
                                                    onChange={handleChangeSearchParam} data-testid="patient_no"/>
                                        <Form.Check type="radio" inline label="Patient Name" name="search_parameter" value="patient_name"
                                                    checked={search_parameter === "patient_name"}
                                                    onChange={handleChangeSearchParam} data-testid="patient_name"/>
                                        <Form.Check type="radio" inline label="Phone No." name="search_parameter" value="phone_no"
                                                    checked={search_parameter === "phone_no"}
                                                    onChange={handleChangeSearchParam} data-testid="phone_no"/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name={searchLabel[search_parameter]} htmlFor="visit_patient_number" type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                                        <TextField type="text" submitted={submitted}
                                                        name="p_number"
                                                        value={p_number}
                                                        onChange={handleChangePatientNumbers} id="visit_patient_no"/>
                                        {(submitted && !p_number) &&
                                        <ErrorMessage>Patient ID is required</ErrorMessage>}
                                        {showNumber && <Form.Control as="select" multiple id="patient_number_select"
                                                                        className="drug-ul visit-ul">
                                            {patientNumbers.map((number, index) => (
                                                <option className="drug-li" key={index}
                                                        onClick={()=>handleClickPatientNumber({innerText:number.label, value:number.value})}
                                                        value={number.value} id="numbers">{number.label}</option>
                                            ))}
                                        </Form.Control>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="First Name"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                                        <TextField type="text" name="first_name" value={first_name}
                                                    id="visit_first_name"
                                                    disabled={true}/>
                                        {(submitted && !first_name) &&
                                        <ErrorMessage>First Name is required</ErrorMessage>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="Last Name"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                                        <TextField type="text" name="last_name" value={last_name} id="visit_last_name"
                                                    disabled={true}/>
                                        {(submitted && !last_name) &&
                                        <ErrorMessage>Last Name is required</ErrorMessage>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                        <Label name="Visit Type"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                                        <CustomSelect onChange={handleVisitTypeChange} id='visit_type'
                                                      value={visit_type}
                                                      options={[
                                                          {value: '1', label: 'New Visit'},
                                                          {value: '2', label: 'Follow Up'},
                                                          {value: '3', label: 'Ante Natal Visit'}
                                                      ]}/>
                                    </Col>
                                </Form.Group>
                                {isOnInsurance && isProvider ?
                                    <ProviderForm {...{...providerProps, isDisabled:true}}/> :  null}
                                {isOnInsurance && !isProvider ? <RightAlignedContainer>
                                    <button type='button' onClick={handleOpenNewInsurance} className='btn btn-sm sms-info-btn mb-2'>Add Provider</button>
                                </RightAlignedContainer>:null}
                                {isOnInsurance && isProvider ? <RightAlignedContainer>
                                    <button type='button' onClick={handleOpenNewInsurance} className='btn btn-sm sms-info-btn mb-2'>Change Provider</button>
                                </RightAlignedContainer> : null}
                                {submitted && isOnInsurance && !isProvider ? <CenteredContainer>
                                    <ErrorMessage style={{fontSize:'13.5px'}}>This patient is not attached to a provider.
                                        Please click the add provider button to attach </ErrorMessage>
                                </CenteredContainer>: null}
                                {/*{patient_no && !isAccumulatedBill  ?*/}
                                {/*<Form.Group className="switch-admission-prescription">*/}
                                {/*    <Label name="Do you want to add a Provider?" className="label-admission-prescription"/>*/}
                                {/*    <text className="label-admission-prescription">No</text>*/}
                                {/*    <Form.Check type="switch" id="custom-insurance-switch" defaultChecked={isOnInsurance} checked={isOnInsurance} onChange={handleChangeIsInsurance}*/}
                                {/*                className="toggle-admission-prescription"/>*/}
                                {/*    <text className="label-admission-prescription">Yes</text>*/}
                                {/*</Form.Group>: null}*/}


                                <Form.Group className="switch-admission-prescription">
                                    <Label name="Do you want to make this an accumulated bill?" className="label-admission-prescription"/>
                                    <text className="label-admission-prescription">No</text>
                                    <Form.Check type="switch" id="custom-accumulated-bill-switch" defaultChecked={isAccumulatedBill} checked={isAccumulatedBill} onChange={handleChangeAccumulatedBill} className="toggle-admission-prescription"/>
                                    <text className="label-admission-prescription">Yes</text>
                                </Form.Group>

                                <Form.Group className="switch-admission-prescription">
                                    <Label name="Do you want to add a refferal?" className="label-admission-prescription"/>
                                    <text className="label-admission-prescription referral-no-toggle-btn" data-testid="referral-btn">No</text>
                                    <Form.Check type="switch" id="customswitch" defaultChecked={addReferral} onClick={handleAddReferral}
                                    className ="switch-add-referral" />
                                    <text className="label-admission-prescription referral-toggle-btn" data-testid="referral-button">Yes</text>
                                </Form.Group>
                                
                                {addReferral ? 
                                    
                                    <div className="row">
                                    <div className="col-md-6 mt-3">
                                        
                                        <ReferralTextField submitted={submitted} value={referral_date} labelSm="4" colSm="8"
                                                            name="referral_date" data-testid="referral_date" type="date" htmlFor="referral_date"
                                                          id="referral_date"  labelName="Date of Referral" disabled={true}/>
                                    

                                        <ReferralTextInput onChange={handleChangeReferral} value={from_hospital} colSm="8"
                                                            labelSm="4" name="from_hospital" type="text" labelName="FROM: "
                                                            data-testid="from_hospital" required={true} submitted={submitted}/>
                                                            

                                        
                                        <ReferralTextInput value={company_name} colSm="8" disabled={true}
                                                            labelSm="4" name="to_hospital" type="text" labelName="TO: "/>

                                        <Form.Group as={Row}>
                                            <Col xs={10} sm={4} md={12} lg={4} xl={4}>
                                                <Label name="Reason"/>
                                            </Col>
                                            {/* <Col xs={12} sm={14} md={12} lg={7} xl={7}> */}
                                        <ReferralTextArea labelSm ="4" value={patient_history} onChange={handleChangeReferral} name="patient_history" colSm="8"
                                                           dataTest="history"/>
                                            {/* </Col> */}
                                        </Form.Group>
                                                        
                                    </div>
                                    
                                    <div className="col-md-6 mt-3">
                                       

                                        <ReferralTextArea value={diagnosis} onChange={handleChangeReferral} name="diagnosis" id='diagnosis'
                                                            labelName="Diagnosis" submitted={submitted} required={true} htmlFor='diagnosis'
                                                            dataTest="diagnosis"/>
                                                        

                                        <ReferralTextArea value={treatment} onChange={handleChangeReferral} name="treatment"
                                                            labelName="Treatment Given"
                                                            dataTest="treatment"/>
                                                    
                                    </div>
                                        
                                    </div>
                            
                                :null}

                                {patient_no ?
                                    <RightAlignedContainer>
                                        {/* <button type='button' onClick={(e)=>{
                                            setReferralDialog(true)
                                            setIsBlocking(false)
                                        }}
                                            className="sms-info-btn btn btn-sm px-3 mr-2">Record Inward Refferal</button> */}
                                        <button type="submit" onClick={(e)=>handleSubmitVisit(e,'new_service_request', 'triage')}
                                            disabled={isPending} id="take_triage"
                                            className="sms-btn btn btn-sm px-3">Take triage</button>
                                        <button type="submit" id='submit_form' className="btn btn-sm sms-gray-btn ml-2" data-testid="new_service_request"
                                        onClick={(e)=>handleSubmitVisit(e,'new_service_request')} disabled={isPending}>Request service</button>
                                    </RightAlignedContainer>
                                    : null}

                            </div>
                            <div className="col-lg-4"/>
                        </div>
                    </form>
                </StyledDiv>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVisit);
