import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {MainSearchComponent} from "../MainSearch";
import FilterResults from "react-filter-search";
import {useRevenueCollected} from "./CustomHooks/useRevenueCollected";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {Link} from "react-router-dom";
import { formatAmount } from "../../Utils/formatNumbers";





const RevenueGenerated = ({actions, snackbars, urls, title, headData, component, children}) => {
        const {inputType,isLoading, isSuccess, isError,
            closeSnackbar, search_type, handleSubmit,
            text, textType, year, date, handleChangeSearch,  csvRef, downloadCsv, downloadPdf,
            handleChangeDate, handleChangeYear, handleIncrementYear,  isSubmitted, submitted,
            searchValue, order, orderBy,  collections, page, rowsPerPage,viewServiceReport,totalAmount,
            handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage} = useRevenueCollected(actions, urls, component)

        const {start_date, end_date} = date;
        const {openBar, type, message} = snackbars;
        const lbl = component === 'department' ? 'Department' : 'Procedure'
        const headers = [{label: lbl, key: 'name'}, {label: 'Amount', key: 'value'}]
        return (
            <div className='journals' data-testid="revenue-generated">
                <PageTitle title={title}/>
                <SubHeader title="Reports" subTitle={title}>
                    <FontAwesomeIcon icon={faClipboardList}/>
                </SubHeader>
                {children}
                <div>
                    <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                         handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                         changePeriod={handleChangeSearch} end_date={end_date}
                                         handleChangeValue={handleChangeYear} inputType={inputType}
                                         search_type={search_type} handleChangeYear={handleIncrementYear}
                                         start_date={start_date} year={year} submitted={submitted}>
                        <DataExportButton headers={headers} title={component === 'department' ? 'Revenue per Department' : 'Revenue per Procedure'}
                        csvRef={csvRef} data={collections} downLoadPdf={downloadPdf} exportCsv={downloadCsv}/>
                    </MainSearchComponent>

                </div>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <div>
                    

                    {/* <div className="row">
                        <div className="col-md-5 pl-3"> */}
                            {/* <StyledDiv>
                                <Form.Group as={Row}>
                                    <Form.Label style={{marginTop: '.5rem'}}
                                                sm={4}>{component === 'department' ? 'Department' : 'Procedure'}:</Form.Label>
                                    <Col sm={8}>
                                        <CustomSelect options={departments} value={department_id}
                                                    onChange={handleChangeDepartment}/>
                                    </Col>
                                </Form.Group>
                            </StyledDiv> */}
                            <CustomTable title="" term={searchValue} handler={handleSearch}
                                         handleChangePage={handleChangePage}
                                         handleChangeRowsPerPage={handleChangeRowsPerPage}
                                         page={page} rowsPerPage={rowsPerPage} headData={headData}
                                         handleRequestSort={handleRequestSort} data={collections} colSpan={headData.length} order={order}
                                         orderBy={orderBy} filter fewRows>
                             
                                <FilterResults value={searchValue} data={collections}
                                    renderResults={results => (
                                    <TableBody>
                                        {isLoading ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                                alt="loader"/></TableCell>
                                    </TableRow>: null}
                                    {isSuccess ? collections.length > 0 ? 
                                    <>
                                    {stableSort(results, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => {
                                            const count = (page * rowsPerPage) + index + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index}
                                                    className='mui-table-row'
                                                    onClick={viewServiceReport(item.service_id ? item.service_id:null, item.id, item.name)}
                                                >
                                                    <TableCell>{count}</TableCell>
                                                    <TableCell><Link
                                                        to={{pathname: `/revenueperservice/${item.service_id ? item.service_id:null}/${item.id}/${item.name}`}}>{item.name}</Link></TableCell>
                                                    <TableCell>{(item.value)}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    <TableRow>
                                        <TableCell align='right'>Total Amount</TableCell>
                                        <TableCell><strong>{(totalAmount)}</strong></TableCell>
                                    </TableRow>
                                    </>
                                    :<TableRow>
                                        <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                    </TableRow>:null}
                                    {isError ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                    </TableRow>: null}
                                </TableBody>)}/>
                            </CustomTable>
                        {/* </div> */}
                        {/* <div className="col-md-7">
                            {(department_id && chips.length > 0) ?
                                <div>
                                    <Card className="p-3 general-card mb-3">
                                        <h6>Revenue
                                            per {component === 'department' ? 'Department' : 'Procedure'}</h6>
                                        <CustomBarChart data={statistics} height={300}>
                                            <Legend onClick={handleDeleteChip}/>
                                            <XAxis dataKey="date" allowDecimals={false}
                                                   label={{value: label, position: 'bottom', offset: -10}}/>
                                            <YAxis label={{
                                                value: 'Amount',
                                                angle: -90,
                                                position: 'insideLeft'
                                            }}/>
                                            {chips.length > 0 &&
                                            chips.map((item) => {
                                                return (
                                                    <Bar key={`bar_${item.value}`}
                                                         dataKey={`value_${item.value}`} name={item.label}
                                                         fill={item.color}/>
                                                )
                                            })
                                            }
                                        </CustomBarChart>
                                    </Card>
                                    <Card className="p-3 general-card">
                                        <h6>Revenue
                                            per {component === 'department' ? 'Department' : 'Procedure'}</h6>
                                        {loading ? <div><img src="/images/smsloader.gif"
                                                             alt="loader"/></div> : collections.length > 0 ?
                                            <SmsPieChart cx={230} cy={130} height={300} innerRadius={40}
                                                         outerRadius={100} data={collections} label>
                                                {collections.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                                ))}
                                            </SmsPieChart> :
                                            <div className="text-center">Revenue per {component} data not
                                                available</div>}
                                    </Card>
                                </div>
                                : <Card className="p-3 general-card">
                                    <h6>Revenue
                                        per {component === 'department' ? 'Department' : 'Procedure'}</h6>
                                    {loading ? <div className="text-center"><img src="/images/smsloader.gif"
                                                                                 alt="loader"/>
                                    </div> : collections.length > 0 ?
                                        <SmsPieChart cx={230} cy={130} height={460} innerRadius={40}
                                                     outerRadius={100} data={collections} label>
                                            {collections.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                            ))}
                                        </SmsPieChart> :
                                        <div className="text-center">Revenue per {component} data not
                                            available</div>}
                                </Card>}

                        </div> */}
                    {/* </div> */}
                 
                </div>
            </div>
        )
    }
;

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(RevenueGenerated);
