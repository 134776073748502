import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { config } from "../../../../Helpers/env";
import {useCommonState} from "../../CustomHooks/useReusableFunctions";
import {randomColor} from "../../../Utils/Charts/utilityFunctions";
import { exportPDF } from "../../../Utils/downloadPDF";
import {convertMonth} from "../../../Utils/ConvertDate";
import { history } from "../../../../Helpers/history";


export const useRevenueCollected = (actions, urls, component) => {
    const [department_id, setId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [chips, setChips] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState('idle');
    const [collections, setList] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const csvRef = useRef();
    const {
        closeSnackbar, search_type, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear,
        searchValue, order, orderBy, page, rowsPerPage, setSearchType, inputType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage
    } = useCommonState(actions)
    const {start_date, end_date} = date;
    const {revenue_url, data_url} = urls;

    const calculateTotal = (arr) =>{
        const sum = arr.reduce((a,b)=>{
            return +a + +b.value
        },0)
        setTotalAmount(sum)
    }


    useEffect(() => {
        axios.get(`${config.smsUrl}/creport/${revenue_url}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data
            const _data = !dt.revenue ? [] : dt.revenue;
            if (component === 'department') {
                let dps = _data.map(item => ({
                    value: item.department_id,
                    label: item.department_name
                }))
                setDepartments(dps);
            } else {
                let prds = _data.map(item => ({
                    value: item.procedure_id,
                    label: item.procedure_name
                }))
                setDepartments(prds);
            }

        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })
    }, []);


    useEffect(() => {
        setLoading('pending');
        axios.post(`${config.smsUrl}/creport/${data_url}`, null, {
            params: {
                start_date: start_date,
                end_date: end_date,
                option: 3,
                department_id: '',
                data: true
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            if (component === 'department') {
                const ls = dt.map(item => ({
                    name: item.department_name,
                    value: item.revenue ? item.revenue : 0,
                    id:item.department_id, ...item
                }))
                setList(ls);
                calculateTotal(ls)
            } else {
                const ls = dt.map(item => ({
                    name: item.procedure_name,
                    value: item.pro_revenue ? item.pro_revenue : 0,
                    ...item
                }))
                setList(ls);
                calculateTotal(ls)
            }
            setLoading('success');
        }).catch((err) => {
            setLoading('error');
            logoutErrorMessage(err, null, actions);
        })
    }, []);

    useEffect(() => {
        axios.post(`${config.smsUrl}/creport/${data_url}`, null, {
            params: {
                start_date: start_date,
                end_date: end_date,
                option: 3,
                department_id: '',
                data: false
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setStatistics(dt)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }, []);


    const handleChangeDepartment = (value) => {
        setId(value)
        const stat = [...statistics]
        const val = {...value, color: randomColor()}
        const existingIds = chips.map((id) => id.value)
        if (!existingIds.includes(val.value)) {
            axios.post(`${config.smsUrl}/creport/${data_url}`, null, {params: {
                start_date: start_date,
                end_date: end_date,
                option: 3,
                department_id: value.value,
                data: false
            }}).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                for (let i = 0; i < stat.length; i++) {
                    stat[i][`value_${value.value}`] = component === 'department' ? dt[i].revenue : dt[i].pro_revenue;
                }
                setStatistics(stat)
                setChips([...chips, val])
            }).catch(err => {
                errorMessages(err, null, actions);
            })
        }
    }


    const handleDeleteChip = (chipToDelete) => {
        setChips((chips) => chips.filter((chip) => `value_${chip.value}` !== chipToDelete.dataKey));
    };


    const handleRetrieveData = (val, submit) => {
        submit && setSubmitted(true)
        if (start_date && end_date) {
            setLoading('idle')
            setChips([]);
            submit && setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/creport/${data_url}`, null, {
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    option: 3,
                    department_id: '',
                    data: val
                }
            }).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                if (val === false) {
                    setStatistics(dt)
                } else {
                    if (component === 'department') {
                        const ls = dt.map(item => ({
                            name: item.department_name,
                            value: item.revenue ? item.revenue : 0,
                            id:item.department_id, ...item
                        }))
                        setList(ls);
                        calculateTotal(ls)
                    } else {
                        const ls = dt.map(item => ({
                            name: item.procedure_name,
                            value: item.pro_revenue ? item.pro_revenue : 0,
                           ...item
                        }))
                        setList(ls);
                        calculateTotal(ls)
                    }
                }
                submit && setIsSubmitted('resolved')
                setLoading('success')
            }).catch(err => {
                submit && setIsSubmitted('rejected')
                setLoading('error')
                errorMessages(err, null, actions);
            })
        }
    }
    const downloadPdf = () => {
        const {end_date, start_date} = date
        const time = search_type === '0' ? start_date :  search_type === '1'? convertMonth(start_date) :
            search_type === '2' ? year : `${start_date} to ${end_date}`;
        const category = component === 'department' ? 'Department' : 'Procedure'
        exportPDF(`Revenue Generated per ${category}(${time})`, [[category, "Amount"]],
            collections.map((item) => [item.name,  item.value]),
            `Revenue Generated per ${category}(${time}).pdf`,[["Total Amount", totalAmount]]);

    }
    const downloadCsv = () => {
        csvRef.current.link.click()
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        handleRetrieveData(false, true);
        handleRetrieveData(true, true);
        setId(null)
    }

    const viewServiceReport = (service_id, department_id, service_name) => () =>{
        history.push(`/revenueperservice/${service_id}/${department_id}/${service_name}`)
    }

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    return {
        department_id, handleChangeDepartment, closeSnackbar, search_type, handleSubmit, chips,
        handleDeleteChip, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear, statistics, isSubmitted, submitted,
        searchValue, order, orderBy, loading, collections, departments, page, rowsPerPage,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage, handleRetrieveData,
        setSearchType, inputType, csvRef, downloadCsv,downloadPdf,viewServiceReport, totalAmount,
        isLoading, isSuccess, isError
    }
}