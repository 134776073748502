import React, {useEffect, useState} from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as snackbarActions from "../../actions/snackbarActions";
import {SubHeader} from "../../Containers/SubHeader";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import CustomTable from "../Utils/Tables/CustomTable";
import {faPrescriptionBottle} from "@fortawesome/free-solid-svg-icons/faPrescriptionBottle";
import * as prescriptionActions from "../../actions/PrescriptionActions/PrescriptionActions"
import {withTheme} from "@material-ui/core/styles";
import { formatDateTime} from "../Utils/ConvertDate";
import {Container} from "../Utils/styledComponents";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { history } from "../../Helpers/history";
import {prescriptionComponents} from "./PrescriptionList";
import {titleCase} from "../Users/addUser";
import axios from "axios";
import {config} from "../../Helpers/env";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {useDispenseConsumables} from "./CustomHooks/useDispenseConsumables";


const headData = [
    {id: 'id', numeric: false, disablePadding: false, label: 'Sl'},
    // {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'pNumber', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    // {id: 'docName', numeric: false, disablePadding: false, label: 'Doctor Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'}
];




const DispenseConsumables = ({actions, snackbars}) => {

    const [term, setTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState('idle')
    const [total_count, setTotalCount] = useState(0)

    const {handleOpenDialog, isResolved, renderDispenseConsumable} = useDispenseConsumables(actions)



    useEffect(() => {
        const formData = new FormData();
        const searchValue = term ? titleCase(term) : ''
        formData.append('search', searchValue);
        setLoading('pending')
        axios.get(`${config.smsUrl}/cpharmacy/patients_with_service_with_consumables`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const dataArr = !dt.data ? [] : dt.data;
            const count = !dt.iTotalRecords ? 0 : dt.iTotalRecords;
            setServices(dataArr)
            setTotalCount(count)
            setLoading('success')
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
            setLoading('rejected')
        })
    }, [term, activePage, isResolved])




    const handler = (event) => {
        setTerm(event.target.value)
    };


    const handleChangeActivePage = (value) => {
        setActivePage(value)
    };

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };



    const isLoading =  loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';







    const {openBar, type, message} = snackbars;

    return (
        <div id="dispense-consumables-list" className='journals'>
            <PageTitle title='Dispense Consumables'/>
            <SubHeader title="Pharmacy" subTitle='Dispense Consumables'>
                <FontAwesomeIcon icon={faPrescriptionBottle}/>
            </SubHeader>
            <MainSnackbar handleCloseBar={closeSnackBar} open={openBar} message={message} variant={type}/>
            {renderDispenseConsumable}
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={prescriptionComponents}/>

                <CustomTable headData={headData} title='' data={services}
                             customPage handleChangeNextPage={handleChangeActivePage}
                             records={10} total_count={total_count} activePage={activePage} term={term}
                             id='search-prescription' handler={handler} colSpan={headData.length}>
                    <TableBody>
                        {isLoading ? <TableRow>
                            <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                                     alt="loader"/></TableCell>
                        </TableRow>:null}
                        {isSuccess ? services.length > 0 ? services.slice(0, 10)
                            .map((unfilled, index) => {
                                const _id = index += 1;
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        className='mui-table-row'>
                                        <TableCell component="th" id={labelId} scope="row"
                                                   padding="default"
                                                   className='mui-table-cell'>
                                            {_id}
                                        </TableCell>
                                        {/*<TableCell>{formatDateTime(unfilled.date_created)}</TableCell>*/}
                                        <TableCell>{unfilled.patient_number}</TableCell>
                                        <TableCell>{`${unfilled.first_name} ${unfilled.last_name}`}</TableCell>
                                        <TableCell>
                                            <button type="button" onClick={()=>handleOpenDialog(unfilled.visit_id)} className='btn btn-sm sms-info-btn'>Dispense</button>
                                        </TableCell>
                                    </TableRow>
                                );
                            }):<TableRow>
                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                        </TableRow>:null}
                        {isError ? <TableRow>
                            <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                        </TableRow>:null}
                    </TableBody>
                </CustomTable>
            </Container>
        </div>
    );

}

function mapStateToProps(state) {
    return {prescriptions: state.prescriptions, snackbars: state.snackbar};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            prescriptionActions: bindActionCreators(prescriptionActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    };
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(DispenseConsumables));
