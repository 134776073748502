import {useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";

function useReceiveDebtorsPayments(actions, obj) {
    const [state, setState] = useState({payment_method:'', payment_date:dateConvert()})
    const [invoices, setInvoices] = useState([])
    const [isFull, setIsFull] = useState(false)
    const [amountReceived, setAmountReceived] = useState(0)
    const [customer, setCustomer] = useState({customer_name:'', due_amount:0, total_amount:0, paid_amount:0})
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [openDialog, setOpenDialog] = useState(false)
    const {customer_id, customer_name:cus_name, data, total_debt, total_amount_due, amount_paid} = obj
    const {due_amount} = customer
    const [memo, setMemo] = useState('');
    const [accounts, setAccounts] = useState([])
    const [debit_to, setDebitTo] = useState('')

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';


    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/ledgers_under_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])





    const calculateTotal = (name='',arr=[]) =>{
        const total = arr.reduce((a, item)=>{
            return +a + +item.total_amount
        },0)
        setCustomer({customer_name:name, due_amount:total})
    }

    useEffect(()=>{
        if(!cus_name){
            return
        }
        // const arr = data?.map(({invoice})=>invoice)
        //calculateTotal(cus_name, data)
        setCustomer({customer_name:cus_name, due_amount: total_amount_due,
            total_amount: total_debt, paid_amount: amount_paid})
        setInvoices(data)
    },[data, cus_name, customer_id, total_amount_due, total_debt, amount_paid])

    const handleChangeMemo = (event) => {
        setMemo(event.target.value);
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false);
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state, [name]:value})
    }

    const handleIsFullBill = (event) =>{
        setIsFull(event.target.checked)
        const full_amount = event.target.checked ? due_amount : ''
        // const arr = invoices.map(item=>({
        //     ...item,
        //     payment: event.target.checked ? item.total_amount: ''
        // }))
        setAmountReceived(full_amount)
        // setInvoices(arr)

    }
    const handleChangeReceived = (event) =>{
        setAmountReceived(event.target.value)
    }

    const calculateTotalAmount = (arr=[]) =>{
        const total = arr.reduce((a, item)=>{
            return a + +item.payment
        }, 0)
        setCustomer({...customer, total_amount:total})
    }

    const handleChangePayment = (event,inv_id) =>{
        const {value} = event.target
        const arr = invoices.map(item=>{
            if (item.invoice_no === inv_id){
                return {...item, payment:value}
            }
            return item
        })
        calculateTotalAmount(arr)
        setInvoices(arr)
    }

    const handleChangeDebitTo = (val) =>{
        setDebitTo(val)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        if (amountReceived && debit_to) {
            /*const formData = new FormData()
            formData.append('invoices_paid', invoices);
            formData.append('amount_paid', amountReceived);
            formData.append('pay_type', 2);
            formData.append('received_by', user_roles_id);
            formData.append('memo', memo)
            formData.append('insurance_provider', customer_id) */

            const params = {
                invoices_paid:invoices, 
                amount_paid:amountReceived,
                pay_type:2,
                received_by:user_roles_id,
                memo,
                insurance_provider:+customer_id,
                debit_to: debit_to?.value
            }

            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbilling/clear_debt`, params).then(() => {
                actions.snackbarActions.snackSuccess('Debt cleared successfully')
                setIsSubmitted('resolved')
                setSubmitted(false)
                setOpenDialog(false)
                setAmountReceived(0)
                setIsFull(false)
                setCustomer({...customer, due_amount: 0})
                setDebitTo(null)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';

    const paymentProps = {state, isPending, submitted, isFull, amountReceived, customer, openDialog, invoices,
    handleChangePayment, handleChange, handleChangeReceived, handleCloseDialog, handleOpenDialog, handleIsFullBill,
    handleSubmit, memo, handleChangeMemo, accounts, debit_to, handleChangeDebitTo, total_amount_due, amount_paid}

    return {paymentProps, isResolved}

}
export {useReceiveDebtorsPayments}