import React, {useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {config} from "../../../../Helpers/env";
import {titleCase} from "../../../Users/addUser";
import {v4 as uuidv4} from "uuid";
import {ConfirmationDialog} from "../../../Utils/ReusableComponents/ConfirmationDialog";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {CustomSelect} from "../../../Utils/FormInputs/SelectInput";
import {RightAlignedContainer} from "../../../Utils/styledComponents";
import {RadiologyTable} from "../RadiologyTable";
import {BlockNavigationComponent} from "../../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../../Helpers/history";

export function useNewRadiologyRequest( obj) {
    const {patient_number, visit_id, actions, visit_type, service_type, department_name,setOpenDialog,setIsBlocking, isBlocking,setServiceType} = obj

    const initialScan = [{
        hospital_scan_id: '', isError: false, uuid: uuidv4(),
        scan_name: '', scan_type: '', indication: '', service_id: '',
        qty: 1, price: 0, total_amount: 0, showScan: false, purpose: '', cost:0,
    }]

    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [scanTests, setScanTests] = useState([]);
    const [scans, setScans] = useState(initialScan);
    const [isBlockDialog, setIsBlockDialog] = useState(false)



    //radiology dialog
    const [openScan, setOpenScan] = useState(false)
    const [scanCashItems, setScanCashItems] = useState([])
    const [scan_id, setScanId] = useState('')




    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''
    // const requested_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;


    const getScanTests = (eventTxt, idx) => {
        const arr = scans.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('hospital_scan_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocompletesearch_hospt_scans`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? [] : resp;
                        if (data.length === 0) {
                            setScanTests([{hospital_scan_id: '', hospital_scan_name: 'No Scans Found', value:'', label:'No Scans Found'}])
                        } else {
                            const arr = data.map(item=>({...item, value:item.hospital_scan_id, label:item.hospital_scan_name}))
                            setScanTests(arr);
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, scan_name: eventTxt, showScan: true, isError: false}
                }
                return {...item, scan_name: eventTxt, showScan: false, isError: false, hospital_test_type:''}
            }
            return item
        })
        setScans(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const retrieveScanDetails = (event, idx) => {
        let arr = scans.map((item, index)=>{
            if(index === idx){
                return {...item, scan_name:event.innerText, hospital_scan_id:event.value,
                    isError:false, showScan:false}
            }
            return item
        })
        const formData = new FormData();
        formData.append('hospital_scan_id', event.value);
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/getscandetails_by_scan_id`, formData).then(res => {
                const data = res.data;
                const details = !data ? {} : data;
                axios.post(`${config.smsUrl}/cbilling/item_cost`, {visit_id:+visit_id, item_id:+data.service_id, item_type:'service'}).then(res => {
                    const resObj = res.data;
                    const dataObj = !resObj.data ? {} : resObj.data;
                    arr = arr.map((item, index)=>{
                        if(index === idx){
                            return {...item, scan_type:details.scan_type_name, service_id: details.service_id,cost:  dataObj.cost ?? 0,
                                currency: dataObj.currency_id, currency_symbol: dataObj.currency, ...dataObj }
                        }
                        return item
                    })
                    setScans(arr);
                    if(dataObj.is_provider === 1 && dataObj.is_provided === 0){
                        setScanId(details.hospital_scan_id)
                        setOpenScan(true)
                    }
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
            }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
        }
        setIsBlocking(true)
    };

    const addScanToReceipt = () =>{
        const arr = scans.filter(item=>item.hospital_scan_id === scan_id)
        setScanCashItems(arr)
        setOpenScan(false)
    }

    const handleCloseScan = () =>{
        const arr = scans.filter(item=>item.hospital_scan_id !== scan_id)
        setScans(arr)
        setOpenScan(false)
    }

    const handleCancelScan = () =>{
        setOpenScan(false)
    }

    const handleChangePurpose = (e, index) => {
        const {value} = e.target
        const arr = scans.map((item, ind) => {
            if (ind === index) {
                return {...item, purpose: value}
            }
            return item
        })
        setScans(arr)
        setIsBlocking(true)
    }



    const handleAddScanRow = () => {
        setScans([...scans, ...initialScan])
    };

    const removeScanRow = (item_uuid) => {
        if (scans.length > 1) {
            const arr = scans.filter((e) => e.uuid !== item_uuid)
            setScans(arr);
        }
    };

    const handleSubmitScanRequest = (event) => {
        event.preventDefault();

        const radiology = scans.filter(scan => scan.hospital_scan_id && scan.service_id).map(item => ({
            hospital_scan_id: item.hospital_scan_id,
            service_id: item.service_id,
            purpose: item.purpose,
            quantity:1,
            rate:item.cost,
            is_provided: item.is_provided,
            is_provider: item.is_provider,
            currency_id:item.currency
        }))

        // required parameters
        const  params = {
            patient_number:patient_number, visit_type: +visit_type?.value ?? '',assigned_to: '',
            department_id: department_name.value ?? '', receptionist:user_roles_id, user_roles_id, patient_type: 1,
            result_type:  3,bill_type:2, visit_id: +visit_id,scan_state:1,
            bill_details:  radiology
        }

        setSubmitted(true);
        setIsBlocking(false)
        const radArr = scans.every(scan => scan.hospital_scan_id && scan.service_id)
        if (service_type && radArr) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cvisit/add_visit_scan_service`, {...params}).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess(`Scan request(s) submitted successfully`);
                setScans(initialScan)
                setSubmitted(false);
                if(setOpenDialog){
                    setOpenDialog(false)
                }
                const arr = scans.map(item=>({
                    ...item,
                    hospital_scan_id: '', isError: false, uuid: uuidv4(),
                    scan_name: '', scan_type: '', indication: '', service_id: '',
                    qty: 1, price: 0, total_amount: 0, showScan: false, purpose: '', cost:0,
                }))
                setScans(arr)
                setServiceType('')
            }).catch((err) => {
                errorMessages(err, null, actions)
                setSubmitted(false);
                setIsSubmitted('rejected');
            });
        }

    };

    const handleResetForm = () =>{
        if(setOpenDialog){
            setOpenDialog(false)
        }
        const arr = scans.map(item=>({
            ...item,
            hospital_scan_id: '', isError: false, uuid: uuidv4(),
            scan_name: '', scan_type: '', indication: '', service_id: '',
            qty: 1, price: 0, total_amount: 0, showScan: false, purpose: '', cost:0,
        }))
        setScans(arr)

        setIsBlockDialog(false)
        setIsBlocking(false)
        setSubmitted(false);
        setServiceType('')
    }

    const handleCloseRadiologyDialog = () => {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            handleResetForm()
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    return {
        isResolved,
        isRejected,
        handleSubmitScanRequest,
        handleCloseRadiologyDialog,
        submitted,
        renderScanRequest:(
            <React.Fragment>
                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />
                <ConfirmationDialog open={openScan} handleClose={handleCancelScan}
                                    title='Make cash payment'
                                    confirmItems={addScanToReceipt}
                                    removeItems={handleCloseScan} service='scan'/>
                <div className="row">
                    <div className="col-lg-6">

                        <Form.Group as={Row}>
                            <Label name="Department" column sm="3" type/>
                            <Col sm="8">
                                <CustomSelect submitted={submitted}   id='visit_department'
                                              value={department_name} options={[]}
                                              data-test="department_name" isDisabled={true}/>
                            </Col>
                        </Form.Group>
                    </div>
                    <div className="col-lg-6"/>
                </div>
                <RadiologyTable removeRow={removeScanRow} handleAddRow={handleAddScanRow} all_scans={scanTests}
                                scans={scans} getScanTests={getScanTests} isResolved={isResolved}
                                handleChangePurpose={handleChangePurpose}
                                retrieveScanDetails={retrieveScanDetails} submitted={submitted}/>
                <RightAlignedContainer>
                    <button type="submit" id='submit_form'
                            className="sms-btn btn btn-sm px-3 mt-3"  disabled={isPending}>{isPending ? 'Saving...' : 'Save Scan Request'}</button>
                    {/*<button type="button"  id="reset_form"  className="sms-gray-btn btn btn-sm ml-2" >Cancel</button>*/}
                </RightAlignedContainer>
            </React.Fragment>
        )
    }
}


