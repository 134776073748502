
import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../../Utils/smsTitle";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {formatDateTime} from "../../Utils/ConvertDate";
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import { useReferralReports } from "./CustomHooks/useReferralReports";
import { AccountingDateFilter } from "../../Utils/AccountingUtils/AccountingDateFilter";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {useExcelReports} from "../../Utils/ReusableComponents/useExcelExport";


const headData = [
    
    {id: 'date', numeric: false, disablePadding: false, label: 'Ref Date'},
    {id: 'hospitalName', numeric: false, disablePadding: false, label: 'Hospital From'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'phoneNumber', numeric: false, disablePadding: false, label: 'Contact'},
    {id: 'kin_name', numeric: false, disablePadding: false, label: 'Kin Name'},
    {id: 'kinContact', numeric: false, disablePadding: false, label: 'Kin Contact'},
    {id: 'patientType', numeric: false, disablePadding: false, label: 'Patient Type'},
    // {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

 const ReferralReport = ({actions,snackbars}) => {

     const components = [
         { label: 'Inward Referrals', path: '/reports/referralreports'},
         {label: 'Outward Referrals', path: '/reports/outwardreferrals'}];

         const {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, 
            referralList, submitted, handleDateFilterSubmit,p_type, handleChange,
            isLoading, isSuccess, date, handleChangeDate
        }= useReferralReports(actions,'incoming');
    

        const {openBar, type, message} = snackbars;
        const {referrals,total_count} = referralList;
        const {from_date, to_date} = date;
        const {patient_type} = p_type

        const excelObj = {file_name:'Refferal List', url:'cmedicationround/referral_list_report', params:{patient_type: p_type, start_date: '', end_date: '', referral_type:"incoming" }}

        const {exportExcel} = useExcelReports(actions, excelObj)

    return (
        <div data-testid="Referrals" className='journals'>
            <PageTitle title="Inward Referrals"/>
            <SubHeader title="Referrals" subTitle="Inward Referrals">
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                </div>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
            <AccountingDateFilter {...{from_date, to_date, handleChange:handleChangeDate, 
                        submitted,handleSubmit:handleDateFilterSubmit,isPending:isLoading}} />
                <div>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} >
                            <Row>
                            <Col xs={12} sm={2} md={2} lg={3} xl={2}>
                                <Label name="Patient Type" />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                                <SelectInput value={patient_type} onChange={handleChange} name="patient_type"
                                                options={[{value:'0', text:'All'},{value:'1', text:'Outpatient'},{value:'2', text:'Inpatient'}]}/>
                            </Col>
                        </Row>
                    </Col>
                <div style={{display:'flex', justifyContent: 'flex-end' }}>
                    <button className='btn sms-gray-btn' onClick={exportExcel}
                    style={{paddingTop:'2px'}}>Download</button>
                </div>
            </div>
                <MainSnackbar variant={type} handleCloseBar={closeSnackBar} open={openBar} message={message}/>
                <CustomTable title="Inward Referrals" customPage handleChangeNewPage={handleChangeNewPage}
                             headData={headData} handler={handleSearch} records={10} total_count={total_count}
                             activePage={activePage} data={referrals} colSpan={headData.length} term={searchValue}>
                    
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ?  referrals.length > 0 ? 
                                referrals.slice(0, 10).map((item) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.visit_id}
                                            className='mui-table-row'>
                                            <TableCell data-testid='date'>{formatDateTime(item.date)}</TableCell>
                                            <TableCell data-testid='hospital_from'>{item.referred_from}</TableCell>
                                            <TableCell data-testid='patient_number'>{item.patient_number}</TableCell>
                                            <TableCell data-testid='patientname'>{`${item.first_name} ${item.last_name}`}</TableCell>
                                            <TableCell data-testid='phone_no'>{item.phone_no}</TableCell>
                                            <TableCell data-testid='kin_name'>{item.kin_first_name && item.kin_last_name ? `${item.kin_first_name} ${item.kin_last_name}` : ''}</TableCell>
                                            <TableCell data-testid='kin_phone_no'>{item.kin_phone_no}</TableCell>
                                            <TableCell data-testid='patient_type'>{item.patient_type === 1 ? 'Out Patient' : item.patient_type === 2 ? 'In Patient' : null}</TableCell>
                                            
                                            
                                        </TableRow>
                                    );
                                }): 
                                <TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>
                            :null}
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralReport);


