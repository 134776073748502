import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import * as billingActions from "../../../actions/BillingActions/BillingActions";
import {bindActionCreators} from "redux";
import {SubHeader} from "../../../Containers/SubHeader";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import CustomTable, {useHiddenColumns} from "../../Utils/Tables/CustomTable";
import PageTitle from "../../Utils/smsTitle";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {titleCase} from "../../Users/addUser";
import {formatDateTime} from "../../Utils/ConvertDate";
import {useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import { useRefundedBills } from "./hooks/useRefundedBills";
import RefundedBills from "./RefundedBills";
import moment from "moment";

const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Bill Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient Number', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false}
];

/**
 * Component that renders the List of bills
 * @param {*} param0 
 * @returns 
 */
const BillingList = ({actions, snackbars}) => {
    const [activePage, setActivePage] = useState(1);
    const [term, setTerm] = useState('');
    const [billStatus, setBillStatus] = useState(0);
    const [billingList, setBillingList] = useState([])
    const [status, setStatus] = useState('idle');
    const [total_count, setTotalCount] = useState(0);

    const convertHourts = (date) =>{
        return moment().diff(moment(date), 'days');
    }

    const retrieveBillingList = () =>{
        const formData = new FormData();
        formData.append('search', term);
        formData.append('status', billStatus === 0 ? 0 : 1);
        formData.append('patient_type', 1);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cbilling/bill_list/${activePage}`, formData).then(res=>{
            const data = res.data;
            const bills = !data ? {} : data;
            const all_bills = !bills.bill_list ? [] : bills.bill_list;
            const count = !bills.total_count ? 0 : bills.total_count;
            const arr = all_bills.map(item=>({
                ...item, days:convertHourts(item.begin_datetime)
            }))
            setBillingList(arr);
            setTotalCount(count)
            setStatus('success')
          }).catch(err=>{
            logoutErrorMessage(err,null,actions)
            setStatus('error')
          })
    }

    useEffect(() => {
        if(billStatus === 2){
            return;
        }
        retrieveBillingList()
        const interval = setInterval(()=>{
            retrieveBillingList()
        }, 10000)

        return ()=>clearInterval(interval)

    }, [activePage, term, billStatus])


    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };

    const handler = (event) => {
        setTerm(event.target.value)
    }

    const closeSnackBar = () => {
        actions.snackbarAction.hideSnackbar();
    };

    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';

    const {openBar, type, message} = snackbars;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const components = [{label: 'Out-patient', path: '/paymentList'},
        {label: 'In-patient', path: '/patientbillinglist'},
        {label: 'Invoiced Bills', path: '/invoicedbills'},

    ]
    const { refundProps} = useRefundedBills(actions, billStatus)

    const all_bills = billingList.map((bill, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        const bill_status = {
            0: (
                <>
                    <Link
                        to={{pathname: `/newbill/${bill.visit_id}`}}
                        style={{
                            textDecoration: "none",
                            color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                            marginRight:'20px'
                        }}>
                        <button className="btn btn-sm sms-info-btn" style={{marginBottom:'4px', width:'130px'}}>Create Receipt
                        </button>

                    </Link>
                    <Link
                        to={{pathname:  `/createinvoice/${bill.visit_id}`}}
                        style={{
                            textDecoration: "none",
                            color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                        }}>
                        <button className="btn btn-sm sms-gray-btn mr-1" style={{marginBottom:'4px', width:'130px'}}>Create Invoice
                        </button>
                    </Link>
                    <Link to={{pathname: `/viewbill/${bill.visit_id}`}}
                          style={{
                              textDecoration: "none",
                              color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                          }}>
                        <button className="btn btn-sm sms-amber-btn ml-1" style={{marginBottom:'4px'}}>View
                        </button>
                    </Link>
                </>
            ),
            1: (
               <>
                   <Link to={{pathname: `/billingreceipt/${bill.visit_id}`}}
                         style={{
                             textDecoration: "none",
                             color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                         }}>
                       <button className="btn btn-sm sms-info-btn mr-1" style={{marginBottom:'4px'}}>View
                       </button>
                   </Link>
                   {bill.days<=1 ?
                       <Link to={{pathname: `/refundbill/${bill.visit_id}`}}
                             style={{
                                 textDecoration: "none",
                                 color: palette.type === "dark" ? "#fff" : "#3d3d3d",
                                 marginRight:'20px'
                             }}>
                           <button className="btn btn-sm sms-amber-btn" style={{marginBottom:'4px'}}>Refund
                           </button>
                       </Link>: null}
               </>
            ),
            2: (
                <Link to={{pathname: `/billingreceipt/${bill.visit_id}`}}
                      style={{
                          textDecoration: "none",
                          color: palette.type === "dark" ? "#fff" : "#3d3d3d"
                      }}>
                    <button className="btn btn-sm sms-info-btn mr-1" style={{marginBottom:'4px'}}>View
                    </button>
                </Link>
            )
        }
        return {
            ...bill,
            count,
            date:formatDateTime(bill.begin_datetime),
            patient_name: titleCase(`${!bill.patient_firstname ? "" : bill.patient_firstname} 
             ${!bill.patient_lastname ? "" : bill.patient_lastname}`),
            action: bill_status[billStatus]
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    return (
        <div className='journals'>
            <PageTitle title="Billing List"/>
            <SubHeader title="Billing" subTitle="Billing list">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='general-ledger-header inpatient-billing-header'>
                <a className={`billing-span btn btn-sm ${billStatus === 0 ? 'active-billing-span':''}`} onClick={()=>setBillStatus(0)}>Unpaid Bills</a>
                <a className={`billing-span btn btn-sm ${billStatus === 1 ? 'active-billing-span':''}`} onClick={()=>setBillStatus(1)}>Paid Bills</a>
                <a className={`billing-span btn btn-sm ${billStatus === 2 ? 'active-billing-span':''}`} onClick={()=>setBillStatus(2)}>Refunded Bills</a>
            </div>
            <div className='mui-tables'>
                <MainSnackbar handleCloseBar={closeSnackBar} message={message} open={openBar} variant={type}/>
                {billStatus === 2 ? <RefundedBills {...refundProps}/>:
                <CustomTable colSpan={7} data={all_bills}
                             headData={headCells} {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                             activePage={activePage} handler={handler}
                             handleChangeNextPage={handleChangeActivePage} total_count={total_count}
                             records={10} customPage term={term}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                                </TableRow> : null}
                            {isSuccess ?  all_bills.length > 0 ? all_bills.slice(0, 10)
                                .map((bill) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={bill.bill_id}
                                            className='mui-table-row'>
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{bill[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }): <TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isRejected ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody>
                </CustomTable>}
            </div>
        </div>
    );

}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar, billing: state.billing
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarAction: bindActionCreators(snackbarActions, dispatch),
            billingActions: bindActionCreators(billingActions, dispatch),
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingList);
