import React from "react";
import TextField from "../../Utils/FormInputs/TextField";
import {ErrorMessage} from "../../Utils/styledComponents";
import SelectInput, {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import {DataCell} from "../../Utils/Lists/CollapsibleList";


function NewExpense({expenses, expenseItems, submitted, handleChangeExpenseItem,
                        handleAddExpense,  handleChangeExpense, handleSubmitExpense,
                        handleRemoveExpense, isPending,currency, currencies,handleChangeAmount,
                        handleChangeCurrency, handleChangeExchangeRate, exchangeRate}){

    const isBase = currency.split('-')[1]
    const currency_symbol = currency.split('-')[2]
    const isNotBase = isBase === 'false'
    const isBaseCurrency = isBase === 'true'
    
    return (
        <form onSubmit={handleSubmitExpense} autoComplete='off'>
            {/*<div className="row">*/}
            {/*    <div className="col-lg-4">*/}
            {/*        <Form.Group as={Col}>*/}
            {/*            <Label name="Currency" sm="3" type/>*/}
            {/*            <SelectInput submitted={submitted} value={currency}*/}
            {/*                         onChange={handleChangeCurrency} options={currencies}*/}
            {/*                         defaultOption="Select..."/>*/}
            {/*        </Form.Group>*/}
            {/*        {isNotBase ?*/}
            {/*            <Form.Group as={Col} >*/}
            {/*                <Label  name="Exchange Rate" />*/}
            {/*                <InputGroup className="">*/}
            {/*                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
            {/*                    <TextField submitted={submitted} type="number" value={exchangeRate} onChange={handleChangeExchangeRate}/>*/}
            {/*                </InputGroup>*/}
            {/*                <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {currency.split('-')[2]} = {exchangeRate} USD)</span>*/}
            {/*            </Form.Group> : null}*/}
            {/*    </div>*/}
            {/*    <div className="col-lg-8"/>*/}
            {/*</div>*/}
            <table className="table table-bordered mt-3">
                <tr>
                    <td >Expense Item<span className="help-block">*</span></td>
                    <td>Receipt No</td>
                    <td>Amount{ currency_symbol ? `(${currency_symbol}) `: ''}<span className="help-block">*</span></td>
                    {isNotBase ? <td>Amount(USD)</td>: null}
                    <td >Action</td>
                </tr>
                <tbody>
                {expenses.map((item, index) => (
                    <tr key={index}>
                        <DataCell variant='300'>
                            <CustomSelect value={item.expense_item} options={expenseItems}
                                          onChange={value => handleChangeExpenseItem(value, index)}/>

                            {(submitted && !item.expense_item) ? <ErrorMessage>Expense item is required</ErrorMessage>:null}
                        </DataCell>
                        <DataCell variant='200'>
                            <TextField  {...{submitted}} value={item.receipt_no} type="text" name="receipt_no"
                                        onChange={(e) => handleChangeExpense(e, index)} placeholder="Enter receipt no."/>
                            {/*{(submitted && !item.receipt_no) ? <ErrorMessage>Receipt No. is required</ErrorMessage>:null}*/}
                        </DataCell>
                        <DataCell variant='200'>
                            <TextField  {...{submitted}} value={item.amount} type="number" name="amount"
                                        onChange={(e) => handleChangeAmount(e, index)} placeholder="Input amount"/>
                            {(submitted && !item.amount) ? <ErrorMessage>Amount is required</ErrorMessage>:null}
                        </DataCell>
                        {isNotBase ? <DataCell variant='200'>
                            <TextField   value={item.usd_amount} type="number" name="usd_amount"
                                        readOnly/>
                        </DataCell> : null}
                        <DataCell>
                            <button type='button' onClick={()=>handleRemoveExpense(index)} className='btn btn-sm sms-btn-dismiss'>Remove</button>
                        </DataCell>
                    </tr>
                ))}
                <tr>
                    <DataCell colSpan={4}> <button type='button' onClick={handleAddExpense} className='btn btn-sm sms-gray-btn'>Add another line</button></DataCell>

                </tr>
                </tbody>
            </table>
            <button type='submit' disabled={isPending}  className='btn btn-sm sms-btn'>{isPending ? 'Submitting...':'Submit'}</button>
        </form>
    )
}
export {NewExpense}