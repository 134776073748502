import React, {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useReceiveDebtorsPayments} from "./useReceiveDebtorsPayments";


export const useDebtorsReportDetails = (actions, match) => {
    const {customer_id, customer_name} = match.params;
    const [data, setData] = useState({invoices:[], loading:'idle', total_count:0})
    const [allDebtors, setAllDebtors] = React.useState({debtors:[], loading:'idle'});
    const [customer, setCustomer] = useState({id:customer_id, name:customer_name})
    const [activePage, setActivePage] = useState(1)
    const [rowData, setRowData] = useState({products:[], loading:'idle'})
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const {loading:loadingProducts} = rowData
    const {invoices,loading:loadingInvoices} = data
    const {loading:loadingDebtors} = allDebtors
    const {id, name} = customer
    const [total_debt, setTotalDebt] = useState(0);
    const obj = {customer_id:id, customer_name:name, data:invoices, total_debt, ...customer}
    const {paymentProps, isResolved} = useReceiveDebtorsPayments(actions, obj)
    const [term, setTerm] = React.useState('');
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        setAllDebtors({...allDebtors, loading: 'pending'})
        axios.get(`${config.smsUrl}/cbilling/debtors`,
        {params: {
            page:activePage,
            search:term
        }}).then(res => {
            const response = res.data;
            const arr = response ? response : [];
            const debt_arr = arr.length > 0 ? arr[0].provider_debt : []; 

            const activeItems = debt_arr.map(item=>({
                ...item, id:item.customer_id
            })).map(item=>{
                if(item.id === +customer_id){
                    const amount_due = item.total_debt 
                    console.log(amount_due)
                    console.log(item.total_debt)
                    setCustomer({...customer, total_amount_due: amount_due, amount_paid:item.amount_paid})
                    setTotalDebt(item.total_debt);
                    return {...item, isActive:'active'}
                }
                return item
            })
            setAllDebtors({debtors: activeItems, loading: 'success'});
        }).catch(err => {
            errorMessages(err, null, actions);
            setAllDebtors({...allDebtors, loading: 'error'});
        })
    }, [isResolved])

    useEffect(() => {
        if(!id){
            return
        }
        setData({...data, loading: 'loading'})
        axios.get(`${config.smsUrl}/cbilling/upaid_invoices`, {params:{customer_id:id}}).then(res => {
            const data = res.data;
            const arr = !data ? [] : data;
            const obj = arr[0] ? arr[0] : {}
            const _id = obj.invoice_no ? obj.invoice_no : ''
            if(_id){
                retrieveProducts(_id, obj)
            }
            const arrData = arr.map((row, index)=>{
                return {
                    ...row,
                    open:index === 0,
                    total_amount: (row.rate * row.quantity)
                }
            })
            setData({...data,invoices: arrData,  loading: 'success', total_count:arrData.length})
        }).catch(err => {
            errorMessages(err,null,actions)
            setData({...data, loading: 'error'})
        })
    }, [id, isResolved]);


    const handleClickCustomer = (item) =>{
        const {id:_id, provider_name, total_debt, amount_paid} = item
        const {debtors} = allDebtors
        const arr = debtors.map(item=>{
            if (item.id === _id  ){
                return {...item,  isActive:'active'}
            }
            return {...item,isActive:'inactive', count:0}
        })

        setCustomer({id:_id, name: provider_name, total_amount_due:total_debt-amount_paid, amount_paid})
        setTotalDebt(total_debt)
        setAllDebtors({...allDebtors, debtors: arr})
    }

 

    //-- is this a single bill

    const retrieveProducts = (_id, obj) =>{
        setRowData({products:[obj], loading: 'success'})

        /*
        axios.get(`${config.smsUrl}/Cinvoice/single_bill`,{params:{bill_id: _id}}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            setRowData({products: arr,  loading: 'success'})
        }).catch(err => {
            errorMessages(err,null,actions)
            setRowData({...rowData, loading: 'error'})
        })
        */
    }

    const handleOpen = (item) => {
        const {invoice_no:item_id} = item;
        const arr = invoices.map(row=>{
            if(row.invoice_no === item_id){
                return {...row, open:!row.open}
            }/* this is for if the user wants to open ONLY one list at a time
             else {
                return {...row, open:false}
            } */
            return row
        })
        // retrieveProducts(item_id)
        setData({...data, invoices: arr})
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeActivePage = (page) =>{
        setActivePage(page)
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };

    //products
    const isLoadingProducts = loadingProducts === 'loading'
    const isSuccessProducts = loadingProducts === 'success'
    const isErrorProducts = loadingProducts === 'error'

    //invoices
    const isLoadingInvoices = loadingInvoices === 'loading'
    const isSuccessInvoices = loadingInvoices === 'success'
    const isErrorInvoices = loadingInvoices === 'error'

    //debtors
    const isLoadingDebtors = loadingDebtors === 'loading'
    const isSuccessDebtors = loadingDebtors === 'success'
    const isErrorDebtors = loadingDebtors === 'error'

    return {data, rowData,activePage, isSuccessProducts, isLoadingProducts, isErrorProducts, handleOpen, handleChangeActivePage,closeSnackbar,
    page, rowsPerPage, handleChangeRowsPerPage, handleChangePage, handleClickCustomer, isLoadingDebtors,isLoadingInvoices, handleSearch,
    isSuccessDebtors, isSuccessInvoices, isErrorDebtors, isErrorInvoices, allDebtors, id, customer, paymentProps}
}