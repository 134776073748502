import React, {useState} from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import { ReportsTable, BorderedCell,BoldBorderedCell } from "../../Utils/Tables/ReportsTable";
import  TableRow  from "@material-ui/core/TableRow";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { LoadingGif } from "../../Utils/Loader";
import { useMohReports } from "./CustomHooks/useMohReports";
import { convertFemales, convertMales, filterData } from "../LabReports/LabTestsByGender";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReusableTabs from "../../Utils/Dialogs/ReusableTabs";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import CustomTable from "../../Utils/Tables/CustomTable";
import { TableBody, TableCell } from "@material-ui/core";
import { formatDateTime } from "../../Utils/ConvertDate";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { TabPanel } from "../../Utils/Dialogs/ReusableTabs";


const headData = [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'p_number', numeric: false, disablePadding: false, label: 'Patient Number'},
    {id: 'gender', numeric:false, disablePadding:false, label:'Gender'},
    {id: 'age', numeric:false, disablePadding:false, label:'Age'},
    {id: 'diagnosis', numeric:false, disablePadding: false, label:'Diagnosis Notes'}
    ]

const MohReports = ({actions}) => {
    const  {reports, isSuccess, isError, state, submitted, without_icd_notes,
        handleChange, handleSubmit, isPending, handleChangeNewPage, isLoading
    } = useMohReports(actions)
    const {start_date, end_date, patient_type} = state
    const { diagnosis, attendance, referrals } = reports
    const allReferrals = Object.keys(referrals)

    const arr = filterData(diagnosis)

    const sum = arr.reduce((a,item)=>{
        return a + item.total
    }, 0)

    const a28days = referrals['a0to28days'] ? referrals['a0to28days'] : {};  
    const a29days = referrals['a29daysto4Yrs'] ? referrals['a29daysto4Yrs'] : {};
    const a5yrs = referrals['a5to9Yrs'] ? referrals['a5to9Yrs'] : {};
    const a10yrs = referrals['a10to19Yrs'] ? referrals['a10to19Yrs'] : {};
    const a20yrs = referrals['a20Yrsandabove'] ? referrals['a20Yrsandabove'] : {};
    const referralTotal = convertMales(a28days) + convertFemales(a28days) + convertMales(a29days) + convertFemales(a29days) +
    convertMales(a5yrs) + convertFemales(a5yrs) +  convertMales(a10yrs) + convertFemales(a10yrs) + convertMales(a20yrs) + convertFemales(a20yrs);

    const title = {
        '1':'Outpatient',
        '2':'Inpatient'
    }

    const tabs = ['With ICD','Without ICD'];
    const [tabValue, setTabValue] = useState(0);
    
    const handleChangeTabValue = (event,val) => {
        setTabValue(val);
    }
  
    return (
        <div className='journals'>
            <PageTitle title='Outpatient Diagnosis'/>
            <SubHeader title="Reports" subTitle='Outpatient Diagnosis'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div style={{margin: '10px'}}>
                <BackButton text='General Reports' to='/reports/generalreports'/>
            </div>
            <div className="mx-2 mt-2">
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} >
                        <Row>
                            <Col xs={12} sm={2} md={2} lg={3} xl={2}>
                                <Label name="Patient Type" />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                                <SelectInput value={patient_type} onChange={handleChange} name="patient_type"
                                             options={[{value:'1', text:'Outpatient'},{value:'2', text:'Inpatient'}]}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} >
                        <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
                    </Col>
                </Row>

                <div className="row">
                    <div className="col-lg-6">
                        <h6 className="mt-2"><strong>{`${title[patient_type]} Attendance`}</strong></h6>
                        <ReportsTable {...{headTitle:'Category'}}>
                            {isLoading ? <LoadingGif/>: null}
                            {isSuccess ? attendance.length > 0 ? 
                            attendance.filter(item=>item.att_name).map((item,index)=>{
                                const a28days = item['a0to28days'] ? item['a0to28days'] : {};  
                                const a29days = item['a29daysto4Yrs'] ? item['a29daysto4Yrs'] : {};
                                const a5yrs = item['a5to9Yrs'] ? item['a5to9Yrs'] : {};
                                const a10yrs = item['a10to19Yrs'] ? item['a10to19Yrs'] : {};
                                const a20yrs = item['a20Yrsandabove'] ? item['a20Yrsandabove'] : {};
                                const total = convertMales(a28days) + convertFemales(a28days) + convertMales(a29days) + convertFemales(a29days) +
                                convertMales(a5yrs) + convertFemales(a5yrs) +  convertMales(a10yrs) + convertFemales(a10yrs) + convertMales(a20yrs) + convertFemales(a20yrs);
                                return(
                                    <TableRow key={index}>
                                        <BoldBorderedCell>{item.att_name ? item.att_name: ''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a28days['M'] ? a28days['M']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a28days['F'] ? a28days['F']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a29days['M'] ? a29days['M']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a29days['F'] ? a29days['F']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a5yrs['M'] ? a5yrs['M']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a5yrs['F'] ? a5yrs['F']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a10yrs['M'] ? a10yrs['M']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a10yrs['F'] ? a10yrs['F']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a20yrs['M'] ? a20yrs['M']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{a20yrs['F'] ? a20yrs['F']:''}</BoldBorderedCell>
                                        <BoldBorderedCell>{total}</BoldBorderedCell>
                                    </TableRow>
                                    
                                )
                            })
                            :<TableRow><BorderedCell colSpan={12} align="center">No records found</BorderedCell></TableRow>
                            :null}
                            {isError ? <TableRow><BorderedCell colSpan={12} align="center">The server did not return a valid response</BorderedCell></TableRow>: null}
                        </ReportsTable>
                    </div>
                    <div className="col-lg-6">
                        <h6 className="mt-2"><strong>{`${title[patient_type]} Referrals `}</strong></h6>
                        <ReportsTable {...{headTitle:'Category'}}>
                            {isLoading ? <LoadingGif/>: null}
                            {isSuccess ? allReferrals.length > 0 ? 
                            <TableRow>
                                <BorderedCell>Referrals from unit</BorderedCell>
                                <BoldBorderedCell>{a28days['M'] ? a28days['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a28days['F'] ? a28days['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a29days['M'] ? a29days['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a29days['F'] ? a29days['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a5yrs['M'] ? a5yrs['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a5yrs['F'] ? a5yrs['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a10yrs['M'] ? a10yrs['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a10yrs['F'] ? a10yrs['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a20yrs['M'] ? a20yrs['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{a20yrs['F'] ? a20yrs['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{referralTotal}</BoldBorderedCell>
                            </TableRow>
                            :<TableRow><BorderedCell colSpan={12} align="center">No records found</BorderedCell></TableRow>
                            :null}
                            {isError ? <TableRow><BorderedCell colSpan={12} align="center">The server did not return a valid response</BorderedCell></TableRow>: null}
                        </ReportsTable>
                    </div>
                </div>

                <ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>
                    
                    <TabPanel value={tabValue} index={0}>
                        <div>
                            <h6 className="mt-4"><strong>{`${title[patient_type]} Diagnoses`}</strong></h6>
                            <ReportsTable {...{headTitle:'Diagnosis'}}>
                                {isLoading ? <LoadingGif/>: null}
                                {isSuccess ? diagnosis.length > 0 ? 
                                <>
                                {arr.filter(item=>item['Diagnosis'] && item['Diagnosis_code']).map((item,index)=>{
                                    return(
                                        <TableRow key={index}>
                                            <BorderedCell>{`${item['Diagnosis_code'] ? item['Diagnosis_code'] : ''}. ${item['Diagnosis'] ? item['Diagnosis'] : ''}`}</BorderedCell>
                                            <BoldBorderedCell>{item.a28days['M'] ? item.a28days['M']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a28days['F'] ? item.a28days['F']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a29days['M'] ? item.a29days['M']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a29days['F'] ? item.a29days['F']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a5yrs['M'] ? item.a5yrs['M']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a5yrs['F'] ? item.a5yrs['F']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a10yrs['M'] ? item.a10yrs['M']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a10yrs['F'] ? item.a10yrs['F']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a20yrs['M'] ? item.a20yrs['M']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.a20yrs['F'] ? item.a20yrs['F']:''}</BoldBorderedCell>
                                            <BoldBorderedCell>{item.total}</BoldBorderedCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow>
                                    <BorderedCell colSpan={11} align="right"><strong>Total</strong></BorderedCell>
                                    <BorderedCell><strong>{sum}</strong></BorderedCell>
                                </TableRow>
                                </> 
                                :<TableRow><BorderedCell colSpan={12} align="center">No records found</BorderedCell></TableRow>
                                :null}
                                {isError ? <TableRow><BorderedCell colSpan={12} align="center">The server did not return a valid response</BorderedCell></TableRow>: null}
                            </ReportsTable>
                        </div>
                    </TabPanel>

                    <TabPanel value={tabValue} index={1}>
                        <CustomTable  headData={headData} data={without_icd_notes} customPageNoPagination colSpan={headData.length} handleChangeNextPage={handleChangeNewPage}>
                            <TableBody>
                                {without_icd_notes.length > 0 ? 
                                without_icd_notes.map((item, idx) => {
                                    return (
                                        <TableRow hover role="checkbox" key={item.visit_id} tabIndex={-1} className="mui-table-row">
                                            <TableCell><span>{formatDateTime(item.begin_datetime)}</span></TableCell>
                                            <TableCell>{item.patient_number}</TableCell>
                                            <TableCell>{item.gender}</TableCell>
                                            <TableCell>{item.age}</TableCell>
                                            <TableCell>{item.notes}</TableCell>
                                        </TableRow>
                                    )
                                }) : null}

                            </TableBody>
                        </CustomTable>  
                    </TabPanel>
                </ReusableTabs>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(MohReports);
