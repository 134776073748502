import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableBody from "@material-ui/core/TableBody";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {useWardCensus} from "./CustomHooks/useWardCensus";
import { BorderedCell } from "../../Utils/Tables/ReportsTable";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import MonthPickerInput from "react-month-picker-input";
import 'react-month-picker-input/dist/react-month-picker-input.css';



const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    tableCell:{
      border:'1px solid rgb(90 88 88)',
      padding:'8px'
    }
});


export const CensusInformationTable = ({actions}) => {

    const {handleChange, isPending,  state, census_info, handleSubmit} = useWardCensus(actions);
    const {month, year} = state;
    const classes = useStyles();

    // wards
    const wards_census = [{census:'A. No. of beds'}, {census:'B. No. of admissions'}, {census:'D. Patient days'}, {census:'E. Average length of stay (E=D/B)'}, 
    {census: 'F. Average occupancy (F=D/30 days)'}, {census: 'G. Bed occupancy (F/A)x100'}];
    return (
        <div>
            <PageTitle title='Ward Report'/>
            <SubHeader title="Reports" subTitle='Ward Report'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div style={{paddingLeft: "15px", paddingRight:"15px"}}>
                <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                </div>
                <div className="mx-2 mt-2" style={{display:'flex', marginBottom:'20px'}}>
                    <MonthPickerInput year={year} className="month-picker-style"
                        month={month}
                        onChange={(maskedValue, selectedYear, selectedMonth) => handleChange(maskedValue, selectedYear, selectedMonth)}
                    />
                    <button onClick={handleSubmit} className='btn btn-sm sms-info-btn' disabled={isPending}>{isPending ? 'Searching...':'Search'}</button>
                </div>

                {/* CENSUS INFORMATION*/}
                <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table} aria-label="spanning table">
                        <TableHead className="table-grey">
                            <TableRow>
                                <BorderedCell colSpan="9">
                                    <strong>CENSUS INFORMATION</strong>
                                </BorderedCell>
                            </TableRow>
                            <TableRow>
                                <BorderedCell colSpan="2"><strong>Wards</strong></BorderedCell>
                                {wards_census.map((item, index) => (
                                    <BorderedCell key={index} align="center" colSpan="1"><strong>{item.census}</strong></BorderedCell>                              
                                ))}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {census_info.length > 0 ?
                             census_info.map((item, index) => {
                                    return (
                                        item.name === "Total Count" ? 
                                            <TableRow key={index}>
                                                <BorderedCell colSpan="2"><strong>{item.name}</strong></BorderedCell>
                                                <BorderedCell align="center"><strong>{item.total_bed_count}</strong></BorderedCell>
                                                <BorderedCell align="center"><strong>{item.total_admissions}</strong></BorderedCell>
                                                <BorderedCell align="center"><strong>{item.total_patient_days}</strong></BorderedCell>
                                                <BorderedCell align="center"><strong>{item.average_length_of_stay}</strong></BorderedCell>
                                                <BorderedCell align="center"><strong>{item.average_occupancy}</strong></BorderedCell>
                                                <BorderedCell align="center"><strong>{item.bed_occupancy_calcualtion}</strong></BorderedCell>
                                            </TableRow>                                
                                        :
                                            <TableRow key={index}>
                                                <BorderedCell colSpan="2">{item.name}</BorderedCell>
                                                <BorderedCell align="center">{item.total_bed_count}</BorderedCell>
                                                <BorderedCell align="center">{item.total_admissions}</BorderedCell>
                                                <BorderedCell align="center">{item.total_patient_days}</BorderedCell>
                                                <BorderedCell align="center">{item.average_length_of_stay}</BorderedCell>
                                                <BorderedCell align="center">{item.average_occupancy}</BorderedCell>
                                                <BorderedCell align="center">{item.bed_occupancy_calcualtion}</BorderedCell>
                                            </TableRow>
                                    )
                            }): 
                            
                             <TableRow>
                                    <BorderedCell colSpan="9">No data avaliable for specified days</BorderedCell>
                            </TableRow> 
                            }  
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )

}

/*
const WardReport = ({actions, snackbars}) => {
    const {data, search_type, text, textType, year, date,csvRef,downloadCsv,downloadPdf,
        isSubmitted, submitted, searchValue, order, orderBy, loading, total_count,
        handleChangeActivePage, handleCustomSearch, activePage, closeSnackbar,   handleRequestSort,
        handleChangeSearch, handleChangeDate, handleChangeYear, handleIncrementYear, handleSubmit,inputType
    } = useWardCensus(actions)
    const dataArr = data.data ?? [];
    const {start_date, end_date} = date;
    const {openBar, type, message} = snackbars;
    const headers = [{label: 'Ward', key: 'ward_name'}, {label: 'No. of Beds', key: 'number_of_beds'},
        {label: 'No. of Admissions', key: 'number_of_admissions'}, {label: 'No. of Deaths', key: 'number_of_deaths'},
        {label: 'Patient Days', key: 'patient_days'},{label: 'Average Length of Stay', key: 'av_length_of_stay'},
        {label: 'Average Occupancy', key: 'av_occupancy'},{label: 'Bed Occupancy', key: 'bed_occupancy'}]

    const wards_types = repeat({ward_name:'', A:0, B:0, C:0, D:0, E:0, F:0, G:0} , 19);
    const ward_names = ["Male medical ward", "Female medical ward", "Paediatrics Ward", "Maternity/Obsteric ward",
    "Male surgical", "Female surgical", "TB ward", "Psychiatric ward", "Emergency ward", "Gynaecology ward", "Acute care unit (ACU)",
    "Palliative ward", "Eye ward", "Intensive Care Unit (ICU)", "Nutrition Ward/Corner", "Ear, Nose and Throat (ENT)", "Orthopaedic",
    "Others", "Totals"];

    const ward_cells = addingNames(wards_types, "ward_name", ward_names); 
    return (
        <div className='journals'>
            <PageTitle title='Ward Report'/>
            <SubHeader title="Reports" subTitle='Ward Report'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                     handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                     changePeriod={handleChangeSearch} end_date={end_date}
                                     handleChangeValue={handleChangeYear} submitted={submitted}
                                     search_type={search_type} handleChangeYear={handleIncrementYear}
                                     start_date={start_date} year={year} inputType={inputType}>
                    <DataExportButton headers={headers} title='Ward Census'
                                      csvRef={csvRef} data={dataArr} downLoadPdf={downloadPdf}
                                      exportCsv={downloadCsv}/>
                </MainSearchComponent>
            </div>
            <div className="mui-tables mt-2">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title='Census Information' handleChangeNextPage={handleChangeActivePage} term={searchValue}
                             records={10} headData={headData} handler={handleCustomSearch} total_count={total_count}
                             activePage={activePage} handleRequestSort={handleRequestSort} data={dataArr} colSpan={5} order={order}
                             orderBy={orderBy} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="8" align="center"><img src="/images/listPreloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : dataArr.length > 0 ?

                        <TableBody>
                            {stableSort(dataArr, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{item.date}</TableCell>
                                            <TableCell>{item.patient_number}</TableCell>
                                            <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                            <TableCell>{item.hospital_scan_name}</TableCell>
                                            <TableCell>{item.radiologist_name}</TableCell>
                                            <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                            <TableCell>{item.hospital_scan_name}</TableCell>
                                            <TableCell>{item.radiologist_name}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan="8"  align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>

            </div>
        </div>
    )
};
*/

/*
function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(WardReport);*/
