import React from "react";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {titleCase} from "../Users/addUser";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {dateTimeConvert, formatDateTime} from "../Utils/ConvertDate";
import {history} from "../../Helpers/history";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import { useEnterResults } from "./CustomHooks/useEnterResults";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import EnterResults from "./EnterResult";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";


const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false},
];

export const PendingSamples = ({actions, snackbars}) => {
    const {resultProps,openDialog, handleOpenDialog, handleCloseDialog,searchValue, order, orderBy,
        activePage, total_count, reports:samples,handleSearch, handleRequestSort, handleChangeNextPage,closeSnackbar,
         isError,isLoading, isSuccess, state, submitted,
         isPending, handleChange, handleSubmit} = useEnterResults(actions)
    const {openBar, type, message} = snackbars;
    const components = [{label: 'Pending Lab Requests', path: "/labrequests"},
    {label: 'Pending Lab Results', path:  "/pendingsamples"}, {label: ' Lab Results', path: "/labresultlist"},
    {label: 'Lab Reports', path: "/labreports"}]       
    const samplesList = !samples.data ? [] : samples.data;

    const reports = samplesList.map((item, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...item,
            count,
            date:item.time_taken ? formatDateTime(item.time_taken) : "",
            patient_name:titleCase(`${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "":item.patient_lastname}`),
            doctor_name:titleCase(`${!item.doctor_firstname ? "" : item.doctor_firstname} ${!item.doctor_lastname ? "":item.doctor_lastname}`),
            action: (
                <button onClick={() => handleOpenDialog({patient_type:item.patient_type,
                    visit_id:item.visit_id, labrequest_id:item.labrequest_id, activity_id:item.activity_id})}
                        className="btn btn-sm sms-info-btn">Record Results</button>
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);
    const {start_date, end_date} = state

    const {isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm} = resultProps
    return (
        <div className='journals'>
            <PageTitle title='Pending Lab Results'/>
            <SubHeader title="Laboratory" subTitle='Pending Lab Results'>
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <CustomDialog title='Record Results' open={openDialog} handleClose={handleCloseDialog} maxWidth="md"
                          isContentOverflow={false} isPaperStyle>
                <EnterResults {...{...resultProps, count:total_count}}/>
            </CustomDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='mui-tables'>
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending}}/>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <CustomTable title="Pending Lab Results" headData={headCells} handler={handleSearch} term={searchValue}
                             handleRequestSort={handleRequestSort} data={reports} colSpan={headCells.length} order={order}
                             orderBy={orderBy} {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                             customPage activePage={activePage} total_count={total_count} records={10}
                             handleChangeNextPage={handleChangeNextPage}>
                        <TableBody>
                            {isLoading ? <TableRow>
                        <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow>:null}
                            {isSuccess ?  reports.length > 0 ? stableSort(reports, getComparator(order, orderBy))
                                .slice(0,10)
                                .map((item) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.visit_id}
                                            className='mui-table-row'
                                           >
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">the server did not return a valid response</TableCell>
                            </TableRow>: null }
                        </TableBody>
                       
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingSamples);
