import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col"
import  { ReusablePhoneInput} from "../../Utils/FormInputs/FormGroup";
import Label from "../../Utils/FormInputs/Label";
import Card from "@material-ui/core/Card";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {useAddAdmission} from "./hooks/useAddAdmission";
import {titleCase} from "../../Users/addUser";
import { CustomSelect } from "../../Utils/FormInputs/SelectInput";
import { FullScreenDialog } from "../../Utils/Dialogs/FullScreenDialog";
import { AssignBed } from "../../BedManager/AddEditBedAssign";
import TextField from "../../Utils/FormInputs/TextField";
import {CenteredContainer, ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {ProviderForm} from "../NewVisit/NewVisit";
import {ConfirmationDialog} from "../../Utils/ReusableComponents/ConfirmationDialog";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import { useNewVisit } from "../NewVisit/hooks/useNewVisit";
import { ReferralTextArea } from "../../DoctorsModule/ReferralNote/MainReferralNote";
import { ReferralTextInput } from "../../DoctorsModule/ReferralNote/MainReferralNote";
import { ReferralTextField } from "../../DoctorsModule/ReferralNote/MainReferralNote";




export const AdmissionForm = ({state, p_number, showNumber, patientNumbers, submitted, relationship, handleChangeRelationship, 
    patient_name, handleChange, handleChangePatientNumbers, handleClickPatientNumber, handleReset,patient_number,
     handleSubmit, contact,setContact, isPending, search_parameter, handleChangeSearchParam, addReferral, handleAddReferral,referralProps,
     isProvider,   isOnInsurance, handleChangeIsInsurance,providerProps,handleOpenNewInsurance}) =>{

    const {handleChangeReferral,referrals,company_name}= referralProps
    const theme = useTheme();
    const {admission_date, first_name, address, nin} = state;

    const {referral_date,  from_hospital, patient_history, diagnosis, treatment} = referrals;

    const searchLabel = {
        'patient_no':'Patient No.',
        'patient_name':'Patient Name',
        'phone_no':'Phone No.'
    }

    const guardianDetails = (
        <>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                    <Label name="Guardian(care taker) Name" type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <TextField onChange={handleChange} name="first_name" value={titleCase(first_name)} type="text"
                               submitted={submitted}
                    />
                    {(submitted && !first_name) &&
                        <ErrorMessage>Guardian name is required</ErrorMessage>}
                </Col>
            </Form.Group>
            <Form.Group as={Row}>
                <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                    <Label name="Guardian(care taker) Address" type/>
                </Col>
                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                    <ResizableTextarea onChange={handleChange} name="address" value={titleCase(address)} type="text"
                                       submitted={submitted} rows='3'
                    />
                    {(submitted && !address) &&
                        <ErrorMessage>Guardian address is required</ErrorMessage>}
                </Col>
            </Form.Group>
            {/*<FormGroup onChange={handleChange} name="first_name" value={titleCase(first_name)} type="text"*/}
            {/*           labelName=" Guardian  Name" colSm="7" labelSm="4"*/}
            {/*           dataTest="first_name" submitted={submitted} labelType/>*/}
            {/*<FormTextArea onChange={handleChange} name="address" value={titleCase(address)} type="text"*/}
            {/*              labelName="Guardian Address" colSm="7" labelSm="4" dataTest="address" submitted={submitted} labelType/>*/}
        </>

    )
    return(
        <form onSubmit={handleSubmit} autoComplete="off">
        <div className="row">
            <div className="col-md-6">
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Label name="Search by:"  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <Form.Check type="radio" inline label="Patient No." name="search_parameter" value="patient_no"
                                    checked={search_parameter === "patient_no"}
                                    onChange={handleChangeSearchParam} data-testid="patient_no"/>
                        <Form.Check type="radio" inline label="Patient Name" name="search_parameter" value="patient_name"
                                    checked={search_parameter === "patient_name"}
                                    onChange={handleChangeSearchParam} data-testid="patient_name"/>
                        <Form.Check type="radio" inline label="Phone No." name="search_parameter" value="phone_no"
                                    checked={search_parameter === "phone_no"}
                                    onChange={handleChangeSearchParam} data-testid="phone_no"/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Label name={searchLabel[search_parameter]}  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <TextField type="text" disabled={patient_number}
                                        submitted={submitted}
                                      className={`form__control ${theme.palette.type === "dark" && 'form-control-dark'}`}
                                      name="p_number"
                                      value={p_number}
                                      onChange={handleChangePatientNumbers} id="patient_id"/>
                        {(submitted && !p_number) &&
                            <ErrorMessage>Patient number is required</ErrorMessage>}
                        {showNumber && <Form.Control as="select" multiple id="patient_number_select"
                                                     className="drug-ul visit-ul">
                            {patientNumbers.map((number, index) => (
                                <option className="drug-li" key={index}
                                        onClick={()=>handleClickPatientNumber({value:number.value,innerText:number.label})}
                                        value={number.label} id="numbers">{number.label}</option>
                            ))}
                        </Form.Control>}
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Label name="Patient  Name"/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <TextField disabled={true} name="patient_name" value={titleCase(patient_name)} type="text"
                        />
                    </Col>
                </Form.Group>
                {/*<FormGroup disabled={true} name="patient_name" value={titleCase(patient_name)} type="text"*/}
                {/*           labelName=" Patient  Name" colSm="7" labelSm="4"*/}
                {/*           dataTest="patient_name"/>*/}
                {isOnInsurance && isProvider ?
                    <ProviderForm {...{...providerProps, isDisabled:true, labelSm:"4", colSm:"7"}}/> :  null}
                <Col sm="11">
                    {isOnInsurance && !isProvider ? <RightAlignedContainer>
                        <button type='button' onClick={handleOpenNewInsurance} className='btn btn-sm sms-info-btn mb-2'>Add Provider</button>
                    </RightAlignedContainer>:null}
                    {isOnInsurance && isProvider ? <RightAlignedContainer>
                        <button type='button' onClick={handleOpenNewInsurance} className='btn btn-sm sms-info-btn mb-2'>Change Provider</button>
                    </RightAlignedContainer> : null}
                </Col>

                {/*{patient_name ?*/}
                {/*    <Form.Group className="switch-admission-prescription">*/}
                {/*        <Label name="Do you want to use Insurance?" className="label-admission-prescription"/>*/}
                {/*        <text className="label-admission-prescription">No</text>*/}
                {/*        <Form.Check type="switch" id="is_on_insurance" defaultChecked={isOnInsurance} onChange={handleChangeIsInsurance}*/}
                {/*                    className="toggle-admission-prescription"/>*/}
                {/*        <text className="label-admission-prescription">Yes</text>*/}
                {/*    </Form.Group>: null}*/}

                {!isOnInsurance ? guardianDetails : null }

                    <Form.Group className="switch-admission-prescription">
                        <Label name="Do you want to add a refferal?" className="label-admission-prescription"/>
                        <text className="label-admission-prescription">No</text>
                        <Form.Check type="switch" id="customswitch" defaultChecked={addReferral} onClick={handleAddReferral}
                        className ="switch-add-referral" />
                        <text className="label-admission-prescription">Yes</text>
                    </Form.Group>

                  
            </div>
            <div className="col-md-6">
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Label name="Admission Date"  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <TextField onChange={handleChange} name="admission_date"
                                   value={admission_date} type="date" submitted={submitted}
                                   disabled={true}/>
                    </Col>
                </Form.Group>
                {/*<FormGroup labelType={true} onChange={handleChange} name="admission_date"*/}
                {/*           value={admission_date} type="date"*/}
                {/*           labelName="Admission Date" colSm="7" labelSm="4" submitted={submitted}*/}
                {/*           dataTest="admission_date" disabled={true}/>*/}
                {isOnInsurance ? guardianDetails : null }

                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Label name="Guardian(care taker) Contact"  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <ReusablePhoneInput submitted={submitted} contact={contact} setContact={setContact} required/>
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Label name="Guardian(care taker) NIN" />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <TextField onChange={handleChange} name="nin" value={nin.toUpperCase()} type="text"
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row}>
                    <Col xs={12} sm={12} md={12} lg={5} xl={5}>
                        <Label name="Relationship"  type/>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <CustomSelect value={relationship} id ='kin_relationship' onChange={handleChangeRelationship}options={[{value:'Mother',label:'Mother'},{value:'Father',label:'Father'},
                            {value:'Wife',label:'Wife'},{value:'Husband',label:'Husband'},
                            {value:'Sister',label:'Sister'},{value:'Brother',label:'Brother'},
                            {value:'Spouse',label:'Spouse'},{value:'Guardian',label:'Guardian'},
                            {value:'Other',label:'Other'}]} dataTest="admission-relation"/>
                    </Col>
                </Form.Group>
            {/*<Form.Group as={Row}>*/}
            {/*    <Label name="Relationship" column sm="4" type/>*/}
            {/*    <Col sm="7">*/}
            {/*        <CustomSelect value={relationship} onChange={handleChangeRelationship}options={[{value:'Mother',label:'Mother'},{value:'Father',label:'Father'},*/}
            {/*            {value:'Wife',label:'Wife'},{value:'Husband',label:'Husband'},*/}
            {/*            {value:'Sister',label:'Sister'},{value:'Brother',label:'Brother'},*/}
            {/*            {value:'Spouse',label:'Spouse'},{value:'Guardian',label:'Guardian'},*/}
            {/*            {value:'Other',label:'Other'}]}/>*/}
            {/*    </Col>*/}
            {/*</Form.Group>*/}
            
            </div>
        </div>
        {addReferral ? 
                    
                    <div className="row">
                    <div className="col-md-6 mt-3">
                        
                        <ReferralTextField submitted={submitted} value={referral_date} labelSm="4" colSm="8"
                                            name="referral_date" dataTest="referral_date" type="date"
                                            labelName="Date of Referral" disabled={true}/>
                    

                        <ReferralTextInput onChange={handleChangeReferral} value={from_hospital} colSm="8"
                                            labelSm="4" name="from_hospital" type="text" labelName="FROM: "
                                            dataTest="from_hospital" required={true} submitted={submitted}/>
                                            

                        
                        <ReferralTextInput value={company_name} colSm="8" disabled={true}
                                            labelSm="4" name="to_hospital" type="text" labelName="TO: "/>

                        <Form.Group as={Row}>
                            <Col xs={10} sm={12} md={12} lg={5} xl={5}>
                                <Label name="Reason"/>
                            </Col>
                            <Col xs={12} sm={14} md={12} lg={7} xl={7}>
                           <ReferralTextArea value={patient_history} onChange={handleChangeReferral} name="patient_history"
                                              dataTest="history"/>
                            </Col>
                        </Form.Group>
                        
                        {/* <Form.Group as={Row}  className="referral-form-group">
                            <Form.Label column sm={"4"}  className="sms-form-label">REFERENCE:</Form.Label>
                            <Col sm={"8"}/>
                        </Form.Group> */}
                        
                        {/* <ReferralTextInput value={patient_number ? patient_number : ''} colSm="8"
                                            labelSm="4" type="text" labelName="Patient Number:"
                                            dataTest="patient_number" disabled={true}/>
                        
                        <ReferralTextInput value={patient_name} colSm="8"
                                            labelSm="4" type="text" labelName="Patient Name:"
                                            dataTest="patient_name" disabled={true}/>
                                        

                        
                        <ReferralTextInput onChange={handleChangeReferral} value={visit_date} colSm="8"
                                            labelSm="4" name="visit_date" type="date" labelName="Date of visit"
                                            dataTest="visit_date"/> */}
                                        
                    </div>
                    
                    <div className="col-md-6 mt-3">
                        <ReferralTextArea value={diagnosis} onChange={handleChangeReferral} name="diagnosis" 
                                            labelName="Diagnosis" submitted={submitted} required={true}
                                            dataTest="diagnosis"/>
                                        

                        <ReferralTextArea value={treatment} onChange={handleChangeReferral} name="treatment"
                                            labelName="Treatment Given"
                                            dataTest="treatment"/>
                                    
                    </div>
                           
                    </div>
            
                :null}


         <RightAlignedContainer>
            <button type="button" id="reset" onClick={handleReset}
                    className="btn btn-sm sms-grey-btn px-4 mr-2">Reset
            </button>
            <button type="submit"  id='submit_form' className="btn btn-sm sms-btn px-4" disabled={isPending}
                    data-testid="submitAdmissionForm">
                {isPending ? "Saving..." : "Save"}</button>
         </RightAlignedContainer>
    </form>
    )
}


export const PatientAdmissionForm = ({actions, match, snackbars,props}) => {
    const {patient_number} = match.params;
    const matchProps = {patient_number, visit_id:'', labrequest_id:'', scan_request_id:'', patient_type:'',patient_admission_id:''}
    
    const {admissionProps, printDialog,handleClosePrintDialog,  handleClick, closeSnackbar,referralProps,
        assignProps, handleCloseDialog, openDialog} = useAddAdmission(actions, patient_number, true,matchProps)

    const {providerProps, openNewInsurance, handleCloseNewInsurance,isPendingProvider,
        handleSubmitProvider, handleOpenNewInsurance, newProviderProps, handleReferralDialog,referralDialog} =  admissionProps
 
    const  {patientDetails} = useNewVisit(actions, patient_number);

    const {first_name, last_name, phone_no} = patientDetails;

    const theme = useTheme();
    const {openBar, type, message} = snackbars;
    const {openConfirmation, handleCloseConfirmation, handleBillRoom} = assignProps
    return (
        <div id="add-document">
            <PageTitle title="Admission Form"/>
            <SubHeader title="Visit" subTitle="Admission form">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <ConfirmationDialog open={openConfirmation} handleClose={handleCloseConfirmation}
                                title='Make cash payment'
                                confirmItems={handleBillRoom}
                                removeItems={handleCloseConfirmation} service='room'/>
            <FullScreenDialog open={openDialog} handleClose={handleCloseDialog} title="Assign Bed">
                <AssignBed {...assignProps}/>
            </FullScreenDialog>
            <div className="add-bill-card">
                <MainSnackbar message={message} open={openBar} handleCloseBar={closeSnackbar} variant={type}/>
                <PrintDialog message="Assign Bed" openDialog={printDialog} handleClose={handleClosePrintDialog}
                             text="Would you like to assign this patient a bed" handleClick={handleClick}
                             handleCancel={handleClosePrintDialog}/>
                <CustomDialog open={openNewInsurance} handleClose={handleCloseNewInsurance} title='Add Provider' isContentOverflow={true}>
                    <form onSubmit={handleSubmitProvider}>
                        <ProviderForm {...newProviderProps}/>
                        <CenteredContainer>
                            <button type='submit' disabled={isPendingProvider} className='btn btn-sm sms-btn'>{isPendingProvider ? 'Saving...' : 'Save'}</button>
                        </CenteredContainer>
                    </form>
                </CustomDialog>
                <Card className="p-3 general-card">
                    <h6 className={`visit-heading ${theme.palette.type === "dark" && "dark-visit-heading"}`}>Patient Admission Form</h6>
                    <AdmissionForm {...{...admissionProps, providerProps,handleOpenNewInsurance,referralProps,snackbars,props}}/>
                </Card>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientAdmissionForm);
