import * as actions from "./billingActions"



export function billingProductsReducer(state, action) {
    const {products} = state
    switch (action.type) {

        case actions.RETRIEVE_PRODUCTS:{
            return {...state, products:action.products, productsPaid:action.products}
        }

        case actions.CHANGE_PRODUCT_CURRENCY_RATE : {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value * +action.exchangeRate : 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate:action.exchangeRate ?  +action.value * +action.exchangeRate : 0,currency_rate: action.value,
                        subTotal: base_total,isRate:true, currency_total: +action.value * +item.quantity}
                }
                return item
            })
            const prods = arr.filter(item=>!item.isExcluded)
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id && !item.isRate).map(item=>({...item, rate: +item.rate}));
            action.calculateTotal(action.services, prods, action.exchangeRate, action?.bedBill);

            return {...state, products:arr, productsEdited:edited, productsPaid:unedited}

        }

        case actions.CHANGE_PRODUCT_RATE: {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +action.value / +action.exchangeRate : 0) * +item.quantity
                if (item.bill_id === action.bill_id){
                    return {...item,rate:action.value,currency_rate: action.exchangeRate ?  +action.value / +action.exchangeRate : 0,
                        subTotal: +item.quantity * +action.value,isRate:true, currency_total:base_total}
                }
                return item
            })
            const prods = arr.filter(item=>!item.isExcluded)
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate}));
            const unedited = arr.filter(item=>item.bill_id !== action.bill_id && !item.isRate).map(item=>({...item, rate: +item.rate}));
            action.calculateTotal(action.services, prods, action.exchangeRate,action?.bedBill);
            return {...state, products:arr, productsPaid:unedited, productsEdited:edited}
        }

        case actions.CHANGE_PRODUCT_QUANTITY: {
            const arr = products.map(item=>{
                const base_total = (action.exchangeRate ?  +item.rate / +action.exchangeRate : 0) * +action.value
                if (item.bill_id === action.bill_id){
                    return {...item,quantity:action.value, currency_rate:action.exchangeRate ?  +item.rate / +action.exchangeRate : 0,
                        subTotal: +item.rate * +action.value, currency_total:base_total}
                }
                return item;
            })

            const unedited = arr.filter(item=>!item.isRate).map(item=>({...item, rate: +item.rate,quantity: +item.quantity}));
            const edited = arr.filter(item=>item.isRate).map(item=>({...item, rate: +item.rate,quantity: +item.quantity}));
            action.calculateTotal(action.services, arr, action.exchangeRate,action?.bedBill);
            return {...state, products:arr, productsPaid:unedited, productsEdited:edited}

        }

        case actions.DELETE_PRODUCT: {
            const removed = products.map(item =>{
                if(item.bill_id === action.bill_id){
                    return {...item, isExcluded:!item.isExcluded}
                }
                return item
            })
            const items = removed.map(item=>({...item,quantity: item.isExcluded ? 0 : item.quantity,
                subTotal:item.isExcluded ? 0 : item.subTotal}))
            const presc = items.filter(item => !item.isExcluded)
            const paidProducts = removed.filter(item=>!item.isRate).filter(item => !item.isExcluded)
            const removedItems = items.filter(item=>item.isExcluded)
            action.calculateTotal(action.services, presc, action.exchangeRate,action?.bedBill);
            return {...state, products:removed, productsPaid:paidProducts, productsRemoved:removedItems}
        }

        case actions.CALCULATE_PRODUCT_AMOUNT:{
            const arr = products.map(item=>({...item,
                currency_rate: +action.rate ?  +item.rate / +action.rate : 0, currency_total: (+action.rate ?  +item.rate / +action.rate : 0) * +item.quantity,
                subTotal:+item.rate * +item.quantity
            }))
            return {...state, products:arr}
        }
        default: {
            throw new Error(`Unsupported action type: ${action.type}`)
        }

    }

}