import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { convertMonthToString } from "../Utils/ConvertDate";

// component for the total, cash and credit earnings and the patient visits
export const CollectionVisitCards = (earnings, patient_visits) => {
    const {in_patients, out_patients, total_patients} = patient_visits;
    const {total_earnings, cash_earnings, percentage_cash_earnings, credit_earnings, percentage_credit_earnings} = earnings;

    return [
        {
            title: 'Total Earnings',
            bodyValues: () => {
                return (
                    <Row>
                        <Col sm={8}>
                            <p className="card-value-text" style={{fontSize:'22px'}}>{total_earnings ? `${total_earnings.toLocaleString()}`: 0}</p>
                        </Col>
                    </Row>
                )
            }
        },
        {
            title: 'Cash Earnings',
            bodyValues: () => {
                return (
                    <Row>
                        <Col>
                        <OverlayTrigger placement="top" 
                            overlay={   
                                <Popover>
                                    <p style={{margin:'0.2rem'}}>{percentage_cash_earnings ? `${percentage_cash_earnings}%`: `0%`} Of Total Collections</p>
                                </Popover>}>
                            <p className="card-value-text" style={{fontSize:'22px'}}>{cash_earnings ? `${cash_earnings.toLocaleString()}`: 0}</p> 
                        </OverlayTrigger>
                        </Col>
                    </Row>
                )
            }
        },
        {
            title: 'Credit Earnings',
            bodyValues: () => {
                return (
                    <Row>
                        <Col>
                            <OverlayTrigger placement="top" 
                                overlay={   
                                    <Popover>
                                        <p style={{margin:'0.2rem'}}>{percentage_credit_earnings ? `${percentage_credit_earnings}%`: `0%`} Of Total Collections</p>
                                    </Popover>}>
                                    <p className="card-value-text" style={{fontSize:'22px'}}>{credit_earnings ? `${credit_earnings.toLocaleString()}`: 0}</p>
                            </OverlayTrigger>                       
                        </Col>
                    </Row>
                )
            }
        },
        {
            title: 'Patient Visits',
            bodyValues: () => {
                return (
                    <Row>
                        <Col>
                            <p className="card-value-text">{in_patients ? `${in_patients.toLocaleString()}`: 0}</p>
                            <p className="card-small-text">IPD</p>
                        </Col>
                        <Col>
                            <p className="card-value-text">{out_patients ? `${out_patients.toLocaleString()}`: 0}</p>
                            <p className="card-small-text">OPD</p>
                        </Col>
                        <Col>
                            <p className="card-value-text">{total_patients ? `${total_patients.toLocaleString()}`: 0}</p>
                            <p className="card-small-text">Total</p>
                        </Col>
                    </Row>
                )
            }
        }
    ]
}

// component for the inventory
export const Inventory = ({inventory, title_report_card}) => {
    const {out_of_stock, expired_items, medical_equipment, sundries, medicine, total_products} = inventory;
    return (
        <div>
            {title_report_card}
            <div style={{marginTop:'10px'}}>
                <Row>
                    <Col>
                        <p className="card-value-text">{out_of_stock ? `${out_of_stock.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Out of Stock</p>
                    </Col>
                    <Col>
                        <p className="card-value-text">{expired_items ? `${expired_items.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Expired Items</p>
                    </Col>

                    <Col>
                        <p className="card-value-text">{medical_equipment ? `${medical_equipment.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Medical Equipment</p>
                    </Col>
                    <Col>
                        <p className="card-value-text">{sundries ? `${sundries.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Sundries</p>
                    </Col>
                    <Col>
                        <p className="card-value-text">{medicine ? `${medicine.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Medicine</p>
                    </Col>
                    <Col>
                        <p className="card-value-text">{total_products ? `${total_products.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Total Inventory</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

// component for the in patient statistics
export const InPatientStatistics = ({in_patient_statistics}) => {
    const {bed_occupancy, total_beds, avg_length_of_stay, total_wards, 
        admitted_patients} = in_patient_statistics;
    const current_month = convertMonthToString(new Date().getMonth());
    return (
        <div>
            <div>
                <p className="dashboard-card-font-color">InPatient Statistics for {`${current_month}`}</p>
                <div className="orange-short-border"></div>                                        
            </div>
            <div style={{marginTop:'10px'}}>
                <Row>
                    <Col>
                        <p className="card-value-text">{total_beds ? `${total_beds.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Total Beds</p>
                    </Col>
                    <Col>
                        <p className="card-value-text">{bed_occupancy ? `${bed_occupancy.toLocaleString()} %` : '0 %'}</p>
                        <p className="card-small-text">Bed Occupancy</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="card-value-text">{avg_length_of_stay ? `${avg_length_of_stay.toLocaleString()} days` : '0 days'}</p>
                        <p className="card-small-text">Average Length of Stay</p>
                    </Col>
                    <Col>
                        <p className="card-value-text">{admitted_patients ? `${admitted_patients.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Admitted Patients</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="card-value-text">{total_wards ? `${total_wards.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Total Wards</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

// component for the mortality rate
export const MortalityRate = ({mortality_rate, filter_type}) => {
    const {inpatient, outpatient, total_deaths} = mortality_rate;
    return (
        <div>
            <div style={{display:'flex', justifyContent: 'space-between'}}>
                <div>
                    <p className="dashboard-card-font-color">Mortality Rate</p>
                    <div className="orange-short-border"></div>
                </div>
                <div>
                    <p className="dropdown-filter">{filter_type}</p>                       
                </div>
            </div>
            <div style={{marginTop:'10px'}}>
                <Row>
                    <Col>
                        <p className="card-value-text">{inpatient ? `${inpatient.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">In</p>
                    </Col>
                    <Col>
                        <p className="card-value-text">{outpatient ? `${outpatient.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Out</p>
                    </Col>
                    <Col>
                        <p className="card-value-text">{total_deaths ? `${total_deaths.toLocaleString()}` : '0'}</p>
                        <p className="card-small-text">Total</p>
                    </Col>
                </Row>
            </div>
            <div style={{display:'flex', justifyContent:'center', marginTop:'15px'}}>
                <Link to={{pathname:'/reports/deathreports'}}>
                  <button className="sms-dashboard-btn">Death Reports</button>
                  </Link>
            </div>
        </div>
    )
}