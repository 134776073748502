import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../Helpers/env";
import {useSnackbar} from "./snackbar-context";
import {hmisLogo} from "./hmisLogo";

const CompanyContext = React.createContext()
CompanyContext.displayName = 'CompanyContext'


function CompanyProvider(props) {
    const {handleSuccess, handleError} = useSnackbar()
    const [state, setState] = useState({companyName: '', companyEmail: '',
        mobile: '', address: '', website: '', logo: '',});
    const [selectedFile, setSelectedFile] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [filename, setFilename] = useState(null);
    const [companyDetails,setCompanyDetails]= useState(null)
    const inputRef = useRef()


    const isResolved = isSubmitted === 'resolved'


    useEffect(() => {
        axios.get(`${config.smsUrl}/cuser/view_company_info`)
            .then((response) => {
                const data= response.data? response.data:{}
                setCompanyDetails(data);
            }).catch(err=>{
            console.log('err')
        });
    }, [isResolved]);

    useEffect(() => {
        if(!companyDetails){
            return
        }
        setState({...state,companyName: companyDetails.company_name,
            companyEmail:companyDetails.email, mobile:companyDetails.mobile,
            address:companyDetails.address, website:companyDetails.website, logo:companyDetails.img_url})
        setFilename(companyDetails.img_url)
        setSelectedFile(companyDetails.img_url)
    },[companyDetails])


    const convertToBase64 = (selectedFile) => {
        console.log(selectedFile)
        const reader = new FileReader()

        reader.readAsDataURL(selectedFile)

        reader.onload = () => {
            setFilename(reader.result)
            setSelectedFile(reader.result)
        }
    }



    const handleImageSelection = (e) => {
        convertToBase64(e.target.files[0])
    };



    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleSubmit = event => {
        event.preventDefault();
        const {companyName, companyEmail, mobile, address, website} = state;
        setSubmitted(true)
        const hmis_logo = hmisLogo()
        const file = selectedFile ? selectedFile : hmis_logo
        if (companyName && mobile && address) {
            setIsSubmitted('pending')
            const data = {
                company_name: companyName,
                email: companyEmail,
                mobile:mobile,
                address:address,
                website:website,
                logo: file
            }
            axios.post(`${config.smsUrl}/cuser/add_company_info`, data)
                .then(() => {
                    setIsSubmitted('resolved')
                    handleSuccess(`Company saved successfully!`);
                    // history.push("/editcompanydetails")
                }).catch(error => {
                setIsSubmitted('rejected')
                handleError('Operation Unsuccessful')
            })
        }

    };

    const value = {state, selectedFile,  submitted,companyDetails,inputRef,
        isSubmitted, handleChange, handleSubmit, handleImageSelection,filename}
    return <CompanyContext.Provider value={value}  {...props}/>
}

function useCompany() {
    const context = React.useContext(CompanyContext)
    if (context === undefined) {
        throw new Error(`useCompany must be used within a CompanyProvider`)
    }
    return context
}

export {CompanyProvider, useCompany}

