import React from 'react';
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import * as snackbarActions from '../../../actions/snackbarActions';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {usePurchaseOrders} from "./hooks/usePurchaseOrderList";
import {dateStringConvert} from "../../Utils/ConvertDate";
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import {usePurchaseOrderDetails} from "./hooks/usePurchaseorderDetails";
import {ReusablePurchaseOrderDetails} from "./PurchaseOrderDetails";
import {printFunctionality} from "../../Utils/printFunctionality";
import ReusableDialog from "../../Utils/Dialogs/ReusableDialog";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import Textarea from "../../Utils/FormInputs/Textarea";
import {Link} from 'react-router-dom';
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";


export const printStyles = () => {
    return '' +
        '<style>' +
        'table {' +
        'width:100%;' +
        'border-collapse:collapse;' +
        '}' +
        'table.vendor-address-table th, table.vendor-address-table td {' +
        'padding:.5rem;' +
        'font-size:1.2rem;' +
        '}' +
        'table.vendor-table thead tr{' +
        'background-color: #1290a4;' +
        'color: #fff;' +
        'text-transform: uppercase;' +
        ' -webkit-print-color-adjust: exact; ' +
        '}' +
        'table.vendor-table th, table.vendor-table td {' +
        'padding:.5rem;' +
        'font-size:1.2rem;' +
        '}' +
        'table td p {' +
        'margin:0;' +
        '}' +
        '</style>';
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
        paddingBottom: 0
    },
    icon: {
        width: '.9em',
        height: '.9em'
    },
    iconHolder: {
        minWidth: '38px '
    },
    listItem: {
        borderBottom: '1px solid #e8e8e8',
        paddingTop: '10px',
        paddingBottom: '10px',
        cursor: 'pointer'
    },
    activeLi: {
        backgroundColor: '#f5f5f5'
    },
    primary: {
        fontSize: '0.99em'
    },
    secondary: {
        fontSize: '0.9em'
    },
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    pItem: {
        borderLeft: '1px solid #c7c6c7',
    },
    rounded: {
        borderRadius: 0
    },
    listText: {
        textAlign: 'right'
    },
    approved: {
        color:"green"
    },
    canceled:{
        color:'#D9512C'
    },
    pending:{
        color:"#F29339"
    },
    purchased: {
        color: "#1d5dd3"
    },
}));
const PurchaseOrderApproval = ({actions, snackbars, match}) => {
    const classes = useStyles();
    const user = JSON.parse(sessionStorage.getItem('user'));
    const singleUser = !user ? {} : user;
    const permissions = !singleUser.permission ? {} : singleUser.permission;
    const add_lpo = !permissions.add_lpo ? {} : permissions.add_lpo;
    const edit_lpo = !permissions.edit_lpo ? {} : permissions.edit_lpo;
    const approve_lpo = !permissions.approve_lpo ? {} : permissions.approve_lpo;
    const cancel_lpo = !permissions.cancel_lpo ? {} : permissions.cancel_lpo;
    const {
        sub_total, downloadPdf, productList, details, cancel, confirmation, isSubmitted, submitted,
        arr, loading: approvalLoading, instructions, pdfRef, id, handleClick, handleConfirm, handleCloseConfirmation,
        handleOpenConfirmation, handleCancel, handleChangeReason, status,approved_by,prepared_by,
        handleOpenCancel, handleCloseCancel, reason, closeSnackbar, setId
    } = usePurchaseOrderDetails(match, actions)
    const {purchaseOrders, loading,value,handleChangeValue, filteredList} = usePurchaseOrders(actions, status, setId)
    const {openBar, type, message} = snackbars;
    const {lpo_status, lpo_id} = id;

    return (
        <div className='journals'>
            <PageTitle title="Purchase Order"/>
            <SubHeader title="Inventory" subTitle="Purchase order">
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            <PrintDialog message='Approve Purchase Order' openDialog={confirmation}
                         handleClose={handleCloseConfirmation} handleCancel={handleCloseConfirmation}
                         handleClick={handleConfirm} next='Approve' preview='No'
                         text='Are you sure you want to approve this purchase order?'/>
            <ReusableDialog openDialog={cancel} handleClose={handleCloseCancel} isSubmitted={isSubmitted}
                            width='sm' message='Reason for Canceling Purchase order' handleSubmit={handleCancel}>
                <Form.Group as={Row}>
                    <Label name='Reason' type sm='2' column/>
                    <Col sm={8}>
                        <Textarea onChange={handleChangeReason} value={reason} rows={5}/>
                        {submitted && !reason ? <div className='help-block'>Please provide reason</div> : null}
                    </Col>
                </Form.Group>
            </ReusableDialog>
            <Card elevation={0} classes={{root: classes.rounded}}>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={4} classes={{item: classes.item}}>
                        <div className="top-bar all-journals">
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={8}>
                                    <SelectInput value={value} onChange={handleChangeValue} defaultOption='All Purchase Orders'
                                                 options={[{value: 0, text: 'Pending Approval'}, {
                                                     value: 1,
                                                     text: 'Approved'
                                                 },
                                                     {value: 2, text: 'Canceled'}]}/>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                </Grid>
                            </Grid>
                        </div>
                        {loading ? <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                                                          style={{width: '20%'}}
                                                                          alt="loader"/></div> :
                            purchaseOrders.length > 0 ?
                                filteredList(purchaseOrders).map(item => (
                                    <List className={classes.root} key={item.local_purchase_order_id}>
                                        <ListItem
                                            classes={{root: `${classes.listItem} ${item.local_purchase_order_id === id.lpo_id && classes.activeLi}`}}
                                            onClick={(() => handleClick(item))}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} md={7}>
                                                    <ListItemText primary={item.supplier_name}
                                                                  secondary={`${item.purchase_order_number} | ${item.create_date ? dateStringConvert(item.create_date) : ''}`}
                                                                  classes={{
                                                                      primary: classes.primary,
                                                                      secondary: classes.secondary
                                                                  }}/>
                                                </Grid>
                                                <Grid item xs={12} md={5}>
                                                    <ListItemText
                                                        primary={`UGX ${item.grand_total ? item.grand_total.toLocaleString() : ''}`}
                                                        secondary={item.status === 0 ? 'PENDING APPROVAL' : item.status === 1 ? 'APPROVED' : item.status === 2 ? 'CANCELED' :
                                                            item.status === 3 ? 'FULFILLED' : ''}
                                                        classes={{
                                                            root: classes.listText,
                                                            primary: classes.primary,
                                                            secondary:`${classes.secondary} ${item.status === 0 ? classes.pending : item.status === 1 ? classes.approved :
                                                                item.status === 2 ? classes.canceled : item.status === 3  ? classes.purchased :''}`
                                                        }}/>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                )) : <div className='text-center mt-3'>No records found</div>}

                    </Grid>
                    <Grid item xs={6} md={8} classes={{item: classes.pItem}}>
                        {approvalLoading ? <div className='text-center mt-5'><img src="/images/listPreloader.gif"
                                                                                  style={{width: '10%'}}
                                                                                  alt="loader"/></div> : <div>
                            <div className="top-bar">

                                <div className="text-right">
                                    <div className='btn-group'>
                                        {((edit_lpo.create || edit_lpo.read || edit_lpo.update || edit_lpo.delete)&& !(lpo_status === 1 || lpo_status === 3)) ? <Link to={{pathname: `/editpurchaseorder/${lpo_id}/${lpo_status}`}}>
                                            <button type='button'
                                                    className='btn btn-sm sms-gray-btn'>
                                                <EditOutlinedIcon classes={{root: classes.icon}}/>
                                            </button>
                                        </Link>:null}
                                        <button type='button' onClick={downloadPdf}
                                                className='btn btn-sm sms-gray-btn'>
                                            <PictureAsPdfOutlinedIcon classes={{root: classes.icon}}/>
                                        </button>
                                        <button type='button'
                                                onClick={() => printFunctionality(pdfRef.current, printStyles())}
                                                className='btn btn-sm sms-gray-btn'>
                                            <PrintOutlinedIcon classes={{root: classes.icon}}/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {lpo_status !== 2  ? <div className="sub-journal-bar approve-purchase-order">
                                <div className="text-right">
                                    { lpo_status === 0 ? <>
                                        {/*{(approve_lpo.create || approve_lpo.read || approve_lpo.update || approve_lpo.delete) &&*/}
                                            <button type='button' onClick={handleOpenConfirmation}
                                                        className="btn btn-sm sms-info-btn mr-2">Approve
                                        </button>
                                        {/*// }*/}
                                        {/*{(cancel_lpo.create || cancel_lpo.read || cancel_lpo.update || cancel_lpo.delete) && */}
                                            <button type='button' onClick={handleOpenCancel}
                                            className="btn btn-sm sms-gray-btn mr-2">Cancel
                                        </button>
                                        {/*// }*/}
                                    </> : null}
                                    {(add_lpo.create || add_lpo.read || add_lpo.update || add_lpo.delete) && lpo_status === 1 ?
                                        <Link to={{pathname: `/newpurchase/${lpo_id}`}}>
                                            <button type='button' className="btn btn-sm sms-btn-outline mr-2">Make Purchase
                                            </button>
                                        </Link>:null}
                                    {(add_lpo.create || add_lpo.read || add_lpo.update || add_lpo.delete) && lpo_status === 3 ?
                                        <Link to={{pathname: `/editpurchaseordertemplate/${lpo_id}/0/new`}}>
                                            <button type='button' className="btn btn-sm sms-btn-outline">Use as Template</button>
                                    </Link>: null}
                                </div>
                            </div>:null}
                            <div className="m-2">
                                <ReusablePurchaseOrderDetails details={details} instructions={instructions} arr={arr}
                                                              pdfRef={pdfRef} productList={productList}
                                                              sub_total={sub_total} approved_by={approved_by} prepared_by={prepared_by}/>
                            </div>
                        </div>}
                    </Grid>
                </Grid>
            </Card>

        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderApproval);