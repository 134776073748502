import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";


export const useReturnMedicine = (actions) =>{
    const [medicineReturn, setMedicineReturn] = useState([{
        product_name: '',
        product_id:'',
        quantity_given:0,
        quantity_returned:0,
        quantity_dispensed:0,
        prescription_detial_id:'',
        bill_id:'',
        batches:[{
            batch_id:'', quantity:0, quantity_returned:0
        }]
    }]);
    const [state, setState] = useState({
        patient_id: '', patient_name: '', gender: '', age: '', weight: '', blood_pressure: '',
        reference: '', visit_id: '', date: '', policy_number: '',address:'', phone:''
    });
    const [prescription, setPrescription] = useState({});
    const [submittedReturn, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [openDialog, setOpenDialog] = useState(false);
    const [product, setProduct] = useState('');
    const [ids, setIds] = useState({prescription_id:'', patient_type:'', visit_id:'', item_status:''})
    const {prescription_id, patient_type,visit_id} = ids;
    const [confirmDialog, setConfirmDialog] = useState(false);

    // get the actual numbers of medicine returned, used, etc
    useEffect(() => {
        if(!(prescription_id && patient_type === 2)){
            return;
        }
        const formData = new FormData();
        formData.append('visit_id',visit_id);
        formData.append('prescription_id',prescription_id);
        axios.post(`${config.smsUrl}/cpharmacy/get_previous_presc_and_treatment`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const item = dt[0] ? dt[0] :{};
            const product_id = item.product_name ? item.product_name : '';
            setProduct(product_id)

            if(!product){
                setMedicineReturn([]);
            }

            setMedicineReturn(dt);

        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, [prescription_id]);

    // get the patient details (patient_info)
    useEffect(() => {
        if(!prescription_id){
            return;
        }
        const formData = new FormData();
        formData.append('prescription_id',prescription_id);
        formData.append('visit_id',visit_id)
        axios.post(`${config.smsUrl}/cpharmacy/get_patient_presc`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const presc = !dt.patient_info ? {} : dt.patient_info;
            setPrescription(presc);
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }, [prescription_id]);

    useEffect(() => {
        if(!prescription_id){
            return;
        }
        setState({
            patient_id: prescription.patient_number,
            patient_name: `${!prescription.patient_firstname ? "" :
                prescription.patient_firstname} ${!prescription.patient_lastname ? "" :
                prescription.patient_lastname}`, gender: prescription.gender,
            age: prescription.age, weight: prescription.weight, blood_pressure: prescription.blood_pressure,
            reference: prescription.reference, visit_id: prescription.visit_id, date: prescription.begin_datetime,
            policy_number: prescription.policy_number, address: prescription.address, phone:prescription.phone_no
        })
    }, [prescription]);

    const handleOpenDialog = (item) =>{
        const {prescription_id:presc, patient_type:patient,visit_id:v_id, status:item_status} = item;
        setOpenDialog(true);
        setIds({prescription_id:presc, patient_type:patient, visit_id:v_id, item_status});
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false);
    }

    const handleConfirmDialog = () => {
        setConfirmDialog(true);
    }

    const handleCloseConfirmationDialog = () => {
        setConfirmDialog(false);
    }

    const handleChangeReturnQuantity = (event, product_id) =>{
        const {value} = event.target;
        const arr = medicineReturn.map(item=>{
            if(item.product_id === product_id){
                return {...item, quantity_returned:value}
            }
            return item;
        })
        
        setMedicineReturn(arr)
    }

    const submissionReturnCall = () => {
        //const formData = new FormData();
        //setSubmitted(true);
        /*medicineReturn.forEach(item=>{
            formData.append('returned_quantity',item.quantity_returned);
            formData.append('prescription_detial_id',item.prescription_detial_id);
            formData.append('product_id',item.product_id);
            formData.append('bill_id',item.bill_id);
        }) */


        const concatenated = medicineReturn.reduce((a,b)=>a.concat(b.batches.map((item)=>({...b, ...item}))), [])
        const concatenatedArr = concatenated.map(({batches, ...item})=>({...item}))
        const isArr = concatenated.every(item=>item.quantity_returned)



        const params = {
            visit_id,
            // prescription_id,
            products : concatenatedArr
        }
        console.log(isArr)
        // if(isArr){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cpharmacy/medicine_return`, params).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Product(s) received successfully');
                const cleared = medicineReturn.map(item=>({
                    ...item,quantity_returned:''
                }))
                
                setMedicineReturn(cleared)
                setSubmitted(false)
                setOpenDialog(false)
                handleCloseConfirmationDialog();
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected');
            })
        // }
       
    };

    const handleSubmitReturn = (event) => {
        event.preventDefault();

        const arr = medicineReturn.every(item=>{
            const balance = parseInt(item.quantity_given) - parseInt(item.quantity_administered);
            return (item.quantity_returned <= balance && item.quantity_returned >= 0)
        });
        setSubmitted(true);
        if (arr) {
            handleConfirmDialog();
        }
        
    }

    const handleChangeBatchReturnQuantity = (event, btch, btch_idx, index) => {

        const {value} = event.target;
        let allRows = [...medicineReturn];

        allRows[index]['batches'][btch_idx]['quantity_returned'] = parseInt(value);
        let new_btches = allRows[index]['batches'];

        // calc the total_chosen_quantity
        let new_quantity_returned = 0;
        for (var x = 0; x < new_btches.length; x++) {   
            new_quantity_returned += parseInt(new_btches[x].quantity_returned? new_btches[x].quantity_returned : 0);
        }

        allRows[index]['quantity_returned'] = parseInt(new_quantity_returned); 
        setMedicineReturn(allRows);
    }
  
    const isPendingReturn = isSubmitted === 'pending';
    const isResolvedReturned = isSubmitted === 'resolved';
    const isRejectedReturn = isSubmitted === 'rejected';
    const returnProps = {medicineReturn,  isPendingReturn,handleChangeReturnQuantity, openDialog,
        handleOpenDialog, handleCloseDialog,submittedReturn, handleSubmitReturn, handleChangeBatchReturnQuantity,
        handleCloseConfirmationDialog, submissionReturnCall, handleConfirmDialog, confirmDialog,
        isRejectedReturn, state}
    return {returnProps,isResolvedReturned}
}