import {gmtConvert} from "../../Utils/ConvertDate";

export function filterServices(exchangeRate, services=[]) {
    return services.map((service => ({
        ...service,
        service_name: service.service_name,
        rate: service.rate,
        currency_rate: exchangeRate ? +service.rate / +exchangeRate : 0,
        quantity: service.quantity,
        subTotal: +service.rate * +service.quantity,
        currency_total: exchangeRate ? (+service.rate * +service.quantity) / +exchangeRate : 0,
        service_id: service.service_id,
        bill_id: service.bill_id,
        status: service.pay_status,
        bill_type: service.bill_type,
        date_created: service.date_created,
        invoice_no: service.invoice_no,
        visit_id: service.visit_id,
        isRate: false,
        isExcluded: false
    })))
}

export function filterProducts(exchangeRate, products){
    return products.map((product => ({
        product_name: product.product_name,
        rate: product.rate,
        currency_rate: exchangeRate ?  +product.rate / +exchangeRate : 0,
        quantity: product.quantity,
        subTotal: (product.rate ? +product.rate :0) * +product.quantity,
        currency_total: exchangeRate ? (+product.rate * +product.quantity) / +exchangeRate : 0,
        product_id: product.product_id,
        bill_id: product.bill_id,
        status: product.pay_status,
        bill_type: product.bill_type,
        date_created: product.date_created,
        invoice_no: product.invoice_no,
        visit_id: product.visit_id,
        stock: product.stock,
        dosage: product.dosage,
        frequency: product.frequency,
        key_information: product.key_information,
        number_of_days: product.number_of_days,
        prescription_detial_id: product.prescription_detial_id,
        prescription_id: product.prescription_id,
        isRate:false,
        isExcluded:false
    })))
}

export function filteredPayloadProducts(arr=[]){
    return arr.map((product => ({
        rate: product.rate,
        quantity: +product.quantity,
        product_id: product.product_id,
        bill_id: product.bill_id,
        pay_status: product.pay_status,
        bill_type: product.bill_type,
        date_created: product.date_created,
        invoice_no: product.invoice_no,
        visit_id: product.visit_id,
        stock: product.stock,
        dosage: product.dosage,
        frequency: product.frequency,
        key_information: product.key_information,
        number_of_days: product.number_of_days,
        prescription_detial_id: product.prescription_detial_id,
        prescription_id: product.prescription_id,
        product_name:product.product_name,
        isExcluded:product.isExcluded,
        isRate:product.isRate
    })));
}

export function filterInpatientServices(exchangeRate,arr=[]) {
    return arr.map(item => ({...item,
        service_name: item.service_name, rate: item.rate, quantity: item.quantity,
        currency_rate: exchangeRate ?  +item.rate / +exchangeRate : 0,
        currency_total: exchangeRate ? (+item.rate * +item.quantity) / +exchangeRate : 0,
        amount: +item.rate * +item.quantity, service_id: item.service_id, showService: false,
        date: item.date_created, bill_id: item.bill_id, pay_status: item.pay_status,
        bill_type: item.bill_type, invoice_no: item.invoice_no, isRate:false, isExcluded:false
    }))
}

export function filterInpatientProducts(exchangeRate,arr=[]) {
    return arr.map(item => ({...item,
        product_name: item.product_name,
        rate: item.rate,
        currency_rate: exchangeRate ?  +item.rate / +exchangeRate : 0,
        quantity: !item.quantity ? 0 : item.quantity,
        currency_total: exchangeRate ? (+item.rate * +item.quantity) / +exchangeRate : 0,
        subTotal: item.rate * (!item.quantity ? 0 : item.quantity),
        product_id: item.product_id,
        isDischargeDrug:item.quantity,
        prescription_id: item.prescription_id,
        stock: item.stock, prescription_detial_id: item.prescription_detial_id,
        number_of_days: item.number_of_days,
        dosage: item.dosage, bill_id: item.bill_id,isExcluded:false,
        bill_type: item.bill_type, frequency: item.frequency, pay_status: item.pay_status,
        invoice_no: item.invoice_no, visit_id: item.visit_id, isRate:false
    }))
}



export function filterInpatientBeds(exchangeRate,arr=[]) {
    const myDate = new Date();
    const today = myDate.toLocaleDateString();
    const now = myDate.toDateString();

    const totalDays = (start_date, end_date) => {
        return Math.abs((gmtConvert(!end_date ? today : end_date) - gmtConvert(start_date)) / 1000 / 60 / 60 / 24)
    }

    return arr.map(bed => ({
        assign_date: bed.assign_date,
        discharge_date: (!bed.end_date ? now : bed.end_date),
        bill_type: bed.bill_type, invoice_no: bed.invoice_no,
        pay_status: bed.pay_status,
        visit_id: bed.visit_id,
        room_name: bed.room_name,
        bill_id: bed.bill_id,
        ward_name: bed.ward_name,
        total_days: totalDays(bed.assign_date, bed.end_date),
        bed_number: bed.bed_number,
        rate: bed.rate,
        currency_rate: exchangeRate ?  +bed.rate / +exchangeRate : 0,
        currency_total: exchangeRate ? (+bed.rate * +bed.quantity) / +exchangeRate : 0,
        amount: bed.rate * +bed.quantity,
        bed_assignment_id: bed.bed_assignment_id,
        quantity: +bed.quantity
    }))
}




