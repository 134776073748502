import React from "react"
import Form from "react-bootstrap/Form"
import Label from "../../Utils/FormInputs/Label"
import TextField from "../../Utils/FormInputs/TextField";
import {formatAmount} from "../../Utils/formatNumbers";
import {RightAlignedContainer} from "../../Utils/styledComponents";
import Textarea from "../../Utils/FormInputs/Textarea";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";








function ReceiveDebtorsPayments({state, isPending, submitted, isFull, amountReceived, customer,
                                     handleChange, handleChangeReceived,  handleIsFullBill,
                                    handleSubmit, memo, handleChangeMemo,accounts, debit_to,
                                    handleChangeDebitTo}){
    const {due_amount} = customer
    const {payment_date} = state

    const isFullSpan = (
        <span>Receive full amount(<strong>{due_amount}</strong>)</span>
    )
    return (
            <form onSubmit={handleSubmit}>
                <div className='row mb-3'>
                    <div className='col-lg-6'>
                        {/*<Form.Group>*/}
                        {/*    <Label name='Customer'/>*/}
                        {/*    <TextField type='text' readOnly value={customer_name}/>*/}
                        {/*</Form.Group>*/}
                        <Form.Group>
                            <Label name='Payment Date' type/>
                            <TextField type='date' value={payment_date} name='payment_date' submitted={submitted} onChange={handleChange}/>
                            {submitted && !payment_date ? <div className='help-block'>Payment date is required</div>:null}
                        </Form.Group>

                        <Form.Group>
                            <Label name='Amount Received' type/>
                            <TextField type='number' value={amountReceived} submitted={submitted} onChange={handleChangeReceived}/>
                            {submitted && !amountReceived ? <div className='help-block'>Amount received is required</div>:submitted && amountReceived < due_amount ? <div className='help-block'>{`Please enter ${due_amount}`}</div>: null}
                            {/* <div className='mt-2'>
                                <Form.Check checked={isFull} onChange={handleIsFullBill} label={isFullSpan}/>
                            </div> */}

                        </Form.Group>

                        <Form.Group>
                            <Label name='Debit To' type/>
                            <CustomSelect submitted={submitted} onChange={handleChangeDebitTo} value={debit_to} options={accounts}/>
                            {submitted && !debit_to ? <div className="help-block">Debit account is required</div>:null}
                        </Form.Group>

                        <Form.Group>
                            <Label name='Memo' type/>
                            <Textarea rows='6' cols='8' submitted={submitted} name="memo" onChange={handleChangeMemo} value={memo}/>
                        </Form.Group>

                        {/*<Form.Group>*/}
                        {/*    <Label name='Payment Method' type/>*/}
                        {/*    <SelectInput options={paymentTypes} onChange={handleChange} value={payment_method} submitted={submitted}*/}
                        {/*                 name='payment_method' defaultOption='Select payment method...'/>*/}
                        {/*    {submitted && !payment_method ? <div className='help-block'>Payment method is required</div>:null}*/}
                        {/*</Form.Group>*/}
                    </div>
                    <div className='col-lg-1' />

                    <div className='col-lg-4'>
                        <p style={{marginTop: 24, fontSize:16}}>Amount Due : <strong>{due_amount}</strong></p>
                        {/* <Form.Group>
                            <Label name='Amount Due'/>
                            <TextField type='number' value={due_amount ? formatAmount(due_amount) : due_amount} readOnly/>
                        </Form.Group> */}

                        {/*<Form.Group>*/}
                        {/*    <Label name='Amount Received' type/>*/}
                        {/*    <TextField type='number' value={amountReceived} submitted={submitted} onChange={handleChangeReceived}/>*/}
                        {/*    {submitted && !amountReceived ? <div className='help-block'>Amount received is required</div>:null}*/}
                        {/*    <div className='mt-2'>*/}
                        {/*        <Form.Check checked={isFull} onChange={handleIsFullBill} label={`Receive full amount(${due_amount ? formatAmount(due_amount) : due_amount})`}/>*/}
                        {/*    </div>*/}

                        {/*</Form.Group>*/}
                    </div>
                </div>
                {/**
                <p><strong>Outstanding Transactions</strong></p>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headData.map((item, index)=>(
                                    <TableCell key={index}>{item.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invoices?.map(item=>{
                                return(
                                    <TableRow key={item.invoice_id}>
                                        {/*<TableCell>
                                            {item.date ? dateStringConvert(item.date) : item.date}
                                        </TableCell> */}
                                        {/*<TableCell>
                                            {item.invoice_no}
                                        </TableCell>
                                        <TableCell>
                                            {formatAmount(item.total_amount)}
                                    </TableCell>*/}
                                        {/*<TableCell>*/}
                                        {/*    {item.prevous_due === 0 ? formatAmount(item.total_amount):formatAmount(item.prevous_due)}*/}
                                        {/*</TableCell>*/}
                                        {/*<TableCell>*/}
                                        {/*    <TextField value={item.payment} onChange={(e)=>handleChangePayment(e, item.invoice_id)}/>*/}
                                        {/*</TableCell>*/}
                                  {/*  </TableRow>
                                )
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>                 * 
                 * 
                 */}

                <RightAlignedContainer>
                    <button type='submit' disabled={isPending} className='btn btn-sm sms-btn'>{isPending ? 'Submitting....' : 'Submit'}</button>
                </RightAlignedContainer>
            </form>
    )
}

export {ReceiveDebtorsPayments}