import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import { formatDateTime } from "../Utils/ConvertDate";
import {history} from "../../Helpers/history";
import { ReusableRouteTabs } from "../Utils/Dialogs/ReusableTabs";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {Container} from "../Utils/styledComponents";
import { usePreviousVisits } from "./CustomHooks/usePreviousVisits";
import { ViewPatientVisitHistory } from "../DoctorsModule/PatientPreviousVisits";
import { PatientVisitFeedback } from "./PatientVisitFeedback";
import AddSchedule from "../ShiftsandSchedules/AddSchedule";
import ScheduleFollowUp from "../ShiftsandSchedules/ScheduleFollowUp";
import { useAddSchedule } from "../ShiftsandSchedules/CustomHooks/useAddSchedule";
import { useEditSchedule } from "../ShiftsandSchedules/CustomHooks/useEditSchedule";
import { AccountingDateFilter } from "../Utils/AccountingUtils/AccountingDateFilter";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Label from "../Utils/FormInputs/Label";
import SelectInput from "../Utils/FormInputs/SelectInput";

// component for the patient visit schedule
const PatientVisitAddSchedule = ({first_name, last_name, actions, _token, phone_no}) => {

    const { addScheduleProps
    } = useAddSchedule(actions, _token, `${first_name} ${last_name}`, phone_no);

    return (
        <ScheduleFollowUp  { ...addScheduleProps }/>
    )
}

const PatientVisitEditSchedule = ({first_name, last_name, actions, _token}) => {

    const { editScheduleProps
    } = useEditSchedule(actions);

    return (
        <AddSchedule  { ...editScheduleProps }/>
    )
}

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'Sl',hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone_number', numeric: false, disablePadding: false, label: 'Phone Number',hidden:false},
    {id: 'kin_phone_number', numeric: false, disablePadding: false, label: 'Kin Contact',hidden:false},
    {id: 'patient_type', numeric: false, disablePadding: false, label: 'Patient Type',hidden:false},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];

const PreviousVisits = ({actions, snackbar, match}) => {
    const {feedback_status} = match.params;
    const {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, openDialog,handleChange,feedback,
        visitList,  handleOpenDialog, handleCloseDialog, patientDetails, submitted, handleDateFilterSubmit,
        isLoading, isSuccess, isError, _token, feedbackProps, date, handleChangeDate, handleDownloadList
    }= usePreviousVisits(actions, feedback_status);

    const {patient_number, screen, visit_id, first_name, last_name, phone_no} = patientDetails
    const {openBar, type, message} = snackbar;
    const {visits,total_count} = visitList;
    const {from_date, to_date} = date;
console.log(visits)
    // for the dialogs
    const isViewHistory = screen === 'view_history';
    const isAddFeedback = screen === 'add_feedback';
    const isViewFeedback = screen === 'view_feedback';
    const isScheduleFollowUp = screen === 'schedule_follow_up';
    const isEditFollowUp = screen === 'edit_follow_up';

    const title = isViewHistory ? 'Patient Visit History' 
                    : isAddFeedback ? 'Add Patient Visit Feedback' 
                    : isViewFeedback ? 'View Patient Visit Feedback'
                    : isScheduleFollowUp ? 'Schedule Follow Up'
                    : isEditFollowUp ? 'Edit Schedule Follow Up' : '';

    const components = [
        {label: 'With Feedback', path: `/previous_visits/${true}`},
        {label: 'Without Feedback', path: `/previous_visits/${false}`},
    ]

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    return (
        <div>
            <PageTitle title="Previous Visits"/>
            <SubHeader title="Previous Visits" subTitle="Visit List">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>

            <CustomDialog title={title} open={openDialog} handleClose={handleCloseDialog} maxWidth="md"  isContentOverflow={false}>
                {isViewHistory ?
                    <>
                        <ViewPatientVisitHistory {...{actions, patient_number, visit_id}}/>
                    </>
                : null}

                {isViewFeedback ?
                    <>
                        <PatientVisitFeedback {...feedbackProps}/>
                    </>
                : null}

                {isAddFeedback ? 
                    <>
                        <PatientVisitFeedback {...feedbackProps}/>
                    </>
                : null}

                {isScheduleFollowUp ?
                    <>
                        <PatientVisitAddSchedule {...{first_name, last_name, actions, _token, phone_no}}/>
                    </>
                : null}

                {isEditFollowUp ?
                    <>
                        <PatientVisitEditSchedule {...{first_name, last_name, actions, _token}}/>
                    </>
                : null}
            </CustomDialog>

            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <div>
                        <AccountingDateFilter {...{from_date, to_date, handleChange:handleChangeDate, 
                        submitted,handleSubmit:handleDateFilterSubmit,isPending:isLoading}} />
                    </div>
                    <div>
                        <button className='btn sms-gray-btn' onClick={handleDownloadList}
                        style={{paddingTop:'2px'}}>Download</button>
                    </div>
                   
                </div>
                {feedback_status === 'true' ?
                   <div>
                   
                            <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4} >
                            <Row>
                                <Col xs={12} sm={2} md={2} lg={3} xl={2}>
                                    <Label name="Feedback Status" style={{paddingRight:'10px'}} />
                                </Col>
                                <Col xs={12} sm={9} md={6} lg={6} xl={9}>
                                    <SelectInput value={feedback} onChange={handleChange} name="feedback" 
                                                options={[{value:'0', text:'All'},{value:'1', text:'Positive'},{value:'2', text:'Negative'}]}/>
                                </Col>
                            </Row>
                        </Col>
                        {visits > 0 }
                        {/* <Col xs={12} sm={12} md={12} lg={12} xl={8} >
                            <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
                        </Col> */}
                    </Row>     
                    
                   </div>
                   : null}

                <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
                <CustomTable  headData={headCells} handler={handleSearch} term={searchValue}
                             data={visits} colSpan={headCells.length} customPage records={10} activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={total_count}
                              handleHeadCells={handleHeadCells} all_hidden={all_hidden} handleAllHeadCells={handleAllHeadCells}>
                        <TableBody>
                            
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                    alt="loader"/></TableCell>
                                </TableRow> :
                            null}

                            {isSuccess ?  visits.length > 0 ? 
                                visits.slice(0, 10).map((item) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.visit_id}
                                            className='mui-table-row'>
                                            <TableCell>{item.count}</TableCell>
                                            <TableCell hidden={headCells[1].hidden}>{formatDateTime(item.date)}</TableCell>
                                            <TableCell hidden={headCells[2].hidden}>{item.patient_number}</TableCell>
                                            <TableCell hidden={headCells[3].hidden}>{item.first_name} {item.last_name}</TableCell>
                                            <TableCell hidden={headCells[4].hidden}>{item.phone_no}</TableCell>
                                            <TableCell hidden={headCells[5].hidden}>{item.kin_phone_no}</TableCell>
                                            <TableCell hidden={headCells[6].hidden}>{item.patient_type === 1 ? 'Out Patient' : item.patient_type === 2 ? 'In Patient' : 'Emergency Patient'}</TableCell>
                                           
                                            <TableCell hidden={headCells[7].hidden}>
                                                <button onClick={()=>handleOpenDialog(item, 'view_history')} style={{marginBottom:'5px'}}
                                                    className="btn btn-sm sms-amber-btn mr-1">Visit History</button>                                                    
                                                
                                                {item.feedback_status === false ?
                                                    <button onClick={()=>handleOpenDialog(item,'add_feedback')} style={{marginBottom:'5px'}}
                                                    className="btn btn-sm sms-info-btn mr-1">Add Feedback</button>
                                                    : null
                                                }

                                                {item.feedback_status === true ?
                                                    <button onClick={()=>handleOpenDialog(item,'view_feedback')} style={{marginBottom:'5px'}}
                                                    className="btn btn-sm sms-btn mr-1">View Feedback</button>
                                                    : null
                                                }

                                                {item.feedback_status === true ?
                                                    <button onClick={()=>handleOpenDialog(item,'schedule_follow_up')} style={{marginBottom:'5px'}}
                                                    className="btn btn-sm sms-info-btn mr-1">Schedule Follow Up</button>
                                                    : null
                                                }
                                            </TableCell>
                                        </TableRow>
                                    );
                                }): 
                                <TableRow>
                                    <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                                </TableRow>
                            :null}

                            {isError ?   
                                <TableRow>
                                    <TableCell colSpan={headCells.length} align="center">The server did not return a successful response</TableCell>
                                </TableRow> 
                            :null }
                        </TableBody>
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PreviousVisits);
