import {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {history} from "../../../Helpers/history";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useViewCompletedDetails} from "./useViewCompletedDetails";


export const useDoctorWaitingList = (props,_token) =>{
    const {actions} = props;
    const [searchValue, setSearchValue] = useState('');
    const [openMenu, setOpenMenu] = useState(null);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [status, setStatus] = useState('idle')
    const [waitingList, setWaitingList] = useState([])
    const [total_count, setTotalCount] = useState(0)
    const [openAssign, setOpenAssign] = useState(false)
    const [doctor_name, setDoctor] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [doctors, setDoctors] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState('');
    const [ids, setIds] = useState({patient_number: '', visit_id: '',patient_name:'', age:'', gender:''});
    const [openEnd, setOpenEnd] = useState(false)
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const department_id = !_token.department_id ? "" : _token.department_id;
    const [isEnd, setIsEnd] = useState('idle')
    const {patientProps} = useViewCompletedDetails(actions)

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isError = isSubmitted === 'reject';

    const isEnding = isEnd === 'pending';
    const isEnded = isEnd === 'resolved';
    const isEndError = isEnd === 'reject';

    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isRejected = status === 'error';

    const retrievePatients = () =>{
        const formData = new FormData();
        // formData.append('user_roles_id', user_roles_id);
        formData.append('search', searchValue);
        formData.append('department_id', department_id);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const patients = !dt.waiting_list ? [] : dt.waiting_list;
            // console.log(dt)
            const count = !dt.total_count ? 0 : dt.total_count;
            const doc_list = patients.map((item, index) => ({
                ...item,
                count: ((activePage - 1) * 10) +  index + 1,
                date: item.begin_datetime,
                patient_number: item.patient_number,
                patient_name: `${!item.first_name ? "" : item.first_name} ${!item.last_name ? "" : item.last_name}`,
                status: item.status,
                visit_id: item.visit_id
            }))
            setWaitingList(doc_list)
            setTotalCount(count)
            setStatus('success')
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
            setStatus('rejected')
        })
    }

    useEffect(() => {
        retrievePatients()
        const interval = setInterval(()=>{
            retrievePatients()
        }, 10000)
        return()=>clearInterval(interval)
    }, [activePage, searchValue,isResolved, isEnded]);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [])

    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const retrieveDoctors = (d_id) =>{
        const formData = new FormData();
        formData.append('department_id', d_id);
        axios.post(`${config.smsUrl}/cvisit/getdoctorsbydepartment_id`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const doctors = !dt.doctors ? [] : dt.doctors;
            const arr = doctors.map(doctor => ({value: doctor.user_roles_id,
                label: `${doctor.first_name} ${doctor.last_name}`
            }))
            setDoctors(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const handleOpenAssign = (item) => {
        setOpenAssign(true)
        const {visit_id, patient_number, patient_name, age, gender} = item
        setIds({visit_id, patient_number, patient_name, age, gender});
    };

    const handleCloseAssign = () => {
        setOpenAssign(false)
    };

    const handleOpenEndVisit = (item) => {
        setOpenEnd(true)
        const {visit_id, patient_number, patient_name, age, gender} = item
        setIds({visit_id, patient_number, patient_name, age, gender});
    };

    const handleCloseEndVisit = () => {
        setOpenEnd(false)
    };

    const handleCloseMenu = () => {
        setOpenMenu(null)
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNextPage = (value) => {
        setActivePage(value)
    };

    const handleChangeDoctor = value => {
        setDoctor(value)
    }

    const handleChangeDepartment = value => {
        setDepartment(value)
        retrieveDoctors(value.value)
    }

    const handleClickPatient = (patient_number, visit_id,patient_type,activity_id, labrequest_id,scan_request_id, bill_id,patient_admission_id) => {
        const formData = new FormData();
        formData.append('visit_id', visit_id ? visit_id : '');
        formData.append('bill_id', bill_id ? bill_id : '');
        axios.post(`${config.smsUrl}/cvisit/Update_visit_takeup_status`, formData).then(() => {
            history.push(`/previousvisits/${patient_number}/${visit_id}/${activity_id}/${labrequest_id ?? null}/${scan_request_id ?? null}/${patient_type}/${patient_admission_id ?? null}`)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        });
    };

    const assignDoctor = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const {visit_id} = ids;
        // formData.append('patient_number', patient_number);
        formData.append('visit_id', visit_id);
        formData.append('user_roles_id', doctor_name.value);
        setSubmitted(true)
        if (doctor_name && department) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cvisit/reassign_patient`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Patient reassigned successfully');
                setIsSubmitted('resolved')
                setOpenAssign(false)
                setDepartment('');
                setDoctor('')
                setSubmitted(false)
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsSubmitted('rejected')
                setOpenAssign(false)
            });
           
        }
    };

    const handleEndVisit = () => {
        const formData = new FormData();
        const {visit_id} = ids;
        formData.append('visit_id', visit_id);
        if (visit_id) {
            setIsEnd('pending')
            axios.post(`${config.smsUrl}/cvisit/complete_visit`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Visit ended successfully');
                setOpenEnd(false)
                setIsEnd('resolved')
            }).catch(e => {
                errorMessages(e, null, actions);
                setIsEnd('rejected')
            });
        }
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    return {searchValue, openMenu, order, orderBy, page, rowsPerPage,isEnding, isEnded, isEndError,
        activePage, handleSearch, handleCloseMenu, handleRequestSort, handleChangeNextPage,
        handleClickPatient, closeSnackbar, waitingList, total_count, isLoading, isSuccess, isRejected,
        openAssign, handleOpenAssign, doctor_name, doctors, handleChangeDoctor, submitted,
        handleCloseAssign, isPending, isResolved, isError, ids, assignDoctor, isSubmitted, department,
        departments, handleChangeDepartment, openEnd, handleOpenEndVisit, handleCloseEndVisit, handleEndVisit,
        patientProps
    }
}