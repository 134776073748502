import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Link} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faBookMedical} from "@fortawesome/free-solid-svg-icons/faBookMedical";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {faTrash} from "@fortawesome/free-solid-svg-icons/faTrash";
import DeleteDialog from "../../Utils/Dialogs/DeleteDialog";
import {useDeathReport} from "./CustomHooks/useDeathReports";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    // {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'nationality', numeric: false, disablePadding: false, label: 'Nationality'},
    {id: 'patientType', numeric: false, disablePadding: false, label: 'Patient Type'},
    {id: 'dateOfDeath', numeric: false, disablePadding: false, label: 'Date of Death'},
    {id: 'time', numeric: false, disablePadding: false, label: 'Time of Death'},
    {id: 'diagnosis', numeric: false, disablePadding: false, label: 'Diagnosis'},
    {id: 'reportedBy', numeric: false, disablePadding: false, label: 'Reported By'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'},
];


const DeathReport = (props) => {
    const {
        searchValue, order, orderBy, loading, deaths, total_count, activePage,
        openDelete, closeSnackbar, handleSearch, handleChangePage, handleRequestSort,
        handleOpenDelete, handleCloseDelete, handleDelete
    } = useDeathReport(props)
    const {openBar, type, message} = props.snackbars;
    const status = 1;
    return (
        <div data-testid="death-list" className='journals'>
            <PageTitle title="Death Reports"/>
            <SubHeader title="Reports" subTitle="Death reports">
                <FontAwesomeIcon icon={faBookMedical}/>
            </SubHeader>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                </div>
                <div style={{margin: '10px'}}>
                    <Link to="/registerdeath">
                        <button className="btn sms-btn btn-sm" data-testid="register-death-button"><FontAwesomeIcon icon={faPlus}/> Register Death
                        </button>
                    </Link>
                </div>
            </div>
            <div className="mui-tables mt-2">
                 <DeleteDialog message={status === 1 ?  'deactivate':'activate'} openDialog={openDelete} handleClose={handleCloseDelete}
                                    text="patient" title={`${status === 1 ? 'Deactivate':'Activate'} Patient`}>
                        <button className='btn sms-info-btn btn-sm' onClick={handleDelete}  data-testid="yes-deactivate">{status === 1 ? 'Deactivate':'Activate'}</button>
                        <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete} data-testid="no-deactivate">No</button>    
                </DeleteDialog>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>

                <CustomTable title="Death Reports" handleChangeNextPage={handleChangePage} term={searchValue}
                             records={10}
                             headData={headData} handler={handleSearch} total_count={total_count}
                             activePage={activePage}
                             handleRequestSort={handleRequestSort} data={deaths} colSpan={5} order={order}
                             orderBy={orderBy} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : deaths.length > 0 ?
                        <TableBody data-testid="death-reports">
                            {stableSort(deaths, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const count = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{count}</TableCell>
                                            {/*<TableCell>{item.date_created ? gmtDateConvert(item.date_created) : ''}</TableCell>*/}
                                            <TableCell>{`${item.patient_firstname} ${item.patient_lastname}`}</TableCell>
                                            <TableCell>{item.nationality}</TableCell>
                                            <TableCell>{item.patient_type === 1 ? 'OPD' : 'IPD'}</TableCell>
                                            <TableCell>{item.date_of_death}</TableCell>
                                            <TableCell>{item.time_of_death}</TableCell>
                                            <TableCell>{item.immediate_cause_of_death}</TableCell>
                                            <TableCell>{`${item.doctor_firstname} ${item.doctor_lastname}`}</TableCell>
                                            <TableCell> <Link
                                                to={{pathname: `/viewdeathreport/${item.patient_death_id}/${item.patient_type}`}}>
                                                <button className="btn btn-sm sms-btn mr-1 mb-1" data-testid={"view-report-"+index}>
                                                    <FontAwesomeIcon icon={faEye}/></button>
                                            </Link>
                                                <Link
                                                    to={{pathname: `/editdeathreport/${item.patient_death_id}/${item.patient_number}`}}>
                                                    <button className="btn btn-sm sms-info-btn mr-1 mb-1" data-testid={"edit-report-"+index}>
                                                        <FontAwesomeIcon icon={faEdit}/></button>
                                                </Link>
                                                <button
                                                    onClick={() => handleOpenDelete(item.patient_death_id)}
                                                    className="btn btn-sm sms-btn-dismiss" data-testid={'delete-report-'+index}><FontAwesomeIcon
                                                    icon={faTrash}/>
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan="8" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
                {/*<Card className="pt-2">*/}
                {/*    <h6 className="visit-heading p-2">Monthly Death Report</h6>*/}
                {/*    <SmsBarChart fill="#ff91a4" name="Out-patient" industry="In-patient"*/}
                {/*                 data={allDeaths.map(item => ({*/}
                {/*                     uv: item.out_patient,*/}
                {/*                     pv: item.in_patient,*/}
                {/*                     name: item.name*/}
                {/*                 }))}*/}
                {/*                 height={250} industryFill="#72A0C1"/>*/}
                {/*</Card>*/}
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(DeathReport);
