import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {Link} from "react-router-dom";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import {faBed} from "@fortawesome/free-solid-svg-icons/faBed";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {useBedAssignList} from "./CustomHooks/useBedAssignList";
import {dateStringConvert, formatDateTime} from "../Utils/ConvertDate";
import {Container} from "../Utils/styledComponents";
import { ReusableRouteTabs } from "../Utils/Dialogs/ReusableTabs";
import { history } from "../../Helpers/history";
import { FullScreenDialog } from "../Utils/Dialogs/FullScreenDialog";
import {AssignBed} from "./AddEditBedAssign"


const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'Sl', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patient_admission_id', numeric: false, disablePadding: false, label: 'Admission No.',hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone',hidden:false},
    {id: 'kin_phone', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'age', numeric: false, disablePadding: false, label: 'Age',hidden:false},
    {id: 'gender', numeric: false, disablePadding: false, label: 'Gender',hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];

const AdmissionsWithoutBeds = ({actions, snackbars}) => {

    const {searchValue, order, orderBy, openDelete, data,  activePage,handleSearch,
        handleRequestSort, handleChangeNewPage, handleCloseDelete, handleDelete,
        closeSnackBar,  isLoading, isSuccess, isError,openDialog,
        handleOpenDialog, handleCloseDialog,assignProps} = useBedAssignList(actions,'cadmission/patient_admission_without_bed_assignments')

    const {openBar, type, message} = snackbars;
    const patients = !data.admission_list ? [] : data.admission_list;
    const count = !data.total_count ? 0 : data.total_count;

    const components = [{label: 'Admissions without beds', path: '/unassignedbeds'},
    {label: 'Admissions with beds', path: '/bedassignlist'}]

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const admissions = patients.map((item, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...item,
            count,
            date:item.admission_date ? formatDateTime(item.admission_date) : '',
            patient_name:`${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
            action:(
                <button onClick={()=>handleOpenDialog({...item, patient_name: `${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`})}
                        className="btn btn-sm sms-info-btn mr-1 mb-1">Assign Bed</button>
            )
        }
    })

    return (
        <div data-testid="bed-assign-list" className='journals'>
            <PageTitle title="Admissions without beds"/>
            <SubHeader title="Ward Manager" subTitle="Admissions without beds">
                <FontAwesomeIcon icon={faBed}/>
            </SubHeader>
            <DeleteDialog message="delete" openDialog={openDelete} handleClose={handleCloseDelete}
                              text="patient" title='Delete Admission'>
                <button className=' btn sms-btn-dismiss btn-sm' onClick={handleDelete}>Delete</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseDelete}>No</button>    
            </DeleteDialog>
            <MainSnackbar variant={type} message={message} open={openBar} handleCloseBar={closeSnackBar}/>
            <FullScreenDialog open={openDialog} handleClose={handleCloseDialog} title="Assign Bed">
                <AssignBed {...assignProps}/>
            </FullScreenDialog>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
                <CustomTable  headData={headCells} handler={handleSearch} term={searchValue}
                             handleRequestSort={handleRequestSort} data={admissions} colSpan={headData.length} order={order}
                             orderBy={orderBy} customPage records={10} activePage={activePage}
                             handleChangeNextPage={handleChangeNewPage} total_count={count}
                              {...{all_hidden, handleAllHeadCells, handleHeadCells}}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                                </TableRow>:null}
                            {isSuccess ? admissions.length > 0 ?  stableSort(admissions, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.patient_admission_id}
                                            className='mui-table-row'>
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody>
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionsWithoutBeds);
