import {v4 as uuidv4} from "uuid";
import React, {useEffect, useState} from "react";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import config from "../../../Helpers/config.json";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {AccountingDrawer} from "../../Utils/AccountingUtils/AccountingDrawer";
import NewLedgerEntry from "./NewLedgerEntry";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";


export default function LedgerEntry({actions, isSubmitLedger, setIsSubmitLedger}) {
    const initialState = [{
        id: uuidv4(),
        account_name: '',
        account_id: '',
        description: '',
        entry_type: '',
        debit: '',
        credit: ''
    },
        {id: uuidv4(), account_name: '', account_id: '', description: '', entry_type: '', debit: '', credit: ''}]
    const [state, setState] = useState({date: dateConvert(), notes: ''});
    const [accounts, setAccounts] = useState([]);
    const [ledger, setLedger] = useState(initialState);
    const [total, setTotal] = useState({debit_total: 0, credit_total: 0})
    const [submitted, setSubmitted] = useState(false);
    const [open, setOpen] = useState(false)
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)



    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/chart/journals`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])

    const handleChange = (event) => {
        const {value, name} = event.target;
        setState({...state, [name]: value})
        setIsBlocking(true)
    }

    const handleChangeAccount = (val, id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                return {...item, account_id: val}
            }
            return item
        })
        setLedger(arr)
        setIsBlocking(true)
    }
    const calculateTotal = (arr) => {
        const debit_total = arr.reduce((sum, item) => {
            return +sum + +item.debit;
        }, 0)
        const credit_total = arr.reduce((sum, item) => {
            return +sum + +item.credit;
        }, 0)
        setTotal({debit_total, credit_total})
    }

    const handleBlurDebit = (id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                if (item.credit !== '' && item.debit) {
                    return {...item, credit: '', entry_type: 'DEBIT'}
                } else {
                    return item;
                }
            }
            return item
        })
        setLedger(arr);
        calculateTotal(arr)
        setIsBlocking(true)
    }
    const handleBlurCredit = (id) => {
        const arr = ledger.map(item => {
            if (item.id === id) {
                if (item.debit !== '' && item.credit) {
                    return {...item, debit: '', entry_type: 'CREDIT'}
                } else {
                    return item;
                }
            }
            return item
        })
        setLedger(arr);
        calculateTotal(arr)
        setIsBlocking(true)
    }

    const handleChangeItems = (event, id) => {
        const {value, name} = event.target;
        const arr = ledger.map(item => {
            if (item.id === id) {
                return {...item, [name]: value, entry_type: name === 'debit' ? 'DEBIT' : name === 'credit' ? 'CREDIT':''}
            }
            return item
        })
        setLedger(arr)
        setIsBlocking(true)
    }

    const addItem = () => {
        const row = {
            id: uuidv4(),
            account_name: '',
            account_id: '',
            description: '',
            entry_type: '',
            debit: '',
            credit: ''
        }
        setLedger([...ledger, row])
    }

    const removeRow = (id) => {
        const arr = ledger.filter(item => item.id !== id)
        setLedger(arr)
    }

    const handleSubmit = (event) => {
        const {date} = state;
        event.preventDefault();
        const formData = new FormData();
        formData.append('date_added', date);
        setSubmitted(true);
        ledger.filter(item => item.account_id.value).forEach(item => {
            if (item.entry_type === 'CREDIT'){
                formData.append('credit_ledger',   item.account_id.value);
            }
            if (item.entry_type === 'DEBIT'){
                formData.append('debit_ledger',   item.account_id.value);
            }
            formData.append('entry_category', item.entry_type);
            formData.append('amount', item.debit ? item.debit : item.credit ? item.credit : 0);
            formData.append('description', item.description);
        })
        const arr = ledger.every(item => item.account_id)
        setIsBlocking(false)
        if (arr && date) {
            setIsSubmitLedger('pending');
            axios.post(`${config.smsUrl}/accounting/journals/entry`, formData).then(() => {
                actions.snackbarActions.snackSuccess('Entry made successfully')
                setOpen(false)
                setIsSubmitLedger('resolved')
                const arr = ledger.map(item=>({
                    ...item,
                    account_name: '',
                    account_id: '',
                    description: '',
                    entry_type: '',
                    debit: '',
                    credit: ''
                }))
                setSubmitted(false)
                setLedger(arr)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitLedger('rejected')
            })
        }
    }

    const handleResetForm = () =>{
        setOpen(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        const arr = ledger.map(item=>({
            ...item,
            account_name: '',
            account_id: '',
            description: '',
            entry_type: '',
            debit: '',
            credit: ''
        }))
        setLedger(arr)
    }

    const handleOpenDialog = () =>{
        setOpen(true)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpen(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }

    const isPending = isSubmitLedger === 'pending'

    const ledgerProps = {
        state, accounts, ledger, handleChange, handleChangeAccount, handleChangeItems, removeRow,
        addItem, total, handleBlurCredit, handleBlurDebit, handleSubmit, isPending, submitted,
        isBlocking, isBlockDialog,setIsBlockDialog, setIsBlocking, open, setOpen, handleResetForm
    }



    return (
        <div>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <div className="text-right">
                <button onClick={handleOpenDialog} className="btn btn-sm sms-info-btn">New Entry</button>
            </div>
            <AccountingDrawer open={open} title='New Ledger Entry' handleClose={handleCloseDialog}>
                <NewLedgerEntry {...ledgerProps}/>
            </AccountingDrawer>
        </div>
    )
}