import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { config } from '../../../../Helpers/env'
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useNewCOAccount} from "../../ChartofAccounts/hooks/useNewCOAccount";
import {dateConvert} from "../../../Utils/ConvertDate";

export const useNewExpenseItem = (actions) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [debitLedgers, setDebitLedgers] = useState([]);
    const [creditLedgers, setCreditLedgers] = useState([]);
    const [debitLedger, setDebitLedger] = useState(null);
    const [creditLedger, setCreditLedger] = useState(null);
    const [expenseItem, setExpenseItem] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [id, setId] = useState('')
    const [editObj, setEditObj] = useState({})
    const [expense_type, setExpenseType] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const balance_date = dateConvert()


    const { openDialog:openLedger, handleOpenDialog:handleOpenLedger, handleCloseDialog:handleCloseLedger,
        coaProps, isResolved:isResolvedLedger} = useNewCOAccount(actions, balance_date)


    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.smsUrl}/accounting/chart/expense_ledgers`)
                let _data = res.data
                let dt = _data.data ?? [];
                let debits = dt.map(i => ({label: i.head_name, value: i.id}))
                setDebitLedgers(debits)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [isResolvedLedger])

    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.smsUrl}/accounting/chart/cash_ledgers`)
                let _data = res.data
                let dt = _data.data ?? [];
                let credits = dt.map(i => ({label: i.head_name, value: i.id}))
                setCreditLedgers(credits)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [])

    useEffect(() => {
        if(!id){
            return
        }
        (async () => {
            try {
                const res = await axios.get(`${config.smsUrl}/accounting/expenses_types`,{params:{id}})
                let _data = res.data
                let dt = _data.data ?? {};
                const type = dt.expense_type_name ? dt.expense_type_name :''
                setEditObj(dt)
                setExpenseType(type)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [id])

    useEffect(()=>{
        if(!(id && expense_type)){
            return
        }
        const {expense_type_name, debit_ledger, debit_ledger_name, credit_ledger, credit_ledger_name} = editObj
        setExpenseItem(expense_type_name)
        setDebitLedger({value:debit_ledger, label:debit_ledger_name})
        setCreditLedger({value:credit_ledger, label:credit_ledger_name})



    },[editObj, expense_type, id])



    const handleChangeExpenseItem = (event) => {
        const {value} = event.target
        setExpenseItem(value)
        setIsBlocking(true)
    }

    const handleChangeDebitLedger = (value) => {
        setDebitLedger(value)
        setIsBlocking(true)
    }

    const handleChangeCreditLedger = (value) => {
        setCreditLedger(value)
        setIsBlocking(true)
    }



    const handleOpenDialog = (_id) =>{
        setOpenDialog(true)
        setId(_id)
    }

    const handleCloseDialog = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpenDialog(false)
            setIsBlockDialog(false)
            setIsBlocking(false)

        }
    }
    const handleResetForm = () =>{
        setOpenDialog(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setExpenseItem(null)
        setDebitLedger(null)
        setCreditLedger(null)
    }

    const submitNewExpense = (event) =>{
        event.preventDefault();
        const formData = new FormData()
        const debit = debitLedger ? debitLedger : {}
        const credit = creditLedger ? creditLedger : {}
        if(id){
            formData.append('id', id)
        }
        formData.append('expense_type_name', expenseItem)
        formData.append('debit_ledger', debit.value ? debit.value : '')
        formData.append('credit_ledger', credit.value ? credit.value : '')
        setSubmitted(true);
        setIsBlocking(false)
        if (expenseItem) {
            const url = id ? 'edit' : 'add'
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/accounting/expenses_types/${url}`, formData).then(() => {
                actions.snackbarActions.snackSuccess(`Expense item ${ id ? 'edit' : 'added'} successfully`)
                setOpenDialog(false)
                setIsSubmitted('resolved')
                setExpenseItem(null)
                setDebitLedger(null)
                setCreditLedger(null)
                setSubmitted(false)
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }

    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const ledgerProps = {...coaProps, openLedger, handleCloseLedger, handleOpenLedger}

    const itemProps = {openDialog, debitLedger, debitLedgers, creditLedger, creditLedgers, expenseItem, submitted,
        isPending, isRejected, isResolved, handleChangeExpenseItem, handleChangeCreditLedger, handleChangeDebitLedger,
        handleOpenDialog, handleCloseDialog, submitNewExpense,  ledgerProps, editObj, id,
        isBlocking, isBlockDialog, setOpenDialog,setIsBlockDialog, setIsBlocking, handleResetForm
    }

    return {itemProps}
}
