import {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {history} from "../../../../Helpers/history";
import { v4 as uuidv4 } from 'uuid';
import {gmtDateConvert} from "../../../Utils/ConvertDate";
import {useAddSupplier} from "./useAddSupplier";

export const useAddPurchaseOrder = (actions, match) => {

    const {local_purchase_order_id, status, template} = match;
    const initialState = [{ product_name: '', product_id: '', quantity: '', rate: '',
        total: '', showProduct: false, isError:false,  uuid: uuidv4(),
    }]
    const [rows, setRows] = useState(initialState);
    const [supplier, setSupplier] = useState('');
    const [state, setState] = useState({
        ship_to_address: '', delivery_date: '', shipping_terms: '',
        fob: '', comments: ''
    });
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [order_id, setOrderId] = useState('');
    const [total_tax, setTotalTax] = useState(0);
    const [sub_total, setSubTotal] = useState(0);
    const [grand_total, setGrandTotal] = useState(0);
    const [suppliers, setSuppliers] = useState([]);
    const [items, setItems] = useState([]);
    const [purchaseOrder, setPurchaseOrder] = useState({});

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    const {supplierProps, openAddSupplier, setOpenAddSupplier,isResolved:isResolvedSupplier} = useAddSupplier(actions, true)


    useEffect(() => {
        if(!local_purchase_order_id){
            return
        }
        const formData = new FormData();
        formData.append('local_purchase_order_id',local_purchase_order_id)
        axios.post(`${config.smsUrl}/cinventory/purchaseoder_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            setPurchaseOrder(dt)
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, [local_purchase_order_id]);

    useEffect(()=>{
        if(!local_purchase_order_id && purchaseOrder){
            return
        }
        const {delivery_date:deliver_date, fob, grand_total, supplier_id, supplier_name,
            shipping_termes, product_list,ship_to_address} = purchaseOrder;
        const products = !product_list ? [] : product_list;
        setState({delivery_date: deliver_date, fob,shipping_terms: shipping_termes,ship_to_address});
        setSupplier({label:supplier_name,value:supplier_id});
        setGrandTotal(grand_total)
        setRows(products)
    }, [purchaseOrder, local_purchase_order_id])

    useEffect(() => {
        axios.get(`${config.smsUrl}/csupplier/retrieve_all_suppliers`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const supps = !dt.total_manufacturers ? [] : dt.total_manufacturers;
            const arr =  supps.map(item=>({
                label:item.supplier_name,value:item.supplier_id
            }))
            setSuppliers(arr);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions)
        })
    }, [isResolvedSupplier]);


    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
    };

    const handleChangeSupplier = value => {
        setSupplier(value);
    };

    const handleChangeItems = (event, index) => {
        let rowsCopy = [...rows];
        let {name, value} = event.target;
        let tmp = rowsCopy[index] ? rowsCopy[index] : {};
        tmp[name] = value;
        rowsCopy[index] = tmp;
        setRows(rowsCopy);
    };


    const handleAddRow = () => {
        setRows([...rows, ...initialState])
    };


    const handleRemoveSpecificRow = (item_uuid) => {
        const new_arr = rows.filter(e => e.uuid !== item_uuid);
        calculateGrandTotal(new_arr, total_tax);
        setRows(new_arr)
    };

    const handleChangeTax = (event, arr) => {
        setTotalTax(event.target.value);
        calculateGrandTotal(arr, event.target.value)
    };

    const calculateTax = (tx) => {
        const tax = !tx ? 0 : tx;
        return tax / 100;
    };

    const calculateGrandTotal = (arr, tx) => {
        const sum = arr.reduce((a, row) => {
            return +(a + row.total);
        }, 0);
        const tax_amount = calculateTax(tx) * sum;
        const total_amount = sum + tax_amount;
        setSubTotal(sum)
        setGrandTotal(total_amount)
    };


    const retrieveProducts = (event, idx) => {
        const productCopy = [...rows];
        if (productCopy[idx]) {
            productCopy[idx].product_name = event;
            const formData = new FormData();
            // formData.append('supplier_id', supplier.value);
            formData.append('product_name', titleCase(event));
            if (event !== '') {
                axios.post(`${config.smsUrl}/cinventory/product_search_by_name`, formData).then(res => {
                    const resp = res.data;
                    const data = !resp ? [] : resp;
                    if (resp === "No Medicine Found") {
                        const val = [{value: "", label: "No Medicine Found"}]
                        setItems(val)
                    } else {
                        setItems(data);
                    }
                }).catch(error => {
                    logoutErrorMessage(error, null, actions)
                });
                productCopy[idx].showProduct = true;
                productCopy[idx].isError = false;
            } else {
                productCopy[idx].showProduct = false;
                productCopy[idx].isError = false;
                setItems([]);
                // actions.snackbarActions.snackInfo('Please select a supplier')
            }
        } else {
            productCopy[idx].showProduct = false;
        }
        setRows(productCopy)
    };

    const clickProduct = (event, idx) => {
        const productCopy = [...rows];
        const formData = new FormData();
        formData.append('product_id', event.value);
        if (productCopy[idx]) {
            productCopy[idx].product_name = event.innerText;
            productCopy[idx].product_id = event.value;
            productCopy[idx].isError = !event.value;
            productCopy[idx].showProduct = false;
            setRows(productCopy)
           
        }
    };

    const getTotalApparent = (index) => {
        const rowsCopy = [...rows]
        rowsCopy[index]['total'] = +(rowsCopy[index]['rate']) * +(rowsCopy[index]['quantity']);
        calculateGrandTotal(rowsCopy, total_tax)
    };

    const handleChangeQuantity = (event, index) => {
        let {value} = event.target;
        const rowsCopy = [...rows]
        if (rowsCopy[index]) {
            rowsCopy[index]["quantity"] = +value;
            getTotalApparent(index)

        }
        setRows(rowsCopy);
        calculateGrandTotal(rowsCopy)
    };

    const handleChangePrice = (event, index) => {
        let {value} = event.target;
        const productCopy = [...rows]
        if (productCopy[index]) {
            productCopy[index]["rate"] = +value;
            getTotalApparent(index)
        }
        setRows(productCopy);
        calculateGrandTotal(productCopy)
    }


    const handleSubmitPurchase = event => {
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        const fieldValue = Object.fromEntries(formData.entries())
        formData.append('supplier_id', supplier.value);
        formData.append('purchase_order_number', '');
        formData.append('tax', total_tax);
        formData.append('grand_total', grand_total);
        formData.append('user_roles_id', user_roles_id);
        // formData.append('status', status);
        rows.filter(item => item.quantity && item.product_id && item.rate).forEach(item => {
            formData.append('product_id', item.product_id);
            formData.append('quantity', item.quantity);
            formData.append('rate', item.rate);
            formData.append('total', item.total);
        })
        if (status === '0 '|| status === '2'){
            formData.append('status', +status);
        }
        const arr = rows.every(item => item.quantity && item.product_id && item.rate)
        setSubmitted(true);
        const params = {
            supplier_id: supplier?.value, purchase_order_number:'',tax:+total_tax,
            grand_total: +grand_total, user_roles_id, status:status === '0'|| status === '2' ? +status : null,
            products:rows.filter(item => item.quantity && item.product_id && item.rate),
            local_purchase_order_id:local_purchase_order_id && !template ? local_purchase_order_id:null,
            ...fieldValue,
        }
        const url = local_purchase_order_id || status || template ? 'purchaseoder_update' : 'creat_purchaseorder'
        const data = local_purchase_order_id || status || template ? params : formData
        if (supplier && fieldValue['ship_to_address'] && arr && fieldValue['delivery_date']) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cinventory/${url}`, params)
                .then(response => {
                    const data = response.data;
                    const dt = !data ? {} : data;
                    const id = !dt.local_purchase_order_id ? '' : dt.local_purchase_order_id;
                    actions.snackbarActions.snackSuccess('Purchase Order Saved Successfully');
                    handleOpenDialog(id)
                    setIsSubmitted('resolved')
                    setSubmitted(false)
                }).catch(error => {
                setIsSubmitted('rejected')
                errorMessages(error, null, actions);
            })
        }
    };

    const handleCloseBar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const handleOpenSupplierDialog = () => {
        setOpenAddSupplier(true)
    }
    const handleCloseSupplierDialog = () => {
        setOpenAddSupplier(false)
    }

    const handleOpenDialog = (_id) => {
        setOpenDialog(true);
        setOrderId(_id)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false)
        history.push('/purchaseorderlist')
    };
    const viewOrder = () => {
        history.push(`/purchaseorderapproval/${order_id}/0`)
    };

    const isResolved = isSubmitted === 'resolved'
    const isPending = isSubmitted === 'pending'

    return {
        rows, supplier, state, openDialog, submitted, isSubmitted, total_tax,
        sub_total, grand_total, suppliers, items, handleChange, handleSubmitPurchase,
        handleChangeSupplier, handleChangeItems, handleAddRow, handleRemoveSpecificRow,
        handleChangeTax, retrieveProducts, clickProduct, handleChangeQuantity, handleCloseBar,
        handleCloseDialog, viewOrder, local_purchase_order_id, handleOpenDialog, handleChangePrice,
        isPending, isResolved,  openAddSupplier, supplierProps,  handleCloseSupplierDialog,
        handleOpenSupplierDialog
    }
}