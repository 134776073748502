import React from "react";
import Form from 'react-bootstrap/Form'
import Label from "../../Utils/FormInputs/Label"
import TextField from "../../Utils/FormInputs/TextField";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";



function SingleSupplierPayment({state,  paid_through,  accounts, handleCloseDialog,handleSubmit,
                                   handleChangePaidThrough, handleChange,submitted, isPending}) {
    const {payment_date, payment_amount}= state
    return (
        <form onSubmit={handleSubmit} autoComplete='off'>
            <Form.Group>
                <Label name='Payment Date' type/>
                <TextField type='date' submitted={submitted} name='payment_date' value={payment_date} onChange={handleChange}/>
                {submitted && !payment_date ? <div className="help-block">Payment date is required</div>:null}
            </Form.Group>
            <Form.Group>
                <Label name='Payment Made(UGX)' type/>
                <TextField type='number' submitted={submitted} name='payment_amount' value={payment_amount} onChange={handleChange}/>
                {submitted && !payment_amount ? <div className="help-block">Payment made is required</div>:null}
            </Form.Group>
            <Form.Group>
                <Label name='Credit To' type/>
                <CustomSelect submitted={submitted} onChange={handleChangePaidThrough} value={paid_through} options={accounts}/>
                {submitted && !paid_through ? <div className="help-block">Payment account is required</div>:null}
            </Form.Group>
            {/*<div className='row'>*/}
            {/*    <div className='col-lg-6'>*/}
            {/*        <Form.Group>*/}
            {/*            <Label name='Payment Method' type/>*/}
            {/*            <CustomSelect submitted={submitted} onChange={handleChangePaymentMethod} value={payment_method} options={payTypes}/>*/}
            {/*            {submitted && !payment_method ? <div className="help-block">Payment method is required</div>:null}*/}
            {/*        </Form.Group>*/}
            {/*    </div>*/}
            {/*    <div className='col-lg-6'>*/}
            {/*        <Form.Group>*/}
            {/*            <Label name='Paid Through' type/>*/}
            {/*            <CustomSelect submitted={submitted} onChange={handleChangePaidThrough} value={paid_through} options={accounts}/>*/}
            {/*            {submitted && !paid_through ? <div className="help-block">Payment account is required</div>:null}*/}
            {/*        </Form.Group>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<Form.Group>*/}
            {/*    <Label name='Notes'/>*/}
            {/*   <Textarea name='notes' value={notes} onChange={handleChange}/>*/}
            {/*</Form.Group>*/}
            <div className="mt-5">
                <button type='submit' className='btn btn-sm sms-info-btn btn-sm mr-4'>{isPending ? 'Submitting...' : 'Submit'}</button>
                <button type='button' onClick={handleCloseDialog} className='btn btn-sm sms-gray-btn btn-sm'>Cancel</button>
            </div>

        </form>
    )
}
export {SingleSupplierPayment}