import React, {useEffect,  useState} from "react";
import axios from "axios";
import { config } from "../Helpers/env";
import {useSnackbar} from "./snackbar-context";

const DoctorContext = React.createContext()
DoctorContext.displayName = 'DoctorContex'


function DoctorProvider(props) {
    const {handleError} = useSnackbar()
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const department_id = !_token.department_id ? "" : _token.department_id;

    const [total_patients, setTotalPatients] = useState(0)

    const retrievePatients = () =>{
        const formData = new FormData();
        formData.append('search', '');
        formData.append('department_id', department_id);
        axios.post(`${config.smsUrl}/cdoctor/doctor_waiting_list/${1}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const count = !dt.total_count ? 0 : dt.total_count;
            setTotalPatients(count)
        }).catch(err => {
            handleError('Operation unsuccessful')
        })
    }



    useEffect(() => {
        retrievePatients()
        const interval = setInterval(()=>{
            retrievePatients()
        }, 10000)
        return()=>clearInterval(interval)
    }, []);


    const value = {total_patients}
    return <DoctorContext.Provider value={value}  {...props}/>
}

function useDoctorContext() {
    const context = React.useContext(DoctorContext)
    if (context === undefined) {
        throw new Error(`useCompany must be used within a CompanyProvider`)
    }
    return context
}

export {DoctorProvider, useDoctorContext}

