/** @jsxImportSource @emotion/react */
import {jsx, css} from '@emotion/react'
import React from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate} from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import Card from '@material-ui/core/Card'
import { formatDate } from '../../Utils/ConvertDate';
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import { useInPatientBillingReceipt } from './hooks/useInPatientBillingReceipt';
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {FooterCell, HeadCell, InpatientBillRow, TotalCell} from "./InpatientBillRow";
import TableBody from "@material-ui/core/TableBody";
import { history } from '../../../Helpers/history';
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";

const textWeight = css`
    font-weight:600;
`


function InpatientBillingReceipt(props) {
    const {state,  patient, items,exchangeRate, currencies,
        currency, handleChangeExchangeRate, handleChangeCurrency} = useInPatientBillingReceipt(props)
    const isBaseCurrency = currency === 'USD'
    const currency_symbol = !isBaseCurrency && exchangeRate ? currency : 'USD'

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:  'rate',value:`Rate`}, {key: 'amount',value:`Amount`}]

    const {total_bill, total_advance, due_amount, currency_total_bill,
        currency_paid_amount, currency_due_amount} = state;
    const {
        admission_id, patient_number, patient_name, admission_date,
        address, phone_no
    } = patient;

    const handleOnAfterPrint = () => {
        history.push('/patientbillinglist');
    }

    const {componentRef, handlePrint,  fontSize}  = usePrint('Current visit Information', handleOnAfterPrint);
    const patientDetails = (
        <tbody>
        <tr>
            <td><span>Admission Date: </span> <span css={[textWeight]}>{formatDate(admission_date)}</span> </td>
            <td><span>Patient Name: </span> <span css={[textWeight]}>{patient_name}</span> </td>

        </tr>
        <tr>
            <td><span>Patient Number: </span> <span css={[textWeight]}>{patient_number}</span></td>
            <td><span>Contact: </span> <span css={[textWeight]}>{phone_no}</span></td>
        </tr>
        <tr>
            <td><span>Admission No: </span> <span css={[textWeight]}>{admission_id}</span></td>
            <td><span>Address:</span> <span css={[textWeight]}>{address}</span></td>
        </tr>
        </tbody>
    )
    const itemDetails = (
        <TableContainer >
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(<HeadCell style={{width: index === 0 ? '500px':'300px'}} key={item.key}>{item.value}</HeadCell>))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((row) => (
                        <InpatientBillRow key={row.id} row={row} headData={headData}/>
                    ))}
                    <TableRow>

                        <FooterCell></FooterCell>
                        <FooterCell></FooterCell>
                        <FooterCell >Total Bill</FooterCell>
                        <FooterCell>{total_bill}</FooterCell>
                    </TableRow>

                    <TableRow>

                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell >Amount Received</TotalCell>
                        <TotalCell>{total_advance}</TotalCell>
                    </TableRow>

                    <TableRow>

                        <TotalCell></TotalCell>
                        <TotalCell></TotalCell>
                        <TotalCell>Balance</TotalCell>
                        <TotalCell>{due_amount}</TotalCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

      return (
          <div>
        <PageTitle title="Billing Receipt"/>
        <SubHeader title="Bill Details" subTitle="Bill Details">
            <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        <MiniCenteredDiv>
            <BackButton to='/patientbillinglist' text='Billing List' data-testid="inpatient-billing-receipt-back-button"/>
            <RightAlignedContainer>
              <Button variant='orange' onClick={handlePrint}>Print</Button>
            </RightAlignedContainer>
            {/*<Card className='mt-2 p-3'>*/}
            {/*    <div ref={componentRef} style={{padding:10}}>*/}
            {/*        <style>*/}
            {/*            /!* {getPageMargins()} *!/*/}
            {/*            {fontSize()}*/}
            {/*        </style>*/}
            {/*        <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>*/}
            {/*    </div>*/}
            {/*</Card>*/}
            <Card className='mt-2'>
                {/*<div style={{padding:'8px 10px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>*/}
                {/*    <RightAlignedContainer>*/}
                {/*        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />*/}
                {/*        { currency !== 'USD' ? <FxRate currency={currency !== 'USD' && currency}  value={exchangeRate}  handleChangeRate={handleChangeExchangeRate} />: null}*/}
                {/*    </RightAlignedContainer>*/}
                {/*</div>*/}
                <div ref={componentRef} style={{padding:10}}>
                    <style>
                        {fontSize()}
                    </style>
                    <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>
                </div>
            </Card>
        </MiniCenteredDiv>
        </div>
      )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}


export default connect(mapStateToProps, mapDispatchToProps)(InpatientBillingReceipt);