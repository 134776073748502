import React from 'react';
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import {history} from "../../../../Helpers/history";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: 0,
        paddingBottom: 0
    },
    listItem: {
        borderBottom: '1px solid #e8e8e8',
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '10px',
        paddingLeft: '10px',
        cursor: 'pointer',
        textTransform:'capitalize',
        '&:hover': {
            borderRight: "2px solid #3f51b5",
            color: '#3f51b5',
            background: 'rgba(14, 118, 186, .08)'
        }
    },
    activeLi: {
        borderRight: "2px solid #3f51b5",
        color: '#3f51b5',
    },
    primary: {
        fontSize: '0.8em'
    },
    item2:{
         borderLeft: '1px solid #c7c6c7',
    },
    item: {
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    }
}));

// component with routes for the Ante Natal screen
export const anteNatalRoutes = (patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id = null, anc_id, anc_status) => {
    const arr =  [
        {
            text:'Ante Natal Medical History',
            path: `/antenatal_previous_visits/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        },  
        {
            text:'Ante Natal Card',
            path: `/antenatal_patient_form/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        },
        {
            text: 'Triage',
            path: `/antenatal_outpatient_triage/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        },
        {
            text: 'Diagnosis',
            path: `/antenatal_diagnosis/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        },
        {
            text: 'Laboratory',
            path: `/antenatal_laboratory/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        },
        {
            text: 'Radiology', 
            path: `/antenatal_radiology/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        },
        {
            text: 'Prescription',
            path: `/antenatal_prescription/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        },
        patient_type === '1' ? {
            text: 'Admission',
            path: `/antenatal_admission/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        }:null,
        {
            text: 'Referral Note',
            path: `/antenatal_referral/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        },
        {
            text: 'View Treatment Details',
            path: `/antenatal_details/${patient_number}/${visit_id}/${labrequest_id}/${scan_request_id}/${patient_type}/${patient_admission_id}/${anc_id}/${anc_status}`
        }
    ]
    return arr.filter(item=>Boolean(item))
}

// component that will display the ante natal routes
const ReusableAnteNatalTabs = ({children, components = []}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={0} data-testid="resusable-ante-natal-tabs">
            <Grid item xs={4} sm={2} md={2} lg={1} classes={{item: classes.item}}>
                {components.filter(Boolean).map((item, index) => (
                    <List className={classes.root} key={index}>
                        <ListItem
                            classes={{root: `${classes.listItem} ${history.location.pathname === item.path ? classes.activeLi : ''}`}}
                            onClick={() => {
                                history.push(item.path)
                            }}>
                            <ListItemText primary={item.text} classes={{
                                primary: classes.primary,
                            }}/>
                        </ListItem>
                    </List>
                ))}
            </Grid>
            <Grid item xs={8} sm={10} md={9} lg={11} classes={{item: classes.item2}}>
                {children}
            </Grid>
        </Grid>
    )
}


export default ReusableAnteNatalTabs;