import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable,  { useHiddenColumns } from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../../Utils/smsTitle";
import {Link} from "react-router-dom";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {history} from "../../../Helpers/history";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {useAdmissionList} from "./hooks/useAdmissionList";
import {formatDateTime} from "../../Utils/ConvertDate";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import { LoadingGif } from "../../Utils/Loader";
import { Container } from "../../DoctorsModule/doctorStyles";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL', hidden: false},
    {id: 'admissionDate', numeric: false, disablePadding: false, label: 'Admission Date', hidden: false},
    {id: 'admissionId', numeric: false, disablePadding: false, label: 'Admission ID', hidden: false},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name', hidden: false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden: false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden: false},
    {id: 'ward', numeric: false, disablePadding: false, label: 'Ward', hidden: false},
    {id: 'room', numeric: false, disablePadding: false, label: 'Room', hidden: false},
    {id: 'bed', numeric: false, disablePadding: false, label: 'Bed', hidden: false},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action', hidden: false},
];
 
const AdmissionList = ({actions, snackbars}) => {
    const {searchValue,  order, orderBy, activePage, admissionList:admission_list, isLoading,
        isSuccess,isError, handleSearch, handleRequestSort, handleChangeNextPage, closeSnackbar,
    } = useAdmissionList(actions,'cadmission/admission_list');

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells,
    } = useHiddenColumns(headData);

    const {openBar, type, message} = snackbars;
    const components = [{label: 'Admitted Patients', path: `/patientroundlist`},
        // {label: 'Lab/Radiology Reports', path: `/medicationresults`},
        {label: 'Discharged Patients', path: `/dischargelist`}];
    const obj = admission_list ? admission_list : {};
    const admissionList = !obj.admission_list ? [] : obj.admission_list;
    const total_count = !obj.total_count ? 0 : obj.total_count;
    return (
        <div data-testid="admission-list" className='journals'>
            <PageTitle title="Admitted Patients"/>
            <SubHeader title="Medication and Rounds" subTitle="Admitted patients">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <Container>
                <div className="mui-tables">
                    <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                    {/* <div className="text-right">
                        <button onClick={handleRefresh} className="btn btn-sm sms-info-btn mb-1">Refresh</button>
                    </div> */}
                    <CustomTable title="Admitted Patients" customPage
                                handleChangeNextPage={handleChangeNextPage}
                                headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                                activePage={activePage}  all_hidden={all_hidden} handleAllHeadCells={handleAllHeadCells} 
                                handleHeadCells={handleHeadCells}
                                handleRequestSort={handleRequestSort} data={admissionList} colSpan={headData.length} order={order}
                                orderBy={orderBy} term={searchValue}>
                            <TableBody>
                                {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center"><LoadingGif/></TableCell>
                                </TableRow>:null}
                                {isSuccess ? admissionList.length > 0 ?  stableSort(admissionList, getComparator(order, orderBy))
                                    .slice(0, 10)
                                    .map((item, index) => {
                                        const count = index += 1;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'
                                            >
                                                <TableCell>{count}</TableCell>
                                                <TableCell hidden={headCells[1].hidden}>{formatDateTime(item.admission_date)}</TableCell>
                                                <TableCell hidden={headCells[2].hidden}>{item.patient_admission_id}</TableCell>
                                                <TableCell hidden={headCells[3].hidden}>{`${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`}</TableCell>
                                                <TableCell hidden={headCells[4].hidden}>{item.phone_no}</TableCell>
                                                <TableCell hidden={headCells[5].hidden}>{item.kin_phone_no}</TableCell>
                                                <TableCell hidden={headCells[6].hidden}>{item.ward}</TableCell>
                                                <TableCell hidden={headCells[7].hidden}>{item.room}</TableCell>
                                                <TableCell hidden={headCells[8].hidden}>{item.bed_number}</TableCell>
                                                <TableCell hidden={headCells[9].hidden}>
                                                    <Link
                                                        to={{pathname: `/takeinpatientvitals/${item.patient_number}/${item.visit_id}/${item.patient_admission_id}`}}>
                                                        <button className="btn btn-sm sms-info-btn mr-1 mb-1">Triage</button>
                                                    </Link>
                                                    <Link
                                                        to={{pathname: `/previousvisits/${item.patient_number}/${item.visit_id}/${null}/${null}/${null}/2/${item.patient_admission_id}`}}>
                                                        <button className="btn btn-sm sms-btn mr-1 mb-1">Check up</button>
                                                    </Link>
                                                    <Link
                                                        to={{pathname: `/inpatienttreatment/${item.visit_id}/${item.patient_admission_id}/${item.patient_number}`}}>
                                                        <button className="btn btn-sm sms-amber-btn mr-1 mb-1">Treatment</button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }):<TableRow>
                                    <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                                {isError ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow>: null}
                            </TableBody>
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionList);
