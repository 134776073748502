import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import { errorMessages, logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import { useDDOFilterComponent } from "./useDDOFilterComponent";
import { history } from "../../../Helpers/history";

export const usePastSurgeries = (actions) => {

    // handles loading of the table
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'reject';

    const [canOpen, setCanOpen] = useState(false);
    const [ids, setIds] = useState({patient_number: '', visit_id: '', status:0, visit_type:'', patient_name:'', age:'', gender:'', screen:''});

    // store the surgeries found
    const [surgeryList, setSurgeryList] = useState({surgeries: [], total_count:0});

    // values for the search
    const [searchValue, setSearchValue] = useState('');

    // status of the searching in the db
    const [status, setStatus] = useState('idle');

    // keep track of the page numbers in the table
    const [activeTablePage, setActiveTablePage] = useState(1);

    const {
        currentstate, handleSubmitFilter, handleFilterChange, submittedDDOFilter
    } = useDDOFilterComponent(actions, activeTablePage, setSurgeryList, 2);

    const {
        start_date, end_date, filter_by, department, filter_operation_class,
    }  = currentstate;

    // api call to get the visits
    useEffect(() => {
        setStatus('pending');

        const formD = new FormData();
        formD.append("status", 2);
        formD.append("page", activeTablePage);
        axios.post(`${config.smsUrl}/surgery/list/${activeTablePage}`, formD).then(res => {

            const response = res['data'];
            const dt = response ? response : [];

            const surgery_arr = dt.map((item, index) => ({
                count: (activeTablePage - 1) * 10 + (index + 1),
                date: item.begin_datetime,
                surgery_id: item.id,
                visit_id: item.visit_id,
                patient_number: item.patient_number,
                patient_name: `${item.first_name} ${item.last_name}`,
                status: item.status,
                operation_date: item.operation_date,
                operation_class: item.operation_class,
                surgery_name : item.service_name,
                lead_surgeon: item.lead_surgeon,
                service_id : item.service_id,
                surgery_department : item.department_name,
            }));

            setSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
            setStatus('success');
        }).catch(err => {
            errorMessages(err, null, actions)
            //  setLoading('error')
        })

    }, [activeTablePage, isResolved]);
       
    const [openDialog, setOpenDialog] = useState(false);

    const handleSearch = (value) => {
        setSearchValue(value);
        const formData = new FormData();
        setStatus('pending');
        formData.append("status", 2);
        formData.append("page", activeTablePage);
            if (value !== '') {
                formData.append('search', value);
            }

            if (filter_by) {
                formData.append('option', Number(filter_by));
            }
            
            if (filter_by === '1') {
                // filter by operation date
                if (start_date && end_date) {
                    formData.append('start_date', start_date);
                    formData.append('end_date', end_date);
                }
            } else if (filter_by === '2') {
                // filter by department/surgery_name
                if (department) {
                    formData.append('department_id', department);
                }
            } else if (filter_by === '3') {
                //filter by operation class
                if (filter_operation_class) {
                    formData.append('operation_class', filter_operation_class);
                }
            }

                axios.post(`${config.smsUrl}/surgery/list/${activeTablePage}`, formData).then(res => {

                    const response = res['data'];
                    const dt = response ? response : [];

                    const surgery_arr = dt.map((item, index) => ({
                        count: (activeTablePage - 1) * 10 + (index + 1),
                        date: item.begin_datetime,
                        surgery_id: item.id,
                        visit_id: item.visit_id,
                        patient_number: item.patient_number,
                        patient_name: `${item.first_name} ${item.last_name}`,
                        status: item.status,
                        operation_date: item.operation_date,
                        operation_class: item.operation_class,
                        surgery_name : item.service_name,
                        lead_surgeon: item.lead_surgeon,
                        service_id : item.service_id,
                        surgery_department : item.department_name,
                    }));

                    setSurgeryList({surgeries: surgery_arr, total_count: surgery_arr.length});
                    setStatus('success');
                }).catch((err) => {
                    errorMessages(err, null, actions)
                })
    }
    
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeNewPage = (page) => {
        setActiveTablePage(page);
    }
    
    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error';

    useEffect(() => {

        if (ids.patient_number !== '') {
            if (canOpen === false) {
                setIds({patient_number: '', visit_id: '', status:0, visit_type:'', patient_name:'', age:'', gender:'', screen:''})
            } else {
                const doctor_view = true;
                const formD = new FormData();
                formD.append("surgery_id", ids.surgery_id);
                formD.append("visit_id", ids.visit_id);
                formD.append("doctor_view", doctor_view.toString());
                
                const formData = new FormData();
                formData.append('patient_number', ids.patient_number);

                const formData3 = new FormData();
                formData3.append('visit_id', ids.visit_id)

                const req1 = axios.post(`${config.smsUrl}/surgery/view_surgery_details`, formD);
                const req2 = axios.post(`${config.smsUrl}/cpatient/patient_update_form`, formData);
                const req3 = axios.post(`${config.smsUrl}/surgery/view_surgery_materials`,formData3);
        
                Promise.all([req1, req2, req3]).then(([res1, res2, res3]) => {

                    setIsSubmitted('pending')

                    const resp = res1.data;
                    const data2 = res2.data;
                    const data3 = res3.data;
                    const details2 = !data2 ? {} : data2;
                
                    const response = resp ? resp : {};
                    const diagnosis = response.diagnosis ? response.diagnosis : [];
                    const triage = response.triage ? response.triage : [];

                    const surgery_data = response.surgery_data ? response.surgery_data : {};
                 
                    const materials = data3 ?? {};
        
                    setIds({...ids, ...surgery_data, ...details2, ...materials, diagnosis, triage, 'screen':'preview'})
                    setOpenDialog(true);
                
                    setIsSubmitted('resolved')
                }).catch(errs => {
                    errorMessages(errs, null, actions);
                    setIsSubmitted('rejected')
                })

            }
        }

    }, [canOpen])
    
    const handleOpenDialog = (item, screen) => {
        setIds(item);
        setCanOpen(true);
    };

    const handleCloseDialog = () => {
        setCanOpen(false);
        setOpenDialog(false);
    };

    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                text: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    const ddoFilterProps = {
        currentstate, handleSubmitFilter, handleFilterChange, submittedDDOFilter
    }

    const handleOnAfterPrint = () => {
        handleCloseDialog();
        history.push('/surgerymodule/pastsurgeries');
    }

    return {
        isPending, isRejected, isResolved,
        searchValue, activeTablePage, handleOnAfterPrint,
        isError, isLoading, isSuccess, ddoFilterProps,
        handleSearch, handleChangeNewPage,
        handleOpenDialog, handleCloseDialog, ids, openDialog,
        surgeryList, setSurgeryList, departments, handleRequestSort, order, orderBy
    }
}