import {useEffect, useReducer, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {history} from "../../../../Helpers/history";
import {useSelfRequestPrescription} from "../../../Visit/NewService/hooks/usePrescriptionSelfRequest";
import {useNewServiceRequest} from "../../../Visit/NewService/hooks/useNewServiceRequest";
import {billingServicesReducer} from "../../utils/billingServicesReducer";
import {billingProductsReducer} from "../../utils/billingProductsReducer";
import * as billingActions from "../../utils/billingActions"
import {filterServices, filterProducts as filterProductsArr, filteredPayloadProducts} from "../../utils/SanitizeArr";

export const useAddBill = (props) => {
    const {actions} = props;
    const [{procedureData,invoicesEdited,invoicesRemoved,invoicesPaid}, dispatch] = useReducer(billingServicesReducer,{
        procedureData:[],invoicesEdited:[],invoicesRemoved:[],invoicesPaid:[]
    })
    const [{products,productsEdited,productsRemoved,productsPaid}, dispatchProducts] = useReducer(billingProductsReducer,{
        products:[],productsEdited:[],productsRemoved:[],productsPaid:[]
    })
    const [payment_method, setPaymentMethod] = useState('');
    const [state, setState] = useState({notes: '', patient_name: '', patient_number: '', address: ''});
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [date, setDate] = useState(dateConvert());
    const [openPrint, setOpenPrint] = useState(false);
    const [total_amount, setTotalAmount] = useState(0);
    const [paidAmount, setPaidAmount] = useState("");
    const [balance, setBalance] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [payTypes, setPayTypes] = useState([]);
    const [addService, setAddService] = useState(false)
    const [addProduct, setAddProduct] = useState(false)
    const [departments, setDepartments] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [usdAmount, setUsdAmount] = useState(0)
    const [currencyTotal, setCurrencyTotal] = useState(0)
    const [isBlocking, setIsBlocking] = useState(false)
    // const [total_bill, setTotalBill] = useState(0)
    
   
    const {visit_id, transaction_id} = props.match.params;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const obj = {visit_id, user_roles_id, actions}



  

    const {renderPrescription, isResolved, handleClosePrescriptionDialog} = useSelfRequestPrescription({...obj, setOpenDialog:setAddProduct})

    const {renderService, isResolvedService, handleCloseServiceDialog} = useNewServiceRequest({...obj,setOpenDialog:setAddService}, true)



    // get payment methods
    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/payment_methods`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:item.id, text:item.payment_type_name})).filter(item=> item.text === 'CASH')
            setPayTypes(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);



    //get departments
    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [])



    const calculateTotal = (procArr, prdts, rate = 0) => {
        const proc_total = procArr.reduce((a, item) => {
            return a + item.subTotal
        }, 0)

        const prdtArr = prdts.reduce((a, item) => {
            return a + item.subTotal
        }, 0)

        const currency_service_total = procArr.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) : 0
            return a + +base_total
        }, 0)
        const product_service_total = prdts.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)
        const amt = paidAmount - (proc_total + prdtArr);
        const bal = amt < 0 ? 0 : amt;
        setBalance(bal)
        // setTotalAmount(proc_total + prdtArr)
        setCurrencyTotal(currency_service_total + product_service_total)
    }


    // get data for bill form
    useEffect(() => {

        function retrieveBillDetails() {
            const formData = new FormData();
            formData.append('visit_id', visit_id);
            formData.append('pay_status', 0);
            axios.post(`${config.smsUrl}/cbilling/bill_update_form`, formData).then(res => {
                const data = res.data;
                const all_data = !data ? {} : data;
                const patient = !all_data.patient_info ? {} : all_data.patient_info;

                const bill_details = !all_data.bill ? [] : all_data.bill;
                const bill_amount = !all_data.total_bill ? 0 : all_data.total_bill
                setTotalAmount(bill_amount.toFixed(4))


                const { due_amount, paid_amount} = patient;

                setState({
                    patient_name: titleCase(`${!patient.patient_firstname ? "" : patient.patient_firstname} ${!patient.patient_lastname ? "" : patient.patient_lastname}`),
                    patient_number: !patient.patient_number ? "" : patient.patient_number,
                    address: titleCase(!patient.address ? "" : patient.address), notes: ''
                });

                const filteredArr = bill_details.filter(item=>item.product_id);
                const procs = bill_details.filter(item => item.service_id);

                const services = filterServices(exchangeRate, procs)

                const prescr = filterProductsArr(exchangeRate, filteredArr);

                dispatch({type:billingActions.RETRIEVE_SERVICES, services})
                dispatchProducts({type: billingActions.RETRIEVE_PRODUCTS,products:prescr})

                setBalance(due_amount ? due_amount : 0);
                setPaidAmount(paid_amount ? paid_amount : '')

                calculateTotal(services, prescr)
            }).catch(err => {
                logoutErrorMessage(err, null, actions)
            })
        }

        retrieveBillDetails()
        const interval = setInterval(()=>{
            retrieveBillDetails()
        }, 15000)

        return ()=>clearInterval(interval)
    }, [isResolved, isResolvedService]);



    // const  countDecimals = (value) => {
    //     if(Math.floor(value) === value) return 0;
    //     return value.toString().split(".")[1].length || 0;
    // }


    const multiplyCurrency = (exchange_rate, amount_received) => {
        const amount_in_usd = +exchange_rate * +amount_received
        // const decimals = countDecimals(total_amount)
        // const usd_amount = +amount_in_usd.toFixed(decimals)
        setUsdAmount(amount_in_usd)
    }


    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setExchangeRate(event.target.value)
        multiplyCurrency(event.target.value, paidAmount)

        dispatchProducts({type: billingActions.CALCULATE_PRODUCT_AMOUNT, rate:event.target.value})
        dispatch({type: billingActions.CALCULATE_SERVICE_AMOUNT, rate:event.target.value})

        calculateTotal(procedureData, products, event.target.value)
        setIsBlocking(true)
    }

    //change amount received
    const handleChangePaidAmount = (event) => {
        const isBase = currency.split('-')[1]
        const isBaseCurrency = isBase === 'true'
        setPaidAmount(event.target.value);
        const total_amt = isBaseCurrency ? +total_amount : +currencyTotal
        const amount = +(event.target.value) -  total_amt;
        const amt = amount < 0 ? 0 : amount;
        setBalance(amt)
        multiplyCurrency(exchangeRate, event.target.value)
        setIsBlocking(true)
    };


    const handleChangeCurrencyRate = (event,bill_id) =>{
        const prods = products.filter(item=>!item.isExcluded)
        dispatch({
            type:billingActions.CHANGE_SERVICE_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods
        })
        setIsBlocking(true)
    }

    const handleChangeRate = (event,bill_id) =>{
        const prods = products.filter(item=>!item.isExcluded)
        dispatch({
            type:billingActions.CHANGE_SERVICE_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods
        })
        setIsBlocking(true)
    }

    const handleDeleteProcedure = (item) => {
        const {bill_id} = item
        const prods = products.filter(item=>!item.isExcluded)
        dispatch({
            type:billingActions.DELETE_SERVICE,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods
        })
        setIsBlocking(true)
    };

    const handleChangeProductRate = (event,bill_id) =>{

        const procedures = procedureData.filter(item=>!item.isExcluded)
        dispatchProducts({
            type:billingActions.CHANGE_PRODUCT_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures
        })
        setIsBlocking(true)
    }

    const handleChangeProductCurrencyRate = (event,bill_id) =>{

        const procedures = procedureData.filter(item=>!item.isExcluded)
        dispatchProducts({
            type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures
        })
        setIsBlocking(true)
    }


    const handleChangeProductQuantity = (event,bill_id) =>{
        const procedures = procedureData.filter(item=>!item.isExcluded)
        dispatchProducts({
            type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures
        })
        setIsBlocking(true)
    }

    const handleDeleteProduct = (item) => {
        const {bill_id} = item
        const procedures = procedureData.filter(item=>!item.isExcluded)
        dispatchProducts({
            type:billingActions.DELETE_PRODUCT,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures
        })
        setIsBlocking(true)
    };


    const handleChangeDate = (event) => {
        setDate(event.target.value)
        setIsBlocking(true)
    };

    const handleChangeMethod = (event) => {
        const {value} = event.target;
        setPaymentMethod(value);
        setIsBlocking(true)
    };

    const paidProducts = filteredPayloadProducts(productsPaid).filter(item=>!item.isExcluded)
    const editedProducts = filteredPayloadProducts(productsEdited).filter(item=>!item.isExcluded)
    const servicesPaid = invoicesPaid.filter(item=>!item.isExcluded)
    const servicesEdited = invoicesEdited.filter(item=>!item.isExcluded)
    const invoicesAdded = [...servicesPaid, ...paidProducts];
    const removedInvoices = [...filteredPayloadProducts(productsRemoved),...invoicesRemoved];
    const editedInvoices = [...servicesEdited, ...editedProducts]


    const handleOpenReceipt = (e) => {
        e.preventDefault();
        // const isBase = currency.split('-')[1]

        // const isNotBase = isBase === 'false'
        // const isBaseCurrency = isBase === 'true'



        // const requiredData = (isNotBase && +usdAmount === +total_amount) ||
        //     (isBaseCurrency && +paidAmount === +total_amount )
        // const isBaseRequired = (isNotBase && exchangeRate) || (isBaseCurrency && !exchangeRate)

        setIsBlocking(false)

        if (paidAmount && payment_method && +paidAmount === +total_amount){
            setOpenPrint(true);
        }else {
            setSubmitted(true)
        }
    }
    

    const handleSubmitBill = (event) => {
        event.preventDefault();
        const { notes} = state;
        setSubmitted(true);

        const isPaid = invoicesAdded.length > 0;
        const isEdited = editedInvoices.length > 0;
        const isInvoice = isPaid || isEdited;

        const amount = !isInvoice ? 0 : paidAmount;
        const payType = !isInvoice ? '' : payment_method;

        // const isBase = currency.split('-')[1]

        // const isNotBase = isBase === 'false'
        // const isBaseCurrency = isBase === 'true'
        //
        // const currency_id = currency.split('-')[0]


        const isBaseData = {is_base_currency:"true", currency_id:3 ,amount_paid: +amount}
        // const isNotBaseData = isNotBase ?  {currency_id,amount_paid_in_the_currency: +paidAmount, rate_input_by_cashier: +exchangeRate, total_amount_in_base_currency: +usdAmount,
        // amount_paid : +usdAmount,is_base_currency:isBase} : {}

        setIsSubmitted('pending')
        setIsBlocking(false)
        // const url = transaction_id ? 'payment_update' : total_amount === +paidAmount ?  'pay_bill' : 'pay_bill'
        axios.post(`${config.smsUrl}/cbilling/pay_bill`, {
            pay_type: payType ? +payType :  payType,
            visit_id: +visit_id,
            received_by: user_roles_id,
            memo: notes,
            bill_mode: "cash",
            // amount_paid: +amount,
            invoices_paid: invoicesAdded,
            invoices_removed: removedInvoices,
            invoices_edited:editedInvoices,
            ...isBaseData
            // ...isBaseData, ...isNotBaseData
        }).then((res) => {
            const data = res.data ?? {};
            let receipt_number;
            if (typeof data.receipt_numbers === "string"){
                receipt_number = data.receipt_numbers ?? '';
            }else {
                const numbers =  data.receipt_numbers ?? [];
                receipt_number = numbers.join('-');
            }
            actions.snackbarActions.snackSuccess(`created successfully`);
            setIsSubmitted('resolved')
            const url = !isInvoice ? '/paymentList' : `/billingReceipt/${visit_id}/${receipt_number}`
            history.push(url);
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsSubmitted('rejected')
        })
    };


    const handleCloseReceipt = () => {
        setOpenPrint(false);
    };


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleAddService =() =>{
        setAddService(true)
        
    }

    const handleAddProduct = () => {
        setAddProduct(true)
    }

    const handleCloseAddService = () => {
      // setAddService(false)
      handleCloseServiceDialog()
    }

    const handleCloseAddProduct=()=>{
      setAddProduct(false)
        handleClosePrescriptionDialog()
    }

    const {patient_name} = state

    
    const isExcluded = patient_name && ( invoicesAdded.length > 0 || editedInvoices.length > 0);


    return {payment_method, state,handleChangeProductRate,
        isSubmitted, date, openPrint, total_amount, paidAmount, balance, submitted,
        handleChangeDate, handleDeleteProcedure,  handleOpenReceipt,
        handleChangeMethod, handleChangePaidAmount, handleSubmitBill, handleCloseReceipt,
        procedureData, closeSnackbar, transaction_id, isExcluded,
        handleChangeProductQuantity, handleDeleteProduct, products,handleChangeRate,
        payTypes, handleAddProduct, handleAddService, addProduct, addService, departments, handleCloseAddService,
        handleCloseAddProduct, currencies, currency,usdAmount,currencyTotal,handleChangeCurrencyRate,
        handleChangeProductCurrencyRate, renderPrescription, renderService,
        handleChangeExchangeRate, handleChangeCurrency, exchangeRate, isBlocking}
}