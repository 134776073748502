import { useState, useEffect } from "react";
import axios from "axios";
import { config } from "../../../../../Helpers/env";
import { logoutErrorMessage } from "../../../../../Helpers/ErrorMessages";
import { usePatientDetails } from './../../../../DoctorsModule/ReferralNote/usePatientDetails';
import { useFirstScreenProps } from "./useFirstScreenProps";
import { useSecondScreenProps } from "./useSecondScreenProps";
import { useThirdScreenProps } from "./useThirdScreenProps";

export const convertChecksToString = (dict_objs) => {
    let new_str = '';
    for (const [key, value] of Object.entries(dict_objs)) {
        if (value) {
            new_str = new_str + key + ','; 
        }
    }
    return new_str;
}

// hooks for the steppers
const useAnteNatalSteppers = () => {

    const steps = ['1', '2', '3'];
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
  
    const totalSteps = () => {
      return steps.length;
    };
  
    const completedSteps = () => {
      return Object.keys(completed).length;
    };
  
    const isLastStep = () => {
      return activeStep === totalSteps() - 1;
    };
  
    const allStepsCompleted = () => {
      return completedSteps() === totalSteps();
    };
  
    const handleNext = () => {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? // It's the last step, but not all steps have been completed,
            // find the first step that has been completed
            steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleStep = (step) => () => {
      setActiveStep(step);
    };
  
    const handleComplete = () => {
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    };
    
    const isFirstScreen = activeStep === 0;
    const isSecondScreen = activeStep === 1;
    const isThirdScreen = activeStep === 2;

    const stepProps = {
        totalSteps, completedSteps, isLastStep, allStepsCompleted, handleNext, handleBack, handleStep, handleComplete,
         isFirstScreen, isSecondScreen, isThirdScreen, activeStep, completed, steps,
        setActiveStep, setCompleted
    }
    return {stepProps}

}

export const useAnteNatalPatientForm = (actions, patient_number, anc_id, anc_status, visit_id) => {

    // first get patient details
    const [status, setStatus] = useState('idle');
    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: "", blood_type:"", chronic_illness:''});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '');
    const [service_id, setServiceId] = useState(0);

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? {} : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;

    // hooks for the Ante Natal Steppers
    const {
        stepProps
    } = useAnteNatalSteppers();
    const { setActiveStep, handleNext, setCompleted} = stepProps;

    /** First Screen */
    const { first_screen_props, handleResetFirstScreen, assignFirstScreenProps, getFirstScreenValues } = useFirstScreenProps(actions);
    // should get the last ante natal visit

    /** Second Screen */
    const {second_screen_props, handleResetSecondScreen, assignSecondScreenProps, getSecondScreenValues} = useSecondScreenProps(patientDetails['blood_type'], visit_id, actions);
    const {
        woa_props, currentTriageData
    } = second_screen_props;

    /** Third screen hooks */
    const {third_screen_props, handleResetThirdScreen, assignThirdScreenProps, 
        getThirdScreenValues} = useThirdScreenProps( woa_props['woa_months'], 
        woa_props['woa_days'], actions, currentTriageData );

    useEffect(() => {
        const formData = new FormData();
            formData.append('service_type', 'Ante Natal Care');
            axios.post(`${config.smsUrl}/cvisit/retrieve_services_by_service_type`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? [] : details;
                    const arr = data[0] ? data[0] : {};
                    setServiceId(arr['service_id'] ? arr['service_id'] : 0);
                }).catch(err => {
                    logoutErrorMessage(err,null,actions);
                    setStatus('rejected')
            });
    }, []);

    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no,
            blood_type: patientDetails.blood_type,
            patient_number : patientDetails.patient_number,
            chronic_illness: patientDetails.chronic_illness
        });

        const formData2 = new FormData();
        formData2.append('visit_id', visit_id);
        const ax2 =  axios.post(`${config.smsUrl}/cdoctor/retrieve_exam_notes`, formData2);
        const check_status = JSON.parse(sessionStorage.getItem(`current_anc_status_${visit_id}`));

        // check if the user is continuing with a saved anc form
        if (check_status === null) {
            // check if it is really there
            sessionStorage.setItem(`current_anc_status_${visit_id}`, JSON.stringify({'anc_stat' : (anc_status === '1' ? 'continue' : `start`)}));
        }

        const chk = check_status === null;
        const isStartAnte = chk ? true : ((check_status['anc_stat'] === 'start')) ? true : false;

        // check if the user had saved the form, the first time
        const check_save = JSON.parse(sessionStorage.getItem(`isANCSave_${visit_id}`));

        if (check_save === null) {
            sessionStorage.setItem(`isANCSave_${visit_id}`, JSON.stringify({'anc_save' : '-1'}));
        } 

        const chk_save = check_save === null;
        const isStartSave = chk_save ? 'first_save' : (check_save['anc_save'] === '-1') ? 'first_save' :  'second_save';

        // get the most recent ANC visit
        if (anc_status) {

            if ((anc_status === '0' && isStartAnte) || (anc_status === '1' && (isStartSave === 'first_save'))) {
                const formData = new FormData();
                formData.append('id', anc_id);
                formData.append('patient_number', patient_number);
                formData.append('user_roles_id', user_roles_id);
                const ax1 = axios.post(`${config.smsUrl}/antenatal/start_antenatal`, formData);
                Promise.all([ax1, ax2]).then(([res, res2]) => {
                    const data = res.data;
                    const dt = !data ? {} : data;
                    const ante_data = dt['antenatal'] ?? {};
                    const grav_data = dt['gravida_details'] ?? [];
                    const exam_data = dt['exam_details'] ?? [];
                
                    // set the details for the first second and third screens
                    // then send them as props to the different hooks
                   
                    const dataB = {...ante_data, 
                        ...patientDetails, 
                        'isStartAnte':'start_ante_natal',
                        'gravida_info' : grav_data,
                        'antenatal_progress' : exam_data
                    };
                    // first screen
                    assignFirstScreenProps(dataB);

                    // second screen        
                    const data2 = res2.data;
                    const dt2 = !data2 ? [] : data2;
                    const not2 = dt2[dt2.length-1] ? dt2[dt2.length-1] : {};
                    const arr2 = not2.notes ? not2.notes : '';
                    const treatment = not2.treatment ? not2.treatment : '';
                    assignSecondScreenProps({...dataB,  ...patientDetails, 'exam_notes': arr2});

                    // third screen
                    assignThirdScreenProps({...dataB, treatment});
                    setStatus('success');
                    // check if it is really there
                    sessionStorage.setItem(`isANCSave_${visit_id}`, JSON.stringify({'anc_save' : '-1'}));
                }).catch(err => {
                    logoutErrorMessage(err,null,actions)
                    setStatus('rejected')
                })
            } else {
                // anc_status === 1
                const formData = new FormData();
                formData.append('id', anc_id);
                const ax1 =  axios.post(`${config.smsUrl}/antenatal/anc_update_form`, formData)
                Promise.all([ax1, ax2]).then(([res, res2]) => {
                    const data = res.data;
                    const dt = !data ? {} : data;
                
                    // set the details for the first second and third screens
                    // then send them as props to the different hooks                    
                    const dataB = {...dt, ...patientDetails, 'isStartAnte':''};
                    // first screen
                    assignFirstScreenProps(dataB);
        
                    // second screen
                    const data2 = res2.data;
                    const dt2 = !data2 ? [] : data2;
                    const not2 = dt2[dt2.length-1] ? dt2[dt2.length-1] : {};
                    const arr2 = not2.notes ? not2.notes : '';
                    const treatment = not2.treatment ? not2.treatment : '';
                    assignSecondScreenProps({...dataB,  ...patientDetails, 'exam_notes': arr2});

                    // third screen
                    assignThirdScreenProps({...dataB, treatment});
                    setStatus('success');
                    sessionStorage.setItem(`isANCSave_${visit_id}`, JSON.stringify({'anc_save' : '1'}));
   
                }).catch(err => {
                    logoutErrorMessage(err,null,actions)
                    setStatus('rejected')
                })
            }
        }

    }, [patientDetails]);

    const handleSaveForm = (event) => {
        const first_screen_values = getFirstScreenValues();
        const {first_patient_data, gravida_info, ...first_values} = first_screen_values;

        const second_screen_values = getSecondScreenValues();
        const {exam_notes, ...second_values} = second_screen_values;

        const third_screen_values = getThirdScreenValues();
        const {antenatal_progress, treatment_notes, ...third_values} = third_screen_values;

        const check_status = JSON.parse(sessionStorage.getItem(`isANCSave_${visit_id}`));

        if (check_status === null) {
            sessionStorage.setItem(`isANCSave_${visit_id}`, JSON.stringify({'anc_save' : '-1'}));
        } 

        const chk = check_status === null;
        const isStartAnte = chk ? 'first_save' : (check_status['anc_save'] === '-1') ? 'first_save' :  'second_save';

        if (isStartAnte === 'first_save') {

            const params  = {
                'id':anc_id,
                'visit_id':parseInt(visit_id),
                patient_number: patient['patient_number'],
                patient_data : {
                    'blood_group' : second_screen_values['blood_group'],
                    ...first_patient_data
                },
                exam_notes,
                treatment_notes,
                antenatal_data : {
                    ...first_values,
                    ...second_values,
                    ...third_values,
                    'seen_by': user_roles_id,
                    service_id,
                    gravida_info
                },
                antenatal_progress
            };
    
            axios.post(`${config.smsUrl}/antenatal/capture_antenatal_details`, params).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                sessionStorage.setItem(`current_anc_status_${visit_id}`, JSON.stringify({'anc_stat' : 'continue'}));
                sessionStorage.setItem(`isANCSave_${visit_id}`, JSON.stringify({'anc_save' : '1'}));
                handleNext();
                setStatus('success');
            }).catch(err => {
                logoutErrorMessage(err,null,actions)
                setStatus('rejected')
            })

        } 
        else {
            const formData2 = new FormData();
            formData2.append('visit_id', visit_id);
            const ax2 =  axios.post(`${config.smsUrl}/cdoctor/retrieve_exam_notes`, formData2);
            ax2.then(res => {
                const data = res.data;
                const dt = !data ? [] : data;
                const exam_id = dt.length > 0 ? dt[0]['examination_notes_id'] : '';
                const params2  = {
                    'visit_id':parseInt(visit_id),
                    
                    patient_number: patient['patient_number'],
                    patient_data : {
                        'blood_group' : second_screen_values['blood_group'],
                        ...first_patient_data
                    },

                    'exam_notes':{
                    'examination_notes_id': exam_id,
                    'notes': exam_notes
                    },
                    treatment_notes,
                    antenatal_data : {
                        'ante_natal_id' : anc_id,
                        ...first_values,
                        ...second_values,
                        ...third_values,
                        'seen_by': user_roles_id,
                        service_id,
                        gravida_info
                    },
                    antenatal_progress
                };
        
                axios.post(`${config.smsUrl}/antenatal/ante_natal_update`, params2).then(res => {
                    const data = res.data;
                    const dt = !data ? {} : data;
                    sessionStorage.setItem(`current_anc_status_${visit_id}`, JSON.stringify({'anc_stat' : 'continue'}));
                    handleNext();
                    setStatus('success');
                }).catch(err => {
                    logoutErrorMessage(err,null,actions)
                    setStatus('rejected')
                })
            }).catch(err => {
                logoutErrorMessage(err,null,actions)
                setStatus('rejected')
            })
        }
    }

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
        handleResetFirstScreen();
        handleResetSecondScreen();
        handleResetThirdScreen();
        setResetConfirmation(false);
    }

    const [resetConfirmation, setResetConfirmation] = useState(false);
    
    const openResetConfirmation = () => {
        setResetConfirmation(true);
    } 

    const handleCloseConfirm = () => {
        setResetConfirmation(false);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {
        stepProps, patient, first_screen_props, second_screen_props, third_screen_props, handleReset, 
        handleCloseConfirm, openResetConfirmation, resetConfirmation, handleSaveForm, closeSnackbar
    }
}
