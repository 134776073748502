import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { config } from "../../../Helpers/env";
import {randomColor} from "../../Utils/Charts/utilityFunctions";
import {useCommonState} from "./useReusableFunctions";
import { exportPDF } from "../../Utils/downloadPDF";
import {convertMonth} from "../../Utils/ConvertDate";
import {titleCase} from "../../Users/addUser";


export const useCommonReportMethods = (actions, url, type) => {
    const [hospital_test_id, setId] = useState('');
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [chips, setChips] = useState([]);
    const [statistics, setStatistics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [labTests, setList] = useState([]);
    const [allTests, setLabTests] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [total, setTotal] = useState(0)
    const csvRef = useRef();
    const {closeSnackbar, search_type,  text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear,
        searchValue, order, orderBy, page, rowsPerPage,setSearchType,inputType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage} = useCommonState(actions)
    const {start_date, end_date} = date;

    const calculateTotal = (arr) =>{
        const sum = arr.reduce((a,b)=>{
            return +a + b.tests_taken
        },0)
        setTotal(sum)
    }

    useEffect(() => {
        const url = type === 'lab' ? 'claboratory/lab_test_list': 'cradiology/get_all_scans';
        axios.get( `${config.smsUrl}/${url}`).then(res => {
            const data = res.data;
            let cats;
            if (type === 'lab') {
                const dt = !data ? {} : data;
                cats = !dt.lab_testlist ? [] : dt.lab_testlist;
            } else {
                cats = !data ? [] : data;
            }
            setLabTests(cats);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }, []);


    useEffect(() => {
        setLoading(true);
        axios.get(`${config.smsUrl}/creport/${url}`, {
            params: {
                start_date: start_date,
                end_date: end_date,
                option: 3,
                hospital_test_id: '',
                data: true
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setList(dt);
            let arr = [];
            if (type === 'radiology'){
                arr = dt.map(item=>({
                    name:item.hospital_scan_name,
                    tests_taken:item.scans_taken
                }))
            }else{
                arr = dt.map(item=>({
                    name:item.hospital_test_name,
                    tests_taken:item.tests_taken
                }))
            }
            calculateTotal(arr)
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            logoutErrorMessage(err, null, actions);
        })
    }, []);

    useEffect(() => {
        axios.get(`${config.smsUrl}/creport/${url}`, {
            params:{
                start_date: start_date,
                end_date: end_date,
                option: 3,
                hospital_test_id: hospital_test_id ? hospital_test_id.value : '',
                data: false
            }
        }).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            setStatistics(dt)
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })

    }, [])


    const parameters = (data, _id) => {
        const {start_date, end_date} = date;
        const s_date = search_type === '2' ? year : start_date;
        const current = s_date ? s_date : year
        const s_type = current === year ? '2' : search_type

        if (search_type === '3') {
            return {
                start_date: s_date,
                end_date: end_date,
                option: search_type,
                hospital_test_id: _id,
                data: data
            }
        } else {
            return {
                start_date:current ,
                option: s_type,
                hospital_test_id: _id,
                data: data
            }
        }

    }

    const handleChangeTest = (value) => {
        setId(value)
        const stat = [...statistics]
        const val = {...value, color: randomColor()}
        const existingIds = chips.map((id) => id.value)
        if (!existingIds.includes(val.value)) {
            axios.get(`${config.smsUrl}/creport/${url}`, {params:{
                start_date: start_date,
                end_date: end_date,
                option: 3,
                hospital_test_id:value.value,
                data: false
            }}).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                for (let i = 0; i < stat.length; i++) {
                    stat[i][`value_${value.value}`] = type === 'lab' ? dt[i].tests_taken : dt[i].scans_taken;
                }
                setStatistics(stat)
                setChips([...chips, val])
            }).catch(err => {
                errorMessages(err, null, actions);
            })

        }

    }

    const handleDeleteChip = (chipToDelete) => {
        setChips((chips) => chips.filter((chip) => `value_${chip.value}` !== chipToDelete.dataKey));
    };

    const handleRetrieveData = (val) => {
        const {start_date, end_date} = date;
        setSubmitted(true)
        if (start_date || (start_date && end_date) || year) {
            setIsSubmitted('pending')
            setChips([])
            axios.get(`${config.smsUrl}/creport/${url}`, {
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    option: 3,
                    hospital_test_id: '',
                    data: val
                }
            }).then(res => {
                const data = res.data;
                const dt = data ? data : [];
                if (val === false) {
                    setStatistics(dt)
                } else {
                    setList(dt)
                    let arr = [];
                    if (type === 'radiology'){
                        arr = dt.map(item=>({
                            name:item.hospital_scan_name,
                            tests_taken:item.scans_taken
                        }))
                    }else{
                        arr = dt.map(item=>({
                            name:item.hospital_test_name,
                            tests_taken:item.tests_taken
                        }))
                    }
                    calculateTotal(arr)
                }
                setIsSubmitted('resolved')
            }).catch(err => {
                setIsSubmitted('rejected')
                errorMessages(err, null, actions);
            })
        }
    }

 

    const handleSubmit = (event) => {
        event.preventDefault();
        handleRetrieveData(false);
        handleRetrieveData(true);
    }

    const downloadPdf = () => {
        const {end_date, start_date} = date
        const time = (search_type === '0' || search_type === '1') ? convertMonth(start_date) :
            search_type === '2' ? year : `${start_date} to ${end_date}`
        let arr = [];
        if (type === 'radiology'){
            arr = labTests.map(item=>({
                name:item.hospital_scan_name,
                tests_taken:item.scans_taken
            }))
        }else{
            arr = labTests.map(item=>({
                name:item.hospital_test_name,
                tests_taken:item.tests_taken
            }))
        }
        exportPDF(`${titleCase(type)} Report (${time})`, [["Test Name", "Total"]],
            arr.map((item) => [item.name,  item.tests_taken]),
            `${titleCase(type)} Report.pdf`,[['Total',total]]);

    }
    const downloadCsv = () => {
        csvRef.current.link.click()
    }



    return {hospital_test_id, handleChangeTest, closeSnackbar, search_type, handleSubmit, chips,
        handleDeleteChip, text, textType, year, date, handleChangeSearch,handleRetrieveData,
        handleChangeDate, handleChangeYear, handleIncrementYear, statistics, isSubmitted, submitted,
        searchValue, order, orderBy, loading, labTests, allTests, page, rowsPerPage,setSearchType,inputType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage, setChips, parameters,
        csvRef,downloadCsv, downloadPdf
    }
}