import React, {useState} from "react";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from '../Utils/smsTitle';
import { TableCell, TableBody, TableRow } from "@material-ui/core";
import CustomTable from "../Utils/Tables/CustomTable";
import { useCurrentSurgeries } from './hooks/useCurrentSurgeries';
import { dateStringConvert } from "../Utils/ConvertDate";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import {SubHeader} from "../../Containers/SubHeader";
import { DDOFilterComponent } from './DDOFilterComponent';
import { RescheduleSurgery } from "./RescheduleSurgery";
import { DoctorsDetails } from "./DoctorsDetails";
import ReusableTabs, { TabPanel } from "../Utils/Dialogs/ReusableTabs";
import { RightAlignedContainer } from "../Utils/styledComponents";
import Triage from "../Triage/Triage";
import { Link } from "react-router-dom";
import { DoctorAdmissionForm } from "../DoctorsModule/Admission/DoctorAdmission";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: '22px',
        overflow:'initial',
        padding:'20px',
        height: 'inherit'
    }
}));

const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Operation Date'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    // department of where the surgery is is retrieved from the type of surgery
    {id: 'department', numeric: false, disablePadding: false, label: 'Department'},
    // add a triage color code -> can filter by color code, for now used Operation Class
    {id: 'operation_class', numeric: false, disablePadding: false, label: 'Operation Class'},
    {id: 'surgeryPerformed', numeric:false, disablePadding:false, label: 'Surgery performed'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

const CurrentSurgeries = ({ actions, snackbar, surgeryVisit=false, surgeriesByStatus=0}) => {
        
    const {
        isPending,searchValue, activeTablePage, isError, isLoading, isSuccess, setIsAdmitted,
        handleSearch, handleChangeNewPage, surgeryList, handleOpenDialog, ids, handleOpenAdmission,
        openDialog, handleCloseDialog, handleSubmitTriage, triageProps,
        surgeryStatus, setSurgeryStatus, cancelDialog, handleCancelSurgery, handleCloseCancel, handleOpenCancel, 
        submitted, handleStartSurgery, currentstate, handleSubmitFilter, handleFilterChange, submittedDDOFilter,
        surgery_time,  onChangeSurgeryTime, handleRescheduleSurgeryScreen, order, orderBy, admissionProps,
        general_notes, handleNotesState, anaesthesia_type, anaesthesiaTypes, handleAnesTypeChange,
        handleUpdateSurgeryScreen, surgeon,surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon,
        other_anaestheists, other_surgeons, isVisitingDoctor, inputDoc, handleRequestSort,  departments,
        handleOtherSurgeons, handleOtherAnaestheists, handleChangeDoctors, handleChangeInputSurgeons,
    } = useCurrentSurgeries(actions, surgeriesByStatus);

    const { start_date, end_date, filter_by, department, filter_operation_class }  = currentstate;

    const {surgeries, total_count} = surgeryList;

    const {openBar, type, message} = snackbar;
    
    const {screen, patient_number, patient_name,  age, gender} = ids;

    const isPreview = screen === 'preview';
    const isUpdatePending = screen === 'updateP';
    const isStartSurgery = screen === 'startS';
    const isRecordDetails = screen === 'record';
    const isAdmitPatient = screen === 'admitP';

    const title = isPreview ? 'Preview Surgery Report' : 
    isUpdatePending ? 'Update existing surgery details' : 
    isStartSurgery ? 'Start the Surgery' :
    isRecordDetails ? 'Record Surgery Details' :
    isAdmitPatient ? 'Patient Admission Form' :
    null;

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const tabs = ['Reschedule','Edit Surgery Details'] 
    const [tabValue, setTabValue] = useState(0);
    
    const handleChangeTabValue = (event,val) => {
        setTabValue(val);
    }

    //const switchToTab = (tabVal) => {
    //    setTabValue(tabVal);
    //}
  
    //const start_surgery_tabs = ['Take Triage', 'Surgery Details'];
    const [goToDoctorsDetails, setGoToDoctorsDetails] = useState(false);
    const [takeTriage, setTakeTriage] = useState(!goToDoctorsDetails);

    //const constOpenDialog = (event) => {
    //    setStartDialog(false)
    //}

    //const theme = useTheme();
    //const them = !theme ? {} : theme;
    //const palette = !them.palette ? {} : them.palette;

    const isFilterByDate = filter_by === '1';
    const isFilterByDepartment = filter_by === '2';
    const isFilterByOperationClass = filter_by === '3';

    const {triageList, triage, setSubmitted:setSubmitTriage} = triageProps;

    const arr1 = triageList.filter(metric=>metric.metric_id !== 6).some(metric=>metric.metric_value);
    const arr2 = triage.some(metric => metric.metric_value);
    const IskipTriage = (arr1 && arr2);

    const handleStartSurgeryScreen = (val) => {
        setTakeTriage(val);
        setSubmitTriage(false);
        setGoToDoctorsDetails(!val);
    }

    const handleSubmitChangeTriage = (event) => {
        handleSubmitTriage(event);
        //if (IskipTriage) {
            handleStartSurgeryScreen(false);
        //}
    }

    const list_title = surgeryStatus === 0 ? "Pending Surgeries" : "Ongoing Surgeries";
    const classes = useStyles();

    const {handleCloseAdmission, openAdmission} = admissionProps;

    return (
        <div>
            {surgeryVisit ?
            <>
                <PageTitle title="Pending Surgeries"/>
            </>                
            :
            <>
                <PageTitle title="Current Surgeries"/>
            </>
            }


            {!surgeryVisit ? 
                <SubHeader title="Surgery" subTitle="Current Surgeries" >
                    <img src="/images/surgery-21.png" alt="surgery" className="icon-resize surgery-icon-tabs"/>
                </SubHeader>
            : null}


            {cancelDialog ? 
                <PrintDialog handleCancel={handleCloseCancel} openDialog={cancelDialog} handleClose={handleCloseCancel}
                handleClick={() => handleCancelSurgery(ids)} message="Cancel Surgery"
                text="Would you like to cancel this scheduled surgery?"/>
            : null}

            {isAdmitPatient ?  
                <CustomDialog title={title} open={openAdmission} handleClose={handleCloseAdmission} maxWidth="md" isContentOverflow={false}>
                    <DoctorAdmissionForm admissionProps={admissionProps}/>
                </CustomDialog>
                : null
            }

            {isUpdatePending ?  
            <CustomDialog title={title} open={openDialog} handleClose={handleCloseDialog} maxWidth="md" isContentOverflow={false}>
                <ReusableTabs value={tabValue} tabHeading={tabs} handleChange={handleChangeTabValue}>
                    
                    <TabPanel value={tabValue} index={0}>
                        <RescheduleSurgery {...{patient_number, surgery_time, onChangeSurgeryTime, submitted, patient_name, handleRescheduleSurgeryScreen}}/>
                    </TabPanel>
                    
                    <TabPanel value={tabValue} index={1}>
                       <DoctorsDetails
                            visitModule={surgeryVisit}
                            pendingSurgeryModule={!surgeryVisit}
                            other_anaestheists={other_anaestheists} other_surgeons ={other_surgeons}
                            anaesthesia_type={anaesthesia_type} anaesthesiaTypes={anaesthesiaTypes} handleAnesTypeChange={handleAnesTypeChange}
                            general_notes={general_notes} handleNotesState={handleNotesState}
                            handleChange={handleChangeInputSurgeons}
                            {...{surgeon, surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon, handleOtherAnaestheists, handleOtherSurgeons, handleChangeDoctors, isVisitingDoctor, inputDoc}}
                       />
                        <RightAlignedContainer>
                            <button type="submit" className="btn btn-sm sms-btn" onClick={handleUpdateSurgeryScreen}>Save</button>
                        </RightAlignedContainer> 
                    </TabPanel>
                
                </ReusableTabs>
            </CustomDialog>
                : null
            }

            {isStartSurgery ? 
                <>
                    {takeTriage ? 
                    <CustomDialog title={title} open={openDialog} handleClose={handleCloseDialog} maxWidth="md" isPaperStyle={true}>
                        <form onSubmit={handleSubmitChangeTriage}>
                            <Triage {...{...triageProps, patient_number,patient:{patient_name,age, gender}}}/>
                            <button type="submit"  disabled={isPending} className="btn btn-sm sms-btn">{isPending ? 'Saving....' : 'Save Triage'}</button>
                            {!IskipTriage ? 
                            <button type="button" onClick={()=>handleStartSurgeryScreen(false)} disabled={isPending} className="btn btn-sm sms-hl-btn" style={{marginLeft:'5px'}}>{isPending ? 'Saving....' : 'Skip Triage'}</button>
                            : null}
                        </form>
                    </CustomDialog>
                        :
                        <CustomDialog title={title} open={openDialog} handleClose={handleCloseDialog} maxWidth="md" isContentOverflow={false}>
                        <div>
                            <DoctorsDetails 
                                startSurgery={true}
                                other_anaestheists={other_anaestheists} other_surgeons ={other_surgeons}
                                anaesthesia_type={anaesthesia_type} anaesthesiaTypes={anaesthesiaTypes} handleAnesTypeChange={handleAnesTypeChange}
                                general_notes={general_notes} handleNotesState={handleNotesState}
                                handleChange={handleChangeInputSurgeons}
                                {...{surgeon, surgeons, isOpen, handleChangeSurgeon, handleSelectSurgeon, handleOtherAnaestheists, handleOtherSurgeons, handleChangeDoctors, isVisitingDoctor, inputDoc}}
                            />
                            <RightAlignedContainer>
                            <button type="submit" className="btn btn-sm sms-btn" onClick={()=>handleStartSurgeryScreen(true)}>Go back</button>
                            <button type="submit" className="btn btn-sm sms-hl-btn" style={{marginLeft:'5px'}} onClick={handleStartSurgery}>Start Surgery</button>
                            </RightAlignedContainer>
                        </div>                    
                        </CustomDialog>
                    }
                </>
            : null}     

            {!surgeryVisit ? 
                <div className='general-ledger-header inpatient-billing-header'>
                    <a className={`billing-span btn btn-sm ${surgeryStatus === 1 ? 'active-billing-span':''}`} onClick={()=>setSurgeryStatus(1)}>Ongoing Surgeries</a>
                    <a className={`billing-span btn btn-sm ${surgeryStatus === 0 ? 'active-billing-span':''}`} onClick={()=>setSurgeryStatus(0)}>Pending Surgeries</a>
                </div>
            : null}

            <div className='mui-tables'>
                <MainSnackbar handleCloseBar={closeSnackBar} message={message} open={openBar} variant={type}/>

                <DDOFilterComponent
                    isFilterByDate={isFilterByDate} filter_by={filter_by} 
                    start_date={start_date} end_date={end_date}
                    submitted={submittedDDOFilter} handleChange={handleFilterChange} handleSubmit={handleSubmitFilter} 
                    isPending={isPending} department={department} isFilterByDepartment={isFilterByDepartment} isFilterByOperationClass={isFilterByOperationClass}
                    filter_operation_class={filter_operation_class} departments={departments}
                />

                <CustomTable headData={headData} handler={handleSearch} term={searchValue} title={list_title} filter={true} searchPlaceHolder={"Search by Patient Name"}
                            data={surgeries} colSpan={headData.length} customPage records={10} activePage={activeTablePage}
                            handleChangeNextPage={handleChangeNewPage} total_count={total_count} handleRequestSort={handleRequestSort} 
                            order={order} orderBy={orderBy}>
                    
                        <TableBody>

                            {isLoading ? <TableRow></TableRow> : null}

                            {
                                isSuccess ?  
                                //surgeries.length > 0 ?
                                surgeries.filter(item => {
                                    return item.status === surgeryStatus
                                }).length > 0 ?

                                surgeries.filter(item => {
                                    return item.status === surgeryStatus
                                }).map((item,idx) => {

                                    return (
                                        /** For each row in the table*/

                                        <TableRow hover 
                                        role ="checkbox"
                                        key={item.visit_id}
                                        tabIndex={-1}
                                        className="mui-table-row">

                                            <TableCell>{((activeTablePage - 1) * 10) + idx + 1}</TableCell>

                                            <TableCell>{dateStringConvert(item.operation_date)}</TableCell>

                                            <TableCell>{item.patient_number}</TableCell>

                                            <TableCell>{item.patient_name}</TableCell>

                                            <TableCell>
                                                {
                                                    item.status === 0 ? <span className="text-pending">Pending</span> :
                                                    item.status === 1 ? <span className='text-success'>Ongoing</span> :
                                                    null
                                                }
                                            </TableCell>

                                            <TableCell>{item.surgery_department}</TableCell>

                                            <TableCell>{item.operation_class}</TableCell>

                                            <TableCell>{item.surgery_name}</TableCell>

                                            <TableCell>
                                                {item.status === 0 && !surgeryVisit ? <button onClick={()=>handleOpenDialog(item, 'startS', surgeryVisit)} type="submit"
                                                        className="btn btn-sm sms-hl-btn mr-1" style={{marginBottom:'4px'}}>Start</button>:null}

                                                {item.status === 0 ? <button onClick={()=>handleOpenDialog(item, 'updateP', surgeryVisit)}
                                                        className="btn btn-sm sms-info-btn mr-1" style={{marginBottom:'4px'}}>Update</button>:null}

                                            {item.patient_type === 1? 
                                            <>
                                            {item.status === 0 && item.patient_type === 1 ? <button onClick={()=>handleOpenAdmission(item, 'admitP', surgeryVisit)}
                                                        className="btn btn-sm sms-btn mr-1" style={{marginBottom:'4px'}}>Admit</button>:null}
                                            </>
                                            :null}
                                                
                                                {item.status === 1 ?
                                                    <Link to={{pathname:`/completesurgerydetails/${item.patient_number}/${item.patient_type}/${item.visit_id}/${item.surgery_id}`}}>
                                                    <button className="btn btn-sm sms-info-btn mr-1">Record Details</button>
                                                    </Link> :null}

                                                {item.status === 0 ? <button onClick={()=>handleOpenCancel(item)}  type="submit" style={{marginBottom:'4px'}}
                                                        className="btn btn-sm sms-gray-btn mr-1">Cancel</button>:null}

                                            </TableCell>
                                        </TableRow>

                                    )
                                })

                                : // else if false no surgeries are present
                                <TableRow>
                                    <TableCell colSpan={headData.length} align="center">
                                        No records found
                                    </TableCell>
                                </TableRow> 
                                : null
                            }
                           
                            {/** Error handling */}
                            {isError ? 
                            <TableRow>
                                <TableCell colSpan={headData.length} align="center">
                                    The server did not return a successful response
                                </TableCell>
                            </TableRow>
                            : null}

                        </TableBody>

                </CustomTable>

            </div>

        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CurrentSurgeries);
