import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableCell:{
    border:'1px solid rgb(90 88 88)',
    padding:'8px'
  }
});

const BorderedCell = ({children, ...props}) =>{
  const classes = useStyles();
  return (
    <TableCell {...props} className={classes.tableCell}>{children}</TableCell>
  )
}

const BoldBorderedCell = ({children, ...props}) =>{
  const classes = useStyles();
  return (
    <TableCell {...props} className={classes.tableCell}><strong>{children}</strong></TableCell>
  )
}

const repeat = (item, times) => {
  return new Array(times).fill(item)
}

function ReportsTable({headTitle,children}) {
  const classes = useStyles();
  const ageGroups = [{age:'0 - 28days'},{age:'29days - 4Yrs'},{age:'5 - 9Yrs'},
  {age:'10 - 19Yrs'},{age:'20Yrs & Above'}]
  const genders = repeat({male:'Male',female:'Female'},5)
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <BorderedCell rowSpan="2">
                <strong>{headTitle}</strong>
            </BorderedCell>
            {ageGroups.map((item, index)=>(
              <BorderedCell key={index} align="center" colSpan="2"><strong>{item.age}</strong></BorderedCell>
            ))}
            <BorderedCell/>
          </TableRow>
          <TableRow>
            {genders.map((item, index)=>(<>
            <BorderedCell><strong>{item.male}</strong></BorderedCell>
            <BorderedCell><strong>{item.female}</strong></BorderedCell>
            </>))}
            <BorderedCell><strong>Total</strong></BorderedCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export {ReportsTable, BorderedCell,BoldBorderedCell}
