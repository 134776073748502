import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {useFileUploader} from "../../PatientRecords/CustomHooks/useFileUploader";
import { useAddConsumables } from "./useConsumablesList";

export const useServiceList = (props) =>{
    const {actions} = props;
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [service_id, setServiceID] = useState('');
    const [status, setStatus] = useState('')
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const obj = {url:'cbilling/uploadCsv_services', download_url:'cbilling/service_csv', file_name:'Services'}
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions,obj );

    const {
        serviceConsumablesProps, handleAddConsumables, handleOpenNewItems, handleCloseNewItems, openNewItems, isAddedConsumablesResolved
    } = useAddConsumables(actions);
    
    const service_status = parseInt(props.match.params['service_status']);

    useEffect(() => {
        // SET THE SERVICE STATUS
        setLoading(true);
        axios.get(`${config.smsUrl}/cbilling/manage_service/${service_status}`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.service_list ? [] : dt.service_list;
            setServices(arr);
            setLoading(false);
        }).catch(err => {
         logoutErrorMessage(err,null,actions)
            setLoading(false)
        })
    }, [status, service_id, isResolvedUpload, service_status, isAddedConsumablesResolved]);

    const handleDelete = (s_id, st) => {
        const formData = new FormData();
        formData.append('service_id', s_id);
        formData.append('status', st);
        axios.post(`${config.smsUrl}/cbilling/delete_service`, formData).then(() => {
            actions.snackbarActions.snackSuccess(`Service ${st=== 1 ? 'deactivated':'activated'} successfully`);
            setStatus( st)
            setServiceID(s_id)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };

    const handleSearch = (searchValue) => {
        setSearchValue(searchValue)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {
        searchValue,  page, rowsPerPage, service_id, services, loading, handleDelete, uploadProps,
        handleSearch, handleChangePage, handleChangeRowsPerPage, closeSnackbar, setServices, setServiceID,
        serviceConsumablesProps, handleAddConsumables, handleOpenNewItems, handleCloseNewItems, openNewItems,
    }
}