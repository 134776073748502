import { useState, useEffect } from "react";
import axios from 'axios'
import { config } from '../../../Helpers/env'
import {logoutErrorMessage, errorMessages} from '../../../Helpers/ErrorMessages';
import { history } from "../../../Helpers/history";
import { useRegisterPatient } from "./useRegisterPatient";
import {useFileUploader} from "./useFileUploader";

function usePatientList(actions) {
    const [data, setData] = useState({loading:'idle', patients:[], total_count:0});
    const [activePage, setActivePage] = useState(1)
    const [patient_id, setPatientId] = useState(null)
    const [term, setTerm] = useState('')
    const [openDelete, setOpenDelete] = useState(false)
    const [openMenu, setOpenMenu] = useState(null);
    const [isRegister, setIsRegister] = useState(false);
    const {patientProps,openDialog,
         handleOpenDialog, handleCloseDialog, isResolved} = useRegisterPatient(actions);

    const obj = {url:'cpatient/uploadCsv_patients', download_url:'cpatient/patient_csv', file_name:'Patients'}
    const {uploadProps, isResolved:isResolvedUpload} = useFileUploader(actions, obj)

    const {setState, setContact, setKinContact, setBloodType, setNumber,
        setPatient, setPatientNumber, setKinRelation, setPatientRelation} = patientProps;

    useEffect(() => {
        const formData = new FormData()
        formData.append('search', term);
        setData({...data,loading:'loading'})
        axios.post(`${config.smsUrl}/cpatient/patient_list/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.patient_list ? [] : dt.patient_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setData({loading:'success', patients:arr, total_count:count})
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
            setData({...data,loading:'error'})
        })

    }, [term, activePage, isResolved, isResolvedUpload])


    const handleOpenDelete = () => {
        setOpenDelete(true)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDelete = () => {
        const formData = new FormData();
        formData.append("patient_number", patient_id);
        axios.post(`${config.smsUrl}/cpatient/delete_patient`, formData).then(() => {
            actions.snackbarActions.snackSuccess("Patient deleted successfully")
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
        this.setState({openDelete: false});
    };

    const handler = (event) => {
        setTerm(event.target.value)
    }


    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };

    const handleOpenSimpleMenu = (event, id) => {
        setOpenMenu(event.currentTarget)
        setPatientId(id)
    
    };
    const handleCloseSimpleMenu = () => {
        setOpenMenu(null);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleClick = (patient_id) => {
        history.push(`/patientProfile/${patient_id}`)
    };

    const handleCloseSimpleOpenDialog = (p_id) => {
        setOpenMenu(null);
        setIsRegister(false);
        handleOpenDialog(p_id);
    }

    const handleRegisterPatientButton = () => {
        handleOpenDialog('');
        setIsRegister(true);
        setState({
            first_name: '', last_name: '', gender: '', dob: '', email: '', id_nin: '',
            address: '', first_name1: '', last_name1: '', address1: '', relationship: '', age:'',
            patient_number :''
        });
        setContact();
        setKinContact();
        setBloodType(null);
        setPatientNumber('');
        setPatient({});
        setKinRelation('');
        setNumber('')
        setPatientRelation(null)

    }
    
    return{data,activePage,patient_id,term,openDelete,openMenu,handleOpenDelete,handleCloseDelete,
        handleDelete,handler,handleChangeActivePage,handleOpenSimpleMenu,handleCloseSimpleMenu,closeSnackbar,
        handleClick,patientProps,openDialog, handleCloseSimpleOpenDialog,uploadProps,
        handleOpenDialog, handleCloseDialog, isRegister, handleRegisterPatientButton
    }
}
export {usePatientList}