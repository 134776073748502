import React, { useState} from "react";
import { dateConvert } from "../../Utils/ConvertDate";



 const useCommonState = (actions) => {
    const current_year = new Date().getFullYear();
    const [search_type, setSearchType] = useState("2");
    const [year, setYear] = useState(current_year)
    const [date, setDate] = useState({start_date: dateConvert(), end_date: dateConvert()});
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1)
    const [customSearch, setCustomSearch] = useState('');
    const inputType = {
        '0':'week',
        '1':'month',
        '3':'date'
    }

    const handleCustomSearch = (event) => {
        setCustomSearch(event.target.value)
    };

    const handleChangeActivePage = (page) => {
        setActivePage(page)
    }

    const handleChangeSearch = (event) => {
        const {value} = event.target;
        setSearchType(value);
        setDate({start_date: '',end_date: ''})
        if (value === '2'){
            setYear(current_year)
        }else{
            setYear('')
        }
    }

    const handleChangeDate = (event) => {
        const {value, name} = event.target;
        setDate({...date, [name]: value})
    }

    const handleChangeYear = (event) => {
        const {value} = event.target;
        setYear(value)
    }

    const handleIncrementYear = (count) => {
        setYear(count)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSearch = (value) => {
        setSearchValue(value)
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {closeSnackbar, search_type,   year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear,
        searchValue, order, orderBy, page, rowsPerPage,activePage, customSearch,
        handleCustomSearch, handleChangeActivePage,setSearchType,inputType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage}
}
export {useCommonState};