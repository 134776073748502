/** @jsxImportSource @emotion/react */
import {jsx} from '@emotion/react'
import React from 'react'
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import {Container, ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents"
import {BootStrapTable} from "../../Utils/Tables/BootStrapTable"
import {SubHeader} from "../../../Containers/SubHeader";
import PageTitle from "../../Utils/smsTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import {useCreateInvoice} from "./hooks/useCreateInvoice";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea"
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader"
import Label from "../../Utils/FormInputs/Label"
import SelectInput from '../../Utils/FormInputs/SelectInput';
import CustomDialog from '../../Utils/Dialogs/CustomDialog';
import { NewProvider } from '../customers/NewProvider';
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";





function CreateInvoice({match:{params}, actions, snackbars}) {
    const  { state, billState,openPrint, total_amount,  submitted,procedureData,  providers , isPending, handleCancel,
        handleDeleteProcedure,handleSubmitBill, handleCloseReceipt,closeSnackbar,products,handleOpenReceipt,
        handleChangeRate,handleChange,handleDeleteProduct,  handleChangeProductRate,
        providerProps, openProvider, handleOpenDialog, handleCloseDialog, isExcluded, handleAddProduct,
        handleAddService, addProduct, addService, handleCloseAddService,
        handleCloseAddProduct,renderService, renderPrescription,
         isBlocking
    } = useCreateInvoice(actions, params)
    const {patient_number, patient_name, address} = state
    const {invoice_date,  memo, insurance_provider, account_name, card_number,policy_holder} = billState
    const {openBar, type, message} = snackbars;

    // const isBase = currency.split('-')[1]
    // const currency_symbol = currency.split('-')[2]
    // const isNotBase = isBase === 'false'

    const headData = [{name:'Item', isRequired:true}, {name:'Quantity', isRequired:true},
        {name:'Rate'}, {name:'Amount'}, {name:'Action'}];


    const productHeadData = [{name:'Drug Name',isRequired:false}, {name:'Dosage',isRequired:false},{name:'Frequency', isRequired:false}, 
    {name:'No. of Days', isRequired:false}, {name:'Prescribed Quantity', isRequired:false},
    {name:'Rate', isRequired:false}, {name:'Amount', isRequired:false},{name:'Action', isRequired:false}]


    const provider = insurance_provider.split('-')[1];
    const isInsurance = provider === 'Insurance Company'
    const text = !isExcluded ? 'This action is irreversible, would you like to continue?' : 'Would you like to generate this invoice'


  return (
    <div className='journals'>
        <PageTitle title='Create Invoice'/>
        <SubHeader title="Billing" subTitle='Create invoice'>
            <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        <BlockNavigationComponent
            when={isBlocking}
            shouldBlockNavigation={()=>{
                return isBlocking;
            }}
            navigate={path=>history.push(path)}
        />
        <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
        <PrintDialog handleCancel={handleCancel} openDialog={openPrint} handleClose={handleCloseReceipt}
                             handleClick={handleSubmitBill} message={!isExcluded ? "Cancel Invoice" : "Generate Invoice"}
                             text={text}/>
        <CustomDialog open={openProvider} handleClose={handleCloseDialog} title="New Provider">
                <NewProvider {...providerProps}/>
        </CustomDialog>

        <CustomDialog open={addService} title="Add Service" handleClose={handleCloseAddService} maxWidth="md"  isContentOverflow={true} >
            {renderService}
                </CustomDialog>

                <CustomDialog open={addProduct} title="Add Product" handleClose={handleCloseAddProduct} maxWidth="lg"  isContentOverflow={false}>
                    {renderPrescription}
                </CustomDialog>
 
        <Container>
        <BackButton to='/paymentList' text='Billing List' data-testid="create-invoice-back-button"/>
        <RightAlignedContainer>
            <button onClick={handleOpenDialog} className="btn sms-btn"><FontAwesomeIcon icon={faPlus}/> Quick Add Provider</button>
        </RightAlignedContainer>
        <MiniHeader title='Create Invoice'/>
  
        <form css={{marginTop:'1.5rem'}} autoComplete="off" onSubmit={handleOpenReceipt}>
            <div className='row'>
                <div className='col-lg-6 col-md-6 pl-0'>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Patient No." htmlFor='patient_no'/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField type="text" value={patient_number} id='patient_no' readOnly/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Patient Name" htmlFor='patient_name'/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField type="text" value={patient_name} id='patient_name' readOnly/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Address" htmlFor='address'/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <Textarea value={address} id='address' readOnly/>
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Invoice For" htmlFor='insurance_provider'  type/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <SelectInput submitted={submitted} value={insurance_provider} name="insurance_provider" onChange={handleChange} options={providers}
                                         defaultOption="Select..." id='insurance_provider'/>
                        </Col>
                    </Form.Group>
                    {/*<Form.Group as={Row}>*/}
                    {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                    {/*        <Label name="Currency"  type/>*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                    {/*        <SelectInput submitted={submitted} value={currency}*/}
                    {/*                     onChange={handleChangeCurrency} options={currencies}*/}
                    {/*                     defaultOption="Select..."/>*/}

                    {/*    </Col>*/}
                    {/*</Form.Group>*/}
                    {/*{isNotBase ?*/}
                    {/*    <Form.Group as={Row} >*/}
                    {/*        <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                    {/*            <Label  name="Exchange Rate"  type/>*/}
                    {/*        </Col>*/}
                    {/*        <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                    {/*            <InputGroup className="">*/}
                    {/*                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                    {/*                <TextField submitted={submitted} type="number" value={exchangeRate} onChange={handleChangeExchangeRate}/>*/}
                    {/*            </InputGroup>*/}
                    {/*            <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {currency.split('-')[2]} = {exchangeRate} USD)</span>*/}
                    {/*        </Col>*/}
                    {/*    </Form.Group> : null}*/}
                 
                </div>
                <div className='col-lg-6 col-md-6 pl-0'>
                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Invoice Date" htmlFor='invoice_date'  type/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField submitted={submitted} type="date" id='invoice_date' value={invoice_date} name="invoice_date" onChange={handleChange}/>
                            {submitted && !invoice_date ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Memo" htmlFor='memo'/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <Textarea value={memo} name="memo" id='memo' onChange={handleChange}/>
                        </Col>
                    </Form.Group>
                    <div  style={{display: !isExcluded ? 'none' : 'block'}}>
                        {isInsurance ?
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <Label name="Policy Holder" htmlFor='policy_holder'  type/>
                            </Col>
                            <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                <TextField submitted={submitted} value={policy_holder} name="policy_holder" id='policy-holder' onChange={handleChange}/>
                                {submitted && !policy_holder ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                            </Col>
                        </Form.Group>:
                        insurance_provider ? <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <Label name="Account Name" htmlFor='account_name'  type/>
                            </Col>
                            <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                <TextField submitted={submitted} value={account_name} id='account_name' name="account_name" onChange={handleChange}/>
                                {submitted && !account_name ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                            </Col>
                        </Form.Group>:null}

                        {isInsurance ?
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                <Label name="Card Number" htmlFor='card_number' type/>
                            </Col>
                            <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                <TextField submitted={submitted} type='text' value={card_number} name="card_number" id='card_number' onChange={handleChange}/>
                                {submitted && !card_number ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                            </Col>

                        </Form.Group>
                        : null }
                    </div>

                    {/*{isNotBase ?*/}
                    {/*    <Form.Group as={Row}>*/}
                    {/*         <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                    {/*            <Label name={`Amount Due(${currency_symbol})`}/>*/}
                    {/*         </Col>*/}

                    {/*        <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                    {/*            <TextField type="text" value={currencyTotal} readOnly/>*/}
                    {/*        </Col>*/}
                    {/*    </Form.Group>: null}*/}

                    <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Label name="Amount Due" htmlfor='amount_due'/>
                        </Col>
                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                            <TextField value={total_amount} id="amount_due" readOnly/>
                        </Col>
                    </Form.Group>

                </div>
            </div>
            <div>

            </div>
            {procedureData.length > 0 ? <BootStrapTable className="strike-table" headData={headData.filter(item=>Boolean(item))} heading={<tr><td colSpan={headData.length} align="center"><strong>Service(s)</strong></td></tr>}>
                <tbody>
                    {procedureData.map((item)=>(
                        <tr key={item.service_id} >
                            <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>{item.service_name}</td>
                            <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>{item.quantity}</td>
                            {/*{ isNotBase ?  <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>*/}
                            {/*    <TextField   value={item.currency_rate} onChange={e => handleChangeCurrencyRate(e, item.bill_id)}*/}
                            {/*                 type='number'/>*/}
                            {/*</td>: null}*/}
                            <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>
                                <TextField value={item.rate} onChange={e=>handleChangeRate(e, item.bill_id)} /></td>
                            {/*{isNotBase ?  <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}} >*/}
                            {/*    {item.currency_total}*/}
                            {/*</td>: null}*/}
                            <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}><strong>{item.subTotal}</strong></td>
                            <td style={{verticalAlign:'middle'}}>
                                <button type="button"
                                        className={`${item.isExcluded ? ' sms-info-btn ' : 'sms-btn-dismiss'} btn btn-sm`}
                                        id="delete_service"
                                        onClick={() => handleDeleteProcedure(item)}>
                                    {item.isExcluded ? 'Include' : 'Exclude'}
                                </button>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </BootStrapTable>: null}
            {products.length > 0 ? 
            <BootStrapTable className="strike-table" headData={productHeadData.filter(item=>Boolean(item))} heading={<tr><td colSpan={productHeadData.length} align="center"><strong>Product(s)</strong></td></tr>}>
                <tbody>
                {products.map((item) => (
                    <tr key={item.product_id} >
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>
                            {item.product_name}
                        </td>
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>
                            {item.dosage}
                        </td>

                        <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>
                            {item.frequency}
                        </td>
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>
                            {item.number_of_days}
                        </td>
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>
                            {item.quantity}
                        </td>
                        {/*{isNotBase ? <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}} >*/}
                        {/*    <TextField value={item.currency_rate} onChange={e => handleChangeProductCurrencyRate(e, item.bill_id)}*/}
                        {/*               type='number'/></td>: null}*/}
                        <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>
                            <TextField value={item.rate}  onChange={e => handleChangeProductRate(e, item.bill_id)}
                                    type='number'/>
                        </td>

                        {/*{isNotBase ? <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>*/}
                        {/*    {item.currency_total}*/}
                        {/*</td>: null}*/}

                        <td className={item.isExcluded ? 'strike-out' : ''} style={{verticalAlign:'middle'}}>
                            {item.subTotal}
                        </td>
                        <td style={{verticalAlign:'middle'}}>
                            <button type="button"
                                    className={`${item.isExcluded ? ' sms-info-btn ' : 'sms-btn-dismiss'} btn btn-sm`}
                                    id="delete_service"
                                    onClick={() => handleDeleteProduct(item)}>
                                {item.isExcluded ? 'Include' : 'Exclude'}
                            </button>
                        </td>
                    </tr>))}
                </tbody>
            </BootStrapTable>
            : null}
            <div className="mt-3">
             <button type="button" onClick={handleAddProduct} className="btn btn-sm btn-primary mr-3" disabled={isPending}>Add Product</button>
            <button type="button" onClick={handleAddService} className="btn btn-sm btn-secondary mr-3" disabled={isPending}>Add Service</button>
           
            <button type="submit" className="btn btn-sm sms-info-btn" disabled={isPending}>{isPending ? 'Submitting...': 'Save Invoice'}</button>
            </div>
        </form>
        </Container>
    </div>
  )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInvoice);
