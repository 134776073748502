import React from 'react';
import RevenueGenerated from "./RevenueGenerated";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import { BackButton } from '../../Utils/Buttons/DataExportationButton';


const  RevenuePerDepartment = () =>{
    const headData = [
        {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
        {id: 'name', numeric: false, disablePadding: false, label: 'Department'},
        {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
    ];
    const components = [
    {label: 'Sales by Service', path: '/reports/revenuebyprocedure'},
    {label: 'Sales by Product', path: '/accounting/productsales'},
]
    return (
        <RevenueGenerated headData={headData} title='Sales by Department' component='department'
                          urls={{revenue_url:'departmental_revenue',data_url:'revenue_in_time_period'}}>
            <BackButton to='/reports/generalreports' text='General Reports'/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>               
        </RevenueGenerated>
    )
}
export default RevenuePerDepartment;