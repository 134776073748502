/** @jsxImportSource @emotion/react */
import {jsx} from '@emotion/react'
import styled from '@emotion/styled/macro'
import * as colors from '../../styles/colors'
import * as mq from "../../styles/media-queries"
import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form';
import {Link} from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';



const buttonVariants = {
    primary: {
      background: colors.blue001,
      color: colors.base,
     
    },
    secondary: {
      background: colors.gray,
      color: colors.text,
    },
    danger:{
      background: colors.danger,
      color: colors.base,
    },
    outline:{
      background: colors.base,
      color: colors.blue001,
      border:`1px solid ${colors.blue001}`,
      textAlign:'center',
      paddinRight:'15px',
      paddingLeft:'15px',
      ':hover':{
        background: colors.blue001,
        color: colors.base,
      }
    },
    default:{
      background: colors.base,
      color: colors.text01,
    },
    orange:{
      background: colors.orange,
      color: colors.base,
    }
  }

const headingVariants = {
  h1: {
    fontSize:'2em'
   
  },
  h2: {
    fontSize:'1.5rem'
  },
  h3: {
    fontSize:'1.2rem'
  },
  h4: {
    fontSize:'1rem'
  },
  h5: {
    fontSize:'.75rem'
  },
  h6: {
    fontSize:'.5rem'
  },
}
const Heading = styled.p(
  {
   marginBottom:0
  },
  ({variant = 'h1'}) => headingVariants[variant],
)

const ParagraphVariants = {
  p1:{
      fontSize:'27.16px',
      [mq.small]:{
        fontSize:'17px'
      },
      [mq.medium]:{
          fontSize:'17px'
      }
  },
  p2:{
      fontSize:'23.47px',
      [mq.small]:{
          fontSize:'17px'
      },
      [mq.medium]:{
          fontSize:'17px'
      }
  },
  p3:{
    fontSize:'17.47px'
  },
  p4:{
    fontSize:'14px'
  }
}

const Paragraph = styled.p(
  {
    margin:0
   },
   ({variant}) => ParagraphVariants[variant],
)

const Hr = styled.hr({
    backgroundColor:colors.gray90,
    color:colors.gray90,
    marginTop:0,
    marginBottom:'1rem'
})

const StyledCard = styled.div({
  borderRadius: '3px',
  padding: '2rem',
  boxShadow: `0 0 0 1px ${colors.muted}`,
})

const CenterDiv = styled.div({
  maxWidth: '450px',
  margin: '20vh auto',
  [mq.small]: {
    width: '90%',
    margin: '10vh auto',
  },
})

const StyledAlert = styled(Alert)({
  paddingTop:'0.4rem',
  paddingBottom:'0.4rem',
  textAlign:'center'
})

  const Button = styled.button(
    {
      padding: '10px 15px',
      border: '0',
      lineHeight: '1',
      borderRadius: '11px',
      fontSize:'14px',
      ':disabled':{
       opacity:'0.5'
      },
      
    },
    ({variant = 'primary'}) => buttonVariants[variant],
  )
  
  
  const CircleButton = styled(IconButton)({
    borderRadius: '30px',
    padding: '0',
    width: '40px',
    height: '40px',
    lineHeight: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.base,
    color: colors.text,
    border: `1px solid ${colors.gray10}`,
    cursor: 'pointer',
  })
  
  const Dialog = styled.div({
    maxWidth: '450px',
    borderRadius: '3px',
    padding: '2rem',
    boxShadow: '0 5px 10px -5px rgba(0, 0, 0, 0.2)',
    margin: '20vh auto',
    [mq.small]: {
      width: '100%',
      margin: '10vh auto',
    },
  })


const Input = styled(Form.Control)({
    borderRadius: '12px',
    border: `0.73800128698349px solid ${colors.black001}`,
    background: colors.base,
    padding: '8px 12px',
    fontSize:'14px'
})

// const SelectInput = styled(Form.Select)({
//   borderRadius: '12px',
//   border: `0.73800128698349px solid ${colors.black001}`,
//   background: colors.base,
//   padding: '8px 12px',
//   fontSize:'14px'
// })

const FormGroup = styled(Form.Group)({
    marginBottom:'1rem'
})

const FormLabel = styled(Form.Label)({
    marginBottom:'0.4rem',
    fontSize:'14px'
})


const StyledUl = styled.ul({
  padding: 0,
  overflowY: 'scroll',
  position: 'absolute',
  zIndex: '100'
})
const StyledLi = styled.li({
  cursor:'pointer',
  fontSize:'14px'
})

const MuiFormLabel = styled(FormControlLabel)({
  fontSize:'14px'
})

const MuiRadio = styled(Radio)({
  '& .MuiSvgIcon-root':{
      width:'0.65em',
      height:'0.65em'
  }
})

const MuiLabel = styled(FormControlLabel)({
  '& .MuiTypography-root':{
      fontSize:'14px'
  }
})


const ErrorStar = styled.span({
    color:colors.danger,
})

const ErrorDiv = styled.div({
    color:colors.danger,
    fontSize:'.85rem'
})

const ErrorMessage = styled.div({
  fontSize: '12px',
  color: colors.red60,
  marginTop: '0.25rem'
})

const StyledLink = styled(Link)({
  textDecoration:'none',
  color:colors.text,
  ':hover': {
    color: colors.indigo,
    textDecoration: 'none',
    background: colors.gray10,
  }
})

const StyledEditIcon = styled(EditOutlinedIcon)({
  width:'0.8em',
  height:'0.8em',
  // marginRight:'15px',
  fill:colors.text02
})

const StyledDeleteIcon = styled(faTrash)({
  fill:colors.text02,
  width:'0.8em',
  height:'0.8em',
})

const RightAlignedContainer = styled.div({
  display:'flex',
  justifyContent:'flex-end'
})



const CenteredContainer = styled.div({
  display: 'flex',
  justifyContent: 'center'
})

const MiniCenteredDiv = styled.div({
  margin:'0 auto',
  maxWidth:'1000px',
  width:'90%',
  padding:'1rem'
})


const BillingContainer = styled.div({
  margin:'0 auto',
  maxWidth:'1300px',
  width:'98%',
})

const Loader = styled.img({
  width:'60px'
})

const Container = styled.div({
    [mq.medium]:{
    padding:'14.17px 44.46px'
    },
    [mq.large]:{
        padding:'14.17px 44.46px'
    },
    [mq.larger]:{
      padding:'14.17px 44.46px'
    },
    [mq.extraLarge]:{
        padding:'14.17px 44.46px'
    },

})

const PatientContainer = styled.div({
  [mq.medium]:{
    width:'1200px'
  },
    [mq.large]:{
        width:'1200px'
    },
    [mq.extraLarge]:{
        width:'1200px'
    },
    [mq.larger]:{
      padding:'14.17px 44.46px'
    },
})

const VisitContainer = styled.div({
  [mq.medium]:{
    padding:'20.17px 82.17px'
  },
  [mq.small]:{
    padding:'20.17px 26px'
  }
})

const PLContainer = styled.div({
  width:'98%',
  maxWidth:'1200px',
  margin:'0 auto'
})
const ProfitContainer = styled.div({
  width:'98%',
  maxWidth:'600px',
  margin:'0 auto'
})

const TableContainer = styled.div({
  marginTop:'27.43px'
})


export {Heading,Hr,StyledCard, CenterDiv, StyledAlert,CircleButton, Button,
   MuiRadio,MiniCenteredDiv,Dialog,Input, FormGroup, FormLabel, ErrorStar, ErrorDiv, 
   StyledLink,StyledUl,ErrorMessage,StyledDeleteIcon, StyledEditIcon, RightAlignedContainer,
   CenteredContainer,MuiFormLabel,Container,TableContainer,VisitContainer,ProfitContainer,
    MuiLabel,BillingContainer, Loader, Paragraph,StyledLi, PLContainer,PatientContainer}
