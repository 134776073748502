import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as snackbarActions from "../../actions/snackbarActions";
import {SubHeader} from "../../Containers/SubHeader";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import CustomTable from "../Utils/Tables/CustomTable";
import {faPrescriptionBottle} from "@fortawesome/free-solid-svg-icons/faPrescriptionBottle";
import * as prescriptionActions from "../../actions/PrescriptionActions/PrescriptionActions"
import {withTheme} from "@material-ui/core/styles";
import {formatDateTime} from "../Utils/ConvertDate";
import {Container} from "../Utils/styledComponents";
import { usePrescriptionList } from "../Prescription/CustomHooks/usePrescriptionList";
import FillPrescription from "../Prescription/FillPrescription";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";

const headData = [
    {id: 'id', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'pNumber', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'docName', numeric: false, disablePadding: false, label: 'Doctor Name'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action'}
];


const OutPatientPrescriptionList = ({actions, snackbars}) => {
    const {openBar, type, message} = snackbars;
    const {term, activePage,  prescriptions, total_count, handler,
        handleChangeActivePage,closeSnackBar,  isLoading, isSuccess, isRejected,
         prescriptionProps, openDialog, handleOpenDialog, handleCloseDialog,
    state,handleChange, isPendingForm, handleSubmit, submitted} = usePrescriptionList(actions, 'previous')
    const unfilledPrescription = !prescriptions ? [] : prescriptions;

    const {start_date, end_date} = state;
    return (
        <div id="prescription-list" className='journals'>
            <PageTitle title='Outpatient Prescriptions'/>
            <SubHeader title="Pharmacy" subTitle='Outpatient prescriptions'>
                <FontAwesomeIcon icon={faPrescriptionBottle}/>
            </SubHeader>
            {/* <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-md-10 mb-1"/>
                    <div className="col-md-2 mb-1">
                        <DataExportButton data={data} title='Prescription List' csvRef={csvRef}
                                          exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                    </div>
                </div>
            </div> */}
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title='View' maxWidth="lg"  isContentOverflow={false}>
                <FillPrescription {...prescriptionProps}/>
            </CustomDialog>
            <MainSnackbar handleCloseBar={closeSnackBar} open={openBar} message={message} variant={type}/>
            <Container>
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending:isPendingForm, type:'date'}}/>
                <CustomTable headData={headData} title='' data={unfilledPrescription}
                             customPage handleChangeNextPage={handleChangeActivePage}
                             records={10} total_count={total_count} activePage={activePage} term={term}
                             id='search-prescription' handler={handler} colSpan={headData.length}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? unfilledPrescription.length > 0 ? unfilledPrescription.slice(0, 10)
                                .map((unfilled, index) => {
                                    const _id = ((activePage - 1) * 10) + index + 1;
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell component="th" id={labelId} scope="row"
                                                       padding="default"
                                                       className='mui-table-cell'>
                                                {_id}
                                            </TableCell>
                                            <TableCell>{formatDateTime(unfilled.date_created)}</TableCell>
                                            <TableCell>{unfilled.patient_number}</TableCell>
                                            <TableCell>{unfilled.patient_name}</TableCell>
                                            <TableCell>{unfilled.doctor_name}</TableCell>
                                            <TableCell>
                                                <button type="button" onClick={() =>handleOpenDialog(unfilled)} className="btn btn-sm sms-info-btn mr-1">View</button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isRejected ? <TableRow>
                                <TableCell colSpan={headData.length} align="center">The server didnot return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody>
                        
                </CustomTable>
            </Container>
        </div>
    );

}

function mapStateToProps(state) {
    return {prescriptions: state.prescriptions, snackbars: state.snackbar};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            prescriptionActions: bindActionCreators(prescriptionActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    };
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(OutPatientPrescriptionList));
