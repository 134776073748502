import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {titleCase} from "../Users/addUser";
import {useTheme} from "@material-ui/core/styles";
import {formatDateTime} from "../Utils/ConvertDate";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import styled from "@emotion/styled/macro";
import * as colors from "../../styles/colors.js"
import { ErrorMessage } from "../Utils/styledComponents";
import TextField from "../Utils/FormInputs/TextField";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
// import { users } from '../../../../../cypress-e2e/cypress/support/utils/users';


const variants = {
    selected: {
        background: colors.blue001,
        color: colors.base,
       
      },
      primary: {
        background: colors.gray,
        color: colors.text,
      },
  }

const StyledDiv = styled.div({
    padding:'5.45px 10px',
    borderRadius:'5px',
    fontSize:'12.11px',
    textTransform:'uppercase',
    textAlign:'center',
    cursor:'pointer'
  },({variant})=>variants[variant])

const StyledColumn = styled.div({
    paddingBottom:'12px',
})


const EnterResults = ({resultState:state, patient, parameters,  submittedResult:submitted, technician, handleChangeResult,isSubmitted,
    handleChangeResultState:handleChange, handleSubmitResult, sampleTypes, sample_type, handleChangeSampleType,
     handleSelectTest,  labTests,isTestIdle,isSampleSelected, isTestSelected,handleChangeQualitative,

}) => {

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {patient_number, patient_name, dob, gender,drawn_date, drawn_by, doctor_firstname, doctor_lastname} = patient;
    const {date, report} = state;
    const reactive = [{value: 3, label: 'Reactive'}, {value: 2, label: 'Non-reactive'}];
    const blood_g = [
        {label: "A+", value: "A+"},
        {label: "A-", value: "A-"},
        {label: "B+", value: "B+"},
        {label: "B-", value: "B-"},
        {label: "O+", value: "O+"},
        {label: "O-", value: "O-"},
        {label: "AB+", value: "AB+"},
        {label: "AB-", value: "AB-"},
    ]
    const positive = [{value: 1, label: 'Positive'}, {value: 0, label: 'Negative'}];

    const doctor_name =  `${!doctor_firstname ? "" : doctor_firstname} ${!doctor_lastname ? "" : doctor_lastname}`


    return (
        <div>
             {/*<PrintDialog handleCancel={handleCloseResult} openDialog={openPrint} handleClose={handleCloseResult}*/}
             {/*                handleClick={handleSubmitResult} message={"Confirm Results"}*/}
             {/*                text={text}/>*/}
         <form autoComplete="off" onSubmit={handleSubmitResult}>

            <div className="lab-patient-details mb-3">
                <table className="table-sm table-borderless table prescription-table">
                    <tr>
                        <td><p>Patient ID: <span className="ml-2"><strong>{patient_number}</strong></span></p></td>
                        <td><p>Requested by:<span
                            className="ml-2"><strong>{doctor_name}</strong></span>
                        </p></td>
                    </tr>
                    <tr>
                        <td><p>Patient Name: <span className="ml-2"><strong>{patient_name}</strong></span></p></td>
                        <td><p>Date Requested:<span
                            className="ml-2"><strong>{drawn_date ? formatDateTime(drawn_date) : ''}</strong></span>
                        </p></td>
                    </tr>
                    <tr>
                        <td><p>Age: <span className="ml-2"><strong>{dob}</strong></span></p></td>
                        <td><p>Drawn By: <span className="ml-2"><strong>{drawn_by}</strong></span></p></td>
                    </tr>
                    <tr>
                        <td><p>Gender: <span
                            className="ml-2"><strong>{gender ? titleCase(gender) : ''}</strong></span></p></td>
                            <td><p>Drawn Date:<span
                            className="ml-2"><strong>{drawn_date ? formatDateTime(drawn_date) : ''}</strong></span>
                        </p></td>
                    </tr>
                </table>
            </div>
            <div className="mb-3">
                <div className="row">
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Sample" htmlFor='sample'  type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect submitted={submitted} dataTest="collected_sample" value={sample_type}
                                                id='collected_sample'
                                                onChange={handleChangeSampleType}
                                                options={sampleTypes}/>
                                {(submitted && !sample_type) && <ErrorMessage>This field is required</ErrorMessage>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Report" htmlFor='report'  />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <ResizableTextarea rows={2} placeholder="Write comment..." onChange={handleChange} id="report" name="report" value={report}/>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Lab Tests"  type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                            <div className='row'>
                                {labTests.map((item, index)=>(
                                    <StyledColumn key={item.hospital_test_id}  className='col-lg-6'>
                                        <StyledDiv data-testid={`hospital_test_name-${index}`} onClick={()=>handleSelectTest(item.hospital_test_id)} variant={item.isSelected ? 'selected':'primary'}>{item.hospital_test_name}</StyledDiv>
                                    </StyledColumn>
                                ))}
                            </div>
                            </Col>
                        </Form.Group>
                        
                    </div>
                    <div className="col-md-6">
                        <Form.Group as={Row}>
                        <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                            <Label name="Date & Time"  htmlFor="date" />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                        <TextField onChange={handleChange} name="date" id="date"
                                    value={date} type="datetime-local"/>
                        </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={2}>
                                <Label name="Technician" htmlFor='technician' />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type="text" disabled={true} id="technician" value={titleCase(technician)}/>
                            </Col>
                        </Form.Group>  
                        {/*<Form.Group as={Row}>*/}
                        {/*<Col xs={12} sm={12} md={12} lg={3} xl={2}>*/}
                        {/*        <Label name="Approved By" htmlFor='approval'  type/>*/}
                        {/*    </Col>*/}
                        {/*    <Col xs={12} sm={12} md={12} lg={7} xl={7}>*/}
                        {/*    <CustomSelect*/}
                        {/*        submitted={submitted}*/}
                        {/*        dataTest="approved_by"*/}
                        {/*        value={approvedBy}*/}
                        {/*        id='approvedBy'*/}
                        {/*        onChange={handleChangeApprovedBy}*/}
                        {/*        options={users} // This should be the array of objects with "value" and "label" properties*/}
                        {/*        />*/}

                        {/*        {(submitted && !sample_type) && <ErrorMessage>This field is required</ErrorMessage>}*/}
                        {/*    </Col>*/}
                        {/*</Form.Group>      */}
                    </div>
                </div>
                
                    <table className="table table-sm table-borderless">
                        <thead>
                            <tr style={{borderTop: "1px solid #909090"}}>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><strong>Code</strong>
                                </td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><strong>Sub Test Name</strong>
                                </td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><strong>Status</strong>
                                </td>
                                <td  className={palette.type === 'dark' && 'dark-theme-color'}
                                    align="center"><strong>Result</strong><span className="help-block">*</span>
                                </td>
                                    <td  className={palette.type === 'dark' && 'dark-theme-color'}
                                    align="center"><strong>Reference Ranges</strong>
                                </td>
                                <td  className={palette.type === 'dark' && 'dark-theme-color'}
                                    align="center"><strong>Unit</strong>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {isTestIdle ? <tr><td colSpan={5} align="center" style={{color:'red'}}>Select sample to load lab tests</td></tr>:null} 
                            {isSampleSelected ? <tr><td colSpan={5} align="center" style={{color:'red'}}>Click on lab test to load  test parameters</td></tr>:null} 
                            {isTestSelected ? parameters.length > 0 ? parameters.map((item, index) =>{
                                const isReactive = item.sub_test_name === 'reactive/non-reactive';
                                const isBlood  = item.sub_test_name === 'blood_group';
                                const isPositive = item.sub_test_name === '+ve/-ve';
                                const isReferenceRange = item.reference_range
                                return(

                                <tr key={index} style={{borderTop: "1px solid #909090",}}>
                                    <td 
                                        className={palette.type === 'dark' && 'dark-theme-color'}> {item.sub_test_code && item.sub_test_code !== 'null' ? item.sub_test_code : 'N/A'}
                                    </td>
                                    <td 
                                        className={palette.type === 'dark' && 'dark-theme-color'}> {(item.sub_test_name && item.sub_test_name !== '+ve/-ve' )? item.sub_test_name :
                                        (item.sub_test_name === '+ve/-ve' || item.sub_test_name === '') ?  'Result':'N/A'}
                                    </td>
                                    <td>  {item.is_external=== true ? 'External Test': item.is_external=== false ?  'Internal Test' : ''}</td>
                                    <td  align="center">
                                        {(isReactive || isBlood || isPositive) ? 
                                        <>
                                        <CustomSelect submitted={submitted}  value={item.test_value}
                                                    onChange={val=>handleChangeQualitative(val, index)}
                                                    options={isPositive ? positive :
                                                        isBlood ? blood_g : isReactive ?
                                                            reactive : []}  placement={isBlood ? "top":"bottom"} id='qualitative-results'/>
                                        {(submitted && !item.test_value) && <ErrorMessage>Result is required</ErrorMessage>}
                                        </>:
                                        isReferenceRange ? <>
                                            <TextField submitted={submitted} type="text" value={item.test_value}
                                                onChange={e => handleChangeResult(e, index)} placeholder="Enter results..."/>
                                                {(submitted && !item.test_value) && <ErrorMessage>Result is required</ErrorMessage>}
                                        </> :
                                        <>
                                            <ResizableTextarea submitted={submitted} type="text" value={item.test_value}
                                                    onChange={e => handleChangeResult(e, index)} rows={3} placeholder="Enter results..."/>
                                            {(submitted && !item.test_value) && <ErrorMessage>Result is required</ErrorMessage>}
                                        </>}
                                    </td>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}
                                        align="center">{(item.reference_range && item.reference_range !== 'null'  ) ?
                                        item.reference_range : 'N/A'} </td>
                                    <td className={palette.type === 'dark' && 'dark-theme-color'}
                                        align="center">{item.unit && item.unit !== 'null' ? item.unit : 'N/A'}</td>
                                </tr>
                            )}):<tr><td colSpan={5} align="center">No available parameters for this lab test</td></tr>:null}
                            {isTestSelected && labTests.length === 0 ? <tr><td colSpan={5} align="center">No lab tests availabe for this sample, select another sample</td></tr>:null}
                        </tbody>
                    </table>
                
            </div>
                <button type="submit" id="submit" className="btn btn-sm sms-btn px-4" disabled={isSubmitted === 'pending'}>
                    {isSubmitted === 'pending' ? "Saving..." : "Save"}
                </button>
            </form>
        </div>
         
    )
};


export default EnterResults;
