import React from "react";
import * as snackbarActions from "../../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from '../../../Utils/smsTitle';
import { TableCell, TableBody, TableRow } from "@material-ui/core";
import CustomTable from "../../../Utils/Tables/CustomTable";
import { dateStringConvert } from "../../../Utils/ConvertDate";
import Form from 'react-bootstrap/Form';
import Label from "../../../Utils/FormInputs/Label";
import { ErrorMessage } from "../../../Utils/styledComponents";
import MainSnackbar from "../../../Utils/Snackbar/SmsSnackbar";
import {SubHeader} from "../../../../Containers/SubHeader";
import { useANCLists } from "./hooks/useANCLists";
import ReusableDialog from './../../../Utils/Dialogs/ReusableDialog';
import { PatientDetails } from "../../../Visit/NewService/SelectService";
import { CustomSelect } from "../../../Utils/FormInputs/SelectInput";
import { PrintANCReport } from "./PrintANCReport";
import { usePrint } from "../../../Utils/Templates/usePrint";
import CustomDialog from "../../../Utils/Dialogs/CustomDialog";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

/**
 * Component to return the lists of Ante Natal 
 * @param {*} param0 
 * @returns 
 */
const ANCLists = ({ actions, snackbar, anc_status=0}) => {

    const {
        ancStatus, setANCStatus, isLoading, isError, isSuccess, handleOpenAssign,handleContinuePatient,
        handleSearch, searchValue, activeTablePage, ancList, handleChangeNewPage, order, orderBy, handleRequestSort, 
        handleCloseAssign, openAssign, assignDoctor, submitted, isSubmitted, ids, department, departments, handleChangeDoctor,
        doctor_name, doctors, handleChangeDepartment, handleTakeUpPatient, handleCompleteVisit, handleViewPastANC,
        openDialog, handleCloseDialog,
    } = useANCLists(actions, anc_status);
    
    const list_titles = ancStatus === 1 ? 'Ongoing Vists' : ancStatus === 0 ? 'Pending Visits' : 'Past Visits';

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const {openBar, type, message} = snackbar;
    const {ancs, total_count} = ancList;
    const { patient_number, patient_name} = ids;
    const {componentRef, handlePrint}  = usePrint(`ANCReport_${ids.patient_number}_${ids.anc_id}`);

    return (
        <div data-testid="ante-natal-lists">
            <PageTitle title="Ante Natal Care Lists"/>

            <SubHeader title="Ante Natal Care" subTitle="Patient Lists" >
                <img src="/images/antenatal-white.png" alt="anc-icon" className="icon-resize surgery-icon-tabs ante-natal-green"/>
            </SubHeader>

            <CustomDialog title={'Preview Surgery Report'} open={openDialog} handleClose={handleCloseDialog} maxWidth="md" isPrint={true} printSurgery={handlePrint}>
                <div className="surgery-report" ref={componentRef}>
                    <PrintANCReport patientANCDetails={ids}/>
                </div>
            </CustomDialog>

            <ReusableDialog message='Reassign Doctor' openDialog={openAssign} handleClose={handleCloseAssign}
                isSubmitted={isSubmitted} handleSubmit={assignDoctor} width='sm'>
                    <PatientDetails {...{patient_number,patient_name}}/>
                        <div className="my-2">
                            <Form.Group>
                                <Label name="Department" type/>
                                <CustomSelect onChange={handleChangeDepartment} value={department} options={departments}/>
                                {(submitted && !department) && <ErrorMessage>This is a required field</ErrorMessage>}
                            </Form.Group>
                            <Form.Group>
                                <Label name="Doctor"  type/>
                                <CustomSelect onChange={handleChangeDoctor} value={doctor_name} options={doctors}/>
                                {(submitted && !doctor_name) && <ErrorMessage>This is a required field</ErrorMessage>}
                            </Form.Group>
                        </div>
            </ReusableDialog>

            <div className='mui-tables'>
                <MainSnackbar handleCloseBar={closeSnackBar} message={message} open={openBar} variant={type}/>
                <div className='general-ledger-header inpatient-billing-header'>
                    <a className={`billing-span btn btn-sm ${ancStatus === 0 ? 'active-billing-span':''}`} onClick={()=>setANCStatus(0)}>Pending ANCs</a>
                    <a className={`billing-span btn btn-sm ${ancStatus === 1 ? 'active-billing-span':''}`} onClick={()=>setANCStatus(1)}>Ongoing ANCs</a>
                    <a className={`billing-span btn btn-sm ${ancStatus === 2 ? 'active-billing-span':''}`} onClick={()=>setANCStatus(2)}>Past ANCs</a>
                </div>

                <CustomTable headData={headData} handler={handleSearch} term={searchValue} title={list_titles} filter={true} searchPlaceHolder={"Search by Patient Name"}
                            data={ancs} colSpan={headData.length} customPage records={10} activePage={activeTablePage}
                            handleChangeNextPage={handleChangeNewPage} total_count={total_count} handleRequestSort={handleRequestSort} 
                            order={order} orderBy={orderBy}>
                    
                        <TableBody>

                            {isLoading ? <TableRow></TableRow> : null}

                            {
                                isSuccess ?  
                                //ancs.length > 0 ?
                                ancs.filter(item => {
                                    return item['anc_status'] === ancStatus;
                                }).length > 0 ?

                                ancs.filter(item => {
                                    return item['anc_status'] === ancStatus;
                                }).map((item,idx) => {

                                    return (
                                        /** For each row in the table*/

                                        <TableRow hover 
                                        role ="checkbox"
                                        key={item.visit_id}
                                        tabIndex={-1}
                                        className="mui-table-row">

                                            <TableCell>{((activeTablePage - 1) * 10) + idx + 1}</TableCell>

                                            <TableCell>{dateStringConvert(item.visit_date)}</TableCell>

                                            <TableCell>{item.patient_number}</TableCell>

                                            <TableCell>{item.patient_name}</TableCell>

                                            <TableCell>
                                                {
                                                    item['anc_status'] === 0 ? <span className="text-pending">Pending</span> :
                                                    item['anc_status'] === 1 ? <span className='text-success'>Ongoing</span> :
                                                    item['anc_status'] === 2 ? <span className="text-done">Completed</span> :
                                                    null
                                                }
                                            </TableCell>

                                            <TableCell>
                                                {item['anc_status'] === 0 ? 
                                                    <button onClick={()=> handleTakeUpPatient(item)} className="btn btn-sm sms-hl-btn mr-1" style={{marginBottom:'4px'}}>Take Up</button>     
                                                :null}

                                                {item['anc_status'] === 1 ?
                                                    <button className="btn btn-sm sms-amber-btn mr-1"  onClick={()=> handleContinuePatient(item)} 
                                                    style={{marginBottom:'4px'}}>Continue</button>
                                                    :null}

                                                {item['anc_status'] === 1 ? <button onClick={()=>handleOpenAssign(item)} style={{marginBottom:'4px'}}
                                                        className="btn btn-sm sms-gray-btn mr-1">Reassign</button>: null}

                                                {item['anc_status'] === 1 ? <button onClick={()=>handleCompleteVisit(item)} style={{marginBottom:'4px'}}
                                                        className="btn btn-sm sms-info-btn mr-1">Complete</button>: null}
                                                
                                                {item['anc_status'] === 2 ? <button style={{marginBottom:'4px'}} onClick={()=>handleViewPastANC(item)}
                                                        className="btn btn-sm sms-btn mr-1">View Visit Details</button>: null}

                                            </TableCell>
                                        </TableRow>

                                    )
                                })
                                
                                : // else if false no ancs are present
                                <TableRow>
                                    <TableCell colSpan={headData.length} align="center">
                                        No records found
                                    </TableCell>
                                </TableRow> 
                                : null
                            }
                           

                            {/** Error handling */}
                            {isError ? 
                                <TableRow>
                                    <TableCell colSpan={headData.length} align="center">
                                        The server did not return a successful response
                                    </TableCell>
                                </TableRow>
                            : null}

                        </TableBody>

                </CustomTable>
            </div>

        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ANCLists);