import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Label from "../../Utils/FormInputs/Label";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import { ErrorMessage } from '../../Utils/styledComponents';

export const ReusableNewCOA = ({accountType, defaultAccounts, handleChangeAccountType, submitted, handleChangeState,
                                    subAccounts, parentAccount, handleChangeParentAccount,
                                    entryType, handleChangeEntryType, handleChangeCurrency, currencies, currency,
                                    entryTypes, state, isExpense=false}) =>{
    const {account_name, opening_balance, isSubAccount, balance_date, isTransactionAccount} = state;                           
    return(
        <div>
            <Form.Group as={Col}>
                <Label name='Account type' htmlFor='account_type' type />
                {/* <Col sm={8}> */}
                    <CustomSelect value={accountType} options={defaultAccounts} dataTest={"account-type"}
                        name="account-type" onChange={handleChangeAccountType} id='account_type'/>
                    {(submitted && !accountType) &&
                    <ErrorMessage>Account type is required</ErrorMessage>}
                {/* </Col> */}
            </Form.Group>
            <Form.Group as={Col}>
                <Label name='Account name' htmlFor='account_name' type/>
                {/* <Col sm={8}> */}
                    <TextField onChange={handleChangeState} value={account_name} id='account_name' name='account_name'
                               type='text' data-testid="account-name"/>
                    {(submitted && !account_name) &&
                    <ErrorMessage>Account name is required</ErrorMessage>}
                {/* </Col> */}
            </Form.Group>
                                  
            {/*<Form.Group as={Col}>*/}
            {/*    <Label name='Currency Type' type />*/}
            {/*    /!* <Col sm={8}> *!/*/}
            {/*        <CustomSelect value={currency} onChange={handleChangeCurrency}*/}
            {/*            name="currency" dataTest={"currency"} id={"currency"} options={currencies} />*/}
            {/*        {(submitted && !currency) &&*/}
            {/*        <ErrorMessage>This field is required</ErrorMessage>}*/}
            {/*    /!* </Col> *!/*/}
            {/*</Form.Group> */}

            {/* {(accountType && subAccounts.length > 0) ?  */}
            {/* <Form.Group as={Col}> */}
                {/* <Col sm={4}/> */}
                {/* <Col sm={8}> */}
                    {/* <Form.Check type='checkbox' label='Is sub-account' name='isSubAccount'
                                value={isSubAccount}
                                onChange={handleChangeState}/> */}
                {/* </Col> */}
            {/* </Form.Group>  */}
            {/* // : null} */}

            {/* {isSubAccount ?
                <> */}
                    <Form.Group as={Col}>
                        <Label name='Parent account' htmlFor='parent_account' type />
                        {/* <Col sm={8}> */}
                            <CustomSelect value={parentAccount} onChange={handleChangeParentAccount} dataTest={"parent-account"}
                                          options={subAccounts} isDisabled={!(accountType)} id='parent_account'/>
                            {(submitted && isSubAccount && !parentAccount) &&
                            <ErrorMessage>This field is required</ErrorMessage>}
                        {/* </Col> */}
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Check type='checkbox' label='Is transaction account' name='isTransactionAccount'
                                value={isTransactionAccount}
                                onChange={handleChangeState}/>
                    </Form.Group>

                    {isTransactionAccount ? <div className='row'>
                        <div className='col-lg-6'>
                            <Form.Group as={Col}>
                                <Label name='Opening balance' htmlFor='opening_balance' type/>
                                {/* <Col sm={8}> */}
                                    <TextField onChange={handleChangeState} value={opening_balance}
                                            name='opening_balance' type='number' 
                                            id='opening_balance' disabled={!isTransactionAccount}/>
                                    {/* {(submitted && isTransactionAccount && !opening_balance) &&
                                    <ErrorMessage>This field  is required</ErrorMessage>} */}
                                {/* </Col> */}
                            </Form.Group>
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group as={Col}>
                                <Label name='As of' htmlFor='balance_date' type/>
                                {/* <Col sm={8}> */}
                                    <TextField onChange={handleChangeState} value={balance_date}
                                            name='balance_date' type='date' 
                                            id='balance-date' disabled={!isTransactionAccount}/>
                                    {(submitted && isTransactionAccount && !balance_date) &&
                                    <ErrorMessage>This field is required</ErrorMessage>}
                                {/* </Col> */}
                            </Form.Group>
                        </div>
                    </div>: null}
                    
                    {isTransactionAccount && !isExpense ? <Form.Group as={Col}>
                        <Label name='Entry type' type />
                        {/* <Col sm={8}> */}
                            <CustomSelect value={entryType} onChange={handleChangeEntryType} id={'entry_type'}
                                          options={entryTypes} isDisabled={!isTransactionAccount}/>
                            {(submitted && isTransactionAccount && !entryType) &&
                            <ErrorMessage>This field is required</ErrorMessage>}
                        {/* </Col> */}
                    </Form.Group> : null}
                {/* </> : null} */}
        </div>
    )
}


