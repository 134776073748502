import  {useEffect,  useState} from "react";
import axios from "axios";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { config } from "../../../Helpers/env";
import {useCommonState} from "./useReusableFunctions";
import {convertMonth} from "../../Utils/ConvertDate";
import {exportPDF} from "../../Utils/downloadPDF";


export const useRevenuePerService = (actions, service_id, service_name, param) => {
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [total_count, setTotalCount] = useState(0)
    const {
        closeSnackbar, search_type, text, textType, year, date, handleChangeSearch,
        handleChangeDate, handleChangeYear, handleIncrementYear,inputType,
        searchValue, order, orderBy, page, rowsPerPage, setSearchType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage
    } = useCommonState(actions)
    const {start_date, end_date} = date;

    const calculateTotalAmount = (arr) =>{
        const sum = arr.reduce((a,b)=>{
            return +a + +b.total_amount
        },0)
        setTotalAmount(sum)
    }

    const processedArr = (arr) =>{
        return arr.map(item=>({
            service_name:item.service_name,
            quantity: item.sum,
            rate:item.rate,
            total_amount: item.sum * item.rate
        }))
    }

    useEffect(() => {
        setLoading(true)
        axios.post(`${config.smsUrl}/creport/service_report`, null,{
            params: {
                start_date: start_date,
                end_date: end_date,
                [param]:service_id,
                option:3,
                page:activePage
            }
        }).then(res => {
            const data = res.data;
            const dt = !data ? {} : data
            const arr = dt.service ?? [];
            const count = dt.total_count ?? 0;
            setServices(processedArr(arr))
            setTotalCount(count)
            calculateTotalAmount(processedArr(arr))
            setLoading(false)
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
            setLoading(false)
        })
    }, [activePage]);


    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true)
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setLoading(true)
            axios.post(`${config.smsUrl}/creport/service_report`, null,{
                params: {
                    start_date: start_date,
                    end_date: end_date,
                    [param]:service_id,
                    option:3,
                    page:activePage
                }
            }).then(res => {
                const data = res.data;
                const dt = !data ? {} : data
                const arr = dt.service ?? [];
                const count = dt.total_count ?? 0;
                setServices(processedArr(arr))
                setTotalCount(count)
                calculateTotalAmount(processedArr(arr))
                setLoading(false)
            }).catch(err => {
                logoutErrorMessage(err, null, actions);
                setIsSubmitted('rejected')
                setLoading(false)
            })
        }
    }

    const handleChangeActivePage = (page) =>{
        setActivePage(page)
    }

    const downloadPdf = () => {
        const {end_date, start_date} = date
        const time = search_type === '0' ? start_date :  search_type === '1'? convertMonth(start_date) :
            search_type === '2' ? year : `${start_date} to ${end_date}`;
        exportPDF(`${service_name}  Financial Report(${time})`, [["Service", "Quantity","Unit Cost","Amount"]],
           services.map((item) => [item.service_name,  item.sum, item.rate, item.total_amount]),
            `${service_name}  Financial Report(${time}).pdf`, [["","","Total Amount",totalAmount]]);
    }


    return {
        closeSnackbar, search_type, handleSubmit,total_count, activePage, handleChangeActivePage,
        text, textType, year, date, handleChangeSearch, services, 
        handleChangeDate, handleChangeYear, handleIncrementYear, isSubmitted, submitted,
        searchValue, order, orderBy, loading, page, rowsPerPage,totalAmount,downloadPdf,inputType,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage, setSearchType
    }
}