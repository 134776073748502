import React from 'react';
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import * as snackbarActions from '../../../actions/snackbarActions';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {LoadingGif} from "../../Utils/Loader";
import {extractDate, formatDate} from "../../Utils/ConvertDate";
import {formatAmount} from "../../Utils/formatNumbers";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {ReportsItemList} from "./ReportsItemList";
import {useDebtorsReportDetails} from "./hooks/useDebtorsReportDetails";
import {CollapsibleList, DataCell,  ItemText, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {ReceiveDebtorsPayments} from "./ReceiveDebtorsPayments";
import {AccountingDrawer} from "../../Utils/AccountingUtils/AccountingDrawer";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Link} from "react-router-dom";
import CustomTable from '../../Utils/Tables/CustomTable';
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

const useStyles = makeStyles((theme) => ({
    drawerWidth:{
        width: 800,
        padding:'16px'
    },
    paragraph:{
        fontSize:'15px',
        display:'inline-block',
        marginBottom:'0.8rem',
        marginRight:'16px'
    },
    icon: {
        width: '.9em',
        height: '.9em'
    },
    iconHolder: {
        minWidth: '38px '
    },
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    pItem: {
        borderLeft: '1px solid #c7c6c7',
    },
    rounded: {
        borderRadius: 0
    },
    listText: {
        textAlign: 'right'
    },
    approved: {
        color: "green"
    },
    purchased: {
        color: "#1d5dd3"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    },
    transactions:{
        padding:'20px 30px'
    }
}));

/*
const headData = [{item:'Item', align:'left'},{item:'Item Type',align: 'left'},
{item:'Quantity', align:'left'},{item:'Rate', align:'left'},{item:'Amount', align: 'left'}];

Date, Patient name, Invoice No, Item, Item Type, Acc name, Acc Number, 
*/


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date Created'},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'acc_name', numeric: false, disablePadding: false, label: 'Account Name'},
    {id: 'card_number', numeric: false, disablePadding: false, label: 'Card Number'},
    {id: 'item_type', numeric: false, disablePadding: false, label: 'Item Type'},
    {id: 'item', numeric: false, disablePadding: false, label: 'Item'},
    {id: 'invoice_number', numeric: false, disablePadding: false, label: 'Invoice Number'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];


const DebtorsReportDetails = ({actions, snackbars, match}) => {
    const classes = useStyles();
    const  {data, rowData, isSuccessProducts, isLoadingProducts, isErrorProducts, handleOpen,closeSnackbar, handleSearch,
        handleClickCustomer, isLoadingDebtors,isLoadingInvoices,  page, rowsPerPage, handleChangeRowsPerPage, handleChangePage,
        isSuccessDebtors, isSuccessInvoices, isErrorDebtors, isErrorInvoices, allDebtors, customer, paymentProps} = useDebtorsReportDetails(actions,match)
    const {invoices} = data
    const {products} = rowData
    const {debtors} = allDebtors
    const {openBar, type, message} = snackbars
    const {name} = customer
    const {openDialog, handleCloseDialog, handleOpenDialog} = paymentProps

    return (
        <div className='journals'>
            <PageTitle title="Accounts Receivables Details"/>
            <SubHeader title="Reports" subTitle="Accounts Receivables Details">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            <AccountingDrawer open={openDialog} title={`Payment for ${name}`} handleClose={handleCloseDialog} width={classes.drawerWidth}>
                <ReceiveDebtorsPayments {...paymentProps}/>
            </AccountingDrawer>

            <Card elevation={0} classes={{root: classes.rounded}}>
                <Grid container spacing={0}>
                    <Grid item xs={6} md={3} classes={{item: classes.item}}>
                        <div  className="top-bar">
                            <BackButton text='Accounts Receivables' to='/customerreceivables'/>
                            {/*<button disabled={!bulkPayment} className='btn btn-sm pharmacy-info-btn'>Record Bulk Payment</button>*/}
                        </div>
                        {isLoadingDebtors ? <div className='text-center'>
                            <LoadingGif/>
                        </div> : null}
                        {isSuccessDebtors ?  debtors.length > 0 ?
                            debtors.map(item => {
                                const isActive = item.isActive === 'active'
                                const styles = {
                                    0: classes.pending,
                                    1: classes.approved,
                                    2: classes.canceled,
                                    3: classes.purchased
                                }
                                const text = {
                                    0: 'PENDING APPROVAL',
                                    1: 'APPROVED',
                                    2: 'CANCELED',
                                    3: 'FULFILLED'
                                }

                                const amount_due = item.total_debt - item.amount_paid

                                const itemObj = {
                                    name:item.provider_name, id:item.customer_id,
                                    date:item.date ? formatDate(item.date):'',
                                    amount: item.total_debt
                                }

                                return(
                                    <Link to={{pathname:`/invoicereportdetails/${item.customer_id}/${item.provider_name}`}}>
                                              <ReportsItemList styles={styles} isActive={isActive} handleClick={()=>handleClickCustomer(item)}
                                                     text={text} item={itemObj} no_date_id={true}/>
                                            </Link>

                                    
                                )}) : <div className='text-center mt-3'>No records found</div>:null}
                        {isErrorDebtors ? <div className='text-center mt-3'>The server did not return a valid response</div> : null}

                    </Grid>
                    <Grid item xs={6} md={9} classes={{item: classes.pItem}}>
                        {isLoadingInvoices ? <div className='text-center mt-5'><LoadingGif/></div>: null}
                        {isSuccessInvoices ? invoices.length > 0 ?  <>
                            <div className="top-bar">
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <p style={{fontSize:'18px', marginTop:'4px', marginBottom:0}}>{name}'s Transactions</p>
                                    </div>
                                    <div className='col-lg-6'>
                                        {/*<div className="text-right">*/}
                                        {/*    <div className='btn-group'>*/}
                                        {/*        <button type='button' onClick={downloadPdf}*/}
                                        {/*                className='btn btn-sm pharmacy-grey-btn'>*/}
                                        {/*            <PictureAsPdfOutlinedIcon classes={{root: classes.icon}}/>*/}
                                        {/*        </button>*/}
                                        {/*        <button type='button'*/}
                                        {/*                className='btn btn-sm pharmacy-grey-btn'>*/}
                                        {/*            <PrintOutlinedIcon classes={{root: classes.icon}}/>*/}
                                        {/*        </button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                            </div>
                            {/*<div className="sub-journal-bar approve-purchase-order">*/}
                            {/*    <div className="row">*/}
                            {/*        <div className='col-lg-6'>*/}
                            {/*        </div>*/}
                            {/*        <div className='col-lg-6'>*/}
                            {/*            <div className="text-right">*/}
                            {/*                <button type='button' style={{marginTop:'0.6rem'}}*/}
                            {/*                        className="btn btn-sm pharmacy-info-btn mr-2">Receive Payment*/}
                            {/*                </button>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={classes.transactions}>
                                <div className="text-right">
                                    <button type='button' style={{marginTop:'0.6rem'}} onClick={handleOpenDialog}
                                            className="btn btn-sm sms-btn mr-2 mb-3">Receive Payment
                                    </button>
                                </div>

                                <CustomTable headData={headData} 
                                    handleChangePage={handleChangePage}
                                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                                    handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                                    data={invoices} colSpan={headData.length}
                                    id="searchValue"  
                                >
                                    <TableBody>
                                        {isLoadingInvoices ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                                alt="loader"/></TableCell>
                                        </TableRow>:null}
                                        {isSuccessInvoices ? invoices.length ?  
                                            invoices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((invoice_details, index) => {

                                                const count = (page * rowsPerPage) + index + 1;
                                                // const invoice_details = item['invoice'];

                                                const rate = invoice_details.rate ? invoice_details.rate : 0
                                                const qty = invoice_details.quantity ? invoice_details.quantity : 0

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        tabIndex={-1}
                                                        key={invoice_details.provider_id}
                                                        className='mui-table-row'
                                                        id="services"
                                                    >
                                                        <TableCell>{count}</TableCell>
                                                        <TableCell>{extractDate(invoice_details.date_created)}</TableCell>
                                                        <TableCell>{invoice_details.first_name ? `${invoice_details.first_name} ${invoice_details.last_name}` : 'Unknown name'}</TableCell>
                                                        <TableCell>{invoice_details.account_name}</TableCell>
                                                        <TableCell>{invoice_details.card_number}</TableCell>
                                                        <TableCell>{invoice_details.product_name ? 'Product' : 'Service'}</TableCell>
                                                        <TableCell>{invoice_details.service_name?? invoice_details.product_name}</TableCell>
                                                        <TableCell>{invoice_details.invoice_no}</TableCell>
                                                        <TableCell>{rate * qty}</TableCell>
                                                    </TableRow>
                                                );
                                            }):<TableRow>
                                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                        </TableRow>:null}
                                        {isErrorInvoices ? <TableRow>
                                            <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                        </TableRow>:null}
                                    </TableBody>
                                </CustomTable>




                                {/*{invoices.map((item, index) => {
                                    const tableData = (
                                        <>
                                            {isLoadingProducts ? <TableRow>
                                                <DataCell align='center' colSpan={headData.length}>
                                                    <LoadingGif/>
                                                </DataCell>
                                            </TableRow> : null}
                                            {isSuccessProducts ? products.length > 0 ?
                                                <>
                                                    {products.map(row=>{
                                                        const rate = row.rate ? row.rate : 0;
                                                        const quantity  = row.quantity ? row.quantity : 0
                                                        return (
                                                            <TableRow key={row.id}>
                                                                <DataCell>{row.product_name ? row.product_name : row.service_name}</DataCell>
                                                                <DataCell>{row.product_name ? 'Product' : 'Service'}</DataCell>
                                                                <DataCell>{row.quantity ?? ''}</DataCell>
                                                                <DataCell>{row.rate ?? ''}</DataCell>
                                                                <DataCell>{formatAmount(quantity * rate)}</DataCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </>
                                                : 
                                                item.service_name ? 
                                                    <TableRow key={item.id}>
                                                        <DataCell>{item.product_name ? item.product_name : item.service_name}</DataCell>
                                                        <DataCell>{item.product_name ? 'Product' : 'Service'}</DataCell>
                                                        <DataCell>{item.quantity ?? ''}</DataCell>
                                                        <DataCell>{item.rate ?? ''}</DataCell>
                                                        <DataCell>{formatAmount(item.quantity * item.rate)}</DataCell>
                                                    </TableRow>  
                                                :
                                                <TableRow>
                                                    <DataCell align='center' colSpan={headData.length}>No records found</DataCell></TableRow>:null}
                                                        {isErrorProducts ? <TableRow><DataCell align='center' colSpan={headData.length}>
                                                        The server did not return a valid response
                                                    </DataCell>
                                                </TableRow> 
                                                : 
                                                null
                                            }
                                        </>
                                    )
                                    const date2 = (
                                        <span>Date: <strong>{item.date ? formatDate(item.date):item.date}</strong></span>
                                    )
                                    const date = (
                                        <span>Invoice No: <strong>{item.invoice_no}</strong></span>
                                    )
                                    const amount = (
                                        <span>Amount: <strong>{item.total_amount ? formatAmount(item.total_amount) : item.total_amount}</strong></span>
                                    )
                                    const buttons = (
                                        <>
                                            <ItemText  primary={date}  />
                                            <ItemText  primary={amount}  />
                                        </>
                                    )

                                    return (
                                        <CollapsibleList key={item.invoice_no} open={item.open} name={item.invoice_number} handleOpen={()=>handleOpen(item)}
                                                            button={buttons}
                                        >
                                            <ModuleTable headData={headData}>
                                                {tableData}
                                            </ModuleTable>
                                        </CollapsibleList>
                                    )
                                })}*/}
                            </div>
                        </>: <div className='text-center mt-5'>No records found</div>:null }
                        {isErrorInvoices ? <div className='text-center mt-5'>The server did not return a valid response</div> : null}
                    </Grid>
                </Grid>
            </Card>

        </div>
    )
}

function mapStateToProps(state) {

    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DebtorsReportDetails);


/*






*/