function formatAmount(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    
    return formatter.format(amount)
}

function formatUgxAmount(amount) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'UGX',
    });

    return formatter.format(amount)
}


function formatDigits(amount) {
  const formatter = new Intl.NumberFormat('en-US',{
      style: 'currency',
      currency: 'USD',
      currencyDisplay: "code"
  });
  
  return formatter.format(amount).replace("USD", "").trim()
}

function formatSsp(amount) {
    const formatter = new Intl.NumberFormat('en-US',{
        style: 'currency',
        currency: 'SSP',
        currencyDisplay: "code"
    });

    return formatter.format(amount).replace("SSP", "").trim()
}
export {formatAmount, formatDigits, formatSsp, formatUgxAmount}