import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {formatDateTime} from "../Utils/ConvertDate";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { useSeenPatients } from "./CustomHooks/usePatientsSeen";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import {useRetrievePatientCount} from "./hooks/useRetrievePatientCount";


const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    // {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];

 const PatientsSeen = ({actions,snackbars}) => {
     const {total_patients} = useRetrievePatientCount(actions)

     const components = [{label: 'Waiting List', path: '/waitinglist', badge:true, badge_count:total_patients},
     //     {
     //     label: 'Lab/Radiology Results',
     //     path: '/pendingdoctorrequests'
     // },
         {label: 'Patients Seen', path: '/patientsSeen'}];

    const {searchValue, activePage, handleSearch,  handleChangeNextPage,handleSubmit,
        closeSnackbar,  isLoading, isSuccess, submitted,state, data,handleChange,
       isPending, isError}= useSeenPatients(actions)
    const {total_count, patients} = data;
    // const doctor_list = patients ? patients : [];
    const {openBar, type, message} = snackbars;
    const {start_date, end_date} = state

     const doctor_list = patients.map((item, index)=>{
         const count = ((activePage - 1) * 10) + index + 1;
         return {
            ...item,
            count,
            date: item.begin_datetime ? formatDateTime(item.begin_datetime) : item.begin_datetime,
            patient_name:`${item.first_name} ${item.last_name}`
         }
     })

     const {
         headCells, all_hidden, handleAllHeadCells, handleHeadCells
     } = useHiddenColumns(headData);


    return (
        <div data-testid="Patients Seen" className='journals'>
            <PageTitle title="Patients Seent"/>
            <SubHeader title="Doctor" subTitle="Patients Seen">
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
            <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending, type:'date'}}/>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <CustomTable title="Patients Seen" customPage handleChangeNextPage={handleChangeNextPage}
                             headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                             activePage={activePage} data={doctor_list} colSpan={headCells.length} term={searchValue}
                             {...{all_hidden, handleAllHeadCells, handleHeadCells}}>
                    
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ?  doctor_list.length > 0 ? doctor_list.slice(0, 10)
                                .map((item, index) => {

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.patient_number}
                                            className='mui-table-row '
                                        >
                                            {headCells.map((head)=>(
                                                <TableCell  key={head.id} hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> : null }
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientsSeen);
