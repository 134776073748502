/** @jsxImportSource @emotion/react */
import React from 'react'
import {titleCase} from "../Users/addUser";
import { formatDateTime } from '../Utils/ConvertDate';
import { ReportPreviousObsHistoryTable, ReportAnteNatalProgressTable } from '../MotherChild/AnteNatal/ANCScreen/PatientFormScreens/AnteNatalTables';
import { dateStringConvert } from '../Utils/ConvertDate';
import {
    table, textWeight
} from "../../styles/tableStyles";
import {PrintTemplate} from "../Utils/Templates/PrintTemplate";
import {TableHeading} from "./ReferralNote/ReusableTreatmentTable";
import {
    Border,
    Heading,
    PreviousClinicalNotes,
    PreviousDiagnosis,
    PreviousLabResults, PreviousPrescription,
    PreviousRadiology,
    PreviousTriage, ResultsHeading
} from "./PatientPreviousVisits";
import styled from "@emotion/styled/macro";
import TextField from "../Utils/FormInputs/TextField";


export const Spacer = styled.div({
    marginTop: "10px"
})


  
const convertSmallCapsToBig = (dict_str) => {
    if (dict_str) {
        const chks_arr = dict_str.split(',');
        const new_arr = chks_arr.filter((item) => {
           return item !== 'hosp_no' && item !== 'hosp_yes' && item !== 'hiv_yes' && item !== 'hiv_no';
        });
        const new_new_arr = new_arr.map((item) => {
            let ar = item.replaceAll("_", " ");
            return ar;
        })
    
        let st = new_new_arr.join(" , ");
        return st;
    }
    return '';
}


export const DisplayAnteNatalDetails = ({antenatal_data, chronic_illness_dt}) => {

    if (Object.keys(antenatal_data).length === 0) {return;}
    const {
        gravida_details, exam_details, antenatal
    } = antenatal_data; 
    const {
        // first screen props
        anc_visit, current_anc_visit_date, anc_timing, client_category, last_normla_mps, expected_date_of_delivery,
        gestational_age_in_days, inr_no, mauc_cm_and_color_code, gravida, parity,
        sgbv_risk, husband_partner_health, obs_gyn, 
        length_of_menses, amount, when_why_disconnected, never_used_why, family_planning_used,

        woa_month, woa_day, blood_type,

        // second screen props
        first_day_of_lnmp, pregnancy_complications, nutritional_status, general_exam, pelvic_exam,
        exam_notes,
        tb_status, emtct_risk_assessment, linkage_art_no, art_code, infant_arv_prophyloxis,
        emtct_woman_initial_result, emtct_woman_stk_given, emtct_partner_age, emtct_partner_stk_result, 
        linked_to_art_yes_no, linked_to_art_tfv, linked_to_art_where, linked_to_art_pre_art_no, linked_to_art_art_no,
        cd4_cells_ul_mm3, virol_load_copies_mm3, who_clinical_stage,
        blood_group, hb, 
        partner_hepatitis_b, partner_syphilis_status, woman_hepatitis_b, woman_syphilis_status,
        tetanus_diphtheria, ipt_ctx, llin, mabendazole,
        iycf_option, maternal_nutrition_counselling, family_planning, gbv_risk,
        examination_notes,
        // third screen props
        treatment_notes, adherence_counselling, bs_for_mps_rdt_for_malaria, partner_disclosure_support_done,
        emtct_code, high_risk_mother, infant_feeding_counselling_done, niverapine_syrup_for_baby,
        random_blood_sugar, referred_psychosocial_group, 
        
        visit_id, seen_by, service_id, status,
   } = antenatal;

   // get the pregnancy complication details
   const pregs = pregnancy_complications ? pregnancy_complications.length > 0 ? pregnancy_complications[0] : {} : {};
   const any_hospitalisation  = pregs['any_hosp_text'] ? pregs['any_hosp_text'] : '';
   const other_complication_text = pregs['other_complication_text'] ? pregs['other_complication_text'] : '';  
   const complications_checks = pregs['complication_checks'] ? pregs['complication_checks'] : '';

   // previous obstetrics history table
   const gravida_table = gravida_details ? gravida_details : [];

   // antenatal progress examination table
   const antenatal_progress_table = exam_details ? exam_details : []; 

   // ultrasound report
   //const ultrasound_report = antenatal_data['ultrasound_report'] ?  antenatal_data['ultrasound_report'] : [];  

   // get the risk of sgbv details
   const check_sg = sgbv_risk ? sgbv_risk : [];
   const check_sgbv = check_sg.length > 0 ? check_sg[0] : {};
   const isRisk = check_sgbv['is_sgbv'] ? check_sgbv['is_sgbv'] : '';
   const other_risks = check_sgbv['others'] ? check_sgbv['others'] : '';
   const specific_sgbv = check_sgbv['specific_sgbv'] ? check_sgbv['specific_sgbv'] : '';   

   const pelvic_str = convertSmallCapsToBig(pelvic_exam);
   const general_str = convertSmallCapsToBig(general_exam);
   const obsgyn_str = convertSmallCapsToBig(obs_gyn);
   const preg_comps = convertSmallCapsToBig(complications_checks);

   let family_social_history = '';
   let surgery_medications = '';
   let medical_illness = '';
   let other_medicals = '';
   let other_surgery_meds_text = '';
   let blood_transfusion_why_text = '';
   let sickle_cell = '';

   const chronic_illness = (chronic_illness_dt === null || !chronic_illness_dt.length) ? [] : chronic_illness_dt;
   chronic_illness.forEach(element => {
    if (element['family_social_history']) {
        family_social_history = convertSmallCapsToBig(element['family_social_history'])
    }
    if (element['surgery_medications']) {
        surgery_medications = convertSmallCapsToBig(element['surgery_medications'])
    } 
    if (element['medical_illness']) {
        medical_illness = convertSmallCapsToBig(element['medical_illness'])
    }
    if (element['other_medicals']) {
        other_medicals = element['other_medicals'];
    }
    if (element['other_surgery_meds_text']) {
        other_surgery_meds_text = element['other_surgery_meds_text'];
    }
    if (element['blood_transfusion_why_text']) {
        blood_transfusion_why_text = element['blood_transfusion_why_text'];
    }
    if (element['sickle_cell']) {
        sickle_cell = element['sickle_cell'];
    }
   });

    return (
        <div data-testid="display-ante-natal-details">
            <TableHeading title='Ante Natal' colSpan={2}/>
            <h6 className=' surgery-print-titles'>Profile and Support Information</h6>
            <table className="table table-sm table-borderless prescription-table" style={{tableLayout:'fixed'}}>
                <tr>
                    <td style={{width:'23%'}}><p className='surgery-report-text'><strong>ANC Visit: </strong>{anc_visit}</p></td>
                    <td style={{width:'23%'}}><p className='surgery-report-text'><strong>ANC1 Timing: </strong>{anc_timing}</p></td>
                    <td style={{width:'24%'}}><p className='surgery-report-text'><strong>Gestational Age: </strong>{gestational_age_in_days}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>Last Normal Menstruation Period: </strong>{dateStringConvert(last_normla_mps)}</p></td>
                </tr>
                <tr>
                    <td ><p className='surgery-report-text'><strong>Current ANC Visit Date: </strong>{current_anc_visit_date}</p></td>
                    <td ><p className='surgery-report-text'><strong>Client Category: </strong>{client_category}</p></td>
                    <td ><p  className='surgery-report-text'><strong>WOA: </strong>{woa_day} days : {woa_month} months</p></td>
                    <td ><p  className='surgery-report-text'><strong>Expected Date of Delivery: </strong>{dateStringConvert(expected_date_of_delivery)}</p></td>
                </tr>
                <tr>
                    <td ><p className='surgery-report-text'><strong>{`MUAC (Cm + Color Code)`} </strong>{mauc_cm_and_color_code}</p></td>
                    <td ><p  className='surgery-report-text'><strong>Parity: </strong>{parity}</p></td>
                    <td ><p className='surgery-report-text'><strong>Gravida: </strong>{gravida}</p></td>
                    <td ><p  className='surgery-report-text'><strong>First Normal Menstruation Period: </strong>{dateStringConvert(first_day_of_lnmp)}</p></td>
                </tr>
                <tr>
                    <td><p className='surgery-report-text'><strong>INR No.: </strong>{inr_no}</p></td>
                </tr>
            </table>

            <h6 className=' surgery-print-titles'>Previous Illness</h6>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width: other_medicals ? '60%' : '100%'}} colSpan={2}><p className='surgery-report-text'><strong>Medical: </strong>{medical_illness}</p></td>
                    {other_medicals ? 
                        <td colSpan={2}><p className='surgery-report-text'><strong>Other Medical Illnesses: </strong>{other_medicals}</p></td>
                    :null}
                </tr>

                <tr>
                    <td style={{width: other_surgery_meds_text ? '60%' : '100%'}} colSpan={2}><p className='surgery-report-text'><strong>Surgery Medications: </strong>{surgery_medications}</p></td>
                    {other_surgery_meds_text ?
                        <td colSpan={2}><p className='surgery-report-text'><strong>Other Surgery Medications: </strong>{other_surgery_meds_text}</p></td>
                    : null}
                </tr>

                {blood_transfusion_why_text ?
                    <tr>
                        <td style={{width:'30%'}} colSpan={2}><p className='surgery-report-text'><strong>Why Blood Transfusion: </strong>{blood_transfusion_why_text}</p></td>
                    </tr>
                : null}
            </table>
            
            <h7 className='h7-titles'>Risk of SGBV</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin"  style={{marginBottom:'2px'}}>
                <tr>
                    <td style={{width:'10%'}}><p className='surgery-report-text'><strong>Risk of SGBV: </strong> {isRisk}</p></td>
                    <td style={{width:'20%'}}><p className='surgery-report-text'><strong>If yes, specify: </strong> {specific_sgbv}</p></td>
                    <td style={{width:'35%'}}><p className='surgery-report-text'><strong>Health of Husband: </strong> {husband_partner_health}</p></td>     
                    <td style={{width:'35%'}}><p className='surgery-report-text'><strong>Other SGBV: </strong> {other_risks}</p></td>      
                </tr>
            </table>
            <table className="table table-sm table-borderless prescription-table"  style={{marginBottom:'10px'}}>
                <tr>
                    <td style={{width:'50%'}} colSpan={2}><p className='surgery-report-text'><strong>OBS/GYN: </strong>{obsgyn_str}</p></td>
                    <td colSpan={2}><p className='surgery-report-text'><strong>Family And Social History: </strong>{family_social_history}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>Menstrual And Contraceptive History</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin" style={{marginBottom:'2px'}}>
                <tr>
                    <td style={{width:'15%'}}><p className='surgery-report-text'><strong>Length of Menses: </strong>{length_of_menses}</p></td>
                    <td style={{width:'15%'}}><p className='surgery-report-text'><strong>Amount: </strong>{amount}</p></td>
                    <td ><p className='surgery-report-text'><strong>Never used Family Planning, why?: </strong>{never_used_why}</p></td>
                </tr>
            </table>
            <table className="table table-sm table-borderless prescription-table"  style={{marginBottom:'10px'}}>
                <tr>
                    <td style={{width:'50%'}}><p className='surgery-report-text'><strong>Family Planning methods ever used: </strong>{family_planning_used}</p></td>
                    <td><p className='surgery-report-text'><strong>When and why was it disconnected?: </strong>{when_why_disconnected}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>Pregnancy Complications</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td>
                        <p><strong>Pregnancy Complications: </strong>{preg_comps}</p>
                    </td>
                    <td>
                        <p><strong>Other Pregnancy Complications: </strong>{other_complication_text}</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <p><strong>Any Hospitalisation? </strong> {complications_checks.includes('hosp_yes') ? 'Yes' : complications_checks.includes('hosp_no') ? 'No' : ''}</p>
                    </td>
                    <td>
                        <p><strong>If yes, specify: </strong>{any_hospitalisation}</p>
                    </td>
                </tr>
            </table>

            <h6 className=' surgery-print-titles'>Physical Examinations</h6>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'50%'}} colSpan={2}><p className='surgery-report-text'><strong>General Examintions: </strong>{general_str}</p></td>
                    <td style={{width:'50%'}} colSpan={2}><p className='surgery-report-text'><strong>Pelvic Examinations: </strong>{pelvic_str}</p></td>
                </tr>
            </table>

            <h6 className=' surgery-print-titles'>Investigations</h6>
            <h7 className='h7-titles'>{`TB Status & eMTCT Risk Assessment`}</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>TB Status: </strong>{tb_status}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>eMTCT Risk Assessment: </strong>{emtct_risk_assessment}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>{`ART`}</h7>
            <table className="table table-sm table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>ART Code: </strong>{art_code}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Linkage Art No.: </strong>{linkage_art_no}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>Infant Arv Prophyloxis: </strong>{infant_arv_prophyloxis}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>eMTCT Status</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Woman Status: </strong></p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Initial Result: </strong>{emtct_woman_initial_result}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>STK Given: </strong>{emtct_woman_stk_given}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Partner Status: </strong></p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Age: </strong>{emtct_partner_age}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>STK Result: </strong>{emtct_partner_stk_result}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>Linked To ART</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Yes/No: </strong>{linked_to_art_yes_no}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>TFV: </strong>{linked_to_art_tfv}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Where: </strong>{linked_to_art_where}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Pre-Art No.: </strong>{linked_to_art_pre_art_no}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>ART No.: </strong>{linked_to_art_art_no}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>{`CD4, VL & WHO Clinical Stage`}</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>{`CD4 (cells/uL or Cells/mm3): `}</strong>{cd4_cells_ul_mm3}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>{`Viral Load (copies/mml): `}</strong>{virol_load_copies_mm3}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>WHO Clinical Stage: </strong>{who_clinical_stage}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>Blood Screening</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Blood Group: </strong>{blood_group}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>HB: </strong>{hb}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>Sickle Cell: </strong>{sickle_cell}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>Test Results</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Woman Status: </strong></p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Syphilis: </strong>{woman_syphilis_status}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>Hep B: </strong>{woman_hepatitis_b}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>{`Random Blood Sugar:`} </strong>{random_blood_sugar}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>BS for MPs / RDT for Malaria: </strong>{bs_for_mps_rdt_for_malaria}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>EMTCT Code: </strong>{emtct_code}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Date: </strong>{mabendazole}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Partner Status: </strong></p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Syphilis: </strong>{partner_syphilis_status}</p></td>
                    <td style={{width:'30%'}}><p  className='surgery-report-text'><strong>Hep B: </strong>{partner_hepatitis_b}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>Treatments/Interventions</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>{`Tetanus Diphtheria (Td):`} </strong>{tetanus_diphtheria}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>IPT/CTX: </strong>{ipt_ctx}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>LLIN: </strong>{llin}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Mabendazole: </strong>{mabendazole}</p></td>
                </tr>
            </table>

            <h7 className='h7-titles'>Counselling</h7>
            <table className="table table-sm table-borderless prescription-table table-top-margin">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>IYCF Option: </strong>{iycf_option}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Family Planning: </strong>{family_planning}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Maternal Nutrition Counselling: </strong>{maternal_nutrition_counselling}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>GBV Risk: </strong>{gbv_risk}</p></td>
                </tr>
            </table>

            <h6 className=' surgery-print-titles'>Risk Factors/Recommendation for Delivery</h6>
            <table className="table table-sm table-borderless prescription-table">
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>High risk Mother: </strong>{high_risk_mother}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Partner disclosure support done:</strong>{partner_disclosure_support_done}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Adherence Counseling: </strong>{adherence_counselling}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Infant feeding counseling done: </strong>{infant_feeding_counselling_done}</p></td>
                </tr>
                <tr>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Referred psychosocial groups (PSSF5G): </strong>{referred_psychosocial_group}</p></td>
                    <td style={{width:'30%'}}><p className='surgery-report-text'><strong>Niverapine Syrup for baby: </strong>{niverapine_syrup_for_baby}</p></td>
                </tr>
            </table>

            {gravida_table.length > 0 ?
                <>
                    <h6 className=' surgery-print-titles'>Previous Obstetrics History</h6>
                    <ReportPreviousObsHistoryTable prev_obstetric_history={gravida_table}/>
                </>
            : null}

            {antenatal_progress_table.length > 0 ?
                <>
                    <h6 className=' surgery-print-titles'>Ante Natal Progress Examination Table</h6>
                    <ReportAnteNatalProgressTable progress_table={antenatal_progress_table}/>                            
                </>
            :null}

            {treatment_notes ?
            <>
                <h6 className=' surgery-print-titles'>Treatment Notes</h6>
                <div>
                    <p className='surgery-report-text'>{treatment_notes}</p>
                </div>
            </>    
            : null}

        </div>
    )
}

export const GetTriageDetails = (triage) => {
    const get_metrics = triage ? triage : [];
    if (get_metrics.length > 0) {
        const metrics_titles = get_metrics[0].data.map((item) => {
        const new_dict = {
          metric_id : item.metric_id,
          metric_name : item.metric_name,
          metric_type : item.metric_type,
          uom : item.uom
        };
        return new_dict;
      })
      const percentage_metrics = parseInt(80/metrics_titles.length);
      return {metrics_titles, percentage_metrics};
    } else {
      return {metrics_titles:[], percentage_metrics:0}
    }
}

export const getMetricValues = (data_arr, value, percentage_metrics, isEdit, handleChangeValue, index) => {
    const dets = data_arr.find(item => item.metric_name === value);
    return (
        dets ?
            <td style={{textAlign:'center', width:`${percentage_metrics}%`, padding:'2px'}}>
                {isEdit ? <TextField value={dets.metric_value} type='text' onChange={(e)=>handleChangeValue(e, index, dets.metric_id)}/>:
                    <p style={{margin:'0px', padding:'0px', fontSize:'12px'}}>{dets.metric_value}</p>}

            </td>
        :
            <td style={{textAlign:'center', width:`${percentage_metrics}%`, padding:'2px'}}>
                {isEdit ? <TextField value={dets.metric_value} type='text'
                                     onChange={(e)=>handleChangeValue(e, index, dets.metric_id)}/>:
                <p style={{margin:'0px', padding:'0px', fontSize:'12px'}}>0</p>}
            </td>
    )
}


const optionFilter = (option=[], filter_type='') => (
   option.length > 0 ?  Boolean(option.filter(item=>item.value === filter_type).map(({value})=>value).join('')) :  false
)

function ReusableTreatmentHistory({patient_number, patientInfo, treatmentDetails,handleOpenResults,
                                       visit_id,triageComponent,clinicalNotesComponent,diagnosisComponent, option=[]}) {

    const {patient_name, age, gender,  address, phone} = patientInfo

    const { labResults, radiologyResults, medication,
        antenatal_data, chronic_illness_dt, visit_type, visit_date} = treatmentDetails

    const antenatalObj = antenatal_data ? antenatal_data : {};
    const chronicIllnessObj = chronic_illness_dt ? chronic_illness_dt : []
    const antenatal_length = Object.keys(antenatalObj);

    const optionValue = option ? option : [];

    const isLab = optionFilter(optionValue, 'lab')
    const isRadiology = optionFilter(optionValue, 'radiology')
    const isTriage = optionFilter(optionValue, 'triage')
    const isNotes = optionFilter(optionValue, 'notes')
    const isDiagnosis = optionFilter(optionValue, 'diagnosis')
    const isPrescription = optionFilter(optionValue, 'prescription')
    const isAntenatal = optionFilter(optionValue, 'antenatal')

    const type = {
        1:'OPD Visit',
        2:'IPD Visit',
        3:'Patient Emergency Visit'
    }

    const antenatal = (
        <DisplayAnteNatalDetails antenatal_data={antenatalObj} chronic_illness_dt={chronicIllnessObj}/>
    )

    const patientDetails = (
        <tbody>
            <tr>
                <td><span>Patient No.: </span> <span css={[textWeight]}>{patient_number}
                    </span> </td>
                <td><span>Age:</span> <span css={[textWeight]}>{age}</span></td>

            </tr>

            <tr>

                <td><span>Patient Name: </span> <span css={[textWeight]}>{patient_name}</span></td>
                <td><span>Phone No.:</span> <span css={[textWeight]}>{phone}</span></td>
            </tr>
            <tr>
                <td><span>Gender: </span> <span css={[textWeight]}>{titleCase(!gender ? "" : gender)}</span></td>
                <td><span>Address:</span> <span css={[textWeight]}>{titleCase(!address ? "" : address)}</span></td>
            </tr>
        </tbody>
    )


    const itemDetails =(
        <>
            {isTriage && triageComponent}
            {isAntenatal && antenatal_length.length > 0 ?  antenatal: null}
            {isNotes && clinicalNotesComponent}
            {isLab && labResults?.length > 0 ?
                <Spacer>
                    <PreviousLabResults {...{labResults, handleOpenResults, visit_id}}/>
                </Spacer>: null}
            {isRadiology && radiologyResults?.length > 0 ?
                <Spacer>
                    <PreviousRadiology {...{radiologyResults,handleOpenResults, visit_id}}/>
                </Spacer>
                : null}
            {isDiagnosis && diagnosisComponent}
            {isPrescription && medication?.length > 0 ?
                <Spacer>
                <PreviousPrescription {...{medication}} />
            </Spacer> :  null}
        </>

    )

    return (
            <PrintTemplate title={`${type[visit_type]}–${visit_date ? formatDateTime(visit_date):''}`} {...{patientDetails,itemDetails}}/>

    )
}
export {ReusableTreatmentHistory}
