import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useDates } from "../../Accounting/CustomHooks/useDates";
import { todayTime } from "../../Utils/ConvertDate";

export const usePreviousVisits = (actions, feedback_status) => {

    // get the current user's details
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';

    const [patientDetails, setIds] = useState({patient_number: '', visit_id: '',department_id:'', department_name:''
    ,status:0, visit_type:'', patient_name:'', age:'', gender:'', screen:''});
    const [openDialog, setOpenDialog] = useState(false);
    const [visitList, setVisitList] = useState({visits: [], total_count: 0});

    const [date, handleChangeDate] = useDates();
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('idle')
    const [activePage, setActivePage] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [isEnd, setIsEnd] = useState('idle')

    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error';
    const isResolved = status === 'resolved';
    const isEnded = isEnd === 'resolved';

    const [feedback_notes, setFeedbackNotes] = useState('');
    const [rating_value, setRatingValue] = useState(3);
    const [visit_feedback_id, setFeedbackID] = useState(null);
    const [isEdit, setEditFeedback] = useState(false);
    const [feedback,setFeedback] = useState("0")
    const current_date = todayTime()

    const handlePreviousVisitList = (event) => {
        // event.preventDefault()
        const {from_date, to_date} = date;
        const feedbackAsInteger = parseInt(feedback, 10);
        const json_data = {
           'start_date': from_date,
            'end_date': to_date,
            'feedback_status': feedback_status,
            'search': searchValue,
            'rating': feedbackAsInteger,
            'page': 1
        }
        
        setSubmitted(true);
        setStatus('pending');
        axios.post(`${config.smsUrl}/cvisit/previous_visit/list`, json_data).then(res => {
            const dt = res.data?? {};
            const d_t = dt.data?? [];
            const dt_cnt = dt.count?? 0;
            setStatus('success');
            const arr = d_t.map((item, index) => ({
                ...item,
                count: (activePage - 1) * 10 + (index + 1),
                date: item.begin_datetime,
            }))
            setSubmitted(false);
            setVisitList({visits:arr,total_count:dt_cnt});
           
           // setStatus('idle');
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            //errorMessages(err, null, actions);
            setStatus('error')
        })
    }

    const handleDateFilterSubmit = (event) => {
        event.preventDefault();
        setFeedback('0')
        handlePreviousVisitList();
    }

    // call to get the previous visits
    useEffect(() => {
        handlePreviousVisitList();
    }, [feedback_status, isResolved, feedback]);

    useEffect(() => {
        handlePreviousVisitList()
        // const interval = setInterval(()=>{
        //     handlePreviousVisitList()
        // }, 10000)
        // return()=>clearInterval(interval)
    }, [activePage, searchValue,isResolved, isEnded]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const handlePatientDetails = (item) => {
        setIds(item);
    }

    const handleOpenDialog = (item, screen) => {
        if (screen === 'view_feedback') {
            const json_data = {
                'visit_id': item['visit_id'] 
            }
            axios.post(`${config.smsUrl}/cvisit/visit_feedback/view`, json_data).then(res => {
                const dt = res.data?? [];
                const fd = dt.length > 0 ? dt[0] : {feedback:'', rating:0, id:null};
                setFeedbackNotes(fd['feedback']);
                setRatingValue(fd['rating']);
                setFeedbackID(fd['id']);
                setEditFeedback(true);
                setIds({...item, screen});
                setOpenDialog(true);
            }).catch(e => {
                logoutErrorMessage(e, null, actions);
            })
        } else {
            setOpenDialog(true);
            setIds({...item, screen});
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIds({patient_number: '', visit_id: '',department_id:'', department_name:''
        ,status:0, visit_type:'', patient_name:'', age:'', gender:'', screen:''});
        setFeedbackID(null);
        setFeedbackNotes('');
        setEditFeedback(false);
        setRatingValue(3);
    };
    
    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    // patient feedback functions

    const handleFeedbackNotesChange = (e) => {
        setFeedbackNotes(e.target.value)
    }

    const handleRatingChange = (event) => {
        setRatingValue(parseInt(event.target.value));
    }

    const handleAddFeedback = () => {

        const params = {
            "visit_id": patientDetails['visit_id'] ,
            "feedback" : feedback_notes ,
            "rating": rating_value ,
            "user_roles_id" : user_roles_id ,
        }

        axios.post(`${config.smsUrl}/cvisit/visit_feedback/add`, params).then(res => {
            const data = res.data;
            handleCloseDialog();
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })

    }


    const handleChange = (event) =>{
        // const {name, value} = event.target;
        setFeedback(event.target.value)
    }

    const handlEditFeedback = () => {

        const params = {
            "feedback" : feedback_notes ,
            "rating": rating_value ,
            visit_feedback_id
        }

        axios.post(`${config.smsUrl}/cvisit/visit_feedback/edit`, params).then(res => {
            const data = res.data;
            handleCloseDialog();
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })

    }

    const handleDownloadList = () => {
        if (visitList['total_count'] > 0) {
            const {from_date, to_date} = date;
            axios.get(`${config.smsUrl}/cvisit/previous_visit_list/excel_report`, 
                    {
                    params: {
                        'start_date': from_date,
                        'end_date': to_date,
                        'feedback_status': feedback_status,
                        download:true
                        },responseType: 'blob'
                    }
                ).then(response=>{
                const data  = response.data;
                const url = URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href =  url;
                link.setAttribute('download', `PreviousVisits_${ (feedback_status === true) ? 'With':'Without'}Feedback-${current_date}.xlsx`);
                document.body.appendChild(link);
                link.click();
            }).catch(err=>{
                logoutErrorMessage(err, null, actions);
            })
        } else {
            actions.snackbarActions.snackError("No record to download");
        }
    }

    const feedbackProps = {
        handleFeedbackNotesChange, feedback_notes, 
        rating_value, handleRatingChange, isEdit,
        handleAddFeedback, handlEditFeedback
    }

    return {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, openDialog, user_roles_id, handleDownloadList,
        visitList,  handleOpenDialog, handleCloseDialog, actions, _token, feedbackProps, date, handleChangeDate, handleChange,feedback,
        patientDetails, isLoading, isSuccess, isError, handlePatientDetails, submitted, handleDateFilterSubmit}
}