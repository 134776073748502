import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled from "@emotion/styled/macro";
import * as colors from "../../../styles/colors";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    icon:{
        width:'0.75em',
        height:'0.75em'
    },
    tableCell:{
        padding:'7px 8px'
    },
    firstCell:{
        padding:'7px 8px 7px 0'
    },
    headCell:{
        padding:'7px 8px',
        borderBottom:0,
        background:'rgb(0 0 0 / 12%)',
        fontWeight:700
    },
    openCell:{
        fontWeight:700
    }
});

const NotFoundContainer = styled.div({
    display:'flex',
    flexDirection:'column',
    justifyContent :'flex-end',
    alignItems:'center'
})

const StyledImage = styled.img({
    width:'100px',
    height:'100px'
})

const P = styled.p({
    margin:'-15px 0 0 0',
    lineHeight:2,
    fontWeight:600
})

const ActionParagraph = styled.p({
    margin:0,
    color:colors.lightBlue,
    fontWeight:600,
    cursor:'pointer',
    ':hover':{
        textDecoration:'underline'
    }
})

const BorderlessCell = styled(TableCell)({
    borderBottom:0,
    padding:'7px 8px',
})

const StyledEdit = styled(ActionParagraph)({
    textDecoration:'underline',
    fontSize:'13px',
})

const NotFound = ({children, text}) => {
    return (
    <NotFoundContainer>
        <StyledImage src="/images/examination.png" alt="clipboard"/>
        <P>{text}</P>
        {children}
    </NotFoundContainer>
)
}


function NotesRow({date, person, open, handleOpen, children, colSpan, isView}) {
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root} onClick={handleOpen} style={{cursor:'pointer'}}>
                <TableCell component="th" scope="row" className={`${classes.firstCell} ${open ? classes.openCell:''}`}>
                    {!isView ?  <IconButton aria-label="expand row" size="small" >
                        {open ? <KeyboardArrowDownIcon className={classes.icon}/> : <ChevronRightIcon className={classes.icon}/>}
                    </IconButton> : null} {date}
                </TableCell>
                <TableCell className={`${classes.tableCell} ${open ? classes.openCell:''}`}>{person}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {children}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}



function PreviousNotesTable({children, headData=[]}) {
    const classes = useRowStyles();
    return (
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {headData.map((item, index)=>(
                            <TableCell key={index} className={classes.headCell}>{item.name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {children}
                    {/*{rows.map((row) => (*/}
                    {/*    <Row key={row.name} row={row} />*/}
                    {/*))}*/}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function BorderLessTable({children}) {
    return (
        <Table aria-label="borderless table">
            <TableBody>
                <TableRow>
                    {children}
                </TableRow>
            </TableBody>
        </Table>
    )
}
export {PreviousNotesTable, NotesRow, NotFound, BorderlessCell, BorderLessTable, ActionParagraph, StyledEdit}
