import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import { logoutErrorMessage } from "../../../../Helpers/ErrorMessages";


function useRefundedBills(actions, billStatus) {
    const [activePage, setActivePage] = useState(1);
    const [term, setTerm] = useState('');
    const [billingList, setBillingList] = useState([])
    const [status, setStatus] = useState('idle');
    const [total_count, setTotalCount] = useState(0);


    useEffect(() => {
        if(billStatus !== 2){
            return;
        }
        const formData = new FormData();
        formData.append('search', term);
        formData.append('status', 1);
        formData.append('patient_type', 1);
        setStatus('pending')
        axios.post(`${config.smsUrl}/cbilling/refund_list/${activePage}`, formData).then(res=>{
            const data = res.data;
            const bills = !data ? {} : data;
            const all_bills = !bills.bill_list ? [] : bills.bill_list;
            const count = !bills.total_count ? 0 : bills.total_count;
            setBillingList(all_bills);
            setTotalCount(count)
            setStatus('success')
          }).catch(err=>{
            logoutErrorMessage(err,null,actions)
            setStatus('error')
          })

    }, [activePage, term, billStatus])


    const handleChangeActivePage = (page) => {
        setActivePage(page)
    };

    const handler = (event) => {
        setTerm(event.target.value)
    }


    const isLoading =  status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error';

    const refundProps = {isLoading, isSuccess, isError,billingList, activePage,handleChangeActivePage,
        handler, total_count, term}

    return {refundProps}
    
}

export {useRefundedBills}