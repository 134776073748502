import {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import { exportPDF } from "../../../Utils/downloadPDF";

export const useDeathReport = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('sl');
    const [loading, setLoading] = useState(false);
    const [deaths, setList] = useState([]);
    const [total_count, setCount] = useState(0);
    const [activePage, setActivePage] = useState(1)
    const [patient_death_id, setId] = useState('');
    const [openDelete, setOpenDelete] = useState(false);
    const [allRecords, setAllRecords] = useState([])
    const csvRef = useRef();
    const {actions} = props;


    useEffect(() => {
        const formData = new FormData();
        formData.append('search', searchValue)
        axios.post(`${config.smsUrl}/creport/deathList/${activePage}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const st = !dt.death_list ? [] : dt.death_list;
            const count = !dt.total_count ? 0 : dt.total_count;
            setCount(count)
            setList(st);
            setLoading(false);

        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, [activePage, searchValue]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value)

    };

    const handleChangePage = (page) => {
        setActivePage(page)
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleOpenDelete = (_id) => {
        setOpenDelete(true)
        setId(_id)
    };

    const handleCloseDelete = () => {
        setOpenDelete(false)
    };

    const handleDelete = () => {
        const {actions} = props;
        const formData = new FormData();
        formData.append('patient_death_id', patient_death_id);
        axios.post(`${config.smsUrl}/creport/deleteDeath`, formData).then(() => {
            const allDeaths = deaths.filter(death => death.patient_death_id !== patient_death_id);
            setList(allDeaths);
            actions.snackbarActions.snackSuccess('Death deleted successfully');
            setOpenDelete(false)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    };

    const retrieveAllRecords = () => {
        const formData = new FormData();
        formData.append('search', searchValue)
        return axios.post(`${config.smsUrl}/creport/deathList/${activePage}`, formData)
    }

    const downloadPdf = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.death_list ? [] : dt.death_list;
            const itemList = items.map(item => ({
                patient_name: `${item.patient_firstname} ${item.patient_lastname}`,
                nationality:item.nationality,
                patient_type:item.patient_type,
                date_of_death:item.date_of_death,
                time_of_death:item.time_of_death,
                cause_of_death:item.cause_of_death,
                doctor_name: `${item.doctor_firstname} ${item.doctor_lastname}`
            }))
            exportPDF("Death Report", [["SL", "Patient", "Nationality", "Patient Type", "Date of Death",
                    "Time of Death", "Diagnosis", "Reported by"]],
                itemList.map((item, index) => [index += 1, item.patient_name, item.nationality,
                    item.patient_type === 1 ? 'IPD' : 'OPD', item.date_of_death,
                    item.time_of_death, item.cause_of_death, item.doctor_name
                ]),
                "Death Report.pdf");
            setAllRecords(items);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    
    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = !dt.death_list ? [] : dt.death_list;
            setAllRecords(items);
            csvRef.current.link.click()
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {
        searchValue, order, orderBy, loading, deaths, total_count, activePage, patient_death_id,
        openDelete, closeSnackbar, handleSearch, handleChangePage, handleRequestSort,
        handleOpenDelete, handleCloseDelete, handleDelete, csvRef, downloadCsv,downloadPdf
    }
}