import {useEffect, useState} from "react";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import { convertPhone } from "../../Utils/convertPhone";
import { useAddPrescription } from "../../DoctorsModule/Prescription/hooks/useAddPrescription";

export const useAddAdmissionSurgery = (actions, patient_number, match, surgery_notes) =>{
    const [state, setState] = useState({admission_date: dateConvert(),
        first_name: "", last_name: "",  address: '', nin: ''
    });

    const [showNumber, setShowNumber] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [printDialog, setOpenPrintDialog] = useState(false);
    const [ids, setIds] = useState({patient_admission_id: '', visit_id: match ? match.visit_id : '', patient_name:''});
    const [patient_name, setPatientName] = useState('');
    const [contact, setContact] = useState('')
    const [relationship, setRelationship] = useState('');
    const [openAdmission, setAdmissionDialog] = useState(false);

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const department_id = !_token.department_id ? "" : _token.department_id;

    const [findings, setAdmissionNotes] = useState('');

    const { 
        addProps
    } = useAddPrescription(actions, match);

    const {
        others, other, prescription,
    } = addProps;

    useEffect(()=>{
        if (!patient_number){
            return;
        }

        const formData = new FormData()
        formData.append('patient_number', patient_number);
        axios.post(`${config.smsUrl}/cvisit/getpatientdetailsby_patient_number`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                const {first_name, last_name} = data;
                setPatientName(`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`)
                setAdmissionNotes(surgery_notes ? surgery_notes : '');
            }).catch(error => {
            errorMessages(error,null, actions)
        })
        setShowNumber(false);
    },[actions, patient_number, surgery_notes]);

    const handleChange = event => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
    };

    const handleChangeRelationship = value =>{
        setRelationship(value)
    }

    const handleReset = () => {
        setState({
            admission_date: "",
            first_name: "",
            last_name: "",
            address: '',
            nin: ''
        });
        setContact('');
        setRelationship('');
        setState({admission_date: dateConvert(),
            first_name: "", last_name: "",  address: '', nin: ''
        });
        setAdmissionNotes('');
    };

    const handleSubmit = event => {

        event.preventDefault();
        const {admission_date, first_name,  address, nin} = state;
        const formData = new FormData();
        formData.append('patient_number', patient_number);
        formData.append('patient_type', 2);
        formData.append('department_id', department_id);
        formData.append('first_name', first_name);
        formData.append('relationship', relationship ? relationship['value'] : '');
        formData.append('phone_no', contact ?  convertPhone(contact):contact);
        formData.append('address', address);
        formData.append('nin', nin);
        formData.append('user_roles_id', user_roles_id);
        formData.append('visit_id', match.visit_id);
        // adding the prescription and the admission notes
        formData.append('findings', findings);

        // check if prescription data is present
        const arr = prescription?.every(item => item.product_id || (other && !item.product_id));

        if (arr) {

            formData.append('bill_type', 3);

            prescription?.filter(item => Boolean(item)).filter(drug => drug.product_id).forEach(prod => {
                formData.append('product_id', prod.product_id);
                formData.append('dosage', prod.dosage);
                formData.append('number_of_days', prod.number_of_days);
                formData.append('key_information', prod.key_information);
                formData.append('rate', prod.rate);
                formData.append('frequency', prod.frequency);
            })

            // add other prescribed drugs to the formData.
            others?.filter(item=>Boolean(item)).filter(drug => drug.other_medicine || drug.dosage || drug.price || drug.instructions ||
                drug.frequency || drug.number_of_days).forEach(prod => {
                formData.append('other_medicine',prod.other_medicine);
                formData.append('dosage', prod.dosage);
                formData.append('number_of_days', prod.number_of_days);
                formData.append('key_information', prod.key_information);
                formData.append('rate', prod.rate);
                formData.append('frequency', prod.frequency);
            })
        }

        setSubmitted(true);
        if (patient_number && admission_date && contact && first_name && relationship && address) {

            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cadmission/add_admission`, formData).then(res => {
                setIsSubmitted('resolved');
                const data = res.data;
                const dt = !data ? {} : data;
                if(dt.message === "Already Exists"){
                    actions.snackbarActions.snackError("Patient already admitted");
                }else{
                    const patient_admission_id = !dt.patient_admission_id ? "" : dt.patient_admission_id;
                    const visit_id = !dt.visit_id ? "" : dt.visit_id;
                    setIds({patient_admission_id,visit_id, patient_name})
                    actions.snackbarActions.snackSuccess("Patient admission registered successfully");                  
                }

                handleReset();
                setSubmitted(false);
                setIsSubmitted('submitted');
                handleCloseAdmission();

            }).catch(err => {
                setSubmitted(false);
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };

    const handleCloseAdmission = () => {
        setAdmissionDialog(false);
    }

    // hooks for the admission notes
    const handleChangeNotes = (event) => {
        const _note = event.target.value;
        setAdmissionNotes(_note);
    }

    const isPending = isSubmitted === 'pending';
    const checkSubmissionAdmit = isSubmitted === 'submitted';


    const admissionProps ={state, showNumber, submitted, prescription, other,
        patient_name, handleChange, handleReset, patient_number, setSubmitted, setIsSubmitted, isSubmitted,
         handleSubmit, contact,setContact, isPending, relationship, handleChangeRelationship, findings, handleChangeNotes,
         checkSubmissionAdmit, handleCloseAdmission, openAdmission, setAdmissionDialog,
         addProps}

    return {admissionProps}
}