import React from "react";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from './../Utils/smsTitle';
import { TableCell, TableBody, TableRow } from "@material-ui/core";
import CustomTable from "../Utils/Tables/CustomTable";
import { usePastSurgeries } from './hooks/usePastSurgeries';
import { dateStringConvert } from "../Utils/ConvertDate";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import {SubHeader} from "../../Containers/SubHeader";
import { PrintSurgeryReport } from "./PrintSurgeryReport";
import { DDOFilterComponent } from './DDOFilterComponent';
import { usePrint } from "../Utils/Templates/usePrint";

// what do it do?
const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Operation Date'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    {id: 'department', numeric: false, disablePadding: false, label: 'Department'},
    {id: 'operation_class', numeric: false, disablePadding: false, label: 'Operation Class'},
    {id: 'surgeryPerformed', numeric:false, disablePadding:false, label: 'Surgery Performed'},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action'},
];


/**
 * Component to display table for past surgeries
 */
const PastSurgeries = ({actions, snackbar, visitModule=false}) => {

    const {
        isPending, isRejected, isResolved,
        searchValue, activeTablePage, ddoFilterProps,
        isError, isLoading, isSuccess, setSurgeryList,
        handleSearch, handleChangeNewPage, openDialog, handleOnAfterPrint,
        surgeryList, ids, handleOpenDialog, handleCloseDialog, 
        submitted, handleRequestSort, order, orderBy, departments
    } = usePastSurgeries(actions);

    const {
        currentstate, handleSubmitFilter, handleFilterChange, submittedDDOFilter
    } = ddoFilterProps;

    const {
        start_date, end_date, filter_by, department, filter_operation_class,
    }  = currentstate;

    const {surgeries, total_count} = surgeryList;

    const isFilterByDate = filter_by === '1';
    const isFilterByDepartment = filter_by === '2';
    const isFilterByOperationClass = filter_by === '3';

    const {componentRef, handlePrint}  = usePrint(`SurgeryReport_${ids.patient_number}`, handleOnAfterPrint);

    return (
        <div>
            <PageTitle title="Past Surgeries List"/>

            {visitModule === false ? 
                <SubHeader title="Surgery" subTitle="Past Surgery List" >
                    <img src="/images/surgery-21.png" alt="surgery" className="icon-resize surgery-icon-tabs"/>
                </SubHeader> : null
            }

            <CustomDialog title={'Preview Surgery Report'} open={openDialog} handleClose={handleCloseDialog} maxWidth="md" isPrint={true} printSurgery={handlePrint}  isContentOverflow={false}>
                <div className="surgery-report" ref={componentRef}>
                    <PrintSurgeryReport patientSurgeryDetails={ids}/>
                </div>
                
            </CustomDialog>

            <DDOFilterComponent
                isFilterByDate={isFilterByDate} filter_by={filter_by} start_date={start_date} end_date={end_date}
                submitted={submittedDDOFilter} handleChange={handleFilterChange} handleSubmit={handleSubmitFilter} 
                isPending={isPending} department={department} isFilterByDepartment={isFilterByDepartment} isFilterByOperationClass={isFilterByOperationClass}
                filter_operation_class={filter_operation_class} departments={departments}
            />

            <CustomTable  headData={headData} handler={handleSearch} term={searchValue} searchPlaceHolder={"Search by Patient Name"} title="Past Surgeries"
                data={surgeries} colSpan={headData.length} customPage records={10} activePage={activeTablePage} filter={true}
                handleChangeNextPage={handleChangeNewPage} total_count={total_count} handleRequestSort={handleRequestSort} 
                order={order} orderBy={orderBy} >
                <TableBody>
                    {isLoading ? <TableRow></TableRow> : null}
                    {isSuccess ?  surgeries.length > 0 ? 
                        surgeries.map((item,idx) => {
                            return (
                                <TableRow hover role ="checkbox" key={item.visit_id} tabIndex={-1} className="mui-table-row">
                                    <TableCell>{((activeTablePage - 1) * 10) + idx + 1}</TableCell>
                                    <TableCell>{dateStringConvert(item.operation_date)}</TableCell>
                                    <TableCell>{item.patient_number}</TableCell>
                                    <TableCell>{item.patient_name}</TableCell>
                                    <TableCell>{item.surgery_department}</TableCell>
                                    <TableCell>{item.operation_class}</TableCell>
                                    <TableCell>{item.surgery_name}</TableCell>
                                    <TableCell>
                                        <button onClick={()=>handleOpenDialog(item, 'preview')}
                                        className="btn btn-sm sms-info-btn mr-1">View</button>
                                    </TableCell>
                                </TableRow>
                            )
                        }) : 
                        <TableRow>
                            <TableCell colSpan={headData.length} align="center">
                                No records found
                            </TableCell>
                        </TableRow> 
                        : null
                    }
                    {/** Error handling */}
                    {isError ? 
                        <TableRow>
                            <TableCell colSpan={headData.length} align="center">
                                The server did not return a successful response
                            </TableCell>
                        </TableRow>
                    : null}
                </TableBody>
            </CustomTable>
        </div>
    )
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PastSurgeries);