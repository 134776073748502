/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {useEffect, useState} from 'react'
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate } from '../../Utils/Templates/PrintTemplate';
import { history } from '../../../Helpers/history';
import { usePrint } from '../../Utils/Templates/usePrint';
import { formatDateTime } from '../../Utils/ConvertDate';
import { titleCase } from '../../Users/addUser';
import Card from '@material-ui/core/Card'
import { faNotesMedical } from '@fortawesome/free-solid-svg-icons/faNotesMedical';
import axios from "axios";
import { config } from '../../../Helpers/env';
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import { useTheme } from '@emotion/react';
import { table, smallUpperCase, thinborderedThead} from '../../../styles/tableStyles';

const textWeight = css`
    font-weight:600;
`

const splitSpanNotes = (txt) =>{
    const nt = txt ? txt : '';
    return nt.split('\n').map(text=><p style={{marginTop:'6px', marginBottom:'6px', marginLeft:'0px', }}><span>{text}</span></p>)
}

const headData = [{name: "Drug Name"}, {name: "Dosage"},{name:"Frequency"},{name:"Number of Days"}, {name: "Instructions"}];

function AdmissionFormTemplate({actions,match}) {
    const {patient_admission_id} = match.params;
    const [details, setDetails] = useState({})
    useEffect(() => {
        const formData = new FormData();
        formData.append('patient_admission_id', patient_admission_id ? patient_admission_id : '');
        axios.post(`${config.smsUrl}/cadmission/retrive_admission_by_id`, formData)
            .then(res => {
                const details = res.data;
                const data = !details ? {} : details;
                setDetails(data)
            }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }, [patient_admission_id]);

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    
    const patient_details = details.patient_info ? details.patient_info : {};
    const findings = details.findings ? details.findings : [];
    const prescription = details.prescription ? details.prescription : [];

    const handleOnAfterPrint = () => {
        history.push(`/waitinglist`);
    }

    const {componentRef, handlePrint, getPageMargins}  = usePrint(`AdmissionForm_${patient_details.patient_number}`, handleOnAfterPrint);

    const patientDetails = (
        <tbody>
            <tr>
                <td><span>Admission No.: </span> <span><strong>{patient_details.patient_admission_id ? patient_details.patient_admission_id:''}</strong></span></td>
                <td colSpan={4}><span>Admission Date: </span> <span css={[textWeight]}>{patient_details.admission_date ? formatDateTime(details.admission_date):''}</span></td>
            </tr>
            <tr>
                <td><span>Patient Number: </span> <span css={[textWeight]}>{patient_details.patient_number}</span></td>
                <td><span>Patient Name: </span> <span css={[textWeight]}>{`${!patient_details.patient_firstname ? "" : patient_details.patient_firstname} 
                                    ${!patient_details.patient_lastname ? "" : patient_details.patient_lastname}`}</span> </td>
            </tr>
            <tr>
                    <td><span>Gender: </span> <span css={[textWeight]}>{titleCase(patient_details.patient_gender)}</span></td>
                    <td><span>Age:</span> <span css={[textWeight]}>{patient_details.age}</span></td>
            </tr>
            <tr>
                    <td><span>Contact: </span> <span css={[textWeight]}>{patient_details.patient_phone_no ? patient_details.patient_phone_no:''}</span></td>
                    <td><span>Address:</span> <span css={[textWeight]}>{patient_details.patient_address}</span></td>
            </tr>
            <tr>
                {/*<td><span>Next of Kin: </span> <span css={[textWeight]}>{`${!patient_details.kin_first_name ? "" : patient_details.kin_first_name} 
                                    ${!patient_details.kin_last_name ? "" : patient_details.kin_last_name}`}</span></td>*/}
                <td><span>Guardian Name: </span> <span css={[textWeight]}>{`${!patient_details.guardian_name ? "" : patient_details.guardian_name}`}</span></td>                    
                <td><span>Guardian Contact: </span> <span css={[textWeight]}>{patient_details.guardian_phone_no}</span></td>
            </tr>
            <tr>
                    <td><span>Guardian Address: </span> <span css={[textWeight]}>{patient_details.guardian_address}</span></td>
                    <td><span>Relationship:</span> <span css={[textWeight]}>{patient_details.relationship}</span></td>
            </tr>
        </tbody>
    )

    const admissionNotes = (
        <>
            {findings.length > 0 ? 
                <>
                    <table css={[table]}>
                        <thead css={[thinborderedThead]}>
                            <tr>
                                <td css={[smallUpperCase]} align='left'><strong>ADMISSION NOTES:</strong></td>
                            </tr>
                        </thead>
                        <tbody>
                            {findings.map((notes, index) => (
                                <tr key={index}>
                                    <td>{splitSpanNotes(notes.findings)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            : null}       
        </>
    )


  const prescriptionDetails = (
    <>
        {prescription.length > 0 ?
            <div className="lab-test-result-table">
                <table className="table table-sm table-borderless">
                    <thead>
                    <tr className={`parameter-row ${palette.type === 'dark' && 'dark-parameter-row'}`}
                        id="parameter-row">
                        {headData.map((cell, index) => (
                            <td key={index}><p style={{margin:'5px'}}
                                className={palette.type === 'dark' && 'dark-theme-color'}><strong>{cell.name}</strong></p></td>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                        {prescription.map((drug, index) => (
                            <tr key={index}>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p style={{margin:'4px'}}>{drug.product_name}</p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p style={{margin:'4px'}}>{drug.dosage}</p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p style={{margin:'4px'}}>{drug.frequency}</p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p style={{margin:'4px'}}>{drug.number_of_days}</p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p style={{margin:'4px'}}>{drug.key_information}</p></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>   
        : null}
    </>
  )

  return (
      <div>
         <PageTitle title="Admission Form"/>
            <SubHeader title="Visit" subTitle="Admission form">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
        <MiniCenteredDiv>
         <RightAlignedContainer>
          <Button variant='orange' onClick={handlePrint}>Print</Button>
        </RightAlignedContainer>
        <Card className='mt-2 p-3'>
            <div ref={componentRef}>
                <style>
                    {getPageMargins()}
                </style>
                <PrintTemplate title='Admission Form' {...{patientDetails,admissionNotes,prescriptionDetails}}/>
            </div>
        </Card>
    </MiniCenteredDiv>
    </div>
  )
}

export default AdmissionFormTemplate
