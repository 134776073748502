import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {dateConvert} from "../../Utils/ConvertDate";
import axios from 'axios';
import { config } from '../../../Helpers/env'
import {errorMessages} from "../../../Helpers/ErrorMessages";

export const useInpatientTreatment = (actions, match, handleCloseRecordDialog, prescribed_drug, setPrescribedDrug, handleRecordTreatment) =>{

    const {patient_admission_id, visit_id} = match;
    const initialState = [{id: uuidv4(), date: dateConvert(),patient_medication_detail_id:'', product_name: "", dosage: "", route: "",
        time: "", comment: "", instructions:"",  product_id:'', rate:0, quantity:'', quantity_given:'', quantity_billed:''}]
    const [data, setData] = useState(initialState);
    const [drugs, setDrugs] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [showDetails, setShowDetails] = useState(1);
    const [current_product_id, setCurrentProdctID] = useState('');
    const [itemCost, setItemCost]  = useState({});
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;


    useEffect(() => {
        const formData = new FormData();
        formData.append('prescription_detial_id', prescribed_drug['prescription_detial_id']);
        axios.post(`${config.smsUrl}/cmedicationround/get_dosage_by_drug_id`, formData).then(response => {
            const res = response.data;
            const dt = res ? res : {};
            const {dosage, price, quantity_administered, product_id} = dt;
            let new_item = prescribed_drug;
            new_item['dosage'] = dosage;
            new_item['rate'] = price;
            new_item['quantity_administered'] = quantity_administered;
            setPrescribedDrug(new_item);
            setCurrentProdctID(product_id);
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    }, []);

    
    useEffect(()=>{
        if (!current_product_id) {return;}
        const params = {visit_id:parseInt(visit_id), item_id:current_product_id, item_type:'product'};
        axios.post(`${config.smsUrl}/cbilling/item_cost`, params).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const actual_dt = dt.data ?? {};
            setItemCost(actual_dt);
        }).catch(err=>{
            errorMessages(err, null, actions)
        })
    },[current_product_id]);

    const handleChangeDrug = (value, idx) =>{
        const allRows = [...data]
        allRows[idx]['product_name'] = value;
        const formData = new FormData();
        const arr = value.value.split('-');
        allRows[idx]['product_id'] = arr.slice(0, 1).join(' ');
        allRows[idx]['quantity_given'] = value.quantity_given;
        allRows[idx]['quantity_billed'] = value.quantity_billed;
        const id = arr.slice(1).join(' ');
        allRows[idx]['prescription_detial_id'] = id;
        formData.append('prescription_detial_id', id);
        axios.post(`${config.smsUrl}/cmedicationround/get_dosage_by_drug_id`, formData).then(response => {
            const res = response.data;
            const dt = res ? res : {};
            const {dosage, instructions, price, quantity_administered} = dt;
            allRows[idx]['dosage'] = dosage;
            allRows[idx]['rate'] = price;
            //allRows[idx]['instructions'] = instructions;
            allRows[idx]['quantity_administered'] = quantity_administered;
            setData(allRows);
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    }

    const handleChange = (event, id='') => {
        const {name, value} = event.target;
        if (id) {
            const arr = data.map(item => {
                if (item.id === id) {
                    return {...item, [name]: value}
                }
                return item
            })
            setData(arr)
        } else {
            // set details for specific drug
            const new_item = {...prescribed_drug, [name]:value};
            setPrescribedDrug(new_item);
        }
    };

    const handleAddRow = () =>{
        const row = {id: uuidv4(), date: dateConvert(),patient_medication_detail_id:'', drug: "", dosage: "", route: "",
            time: "", comment: "", instructions:"", prev_date:'',prev_drug:'', quantity:''}
        setData([...data, row])
    }

    const removeRow = (item_id) =>{
        const arr = data.filter(({id})=>id !== item_id)
        setData(arr)
    }

    const handleSubmitTreatment = (event) =>{
        event.preventDefault();
        const arr = prescribed_drug.time && prescribed_drug.product_name && prescribed_drug.quantity
                    && (
                        (prescribed_drug.quantity > 0) && (prescribed_drug.quantity <= (prescribed_drug.quantity_given - prescribed_drug.quantity_administered) )
                    )
           
        setSubmitted(true)
        
        if (arr){
            setIsSubmitted('pending');
            const params = {
                patient_admission_id,
                prescribed_by:user_roles_id,
                visit_id: parseInt(visit_id),
                bill_type:3,
                prescription_id : prescribed_drug.prescription_id,
                bill_details:[{
                    rt: prescribed_drug.route??'',
                    intake_time:prescribed_drug.time,
                    instructions: prescribed_drug.comment ? prescribed_drug.comment : '',
                    dosage: prescribed_drug.dosage,
                    product_id: parseInt(prescribed_drug.product_id),
                    rate: parseInt(prescribed_drug.rate),
                    quantity:parseInt(prescribed_drug.quantity),
                    prescription_detial_id: parseInt(prescribed_drug.prescription_detial_id),
                    already_administered: prescribed_drug.quantity_administered,
                    ...itemCost
                }]
            }
            axios.post(`${config.smsUrl}/cmedicationround/save_treatment`, {...params}).then(()=>{
                actions.snackbarActions.snackSuccess('Treatment recorded successfully')
                setIsSubmitted('resolved')
                setData(initialState)
                handleCloseRecordDialog();
                handleRecordTreatment();
            }).catch(err=>{
                errorMessages(err, null,actions)
                setIsSubmitted('rejected')
            })
        }

    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    return {data, handleChange,  closeSnackbar, handleAddRow, drugs, handleChangeDrug,
    handleSubmitTreatment, submitted,isSubmitted, showDetails, setShowDetails, removeRow}
}
