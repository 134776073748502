import React from "react";
//import Card from "@material-ui/core/Card";
import {useTheme} from "@material-ui/core/styles";
import {titleCase} from "../Users/addUser";
import ReceiptTemplate from "../Utils/ReceiptTemplate";
import {formatDateTime} from "../Utils/ConvertDate";


export const splitNotes = (txt) =>{
    const nt = txt ? txt : '';
    return nt.split('\n').map(text=><p style={{margin:'6px 0'}}>{text}</p>)
}

export const splitConclusion = (txt) =>{
    const nt = txt ? txt : '';
    return nt.split('\n').map(text=><ul style={{marginBottom:0, padding:'8px 0', border:0}}><li style={{listStyle:'square', marginLeft:'20px'}}>
        <span>{text}</span></li></ul>)
}
const RadiologyReport = ({patient_info, scan_result}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {
        patient_firstname, doctor_firstname, patient_lastname, doctor_lastname,
         gender, address, time_requested,age, radiologist_name
    } = patient_info;
    const {hospital_scan_name,  notes, conclusion, indication, } = scan_result;

    
    return (
            <ReceiptTemplate>
                <div className={`patient-lab-report ${palette.type === 'dark' && 'dark-patient-lab-report'}`}
                     id="patient-radiology-header-report">
                    <table className="table table-sm table-borderless">
                        <tbody>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Patient Name:
                                    <strong>{`${!patient_firstname ? "" : patient_firstname} ${!patient_lastname ? "" : patient_lastname}`}</strong></p>
                                </td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Date: <strong>{time_requested ? formatDateTime(time_requested) : ''}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Age: <strong>{age}</strong></p></td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Requested By:
                                    <strong>{`${!doctor_firstname ? "" : doctor_firstname} ${!doctor_lastname ? "" : doctor_lastname}`}</strong></p>
                                </td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                    <p>Gender: <strong>{titleCase(gender)}</strong></p>
                                </td>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}>
                                    <p>Radiologist: <strong>{radiologist_name}</strong></p></td>
                            </tr>
                            <tr>
                                <td className={palette.type === 'dark' && 'dark-theme-color'}><p>Address: <strong>{address}</strong></p></td>
                                <td/>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="patient-radiology-report">
                <table className="table table-borderless table-sm" >
                    <tbody>
                    <tr>
                        <td  align='center'><h5>{`${hospital_scan_name ? hospital_scan_name.toUpperCase() : ''} REPORT`}</h5></td>
                    </tr>
                    <tr>
                        <td  ><p><strong>EXAMINATION: </strong> {hospital_scan_name}</p></td>
                    </tr>
                    <tr>
                        <td  style={{paddingBottom:10}}><p><strong>INDICATION: </strong>{indication ? indication : ''}</p></td>
                    </tr>
                    <tr/>
                    <tr>
                        <td  style={{paddingBottom:0}}><p><strong>Findings</strong></p></td>
                    </tr>
                    <tr>
                        {/*splitNotes(notes)*/}
                        <td style={{paddingBottom:20}}>{splitNotes(notes)}</td> {/*/ <- changed it to this to fix the pushing of text to the next page when printing. */}
                    </tr>

                    {conclusion ? <tr>
                        <td  style={{paddingBottom:10}}><p><strong>Conclusion</strong></p></td>
                    </tr> : null}
                    {conclusion ? <tr>
                        <td  >{splitConclusion(conclusion)}</td>
                    </tr>: null}
                    <tr>
                        <td colSpan="2">
                            <div className="text-right radiology-findings">
                            </div>
                        </td>
                        <td/>
                    </tr>
                    </tbody>
                </table>
                </div>
            </ReceiptTemplate>
    )
};
export default RadiologyReport;
