import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {MainSearchComponent} from "../MainSearch";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {useLabRadiologyDetailedReport} from "../CustomHooks/useLabandRadiologyDetailedReport";
import {useAccountingStyles} from "../../Accounting/Expenses/hooks/useAccountingStyles";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";


const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
    // {id: 'test', numeric: false, disablePadding: false, label: 'Test'},
    {id: 'count', numeric: false, disablePadding: false, label: 'count'},
];

const positivesData =  [
    {id: 'date', numeric: false, disablePadding: false, label: 'Date'},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.'},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name'},
];



const LabReportDetails = ({actions, snackbars,match}) => {
    const {hospital_test_id, name, positives} = match.params
    const table_url = positives ? 'get_total_positives_test_details':'get_number_of_lab_test_details'
    const url = positives ? 'creport/qualitative_tests':'claboratory/lab_test_list'
    const {tableData, options,loadingOptions, search_type, text, textType, year, date, handleChangeActivePage,
        handleCustomSearch, isSubmitted, submitted, customSearch, order, orderBy, loading, total_count,
        activePage, closeSnackbar,  handleRequestSort, inputType, handleChangeSearch, handleChangeDate,
        handleChangeYear, handleIncrementYear, handleSubmit, optionValue,
        handleChangeOption,title,covertTimeTaken} = useLabRadiologyDetailedReport(actions, {table_url,options_url:url}, {hospital_test_id,name},'lab')
    const classes = useAccountingStyles();
    const {start_date, end_date} = date;
    const {openBar, type, message} = snackbars;
    const data = tableData?.test ? tableData?.test : [];


    return (
        <div className='journals'>
            <PageTitle title="Lab Test Details"/>
            <SubHeader title="Reports" subTitle="Lab Test Details">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <div className='mb-2'>
                    <BackButton text='Lab Reports' to='/reports/numberoftestsreports'/>
                </div>

                <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                     handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                     changePeriod={handleChangeSearch} end_date={end_date}
                                     handleChangeValue={handleChangeYear} submitted={submitted}
                                     search_type={search_type} handleChangeYear={handleIncrementYear}
                                     start_date={start_date} year={year} inputType={inputType}/>
            </div>
            <div className="mui-tables mt-3">

                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <div className={classes.customSelect}>
                    <Form.Group as={Row} style={{marginBottom:'.4rem'}}>
                        <Label name='Select Test:' sm={2} htmlFor='optionvalue' column style={{textAlign:'right'}}/>
                        <Col sm={5}>
                            <CustomSelect value={optionValue} options={options} onChange={handleChangeOption}
                                  isDisabled={loadingOptions==='pending'} id='optionvalue'
                                  placeholder={loadingOptions==='pending' ? 'Fetching tests....':loadingOptions === 'resolved' ? 'Select test...':'Select...'}/>
                        </Col>
                    </Form.Group>
                </div>
                <CustomTable title={`${title} Report`} handleChangeNextPage={handleChangeActivePage} term={customSearch}
                             records={10} headData={positives ? positivesData: headData} handler={handleCustomSearch} total_count={total_count}
                             activePage={activePage} handleRequestSort={handleRequestSort} data={data} colSpan={5} order={order}
                             orderBy={orderBy} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan={positives ? "3":"4"} align="center"><img src="/images/listPreloader.gif"
                                                                   alt="loader" style={{width:'5%'}}/></TableCell>
                    </TableRow></TableBody> : data.length > 0 ?

                        <TableBody>
                            {stableSort(data, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    const count  = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{count}</TableCell>
                                            <TableCell>{covertTimeTaken(positives ? item.date_created : item.time_taken)}</TableCell>
                                            <TableCell>{item.patient_number}</TableCell>
                                            <TableCell>{`${item.first_name} ${item.last_name}`}</TableCell>
                                            {!positives ?   <TableCell>{item.sum}</TableCell>:null}
                                        
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan={positives ? "3":"4"}  align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>

            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(LabReportDetails);
