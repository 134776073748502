import React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as snackbarActions from "../../actions/snackbarActions";
import {SubHeader} from "../../Containers/SubHeader";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import {faPrescriptionBottle} from "@fortawesome/free-solid-svg-icons/faPrescriptionBottle";
import * as prescriptionActions from "../../actions/PrescriptionActions/PrescriptionActions"
import {withTheme} from "@material-ui/core/styles";
import { formatDateTime} from "../Utils/ConvertDate";
import {Container} from "../Utils/styledComponents";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { history } from "../../Helpers/history";
import { usePrescriptionList } from "./CustomHooks/usePrescriptionList";
import FillPrescription from "./FillPrescription";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import { ReturnPrescription } from "./ReturnPrescription";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";

const headData = [
    {id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_number', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone', numeric: false, disablePadding: false, label: 'Phone',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor Name',hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action',hidden:false}
];

export const prescriptionComponents = [{label: 'Out-Patient Prescriptions', path: '/medicalAnalysis/out-patient'},
    {label: 'In-Patient Prescriptions', path: '/medicalAnalysis/in-patient'},
    {label: 'Previous Prescriptions', path: '/medicalAnalysis/previous'},
    // {label: 'Dispense Consumables', path: '/dispenseConsumables'}
]


const PrescriptionList = ({actions, snackbars, match}) => {

    const {openBar, type, message} = snackbars;
    const {component} = match.params;
    const {term, activePage,  prescriptions,total_count, handler, 
        returnProps, handleCloseReturnDialog, handleOpenReturnDialog, returnDialog,
        handleChangeActivePage,closeSnackBar,  isLoading, isSuccess, isRejected,
         prescriptionProps, openDialog, handleOpenDialog, handleCloseDialog,
    state,handleChange, isPendingForm, handleSubmit, submitted} = usePrescriptionList(actions, component)
    // const unfilledPrescription = !prescriptions ? [] : prescriptions;
    const { handleCloseConfirmationDialog, submissionReturnCall, confirmDialog } = returnProps;
    
    // const data = allRecords.map(item => ({
    //     date_created: dateStringConvert(item.date_created),
    //     patient_number: item.patient_number,
    //     patient_name: `${item.patient_firstname} ${item.patient_lastname}`,
    //     doctor_name: `${item.doctor_firstname} ${item.doctor_lastname}`
    // }));
    // const headers = [{label: 'Date', key: 'date_created'}, {label: 'Patient No', key: 'patient_number'},
    //     {label: 'Patient Name', key: 'patient_name'}, {label: 'Doctor Name', key: 'doctor_name'}]

    const buttonText = {
        'out-patient':'Dispense',
        'in-patient':'Dispense',
        'previous':'View'
    }


    const unfilledPrescription = prescriptions.map((unfilled, index)=>{
        const action = {
            4: (
                    <button type="button" onClick={() =>handleOpenDialog(unfilled)} className="btn btn-sm sms-info-btn mr-1 mb-2">View</button>

            ),
            8:( <button type="button" onClick={() =>handleOpenReturnDialog(unfilled)} className="btn btn-sm sms-amber-btn mr-1 mb-2">Receive Remaining Stock</button>)
        }
        const count = ((activePage - 1) * 10) + index + 1;
        return {
            ...unfilled,
            count,
            date:formatDateTime(unfilled.date_created),
            phone:unfilled.patient_Phone_number,
            kin_phone_no:unfilled.patient_kin_Phone_number,
            action: (
                <>
                    {unfilled.status !== 4 ? <button type="button" onClick={() =>handleOpenDialog(unfilled)} className={`btn btn-sm mb-2 ${buttonText[component] === 'View' ?
                        'sms-info-btn' : 'sms-btn'}  mr-1`}>{buttonText[component]}</button>:null}
                    {action[unfilled.status]}
                </>

            )

        }
    })



    const title = {
        'out-patient':'Out-Patient Prescriptions',
        'in-patient':'In-Patient Prescriptions',
        'previous':'Previous Prescriptions'
    }

    const dialogText = {
        'out-patient':'Dispense Drug(s)',
        'in-patient':'Dispense Drug(s)',
        'previous':'Dispensed Drug(s)'
    }
    const {start_date, end_date} = state;
    const isPrevious = component === 'previous'

    const {isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm} = prescriptionProps

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);
    return (
        <div id="prescription-list" className='journals'>
            <PageTitle title={title[component]}/>
            <SubHeader title="Pharmacy" subTitle={title[component]}>
                <FontAwesomeIcon icon={faPrescriptionBottle}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            {/* <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-md-10 mb-1"/>
                    <div className="col-md-2 mb-1">
                        <DataExportButton data={data} title='Prescription List' csvRef={csvRef}
                                          exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                    </div>
                </div>
                handleClose,handleClick,message,text, handleCancel,
            </div> */}
            <PrintDialog openDialog={confirmDialog} handleClose={handleCloseConfirmationDialog} handleCancel={handleCloseConfirmationDialog} 
            text="Would you like to save the return?" handleClick={submissionReturnCall} message="Confirm return"/>
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title={dialogText[component]} maxWidth="xl"  isContentOverflow={false}>
                <FillPrescription {...prescriptionProps}/>
            </CustomDialog>
            <CustomDialog open={returnDialog} handleClose={handleCloseReturnDialog} title={'Return Remaining Stock'} maxWidth="xl"  isContentOverflow={false}>
                <ReturnPrescription {...returnProps}/>
            </CustomDialog>
            <MainSnackbar handleCloseBar={closeSnackBar} open={openBar} message={message} variant={type}/>
            <Container>
                <ReusableRouteTabs value={history.location.pathname} tabHeading={prescriptionComponents}/>
                {isPrevious ? <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending:isPendingForm, type:'date'}}/>:null}
                <CustomTable headData={headCells} title='' data={unfilledPrescription}
                             customPage handleChangeNextPage={handleChangeActivePage}
                             records={10} total_count={total_count} activePage={activePage} term={term}
                             id='search-prescription' handler={handler} colSpan={headCells.length}
                             {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                >
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? unfilledPrescription.length > 0 ? unfilledPrescription.slice(0, 10)
                                .map((unfilled, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{unfilled[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isRejected ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server didnot return a valid response</TableCell>
                            </TableRow>:null}
                        </TableBody> 
                </CustomTable>
            </Container>
        </div>
    );

}

function mapStateToProps(state) {
    return {prescriptions: state.prescriptions, snackbars: state.snackbar};
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            prescriptionActions: bindActionCreators(prescriptionActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    };
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(PrescriptionList));
