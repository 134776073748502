import React, {useState, useEffect} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase, sentenceCase} from "../../../Users/addUser";


export const useDoctorDiagnosis = (patient_number, actions, visit_id) => {
    const storage = JSON.parse(sessionStorage.getItem('diagnosis'))
    const item = storage ? storage : {}
    const note_item = item[visit_id] ? item[visit_id] : ''
    const diagNotes = () => note_item || ''
    const [state, setState] = useState({ notes:diagNotes(), date:'', isDisabled:false, diagnosis_id:''});
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [allCodes, setAllCodes] = useState([]);
    const [codes, setCodes] = useState([]);
    const [status, setStatus] = useState('disease');
    const [submitted, setSubmitted] = useState(false);
    const [showDiagnosis, setShowDiagnosis] = useState(false);
    const [disease, setDisease] = useState('');
    const [notesStatus, setNotesStatus] = useState('idle')
    const [error, setError] = useState(null);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;
    const [previousDiagnosis, setPreviousDiagnosis] = useState([]);
    const [isDiagnosis, setIsDiagnosis] = useState('')
    const [diagnosisRemoved, setDiagnosisRemoved] = useState([])
    const [isNew, setIsNew] = useState(false)
    
    const isResolved = isSubmitted === 'resolved'
    const isSuccess = notesStatus === 'success'
    useEffect(() => {
        if(isSuccess){
            return
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id)
        axios.post(`${config.smsUrl}/cdoctor/get_previous_diagnosis`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const items = dt.diagnosis ? dt.diagnosis : []
            const id = items[items.length - 1] ? items[items.length - 1] : {};
            const note = id.diagnosis_id ? id.diagnosis_id : '';
            setPreviousDiagnosis(items);
            setIsDiagnosis(note)
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }, [isResolved, notesStatus])


    // TO DOOOOOOOOOOOOOO
    // concatenate the strings in to one long note
    useEffect(() => {
        if(!(isDiagnosis && previousDiagnosis)){
            return;
        }
        const arr = previousDiagnosis.map(item => ({
            ...item, isAdded:false
        }))
        const diag_codes = arr.filter(item=>item.diagnosis_code || item.diagnosis).map(item=>({
            code:item.diagnosis_code, ...item
        }))
        const prevNotes = arr.filter(item=>item.notes).map(item => ({notes:item.notes, diagnosis_id:item.diagnosis_id}));

        const newArr = [...new Set(prevNotes)];
        const notesArr = newArr.filter(item=>Boolean(item))
        const diagnosis = notesArr ? notesArr : [];

        const id = diagnosis[notesArr.length - 1] ? diagnosis[notesArr.length - 1] : {};

        const note = id.notes ? id.notes : '';
        const diagnosis_id = id.diagnosis_id ? id.diagnosis_id : '';

        setState({...state, notes:note, isDisabled: (note !== '' || diag_codes.length > 0), diagnosis_id})
        setCodes(diag_codes)
    }, [isDiagnosis, previousDiagnosis]);


    const handleChangeState = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        const obj = {[visit_id]: value}
        sessionStorage.setItem('diagnosis', JSON.stringify({...item, ...obj}))
        setIsSubmitted('idle')
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.value)
        setDisease('')
        setShowDiagnosis(false)
    }

    const stripString = (str) => {
        let arr = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
        return arr ? titleCase(arr) : arr
    }


    const handleCreateNewDisease = () =>{
        setIsNew(true)
        setShowDiagnosis(false)
    }

    const retrieveAllCodes = (event) => {
        const {value} = event.target
        setDisease(event.target.value)
        if (event.target.value !== '' && !isNew) {
            const formData = new FormData();
            formData.append('code', status === 'code' ? value.toUpperCase() : '');
            formData.append('diagnosis', status === 'disease' ? sentenceCase(value) : '');
            axios.post(`${config.smsUrl}/cdoctor/auto_search_codes`, formData).then(res => {
                const data = res.data;
                const dt = !data ? [] : data;
                if (dt.length > 0) {
                    setAllCodes(dt);
                } else {
                    setAllCodes([{code: '', diagnosis:`No results found for ${value}`}])
                }

            }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
            setShowDiagnosis(true)
        } else {
            setShowDiagnosis(false)
        }
        setIsSubmitted('idle')
    };

    const getDetails = (event) => {
        const formData = new FormData();
        formData.append('code', status === 'code' ? event.value : '');
        formData.append('diagnosis', status === 'disease' ? event.innerText : '');
        if (event.value) {
            axios.post(`${config.smsUrl}/cdoctor/retrieve_icd_info`, formData).then(res => {
                const resp = res.data;
                let data = !resp ? {} : resp;
                data['code'] = event.value
                data['isAdded'] = true
                setCodes([...codes, data])
            }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
            setError(null)
        } else {
            setError('Please enter a valid disease or code')
        }
        setShowDiagnosis(false)

    };

    const handleAddNewDiagnosis = () =>{
        const obj = {code:'', diagnosis:disease, isAdded:true}
        setCodes([...codes, obj])
        setIsNew(false)
        setDisease('')
    }

    const cancelNewDiagnosis = () =>{
        setIsNew(false)
        setDisease('')
    }

    const removeRow = (idx) => {
        const arr = codes.filter((item, index ) => index !== idx)
        const removed =  codes.filter((item, index) => index === idx)
        setCodes(arr);
        setDiagnosisRemoved([...diagnosisRemoved,...removed])
    };

    const handleSubmitNotes = (event) => {
        event.preventDefault();

        // get the notes and their diagnosis id
        const {notes, diagnosis_id} = state;

        // get the icd codes
        // get the ones with the valid code and diagnosis
        const filteredArr = codes.filter(item => item.diagnosis && item.code);
        // confirm that some items in filteredArr has an id
        const check_codes = filteredArr.some(item => item.diagnosis_id);

        const newDiseases = codes.filter(item=>item.code === '')

        // const diagnosisArr = filteredArr.map((item, index)=>{
        //     if (index === 0){
        //         return {...item,disease:newDiseases}
        //     }
        //     return item
        //
        // })

        

        // check if there were already existing data for the notes and codes
        const params = (diagnosis_id || check_codes) ? {
            notes_diagnosis_object: {
                diagnosis_id,
                notes
            },
            visit_id,
            diagnosis: [...filteredArr, ...newDiseases].filter(item=>!item.isAdded).map(({isAdded,...item })=>item),
            // disease:newDiseases,
            new_diagnosis:  [...filteredArr, ...newDiseases].filter(item=>item.isAdded).map(({isAdded, ...item})=>({...item})),
            removed_diagnosis: diagnosisRemoved.map(({isAdded, ...item})=>({...item})).filter(item=>item.diagnosis_id)
        } : {
            visit_id,
            notes,
            diagnosis: [...filteredArr, ...newDiseases].map(({isAdded,...item })=>item),
            // disease:newDiseases
        };

        setSubmitted(true);
        if (notes || (filteredArr.length > 0)) {
            setIsSubmitted('pending');
            const url = (diagnosis_id || check_codes) ? 'update_diagnosis': 'save_diagnosis';
            axios.post(`${config.smsUrl}/cdoctor/${url}`, params ).then(() => {
                actions.snackbarActions.snackSuccess(`Diagnosis ${(diagnosis_id || check_codes) ? 'updated': 'saved'} successfully`)
                setIsSubmitted('resolved');
                setCodes([]);
              //  setState({notes: '', date:'', isDisabled:false, diagnosis_id:''})
                setSubmitted(false)
                setNotesStatus('idle')
                setDisease('')
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
        }
    };

    const handleContinue = () =>{
        setState({...state, isDisabled:false})
        setNotesStatus('success')
    }

    const handleReset = () =>{
        setState({notes:'', date:'', isDisabled:false})
        setCodes([])
        setNotesStatus('success')
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    

    return {
         isSubmitted, doctor_name, handleChangeState, handleSubmitNotes, closeSnackbar, state,
        codes, allCodes, removeRow, retrieveAllCodes, showDiagnosis, getDetails, isResolved,
        status, handleChangeStatus, submitted, disease, error, handleContinue,handleReset,
        isNew, handleCreateNewDisease, handleAddNewDiagnosis, cancelNewDiagnosis
    }
}