import React from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../Utils/smsTitle";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {history} from "../../Helpers/history";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import { useAdmissionList } from "../Billing/in-patient/hooks/useAdmissionList";
import {dateStringConvert} from "../Utils/ConvertDate";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { LoadingGif } from "../Utils/Loader";
import FollowUpDateFilter from "../Utils/FollowUpDateFilter";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Container } from "../DoctorsModule/doctorStyles";



const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'patient_admission_id', numeric: false, disablePadding: false, label: 'Admission ID', hidden:false},
    {id: 'admission_date', numeric: false, disablePadding: false, label: 'Admission Date', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'discharge_date', numeric: false, disablePadding: false, label: 'Discharge Date', hidden:false},
    {id: 'follow_up_date', numeric: false, disablePadding: false, label: 'Follow-up Date', hidden:false},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', hidden:false},
  
 
];

const DischargeList = ({actions, snackbars}) => {
    const {searchValue,  order, orderBy, activePage, admissionList:admission_list, isLoading,
        isSuccess,isError, handleSearch, handleRequestSort,handleClick, handleChangeNextPage, closeSnackbar,
        state,handleChangeOption,handleChangeDate , handleSubmit
    } = useAdmissionList(actions,'cvisit/discharge_list');
    
   
    const {openBar, type, message} = snackbars;
    const components = [{label: 'Admitted Patients', path: `/patientroundlist`},
        // {label: 'Lab/Radiology Reports', path: `/medicationresults`},
        {label: 'Discharged Patients', path: `/dischargelist`}];
    const obj = admission_list ? admission_list : {};
    const dischargeList = !obj.discharge_list ? [] : obj.discharge_list;
    const total_count = !obj.total_count ? 0 : obj.total_count;

    const admissionList = dischargeList.map((item, index)=>{
        const count  = ((activePage - 1) * 10) + index + 1;
        return {
            ...item,
            count,
            admission_date:item.admission_date ? dateStringConvert(item.admission_date):null,
            patient_name: `${!item.p_first_name ? "" : item.p_first_name} ${!item.p_last_name ? "" : item.p_last_name}`,
            phone_no:item.p_phone_no,
            discharge_date: item.discharge_date ?  dateStringConvert(item.discharge_date) : null,
            follow_up_date:item.followup_date ? dateStringConvert(item.followup_date):null,
            action:(
                <VisibilityIcon onClick={() => handleClick(item.visit_id)} />
            )
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const {from_date, to_date} = state
    return (
        <div data-testid="admission-list" className='journals'>
            <PageTitle title="Discharged Patients"/>
            <SubHeader title="Medication and Rounds" subTitle="Discharged patients">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>

            <Container>
                <div className="mui-tables">
                    <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                    <FollowUpDateFilter handleChangeDate={handleChangeDate} handleSubmit={handleSubmit} from_date={from_date} to_date={to_date} handleChangeOption={handleChangeOption} isLoading={isLoading} />
                    {/* <div className="text-right">
                        <button onClick={handleRefresh} className="btn btn-sm sms-info-btn mb-1">Refresh</button>
                    </div> */}
                    <CustomTable title="Discharged Patients" customPage
                                handleChangeNextPage={handleChangeNextPage}
                                headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                                activePage={activePage}
                                handleRequestSort={handleRequestSort} data={admissionList} colSpan={headCells.length} order={order}
                                orderBy={orderBy} term={searchValue}
                                 {...{all_hidden, handleAllHeadCells, handleHeadCells}}>
                            <TableBody>
                                {isLoading ? <TableRow>
                                    <TableCell colSpan={headCells.length} align="center"><LoadingGif/></TableCell>
                                </TableRow>:null}
                                {isSuccess ? admissionList.length > 0 ?  stableSort(admissionList, getComparator(order, orderBy))
                                    .slice(0, 10)
                                    .map((item, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                                className='mui-table-row'
                                            >
                                                {headCells.map((head)=>(
                                                    <TableCell id={head.id}  key={head.id}
                                                               hidden={head.hidden}>{item[head.id]}</TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    }):<TableRow>
                                    <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                                {isError ? <TableRow>
                                    <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                                </TableRow>: null}
                            </TableBody>
                    </CustomTable>
                </div>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DischargeList);
