import * as React from 'react'
import {CompanyProvider} from "./company-context";
import {SnackbarProvider} from "./snackbar-context";
import {DoctorProvider} from "./doctor-context";


function AppProviders({children}) {
    return (
        <SnackbarProvider>
            <CompanyProvider>{children}</CompanyProvider>
        </SnackbarProvider>

    )
}

export {AppProviders}
