import React, {useEffect} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container, RightAlignedContainer} from "../../Utils/styledComponents"
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {CustomTableCell, MuiPaper, StickyHeadTable} from "../../Utils/Tables/StickyHeaderTable";
import {LoadingGif} from "../../Utils/Loader";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {Link} from "react-router-dom";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {AssetAccountingDetails, AssetDetails, AssetDetailsTemplate} from "./AssetDetailsTemplate";
import {formatDate} from "../../Utils/ConvertDate";




const headData = [
    {id: 'asset_name', numeric: false, disablePadding: false, label: 'Asset name'},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date purchased'},
    {id: 'cost', numeric: false, disablePadding: false, label: 'Purchase cost'},
    {id: 'current_value', numeric: false, disablePadding: false, label: 'Current value'},
    {id: 'depreciation_rate', numeric: false, disablePadding: false, align:'center', label: 'Depreciation rate(%)'},
    {id: 'action', numeric: false, disablePadding: false, label: 'Action', align:'center'},
];



const FixedAssetList = ({actions, snackbars}) => {
    const [data, setData] = React.useState({assets:[], loading:'idle'});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openDepreciation, setOpenDepreciation] = React.useState(false)
    const [openView, setOpenView] = React.useState(false)
    const [asset_id, setAssetId] = React.useState('')
    const [isDeprecate, setIsDeprecate] = React.useState('idle')
    const [assetDetails, setAssetDetails] = React.useState({})
    const {assets, loading} = data

    const isDepreciateResolved = isDeprecate === 'resolved'

    useEffect(() => {
        setData({...data, loading: 'pending'})
        axios.get(`${config.smsUrl}/cinventory/fixed_asset/list`).then(res => {
            const response = res.data;
            const dt = response ? response : {};
            const arr = dt.data ? dt.data : []
            const dataArr = arr.map(item=>({...item,id:item.id}))
            setData({assets: dataArr, loading: 'success'})
        }).catch(err => {
            errorMessages(err, null, actions)
            setData({...data, loading: 'error'})

        })
    }, [isDepreciateResolved])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleOpenViewDialog = (item) =>{
        const state = {...item, warranty:item.waranty_period, serial_number:item.serial_no,date:formatDate(item.date_purchased)}
        const assetValue = {...item,assetAccount:{label:item.asset_account_name},
            supplier:{label:item.supplier_name}, depreciationExpense:{label:item.depreciated_expenses},
            depreciationAccount:{label:item.accumulated_depriciation},paymentAccount:{label:''}}
        setAssetDetails({state, assetValue})
        setOpenView(true)
    }

    const handleCloseViewDialog = () =>{
        setOpenView(false)
    }


    const handleOpenDepreciationDialog = (id) =>{
        setAssetId(id)
        setOpenDepreciation(true)
    }

    const handleCloseDepreciationDialog = () =>{
        setOpenDepreciation(false)
    }

    const handleDepreciateAsset = () =>{
        setIsDeprecate('pending')
        const formData = new FormData()
        formData.append('fixed_asset_id', asset_id)
        axios.post(`${config.smsUrl}/accounting/fixed_asset/depreciate`, formData).then(() => {
            setIsDeprecate('resolved')
            actions.snackbarActions.snackSuccess('Asset depreciation recorded')
            setOpenDepreciation(false)
        }).catch(err => {
            errorMessages(err, null, actions)
            setIsDeprecate('rejected')

        })
    }


    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };



    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'



    const {openBar, type, message} = snackbars;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, assets.length - page * rowsPerPage);

    const isDeprecatePending = isDeprecate === 'pending'



    const buttons = (
        <>
            <button type='button' onClick={handleDepreciateAsset} disabled={isDeprecatePending} className='btn btn-sm sms-info-btn mr-4'>{isDeprecatePending ? 'Saving...' : 'Depreciate'}</button>
            <button type='button' onClick={handleCloseDepreciationDialog} className='btn btn-sm sms-gray-btn'>Cancel</button>
        </>
    )



    return (
        <div>
            <PageTitle title="Fixed Assets"/>
            <SubHeader title="Accounting" subTitle="Fixed Assets">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <CustomDialog buttons={buttons} open={openDepreciation} title='Depreciate Asset' handleClose={handleCloseDepreciationDialog}>
                <p>You are about to depreciate this asset. Would you like to continue?</p>
            </CustomDialog>

            <CustomDialog open={openView} title="View asset details" handleClose={handleCloseViewDialog} maxWidth="md">
              <AssetDetailsTemplate>
                    <AssetDetails {...assetDetails}/>
                    <AssetAccountingDetails {...assetDetails}/>
                </AssetDetailsTemplate>
            </CustomDialog>
            <Container>
                <RightAlignedContainer>
                    <Link to='/accounting/newfixedasset'>
                        <button type='button' className="btn btn-sm sms-info-btn mb-3">New Asset</button>
                    </Link>
                </RightAlignedContainer>
                <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
                <MuiPaper>
                <StickyHeadTable {...{width:'100%',page,rowsPerPage,handleChangePage,handleChangeRowsPerPage
                    ,data:assets, columns:headData.filter(item=>Boolean(item))}}>
                    {isLoading ? <TableRow><TableCell align='center' {...{colSpan:headData.length}}><LoadingGif/></TableCell></TableRow>:null}
                    {isSuccess ? assets.length > 0 ?
                        <>
                            {assets.map((item, index) => {
                                return (
                                    <TableRow   tabIndex={-1} key={item.id}>
                                        <CustomTableCell className='mui-table-cell'>
                                            {item.asset_name}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {formatDate(item.date_purchased)}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {item.asset_cost}
                                        </CustomTableCell>
                                        <CustomTableCell>
                                            {item.current_value}
                                        </CustomTableCell>
                                        <CustomTableCell align='center'>
                                            {item.depreciation_rate}
                                        </CustomTableCell>
                                        <CustomTableCell align='right'>
                                            <button type='button' onClick={()=>handleOpenDepreciationDialog(item.id)}
                                                    className='btn btn-sm sms-info-btn small-btn mr-3 mb-2'>Depreciate</button>

                                            <button onClick={()=>handleOpenViewDialog(item)} type='button'
                                                    className='btn btn-sm sms-info-btn small-btn mr-3 mb-2'>View</button>
                                        </CustomTableCell>

                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 43 * emptyRows }}>
                                    <TableCell colSpan={headData.length} />
                                </TableRow>
                            )}
                        </>:<TableRow><TableCell align='center'  {...{colSpan:headData.length}}>No records found</TableCell></TableRow>:null}
                    {isError ? <TableRow><TableCell align='center'  {...{colSpan:headData.length}}>The server did not return a valid response</TableCell></TableRow>:null}
                </StickyHeadTable>
                </MuiPaper>

            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FixedAssetList);
