import React, { useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {convDate} from "../../../Utils/ConvertDate";
import CustomDialog from "../../../Utils/Dialogs/CustomDialog";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import TextField from "../../../Utils/FormInputs/TextField";
import {ErrorMessage} from "../../../Utils/styledComponents";
import styled from "@emotion/styled/macro";
import * as mq from "../../../../styles/media-queries";
import {BlockNavigationComponent} from "../../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../../Helpers/history";


export const FormField = styled.div({
    [mq.large]:{
        width:'550px'
    }
})

const useReceiveStock = (props) =>{
    const {actions,  setOpenDialog} =props
    const [invoice, setInvoice] = useState('');
    const [products, setProducts] = useState([{ product_id:'',
        quantity:'',
        product_purchase_details_id:'',
        expiry_date:'', batch_id:''}]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [search, setSearch] = useState(false);
    const [isSearch, setIsSearch] = useState('idle')
    const [product_purchase_id, setId] = useState('')
    const [status, setStatus] = useState('')
    const [openConfirm, setOpenConfirm] = useState(false)
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;




    const handleChangeInvoice = (event) =>{
        setInvoice(event.target.value)
        setIsBlocking(true)
    }

    const viewReceivedStock = ()=>{
        const formData = new FormData();
        formData.append('invoice_id', invoice);
        if(invoice){
            setIsSearch('pending')
            axios.post(`${config.smsUrl}/cinventory/purchase_info_form`, formData).then(res => {
                const data = res.data;
                const all_data = !data ? {}: data;
                const prods = all_data.purchase_info ? all_data.purchase_info: [];
                const arr = prods.map(item=>({
                    ...item,expiry_date:item.expiry_date ? convDate(item.expiry_date) : '',
                    received_stock:item.recieved_stock ? item.recieved_stock :'' ,
                    batch_id:item.batch_id ? item.batch_id : '',

                }))
                const id = all_data.product_purchase_id ? all_data.product_purchase_id : ''
                const purchase_status = all_data.purchase_status  ? all_data.purchase_status : ''
                setStatus(purchase_status)
                setProducts(arr);
                setIsSearch('resolved')
                setId(id)
            }).catch(e => {
                logoutErrorMessage(e, null, actions)
                actions.snackbarActions.snackError(e.response.data.message)
                setIsSearch('rejected')
            })
        }
    }


    const handleSearchInvoice = () =>{
        setSearch(true)
        viewReceivedStock()

    }

    const handleChangeproduct = (event,product_id) =>{
        const {name, value} = event.target
        const arr = products.map(item=>{
            if(item.product_id === product_id){
                return { ...item, [name]:value}
            }
            return item
        })
        setProducts(arr)
        setIsBlocking(true)
    }

    const handleSaveDraft = () =>{
        const prods = products.map(item=>({
            product_id:+item.product_id,
            quantity:+item.received_stock,
            product_purchase_details_id: +item.product_purchase_details_id,
            expiry_date:item.expiry_date,
            batch_id:item.batch_id
        }))
        setIsSubmitted('pendingDraft')
        setIsBlocking(false)
        axios.post(`${config.smsUrl}/cpharmacy/receive_medicine_draft`,{products:prods , user_roles_id, invoice_no:invoice, product_purchase_id, to_destination:2}).then(()=>{
            actions.snackbarActions.snackSuccess('Draft saved Successfully');
            setIsSubmitted('resolvedDraft')
            viewReceivedStock()
        }).catch(err=>{
            errorMessages(err, null, actions)
            setIsSubmitted('rejectedDraft')
        })
    }


    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        const arr = products.every(item=>item.product_id && item.expiry_date && item.received_stock !== '' && item.batch_id)
        const prods = products.map(item=>({
            product_id:+item.product_id,
            quantity:+item.received_stock,
            product_purchase_details_id: +item.product_purchase_details_id,
            expiry_date:item.expiry_date,
            batch_id:item.batch_id
        }))
        setIsBlocking(false)
        if(invoice && arr){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cpharmacy/receive_medicine`, {products:prods , user_roles_id, invoice_no:invoice, product_purchase_id, to_destination:2}).then(()=>{
                setSubmitted(false);
                actions.snackbarActions.snackSuccess('Items received successfully')
                setOpenDialog(false)
                setIsSubmitted('resolved')
                setInvoice('')
                setProducts([])
                setOpenConfirm(false)
            }).catch(err=>{
                setIsSubmitted('rejected')
                errorMessages(err, null, actions)
            })
        }

    }

    const handleOpenConfirm = (event) =>{
        event.preventDefault();
        setSubmitted(true)
        setIsBlocking(false)
        const arr = products.every(item=>item.product_id && item.expiry_date && item.received_stock && item.batch_id)
        if(invoice && arr){
            setOpenConfirm(true)
        }
    }

    const handleCloseConfirm = () =>{
        setOpenConfirm(false)
    }


    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false);
        setSubmitted(false)
        setInvoice('')
        setProducts([])
    }

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const isSearching = isSearch === 'pending';
    const isSearchSuccess = isSearch === 'resolved';
    const isSearchError = isSearch === 'rejected';

    const isPendingDraft = isSubmitted === 'pendingDraft'

    // const stockProps = {products, submitted,  handleSubmit, closeSnackbar, invoice, search,
    //     handleSearchInvoice, handleChangeInvoice, handleChangeproduct, isPending, isRejected, isResolved,
    // isSearching, isSearchSuccess, isSearchError, status, isPendingDraft, handleSaveDraft, openConfirm, handleOpenConfirm,
    //     handleCloseConfirm,isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm}

    const isValid = status === 1;
    const isInvalid = status === 2;

    const buttons = (
        <>
            <button type='button' id="continue-receive-items"  onClick={handleSubmit} className='btn btn-sm sms-info-btn'>Continue</button>
            <button type='button' onClick={handleCloseConfirm} className='btn btn-sm sms-gray-btn'>No</button>
        </>
    )

    // return {openDialog,setOpenDialog, isResolved,stockProps, setInvoice}

    return {
        isResolved,
        isRejected,
        closeSnackbar,
        setOpenConfirm,
        setOpenDialog,
        isBlocking,
        setIsBlockDialog,
        handleResetForm,
        receiveStock:(
            <div>
                <BlockNavigationComponent
                    when={isBlocking}
                    shouldBlockNavigation={()=>{
                        return isBlocking;
                    }}
                    navigate={path=>history.push(path)}
                    dialogClose={isBlockDialog}
                    setIsBlockDialog={setIsBlockDialog}
                    clearForm={handleResetForm}
                />
                <CustomDialog title='Confirmation' open={openConfirm} handleClose={handleCloseConfirm} buttons={buttons}>
                    <p>This action is irreversible, Are you sure you want to continue?</p>
                </CustomDialog>
                <form autoComplete="off" onSubmit={handleOpenConfirm}>
                    <FormField>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                                <Label name='Invoice'  type htmlFor="invoice"/>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                                <TextField value={invoice} name="invoice" onChange={handleChangeInvoice}  id="invoice"/>
                                {search && !invoice ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <button type='button' onClick={handleSearchInvoice} className='btn btn-sm sms-info-btn' disabled={isPending}>{isSearching ? 'Searching...': 'Search'}</button>
                            </Col>
                        </Form.Group>
                    </FormField>
                    {isSearchSuccess && isValid &&  products.length > 0 ? <div className="table-responsive">
                        <table className="table table-bordered table-sm profile-table strike-table">
                            <thead>
                            <tr>
                                <td>Product</td>
                                <td>Quantity Purchased</td>
                                <td>Batch#</td>
                                <td>Expiry Date</td>
                                <td>Received Quantity</td>

                            </tr>
                            </thead>
                            <tbody>
                            {products.map((item, index) => {
                                return (
                                    <tr key={item.product_id}>
                                        <td>{item.product_name}</td>
                                        <td>{item.quantity}</td>
                                        <td><TextField type="text" submitted={submitted} name="batch_id" value={item.batch_id}
                                                       onChange={(e)=>handleChangeproduct(e, item.product_id)} data-testid={`batch_id-${index}`}/>
                                            {submitted && !item.batch_id? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                        </td>
                                        <td><TextField type="date" data-testid={`expiry_date-${index}`} submitted={submitted} name="expiry_date" value={item.expiry_date}
                                                       onChange={(e)=>handleChangeproduct(e, item.product_id)}/>
                                            {submitted && !item.expiry_date ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                        </td>
                                        <td><TextField type="number" data-testid={`received_quantity-${index}`} submitted={submitted} name="received_stock" value={item.received_stock}
                                                       onChange={(e)=>handleChangeproduct(e, item.product_id)}/>
                                            {submitted && !item.received_stock ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>:null}
                    {isSearchError ? <p>Failed to load products</p>: null}
                    {isInvalid ? <p>Stock already received for this invoice, please input another invoice</p>: null}
                    {products.length > 0 && isValid ?
                        <>
                            <button type="button" onClick={handleSaveDraft} disabled={isPendingDraft} className="btn btn-sm sms-info-btn px-4 mr-4"  id="submit_stock_draft">
                                {isPending ? "Saving...":"Save Draft"}</button>
                            <button type="submit" disabled={isPending} className="btn btn-sm sms-btn px-4"  id="submit-received-items">
                                {isPending ? "Saving...":"Save Final"}</button>
                        </>
                        : null}
                </form>
            </div>
        )


    }
}

export {useReceiveStock}