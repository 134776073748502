import React from "react";
import Form from "react-bootstrap/Form";
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import TextField from "../../Utils/FormInputs/TextField";
import { ReusablePhoneInput } from "../../Utils/FormInputs/FormGroup";
import { ErrorMessage } from "../../Utils/styledComponents";

function NewProvider({state, handleChange, handleSubmit, contact, setContact, isPending, handleUpdateProvider, isEditProvider,
    submitted, currencies, handleChangeCurrency, currency, providerTypes=[]}) {

    const {provider_name, provider_type} = state;

    return (
        <form onSubmit={isEditProvider ? handleUpdateProvider : handleSubmit}  autoComplete="off">
            <Form.Group>
                <Label name="Provider Type" type htmlFor="provider_type"/>
                <SelectInput submitted={submitted} value={provider_type} testId="provider_type" defaultOption="Select provider type..." name="provider_type" options={providerTypes} onChange={handleChange}/>
            </Form.Group>
            <Form.Group>
                <Label name="Provider Name" type htmlFor="provider_name"/>
                <TextField submitted={submitted} value={provider_name} name="provider_name" onChange={handleChange} id="provider_name"/>
                {submitted && !provider_name ? <ErrorMessage>This field is required</ErrorMessage>:null}
            </Form.Group>
            <Form.Group>
                <Label name="Contact" htmlFor="provider_contact"/>
                <ReusablePhoneInput contact={contact} setContact={setContact} id="provider_contact"/>
            </Form.Group>
            {/*<Form.Group>*/}
            {/*    <Label name="Currency"  type htmlFor="provider_currency"/>*/}
            {/*        <SelectInput submitted={submitted} value={currency}*/}
            {/*                                     onChange={handleChangeCurrency} options={currencies}*/}
            {/*                                     defaultOption="Select..." testId='provider_currency'/>*/}
            {/*</Form.Group>*/}
            <button type="submit"  className="btn btn-sm sms-btn px-4"
                    disabled={isPending}>
                {isPending ? "Saving..." : "Save"}</button>
        </form>
    )
}

export {NewProvider}
