import {dateConvert, convDate} from "../../../Utils/ConvertDate";
import  {useRef, useState, useEffect} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";


export const useStockAdjustment = (actions) => {
    const [adjustment, setAdjustment] = useState('available')
    const type = adjustment === 'old' ? 'I':'';
    const initialState =[{product_name: '', product_id: '', available_stock: '', batches: [], expiry_date: '',
        adjustment_quantity: '', adjustment_type: type, total_stock: '', batch_id: '',
         showProduct: false, isError:false
    }]
    const [rows, setRows] = useState(initialState);
    const [products, setProducts] = useState([]);
    const [product, setProduct] = useState({product_name:'', product_id:'', selected_product:''})
    const [purchase_date, setPurchaseDate] = useState(dateConvert());
    const [openDialog, setOpenDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [allStock, setAllStock] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [isCsv, setIsCsv] = useState('idle');
    const [openStockDialog, setOpenStockDialog] = useState(false)
    const [openCsvDialog, setOpenCsvDialog] = useState(false);
    const [state, setState] = useState({reason:'',description:'', positive_reason:''})
    const [loading, setLoading] = useState('idle');
    const [location, setLocation] = useState('')

    const csvLink = useRef();
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';
    const adjusted_by = `${_token.first_name ? _token.first_name : ''} ${_token.last_name ? _token.last_name : ''}`
    const reasons = [{text:'Wastage', value: 'Wastage'},{text: 'Damage', value: 'Damage'} ,{text: 'Expiry', value: 'Expiry'},
        {text: 'Positive adjustment for', value: 'Positive adjustment for'}, {text: 'Negative adjustment for', value: 'Negative adjustment for'},
        {value:'Monthly stock take', text:'Monthly stock take'}]

    const {product_id, selected_product, expiry_limit} = product;

    const isPharmacy = location === 'pharmacy'
    const isLab = location === 'Lab'

    // get the batch ids for a specific product
    useEffect(()=>{
        if(!product_id){
            return
        }
        const formData = new FormData()
        formData.append('product_id',product_id)
        formData.append('location',isPharmacy ? 2 : isLab ? 3 : 1)
        setLoading('loading')
        const url = isPharmacy ? 'get_pharmacy_batches' : 'retrieve_product_batch_ids'

        axios.post(`${config.smsUrl}/cinventory/${url}`, formData).then(response => {
            const data = response.data;
            const dt = !data ? [] : data;
            let arr = [];
            if(isLab){
               const prod =  dt.product ? dt.product : {};
               const dispatch = prod.product_batches_in_dispatch ? prod.product_batches_in_dispatch : [];
               const purchase = prod.product_batches_in_purchase ? prod.product_batches_in_purchase : [];
               arr = [...dispatch, ...purchase]
            }else{
                arr = dt.product ? dt.product : []
            }
        if (product_id && arr.length === 0) {
            const obj = {product_name:selected_product, product_id, open:true, batches:[], expiry_limit};
            products.unshift(obj);
            setProducts(products);
            setLoading('success');
        } else {
            const btches = arr.map((item) => (item['batch_id']));

            const params = {
                product_id,
                batches : btches,
                location: isPharmacy ? 2 : isLab ? 3 : 1
            };
    
            axios.post(`${config.smsUrl}/cinventory/retrieve_many_product_batch_ids`, params).then(res => {
                const arr = res.data;
                const batchData = arr.map(item=>({
                    ...item,
                    batch_id:item.batch_id??'',
                    expiry_date:item.expiry_date??'',
                    stock:item.total_product??0,
                    physical_stock:'',
                    unit_cost:item.rate??'',
                    isBatch:true,
                }));
                const obj = {product_name:selected_product, product_id, open:true, batches:batchData, expiry_limit};
                products.unshift(obj);
                setProducts(products);
                setLoading('success');
            }).catch(err => {
                errorMessages(err, null, actions);
                setLoading('error');
            });

        }

        }).catch(error => {
            errorMessages(error, null, actions)
            setLoading('error')
        });
    },[product_id])

    const handleInput = event => {
        const {value} = event.target;
        setPurchaseDate(value);
    };

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state, [name]:value})
    }

    const handleChangeQuantity = (event, index) => {
        const {value} = event.target
        const arr = rows.map((item, idx)=>{
            if(index === idx){
                const isIncrement =  +item.available_stock + +value
                const isDecrement = +item.available_stock - +value
                const total = item.adjustment_type === 'I' ? isIncrement :
                item.adjustment_type === 'D' && item.available_stock > value ? isDecrement : 0
                return {...item, adjustment_quantity: value, total_stock:total}
            }
            return item
        })
        setRows(arr)
    };

    const handleChangeItems = (event, index) => {
        const {value, name} = event.target
        const arr = rows.map((item, idx)=>{
            if(index === idx){
                return {...item, [name]: value}
            }
            return item
        })
        setRows(arr)
    }

    const handleChangeAdjustmentType = (event, index) => {
        const {value} = event.target;
        const arr = rows.map((item, idx)=>{
            if(index === idx){
                const isIncrement =  +item.available_stock + +item.adjustment_quantity
                const isDecrement = +item.available_stock - +item.adjustment_quantity
                const total = value === 'I' ? isIncrement :
                value === 'D' && item.available_stock > item.adjustment_quantity ? isDecrement : 0
                return {...item, adjustment_type: value, total_stock:total}
            }
            return item
        })
        setRows(arr)
    };


    const handleAddRow = () => {
        const type = adjustment === 'old' ? 'I':'';
        const item = {
            product_name: '', product_id: '', available_stock: '', batches: [], showProduct: false,
            adjustment_quantity: '', adjustment_type: type, total_stock: '', batch_id: '', expiry_date: '',
        };
        setRows([...rows, item])
    };

    const handleRemoveSpecificRow = (idx) =>  {
        const arr = rows.filter((_, index)=> index !== idx)
        setRows(arr)
    };


    const handleRetrieveDrugs = (event) => {
        const val = event.target ? event.target.value : event;
        setProduct({...product,product_name:val})
        let tmp = val.trim();
        if (tmp !== '') {
            const formData = new FormData();
            formData.append('product_name', titleCase(tmp));
            axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(response => {
                const drugs = response.data;
                const dt = !drugs ? [] : drugs;
                setAllStock(dt)
            }).catch(error => {
                errorMessages(error, null, actions)
            });
        }
    };

    const handleClickDrug = (event) => {
        setProduct({product_id: event.value, product_name:'', selected_product: event.innerText, expiry_limit: event.expiry_limit});
    };

    const handleOpenDialog = (loc) => {
        setOpenDialog(true)
        setLocation(loc)
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setLoading('idle');
        setProducts([]);
        setProduct({product_name:'', product_id:'', selected_product:''});
    };

    const handleOpenStockDialog = () => {
        setOpenStockDialog(true)
    };

    const handleCloseStockDialog = () => {
        setOpenStockDialog(false)
    };

    const handleOpenCsvDialog = () => {
        setOpenCsvDialog(true)
    };

    const handleCloseCsvDialog = () => {
        setOpenCsvDialog(false)
    };



    const handleChangeAdjustment = (event) => {
        const {value} = event.target;
        setAdjustment(value)
        const type = value === 'old' ? 'I':'';
        const arr = rows.map((item, ) => ({
            product_name: item.product_name, product_id: item.product_id, available_stock: '', batches: [], expiry_date: '',
            adjustment_quantity: '', adjustment_type: type, total_stock: '', batch_id: '', showProduct: false
        }))
        setRows(arr)
    }



    const handleSubmit = event => {
        event.preventDefault();
        setSubmitted(true);
        const {reason, description, positive_reason} = state;
        let check_batch_stock = false;
        const rows = products.filter((it) => {
            return it.batches.length > 0;
        }).map((item) => {

            const batch_array = item.batches.map((ex) => {
                
                if (check_batch_stock === false && !ex.physical_stock) {
                    check_batch_stock = true;
                }

                return {
                batch_id:ex.batch_id,
                expiry_date: convDate(ex.expiry_date),
                stock:ex.stock,
                physical_stock: parseInt(ex.physical_stock),
                unit_cost:ex.unit_cost
                }
            });

            return {
                product_id : item.product_id,
                batches : batch_array
            }
        });
        const data = rows.filter(item=>item.product_id).reduce((r, a) => r.concat(a.batches.map(({ ...b}) => ({product_id:
            a.product_id,  ...b}))), []);

        const isPositiveAdjustment = reason.includes('Positive')
        const isNegativeAdjustment = reason.includes('Negative')
        const isPositiveQuantity = isPositiveAdjustment && data.some(row=> row.physical_stock >= row.stock)
        const isNegativeQuantity = !isPositiveAdjustment && data.some(row=> row.physical_stock <= row.stock)


        let dt = {
            user_roles_id, 
            reason:`${reason} ${positive_reason}`,
            description,
            date : purchase_date, 
            details: data,
            location,
            adjustment_type: isPositiveAdjustment ? 'I' : isNegativeAdjustment ? 'D' : ''
        };


        if (reason && (check_batch_stock === false) && (isNegativeQuantity || isPositiveQuantity)) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cinventory/stock_adjustment`, dt)
                .then(() => {
                    actions.snackbarActions.snackSuccess('Stock adjusted Successfully');
                    setOpenDialog(false)
                    setLoading('idle');
                    setProducts([]);
                    setProduct({product_name:'', product_id:'', selected_product:''});
                    setSubmitted(false)
                    setIsSubmitted('resolved')
                }).catch(err=>{
                errorMessages(err,null, actions)
                setIsSubmitted('rejected')
                setRows(initialState)
            })
        }
    };



    const handleRemoveProduct = (index) => {
        const arr = products.filter((_, idx) => (index !== idx));
        setProducts(arr);
        setLoading('idle');
    }

    const handleChangeItem = (event, product_index, batch_index) => {
        const {name, value} = event.target;
        const arr = products.map((item, index)=>{
            if(index === product_index){
                const arr = item.batches.map((batch, batch_idx)=>{
                    if (batch_index === batch_idx){
                        return {...batch, [name]:value}
                    }
                    return batch
                })
                return {...item, batches:arr}
            }
            return item
        })
        setProducts(arr)
    }

    const handleOpen = (idx) =>{
        const itemsArr = products.map((item, index)=>{
            if(index === idx){
                return {...item,open:!item.open}
            }else{
                return item
            }
        })
        setProducts(itemsArr)
    }

    const handleRemoveBatch = (idx, batch_idx) =>{
        const arr = products.map((item, index)=>{
            if(index === idx){
                const arr = item.batches.filter((_, batch_index)=>batch_index !== batch_idx);
                return {...item, batches:arr}
            }
            return item;
        });
        setProducts(arr);
    }

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const isCsvResolved = isCsv === 'resolved'

    const isLoading = loading === 'loading';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const adjustmentProps = {rows, products, purchase_date,  openDialog, submitted,
        allStock, csvLink, adjusted_by, handleInput, handleChangeQuantity,openStockDialog, handleOpenStockDialog,
        handleChangeAdjustmentType, handleAddRow, handleRemoveSpecificRow,handleCloseStockDialog,
        handleRetrieveDrugs, handleClickDrug, handleCloseDialog,isRejected,handleOpenDialog,
        handleSubmit,  handleChangeItems,isCsv, setIsCsv,setOpenStockDialog, setOpenDialog,
        adjustment, handleChangeAdjustment, isPending, product, handleChangeItem,
        isLoading, isSuccess, isError, state, handleRemoveProduct, handleOpen, handleRemoveBatch,
        openCsvDialog, handleOpenCsvDialog, handleCloseCsvDialog, reasons, handleChange,
    location}

    return {adjustmentProps,isResolved,isCsvResolved}
}