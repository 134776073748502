import React from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import TextField from './FormInputs/TextField';
import Label from './FormInputs/Label';
import {ErrorMessage} from "./styledComponents";
import * as mq from "../../styles/media-queries"
import styled from '@emotion/styled/macro'
import SelectInput from './FormInputs/SelectInput';



const StyledForm  = styled.form({
    [mq.extraLarge]:{
        width:'80%'
    }
})

const FollowUpDateFilter = ({ handleChangeDate, handleChange, submitted,handleSubmit,isPending, handleChangeOption, from_date, to_date, type, children, isLoading}) =>{
  const options =  [{value: 'Follow up date', text: 'Follow up date'}, {value:'Discharge date', text: 'Discharge date'}]
 
    return(
        <StyledForm onSubmit={handleSubmit}>
            <div className='row'>
                {children}
                <div className='col-lg-4'>
                  <Form.Group as={Row}>
                    <Col >
                       <SelectInput options={options} onChange={handleChangeOption} ></SelectInput>
                       </Col>
                    </Form.Group>
                    
                </div>
                <div className='col-lg-4'>
                    <Form.Group as={Row}>
                        <Label name='Start' sm={2} column type/>
                        <Col sm={7}>
                            <TextField type='date' name='from_date' value={from_date} onChange={handleChangeDate}/>
                            {submitted && !from_date ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                        </Col>
                    </Form.Group>
                </div>
                <div className='col-lg-4'>
                    <Form.Group as={Row}>
                        <Label name='End' sm={2} column type/>
                        <Col sm={6}>
                            <TextField type='date'  name='to_date' value={to_date} onChange={handleChangeDate}/>
                            {to_date < from_date ? <ErrorMessage>To date shouldn't be less than from date</ErrorMessage> :   submitted && !to_date ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                        </Col>
                        <Col sm={2}>
                            <button type='submit' className='btn btn-sm sms-info-btn' disabled={isPending}>{isPending ? 'Searching...':'Search'}</button>
                        </Col>
                    </Form.Group>
                </div>
              
            </div>
        </StyledForm>
    ) 
}
export default FollowUpDateFilter;
