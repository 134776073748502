import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../../Utils/Menu/ReusableDoctorTabs";
import {dateConvert} from "../../Utils/ConvertDate";
import {PatientInformation} from "../../DoctorsModule/PatientInfo/PatientInformation";
import { usePatientDetails } from "../../DoctorsModule/ReferralNote/usePatientDetails";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {usePreviousFindings} from "./hooks/usePreviousFindings";
import {PatientNotesContainer, Button, StyledAdd, SavedNotesContainer} from "../../DoctorsModule/Notes/PatientNotesContainer";
import {ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import {
    PreviousNotesTable,
    NotesRow,
    NotFound, StyledEdit, ActionParagraph
} from "../../DoctorsModule/Notes/PreviousNotesTable";
import {LoadingGif} from "../../Utils/Loader";
import {splitNotes} from "../../Radiology/RadiologyReport";
import Form from "react-bootstrap/Form";
import Label, {FormLabel} from "../../Utils/FormInputs/Label";
import {ResizableTextarea} from "../../Utils/FormInputs/Textarea";
import styled from "@emotion/styled"
import * as colors from "../../../styles/colors"


const Title = styled.p({
    fontSize:'1rem',
    margin:'10px 0',
    fontWeight:700,
    borderBottom:`1px solid ${colors.gray00}`,
    padding:'8px'
})

export const Paragraph = styled.p({
    margin:'0 0 5px 0'
})

const HistoryContainer = styled.div({
    marginLeft:'15px'
})

export const Radio = styled(Form.Check)({
    marginTop:'0.6rem'
})

export const FormGroup = styled(Form.Group)({
    marginTop:'0.8rem'
})

export const HistoryGroup = styled.div({
    marginBottom:'1rem'
})

const Switch = styled(Form.Check)({
    paddingLeft:'3.2rem'
})


export const SaveNotesForm = ({handleSubmit,submitted,examNotes,handleChangeState,children, diseaseComponent=null, allergyComponent= null}) =>{
    const {physical_examination, past_medical_history, past_obstetric_history,
        family_history, notes, complaints} = examNotes
    return (
        <form onSubmit={handleSubmit} style={{marginLeft:'22px', marginTop:'16px'}}>
            <div className='row'>
                <div className='col-lg-6'>
                    <Form.Group>
                        <FormLabel className="pl-0" name='Physical examination' isRequired  
                        toolTip='Enter physical examination notes ' htmlFor="physical_examination"/>
                        <ResizableTextarea submitted={submitted} rows='0'  value={physical_examination}  name='physical_examination'
                                           onChange={handleChangeState} id="physical_examination"  placeholder="Write physical examination...."/>
                        {submitted && !physical_examination ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                    </Form.Group>
                    <Form.Group>
                        <FormLabel className="pl-0" name='Past medical history'
                         toolTip='Enter past medical history ' htmlFor="past_medical_history"/>
                        <ResizableTextarea  rows='0'  value={past_medical_history}  name='past_medical_history'
                                           onChange={handleChangeState} id="past_medical_history"  placeholder="Write past medical history...."/>
                        {/*{submitted && !past_medical_history ? <ErrorMessage>This is a required field</ErrorMessage>:null}*/}
                    </Form.Group>
                    <Form.Group>
                        <FormLabel className="pl-0" name='Notes' isRequired htmlFor="notes"  toolTip='Enter notes '/>
                        <ResizableTextarea submitted={submitted} rows='0' id="notes"  value={notes}  name='notes'
                                           onChange={handleChangeState}  placeholder="Write notes...."/>
                        {submitted && !notes ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                    </Form.Group>
                    <Form.Group>
                        <FormLabel className="pl-0" name='Presenting Complaints' isRequired htmlFor="complaints"  toolTip='Enter complaints '/>
                        <ResizableTextarea submitted={submitted} rows='0' id="complaints"  value={complaints}  name='complaints'
                                           onChange={handleChangeState}  placeholder="Write presenting complaints...."/>
                        {submitted && !complaints ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                    </Form.Group>
                    {diseaseComponent}
                </div>
                <div className='col-lg-6'>
                    <Form.Group>
                        <FormLabel className="pl-0" name='Family history' htmlFor="family_history"   toolTip='Enter family history '/>
                        <ResizableTextarea  rows='0' id="family_history"  value={family_history}  name='family_history'
                                           onChange={handleChangeState}  placeholder="Write family history...."/>
                        {/*{submitted && !family_history ? <ErrorMessage>This is a required field</ErrorMessage>:null}*/}
                    </Form.Group>
                    <Form.Group>
                        <FormLabel className="pl-0" name='Past obstetric history'  htmlFor="past_obs_history"
                         toolTip='Enter past obstetric history '/>
                        <ResizableTextarea  rows='0' id="past_obs_history"  value={past_obstetric_history}  name='past_obstetric_history'
                                           onChange={handleChangeState}  placeholder="Write past obstetric history...."/>
                        {/*{submitted && !past_obstetric_history ? <ErrorMessage>This is a required field</ErrorMessage>:null}*/}
                    </Form.Group>
                    {allergyComponent}
                    {children}
                </div>
            </div>

        </form>
    )
}





const headData = [{name:'Date'},{name:'Written By'}]
const PatientFindings = ({actions, snackbars, match}) => {
    const {patient_number, visit_id, labrequest_id, scan_request_id,
         patient_type,patient_admission_id,activity_id} = match.params;

    const [patient, setPatient] = useState({patient_name: "", age: "", gender: "", address: "", phone: ""});
    const {patient: patientDetails} = usePatientDetails(actions, patient_number, '')
    useEffect(() => {
        setPatient({
            patient_name: `${!patientDetails.first_name ? "" : patientDetails.first_name} ${!patientDetails.last_name ? "" : patientDetails.last_name}`,
            age: patientDetails.Age,
            gender: patientDetails.gender,
            address: patientDetails.address,
            phone: patientDetails.phone_no
        });
    }, [patientDetails]);


    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const {previousFindings,  handleUpdateNotes, isLoading, isError,submittedNotes,
        isPendingEdit, handleEdit, handleCancelEdit, handleChangeNotes,
        isSuccess, handleOpen, addNotesProps, isAddResolved} = usePreviousFindings(actions,visit_id, patient_type,patient_number)

    const arr = doctorRoutes(patient_number, visit_id, labrequest_id, scan_request_id,
        patient_type,patient_admission_id,activity_id)
    const {openBar, type, message} = snackbars;
    const {openAddNotes,  handleOpenNotesInput} = addNotesProps


    // get the doctor's name
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;   

    const title = {
        '1':'Clinical Notes',
        '2':'Round Notes'
    }

    const containerHeading = {
        '1':'Clinical Notes',
        '2' : 'Findings'
    }
    const {isPending, handleCloseNotesInput,diseases, handleChangeDisease, submitted, handleChangeState, examNotes} = addNotesProps
    const {other_diseases, allergy, allergies} = examNotes

    const diseaseComponent = (
        <HistoryGroup>
            <Paragraph><strong>Chronic Illnesses</strong></Paragraph>
            {diseases.map((item, index)=>(
                <Radio
                    key={index}
                    label={item.disease}
                    onChange={(e)=>handleChangeDisease(e, index)}
                    type="checkbox"
                    name={item.name}
                    checked={item[item.name]}
                />
            ))}
            <FormGroup>
                <FormLabel  name='Others(specify)' toolTip='Write other previous illnesses'/>
                <ResizableTextarea rows='0' submitted={submitted} name='other_diseases' value={other_diseases} onChange={handleChangeState} placeholder='Write other illnesses.....'/>
            </FormGroup>
        </HistoryGroup>
    )

    const allergyComponent = (
        <HistoryGroup>
            <Paragraph>Allergies</Paragraph>
            <Radio
                inline
                checked={allergy === 'Yes'}
                label="Yes"
                value="Yes"
                name="allergy"
                onChange={handleChangeState}
                type="radio"
            />
            <Radio
                inline
                checked={allergy === 'No'}
                label="No"
                value="No"
                name="allergy"
                onChange={handleChangeState}
                type="radio"
            />
            {allergy === 'Yes' ?  <ResizableTextarea   rows='0' submitted={submitted} value={allergies} name='allergies'
                                                       onChange={handleChangeState} placeholder='Write other allergies.....'/>:null}
        </HistoryGroup>
    )
    return (
        <div className='journals'>
            <PageTitle title={title[patient_type]}/>
            <SubHeader title="Doctor" subTitle={title[patient_type]} doctor_name={doctor_name}>
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>

            <MainSnackbar open={openBar} handleCloseBar={closeSnackbar} variant={type} message={message}/>
            <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
                <PatientInformation patient_number={patient_number} isResolved={isAddResolved}/>
                {openAddNotes ?  <SaveNotesForm {...{...addNotesProps, allergyComponent, diseaseComponent}}>
                    <RightAlignedContainer>
                        <button type="submit" disabled={isPending}
                                className="btn sms-btn btn-sm mt-5 mr-3">{isPending ? "Saving" : "Save Notes"}</button>
                        <button type='button' onClick={handleCloseNotesInput} className="btn sms-gray-btn btn-sm  mt-5">Cancel</button>
                    </RightAlignedContainer>
                </SaveNotesForm> : null}
                <PatientNotesContainer heading={containerHeading[patient_type]} button={
                    previousFindings.length > 0 ? <RightAlignedContainer>
                        <Button onClick={handleOpenNotesInput}><StyledAdd/>Add</Button>
                    </RightAlignedContainer>: null}>
                    {isLoading ? <LoadingGif/> : null}
                    {isSuccess && previousFindings.length > 0 ?
                        <PreviousNotesTable headData={headData}>
                            {previousFindings.map(item=>{


                                const examNotes = {
                                    physical_examination:item.physical_examination, past_medical_history:item.past_medical_history, past_obstetric_history:item.past_obstetric_history,
                                    family_history:item.family_history, notes:item.notes, complaints:item.complaints
                                }

                                const updateForm = (
                                    <>
                                        <SaveNotesForm submitted={submittedNotes} handleSubmit={(e)=>handleUpdateNotes(e, item)}
                                                        handleChangeState={(e)=>handleChangeNotes(e, item.id)}
                                        examNotes={examNotes} isEdit>
                                            <button type="submit" disabled={isPendingEdit}
                                                    className="btn sms-btn small-btn btn-sm mt-2 mr-3">{isPendingEdit ? "Saving...." : "Update"}</button>
                                            <button type='button' onClick={()=>handleCancelEdit(item.id)} className="btn sms-gray-btn small-btn  btn-sm mt-2">Cancel</button>

                                        </SaveNotesForm>
                                        {/*<form onSubmit={(e)=>handleUpdateNotes(e, item)} style={{marginLeft:'22px'}}>*/}
                                        {/*    <ResizableTextarea submitted={submittedNotes} rows={6} value={item.notes}  name='notes'*/}
                                        {/*                       onChange={(e)=>handleChangeNotes(e, item.id)}  placeholder="Write findings...."/>*/}
                                        {/*    {submittedNotes && !item.notes ? <ErrorMessage>This is a required field</ErrorMessage>:null}*/}

                                        {/*</form>*/}
                                    </>

                                )
                                const viewNotes = (
                                    <div className='row mb-0'>
                                        <div className='col-lg-6'>
                                            {item.physical_examination ?
                                                <>
                                                    <Label name='Physical examination'/>
                                                     <SavedNotesContainer data-testid="p_examination">
                                                        {splitNotes(item.physical_examination)}
                                                    </SavedNotesContainer>
                                                </>: null}
                                            {item.past_medical_history ? <>
                                                <Label name='Past medical history'/>
                                                <SavedNotesContainer data-testid="medical_history">
                                                    {splitNotes(item.past_medical_history)}
                                                </SavedNotesContainer>
                                            </>: null}
                                            {item.notes ? <>
                                                <Label name='Notes'/>
                                                <SavedNotesContainer data-testid="clinical_notes">
                                                    {splitNotes(item.notes)}
                                                </SavedNotesContainer>
                                            </>: null}
                                            {item.complaints ? <>
                                                <Label name='Presenting Complaints'/>
                                                <SavedNotesContainer data-testid="complaints">
                                                    {splitNotes(item.complaints)}
                                                </SavedNotesContainer>
                                            </>: null}

                                        </div>
                                        <div className='col-lg-6'>
                                            {item.family_history ? <>
                                                <Label name='Family history'/>
                                                <SavedNotesContainer data-testid="f_history">
                                                    {splitNotes(item.family_history)}
                                                </SavedNotesContainer>
                                            </>: null}
                                            {item.past_obstetric_history ? <>
                                                <Label name='Past obstetric history'/>
                                                <SavedNotesContainer data-testid="obs_history">
                                                    {splitNotes(item.past_obstetric_history)}
                                                </SavedNotesContainer>
                                            </>: null}

                                        </div>
                                    </div>
                                )
                            return(
                                <NotesRow open={item.open} date={item.date} handleOpen={()=>handleOpen(item.id)}
                                          person={item.doctor_name} colSpan={headData.length}>
                                    {!item.displayInput ?
                                        <>
                                            {viewNotes}
                                            {item.isEdit ? <button className='btn-sm btn small-btn sms-info-btn' onClick={()=>handleEdit(item.id)}>Edit notes</button> : null}
                                        </>
                                    :null}
                                    {item.displayInput ? updateForm : null}
                                </NotesRow>
                            )})}
                        </PreviousNotesTable>:null}
                    {isSuccess &&  previousFindings.length === 0 && !openAddNotes ?
                        <NotFound text='There are no clinical notes to display for this patient' >
                            <ActionParagraph id="record_notes" onClick={handleOpenNotesInput}>Record notes</ActionParagraph>
                        </NotFound>:null}
                    {isError ? <p>The server did not return a valid response</p>:null}
                </PatientNotesContainer>
            </ReusableDoctorTabs>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PatientFindings);