import React, {useState} from "react";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {ErrorMessage, RightAlignedContainer} from "../../Utils/styledComponents";
import {PreviousVisitContainer} from "../../Utils/Lists/PreviousVisitContainer";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import {PreviousHistoryTabs} from "../../Utils/Menu/ReusableDoctorTabs";
import {AutoCompleteUnControlled} from "../../Utils/FormInputs/AutoCompleteInput";
import TextField from "../../Utils/FormInputs/TextField";
import IconButton from "@material-ui/core/IconButton";
import {StyledCell, StyledRemoveIcon} from "../../DoctorsModule/doctorStyles";
import SelectInput from "../../Utils/FormInputs/SelectInput";


export function useDispenseConsumables(actions) {
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false)
    const [visit_id, setVisitId] = useState('')





    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''
    const requested_by = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    // React.useEffect(()=>{
    //     if (!visit_id){
    //         return
    //     }
    //
    //     axios.get(`${config.smsUrl}/cpharmacy/services_with_consumables`, {params:{visit_id}}).then(res => {
    //         const data = res.data;
    //         const dt = !data ? [] : data;
    //         setData(dt);
    //     }).catch(error => {
    //         errorMessages(error, null, actions)
    //     });
    //
    // },[visit_id])


    const handleOpenService = (serv_id) =>{
        const arr = data.map((item)=> {
            if (item.service_id === serv_id) {
                return {
                    ...item,  open: !item.open,

                }
            }
            return item
        })
        setData(arr)
    }

    const handleRetrieveBatches = (service_idx,product_idx, product_id, batch_idx) =>{
        const formData = new FormData();
        formData.append('product_id', product_id);
        axios.post(`${config.smsUrl}/cinventory/get_pharmacy_batches`, formData).then(res => {
            const resp = res.data;
            const batchData = !resp ? {} : resp;
            const batches = batchData.product ? batchData.product : [];
            const batchArr = batches.map(item=>({value:item.batch_id, text:item.batch_id}))
            const arr = data.map((item, index)=>{
                if(index === service_idx){
                    return {
                        ...item,
                        consumables: item?.consumables.map((prod, prod_idx)=>{
                            if(product_idx === prod_idx){
                                return {...prod,
                                    batches:batchArr,
                                    // batchItems:prod?.batchItems.map((batch, idx)=>{
                                    //     if (batch_idx === idx){
                                    //         return {...batch}
                                    //     }
                                    //     return batch
                                    // })
                                }
                            }
                            return prod
                        })
                    }
                }
                return item
            })
            setData(arr)
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }

    const handleRetrieveBatchDetails = (event, product_idx, service_idx, batch_idx,product_id) =>{
        // console.log(event)
        const formData = new FormData();
        formData.append('product_id', product_id);
        formData.append('batch_id', event.target.value);
        let arr = data.map((item, index)=>{
            if(index === service_idx){
                return {
                    ...item,
                    consumables: item?.consumables.map((prod, prod_idx)=>{
                        if(product_idx === prod_idx){
                            return {...prod,
                                batchItems:prod?.batchItems.map((batch, idx)=>{
                                    if (batch_idx === idx){
                                        return {...batch, batch_id:event.target.value}
                                    }
                                    return batch
                                })
                            }
                        }
                        return prod
                    })
                }
            }
            return item
        })
        axios.post(`${config.smsUrl}/cinventory/get_pharmacy_stock`, formData).then(response => {
            const res = response.data;
            const batchInfo = res ? res : {};
            const t_product = batchInfo.total_product ? batchInfo.total_product : 0;
            arr = arr.map((item, index)=>{
                if(index === service_idx){
                    return {
                        ...item,
                        consumables: item?.consumables.map((prod,prod_idx)=>{
                            if(product_idx === prod_idx){
                                return {...prod,
                                    batchItems:prod?.batchItems.map((batch, idx)=>{
                                        if (batch_idx === idx){
                                            return {...batch,  available_quantity:t_product}
                                        }
                                        return batch
                                    })
                                }
                            }
                            return prod
                        })
                    }
                }
                return item
            })
            setData(arr)
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
    }

    const handleChangeBatchQuantity = (event, product_idx, service_idx, batch_idx) => {
        let arr = data.map((item, index) => {
            if (index === service_idx) {
                return {
                    ...item,
                    consumables: item?.consumables.map((prod, prod_idx) => {
                        if (product_idx === prod_idx) {
                            return {
                                ...prod,
                                batchItems: prod?.batchItems.map((batch, idx) => {
                                    if (batch_idx === idx) {
                                        return {
                                            ...batch,
                                            quantity: event.target.value
                                        }
                                    }
                                    return batch
                                })
                            }
                        }
                        return prod
                    })
                }
            }
            return item
        })
        setData(arr)
    }

    const handleAddBatchItem = (product_idx, service_idx) => {
        const obj = { batch_id:'', available_quantity: '', quantity:''}
        let arr = data.map((item, index) => {
            if (index === service_idx) {
                return {
                    ...item,
                    consumables: item?.consumables.map((prod, prod_idx) => {
                        if (product_idx === prod_idx) {
                            return {
                                ...prod,
                                batchItems: [...prod?.batchItems, obj]
                            }
                        }
                        return prod
                    })
                }
            }
            return item
        })
        setData(arr)
    }

    const handleRemoveBatchItem = (product_idx, service_idx, batch_idx) => {
        let arr = data.map((item, index) => {
            if (index === service_idx) {
                return {
                    ...item,
                    consumables: item?.consumables.map((prod, prod_idx) => {
                        if (product_idx === prod_idx) {
                            return {
                                ...prod,
                                batchItems: prod?.batchItems.filter((_, bat_idx) =>bat_idx !== batch_idx)
                            }
                        }
                        return prod
                    })
                }
            }
            return item
        })
        setData(arr)
    }



    const handleOpenDialog = (_id) =>{
        setOpenDialog(true)
        setVisitId(_id)
        axios.get(`${config.smsUrl}/cpharmacy/services_with_consumables`, {params:{visit_id:_id}}).then(res => {
            const data = res.data;
            const dt = !data.data ? [] : data.data;
            const arr = dt.map(item=>({...item,
                consumables: item?.consumables?.map(prod=>({
                    ...prod,
                    batches:[], batchItems:[{batch_id:'', available_quantity: '', quantity:''}]
                })),
                open:true
            }))
            setData(arr);
        }).catch(error => {
            errorMessages(error, null, actions)
        });
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }



    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        setIsSubmitted('pending');
        const params = data.filter(item=>item.service_id).reduce((r, a) =>
            r.concat(a.consumables.map(({product_id,batchItems, quantity}) =>
                ({product_id, batches:batchItems.filter(item=>item.batch_id && item.quantity).map(({batch_id, quantity})=>({batch_id, quantity:+quantity})), quantity}))), []);
        const paramsArr = params.filter(item=>item?.batches?.length > 0)
        axios.post(`${config.smsUrl}/cpharmacy/dispense_service_consumables`, paramsArr).then(() => {
            setIsSubmitted('resolved');
            actions.snackbarActions.snackSuccess(`Consumables dispensed successfully successfully`);
            setSubmitted(false);
            setOpenDialog(false)
        }).catch((err) => {
            errorMessages(err, null, actions)
            setSubmitted(false);
            setIsSubmitted('rejected');
        });

    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const headData = [{name:'Name', width:300},{name:'Quantity Billed', width:100},
        {name:'Chosen Batch ID(s)', width:300, sub_headings: ["Batch ID", "Available Quantity", "Chosen Quantity", "Action"]}];

    return {
        isResolved,
        isRejected,
        handleSubmit,
        submitted,
        handleOpenDialog,
        renderDispenseConsumable:(
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title='Dispense Consumables' maxWidth='lg' isPaperStyle>
                <form onSubmit={handleSubmit} autoComplete='off'>
                    {data.map((item, service_idx)=>{
                        const consumables = item.consumables ? item.consumables : []
                        return (
                            <PreviousVisitContainer open={item.open} key={service_idx} name={item.procedure_name}
                                                    handleOpen={()=>handleOpenService(service_idx)}>
                                <div className='p-2'>
                                    <table className="table table-bordered fill-prescription-table">
                                        <thead>
                                        <tr>
                                            {headData.map((item,index)=>(
                                                item.sub_headings && item.sub_headings.length > 0 ?
                                                    <th style={{padding:0}}>
                                                        <tr style={{display:'table', width:'100%'}}>
                                                            <td width={item.width ? item.width : 100}  colSpan={item.sub_headings.length} style={{textAlign:'center'}}>
                                                                {item.name}
                                                            </td>
                                                        </tr>
                                                        <tr style={{display:'table', width:'100%'}}>
                                                            {item.sub_headings.map((sub_head)=>(
                                                                <th>{sub_head}</th>
                                                            ))}
                                                        </tr>
                                                    </th>
                                                    :
                                                    <td width={item.width ? item.width : 100} >{item.name}</td>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                            {consumables.map((cons, idx)=>{
                                                const batches = cons.batches ? cons.batches : [];
                                                const batchItems = cons.batchItems ? cons.batchItems : []
                                                return(
                                                    <tr key={idx}>
                                                        <td>{cons.product_name}</td>
                                                        <td>{cons.quantity}</td>
                                                        <td>
                                                            <table>
                                                                <tbody>
                                                                {batchItems?.map((batch, batch_idx) => (
                                                                    <tr key={batch_idx}>
                                                                        <td style={{width:'30%'}}>

                                                                            <SelectInput defaultOption='Select...' onChange={(e)=>handleRetrieveBatchDetails(e, idx, service_idx,batch_idx,cons.product_id)}
                                                                                         options={batches} value={batch.batch_id} onFocus={()=>handleRetrieveBatches(service_idx, idx, cons.product_id)}/>
                                                                            {/*<AutoCompleteUnControlled options={batches} submitted={submitted}  handleChange={()=>handleRetrieveBatches(service_idx, cons.product_id, batch_idx)} label_text="label"*/}
                                                                            {/*                          // sub_idx={batch_idx}*/}
                                                                            {/*                          // onBlur={handleOnBlur}*/}
                                                                            {/*                          onFocus={()=>handleRetrieveBatches(service_idx, cons.product_id, batch_idx)}*/}
                                                                            {/*                          medicine_value={{label:batch.batch_id, value: batch.batch_id}} id="batch_ids" value_text="value"*/}
                                                                            {/*                          errorMsg_Valid = "Please enter a valid batch id"*/}
                                                                            {/*                          // valid_error_check={btch.isError}*/}
                                                                            {/*                          handleProductData={()=>handleRetrieveBatchDetails({value:batch.batch_id, innerText: batch.batch_id}, idx, service_idx,batch_idx)}*/}
                                                                            {/*                          placeholder="Type Batch ID" errorMsg="Please select a batch ID"/>*/}
                                                                            {/*{btch.isDuplicate && <ErrorMessage>This Batch ID was already selected</ErrorMessage>}*/}
                                                                        </td>
                                                                        <td style={{width:'20%'}}>
                                                                            {batch.available_quantity}
                                                                        </td>
                                                                        <td style={{width:'40%'}}>
                                                                            <TextField submitted={submitted} type="number" value={batch.quantity} min="0"
                                                                                       onChange={(e) => handleChangeBatchQuantity(e, idx, service_idx,batch_idx)} style={{fontSize:'11px'}}/>
                                                                            {/*{btch.chosen_quantity > btch.available_quantity &&*/}
                                                                            {/*    <ErrorMessage>Chosen Quantity is greater than available quantity</ErrorMessage>*/}
                                                                            {/*}*/}
                                                                            {/*{submitted && (!btch.chosen_quantity) &&*/}
                                                                            {/*    <ErrorMessage>This is a required field</ErrorMessage>}*/}
                                                                        </td>

                                                                        <td style={{width:'10%'}}>
                                                                            <Tooltip title='Remove'>
                                                                                <IconButton type='button' onClick={()=>handleRemoveBatchItem(idx, service_idx,batch_idx)}>
                                                                                    <StyledRemoveIcon/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                <tr>
                                                                    <StyledCell colSpan="4">
                                                                        <button type="button" onClick={()=>handleAddBatchItem(idx, service_idx)}  className="btn btn-sm sms-gray-btn"
                                                                                id="add-row">Add another batch
                                                                        </button>
                                                                        {/*{submitted && (parseInt(row.total_chosen_quantity) !== parseInt(row.quantity_billed)) &&*/}
                                                                        {/*    <ErrorMessage>Chosen Quantity is not equal to prescribed quantity</ErrorMessage>}*/}
                                                                    </StyledCell>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </PreviousVisitContainer>
                        )
                    })}
                    <RightAlignedContainer>
                        <button type="submit" id='submit_form'
                                className="sms-btn btn btn-sm px-3"  disabled={isPending}>{isPending ? 'Saving...' : 'Save'}</button>
                        <button type="button"  id="reset_form" onClick={handleCloseDialog}  className="sms-gray-btn btn btn-sm ml-2" >Cancel</button>
                    </RightAlignedContainer>
                </form>
            </CustomDialog>

        )
    }
}


