import React, {useRef} from 'react';
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookMedical} from "@fortawesome/free-solid-svg-icons/faBookMedical";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {MainSearchComponent} from "./MainSearch";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {connect} from "react-redux";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import FilterResults from "react-filter-search";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import CustomTable from "../Utils/Tables/CustomTable";
import {useRevenuePerService} from "./CustomHooks/useRevenuePerService";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {convertMonth} from "../Utils/ConvertDate";
import ReceiptTemplate from "../Utils/ReceiptTemplate";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import { formatAmount } from '../Utils/formatNumbers';


export const useStyles = makeStyles((theme) => ({
    icon: {
        width: '0.9em',
        height: '0.9em'
    },

}));
const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {
        id: 'service',
        numeric: false,
        disablePadding: false,
        label: 'Service'
    },
    {
        id: 'quantity',
        numeric: false,
        disablePadding: false,
        label: 'Quantity'
    },
    {
        id: 'cost',
        numeric: false,
        disablePadding: false,
        label: 'Unit Cost'
    },
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
];

const RevenuePerService = ({actions, snackbars, match}) => {
    const classes = useStyles();
    const {service_id, department_id, service_name} = match.params;
    const id = service_id !== 'null' ? service_id : department_id;
    const param = service_id !== 'null' ? 'service_id' : 'department_id' 
    const {
        closeSnackbar, search_type, handleSubmit,downloadPdf,
        text, textType, year, date, handleChangeSearch, services, 
        handleChangeDate, handleChangeYear, handleIncrementYear, isSubmitted, submitted,
        searchValue, order, orderBy, loading, page, rowsPerPage, totalAmount,
        handleSearch, handleRequestSort, handleChangePage, handleChangeRowsPerPage, activePage,
        total_count, handleChangeActivePage, inputType
    } = useRevenuePerService(actions, id, service_name, param)
    const printRef = useRef();
    const {openBar, type, message} = snackbars;
    const {start_date, end_date} = date;
    const current_date = search_type === '1' ? convertMonth(start_date) : search_type === '2' ? year :
        search_type === '3' ? `${start_date} to ${end_date}` : start_date;
    return (
        <div className='revenue-container'>
            <PageTitle title="Financial Reports"/>
            <SubHeader title="Reports" subTitle={`${service_name} revenue generated`}>
                <FontAwesomeIcon icon={faBookMedical}/>
            </SubHeader>
            <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
            <div className="revenue-generated-header">
                <Grid container spacing={0}>
                    <Grid item xs={6} md={10}>
                        <div className="revenue-generated-form">
                            <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                                 handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                                 changePeriod={handleChangeSearch} end_date={end_date}
                                                 handleChangeValue={handleChangeYear} inputType={inputType}
                                                 search_type={search_type} handleChangeYear={handleIncrementYear}
                                                 start_date={start_date} year={year} submitted={submitted}/>
                        </div>
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <div className="text-right">
                            <div className="btn-group mt-2">
                                <button onClick={downloadPdf}
                                        className='btn btn-sm sms-gray-btn'><PictureAsPdfIcon classes={{root:classes.icon}}/>
                                </button>
                                <ReactToPrint content={() => printRef.current}
                                              trigger={() => <button className="btn sms-gray-btn  btn-sm"><PrintIcon classes={{root:classes.icon}}/></button>}/>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className="mt-3">
                <CustomTable title={`${service_name}`} term={searchValue} handler={handleSearch}
                            activePage={activePage} headData={headData}
                             handleRequestSort={handleRequestSort} data={services} colSpan={2} order={order}
                             orderBy={orderBy} customPage filter handleChangeNextPage={handleChangeActivePage} 
                             records={10} total_count={total_count}>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : services.length > 0 ?
                        <FilterResults value={searchValue} data={services}
                                       renderResults={results => (
                                           <TableBody>
                                               {stableSort(results, getComparator(order, orderBy))
                                                   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                   .map((item, index) => {
                                                       const count = (page * rowsPerPage) + index + 1;
                                                       return (
                                                           <TableRow
                                                               hover
                                                               role="checkbox"
                                                               tabIndex={-1}
                                                               key={index}
                                                               className='mui-table-row'>
                                                                <TableCell>{count}</TableCell>
                                                               <TableCell>{item.service_name}</TableCell>
                                                               <TableCell>{item.quantity}</TableCell>
                                                               <TableCell>{formatAmount(item.rate)}</TableCell>
                                                               <TableCell>{formatAmount(item.total_amount)}</TableCell>
                                                           </TableRow>
                                                       );
                                                   })}
                                               <TableRow>
                                                   <TableCell colSpan={3} align='right'>Total Amount :<div className="ml-1"/></TableCell>
                                                   <TableCell><strong>{formatAmount(totalAmount)}</strong></TableCell>
                                               </TableRow>
                                           </TableBody>)}/> : <TableBody><TableRow>
                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
                <div ref={printRef} className='revenue-print'  id="prescription-print">
                    <ReceiptTemplate>
                        <table className='table table-borderless profile-table prescription-table'>
                            <thead>
                            <tr>
                                <td colSpan={4}><h6><strong>{`${service_name} Financial Report (${current_date})`}</strong></h6></td>
                            </tr>
                            <tr>
                                <td style={{borderBottom: '1px solid gray', borderTop: '1px solid gray'}}><p><strong>Service</strong></p></td>
                                <td style={{borderBottom: '1px solid gray', borderTop: '1px solid gray'}}><p><strong>Quantity</strong></p></td>
                                <td style={{borderBottom: '1px solid gray', borderTop: '1px solid gray'}}><p><strong>Unit Cost</strong></p></td>
                                <td style={{borderBottom: '1px solid gray', borderTop: '1px solid gray'}}><p><strong>Amount</strong></p></td>
                            </tr>
                            </thead>
                            <tbody>
                            {services.map((item, index) => (
                                <tr key={index}>
                                    <td><p>{item.service_name}</p></td>
                                    <td><p>{item.quantity}</p></td>
                                    <td><p>{item.rate}</p></td>
                                    <td><p>{item.total_amount}</p></td>
                                </tr>
                            ))}
                            <tr>
                                <td style={{borderBottom: '1px solid gray', borderTop: '1px solid gray'}} colSpan={3} align='right'><p>Total Amount:</p></td>
                                <td style={{borderBottom: '1px solid gray', borderTop: '1px solid gray'}}><p>{totalAmount}</p></td>
                            </tr>
                            </tbody>
                        </table>
                    </ReceiptTemplate>
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(RevenuePerService)