import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {getActualMonthValue} from "../../../Utils/ConvertDate";

function GetTotalCounts(arr) {

    let average_length_of_stay = 0; 
    let average_occupancy = 0;
    let bed_occupancy_calcualtion = 0;
    let total_admissions = 0;
    let total_bed_count = 0; 
    let total_patient_days = 0;

    arr.forEach(element => {
        average_length_of_stay += element.average_length_of_stay;
        average_occupancy += element.average_occupancy;
        bed_occupancy_calcualtion += element.bed_occupancy_calcualtion;
        total_admissions += element.total_admissions;
        total_bed_count += element.total_bed_count;
        total_patient_days += element.total_patient_days;
    });

    let total = {
        average_length_of_stay : average_length_of_stay.toFixed(2),
        average_occupancy : average_occupancy.toFixed(2),
        bed_occupancy_calcualtion : bed_occupancy_calcualtion.toFixed(2),
        name: "Total Count",
        total_admissions : total_admissions.toFixed(2),
        total_bed_count : total_bed_count.toFixed(2),
        total_patient_days : total_patient_days.toFixed(2)
    };

    return total;
}

export const useWardCensus = (actions) => {
    const [loading, setLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const today = new Date();
    const [state, setState] = useState({str_val:`${getActualMonthValue(today.getMonth())}/${today.getFullYear()}`, month: today.getMonth(), year: today.getFullYear()});
    const [census_info, setCensusInfo] = useState([]);

    useEffect(() => {
        setLoading(true);
        const formData = new FormData();
        const {month, year} = state;
        formData.append('date', `${year}-${getActualMonthValue(month)}`);
        axios.post(`${config.smsUrl}/creport/ward_census`, formData).then(res => {
            const data = res.data;
            const dt = data ? data.ward ? data.ward : [] : {};
            if (dt.length === 0) {
                setCensusInfo([]);
                return;
            }
            const arr = dt[1];
            arr.push(GetTotalCounts(dt[1]));
            setCensusInfo(arr);
            setLoading(false);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading(false);
        })
    }, []);

    const handleChange = (maskedValue, year, month)  =>{
        const new_factors = {str_val : maskedValue, year, month};
        setState(new_factors);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const {str_val, month, year} = state;
        const formData = new FormData();
        const str_arr = str_val.split('/');
        formData.append('date', `${str_arr[1]}-${str_arr[0]}`);
        setSubmitted(true);
        if ((month || month === 0) && year) {
            setIsSubmitted(true)
            setLoading(true)
            axios.post(`${config.smsUrl}/creport/ward_census`, formData).then(res => {
                const data = res.data;
                const dt = data ? data.ward ? data.ward : [] : {};
                if (dt.length === 0) {
                    setCensusInfo([]);
                    return;
                }
                const arr = dt[1];
                arr.push(GetTotalCounts(dt[1]));
                setCensusInfo(arr);
                setLoading(false);
                setIsSubmitted(false)
            }).catch(err => {
                setIsSubmitted(false)
                setLoading(false)
                errorMessages(err, null, actions);
            })
        }
    }

    const isPending = isSubmitted === 'pending';

    return {handleChange, isPending, submitted, state, census_info, handleSubmit}
}