import {useTheme} from "@material-ui/core/styles";
import Form from "react-bootstrap/Form";
import React from "react";
import {ErrorMessage} from "../Utils/styledComponents";


function getFieldError(value) {
    if (!value) return 'This field is required'
    return null
}

const CustomInput = ({type,name, index,  submitted=false, setIsBlocking=()=>{}, ...props}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {isRequired, reset, inputValue} = props

    const [value, setValue] = React.useState( '')
    const [touched, setTouched] = React.useState(false)

    React.useEffect(()=>{
        setValue('')
        setTouched(false)
    }, [reset])

    React.useEffect(()=>{
        if(!inputValue){
            return
        }
        setValue(inputValue)
    }, [inputValue])

    const handleChangeValue = (e) =>{
        setValue(e.currentTarget.value)
        setIsBlocking(true)
    }



    const errorMessage = getFieldError(value)
    const displayErrorMessage = isRequired && (submitted || touched) && errorMessage
    return (
        <React.Fragment>
            <Form.Control id={name}
                          style={{border: displayErrorMessage ? '1px solid red':''}}
                          type={type} value={value} name={name}
                          onChange={handleChangeValue}
                          onBlur={()=>setTouched(true)}
                          className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}  {...props}/>
            {displayErrorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage>: null}
        </React.Fragment>

    )
};

const CustomSelectInput = ({name, index, defaultOption,options,  submitted=false,setIsBlocking=()=>{}, ...props}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {isRequired, reset, inputValue} = props

    const [value, setValue] = React.useState( '')
    const [touched, setTouched] = React.useState(false)

    React.useEffect(()=>{
        setValue('')
        setTouched(false)
    }, [reset])

    React.useEffect(()=>{
        if(!inputValue){
            return
        }
        setValue(inputValue)
    }, [inputValue])



    const errorMessage = getFieldError(value)
    const displayErrorMessage = isRequired && (submitted || touched) && errorMessage
    return (
        <React.Fragment>
            <Form.Control style={{border: displayErrorMessage ? '1px solid red':''}} as="select"
                          name={name} value={value}
                          onChange={(e)=>setValue(e.currentTarget.value)}
                          onBlur={()=>setTouched(true)}
                          className={` form__control ${palette.type === "dark" && 'form-control-dark'}`} {...props}>
                {defaultOption && <option value="">{defaultOption}</option>}
                {options.map(option=>(
                    <option key={option.value} value={option.value}>{option.text}</option>
                ))}
            </Form.Control>
            {displayErrorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage>: null}
        </React.Fragment>

    )
};

const CustomCheckInput = ({type,name, index,  submitted=false, ...props}) =>{
    const {isRequired, reset, inputValue, value:radioValue} = props

    const [value, setValue] = React.useState( '')
    const [touched, setTouched] = React.useState(false)

    const handleChange = (e)=>{
        const  val = type === 'radio' ? e.target.value : e.target.checked;
        setValue(val)
    }

    React.useEffect(()=>{
        setValue('')
        setTouched(false)
    }, [reset])

    React.useEffect(()=>{
        if(!inputValue){
            return
        }
        setValue(inputValue)
    }, [inputValue])

    const errorMessage = getFieldError(value)
    const displayErrorMessage = isRequired && (submitted || touched) && errorMessage

    const checkValue = type === 'radio' ? value === radioValue : value
    return (
        <React.Fragment>
            <Form.Check type={type}  name={name} className="form__label"
                        checked={checkValue} onChange={handleChange} {...props}/>
            {displayErrorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage>: null}
        </React.Fragment>


    )
}

const MIN_TEXTAREA_HEIGHT = 45

function CustomResizableTextarea({name, submitted=false, rows='8',setIsBlocking=()=>{}, ...props}){
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {isRequired, reset,inputValue} = props

    const [value, setValue] = React.useState('')
    const [touched, setTouched] = React.useState(false)

    const errorMessage = getFieldError(value)
    const displayErrorMessage = isRequired && (submitted || touched) && errorMessage

    const textareaRef = React.useRef(null);

    React.useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        textareaRef.current.style.height = "inherit";
        // Set height
        textareaRef.current.style.height = `${Math.max(
            textareaRef.current.scrollHeight,
            MIN_TEXTAREA_HEIGHT
        )}px`;
    }, [value]);


    React.useEffect(()=>{
        if(!reset){
            return
        }
        setValue('')
        setTouched(false)
    },[reset])

    React.useEffect(()=>{
        if(!inputValue){
            return
        }
        setValue(inputValue)
    }, [inputValue])

    const handleChangeValue = (e) =>{
        setValue(e.currentTarget.value)
        setIsBlocking(true)
    }

    return(
        <React.Fragment>
            <Form.Control as="textarea"
                          ref={textareaRef}
                          rows={rows}
                          style={{
                              minHeight: MIN_TEXTAREA_HEIGHT,
                              resize: "none",
                              border: displayErrorMessage ? `1px solid red`:''
                          }}
                          value={value}
                          name={name}
                          id={name}
                          onChange={handleChangeValue}
                          onBlur={()=>setTouched(true)}
                          className={`form__control ${palette.type === "dark" && 'form-control-dark'}`}
                          {...props}
            />
            {displayErrorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage>: null}
        </React.Fragment>

    )
}
export {CustomInput, CustomResizableTextarea, CustomCheckInput, CustomSelectInput};