import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable, {useHiddenColumns} from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../../Utils/smsTitle";
import {Link} from "react-router-dom";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import {useInPatientBillingList} from "./hooks/useInPatientBillingList";
import {formatDateTime} from "../../Utils/ConvertDate";
import {history} from "../../../Helpers/history";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import { LoadingGif } from "../../Utils/Loader";
import {titleCase} from "../../Users/addUser";

const headData = [{id: 'count', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_admission_id', numeric: false, disablePadding: false, label: 'Admission ID', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action', hidden:false},
];

export const PatientBillingList = ({actions, snackbars}) => {
    const {searchValue, order, orderBy, activePage, billingList, total_count,
         handleSearch,  handleRequestSort, handleChangeNextPage,
        closeSnackbar, activeStatus, handleChangeStatus,
        isError, isLoading, isSuccess} = useInPatientBillingList(actions)
    const {openBar, type, message} = snackbars;


    const components = [{label: 'Out-patient', path: '/paymentList'},
        {label: 'In-patient', path: '/patientbillinglist'},
        {label: 'Invoiced Bills', path: '/invoicedbills'},
       
    ]
    const title = {
        1: 'Deposits',
        2:'Pending Clearance',
        3: 'Cleared'
    }

    const patientBills = billingList.map((bill, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;
        const active_status = {
            1: (
                <Link to={{pathname: `/inpatientbillform/${bill.visit_id}/${bill.patient_admission_id}/${activeStatus}/${bill.invoice_no}/${bill.status}`}}
                      style={{textDecoration: "none", color: "#474747"}}>
                    <button className='btn btn-sm sms-info-btn mr-1'>Make Deposit</button></Link>
            ),
            2: (
                <>
                    <Link to={{pathname: `/inpatientbillform/${bill.visit_id}/${bill.patient_admission_id}/${activeStatus}/${bill.invoice_no}/${bill.status}`}}
                          style={{textDecoration: "none", color: "#474747"}}>
                        <button className='btn btn-sm sms-info-btn mr-1'>Create Receipt</button></Link>
                    <Link to={{pathname: `/inpatientbillinvoice/${bill.visit_id}/${bill.patient_admission_id}/${activeStatus}/${bill.invoice_no}`}}>
                        <button className="btn btn-sm sms-gray-btn mr-1">Create Invoice</button></Link>
                </>

            )
        }

        return {
            ...bill,
            count,
            date:formatDateTime(bill.begin_datetime),
            patient_name: titleCase(`${!bill.patient_firstname ? "" : bill.patient_firstname} ${!bill.patient_lastname ? "" : bill.patient_lastname}`),
            action: (
                <>
                    {active_status[activeStatus]}
                    <Link to={{pathname: `/viewinpatientbill/${bill.visit_id}/${bill.patient_admission_id}/${bill.status}`}}
                          style={{textDecoration: "none", color: "#474747"}}>
                        <button className='btn btn-sm sms-info-btn'>View</button></Link>
                </>

            )

        }

    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);
    return (
        <div data-testid="inpatient-bill-list" className='journals'>
            <PageTitle title="In-patient Billing List"/>
            <SubHeader title="Billing" subTitle="In-patient billing list">
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='general-ledger-header inpatient-billing-header'>
                <a className={`billing-span btn btn-sm ${activeStatus === 1 ? 'active-billing-span':''}`} 
                onClick={()=>handleChangeStatus({active_status:1, admission_status:1, pay_status:0})}>Deposits</a>
                <a className={`billing-span btn btn-sm ${activeStatus === 2 ? 'active-billing-span':''}`} 
                onClick={()=>handleChangeStatus({active_status:2, admission_status:2, pay_status:0})}>Pending Clearance</a>
                  <a className={`billing-span btn btn-sm ${activeStatus === 3 ? 'active-billing-span':''}`} 
                onClick={()=>handleChangeStatus({active_status:3, admission_status:3, pay_status:1})}>Cleared Bills</a>
            </div> 

            <div className="mui-tables">
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <CustomTable title={title[activeStatus]} customPage handleChangeNextPage={handleChangeNextPage}
                             headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                             activePage={activePage} {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                             handleRequestSort={handleRequestSort} data={patientBills} colSpan={5} order={order}
                             orderBy={orderBy} term={searchValue}>
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><LoadingGif/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? patientBills.length > 0 ? patientBills.slice(0, 10).map((item, index) => {

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1} 
                                            key={index}
                                            className='mui-table-row'
                                        >
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    )}): <TableRow>
                                    <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                                </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>: null}
                        </TableBody>
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientBillingList);
