import styled from "@emotion/styled/macro";
import * as colors from "../../styles/colors";
import EditIcon from "@material-ui/icons/Edit";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {DataCell} from "../Utils/Lists/CollapsibleList";

const Status = styled.span({
    borderRadius:'15px',
    display:'inline-block',
    padding:'3px 10px',
    fontSize:'11px'
})

const PendingStatus = styled(Status)({
    background:colors.lightOrange,
    color:colors.pending
})

const ExpiredStatus = styled(Status)({
    background:colors.lightOrange,
    color:colors.red60
})

const Processing = styled(Status)({
   background:colors.lightYellow,
   color: colors.yellow
})

const FinishedStatus = styled(Status)({
    background:colors.lightGreen,
    color:colors.green
})

const OtherMedicine = styled.span({
    background:colors.lightPurple,
    color:colors.indigo,
    fontSize:'9px',
    borderRadius:'10px',
    padding:'2px 5px'
})

const StyledIcon = styled(EditIcon)({
    width:'0.8em',
    height:'0.8em'
})


const StyledLibraryIcon = styled(LibraryBooksIcon)({
    width:'0.8em',
    height:'0.8em'
})

const P = styled.p({
    marginTop:'20px ',
    fontWeight:700
})

const Container = styled.div({
    padding: "10px 20px"
})

const StyledRemoveIcon = styled(RemoveCircleIcon)({
    width:'0.8em',
    height:'0.8em',
    fill:'#c4495c'
})

const StyledCell = styled(DataCell)({
    borderBottom:`0.78px solid ${colors.gray00}`,
})

const ViewButton = styled.button({
    fontSize:'13px !important',
    paddingTop:'0.08rem',
    paddingBottom:'0.08rem'
})

export {PendingStatus, FinishedStatus, OtherMedicine, StyledIcon, StyledLibraryIcon,P, Container, StyledRemoveIcon, StyledCell,
ViewButton, Processing, ExpiredStatus}