import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { config } from '../../../../Helpers/env'
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {convDate, dateConvert} from "../../../Utils/ConvertDate";
import {useRecordExpenses} from "./useRecordExpense";


export const useExpenses = (actions) => {
    const [expenses, setExpenses] = useState([]);
    const [state, setState] = useState({start_date: dateConvert(), end_date: dateConvert()});
    const [loading, setLoading] = useState('idle');
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const {expenseProps} = useRecordExpenses(actions)
    const {isResolved:isExpenseResolved} = expenseProps
    const {start_date, end_date} = state


    useEffect(() => {

        (async () => {
            setLoading('pending')
            try {
                const res = await axios.get(`${config.smsUrl}/accounting/expenses`, {params:{start_date, end_date}})
                let _data = res.data
                let dt = _data.data ?? [];
                const arr = dt.map(({date,receipt_no, expense_type_name, amount,expense_id})=>({
                    date:convDate(date),
                    receipt_no,expense_type_name,amount,expense_id
                }))
                setExpenses(arr)
                setLoading('success')
            } catch (e) {
                // errorMessages(e, null, actions)
                setLoading('error')
            }
        })();
    }, [isExpenseResolved])



    const handleChange = (e) => {
        const {name, value} = e.target
        setState({...state, [name]: value})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true);
        if (start_date && end_date) {
            setIsSubmitted('pending')
            setLoading('pending')
            axios.get(`${config.smsUrl}/accounting/expenses`, {params:{start_date, end_date}}).then((res) => {
                let _data = res.data
                let dt = _data.data ?? [];
                const arr = dt.map(({date,receipt_no, expense_type_name, amount,expense_id})=>({
                    date:convDate(date),
                    receipt_no,expense_type_name,amount,expense_id
                }))
                setExpenses(arr)
                setIsSubmitted('resolved')
                setLoading('success')
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
                setLoading('error')
            })
        }
    }


    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const isLoading = loading === 'pending'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'



    return {expenses, state, submitted, isPending, isResolved, isRejected, isLoading, isSuccess,
    isError,handleSubmit, handleChange, closeSnackbar, expenseProps}
}
