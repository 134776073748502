import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";

export const useDischargeNote = (props) =>{
    const [discharge, setDischarge] = useState({});
    const componentRef = useRef();
    const {patient_discharge_id, visit_id} = props.match.params;
    const {actions} = props;

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const discharging_office = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    useEffect(() => {
        const formData = new FormData();
        formData.append('discharge_id', patient_discharge_id);
        formData.append('visit_id', visit_id);
        axios.post(`${config.smsUrl}/cvisit/retrieve_discharge_info`, formData).then(res => {
            const details = res.data;
            const data = !details ? {} : details
            setDischarge(data)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }, []);

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {discharge, componentRef, discharging_office, closeSnackbar,userObj, token, _token}
}