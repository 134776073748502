import {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import {titleCase} from "../../Users/addUser";
import {convDate, dateConvert} from "../../Utils/ConvertDate";


export const useFillPrescription = (actions, component) =>{
    const [rows, setRows] = useState([]);
    const [state, setState] = useState({
        patient_id: '', patient_name: '', gender: '', age: '', weight: '', blood_pressure: '',
        reference: '', visit_id: '', date: '', policy_number: '',address:'', phone:''
    });
    const [prescription, setPrescription] = useState({});
    const [drugs, setDrugs] = useState([]);
    const [drugList, setDrugList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [ids, setIds] = useState({prescription_id:'', patient_type:'', visit_id:'', item_status:''})
    const [openDialog, setOpenDialog] = useState(false)
    const [prescribed, setIsPrescribed] = useState('pending');
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)
    const {prescription_id, patient_type,visit_id, item_status} = ids;

    const [current_batches, setCurrentBatches] = useState([]);
    const [current_idx, setCurrentIdx] = useState();

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? 1 : _token.user_roles_id;

    const route = {
        'out-patient':'get_patient_presc',
        'in-patient':'get_patient_presc',
        'previous':'get_previous_patient_presc'
    }

    const isPrescribed = prescribed === 'finished'
    //
    // useEffect(() => {
    //     if(!(prescription_id && patient_type)){
    //         return;
    //     }
    //
    // }, [prescription_id,patient_type]);

    useEffect(() => {
        if(!isPrescribed){
            return
        }

        const prdts = drugs.map(product=>({
            ...product,
            medicine_name: product.product_name,
            alternative_product_id:'',
            alternative_product_name:'',
            dosage: product.dosage, 
            quantity: product.quantity ? product.quantity : 0 ,
            number_of_days:product.number_of_days,
            cost: product.amount,
            instructions: product.key_information,
            prescription_detial_id:product.prescription_detial_id ?? '',
            frequency:product.frequency,
            bill_id:product.bill_id ? product.bill_id:'',
            isError:false,
            total_chosen_quantity: 0,
            isChange:false,
            quantity_given:product.quantity_given ? product.quantity_given : 0,
            selected_batch_ids:[{
                batch_id:'', available_quantity: 0, chosen_quantity: 0, isError: false, isDuplicate: false
            }],
        }));
     
        setRows(prdts);
        setState({
            patient_id: prescription.patient_number,
            patient_name: `${!prescription.patient_firstname ? "" :
                prescription.patient_firstname} ${!prescription.patient_lastname ? "" :
                prescription.patient_lastname}`, gender: prescription.gender,
            age: prescription.age, weight: prescription.weight, blood_pressure: prescription.blood_pressure,
            reference: prescription.reference, visit_id: prescription.visit_id, date: prescription.begin_datetime,
            policy_number: prescription.policy_number, address: prescription.address, phone:prescription.phone_no
        })
        return () =>{}
    }, [drugs,  prescription, isPrescribed]);


    const handleChangeMedicine = (index) =>{
        const arr = rows.map((item, idx)=>{
            if(idx === index){
                return {...item,  isChange:true}
            }
            return item
        })
        setRows(arr)
        setIsBlocking(true)
    }

    const handleCancelChangeMedicine = (index) =>{
        const arr = rows.map((item, idx)=>{
            if(idx === index){
                return {...item, batch_id:null, isChange:false, alternative_product_id:'', alternative_product_name:''}
            }
            return item
        })
        setRows(arr)
        setIsBlocking(true)
    }


    const retrieveDrugs = (eventTxt, idx) => {
        const arr = rows.map((item, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('product_name', titleCase(eventTxt));
                if (eventTxt !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_medicine`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? [] : resp;
                        if (data[0] === "No Product Found") {
                            setDrugList([{value: '', label: 'No medicine found'}])
                        } else {
                            const arr = data.map(item=>({value:item.value, label: `${item.label}-${item.generic_name}-${item.country_of_origin}`}))
                            setDrugList(arr);
                        }
                    }).catch(error => {
                        // logoutErrorMessage(error, null, actions)
                    });
                    return {...item, alternative_product_name: eventTxt, showDrug: true, isError: false, }
                }
                return {...item, alternative_product_name: eventTxt, showDrug: false, isError: false}
            }
            return item
        })
        setRows(arr)
        setIsSubmitted('idle')
        setIsBlocking(true)
    };

    const clickDrug = (event, idx) => {
        let arr = rows.map((item, index)=>{
            if (index === idx){
                return {...item, alternative_product_name:event.innerText, alternative_product_id:event.value, generic_name:event.generic_name,
                    isError: false, showDrug: false}
            }
            return item
        })

        setRows(arr)
        setIsBlocking(true)
    }


    // add a new row to the batch_ids list for a prescription
    const handleAddBatchRow = (index, item) => {
        let allRows = [...rows];
        let new_selected_batches = item['selected_batch_ids'];
        new_selected_batches.push({
            batch_id:'', available_quantity: 0, chosen_quantity: 0,  isError: false, isDuplicate: false, isExpired:false
        });
        allRows[index]['selected_batch_ids'] = new_selected_batches;
        setRows(allRows);
        setIsBlocking(true)
    };

    // remove row for the batch ids
    const removeBatchRow = (index, btch_idx, item) => {
        let allRows = [...rows];
        let new_selected_batches = item['selected_batch_ids']; 
        new_selected_batches.splice(btch_idx, 1);
        allRows[index]['selected_batch_ids'] = new_selected_batches;
        setRows(allRows);
        setIsBlocking(true)
    };

    const handleChangeQuantity = (event, index) =>{
        const {value} = event.target;
        const arr = rows.map((item,idx)=>{
            if(idx === index){
                return {...item, quantity:value}
            }
            return item;
        })
        
        setRows(arr)
        setIsBlocking(true)
    }




    function addExpirationStatus(array) {
        return array.map(item => {
            const isExpired = convDate(item.expiry_date) <= dateConvert();
            return {...item, isExpired};
        });
    }

    const handleOnClick = (idx) => {
        if (idx !== current_idx) {
            setCurrentIdx(idx);
        }

        const product_id = rows[idx]['alternative_product_id'] ? rows[idx]['alternative_product_id'] : rows[idx]['product_id']
        const formData = new FormData();
        formData.append('product_id', product_id);
        axios.post(`${config.smsUrl}/cinventory/get_pharmacy_batches`, formData).then(res => {
            const resp = res.data;
            const data = !resp ? {} : resp;
            const batches = data.product ? data.product : [];

            const btches =  batches.map(item=>({...item,  value:item.batch_id, label:item.batch_id}))
            const batch_arr = addExpirationStatus(btches)

            setCurrentBatches(batch_arr);
        }).catch(error => {
            logoutErrorMessage(error, null, actions)
        });
        setIsBlocking(true)
    }

    const retrieveBatches = (eventTxt, idx) => {
        if (idx !== current_idx) {
            setCurrentIdx(idx);
        }
        const arr = rows.map((item, index) => {
            if (index === idx) {
                const product_id = item['alternative_product_id'] ? item['alternative_product_id'] : item['product_id']
                const formData = new FormData();
                formData.append('product_id', product_id);
                let btches = [];
                    axios.post(`${config.smsUrl}/cinventory/get_pharmacy_batches`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? {} : resp;
                        const batches = data.product ? data.product : [];
                        btches = batches.map(item=>({...item,  value:item.batch_id, label:item.batch_id}))
                        const batch_arr = addExpirationStatus(btches)
                        setCurrentBatches(batch_arr);
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    return {...item, test_name: eventTxt, batches: btches, isError: false};
            }
            return item
        })
        setRows(arr);
        setIsBlocking(true)
    }

    const getBatchDetails = (event, idx, btch_idx) => {
        // get the drugs
        let allRows = [...rows];

        if (!event.innerText) {
            allRows[idx]['selected_batch_ids'][btch_idx]['batch_id'] = '';
            allRows[idx]['selected_batch_ids'][btch_idx]['available_quantity'] = '';
            allRows[idx]['selected_batch_ids'][btch_idx]['chosen_quantity'] = '';
            return;
        }





        // get the selected batch_ids
        let new_selected_batches = allRows[idx]['selected_batch_ids'];

        const check_dups = new_selected_batches.some((item) => (item['batch_id']===event.innerText));

        if (check_dups) {
            new_selected_batches[btch_idx]['isDuplicate'] = true;
        } else {
            new_selected_batches[btch_idx]['isDuplicate'] = false;
        }

        new_selected_batches[btch_idx]['isExpired'] = event.isExpired;

        // set the batch_id
        new_selected_batches[btch_idx]['batch_id'] = event.innerText;
        const product_id = allRows[idx]['alternative_product_id'] ? allRows[idx]['alternative_product_id'] : allRows[idx]['product_id']
        const formData = new FormData();
        formData.append('batch_id',  event.innerText);
        formData.append('product_id', product_id)
        axios.post(`${config.smsUrl}/cinventory/get_pharmacy_stock`, formData).then(response => {

            const res = response.data;
            const data = res ? res : {};
            const t_product = data.total_product ? data.total_product : 0;

            new_selected_batches[btch_idx]['available_quantity'] = t_product < 0 ? 0 : t_product;
            new_selected_batches[btch_idx]['isBatch'] = false;

            allRows[idx]['selected_batch_ids'] = new_selected_batches;
            setRows(allRows);
 
        }).catch(error => {
            errorMessages(error, null, actions)
        });
        setIsBlocking(true)
    }

    const handleChosenBatchQuantity = (event, idx, btch_idx) => {
        const {value} = event.target;
        let allRows = [...rows];
        allRows[idx]['selected_batch_ids'][btch_idx]['chosen_quantity'] = value;
        let new_btches = allRows[idx]['selected_batch_ids'];

        // calc the total_chosen_quantity
        let new_total_chosen_quantity = 0;
        for (var x = 0; x < new_btches.length; x++) {   
            new_total_chosen_quantity += parseInt(new_btches[x].chosen_quantity? new_btches[x].chosen_quantity : 0);
        }

        allRows[idx]['total_chosen_quantity'] = parseInt(new_total_chosen_quantity);
        if (patient_type === 2) {allRows[idx]['quantity_given'] = new_total_chosen_quantity; }
        
        setRows(allRows);
        setIsBlocking(true)
    }

    const handleOpenDialog = (item) =>{
        const {prescription_id:presc, patient_type:patient,visit_id:v_id, status:item_status} = item;
        setIds({prescription_id:presc, patient_type:patient, visit_id:v_id, item_status})
        setOpenDialog(true)
        const formData = new FormData();
        formData.append('prescription_id',presc);
        formData.append('visit_id',v_id);
        axios.post(`${config.smsUrl}/cpharmacy/${route[component]}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const presc = !dt.patient_info ? {} : dt.patient_info;
            const prescrip = patient === 1 ? dt.out_presc ?? [] : dt.in_presc ?? [];
            setPrescription(presc);
            setDrugs(prescrip)
            setIsPrescribed('finished')
        }).catch(err => {
            logoutErrorMessage(err,null,actions)
        })
    }

    const handleCloseDialog = () =>{
        // clear everything
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setIsBlockDialog(false)
            setIsBlocking(false)
            setOpenDialog(false);
            setIds({prescription_id:'', patient_type:'', visit_id:''});
            setCurrentBatches([]);
            setCurrentIdx();
            setRows([]);
            setDrugs([]);
            setSubmitted(false);
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let contains_duplicates = false;
        let unequal_chosen_quantities = false;

        const products_dispensed = rows.filter(item=>item.status !== 4).map((item)=> {
           
            const btches = item.selected_batch_ids.filter((it)=>(it.batch_id)).map((ex)=>{

                // check if the user chose batch_ids that are the same
                if (ex.isDuplicate && ex.isExpired && !contains_duplicates) { contains_duplicates = true; }
                
                return {
                    'batch_id': ex.batch_id, 
                    'quantity': parseInt(ex.chosen_quantity),
                }
            })

            // check if the chosen quanitities is equal to the required quantity
            if (parseInt(item.total_chosen_quantity) !== parseInt(item.quantity_billed) && !unequal_chosen_quantities) { unequal_chosen_quantities = true;}

                return {
                    'quantity' : parseInt(item.quantity_billed),
                    'prescription_detial_id' : item.prescription_detial_id,
                    'bill_id' : item.bill_id,
                    'product_id' : item.product_id,
                    'drug_change' : item.isChange,
                    'alternative_product_id' : item.alternative_product_id,
                    'batches' : btches,
                    prescription_id:item.prescription_id
                }   
        })


        let params = {
            // prescription_id,
            visit_id,
            products_dispensed,
            user_roles_id
        }

        setSubmitted(true);
        
        const arr = (contains_duplicates === false) && (unequal_chosen_quantities === false)
            && products_dispensed.every(item=> item.batches.length > 0)

        let url = ''
        if(patient_type === 1){
            url =  'fill_prescription';
            params['patient_type'] = 1;

        }else{
            rows.forEach(item=>{
                if(item.discharge_status== null || item.discharge_status=== true){
                    url =  'fill_prescription';
                    params['patient_type'] = 1;
                }else{
                    url =  'fill_prescription';
                    params['patient_type'] = 2;
                }
            })
        }

        setIsBlocking(false)
        if(arr){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cpharmacy/${url}`, params).then(() => {

                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Prescription filled successfully');
                setRows([]);
                setOpenDialog(false);
                setSubmitted(false);
                setIsSubmitted('resolved');

            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected');
            })
        }
    };

    const handleResetForm = () =>{
        setIsBlockDialog(false)
        setIsBlocking(false)
        setOpenDialog(false);
        setIds({prescription_id:'', patient_type:'', visit_id:''});
        setCurrentBatches([]);
        setCurrentIdx();
        setRows([]);
        setDrugs([]);
        setSubmitted(false);
    }

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';
    const prescriptionProps = {rows, state,  isSubmitted,prescription, drugs,  retrieveBatches, getBatchDetails, handleChosenBatchQuantity, removeBatchRow, handleOnClick,
        handleChangeQuantity, handleSubmit,submitted, isPending,isRejected, isResolved, patient_type, component, current_batches, handleAddBatchRow, item_status,
    handleChangeMedicine, drugList, clickDrug, retrieveDrugs, handleCancelChangeMedicine,isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm}
    return { patient_type, prescriptionProps, openDialog, handleOpenDialog, handleCloseDialog, isResolved,}
}