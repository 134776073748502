import {useEffect, useReducer, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {history} from "../../../../Helpers/history";
import {useSelfRequestPrescription} from "../../../Visit/NewService/hooks/usePrescriptionSelfRequest";
import {useNewServiceRequest} from "../../../Visit/NewService/hooks/useNewServiceRequest";
import * as billingActions from "../../utils/billingActions"
import {billingServicesReducer} from "../../utils/billingServicesReducer";
import {billingProductsReducer} from "../../utils/billingProductsReducer";
import {filterInpatientProducts, filterInpatientServices, filterInpatientBeds} from "../../utils/SanitizeArr";

export const useInPatientBillForm = (props) => {
    const {actions} = props;
    const {patient_admission_id, visit_id, status, pay_status} = props.match.params;
    const [{procedureData,invoicesEdited,invoicesRemoved,invoicesPaid}, dispatch] = useReducer(billingServicesReducer,{
        procedureData:[],invoicesEdited:[],invoicesRemoved:[],invoicesPaid:[]
    })
    const [{products,productsEdited, productsRemoved,productsPaid}, dispatchProducts] = useReducer(billingProductsReducer,{
        products:[],productsEdited:[],productsPaid:[],productsRemoved:[]
    })
    const [payment_method, setPaymentMethod] = useState('');
    const [state, setState] = useState({
        patient_number: "", first_name: '', last_name: '',phone_no:'',
        address: '', dob: '', gender: '', admission_date: '', discharge_date: '', total_days: ''
    });
    const [bedBill, setBedBill] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [date, setDate] = useState(dateConvert());
    const [openPrint, setOpenPrint] = useState(false);
    const [totals, setTotals] = useState({total_amount:0,bed_amount:0,total_product:0,due_amount:0,
    balance:0, net_total:0,tax_amount:0, discount_amount:0})
    const [paidAmount, setPaidAmount] = useState("");
    const [billDetails, setBillDetails] = useState({});
    const [payTypes, setPayTypes] = useState([]);
    const [addService, setAddService] = useState(false)
    const [addProduct, setAddProduct] = useState(false)
    const [departments, setDepartments] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [usdAmount, setUsdAmount] = useState(0)
    const [currencyTotal, setCurrencyTotal] = useState(0)
    const [isBlocking, setIsBlocking] = useState(false)
    const [paidReceipts, setPaidReceipts] = useState([])
    const [receiptTotals, setReceiptTotals] = useState({total_amount_paid:0, remaining_balance:0})



    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const obj = {visit_id, user_roles_id, actions}
    const {renderPrescription, isResolved:isPrescription, handleClosePrescriptionDialog} = useSelfRequestPrescription({...obj, setOpenDialog:setAddProduct})
    const {renderService, isResolvedService:isServiceResolved, handleCloseServiceDialog} = useNewServiceRequest({...obj,setOpenDialog:setAddService}, true)





    useEffect(() => {
        axios.get(`${config.smsUrl}/cbilling/payment_methods`).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const arr = all_data.data ?? [];
            const cash = arr.map(item=>({value:item.id, text:item.payment_type_name})).filter(item=> item.text === 'CASH')
            setPayTypes(cash)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    //get departments
    useEffect(() => {
        axios.get(`${config.smsUrl}/cvisit/retrieve_department`).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const departs = !dt.departments ? [] : dt.departments;
            const arr = departs.map(department => ({value: department.department_id,
                label: department.department_name
            }))
            setDepartments(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [])


    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);

    useEffect(() => {
        function retrieveBillDetails() {
            const formData = new FormData();
            formData.append('visit_id', visit_id);
            formData.append('patient_admission_id', patient_admission_id);
            formData.append('pay_status', pay_status);
            formData.append('invoice_number', '');
            axios.post(`${config.smsUrl}/cbilling/get_inpatient_bill`, formData)
                .then(res => {
                    const details = res.data;
                    const data = !details ? {} : details;
                    setBillDetails(data)

                }).catch(error => {
                logoutErrorMessage(error, null, actions)
            });
        }
        retrieveBillDetails()
        const interval = setInterval(()=>{
            retrieveBillDetails()
        }, 15000)

        return ()=>clearInterval(interval)

    }, [isPrescription, isServiceResolved])

    const calculateRemainingBalance = (arr=[]) =>{

        const totalPaid = arr.reduce((a, item) => {
            return +(a + item.amount_paid)
        }, 0);

        const totalBalance = arr[arr.length-1] ? arr[arr.length-1] : {}
        const balance = totalBalance.balance ? totalBalance.balance : 0

        setReceiptTotals({total_amount_paid: totalPaid, remaining_balance: balance})
    }


    useEffect(() => {
        const {patient_info, admission_bill, receipts} = billDetails;
        const p_info = !patient_info ? [] : patient_info;
        const receiptsArr = !receipts ? [] : receipts;
        const pt_info = !p_info[0] ? {} : p_info[0];
        const service_bill = !admission_bill ? [] : admission_bill;

        const {
            admission_date, discharge_date, p_address, p_dob, p_first_name,
            p_gender, p_last_name, patient_number, phone_no
        } = pt_info;
        setState({
            patient_number, first_name: p_first_name, last_name: p_last_name, address: p_address, dob: p_dob,
            gender: p_gender, admission_date, discharge_date, phone_no
        })
        const srvc = filterInpatientServices(exchangeRate,service_bill.filter(service => service.service_id))

        const prdts = filterInpatientProducts(exchangeRate, service_bill.filter(prod => prod.product_id))

        const bd = filterInpatientBeds(exchangeRate, service_bill.filter(bd => bd.bed_assignment_id))

        setPaidReceipts(receiptsArr)
        calculateRemainingBalance(receiptsArr)


        dispatch({type:billingActions.RETRIEVE_SERVICES, services:srvc})
        dispatchProducts({type: billingActions.RETRIEVE_PRODUCTS,products:prdts})

        setBedBill(bd)
        calculateTotal(srvc, prdts,  exchangeRate,bd)
    }, [billDetails])




    const calculateTotal = (servicesArr, prdts, rate = 0, bed=[]) => {

        //usd total amount
        const totalBedBill = bed.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        const totalProduct = prdts.reduce((a, item) => {
            return +(a + item.subTotal)
        }, 0);
        const totalService = servicesArr.reduce((a, item) => {
            return +(a + item.amount)
        }, 0);

        // non usd total amount
        const currency_service_total = servicesArr.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) : 0
            return a + +base_total
        }, 0)

        const product_service_total = prdts.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const currency_bed_total = bed.reduce((a, item) => {
            const base_total = rate ?  (+item.rate / rate) * +item.quantity : 0
            return a + +base_total
        }, 0)

        const non_currency_total_amt = totalProduct + totalService + totalBedBill
        const currency_total_amount =  currency_bed_total + currency_service_total + product_service_total
        const amt = paidAmount - (non_currency_total_amt);
        const bal = amt < 0 ? 0 : amt;
        setTotals({...totals, total_amount: non_currency_total_amt, balance: bal})
        setCurrencyTotal(currency_total_amount)
    }

    const calculateBedAmount = (rate) => {

        const bedArr = bedBill.map(item=>({...item,
            currency_rate: +rate ?  +item.rate / +rate : 0, currency_total: (+rate ?  +item.rate / +rate : 0) * +item.quantity,
            amount:+item.rate * +item.quantity
        }))
        setBedBill(bedArr)
    }

    const multiplyCurrency = (exchange_rate, amount_received) => {
        const amount_in_usd = +exchange_rate * +amount_received
        setUsdAmount(amount_in_usd)
    }


    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)
        setPaidAmount('')
        setTotals({...totals, balance: 0})
        setUsdAmount(0)
        setExchangeRate('')
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setExchangeRate(event.target.value)
        multiplyCurrency(event.target.value, paidAmount)
        calculateBedAmount(event.target.value)
        dispatchProducts({type: billingActions.CALCULATE_PRODUCT_AMOUNT, rate:event.target.value})
        dispatch({type: billingActions.CALCULATE_SERVICE_AMOUNT, rate:event.target.value})
        calculateTotal(procedureData, products, event.target.value, bedBill)
        setIsBlocking(true)
    }

    //change amount received
    const handleChangePaidAmount = (event) => {
        const {total_amount} = totals
        const isBase = currency.split('-')[1]
        const isBaseCurrency = isBase === 'true'

        setPaidAmount(event.target.value);

        const total_amt = isBaseCurrency ? +total_amount : +currencyTotal
        const amount = +(event.target.value) -  total_amt;
        const amt = amount < 0 ? 0 : amount;
        const balance_amount = total_amt > 0 ? amt : 0
        setTotals({...totals, balance: balance_amount})
        multiplyCurrency(exchangeRate, event.target.value)
        setIsBlocking(true)
    };

    const handleChangeServiceCurrencyRate = (event, bill_id) => {
        const prods = products.filter(item=>!item.isExcluded)
        dispatch({
            type:billingActions.CHANGE_SERVICE_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        })
        setIsBlocking(true)
    }

    const handleChangeServiceRate = (event, bill_id) => {

        const prods = products.filter(item=>!item.isExcluded)
        dispatch({
            type:billingActions.CHANGE_SERVICE_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        })
        setIsBlocking(true)
    }

    const handleChangeProductRate = (event, bill_id) => {
        const procedures = procedureData.filter(item=>!item.isExcluded)
        dispatchProducts({
            type:billingActions.CHANGE_PRODUCT_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })
        setIsBlocking(true)
    }

    const handleChangeProductCurrencyRate = (event, bill_id) => {

        const procedures = procedureData.filter(item=>!item.isExcluded)
        dispatchProducts({
            type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })
        setIsBlocking(true)
    }


    const handleDeleteServices = (item) => {
        const {bill_id} = item;
        const prods = products.filter(item=>!item.isExcluded)
        dispatch({
            type:billingActions.DELETE_SERVICE,
            bill_id,
            exchangeRate,
            calculateTotal,
            products:prods,
            bedBill
        })
        setIsBlocking(true)
    };

    const handleChangeProductQuantity = (event,bill_id) =>{
        const procedures = procedureData.filter(item=>!item.isExcluded)
        dispatchProducts({
            type:billingActions.CHANGE_PRODUCT_CURRENCY_RATE,
            value:event.target.value,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })
        setIsBlocking(true)
    }

    const handleDeleteProduct = (item) => {
        const {bill_id} = item
        const procedures = procedureData.filter(item=>!item.isExcluded)
        dispatchProducts({
            type:billingActions.DELETE_PRODUCT,
            bill_id,
            exchangeRate,
            calculateTotal,
            services:procedures,
            bedBill
        })
        setIsBlocking(true)
    };


    const handleChangeDate = (event) => {
        setDate(event.target.value)
        setIsBlocking(true)
    };

    const handleChangeMethod = (event) => {
        const {value} = event.target;
        setPaymentMethod(value);
        setIsBlocking(true)
    };


    const handleSubmitBill = (event) => {
        event.preventDefault();
        setIsBlocking(false)
        setSubmitted(true)
        const {due_amount} = totals
        const arr = products.every(item=> (item.quantity >= 0))
        const isPayment = (payment_method && paidAmount === due_amount)||(payment_method && paidAmount)
        if (arr && isPayment) {
            setOpenPrint(true)
        }

    };
    const handleCloseReceipt = () => {
        setOpenPrint(false);
        history.push('/patientbillinglist');

    };

    const handleClickReceipt = () => {
        const {notes} = state;
        const bedArr = bedBill.map(bed => ({
            assign_date: bed.assign_date,
            bill_type: bed.bill_type, invoice_no: bed.invoice_no,
            pay_status: bed.pay_status,
            visit_id: bed.visit_id,
            room_name: bed.room_name,
            bill_id: bed.bill_id,
            ward_name: bed.ward_name,
            bed_number: bed.bed_number,
            rate: bed.rate,
            bed_assignment_id: bed.bed_assignment_id,
            quantity: bed.quantity
        }))
        const editedServices = invoicesEdited.filter(item=>!item.isExcluded);
        const editedProducts = productsEdited.filter(item=>!item.isExcluded);
        const paidServices = invoicesPaid.filter(item=>!item.isExcluded);
        const paidProducts = productsPaid.filter(item=>!item.isExcluded);
        const editedInvoices = [...editedServices, ...editedProducts]
        const paidInvoices = [...paidServices, ...bedArr, ...paidProducts]
        const removedInvoices = [...invoicesRemoved, ...productsRemoved]

        const isBase = currency.split('-')[1]

        const isNotBase = isBase === 'false'
        const isBaseCurrency = isBase === 'true'

        const currency_id = parseInt(currency.split('-')[0]);

        const isBaseData = {is_base_currency:"true", currency_id:3,amount_paid: +paidAmount}
        const isNotBaseData = isNotBase ?  {currency_id,amount_paid_in_the_currency: +paidAmount, rate_input_by_cashier: +exchangeRate, total_amount_in_base_currency: +usdAmount,
            amount_paid : +usdAmount,is_base_currency:isBase} : {}


        const arr = products.every(item=>item.quantity >= 0)
        if (arr) {
            setOpenPrint(true)
            setIsSubmitted('pending');
            const params = status === '1' ? {} : {
                invoices_paid: paidInvoices,
                invoices_removed: removedInvoices,
                invoices_edited: editedInvoices
            };
            const url = status === '1' ? 'make_inpatient_deposit':'pay_in_patientbill';
            axios.post(`${config.smsUrl}/cbilling/${url}`, {
                // currency_id,
                pay_type: +payment_method,
                visit_id,
                received_by: user_roles_id,
                // amount_paid: +paidAmount,
                bill_mode: "cash",
                memo: notes ?? '',
                ...params,
                ...isBaseData
                // ...isBaseData, ...isNotBaseData
            }).then((res) => {
                const data = res.data ?? {};
                let receipt_number;
                if (typeof data.receipt_numbers === "string" || typeof data.receipt_numbers === "number") {
                    receipt_number = data.receipt_numbers ?? '';
                } else {
                    const numbers = data.receipt_numbers ?? [];
                    receipt_number = numbers.join('-');
                }
                actions.snackbarActions.snackSuccess('Bill paid successfully');
                setIsSubmitted('resolved');
                history.push(`/inpatientreceipt/${visit_id}/${receipt_number ? receipt_number : 0}`);
                setOpenPrint(false);
            }).catch(err => {
                setIsSubmitted('rejected');
                errorMessages(err, null, actions)
            })
       }
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const handleAddService =() =>{
        setAddService(true)
        
    }

    const handleAddProduct = () => {
        setAddProduct(true)
    }

    const handleCloseAddService = () => {
      // setAddService(false)
        handleCloseServiceDialog()
    }

    const handleCloseAddProduct=()=>{
      // setAddProduct(false)
        handleClosePrescriptionDialog()
    }


    return {
        payment_method,
        state,
        bedBill,
        services:procedureData,
        products,
        isSubmitted,
        date,
        openPrint,
        paidAmount,
        submitted,
        handleChangePaidAmount,
        handleDeleteServices,
        handleChangeDate,
        handleChangeMethod,
        payTypes,
        handleSubmitBill,
        handleCloseReceipt,
        handleClickReceipt,
        closeSnackbar,
        handleChangeProductRate,
        handleChangeServiceRate,
        handleDeleteProduct, handleChangeProductQuantity,
        handleAddProduct, handleAddService, addProduct, addService,
        handleCloseAddService, handleCloseAddProduct, departments,
        currencies, currency, handleChangeExchangeRate, handleChangeServiceCurrencyRate,
        exchangeRate, usdAmount, currencyTotal, handleChangeProductCurrencyRate,
        handleChangeCurrency, totals, renderPrescription, renderService,
        isBlocking,
        receiptTotals,
        paidReceipts
    }

}