import React, {useEffect, useState} from 'react'
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../actions/snackbarActions'
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { PLContainer,ProfitContainer} from '../../Utils/styledComponents';
import { usePrint } from '../../Utils/Templates/usePrint';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import {PrintPdfButtons} from "./PrintPdfButtons";
import Card  from '@material-ui/core/Card';
import {NoTransaction} from "../ChartofAccounts/ReusableJournalTable";
import { LoadingGif } from '../../Utils/Loader';
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {HospitalAddress} from "../../Utils/HospitalAddress";
import {COACell, TrialBalancePrint, TrialBalanceTree} from "../Utils/TrialBalanceTree";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {COATableCustomCell} from "../ChartofAccounts/TreeViewComponent";
import {history} from "../../../Helpers/history";
import {formatDigits} from "../../Utils/formatNumbers";
import Label from '../../Utils/FormInputs/Label';
import { CustomSelect } from '../../Utils/FormInputs/SelectInput';
import { Form } from 'react-bootstrap';

const header = (
    <TableHead>
        <TableRow>
            <COATableCustomCell><strong>Account</strong></COATableCustomCell>
            <COACell variant='200'><strong>Debit</strong></COACell>
            <COACell variant='120'><strong>Credit</strong></COACell>
        </TableRow>
    </TableHead>
)

const tree_label = (str_type, nav,   isParent) => {
    if (str_type === 'final_level') {
        return (
            <TableRow>
                <COATableCustomCell  onClick={()=>history.push(`/view_transactions/${nav.id}/${nav.head_code}-${nav.head_name}`)}
                                     style={{cursor:'pointer'}}>
                    <span  style={{color: '#0065ff',   display:'inline-block'}}>{nav.account_name || nav.name}</span></COATableCustomCell>
                <COACell variant='200'><strong>{ nav.debit_value ? formatDigits( nav.debit_value) : nav.debit_value}</strong></COACell>
                <COACell variant='120'><strong>{ nav.credit_value ? formatDigits( nav.credit_value) : nav.credit_value}</strong></COACell>
            </TableRow>
        )
    } else if (str_type === 'current_parent') {
        return (
            <TableRow>
                <COATableCustomCell  style={{fontWeight : isParent ? 'bold':''}}>
                    <span style={{fontWeight : isParent ? 'bold':''}}>{nav.account_name || nav.name}</span>
                </COATableCustomCell>
                <COACell variant='200' ><strong>{ nav.debit_value ? formatDigits( nav.debit_value) : nav.debit_value}</strong></COACell>
                <COACell variant='120' ><strong>{ nav.credit_value ? formatDigits( nav.credit_value) : nav.credit_value}</strong></COACell>
            </TableRow>
        )
    } else {
        return (
            <TableRow>
                <COATableCustomCell style={{fontWeight : isParent ? 'bold':''}} >{nav.account_name || nav.name}</COATableCustomCell>
                <COACell variant='200' ><strong>{ nav.debit_value ? formatDigits( nav.debit_value) : nav.debit_value}</strong></COACell>
                <COACell variant='120' ><strong>{ nav.credit_value ? formatDigits( nav.credit_value) : nav.credit_value}</strong></COACell>
            </TableRow>
        )
    }
}



const TrialBalance = ({actions, snackbar}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState('idle')
    const [accountingPeriod,setPeriod] = useState([])
    const [changePeriod, setChangePeriod] = useState('')

    useEffect (() =>{
    axios.get(`${config.smsUrl}/accounting/reports/accouting_period`).then(res => {
            const data = res.data;
            const dt = data ? data : [];
            const arr = dt.map(item=>({value:item.id, label:item.financial_year}))
            setPeriod(arr)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    },[])

    // useEffect(() => {
    //     setLoading('pending')
    //     axios.get(`${config.smsUrl}/accounting/reports/get_trial_balance`).then(res => {
    //         const data = res.data;
    //         const dt = data ? data : {};
    //         const dat = dt.data ? dt.data : []
    //         const arr = dat.reduce((a, b)=>a.concat(b.parent_accounts),[])
    //         setData(arr)
    //         setLoading('success')
    //     }).catch(err => {
    //         errorMessages(err, null, actions)
    //         setLoading('error')
    //     })

    // }, [])

    const handleChange = (value) =>{
    setChangePeriod(value)
    setLoading('pending')
        axios.get(`${config.smsUrl}/accounting/reports/get_trial_balance`,{params:{financial_period: value.value}}).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : []
            const arr = dat.reduce((a, b)=>a.concat(b.account),[])
            setData(arr)
            setLoading('success')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
        })

    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';


    const {open, variant, message} = snackbar;

    const trialBalanceData = data ? data : [];



    const {componentRef, handlePrint} = usePrint( `Trial Balance`)
    const printDoc = (
        <div  ref={componentRef}>
            <ProfitContainer>
                <HospitalAddress/>
                <table className="table table-sm table-borderless prescription-table">
                    <tbody>
                    <tr>
                        <td align='center'><h5><strong>Trial Balance</strong></h5></td>
                    </tr>
                    </tbody>
                </table>
                <TrialBalancePrint header={header} data={trialBalanceData} tree_label={tree_label}/>
                <p style={{textAlign: "left", fontStyle: "italic"}}  className="mpeke-footer">Powered by Mara Scientific</p>
            </ProfitContainer>
        </div>
    )

    return (
        <div className='journals'>
            <PageTitle title="Trial Balance"/>
            <SubHeader title="Reports" subTitle="Trial Balance">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>

            <MainSnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>


            <PLContainer>
                <div className="my-3">
                    <BackButton to='/reports/generalreports' text='General Reports'/>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <Form.Group  className="FormField">
                            <Label  name="Select financial period" />
                            <CustomSelect
                                onChange={handleChange}
                                value={changePeriod}
                                options={accountingPeriod}
                            />
                        </Form.Group>
                    </div>
                </div>

                {!changePeriod ? <div className='mt-2'>Please select financial period to generate the trial balance</div>: null}

                {isLoading ? <div className='text-center mt-5'>
                    <LoadingGif/>
                </div>:null}
                {isSuccess ? trialBalanceData.length > 0 ?
                    <Card>
                        <div style={{padding:'8px 32px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                            <PrintPdfButtons   handlePrint={handlePrint}/>
                        </div>
                        <div style={{padding:'32px'}}>
                            <div  id="profit-loss-statement-pdf" style={{overflow: "hidden"}}>
                                <HospitalAddress/>
                                <table className="table table-sm table-borderless prescription-table">
                                    <tbody>
                                    <tr>
                                        <td align='center'><h5><strong>Trial Balance</strong></h5></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <TrialBalanceTree header={header} data={trialBalanceData} tree_label={tree_label}/>
                                <p style={{textAlign: "center", fontStyle: "italic"}}>Powered by Mara Scientific</p>

                            </div>

                        </div>
                    </Card>: <NoTransaction text='transactions'/>: null}
                {isError ? <div>The server did not return a valid response</div>:null}
                <div style={{display:'none'}}>
                    {printDoc}
                </div>
            </PLContainer>
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(TrialBalance);
