import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {useReusableInventory} from "./CustomHooks/useReusableInventory";
import {convertMonth} from "../../Utils/ConvertDate";
import { exportPDF } from "../../Utils/downloadPDF";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'name', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'orderLevel', numeric: false, disablePadding: false, label: 'Reorder Level'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'}
];


const adjustmentHeadData = [{name:'Product', isRequired:true},{name:'Stock', isRequired:false},
{name:'Quantity', isRequired:true},{name:'Adjustment Type', isRequired:true},{name:'Total Stock', isRequired:false},{name:'Action', isRequired:false}]


const OutofStockReport = ({actions, snackbars}) => {
    const {data, search_type,  year, date,csvRef,downloadCsv, searchValue, order, orderBy, loading, total_count,
        handleChangeActivePage, handleCustomSearch, activePage, closeSnackbar, handleRequestSort,
    } = useReusableInventory(actions, 'out_of_stock')
   
    const {openBar, type, message} = snackbars;
    const stock = data.out_of_stock ?? [];
    const downloadPdf = () => {
        const {end_date, start_date} = date
        const time = search_type === '0' ? start_date : search_type === '1' ? convertMonth(start_date) :
            search_type === '2' ? year : `${start_date} to ${end_date}`;
        exportPDF(`Out of Stock Products (${time})`, [['SN', "Product",  "Reorder Level", "Stock"]],
            stock.map((item, index) => [index++, item.product_name, item.unit_name, item.reorder_level, item.stock]),
            `Out of Stock Products (${time}).pdf`);
    }

    const headers = [{label: 'Product', key: 'product_name'}, 
        {label: 'Reorder Level', key: 'reorder_level'},{label: 'Stock', key: 'stock'}]
    return (
        <div className='journals'>
            <PageTitle title="Out of Stock"/>
            <SubHeader title="Reports" subTitle="Out of Stock">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div style={{display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                <div style={{margin: '10px'}}>
                    <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
                </div>
                <div style={{margin: '10px'}}>
                    <DataExportButton headers={headers} title='Out of Stock Products'
                        csvRef={csvRef} data={stock} downLoadPdf={downloadPdf}
                        exportCsv={downloadCsv}/>
                </div>
            </div>
            <div className="mui-tables mt-2">
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="" handleChangeNextPage={handleChangeActivePage} term={searchValue}
                             records={10}
                             headData={headData} handler={handleCustomSearch} total_count={total_count}
                             activePage={activePage}
                             handleRequestSort={handleRequestSort} data={stock} colSpan={5} order={order}
                             orderBy={orderBy} customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan="8" align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : stock.length > 0 ?
                        <TableBody>
                            {stableSort(stock, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{item.sl}</TableCell>
                                            <TableCell>{item.product_name}</TableCell>
                                            <TableCell>{item.reorder_level}</TableCell>
                                            
                                            <TableCell>{item.stock_quantity}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan="8" align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>

            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(OutofStockReport);
