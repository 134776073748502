import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import { logoutErrorMessage } from "../../../../Helpers/ErrorMessages";
import { useDates } from "../../../Accounting/CustomHooks/useDates";

export const useReferralReports = (actions, referral_type ) => {

    // get the current user's details
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : '';

    const [patientDetails, setIds] = useState({patient_number: '', referral_date: '',hospital_from:'', kin_name:'',
     patient_type:'', patient_name:'', phone_no:'', patient_type: 1});
    const [referralList, setReferralList] = useState({referrals: [], total_count: 0});

    const [date, handleChangeDate] = useDates();
    const [searchValue, setSearchValue] = useState('');
    const [status, setStatus] = useState('idle')
    const [activePage, setActivePage] = useState(1);
    const [submitted, setSubmitted] = useState(false);
    const [p_type,setPatientType] = useState('0')
    

    const isLoading = status === 'pending';
    const isSuccess = status === 'success';
    const isError = status === 'error';
    const isResolved = status === 'resolved';

    const handleReferralList = () => {
        const formData = new FormData();
        formData.append('patient_type', p_type);
        formData.append('search', searchValue);
        formData.append('page', activePage);
        formData.append('referral_type',referral_type)

        setSubmitted(true);
        setStatus('pending');
        axios.post(`${config.smsUrl}/cmedicationround/referral_list/${activePage}`, formData).then(res => {
            const dt = res.data?? {};
            const dt_cnt = dt.total_count?? 0;
            const r_list = dt.referral ?? []
            console.log(dt_cnt)
            setStatus('success');
            // const arr = r_list.map((item, index) => ({
            //     ...item,
            //     count: (activePage - 1) * 10 + (index + 1),
            //     date: item.begin_datetime,
            // }))
            setSubmitted(false);
            setReferralList({referrals:r_list,total_count:dt_cnt});
           // setStatus('idle');
        }).catch(e => {
            logoutErrorMessage(e, null, actions);
            //errorMessages(err, null, actions);
            setStatus('error')
        })
    }

    const handleDateFilterSubmit = (event) => {
        event.preventDefault();
        handleReferralList();
    }

    const handleChange = (event) =>{
        // const {name, value} = event.target;
        setPatientType(event.target.value)
    }

    // call to get the referral list
    useEffect(() => {
        handleReferralList();
    },[p_type, activePage]);


    const handleSearch = (event) => {
        setSearchValue(event.target.value);
    };

    const handleChangeNewPage = (page) => {
        setActivePage(page);
    };

    const handlePatientDetails = (item) => {
        setIds(item);
    }

    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    
    // const handleDownloadList = () => {
    //     if (visitList['total_count'] > 0) {
    //         const {from_date, to_date} = date;
    //         axios.get(`${config.smsUrl}/cvisit/previous_visit_list/excel_report`, 
    //                 {
    //                 params: {
    //                     'start_date': from_date,
    //                     'end_date': to_date,
    //                     'feedback_status': feedback_status,
    //                     download:true
    //                     },responseType: 'blob'
    //                 }
    //             ).then(response=>{
    //             const data  = response.data;
    //             const url = URL.createObjectURL(new Blob([data]));
    //             const link = document.createElement('a');
    //             link.href =  url;
    //             link.setAttribute('download', `PreviousVisits_${ (feedback_status === true) ? 'With':'Without'}Feedback-${current_date}.xlsx`);
    //             document.body.appendChild(link);
    //             link.click();
    //         }).catch(err=>{
    //             logoutErrorMessage(err, null, actions);
    //         })
    //     } else {
    //         actions.snackbarActions.snackError("No record to download");
    //     }
    // }

    

    return {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, user_roles_id,
        referralList, actions, _token, date, handleChangeDate, p_type, handleChange,
        patientDetails, isLoading, isSuccess, isError, handlePatientDetails, submitted, handleDateFilterSubmit}
}