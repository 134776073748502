import React, {useState} from "react";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import SimpleMenu from "../Utils/Menu/SimpleMenu";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import MenuItem from "@material-ui/core/MenuItem";
import * as doctorActions from "../../actions/DoctorsActions/doctorActions"
import * as snackbarActions from "../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PageTitle from "../Utils/smsTitle";
import {Link} from "react-router-dom";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {formatDateTime} from "../Utils/ConvertDate";
import {useDoctorWaitingList} from "./CustomHooks/useDoctorWaitingList";
import {history} from "../../Helpers/history";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import { PatientDetails } from "../Visit/NewService/SelectService";
import ReusableDialog from "../Utils/Dialogs/ReusableDialog";
import Form from 'react-bootstrap/Form';
import Label from "../Utils/FormInputs/Label";
import { CustomSelect } from "../Utils/FormInputs/SelectInput";
import {ErrorMessage, RightAlignedContainer} from "../Utils/styledComponents";
import DeleteDialog from "../Utils/Dialogs/DeleteDialog";
import styled from "@emotion/styled/macro";
import * as colors from "../../styles/colors"
import {FullScreenDialog} from "../Utils/Dialogs/FullScreenDialog";
import {ReusableTreatmentHistory, Spacer} from "./ReusableTreatmentHistory";
import { usePrint } from '../Utils/Templates/usePrint';
import {useRetrievePatientCount} from "./hooks/useRetrievePatientCount";
import {
    ResultsHeading, PreviousTriage, PreviousClinicalNotes,
    PreviousDiagnosis, Heading, Border, SelectForm, UpdateCompletedDiagnosis
} from "./PatientPreviousVisits";
import {useVisitList} from "../Visit/NewVisit/hooks/useVisitList";
import {useObservationChart} from "../MedicationandVisits/CustomHooks/useObservationChart";
import {doctorRoutes} from "../Utils/Menu/ReusableDoctorTabs";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import Triage from "../Triage/Triage";
import {NotesRow, PreviousNotesTable} from "./Notes/PreviousNotesTable";
import {SavedNotesContainer} from "./Notes/PatientNotesContainer";
import {splitNotes} from "../Radiology/RadiologyReport";
import {SaveNotesForm} from "../MedicationandVisits/Findings/PatientFindings";


const Status = styled.span({
    borderRadius:'15px',
    display:'inline-block',
    padding:'3px 10px',
    fontSize:'11px'
})

const PendingStatus = styled(Status)({
    background:colors.lightOrange,
    color:colors.pending
})

export const Progress = styled(Status)({
    background:colors.progress,
    color: colors.progressText
})

const FinishedStatus = styled(Status)({
    background:colors.lightGreen,
    color:colors.green
})


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'Sl.',hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date',hidden:false},
    {id: 'patientNo', numeric: false, disablePadding: false, label: 'Patient No.',hidden:false},
    {id: 'patientName', numeric: false, disablePadding: false, label: 'Patient Name',hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone ',hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone',hidden:false},
    {id: 'doctor_name', numeric: false, disablePadding: false, label: 'Doctor Name',hidden:false},
    {id: 'visitDepartment', numeric: false, disablePadding: false, label: 'Visit Department',hidden:false},
    {id: 'status', numeric: false, disablePadding: false, label: 'Status',hidden:false},
    {id: 'actions', numeric: false, disablePadding: true, label: 'Action',hidden:false},
];

const useEditCompletedTriage = (actions, patient_number, visit_id) =>{
    const [takeVitals, setTakeVitals] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { handleSubmitTriage, triageProps,  handlePatientDetails, isTriage,
        handleEditTriage} = useVisitList(actions,'');



    const {triageList, triage:propsTriage, setSubmitted:setSubmitTriage, isEditTriage, setEditTriage,
        setAllMetrics,  clearTriageDetails, setSaveTriage,
    } = triageProps;

    const arr1 = triageList.filter(metric=>metric.metric_id !== 6).some(metric=>metric.metric_value);
    const arr2 = propsTriage.some(metric => metric.metric_value);
    const IskipTriage = (arr1 && arr2);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        if (isEditTriage) {
            setEditTriage(false);
            clearTriageDetails();
        } else {
            setSaveTriage(false);
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handlePatientDetails({patient_number, visit_id});
    };

    const handleTakeVitals = (e) => {
        setTakeVitals(true);
        setSaveTriage(true);
        //  setEditTriage(false);
        setSubmitTriage(false);
        handleOpenDialog();
    }

    const handleSubmitTriageSurgery = (event) => {
        handleSubmitTriage(event);
        if (IskipTriage) {
            setTakeVitals(false);
            handleCloseDialog();
        }
    }

    const handleOpenEditTriage = (item) => {
        setEditTriage(true);
        const dt = item.data ? item.data : [];
        setAllMetrics(dt);
        handleOpenDialog();
    }

    const handleCloseEditTriage = (event) => {
        setEditTriage(false);
        clearTriageDetails();
        handleEditTriage(event);
        handleCloseDialog();
    }

    return {takeVitals, openDialog, handleTakeVitals,handleOpenEditTriage, handleCloseEditTriage, handleCloseDialog,
    triageProps}
}



export const PatientWaitingList = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'));
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];

    const {actions} = props

    const {searchValue, openMenu, order, orderBy, page, rowsPerPage,
        activePage, handleSearch, handleCloseMenu, handleRequestSort, handleChangeNextPage,
        handleClickPatient, closeSnackbar,waitingList, total_count, isLoading, isSuccess, isRejected,
        openAssign, handleOpenAssign, doctor_name, doctors, handleChangeDoctor, isSubmitted, submitted,
        handleCloseAssign,  ids, assignDoctor,department,isEnding,
        departments, handleChangeDepartment,openEnd, handleOpenEndVisit,
        handleCloseEndVisit, handleEndVisit, patientProps} = useDoctorWaitingList(props,_token)
    const {openDialog, handleOpenDialog, handleCloseDialog, option, options, handleChangeOption } = patientProps
    const {total_patients} = useRetrievePatientCount(actions)

    const components = [{label: 'Waiting List', path: '/waitinglist', badge:true, badge_count:total_patients},
    //     {
    //     label: 'Lab/Radiology Results',
    //     path: '/pendingdoctorrequests'
    // }
    {label: 'Patients Seen', path: '/patientsSeen'}];
    const permission = !perm ? {} : perm;
    const waiting_list = !permission.waiting_list ? {} : permission.waiting_list;
    const doctor_list = !waitingList ? [] : waitingList;
    const {openBar, type, message} = props.snackbars;
    const { patient_number, patient_name} = ids;



    const {componentRef, handlePrint,  fontSize}  = usePrint('Medical History Report');




    const {treatmentDetails, handleOpenNote, isPrint, setIsPrint,
        handleEdit, handleCancelEdit, handleChangeNotes, handleUpdateNotes,
        submitted:submittedNotes,isPendingEdit, diagnosisProps, triageProps} = patientProps
    const {isEditDiagnosis,  handleEditDiagnosis} = diagnosisProps

    const handlePrintDetails = () =>{
        handlePrint()
        setIsPrint(true)
    }
    

    const {patientDiagnosis, patientTriage, clinicalNotes} = treatmentDetails



    const notesHeadData = [{name:'Date'},{name:'Written By'}]

    const triageComponent = (
        patientTriage?.length > 0 ?  <>
                <ResultsHeading>
                    <Heading>Triage</Heading>
                    <Border/>
                </ResultsHeading>
                <PreviousTriage {...{patientTriage, isEditTriage: !isPrint, ...triageProps}} />
            </>: null
    )

    const clinicalNotesComponent = (
        clinicalNotes?.length > 0 ?
                <Spacer>
                    <ResultsHeading>
                        <Heading>Clinical Notes</Heading>
                        <Border/>
                    </ResultsHeading>
                    <PreviousNotesTable headData={notesHeadData}>
                        {clinicalNotes.map(item=>{

                            const examNotes = {
                                physical_examination:item.physical_examination, past_medical_history:item.past_medical_history, past_obstetric_history:item.past_obstetric_history,
                                family_history:item.family_history, notes:item.notes, complaints:item.complaints
                            }

                            const updateForm = (
                                <>
                                    <SaveNotesForm submitted={submittedNotes} handleSubmit={(e)=>handleUpdateNotes(e, item)}
                                                   handleChangeState={(e)=>handleChangeNotes(e, item.examination_notes_id)}
                                                   examNotes={examNotes} isEdit>
                                        <button type="submit" disabled={isPendingEdit}
                                                className="btn sms-btn small-btn btn-sm mt-2 mr-3">{isPendingEdit ? "Saving...." : "Update"}</button>
                                        <button type='button' onClick={()=>handleCancelEdit(item.examination_notes_id)} className="btn sms-gray-btn small-btn  btn-sm mt-2">Cancel</button>

                                    </SaveNotesForm>
                                </>

                            )
                            const viewNotes = (
                                <div className='row mb-0'>
                                    <div className='col-lg-6'>
                                        {item.physical_examination ?
                                            <>
                                                <Label name='Physical examination'/>
                                                <SavedNotesContainer data-testid="p_examination">
                                                    {splitNotes(item.physical_examination)}
                                                </SavedNotesContainer>
                                            </>: null}
                                        {item.past_medical_history ? <>
                                            <Label name='Past medical history'/>
                                            <SavedNotesContainer data-testid="medical_history">
                                                {splitNotes(item.past_medical_history)}
                                            </SavedNotesContainer>
                                        </>: null}
                                        {item.notes ? <>
                                            <Label name='Notes'/>
                                            <SavedNotesContainer data-testid="clinical_notes">
                                                {splitNotes(item.notes)}
                                            </SavedNotesContainer>
                                        </>: null}
                                        {item.complaints ? <>
                                            <Label name='Presenting Complaints'/>
                                            <SavedNotesContainer data-testid="complaints">
                                                {splitNotes(item.complaints)}
                                            </SavedNotesContainer>
                                        </>: null}

                                    </div>
                                    <div className='col-lg-6'>
                                        {item.family_history ? <>
                                            <Label name='Family history'/>
                                            <SavedNotesContainer data-testid="f_history">
                                                {splitNotes(item.family_history)}
                                            </SavedNotesContainer>
                                        </>: null}
                                        {item.past_obstetric_history ? <>
                                            <Label name='Past obstetric history'/>
                                            <SavedNotesContainer data-testid="obs_history">
                                                {splitNotes(item.past_obstetric_history)}
                                            </SavedNotesContainer>
                                        </>: null}

                                    </div>
                                </div>
                            )
                            return(
                                <NotesRow open={item.openNote} date={item.start_time ? formatDateTime(item.start_time):''} handleOpen={()=>handleOpenNote(item.examination_notes_id)}
                                          person={`${item.doctor_firstname} ${item.doctor_lastname}`} colSpan={notesHeadData.length}>
                                    {!item.displayInput ?
                                        <>
                                            {viewNotes}
                                            {item.isEdit && !isPrint ? <button className='btn-sm btn small-btn sms-info-btn' onClick={()=>handleEdit(item.examination_notes_id)}>Edit notes</button> : null}
                                        </>
                                        :null}
                                    {item.displayInput ? updateForm : null}
                                </NotesRow>
                            )})}
                    </PreviousNotesTable>

                </Spacer>:  null
    )
    const editDiagnosisButton = (
        <RightAlignedContainer>
            <button type="button" onClick={handleEditDiagnosis} className='btn btn-sm small-btn sms-info-btn px-3 mb-2'>Edit</button>
        </RightAlignedContainer>

    )

    const diagnosisComponent = (
        patientDiagnosis?.length > 0 ?
                <Spacer>
                    {isEditDiagnosis ? <>
                            <ResultsHeading>
                                <Heading>Diagnosis</Heading>
                                <Border/>
                            </ResultsHeading>
                            <UpdateCompletedDiagnosis {...diagnosisProps}/>
                        </> :
                        <PreviousDiagnosis {...{patientDiagnosis}} editDiagnosis={!isPrint ?  editDiagnosisButton : null} />}

                </Spacer>
                : null
    )

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);
    return (
        <div data-testid="doctor-waiting-list" className='journals'>
            <PageTitle title="Doctor Waiting List"/>
            <SubHeader title="Doctor" subTitle="Waiting List">
                <FontAwesomeIcon icon={faUserMd}/>
            </SubHeader>


            <DeleteDialog message="end" openDialog={openEnd} handleClose={handleCloseEndVisit}
                          text="visit" title='End Visit'>
                <button className=' btn sms-info-btn btn-sm' id="end_visit" disabled={isEnding} onClick={handleEndVisit}>End Visit</button>
                <button className="btn sms-grey-btn btn-sm" onClick={handleCloseEndVisit}>No</button>
            </DeleteDialog>

            <FullScreenDialog open={openDialog} title='Visit Details' handleClose={handleCloseDialog}>
                <SelectForm {...{options, option, handleChangeOption, handlePrint:handlePrintDetails}}/>
                <div ref={componentRef}>
                        {fontSize}
                    <ReusableTreatmentHistory {...{...patientProps, clinicalNotesComponent, triageComponent,diagnosisComponent}}/>
                </div>
            </FullScreenDialog>
            <ReusableDialog message='Reassign Doctor' openDialog={openAssign} handleClose={handleCloseAssign}
            isSubmitted={isSubmitted} handleSubmit={assignDoctor} width='sm'>
                <PatientDetails {...{patient_number,patient_name}}/>
                    <div className="my-2">
                        <Form.Group>
                            <Label name="Department" htmlFor='department' type/>
                            <CustomSelect onChange={handleChangeDepartment} id='department' value={department} options={departments}/>
                            {(submitted && !department) && <ErrorMessage>This is a required field</ErrorMessage>}
                        </Form.Group>
                        <Form.Group>
                            <Label name="Doctor" htmlFor='doctor' type/>
                            <CustomSelect onChange={handleChangeDoctor} id='doctor' value={doctor_name} options={doctors}/>
                            {(submitted && !doctor_name) && <ErrorMessage>This is a required field</ErrorMessage>}
                        </Form.Group>
                    </div>
            </ReusableDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className="mui-tables">
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <CustomTable title="Waiting List" customPage handleChangeNextPage={handleChangeNextPage}
                             headData={headCells} handler={handleSearch} records={10} total_count={total_count}
                             activePage={activePage}
                             handleRequestSort={handleRequestSort} data={doctor_list} colSpan={5} order={order}
                             orderBy={orderBy} term={searchValue}
                             handleHeadCells={handleHeadCells} all_hidden={all_hidden}
                             handleAllHeadCells={handleAllHeadCells}>
                    
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                        alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ?  doctor_list.length > 0 ? doctor_list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.visit_id}
                                            className='mui-table-row '
                                        >
                                            <TableCell>{item.count}</TableCell>
                                            <TableCell hidden={headCells[1].hidden}>{item.date ? formatDateTime(item.date) : item.date}</TableCell>
                                            <TableCell hidden={headCells[2].hidden}>{item.patient_number}</TableCell>
                                            <TableCell hidden={headCells[3].hidden}>{item.patient_name}</TableCell>
                                            <TableCell hidden={headCells[4].hidden}>{item.phone_no}</TableCell>
                                            <TableCell hidden={headCells[5].hidden}>{item.kin_phone_no}</TableCell>
                                            <TableCell hidden={headCells[6].hidden}>{`${item.doctor_first_name} ${item.doctor_last_name}`}</TableCell>
                                            <TableCell hidden={headCells[7].hidden}>{item.visit_department}</TableCell>
                                            <TableCell hidden={headCells[8].hidden}>{item.status === 2 ? <PendingStatus>Pending</PendingStatus> : item.status === 3 ? <Progress>Treatment ongoing</Progress>:
                                            item.status === 4 ? <FinishedStatus>Complete</FinishedStatus>: null}</TableCell>
                                            <TableCell hidden={headCells[9].hidden}>
                                                {item.status !== 4 ? <>
                                                <button className={`btn btn-sm mr-3  ${item.status === 2 ? 'sms-info-btn':'sms-amber-btn'}`}
                                                        onClick={() => handleClickPatient(item.patient_number, item.visit_id, 1,item.activity_id)}>{
                                                            item.status === 2 ? "Take up" : "Continue"}
                                                </button>
                                                <button onClick={()=>handleOpenAssign(item)}
                                                        className="btn btn-sm sms-gray-btn mr-3 ">Reassign</button>

                                                {item.status !== 2 ?  <button onClick={()=>handleOpenEndVisit(item)}
                                                        className="btn btn-sm sms-info-btn mr-3 ">End Visit</button> :  null}
                                                </> : <button className='btn btn-sm sms-info-btn ' onClick={()=>handleOpenDialog(item)}>View Details</button>}
                                            </TableCell>
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isRejected ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow> : null }
                        </TableBody>
                </CustomTable>
                <SimpleMenu anchorEl={openMenu} handleClose={handleCloseMenu}>
                    {waiting_list.update &&
                    <Link to="/doctordashboard" style={{textDecoration: "none", color: "#474747"}}>
                        <MenuItem>Take Up</MenuItem></Link>}
                </SimpleMenu>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {doctors: state.doctors, snackbars: state.snackbar}

}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            doctorActions: bindActionCreators(doctorActions, dispatch),
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientWaitingList);
