import React, {useEffect, useState} from "react";
import {useVisitPatientTriage} from "../../../Triage/hooks/useVisitPatientTriage"
import {useRetrieveVisits} from "../../CustomHooks/useRetrieveVisits"
import {useSelfRequestPrescription} from "../../NewService/hooks/usePrescriptionSelfRequest";
import {useNewServiceRequest} from "../../NewService/hooks/useNewServiceRequest";

export const useVisitList = (actions,activity_id= null) => {

    const [ids, setIds] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const [visitList, setVisitList] = useState({visits: [], total_count: 0});
    const [tabValue, setTabValue] = React.useState(0);

    const {patient_number, visit_id,visit_type} = ids
    const obj = {patient_number,visit_id,visit_type, setOpenDialog,activity_id,actions}

    const {renderPrescription, isResolved:isResolvedPrescription, handleClosePrescriptionDialog} = useSelfRequestPrescription(obj)
    const {renderService, isResolvedService, handleCloseServiceDialog} = useNewServiceRequest(obj)

    const {triageProps} = useVisitPatientTriage(actions, obj)



    const {isResolved:isTriage, handleSubmitTriage, handleEditTriage} = triageProps;

    const isResolved = isTriage || isResolvedPrescription || isResolvedService;

    const {searchValue, data,  activePage,isError, isLoading, isSuccess,
    handleSearch,handleChangeNewPage,cancelProps} =useRetrieveVisits(actions, isResolved,'cvisit/retrieve_all_visit_with_triage');
    
    useEffect(()=>{
        const list = !data.triage_list ? [] : data.triage_list;
        const count = !data.total_count ? 0 : data.total_count;
        if(!(list && count)){
            return
        }
        const arr = list.map((item, index) => ({
            ...item,
            count: (activePage - 1) * 10 + (index + 1),
            date: item.begin_datetime,
            visit_id: item.visit_id,
            patient_number: item.patient_number,
            patient_name: `${item.patient_fn} ${item.patient_ln}`,
            status: item.status,
            referal_status: item.referal_status,
            
        }))
        setVisitList({visits:arr,total_count:count});

    }, [data])





    const handlePatientDetails = (item) => {
        setIds(item);
    }

    const handleOpenDialog = (item, screen) => {
        setOpenDialog(true)
        const {visit_id, patient_number,  status, patient_name, age, gender} = item
        setIds({visit_id, patient_number, status, patient_name, age, gender, screen});

    };

    const handleCloseDialog = () => {
        // setOpenDialog(false)
        if(tabValue === 1){
            handleClosePrescriptionDialog()
        }else{
            handleCloseServiceDialog()
        }

    };

    const handleChangeTabValue = (event,val) => {
        setTabValue(val)
    }
    
    const closeSnackBar = () => {
        actions.snackbarActions.hideSnackbar()
    };


    return {searchValue, activePage, handleSearch, handleChangeNewPage, closeSnackBar, openDialog, handleEditTriage,
        visitList,  handleOpenDialog, handleCloseDialog, triageProps, isTriage, renderService, renderPrescription,
        ids,isLoading, isSuccess, isError,handlePatientDetails, handleSubmitTriage,tabValue,handleChangeTabValue,
    cancelProps}
}