import React from "react";
import {useTheme} from "@material-ui/core/styles";
import TextField from "../Utils/FormInputs/TextField";
import { ErrorMessage} from "../Utils/styledComponents";
import ReusablePrescriptionDetails from "./ReusablePrescription";
import {PendingStatus, FinishedStatus, Processing, StyledIcon} from "../DoctorsModule/doctorStyles";
import { Progress } from "../DoctorsModule/WaitingList";
import {AutoCompleteProducts, AutoCompleteUnControlled} from "../Utils/FormInputs/AutoCompleteInput";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { StyledCell, StyledRemoveIcon } from "../DoctorsModule/doctorStyles";
import styled from "@emotion/styled/macro";
import CloseIcon from "@material-ui/icons/Close";
import {ExpiredStatus} from "../DoctorsModule/doctorStyles";
import {DataCell} from "../Utils/Lists/CollapsibleList";

const StyledEditIcon = styled(StyledIcon)({
    fill:'white',
    width:'0.6em',
    height:'0.6em'
})

const StyledCloseIcon = styled(CloseIcon)({
    fill:'#c4495c',
    width:'0.6em',
    height:'0.6em'
})

const StyledIconButton = styled(IconButton)({
    background:'#1361c1',
    padding:'3px',
    '&:hover':{
        background:'#1361c1',
    }
})

const Expired = styled(ExpiredStatus)({
    paddingTop:0,
    paddingBottom:0,
    fontSize:'10px'
})

const FillPrescription = ({rows, state, handleSubmit,submitted, retrieveBatches, getBatchDetails, current_batches, handleChosenBatchQuantity,
    handleOnClick, item_status,handleChangeMedicine,drugList, clickDrug, retrieveDrugs,handleCancelChangeMedicine,
    removeBatchRow, handleAddBatchRow, isPending, patient_type, component, handleOnBlur}) => {
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const isOutPatient = patient_type === 1
    const isInPatient = patient_type === 2
    const isPrevious = component === 'previous';
    const text = isOutPatient ? 'Out-Patient':'In-Patient'

    const headData = [{name:'Drug Name', width:300},{name:'Quantity Billed', width:100},
        {name:'Chosen Batch ID(s)', width:300, sub_headings: isPrevious || item_status === 4 ? ["Batch ID", "Chosen Quantity"] :
        ["Batch ID", "Available Quantity", "Chosen Quantity", "Action"]},
        isInPatient ?  {name:'Quantity Given'} : null,
        isInPatient && isPrevious ? {name: 'Quantity Returned'} : null,
        {name:'Dosage', width:100},{name:'Frequency'},{name:'Number of Days', width:100},
        {name:'Instructions'}, !isPrevious ? {name: 'Status'} : null ];

    return (
        <form onSubmit={handleSubmit} id="submit-prescription" autoComplete="off">
            <ReusablePrescriptionDetails {...{headData:headData.filter(item=>Boolean(item)),text,state, isFillPrescription:true}}>
                <tbody>
                    {rows.length > 0 ? rows.map((row, index) => {
                        //const isStopped = row.status === 7;

                        const medicine = (
                            <>
                                {row.medicine_name} 
                                {isOutPatient &&
                                
                                <span> <Tooltip title='Change drug'>
                                <StyledIconButton type='button' onClick={()=>handleChangeMedicine(index)}>
                                    <StyledEditIcon/>
                                </StyledIconButton>
                            </Tooltip></span>}
                            </>
                        );

                        const isMedicineChange = (
                                <>

                                        <AutoCompleteProducts options={drugList} submitted={submitted} idx={index} handleChange={retrieveDrugs} label_text="label"
                                                          medicine_value={{label:rows[index].alternative_product_name, value: rows[index].alternative_product_id,
                                                          generic_name: rows[index].generic_name}} id="drug_name" value_text="value"
                                                          errorMsg_Valid={"Enter valid drug name"} valid_error_check={row.isError && submitted}
                                                          handleProductData={clickDrug} placeholder="Type drug name"
                                                          errorMsg="Enter drug name"/>
                                    {/*<span> */}
                                    {/*    <Tooltip title='Cancel'>*/}
                                    {/*        <IconButton type='button' onClick={()=>handleCancelChangeMedicine(index)}>*/}
                                    {/*            <StyledCloseIcon/>*/}
                                    {/*        </IconButton>*/}
                                    {/*    </Tooltip>*/}
                                    {/*</span>*/}
                                </>
                            )
                        return (
                            <tr key={index}>
                                <DataCell variant='200'>
                                    {isPrevious ? row.medicine_name : (row.isChange ? isMedicineChange : medicine)}
                                </DataCell>
                                <DataCell variant='100'>
                                    {row.quantity_billed}
                                    {/*(isOutPatient || isPrevious || isStopped) ? row.quantity :
                                    <>
                                        <TextField disabled={row.discharge_status} submitted={submitted} type="number" value={row.quantity}
                                        min="0"
                                            onChange={(e) => handleChangeQuantity(e, index)}/>
                                        {submitted && !row.quantity ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                    </>
                                    */}
                                </DataCell>
                               
                                <DataCell>
                                    {isPrevious || (isInPatient && row.status === 4) ?
                                        <table>
                                            <tbody>
                                                {row.batches?.map((btch, btch_idx) => (
                                                    <tr key={btch_idx}>
                                                        <DataCell variant='100'>
                                                            {btch.batch_id}
                                                        </DataCell>
                                                        <DataCell variant='100'>
                                                            {btch.quantity}
                                                        </DataCell>
                                                    </tr>
                                                ))}                                   
                                            </tbody>
                                        </table>  
                                        :
                                        <table>
                                        <tbody>
                                            {row.selected_batch_ids?.map((btch, btch_idx) => {
                                                const renderOption = option => (
                                                    <>
                                                        <span style={{display:"inline-block", marginRight:'6px'}}>{option.label}</span>{option.isExpired ? <Expired>Expired</Expired>: null}
                                                    </>
                                                )
                                                    return (
                                                        <tr key={btch_idx}>
                                                            <DataCell variant='150'>
                                                                <AutoCompleteUnControlled options={current_batches}
                                                                                          submitted={submitted} idx={index}
                                                                                          handleChange={retrieveBatches}
                                                                                          label_text="label"
                                                                                          sub_idx={btch_idx}
                                                                                          onBlur={handleOnBlur}
                                                                                          onFocus={() => handleOnClick(index)}
                                                                                          medicine_value={{
                                                                                              label: btch.batch_id,
                                                                                              value: btch.batch_id
                                                                                          }} id="batch_ids"
                                                                                          value_text="value"
                                                                                          errorMsg_Valid="Please enter a valid batch id"
                                                                                          valid_error_check={btch.isError}
                                                                                          handleProductData={getBatchDetails}
                                                                                          placeholder="Type Batch ID"
                                                                                          errorMsg="Please select a batch ID"
                                                                                          renderOption={renderOption}
                                                                />
                                                                {btch.batch_id && btch.isDuplicate && !btch.isExpired  && <ErrorMessage>This Batch ID was already
                                                                    selected</ErrorMessage>}
                                                                {btch.batch_id && btch.isExpired  && <ErrorMessage>This batch is expired</ErrorMessage>}
                                                            </DataCell>
                                                            <DataCell variant='100'>
                                                                {btch.available_quantity}
                                                            </DataCell>
                                                            <DataCell variant='100'>
                                                                <TextField submitted={submitted} type="number" disabled={btch.isExpired}
                                                                           value={btch.chosen_quantity} min="0"
                                                                           onChange={(e) => handleChosenBatchQuantity(e, index, btch_idx)}
                                                                           style={{fontSize: '11px'}}/>
                                                                {btch.chosen_quantity > btch.available_quantity &&
                                                                    <ErrorMessage>Chosen Quantity is greater than available
                                                                        quantity</ErrorMessage>
                                                                }
                                                                {submitted && (!btch.chosen_quantity) &&
                                                                    <ErrorMessage>This is a required field</ErrorMessage>}
                                                            </DataCell>

                                                            <DataCell>
                                                                <Tooltip title='Remove'>
                                                                    <IconButton type='button'
                                                                                onClick={() => removeBatchRow(index, btch_idx, row)}>
                                                                        <StyledRemoveIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </DataCell>
                                                        </tr>
                                                    )
                                                }
                                            )}
                                            <tr>
                                                <StyledCell colSpan="4">
                                                    <button type="button" onClick={()=>handleAddBatchRow(index, row)} className="btn btn-sm sms-gray-btn"
                                                            id="add-row">Add another batch
                                                    </button>
                                                    {submitted && (parseInt(row.total_chosen_quantity) !== parseInt(row.quantity_billed)) &&
                                                    <ErrorMessage>Chosen Quantity is not equal to prescribed quantity</ErrorMessage>}
                                                </StyledCell>
                                            </tr>                                    
                                        </tbody>
                                        </table>  
                                    }                    
                                </DataCell>
                                 
                                {isInPatient ? <td>{row.quantity_given}</td> : null}
                                {isInPatient && isPrevious ? <td>{row.quantity_returned}</td> : null}
                                <td>{row.dosage}</td>
                                <td>{row.frequency}</td>
                                <td>{row.number_of_days}</td>
                                <td>{row.instructions}</td>
                                {!isPrevious ?
                                <td>{row.status === 3 ? <Progress>Treatment ongoing</Progress> :
                                row.status === 7 ? <PendingStatus>Stopped</PendingStatus> : 
                                isInPatient && row.status === 4 ? <FinishedStatus>Dispensed</FinishedStatus> :
                                isInPatient && row.status === 6 ? <Processing>Being Discharged</Processing> : null}</td>
                                : null}
                            </tr>
                        )
                    }): 
                    <tr>
                        <td colSpan="9" align="center"
                        className={palette.type === 'dark' && 'dark-theme-color'}>No drugs
                        prescribed
                        </td>
                    </tr>}
                </tbody> 
            </ReusablePrescriptionDetails>
            {!isPrevious ? 
            <button type="submit" className="btn btn-sm sms-btn px-4" id="submit-btn"
                        disabled={isPending}>{isPending ? 'Saving...' : 'Save'}</button>:null}
        </form>
     
    )
};

export default FillPrescription;
