import {useEffect, useState} from "react";
import {dateConvert} from "../../../Utils/ConvertDate";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {history} from "../../../../Helpers/history";

export const useRefundBill = (actions, match) => {
    const {visit_id} = match.params;
    const [state, setState] = useState({notes: '', patient_name: '', patient_number: '', address: ''});
    const [services, setServices] = useState([]);
    const [products, setProducts] = useState([]);
    const [billDetails, setBillDetails] = useState({});
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [date, setDate] = useState(dateConvert());
    const [openPrint, setOpenPrint] = useState(false);
    const [totalAmounts, setTotalAmounts] = useState({total_amount:0, currency_total_amount:0, currency_paid_amount:0, paid_amount:0,
        due_amount:0,total_refund:0, currency_total_refund:0});
    const [submitted, setSubmitted] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [ids, setIds] = useState({bill_id: '', status: '',});
    const [name, setName] = useState('');
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;



    useEffect(() => {
        if(!visit_id){
            return;
        }
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        formData.append('pay_status', 1);
        axios.post(`${config.smsUrl}/cbilling/bill_update_form`, formData).then(res => {
            const data = res.data;
            const all_data = !data ? {} : data;
            const info = !all_data.patient_info ? {} : all_data.patient_info;
            const user = info.patient_number ? info.patient_number : ''
            setBillDetails(all_data);
            setName(user)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, [visit_id]);

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    useEffect(() => {
        if(!name){
            return;
        }
        const {patient_info, bill} = billDetails;
        const billObj = bill[0] ? bill[0] : {};

        const patient = patient_info ? patient_info : {};
        setState({
            patient_name: titleCase(`${!patient.patient_firstname ? "" : patient.patient_firstname} ${!patient.patient_lastname ? "" : patient.patient_lastname}`),
            patient_number: !patient.patient_number ? "" : patient.patient_number,
            address: titleCase(!patient.address ? "" : patient.address), notes: ''
        });

        const serviceArr = bill.filter(item => item.service_id).map((service => ({
            ...service,subTotal: +service.rate * +service.quantity,currency_total:service.currency_total,
            status: service.pay_status, refund_amount:0, isRefund:false, currency_rate: +service.rate / +service.currency_rate
        })));

        const productsArr = bill.filter(item => item.product_id).map((product => ({
            ...product,subTotal: (product.rate ? +product.rate :0) * +product.quantity,status: product.pay_status, refund_amount:0,
            isRefund:false,currency_total:product.currency_total, currency_rate: +product.rate / +product.currency_rate
        })));


        setExchangeRate(billObj.currency_rate)
        setCurrency(`${billObj?.currency_id}-${billObj?.is_base_currency}-${billObj?.currency_symbol}`)



        calculateTotal(serviceArr, productsArr, billObj?.currency_rate)
        setProducts(productsArr)
        setServices(serviceArr)
       
    }, [name]);


    const reduceAmounts = (arr = []) =>{
        return arr.reduce((a, item) => {
            return a + item.amount
        }, 0)
    }

    const calculateTotal = (procArr, prdts, rate) => {
        const billArr = [...procArr, ...prdts]

        // calculating the paid amount
        const pAmount = reduceAmounts(billArr.map(item=>({...item, amount:item.amount_paid})));
        const currencyPaidAmount = pAmount / rate

        //usd total amounts
        const proc_total = reduceAmounts(procArr.map(item=>({...item, amount:item.subTotal})))
        const prdtArr = reduceAmounts(prdts.map(item=>({...item, amount:item.subTotal})))
        const currencyTotalAmount = (+proc_total + +prdtArr) / rate

        //refund total amounts
        const service_refund = reduceAmounts(procArr.map(item=>({...item, amount:item.refund_amount})))
        const product_refund = reduceAmounts(prdts.map(item=>({...item, amount:item.refund_amount})))
        const totalRefundAmount = (+service_refund + +product_refund) / rate

        setTotalAmounts({...totalAmounts, total_refund:service_refund + product_refund,
            currency_total_amount: currencyTotalAmount, currency_total_refund: totalRefundAmount,
            total_amount:(proc_total + prdtArr), paid_amount:pAmount, currency_paid_amount: currencyPaidAmount,
        })
    }

    const calculateAmount = (rate) => {
        const arr = products.map(item=>({...item,
            currency_rate: +rate ?  +item.rate / +rate : 0, currency_total: (+rate ?  +item.rate / +rate : 0) * +item.quantity,
            subTotal:+item.rate * +item.quantity
        }))

        const proceduresArr = services.map(item=>({...item,
            currency_rate: +rate ?  +item.rate / +rate : 0, currency_total: (+rate ?  +item.rate / +rate : 0) * +item.quantity,
            subTotal:+item.rate * +item.quantity
        }))
        setProducts(arr)
        setServices(proceduresArr)
    }

    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setExchangeRate(event.target.value)
        calculateAmount(+event.target.value)
        calculateTotal(services, products, +event.target.value)
    }

    const handleOpen = (item) => {
        const {bill_id, status} = item
        setOpenDialog(true)
        setIds({bill_id: bill_id, status})
    };

    const handleClose = () => {
        setOpenDialog(false)
    };


    const handleServiceChangeRefund = (event,service_id) =>{
        const {value} = event.target;
        const arr = services.map(item=>{
            if (item.service_id === service_id){
                return {...item,refund_amount:value, isRefund:true}
            }
            return item
        })
        calculateTotal(arr, products);
        setServices(arr)

    }


    const handleRefundService = (id) =>(event)=>{
        const {checked} = event.target;
        const arr = services.map(item=>{
            if(item.service_id === id){
                return {...item, isRefund:checked, refund_amount: checked ? item.subTotal : 0}
            }
            return item
        })
        calculateTotal(arr, products, exchangeRate);
        setServices(arr)
    }

    const handleRefundProduct = (id) =>(event)=>{
        const {checked} = event.target;
        const arr = products.map(item=>{
            if(item.product_id === id){
                return {...item, isRefund:checked, refund_amount: checked ? item.subTotal : 0}
            }
            return item
        })
        calculateTotal(services, arr, exchangeRate);
        setProducts(arr)
    }

    const handleChangeProductRefund = (event,product_id) =>{
        const {value} = event.target;
        const arr = products.map(item=>{
            if (item.product_id === product_id){
                return {...item,refund_amount:value, isRefund:true}
            }
            return item
        })
        calculateTotal(services, arr);
        setProducts(arr)

    }

    const handleChangeDate = (event) => {
        setDate(event.target.value)
    };




    const handleSubmitBill = (event) => {
        event.preventDefault();
        const {total_refund, currency_total_refund} = totalAmounts;
        // setSubmitted(true) <- was originally this
        const {patient_number} = state
        setSubmitted(true);
        const serviceArr = services.filter(item=>item.isRefund).map(({isRefund, refund_amount, ...item})=>({...item, refund_amount: item.subTotal}))
        const productArr = products.filter(item=>item.isRefund).map(({isRefund,refund_amount, ...item})=>({...item, refund_amount: item.subTotal}))
        const remainingServices = services.filter(item=>!item.isRefund).map(({isRefund, ...item})=>({...item}))
        const remainingProds = products.filter(item=>!item.isRefund).map(({isRefund, ...item})=>({...item}))

        const invoicesRefunded = [...serviceArr, ...productArr];
        const remainingInvs =  [...remainingServices, ...remainingProds]
        const servs = services.some(item=>item.isRefund)
        const prdts = products.some(item=>item.isRefund)

        const isBase = currency.split('-')[1]

        const isNotBase = isBase === 'false'
        // const isBaseCurrency = isBase === 'true'

        const currency_id = currency.split('-')[0]

        const isNotBaseData = isNotBase ?  {currency_total:+currency_total_refund} : {}
        if(servs || prdts){
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/cbilling/make_refund`, {
                visit_id: +visit_id,
                refunded_by: user_roles_id,
                invoices_refunded: invoicesRefunded,
                invoices_remaining: remainingInvs,
                refund_amount: +total_refund,
                amount_refunded: +total_refund,
                // is_base_currency:isBase,
                // currency_id: +currency_id,
                // ...isNotBaseData

            }).then((res) => {
                actions.snackbarActions.snackSuccess(`Bill refunded successfully`);
                setIsSubmitted('resolved')
                history.push(`/refundreceipt/${visit_id}/1/${patient_number}`);
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })

        }

    };

    const handleOpenReceipt = (e) => {
        e.preventDefault();
        const servs = services.some(item=>item.isRefund)
        const prdts = products.some(item=>item.isRefund)
        if (servs || prdts) {
            setOpenPrint(true);
            setSubmitted(false);
        } else {
            setSubmitted(true);
        }

    }

    const handleCloseReceipt = () => {
        setOpenPrint(false);
    };


    const handleClickReceipt = () => {
        setOpenPrint(false);
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    return {state,totalAmounts,handleRefundProduct, handleRefundService,
        isSubmitted, date, openPrint, submitted,products,
        handleChangeDate, handleChangeProductRefund, handleServiceChangeRefund,
        handleSubmitBill, handleCloseReceipt,handleClickReceipt, closeSnackbar, services, 
        openDialog, handleOpen, handleClose, ids, handleOpenReceipt, visit_id,currencies,
        currency, exchangeRate, handleChangeCurrency, handleChangeExchangeRate
    }
}
