import React from "react";
import Card from "@material-ui/core/Card";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Label from "../Utils/FormInputs/Label";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions";
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {useTheme} from "@material-ui/core/styles";
import {CustomSelect} from "../Utils/FormInputs/SelectInput";
import * as departmentActions from "../../actions/DoctorsActions/departmentActions";
import {titleCase} from "../Users/addUser";
import {useEditService} from "./CustomHooks/useEditService";
import {Link} from "react-router-dom";
import {faListAlt} from "@fortawesome/free-solid-svg-icons/faListAlt";
import TextField from "../Utils/FormInputs/TextField";
import {ErrorMessage} from "../Utils/styledComponents";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";

export function EditService(props) {
    const {state, submitted, isSubmitted, department, service_type, handleChange,
        handleChangeServiceType, handleChangeDepartment, handleUpdateService, handleCloseSnackbar} = useEditService(props)
    const {openBar, type, message} = props.snackbars;
    const {departments} = props.departments;
    const departs = !departments ? [] : departments;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <div data-testid="edit-service" className='journals'>
            <PageTitle title="Edit Service"/>
            <SubHeader title="Services" subTitle="Edit Service">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <div className='general-ledger-header'>
                <div className="text-right">
                    <Link to="/servicelist/1">
                        <button className="btn sms-btn mb-2"><FontAwesomeIcon icon={faListAlt} style={{marginRight:5}}/>Services</button>
                    </Link>
                </div>
            </div>
            <div className="add-service-card mt-3">
                <Card className="p-3 general-card">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>Edit Service</h6>
                    <MainSnackbar handleCloseBar={handleCloseSnackbar} message={message} open={openBar} variant={type}/>
                    <Form onSubmit={handleUpdateService} id="edit_service">
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Service Type" htmlFor='service-type' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect onChange={handleChangeServiceType} value={service_type} isDisabled
                                              id='service_type'
                                              options={[
                                                {label: 'Procedure', value: 'Procedure'},
                                                {label: 'Lab', value: 'Lab'},
                                                {label: 'Radiology', value: 'Radiology'}, 
                                                {value:'Surgery', label:'Surgery'},
                                                {label: 'Ante Natal Care', value: 'Ante Natal Care'}
                                                ]} dataTest="service_type"/>
                                {(submitted && !service_type) &&
                                <div className="help-block" id="status_help_block">Service Type  is required</div>}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Service Name" htmlFor='service_name' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField type="text" id='service_name'
                                           value={titleCase(state.service_name)}
                                           name="service_name" onChange={handleChange}
                                           submitted={submitted}/>
                                {submitted && !state.service_name ? <ErrorMessage>Service name is required</ErrorMessage>:null}
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Clinic/Department" htmlFor='department' />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <CustomSelect onChange={handleChangeDepartment} value={department}
                                              id='department'
                                              options={departs.map(dep => ({
                                                  label: dep.department_name, value: dep.department_id
                                              }))} dataTest="department" isDisabled={service_type.label === 'Lab' || service_type.label === 'Radiology'}/>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Cost" htmlFor='rate' type/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <TextField onChange={handleChange}  value={state.rate}
                                           name="rate" type="number"  id='rate' submitted={submitted}
                                           placeholder = 'Input service cost'/>
                                {submitted && !state.rate ? <ErrorMessage>Service cost is required</ErrorMessage>:null}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                <Label  name="Description"  htmlFor='description'/>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                <ResizableTextarea value={titleCase(state.description)} id='description'
                                                   onChange={handleChange} rows={3} name="description"/>
                            </Col>
                        </Form.Group>

                        {/*
                            
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                    <Label  name="Status"  htmlFor='status' type/>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={7} xl={7}>
                                    <Form.Check type="radio" inline label="Active" name="status" value="1"
                                                checked={state.status === "1"}
                                                onChange={handleChange} id="active"/>
                                    <Form.Check type="radio" inline label="Inactive" name="status" value="0"
                                                checked={state.status === "0"}
                                                onChange={handleChange} id="inactive"/>
                                    {(submitted && !state.status) &&
                                    <div className="help-block" id="status_help_block">Status is required</div>}
                                </Col>
                            </Form.Group> 
                        */}
                        <div className="text-right">
                            <button type="submit" disabled={!!isSubmitted} className="btn btn-sm sms-btn px-4"  id="submit_service" >
                              {isSubmitted ? "Saving..." : "Save"}</button>
                        </div>
                    </Form>
                </Card>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar, departments: state.departments}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch),
            departmentActions: bindActionCreators(departmentActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditService);
