import React from "react";
import Card from "@material-ui/core/Card";
import TextField from "../Utils/FormInputs/TextField";
import Textarea from "../Utils/FormInputs/Textarea";
import {useInpatientTreatment} from "./CustomHooks/useInpatientTreatment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from '../../actions/snackbarActions';
import SmsSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {DataCell} from "../Utils/Lists/CollapsibleList";


const RecordTreatment = ({actions, snackbar, match, handleCloseRecordDialog, prescribed_drug, setPrescribedDrug, handleRecordTreatment}) => {
    const {
       handleChange, closeSnackbar,
        handleSubmitTreatment, isSubmitted, submitted,
    } = useInpatientTreatment(actions, match, handleCloseRecordDialog, prescribed_drug, setPrescribedDrug, handleRecordTreatment)
    const {openBar, type, message} = snackbar;

    return (
        <div className="table-card mt-4">
            <SmsSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            {/*<Card className="general-card p-3">*/}
                <h6 className='text-center'>RECORD TREATMENT</h6>
                <form onSubmit={handleSubmitTreatment}>
                    <div className='table-responsive'>
                        <table className=" table table-bordered table-sm profile-table">
                        <thead>
                        <tr>
                            <td>Date</td>
                            <td width='200px'>Drug</td>
                            <td>Dosage</td>
                            <td>Route</td>
                            <td>Quantity Dispensed</td>
                            <td>Quantity already Administered</td>
                            <td>Quantity to be Administered</td>
                            <td>Time<span className='help-block'>*</span></td>
                            <td>Comments</td>
                            {/*<td/>*/}
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <DataCell>
                                    <TextField type="date" value={prescribed_drug.date} readOnly/>
                                </DataCell>
                                <DataCell>
                                    {prescribed_drug.product_name}
                                </DataCell>
                                <DataCell>
                                    {prescribed_drug.dosage}
                                </DataCell>
                                <DataCell variant='150'>
                                    <TextField type="text" value={prescribed_drug.route} name='route'
                                               onChange={e => handleChange(e)}/>
                                </DataCell>
                                <DataCell>
                                    {prescribed_drug.quantity_given}
                                </DataCell>
                                <DataCell>
                                    {prescribed_drug.quantity_administered}
                                </DataCell>
                                <DataCell variant='150'>
                                    <TextField type="number" value={prescribed_drug.quantity} name='quantity'
                                               onChange={e => handleChange(e)}/>
                                    {(submitted && (!prescribed_drug.quantity || prescribed_drug.quantity === 0)) &&
                                        <div className='help-block'>Quantity is required</div>}
                                    {(submitted && ((prescribed_drug.quantity <= 0) || (prescribed_drug.quantity > (prescribed_drug.quantity_given - prescribed_drug.quantity_administered)))) ?
                                        <div className='help-block'>Quantity is more than available or equal to zero</div>  
                                        : null}
                                </DataCell>
                                <DataCell variant='150'>
                                    <TextField type="time" value={prescribed_drug.time} name='time'
                                               onChange={e => handleChange(e)}/>
                                    {(submitted && !prescribed_drug.time) ?
                                        <div className='help-block'>Time is required</div> : null}
                                </DataCell>
                                <DataCell variant='150'>
                                    <Textarea value={prescribed_drug.comment} name='comment'
                                              onChange={e => handleChange(e)}/>
                                </DataCell>
                            </tr>
                        {/*
                        For when the treatment was a list
                        {data.map((item, index) => (
                            <tr key={item.id}>
                                <td>
                                    <TextField type="date" value={item.date} readOnly/>
                                </td>
                                <td>
                                    <CustomSelect value={item.product_name}
                                                  onChange={(value) => handleChangeDrug(value, index)}
                                                  options={drugs}/>
                                </td>
                                <td>
                                    {item.dosage}
                                </td>
                                <td>
                                    <TextField type="text" value={item.route} name='route'
                                               onChange={e => handleChange(e, item.id)}/>
                                </td>
                                <td>
                                    {item.quantity_given}
                                </td>
                                <td>
                                    {item.quantity_administered}
                                    {/*{item.quantity_given && item.quantity_administered ? 
                                    //(parseInt(item.quantity_given) - parseInt(item.quantity_administered)) : ''}
                                </td>
                                <td>
                                    <TextField type="number" value={item.quantity} name='quantity'
                                               onChange={e => handleChange(e, item.id)}/>
                                    {(submitted && (!item.quantity || item.quantity === 0)) &&
                                        <div className='help-block'>Quantity is required</div>}
                                    {(submitted && ((item.quantity <= 0) || (item.quantity > (item.quantity_given - item.quantity_administered)))) ?
                                        <div className='help-block'>Quantity is more than available</div>  
                                        : null}
                                    {/*(submitted && (item.quantity <= 0) && (
                                        item.quantity > (item.quantity_given && item.quantity_administered ? (parseInt(item.quantity_given) - parseInt(item.quantity_administered)) : 0))) ?
                                        <div className='help-block'>Quantity is more than dispensed</div>  
                                        : null} 
                                </td>
                                <td>
                                    <TextField type="time" value={item.time} name='time'
                                               onChange={e => handleChange(e, item.id)}/>
                                    {(submitted && !item.time) ?
                                        <div className='help-block'>Time is required</div> : null}
                                </td>
                                <td>
                                    <Textarea value={item.comment} name='comment'
                                              onChange={e => handleChange(e, item.id)}/>
                                </td>
                                <td>
                                    <button type='button' onClick={() => removeRow(item.id)}
                                            className='btn btn-sm sms-btn-dismiss'><FontAwesomeIcon icon={faTrash}/>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={10}>
                                <button type='button' onClick={handleAddRow}
                                        className='btn btn-sm sms-gray-btn'>Add
                                </button>
                            </td>
                        </tr>*/}
                        </tbody>
                    </table>
                    </div>
                    <button type='submit' className='btn btn-sm sms-info-btn'
                            disabled={isSubmitted === 'pending' || isSubmitted === 'resolved'}>{isSubmitted === 'pending' ? 'Submitting...' : 'Submit'}</button>
                </form>
            {/*</Card>*/}
        </div>
    );
};

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordTreatment);
