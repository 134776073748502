import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import *  as snackbarActions from "../../../actions/snackbarActions";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import { ReportsTable, BorderedCell,BoldBorderedCell } from "../../Utils/Tables/ReportsTable";
import  TableRow  from "@material-ui/core/TableRow";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { useTestsByGender } from "./CustomHooks/useTestsByGender";
import { LoadingGif } from "../../Utils/Loader";
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Label from "../../Utils/FormInputs/Label";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";

export const convertMales = (item) =>{
    return item['M'] ? item['M']:0; 
}

export const convertFemales = (item) =>{
    return item['F'] ? item['F']:0; 
}

export const filterData = (arr) =>{
    return arr.map(item=>{
        const a28days = item['a0to28days'] ? item['a0to28days'] : {};  
        const a29days = item['a29daysto4Yrs'] ? item['a29daysto4Yrs'] : {};
        const a5yrs = item['a5to9Yrs'] ? item['a5to9Yrs'] : {};
        const a10yrs = item['a10to19Yrs'] ? item['a10to19Yrs'] : {};
        const a20yrs = item['a20Yrsandabove'] ? item['a20Yrsandabove'] : {};
        const total = convertMales(a28days) + convertFemales(a28days) + convertMales(a29days) + convertFemales(a29days) +
        convertMales(a5yrs) + convertFemales(a5yrs) +  convertMales(a10yrs) + convertFemales(a10yrs) + convertMales(a20yrs) + convertFemales(a20yrs);
        return{
            ...item,
            a28days,
            a29days,
            a5yrs,
            a10yrs,
            a20yrs,
            total
        }
    })
}

const LabTestsByGender = ({actions, match:{params}}) => {
    const {component} = params
    const  {
        reports,  isLoading,
        isSuccess, isError, state,submitted,handleChange, handleSubmit, isPending,
    } = useTestsByGender(actions, component)
    const {start_date, end_date, patient_type} = state
    const title = {
        'lab':'Lab Report by Gender',
        'scan':'Radiology Report by Gender'
    }

    const arr = filterData(reports)

    const sum = arr.reduce((a,item)=>{
        return a + item.total
    }, 0)
    const labComponents = [{label: 'Lab Tests by Gender', path: '/reports/tests/lab'},
        //{label: 'Total Positives & Negatives', path: '/reports/totalpositives'},
        {label: 'Number of Lab Tests', path: '/reports/numberoftestsreports'},
    ]
    const radiologyComponents = [{label: 'Radiology Reports by Gender', path: '/reports/tests/scan'},
    {label: 'Radiology Reports by Test Name', path: '/reports/xrayandscanreports'},
    ]
    const components = {
        'lab':labComponents,
        'scan':radiologyComponents
    }
    return (
        <div className='journals'>
            <PageTitle title={title[component]}/>
            <SubHeader title="Reports" subTitle={title[component]}>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <BackButton text='General Reports' to='/reports/generalreports'/>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components[component]}/> 
            <div>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={4} >
                        <Row>
                            <Col xs={12} sm={2} md={2} lg={3} xl={2}>
                                <Label name="Patient Type" />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                                <SelectInput value={patient_type} onChange={handleChange} name="patient_type"
                                             options={[{value:'1', text:'Outpatient'},{value:'2', text:'Inpatient'}]}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} >
                        <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending,type:'date'}}/>
                    </Col>
                </Row>
                <ReportsTable {...{headTitle:'Test'}}>
                    {isLoading ? <LoadingGif/>: null}
                    {isSuccess ? reports.length > 0 ?
                    <>
                    {arr.map((item,index)=>{
                        return(
                            <TableRow key={index}>
                                <BorderedCell>{item.test_name}</BorderedCell>
                                <BoldBorderedCell>{item.a28days['M'] ? item.a28days['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a28days['F'] ? item.a28days['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a29days['M'] ? item.a29days['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a29days['F'] ? item.a29days['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a5yrs['M'] ? item.a5yrs['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a5yrs['F'] ? item.a5yrs['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a10yrs['M'] ? item.a10yrs['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a10yrs['F'] ? item.a10yrs['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a20yrs['M'] ? item.a20yrs['M']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.a20yrs['F'] ? item.a20yrs['F']:''}</BoldBorderedCell>
                                <BoldBorderedCell>{item.total}</BoldBorderedCell>
                            </TableRow>
                        )
                    })}
                    <TableRow>
                        <BorderedCell colSpan={11} align="right"><strong>Total</strong></BorderedCell>
                        <BorderedCell><strong>{sum}</strong></BorderedCell>
                    </TableRow>
                    </> 
                    :<TableRow><BorderedCell colSpan={12} align="center">No records found</BorderedCell></TableRow>
                    :null}
                    {isError ? <TableRow><BorderedCell colSpan={12} align="center">The server did not return a valid response</BorderedCell></TableRow>:null}
                    
                </ReportsTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(LabTestsByGender);
