import React, { useEffect} from "react";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {MiniCenteredDiv} from "../../Utils/styledComponents"
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {AssetAccountingDetails, AssetDetails, AssetDetailsTemplate} from "./AssetDetailsTemplate";
import {LoadingGif} from "../../Utils/Loader";







const FixedAssetDetails = ({actions, snackbars, match:{params}}) => {
    const {fixed_asset_id} = params
    const [assetDetails, setAssetDetail] = React.useState({});
    const [loading, setLoading] = React.useState('idle')


    useEffect(()=>{
        setLoading('loading')
        axios.get(  `${config.smsUrl}/cinventory/fixed_asset/detail/${fixed_asset_id}`).then(({data})=> {
            let res = data ?? {};
            setAssetDetail(res)
            setLoading('success')
        }).catch(err=>{
            errorMessages(err, null, actions)
            setLoading('error')
        })
    },[])



    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar();
    };

    const {openBar, type, message} = snackbars;

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return (
        <div>
            <PageTitle title="Fixed Asset Details"/>
            <SubHeader title="Accounting" subTitle="Fixed Asset Details">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <MiniCenteredDiv>
                <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
                <div className='mb-3'>
                    <BackButton to='/accounting/fixedassets' text='Asset List'/>
                </div>
                {isLoading ? <LoadingGif/> :  null}
                {isSuccess ? <AssetDetailsTemplate>
                    <AssetDetails {...{assetDetails}}/>
                    <AssetAccountingDetails {...{assetDetails}}/>
                </AssetDetailsTemplate> :  null}
                {isError ? <p className='text-center'>The server did not return a valid a response</p>: null}
            </MiniCenteredDiv>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FixedAssetDetails);
