import React from "react";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CustomTable from "../../Utils/Tables/CustomTable";
import FilterResults from "react-filter-search";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {bindActionCreators} from "redux";
import {Container, RightAlignedContainer} from "../../Utils/styledComponents";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import { faCoins } from "@fortawesome/free-solid-svg-icons/faCoins";
import { useInvoiceProviders } from "./hooks/useInvoiceProviders";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import { NewProvider } from "./NewProvider";
import { history } from "../../../Helpers/history";
import {CsvDialog} from "../../Utils/Dialogs/CsvDialog";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'Sl'},
    {id: 'providerName', numeric: false, disablePadding: false, label: 'Provider Name'},
    {id: 'providerType', numeric: false, disablePadding: false, label: 'Provider Type'},
    {id: 'contact', numeric: false, disablePadding: false, label: 'Contact'},
    {id: 'actions', numeric: false, disablePadding: false, label: 'Action'},
];

const InvoiceProviders = ({actions, snackbars}) => {
    const {providerProps,providers,searchValue, page, rowsPerPage,isLoading, isSuccess, isError,openDialog,
        handleSearch, handleChangePage, handleChangeRowsPerPage, isEditProvider,
        closeSnackbar, handleOpenDialog, handleCloseDialog, uploadProps} = useInvoiceProviders(actions)
    const { isBlocking,isBlockDialog,setIsBlockDialog,handleResetForm} = providerProps
    const {openBar, type, message} = snackbars;
    const {handleOpenCsvDialog} = uploadProps
    return (
        <div className='journals'>
            <PageTitle title="Invoice Providers"/>
            <SubHeader title="Billing" subTitle="Invoice Providers">
            <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />
            <CustomDialog open={openDialog} handleClose={handleCloseDialog} title={isEditProvider ? "Edit Provider": "New Provider"}>
                <NewProvider {...providerProps}/>
            </CustomDialog>
            <CsvDialog {...{...uploadProps, file:"/csv/provider_data.csv", title:'Upload Providers'}}/>

            <Container>
                <RightAlignedContainer>
                    <div className="my-3">
                        <button onClick={handleOpenDialog} className="btn sms-btn btn-sm mr-4">
                            <FontAwesomeIcon icon={faPlus}/> New Provider</button>
                        <button onClick={handleOpenCsvDialog} id="upload-providers" className="btn sms-info-btn btn-sm">Upload Providers
                        </button>
                    </div>

                </RightAlignedContainer>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable  handleChangePage={handleChangePage}
                             handleChangeRowsPerPage={handleChangeRowsPerPage}
                             headData={headData} handler={handleSearch} page={page} rowsPerPage={rowsPerPage}
                              data={providers} colSpan={headData.length}
                              id="searchValue" filter >
                  
                        <FilterResults value={searchValue} data={providers}
                            renderResults={results => (
                                <TableBody>
                                    {isLoading ? <TableRow>
                                    <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                            alt="loader"/></TableCell>
                                </TableRow>:null}
                                    {isSuccess ? providers.length ?  results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, idx) => {
                                            const cnt = (page * rowsPerPage) + idx + 1;
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={item.provider_id}
                                                    className='mui-table-row'
                                                    id="services"
                                                >
                                                    <TableCell>{cnt}</TableCell>
                                                    <TableCell>{item.provider_name}</TableCell>
                                                    <TableCell>{item.provider_type}</TableCell>
                                                    <TableCell>{item.contact}</TableCell>
                                                    <TableCell>
                                                        {/* Editing provider put on hold
                                                        <button className="btn btn-sm sms-amber-btn" style={{marginRight: '10px', marginBottom:'5px'}} onClick={()=>handleEditProvider(item)}>Edit Provider</button> */}
                                                        {/*<button onClick={()=>history.push(`/items_provider_allows/${item.provider_id}/${item.provider_name}/1/${item.currency_id}-${item.currency_symbol}`)} */}
                                                        {/*        className="btn btn-sm sms-btn" style={{marginRight: '10px', marginBottom:'5px'}}>Items List</button>*/}
                                                        <button onClick={()=>history.push(`/customer-transactions/${item.provider_id}/${item.provider_name}`)} className="btn btn-sm sms-info-btn" style={{marginBottom:'5px'}}>View Transactions</button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        }):<TableRow>
                                        <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                                    </TableRow>:null}
                                    {isError ? <TableRow>
                                        <TableCell colSpan={headData.length} align="center">The server did not return a valid response</TableCell>
                                    </TableRow>:null}
                                </TableBody>
                            )}/>
                </CustomTable>
            </Container>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceProviders);
