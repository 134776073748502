import React from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import {CSVLink} from "react-csv";
import {Link} from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';




const BackButton = ({to, text}) =>{
    return (
        <Link to={to}>
            <button className='btn btn-sm sms-btn mb-2'>
                <ArrowBackIcon/> Back to {text}
            </button>
        </Link>
    )
}


const DataExportButton = ({downLoadPdf, exportCsv, csvRef, data, title, headers}) => {
    return (
        <>
            <Dropdown align='start'>
                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" style={{paddingBottom:3, paddingTop:3}}>
                    Export As
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={downLoadPdf} style={{fontSize: '.85em'}}>PDF</Dropdown.Item>
                    {/* <Dropdown.Item style={{fontSize: '.85em'}} onClick={exportCsv}>CSV</Dropdown.Item> */}
                </Dropdown.Menu>
            </Dropdown>
            <CSVLink
                data={data}
                ref={csvRef}
                headers={headers}
                filename={`${title}.csv`}
                className="hidden"
            />
        </>

    )
}
export {BackButton, DataExportButton}
