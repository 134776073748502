import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { config } from '../../../../Helpers/env';
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useNewCOAccount} from "./useNewCOAccount"

const useChartOfAccounts = (actions) =>{
    const [openMenu, setOpenMenu] = useState(null)
    const [categoryIndex, setCategoryIndex] = useState(0)
    const [subCategories, setSubCategories] = useState([]);
    const [allAccounts, setAllAccounts] = useState([])
    const [loading, setLoading] = useState('idle')

    const [collapse, setCollapse] = useState({});

    const { openDialog, handleOpenDialog, handleCloseDialog,  coaProps, isResolved,
    accountCategories, category, setCategory} = useNewCOAccount(actions)
    const {category_id} = category;

    useEffect(()=>{
        if(!category_id){
            return
        }
        const formData = new FormData()
        formData.append('account_category', category_id)
        axios.post(`${config.smsUrl}/accounting/chart/parent_accounts`, formData).then(res=>{
            const data = res.data;
            const dt = data ?? {};
            const arr = dt.data ?? [];
            setSubCategories(arr)
        },err=>console.log(err))
    },[category_id])


    const formatData = (data={}) =>{
        let arr = []
        for (let item in data){
            const obj = {
                head_name: item,
                head_level:-1,
                children: data[item]
            }
            arr.push(obj)
        }
        return arr
    }

    useEffect(()=>{
        setLoading('loading')
        axios.get(`${config.smsUrl}/accounting/chart/accounts`).then(res=>{
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : {};
            const dataArr = formatData(arr)
            setAllAccounts(dataArr)
            setLoading('success')
        }).catch(err=>{
            errorMessages(err, null, actions)
            setLoading('error')
        })
    }, [isResolved])


    if (collapse["parentTasks"] === undefined) {
        const newstate = { ...collapse, parentTasks: true };
        setCollapse(newstate);
    }


    const handleCollapse = (param) => {
        setCollapse(prevState => ({...prevState, [param]: !prevState[param]}));
    };


    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleCloseMenu = () =>{
        setOpenMenu(null)
    }

    const handleClickCategory = (index, item) =>{
        const {id} = item;
        setCategoryIndex(index)
        setCategory({...category, category_id:id})
    }

    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    return {closeSnackbar, openDialog, handleOpenDialog, handleCloseDialog,  coaProps, isResolved,
    openMenu,handleOpenMenu, handleCloseMenu, accountCategories, categoryIndex, handleClickCategory,
     subCategories, collapse, handleCollapse, allAccounts,isLoading, isSuccess,isError}
}
export {useChartOfAccounts}