import React from "react";
import { BorderedCell } from "../../Utils/Tables/ReportsTable";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { DateTimeFilter } from "../../Utils/ReusableComponents/DateTimeFilter";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { useSurgeryMOH } from "./CustomHooks/useSurgeryMOH";


const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    tableCell:{
      border:'1px solid rgb(90 88 88)',
      padding:'8px'
    }
});

const repeat = (item, times) => {
    return new Array(times).fill(item)
}


const SurgeryMOH = ({actions}) => {

    const classes = useStyles();

    const {surgery_reports, state,submitted, handleChange, handleSubmit, checkForData, isPending} = useSurgeryMOH(actions);
    const {start_date, end_date} = state;

    // these are for the table heads
    const ageGroups = [{age:'0 - 28days'},{age:'29days - 4Yrs'},{age:'5 - 9Yrs'},
    {age:'10 - 19Yrs'},{age:'20Yrs & Above'}]

    const genders = repeat({male:'Male',female:'Female'}, 5);

    const createNewRow = (element, index) => {
        const item = Object.values(element)[0];
        return (
            <TableRow key={index}>
                <BorderedCell colSpan="3">{Object.keys(element)[0]}</BorderedCell>
                <BorderedCell align="center">{item.zero_28days.male}</BorderedCell>
                <BorderedCell align="center">{item.zero_28days.female}</BorderedCell>
                <BorderedCell align="center">{item.twenty_nine_four_years.male}</BorderedCell>
                <BorderedCell align="center">{item.twenty_nine_four_years.female}</BorderedCell>
                <BorderedCell align="center">{item.five_years.male}</BorderedCell>
                <BorderedCell align="center">{item.five_years.female}</BorderedCell>
                <BorderedCell align="center">{item.ten_19years.male}</BorderedCell>
                <BorderedCell align="center">{item.ten_19years.female}</BorderedCell>
                <BorderedCell align="center">{item.twenty_years_plus.male}</BorderedCell>
                <BorderedCell align="center">{item.twenty_years_plus.female}</BorderedCell>
                <BorderedCell align="center">{item.total}</BorderedCell>
            </TableRow>
        )
    }

    return (
        <div className="journals">
            <PageTitle title='Outpatient Diagnosis'/>
            <SubHeader title="Reports" subTitle='Surgical Procedures'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div style={{margin: '10px'}}>
                <BackButton text={'General Reports'} to={'/reports/generalreports'}/>
            </div>
            <div className="mx-2 mt-2">
                <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending, type:'date'}}/>
            </div>

            <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table}>
                    <TableHead className="table-grey">
                        <TableRow>
                            <BorderedCell rowSpan="2" colSpan="3">
                                <strong>Category</strong>
                            </BorderedCell>
                            {ageGroups.map((item, index)=>(
                            <BorderedCell key={index} align="center" colSpan="2"><strong>{item.age}</strong></BorderedCell>
                            ))}
                            <BorderedCell/>
                        </TableRow>
                        <TableRow align="center" >
                            {genders.map((item, index)=>(<>
                            <BorderedCell><strong>{item.male}</strong></BorderedCell>
                            <BorderedCell><strong>{item.female}</strong></BorderedCell>
                            </>))}
                            <BorderedCell><strong>Total</strong></BorderedCell>
                        </TableRow>
                    </TableHead>

                    {checkForData ?
                        <>
                            {surgery_reports ?
                                Object.entries(surgery_reports).map(([key, value]) => (
                                <>
                                    <TableHead className="table-grey">
                                        <TableRow>
                                            <BorderedCell colSpan="14"><strong>{key}</strong></BorderedCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {value["actual_surgeries"].map((item, index) => (
                                            createNewRow(item, index)
                                        ))}
                                    </TableBody>
                                </>
                            )): null}
                        </>
                        : 
                        <TableBody>
                            <TableRow>
                                <BorderedCell colSpan="14">No data available</BorderedCell>
                            </TableRow>
                        </TableBody>
                    }

                </Table>                
            </TableContainer>
        </div>
    )
}

export {SurgeryMOH};