import React from "react";
import {useTheme} from "@material-ui/core/styles";
import {useCompany} from "../../Context/company-context";


export const HospitalAddress = (props) => {
    const {state} = useCompany(props);
    const { companyName, companyEmail, mobile, address, website, logo } = state;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    return (
        <div className="hosp-address">
            <table className="table table-sm table-borderless prescription-table">
                {/* <tr>
                    <td/>
                    <td align="center"  className={palette.type === 'dark' && 'dark-theme-color'}><img style={{marginTop:20,width:'80%'}}  src={image} alt="smartmedlogo"/></td>
                </tr> */}
                <tr>
                   <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}><img   src={logo ? logo : '/images/smartmedlogo.svg'} alt="smartmedlogo" style={{  width:'120px', marginBottom:'-5px'}}/></td>
                </tr>
                <tr>
                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}><p><strong>{companyName}</strong></p></td>
                </tr>

                <tr>
                   <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}><p><strong>{mobile}</strong></p></td>
                </tr>
                <tr>
                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}><p><strong>{address}</strong></p></td>
                </tr>
                {companyEmail ? <tr>
                    <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}><p><strong>{companyEmail}</strong></p></td>
                </tr>:null}
                <tr>
                    {website ? <td align="center" className={palette.type === 'dark' && 'dark-theme-color'}><p><strong>Website:</strong> <a href={website}
                                                                                                                target="__blank">{website}</a></p></td>:<td/>}
                </tr>

            </table>

        </div>
    )
};
