import React, { useState } from "react";
import {PatientInformation} from "../DoctorsModule/PatientInfo/PatientInformation";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import ReusableDoctorTabs, {doctorRoutes} from "../Utils/Menu/ReusableDoctorTabs";
import {dateConvert, dateStrokeConvert} from "../Utils/ConvertDate";
import {useDischargeForm} from "../Visit/NewAdmission/hooks/useDischargeForm";
import {usePatientDetails} from "../DoctorsModule/ReferralNote/usePatientDetails";
import  { ReusablePhoneInput } from "../Utils/FormInputs/FormGroup";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import  {  NewPrescriptionTable } from "../DoctorsModule/Prescription/NewPrescription";
import Label from "../Utils/FormInputs/Label";
import Form from "react-bootstrap/Form"
import TextField from "../Utils/FormInputs/TextField";
import {ErrorMessage, RightAlignedContainer} from "../Utils/styledComponents";
import {ResizableTextarea} from "../Utils/FormInputs/Textarea";

const style = {
    margin: '5px 20px'
}

const DoctorDischargeForm = ({ actions, snackbars, match }) => {
    const { patient_number, visit_id, labrequest_id, scan_request_id, patient_type, patient_admission_id } = match.params;
    const {  state, submitted,  patient, printDialog,  handleChange, handleClosePrintDialog,
        handleSubmit, handleClick, closeSnackbar, contact, setContact, isPending } = useDischargeForm(actions, match);
    const { patient: patientDetails } = usePatientDetails(actions, patient_number, "");
    const arr = doctorRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type, patient_admission_id);
    const [show, setShow] = useState(false);
  
    const {
      summary_of_invention,
      final_diagnosis,
      recommendation,
      follow_up_date,
      start_time,
      description
    } = state;
  
    const { patient_name, admission_date, ward, room, bed_number } = patient || {};
    const { first_name, last_name } = JSON.parse(sessionStorage.getItem("user"))?.token?.[1] || {};
    const doctor_name = `${first_name || ""} ${last_name || ""}`;
    const { openBar, type, message } = snackbars;
    const { age, gender, address, phone_no: phone } = patientDetails;
  
    const headData = [
      { item: "Drug Name", toolTip: "Enter drug name", isRequired: true },
      { item: "Quantity Prescribed", toolTip: "Enter prescribed quantity" },
      { item: "Dosage", toolTip: "Enter dosage" },
      { item: "Frequency", toolTip: "Enter frequency" },
      { item: "No. of Days", toolTip: "Enter number of days" },
      { item: "Instructions", toolTip: "Enter instructions" },
      { item: "Action" }
    ];
  
    const addPrescription = (event) => {
      setShow(event.target.checked);
    };
  
    return (
      <div className="journals">
        <PageTitle title="Discharge Form" />
        <SubHeader title="Doctor" subTitle="Discharge Form">
          <FontAwesomeIcon icon={faUserMd} />
        </SubHeader>
        <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message} />
        <PrintDialog
          message="View Discharge Note"
          openDialog={printDialog}
          handleClose={handleClosePrintDialog}
          text="Would you like to view and print this discharge note"
          handleClick={handleClick}
          handleCancel={handleClosePrintDialog}
        />
        <ReusableDoctorTabs components={arr} patient_type={+patient_type}>
          <div className="text-right">
            <p style={style}>
              Date: <strong>{dateConvert()}</strong>
            </p>
            <p style={style}>
              Doctor: <strong>{doctor_name}</strong>
            </p>
          </div>
          <PatientInformation phone={phone} gender={gender} age={age} patient_name={patient_name} address={address} patient_number={patient_number} />
  
          <form onSubmit={handleSubmit} autoComplete="off" className="p-3">
            <h6 className="patient-personal-details">ADMISSION DETAILS</h6>
            <table className="table table-sm table-borderless prescription-table">
              <tbody>
                <tr>
                  <td>
                    <p>
                      Admission No.: <strong>{patient_admission_id}</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      Admission Date: <strong>{admission_date ? dateStrokeConvert(admission_date) : admission_date}</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      Ward: <strong>{ward}</strong>
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Room: <strong>{room}</strong>
                    </p>
                  </td>
                  <td>
                    <p>
                      Bed: <strong>{bed_number}</strong>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="patient-personal-details discharge" />
            <div className="row">
              <div className="col">
                <Form.Group>
                  <Label name="Follow-up Date" htmlFor="follow_up_date" type />
                  <TextField {...{ submitted }} value={follow_up_date} type="date" onChange={handleChange}  name="follow_up_date" />
                  {submitted && !follow_up_date && <ErrorMessage>Follow-up date is required</ErrorMessage>}
                </Form.Group>
                <Form.Group>
                  <Label name="Follow-up Time" htmlFor="start_time" type/>
                  <TextField {...{ submitted }} value={start_time} onChange={handleChange} type="time" name="start_time"
                             placeholder="Start Time" />
                    {submitted && !start_time && <ErrorMessage>Follow-up time is required</ErrorMessage>}
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group>
                  <Label name="Phone" type htmlFor="phone_no" />
                  <ReusablePhoneInput submitted={submitted} contact={contact} setContact={setContact} required id="phone_no" />
                </Form.Group>
                <Form.Group>
                  <Label name="Follow-up Detail" type htmlFor="follow_up_detail"/>
                  <ResizableTextarea rows={2} {...{ submitted }} value={description} onChange={handleChange} id="follow_up_detail" name="description" placeholder="Write description..." />
                  {submitted && !description && <ErrorMessage>Follow-up detail is required</ErrorMessage>}
                </Form.Group>
              </div>
            </div>
  
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Label name="Diagnosis" htmlFor="final_diagnosis" type/>
                  <ResizableTextarea placeholder="Write diagnosis...." {...{ submitted }} value={final_diagnosis} onChange={handleChange} name="final_diagnosis" rows={5} dataTest="final_diagnosis" id="final_diagnosis" />
                  {submitted && !final_diagnosis && <ErrorMessage>Final diagnosis is required</ErrorMessage>}
                </Form.Group>
              </div>
              {/* <div className="col-md-6">
                {show && (
                  <NewPrescriptionTable
                    headData={headData}
                    {...{
                      submitted,
                      actions,
                      handleChange,
                      isSubmitted,
                      prescription
                    }}
                  />
                )}
              </div> */}
            </div>
            <Form.Group>
              <Label name="Summary of Invention" htmlFor="summary_of_invention" type/>
              <ResizableTextarea
                placeholder="Write summary...."
                {...{ submitted }}
                value={summary_of_invention}
                onChange={handleChange}
                name="summary_of_invention"
                rows={5}
                dataTest="summary_of_invention"
                id="summary_of_invention"
              />
              {submitted && !summary_of_invention && <ErrorMessage>Summary of invention is required</ErrorMessage>}
            </Form.Group>
  
            <Form.Group>
              <Label name="Recommendation" htmlFor="recommendation" type/>
              <ResizableTextarea
                placeholder="Write recommendation...."
                {...{ submitted }}
                value={recommendation}
                onChange={handleChange}
                name="recommendation"
                rows={5}
                dataTest="recommendation"
                id="recommendation"
              />
              {submitted && !recommendation && <ErrorMessage>Recommendation is required</ErrorMessage>}
            </Form.Group>
              <RightAlignedContainer>
                  <button type="submit" className="btn btn-primary mb-2" disabled={isPending}>
                      {isPending ? 'Submitting...' :  'Submit'}
                  </button>
              </RightAlignedContainer>
          </form>
        </ReusableDoctorTabs>
      </div>
    );
  };
  

function mapStateToProps(state) {
    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorDischargeForm);
