import React from "react";
import CustomTable, {useHiddenColumns} from "../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../Utils/Tables/sortingFunctions";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions"
import MainSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {convertGmtTime,  formatDateTime} from "../Utils/ConvertDate";
import {exportPDF} from "../Utils/downloadPDF";
import {logoutErrorMessage} from "../../Helpers/ErrorMessages";
import {ReusableRouteTabs} from "../Utils/Dialogs/ReusableTabs";
import {history} from "../../Helpers/history";
import PageTitle from "../Utils/smsTitle";
import {SubHeader} from "../../Containers/SubHeader";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import { DataExportButton } from "../Utils/Buttons/DataExportationButton";
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import { useEditResults } from "./CustomHooks/useEditResults";
import CustomDialog from "../Utils/Dialogs/CustomDialog";
import EditLabResults from "./EditResults";
import {LabResultsReport} from "./LabResultsReport";
import {usePrint} from "../Utils/Templates/usePrint";
import {BlockNavigationComponent} from "../Utils/ReusableComponents/BlockNavigationComponent";
import PrintDialog from "../Utils/Dialogs/PrintDialog";
import {RightAlignedContainer} from "../Utils/styledComponents";
import {PendingStatus, FinishedStatus, ExpiredStatus} from "../DoctorsModule/doctorStyles";


const headData = [{id: 'sl', numeric: false, disablePadding: false, label: 'SL', hidden:false},
    {id: 'date', numeric: false, disablePadding: false, label: 'Date', hidden:false},
    {id: 'patient_name', numeric: false, disablePadding: false, label: 'Patient Name', hidden:false},
    {id: 'phone_no', numeric: false, disablePadding: false, label: 'Phone', hidden:false},
    {id: 'kin_phone_no', numeric: false, disablePadding: false, label: 'NOK Phone', hidden:false},
    {id: 'hospital_test_name', numeric: false, disablePadding: false, label: 'Lab Test', hidden:false},
    {id: 'sample_id', numeric: false, disablePadding: false, label: 'Sample No.', hidden:false},
    {id: 'result_status', numeric: false, disablePadding: false, label: 'Status', hidden:false},
    {id: 'action', numeric: false, disablePadding: true, label: 'Action', hidden:false},
];


export const LabResultsList = ({actions, snackbars}) => {
    const {resultProps, openDialog, handleOpenDialog, handleCloseDialog,searchValue, order, orderBy,  activePage, total_count, reports:results,
        handleSearch, handleRequestSort, handleChangeNextPage, closeSnackbar,handleCloseResultDialog,
         retrieveAllRecords, csvRef, downloadCsv, allRecords, isError, isLoading, isSuccess, state,
          submitted, isPending, handleChange, handleSubmit, printOutProps, openResultDialog, handleOpenResultDialog,
        isBlockDialog, isBlocking, setIsBlockDialog,  handleResetForm,
        openApproval, confirmApproval, confirmCancel, handleConfirmApproval, handleApproval, handleCloseApproveDialog,
        handleCloseConfirmCancel, handleCloseConfirmApproval, handleConfirmCancel,handleOpenApproveDialog, ids} = useEditResults(actions)
     const {start_date, end_date} = state;

    const {status} = ids

    const downloadPdf = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            const itemList = dt.map(item => ({
                time_taken: convertGmtTime(item.time_taken),
                patient_name: `${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
                hospital_test_name: item.hospital_test_name,
                sample_id: item.sample_id
            }))
            exportPDF("Lab Test Report", [["SL", "Date", "Patient Name",
                    "Lab Test", "Sample No."]],
                itemList.map((item, index) => [index += 1, item.time_taken,
                    item.patient_name, item.hospital_test_name, item.sample_id
                ]),
                "Lab Test Report.pdf");
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
        })
    }
    const data = allRecords.map(item => ({
        time_taken: convertGmtTime(item.time_taken),
        patient_name: `${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
        hospital_test_name: item.hospital_test_name,
        sample_id: item.sample_id
    }))


    const headers = [{label: 'Date', key: 'time_taken'}, {label: 'Patient Name', key: 'patient_name'},
        {label: 'Lab Test', key: 'hospital_test_name'}, {label: 'Sample No.', key: 'sample_id'}]
    const {openBar, type, message} = snackbars;


    const components =  [{label: 'Pending Lab Requests', path: "/labrequests"},
    {label: 'Pending Lab Results', path:  "/pendingsamples"}, {label: ' Lab Results', path: "/labresultlist"},
    {label: 'Lab Reports', path: "/labreports"}]
    const resultList = !results.data ? [] : results.data;

    const reports = resultList.map((item, index)=>{
        const count = ((activePage - 1) * 10) + index + 1;

        // outpatient
        // 3-pending
        //
        // inpatient
        // 6-pending
        // 8-approved
        // 9-cancelled

        const resultStatus = {
            3:(<PendingStatus>Pending Approval</PendingStatus>),
            6:(<PendingStatus>Pending Approval</PendingStatus>),
            8:(<FinishedStatus>Approved</FinishedStatus>),
            9:(<ExpiredStatus>Canceled</ExpiredStatus>)
        }

        const buttons = {
            3 : (
                <>
                    <button onClick={()=>handleOpenApproveDialog(item)}
                            type="button" className="btn btn-sm sms-amber-btn mr-3 mb-2">Approve</button>
                    <button onClick={()=>handleOpenResultDialog(item)}
                            type="button" className="btn btn-sm sms-gray-btn mb-2">View</button>
                </>

            ),
            6 : (
                <>
                    <button onClick={()=>handleOpenApproveDialog(item)}
                            type="button" className="btn btn-sm sms-amber-btn mr-3 mb-2">Approve</button>
                    <button onClick={()=>handleOpenResultDialog(item)}
                            type="button" className="btn btn-sm sms-gray-btn mb-2">View</button>
                </>

            ),
            8 : (
                <button onClick={()=>handleOpenResultDialog(item)}
                        type="button" className="btn btn-sm sms-gray-btn mr-3 mb-2">View</button>
            ),
            9:(
                <>
                    <button onClick={()=>handleOpenResultDialog(item)}
                            type="button" className="btn btn-sm sms-gray-btn mr-3 mb-2">View</button>
                    <button onClick={()=>handleOpenDialog(item)}
                            type="button" className="btn btn-sm sms-info-btn mb-2">Edit</button>
                </>
            )
        }
        return {
            ...item,
            count,
            date:item.date_created ? formatDateTime(item.date_created) : "",
            patient_name:`${!item.patient_firstname ? "" : item.patient_firstname} ${!item.patient_lastname ? "" : item.patient_lastname}`,
            action: buttons[item.status],
            result_status:resultStatus[item.status]
        }
    })

    const {
        headCells, all_hidden, handleAllHeadCells, handleHeadCells
    } = useHiddenColumns(headData);

    const {componentRef, handlePrint, fontSize}  = usePrint(`Lab Results`);
    const {handleEdit} = printOutProps
    return (
        <div className='journals'>
            <PageTitle title='Lab Results'/>
            <SubHeader title="Laboratory" subTitle='Lab Results'>
                <FontAwesomeIcon icon={faVials}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
                dialogClose={isBlockDialog}
                setIsBlockDialog={setIsBlockDialog}
                clearForm={handleResetForm}
            />

            {/*cancel results dialog*/}

            <PrintDialog handleCancel={handleCloseConfirmCancel} openDialog={confirmCancel} handleClose={handleCloseConfirmCancel}
                         handleClick={()=>handleApproval(0)} message={"Cancel Results"}
                         text='Are you sure you want to cancel these results?'/>

            {/*confirm results dialog*/}

            <PrintDialog handleCancel={handleCloseConfirmApproval} openDialog={confirmApproval} handleClose={handleCloseConfirmApproval}
                         handleClick={()=>handleApproval(1)} message={"Approve Results"}
                         text='Are you sure you want to approve these results?'/>

            {/*approve results dialog*/}
            <CustomDialog title='Approve Results' open={openApproval} handleClose={handleCloseApproveDialog} maxWidth="md"  isContentOverflow={false}>

                <div style={{padding:'8px 20px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                    <RightAlignedContainer>
                        <button onClick={handleConfirmApproval} className="btn sms-info-btn mr-3 btn-sm">Approve</button>
                        <button onClick={handleConfirmCancel} className="btn sms-gray-btn btn-sm">Cancel</button>
                    </RightAlignedContainer>
                </div>
                <div ref={componentRef} style={{padding:"20px"}}>
                    {fontSize}
                    <LabResultsReport {...printOutProps}/>
                </div>
            </CustomDialog>


            <CustomDialog title='View Results' open={openResultDialog} handleClose={handleCloseResultDialog} maxWidth="md"  isContentOverflow={false}>
                <div className="text-right mb-2">
                    <button onClick={handlePrint} className="btn sms-amber-btn mr-3 btn-sm">Print</button>
                    {status === 9 ? <button onClick={handleEdit} className="btn sms-info-btn btn-sm">Edit</button>: null}
                </div>
                <div ref={componentRef}>
                    {fontSize}
                    <LabResultsReport {...printOutProps}/>
                </div>

            </CustomDialog>
            <CustomDialog title='Edit Results' open={openDialog} handleClose={handleCloseDialog} maxWidth="md"  isContentOverflow={false}>
                <EditLabResults {...resultProps}/>
            </CustomDialog>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/>
            <div className='mui-tables'>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <div className="row">
                    <div className="col-md-10 mb-1">
                        <DateTimeFilter {...{start_date,end_date, handleChange, submitted,handleSubmit,isPending}}/>
                    </div>
                    <div className="col-md-2 mb-1">
                        <DataExportButton data={data} title='Lab Results List' csvRef={csvRef}
                                          exportCsv={downloadCsv} downLoadPdf={downloadPdf} headers={headers}/>
                    </div>
                </div>
                <CustomTable title='Lab Results' headData={headCells} handler={handleSearch} customPage total_count={total_count}
                             records={10}  {...{all_hidden, handleAllHeadCells, handleHeadCells}}
                             handleRequestSort={handleRequestSort} data={reports} colSpan={headCells.length} order={order}
                             term={searchValue}
                             orderBy={orderBy} activePage={activePage} handleChangeNextPage={handleChangeNextPage}
                             id="searchValue">
                 
                        <TableBody>
                            {isLoading ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                            </TableRow>:null}
                            {isSuccess ? reports.length > 0 ?  stableSort(reports, getComparator(order, orderBy))
                                .slice(0, 10)
                                .map((item, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={item.sl}
                                            className='mui-table-row'
                                            id="services"
                                        >
                                            {headCells.map((head)=>(
                                                <TableCell id={head.id}  key={head.id}
                                                           hidden={head.hidden}>{item[head.id]}</TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                }):<TableRow>
                                <TableCell colSpan={headCells.length} align="center">No records found</TableCell>
                            </TableRow>:null}
                            {isError ? <TableRow>
                                <TableCell colSpan={headCells.length} align="center">The server did not return a valid response</TableCell>
                            </TableRow>: null}
                        </TableBody>
                       
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(LabResultsList);
