//Service actions
export  const CHANGE_SERVICE_CURRENCY_RATE = "CHANGE_SERVICE_CURRENCY_RATE"
export const CHANGE_SERVICE_RATE = "CHANGE_SERVICE_RATE";
export const DELETE_SERVICE = "DELETE_SERVICE";
export const CALCULATE_SERVICE_AMOUNT = "CALCULATE_SERVICE_AMOUNT";
export const RETRIEVE_SERVICES = "RETRIEVE_SERVICES";

//product actions
export  const CHANGE_PRODUCT_CURRENCY_RATE = "CHANGE_PRODUCT_CURRENCY_RATE"
export const CHANGE_PRODUCT_RATE = "CHANGE_PRODUCT_RATE";
export const CHANGE_PRODUCT_QUANTITY = "CHANGE_PRODUCT_QUANTITY";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const CALCULATE_PRODUCT_AMOUNT = "CALCULATE_PRODUCT_AMOUNT";
export const RETRIEVE_PRODUCTS = "RETRIEVE_PRODUCTS";