import {useState, useEffect} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage, errorMessages} from "../../../Helpers/ErrorMessages";

export const useAddRoom= (actions) =>{
    const [state, setState] = useState({room_name: '', bed_capacity: '', charge: '', description: '', status: '1'});
    const [ward_name, setWard] = useState('');
    const [wards, setWards] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [open, setOpen] = useState(false)
    const [room, setRoom] = useState({});
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [room_id, setRoomId] =useState('')
    const [isRoom, setIsRoom] = useState('')
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)




    useEffect(() =>{
        if(!room){
            return
        }
        const {ward_name, ward_id,name, bed_capacity, charge, descriprion} = room;
        setState({room_name:name, bed_capacity, charge, description:descriprion, status:'1'});
        setWard({value: ward_id, label: ward_name});
    },[room]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value})
        setIsBlocking(true)
    };

    const handleChangeWard = (value) => {
        setWard(value)
        setIsBlocking(true)
    };

    const handleOpenDialogForm =()=>{
        setOpen(true)
        axios.get(`${config.smsUrl}/cbedmanager/getAllWards`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const list = !dt.wards ? [] : dt.wards;
            const arr = list.map(wd => ({label: wd.name, value: wd.ward_id}))
            setWards(arr)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const handleCloseDialogForm =()=> {
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpen(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const formData = new FormData(event.currentTarget);
        const fieldValue = Object.fromEntries(formData.entries())
        if(room_id){
            formData.append('room_id', room_id);
        }
        formData.append('ward_id', ward_name.value);
        const url = room_id ? 'room_update' : 'add_room'
        setIsBlocking(false)
        if (ward_name && fieldValue['name'] && fieldValue['bed_capacity'] && fieldValue['charge'] && fieldValue['status']) {
            setIsSubmitted('pending')
             axios.post(`${config.smsUrl}/cbedmanager/${url}`, formData).then(()=>{
                actions.snackbarActions.snackSuccess('Room added successfully');
                 setIsSubmitted('resolved')
                setState({room_name: '', bed_capacity: '', charge: '', description: '', status: '1'})
                setWard('')
                setSubmitted(false)
                setOpen(false)
                 setOpenEditDialog(false)
                 setRoomId('')
            }).catch(err=>{
                errorMessages(err, null, actions)
                 setIsSubmitted('rejected')
            })
        }
    };

    const handleOpenEditDialog =(rm_id)=>{
        setOpenEditDialog(true);
        setRoomId(rm_id)
        const formData = new FormData();
        formData.append('room_id', rm_id);
        axios.post(`${config.smsUrl}/cbedmanager/room_update_form`, formData).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const id = dt.room_id ? dt.room_id : ''
            setRoom(dt)
            setIsRoom(id)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }

    const handleCloseEditDialog =()=>{
        setOpenEditDialog(false)
        setState({room_name: '', bed_capacity: '', charge: '', description: '', status: '1'})
        setWard('')
        setRoomId('')
    }

    const handleReset = () => {
        setOpen(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        setWard(null);
        setState({room_name: '', bed_capacity: '', charge: '', description: '', status: ''})
    };

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar();
    };

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';

    const blockingProps = {handleReset, isBlocking, isBlockDialog, setIsBlockDialog, setIsBlocking}

    const roomProps = {
        state, ward_name, wards, submitted,handleChange, handleChangeWard, handleSubmit,
        closeSnackbar, isPending, isResolved, isRejected,open, handleOpenDialogForm, handleCloseDialogForm,
        openEditDialog, handleCloseEditDialog, handleOpenEditDialog, blockingProps
    }

    return {isResolved, roomProps}
}