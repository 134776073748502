import { useState } from "react";
import axios from "axios";
import { config } from "../../../../../Helpers/env";
import { logoutErrorMessage } from "../../../../../Helpers/ErrorMessages";
import {dateConvert} from "../../../../Utils/ConvertDate";
import { convertJSONToTable, convertTableToJSON } from './../PatientFormScreens/AnteNatalTables';

export const useThirdScreenProps = (woa_months, woa_days, actions, currentTriageData={}) => {
    // TREATMENT NOTES
    const [treatment_notes, setNotes] = useState('');

    const handleNotesChange = (event) => {
        setNotes(event.target.value);
    }

    // RISK FACTORS / RECOMMENDATION FOR DELIVERY
    const [recommendations_delivery, setRecommendations] = useState({
        high_risk_mother:'', partner_disclosure:'', adherence_counselling:'', infant_feeding_counselling:'', referred_psychosocial:'', niverapine_syrup:''
    });

    const handleRecommendations = (event) => {
        const {name, value} = event.target;
        setRecommendations({...recommendations_delivery, [name]:value});
    }

    // ROUTINE MEDICINES/INTERVENTION
    const emtct_codes = [
        { label: 'C', value: "C" },
        { label: 'TR', value: "TR" },
        { label: 'TRR', value: "TRR" },
        { label: 'TRRDM', value: "TRRDM" },
        { label: 'TRK', value: "TRK" },
        { label: 'TRRK', value: "TRRK" },
    ]

    const [emtct, setEMTCTCodes] = useState([]);


    // ANTENATAL PROGRESS EXAMINATION
    const [progress_table, setProgressTable] = useState([]);

    const removeProgressRow = (index, exam_id = '') => {
        if (exam_id) {
            const formData = new FormData();
            formData.append('exam_id', exam_id);
            axios.post(`${config.smsUrl}/antenatal/deactivate_antenatal_exam`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                setProgressTable(progress_table.filter((item, idx) => idx !== index));   
            }).catch(err => {
               logoutErrorMessage(err,null,actions);
            })
        } else {
            setProgressTable(progress_table.filter((item, idx) => idx !== index));  
        }
    }

    const handleProgressChange  = (event, index) => {
        const {name, value} = event.target;
        const arr = progress_table.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setProgressTable(arr);
    }

    const handleNewProgressRow = () => {
        let progress_row = {
            exam_date:dateConvert(),
            exam_woa: `${woa_months} months ${woa_days} days`, fundal_height:'',
            presentation: '', position:'', relation_pp_brim:'', footal_heart_rate:0,
            mother_weight:currentTriageData['tri_weight'] ? currentTriageData['tri_weight'] : 0, 
            bp_mm_lg: currentTriageData['tri_bp'] ?  currentTriageData['tri_bp'] : 0 , varicose_code:'', 
            urine_test:currentTriageData['tri_urine'] ? currentTriageData['tri_urine'] : '',
            emtct_code: emtct['value'] ? emtct['value'] :'', hb:'', iron_folic_acid_no_of_pills:'', ipt:'', complaints_and_returns:'', 
            return_date:dateConvert(), name_of_examiner:'', exam_id:''
        };
        setProgressTable([...progress_table, progress_row]);
    }

    const progress_table_props = {
        progress_table, removeProgressRow, handleNewProgressRow, handleProgressChange
    }

    // ULTRASOUND REPORT TABLE HOOKS
    const [ultrasound_table, setUltraTable] = useState([{
        ultra_date:dateConvert(), gestational_age:'', placenta:'', amniotic_fluid:'', complication:'', comments:''
    }]);

    const removeUltraRow = (event, index) => {
        setUltraTable(ultrasound_table.filter((item, idx) => idx !== index));
    }

    const handleNewUltraRow = () => {
        let new_ultra = {ultra_date:dateConvert(), gestational_age:'', placenta:'', amniotic_fluid:'', complication:'', comments:''};
        setUltraTable([...ultrasound_table, new_ultra]);
    }

    const handleUltraChange = (event, index) => {
        const {name, value} = event.target;
        const arr = progress_table.map((item, idx) => {
            if (idx === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setProgressTable(arr);
    }

    const ultrasound_props = {
        ultrasound_table, removeUltraRow, handleNewUltraRow, handleUltraChange
    }

    const assignThirdScreenProps = (item) => {
        // need to check how they get those
        setNotes(item['treatment'] ? item['treatment'] : '');

        const high_risk = item.high_risk_mother ? item.high_risk_mother : '';
        const partner_dis = item.partner_disclosure_support_done ? item.partner_disclosure_support_done : '';
        const infant_feed = item.infant_feeding_counselling_done ? item.infant_feeding_counselling_done : '';
        const referred = item.referred_psychosocial_group ? item.referred_psychosocial_group : '';
        const niv = item.niverapine_syrup_for_baby ? item.niverapine_syrup_for_baby : '';
        const adh = item.adherence_counselling ? item.adherence_counselling : '';

        setRecommendations({
            high_risk_mother:high_risk, partner_disclosure:partner_dis, adherence_counselling:adh, 
            infant_feeding_counselling:infant_feed, referred_psychosocial:referred, niverapine_syrup:niv
        })


        // set the tables
      //  const isProgress = true;
        setProgressTable(convertJSONToTable(item['antenatal_progress'] ? item['antenatal_progress'] : [], item['isStart'], 'progress'));

        // set the ultra_sound table
       // setUltraTable(convertJSONToTable(item['ultra_sound_report'] ? item['ultra_sound_report'] : []));
    }

    const handleResetThirdScreen = () => {
        setRecommendations({
            high_risk_mother:'', partner_disclosure:'', adherence_counselling:'', infant_feeding_counselling:'', referred_psychosocial:'', niverapine_syrup:''
        });

        setNotes('');

        // reset the tables
    }

    const getThirdScreenValues = () => {
        return {

            // Risk Factors/ Recommendations for Delivery
            'high_risk_mother' : recommendations_delivery['high_risk_mother'],
            'partner_disclosure_support_done' : recommendations_delivery['partner_disclosure'],
            'adherence_counselling' :  recommendations_delivery['adherence_counselling'],
            'infant_feeding_counselling_done' : recommendations_delivery['infant_feeding_counselling'],
            'referred_psychosocial_group' : recommendations_delivery['referred_psychosocial'],
            'niverapine_syrup_for_baby' : recommendations_delivery['niverapine_syrup'],

            // treatment notes
            treatment_notes,

            // progress examination table
            'antenatal_progress' : convertTableToJSON(progress_table, 'progress')
        }
    }

    const third_screen_props = {

        progress_table_props,
         ultrasound_props,
         handleNotesChange, getThirdScreenValues,
        handleResetThirdScreen, assignThirdScreenProps,
        recommendations_delivery, handleRecommendations, treatment_notes
    }
    return {third_screen_props, getThirdScreenValues, assignThirdScreenProps}
}