import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Label from "../../Utils/FormInputs/Label";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea";
import {CustomSelect} from "../../Utils/FormInputs/SelectInput";
import ClearIcon from '@material-ui/icons/Clear';
import {makeStyles} from "@material-ui/core/styles";



const useStyles = makeStyles((theme) => ({
    margin: {
        margin: 0,
    },
    inputBase: {
        fontSize: '0.9em',
        padding: '2px'
    },

    col: {
        maxWidth: 600,
    },
    td: {
        width: '20%'
    },
    btn: {
        width: '4%'
    },
    icon: {
        fontSize: '.8rem'
    },
    totalSection:{
        borderRadius:'10px',
        backgroundColor:'#fbfafa'
    }
}));

const NewLedgerEntry = ({state, accounts, ledger, handleChange, handleChangeAccount, handleSubmit, isPending,
    submitted, handleChangeItems, removeRow, addItem, total, handleBlurDebit, handleBlurCredit}) => {
    const classes = useStyles();
    const {credit_total, debit_total} = total;
    const {date} = state
    return (
            <form onSubmit={handleSubmit} autoComplete='off'>
                <div className={classes.col}>
                    <Form.Group as={Row}>
                        <Label name='Date' htmlFor='date' type/>
                        <TextField type="date" id='date' onChange={handleChange} value={date} name="date"/>
                            {(submitted && !date) && <div className='help-block'>Date is required</div>}
                    </Form.Group>
                    {/*<Form.Group as={Row}>*/}
                    {/*    <Label name='Description' sm={2} column style={style.textRight} type/>*/}
                    {/*    <Col sm={6}>*/}
                    {/*        <Textarea value={notes} onChange={handleChange} name='notes'/>*/}
                    {/*        {(submitted && !notes) && <div className='help-block'>Details are required</div>}*/}
                    {/*    </Col>*/}
                    {/*</Form.Group>*/}
                </div>
                <table className="table table-bordered table-sm">
                    <tr>
                        <td className={classes.td}>Account<span className="help-block">*</span></td>
                        <td className={classes.td}>Memo</td>
                        <td className={classes.td}>Debit</td>
                        <td className={classes.td}>Credit</td>
                        <td className={classes.btn}/>
                    </tr>
                    <tbody>
                    {ledger.map(item => (
                        <tr key={item.id}>
                            <td>
                                <CustomSelect value={item.account_id} options={accounts}
                                                onChange={value => handleChangeAccount(value, item.id)}/>
                                {(submitted && !item.account_id) &&
                                <div className='help-block'>Select account</div>}
                            </td>
                            <td><Textarea value={item.description} onChange={e => handleChangeItems(e, item.id)}
                                            name='description'/></td>
                            <td><TextField type="number" onChange={e => handleChangeItems(e, item.id)}
                                            name='debit' value={item.debit}
                                            onBlur={() => handleBlurDebit(item.id)}/></td>
                            <td><TextField type="number" onChange={e => handleChangeItems(e, item.id)}
                                            name="credit" value={item.credit}
                                            onBlur={() => handleBlurCredit(item.id)}/></td>
                            <td>
                                <button type='button' onClick={() => removeRow(item.id)}
                                        className="btn btn-sm sms-btn-dismiss"><ClearIcon
                                    classes={{root: classes.icon}}/></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="row col-md-12">
                    <div className="col-md-4">
                        <button type="button" onClick={addItem} className="btn btn-sm sms-grey-btn">Add
                            another Line
                        </button>
                    </div>
                    <div className="col-md-8">
                        <div className={classes.totalSection}>
                        <div className="row">
                            <div className="col-md-3">
                                <p >Total</p>
                            </div>
                            <div className="col-md-3">
                                <p className='text-center'>{debit_total}</p>
                            </div>
                            <div className="col-md-3">
                                <p className='text-center'>{credit_total}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <button type="submit" disabled={isPending}
                        className="btn btn-sm sms-info-btn mt-2">{isPending ? 'Submitting...':'Submit'}
                </button>
                
            </form>
    )
}

export default NewLedgerEntry;