import React, {useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../../../actions/snackbarActions";
import MainSnackbar from "../../../Utils/Snackbar/SmsSnackbar";
import PageTitle from "../../../Utils/smsTitle";
import {SubHeader} from "../../../../Containers/SubHeader";
import {dateConvert} from "../../../Utils/ConvertDate";
import Card from "@material-ui/core/Card";
import {ReloadObservationTriageChart} from "../../../MedicationandVisits/ObservationChart"
import { useObservationChart } from '../../../MedicationandVisits/CustomHooks/useObservationChart';
import CustomDialog from '../../../Utils/Dialogs/CustomDialog';
import Triage from '../../../Triage/Triage';
import { useVisitList } from '../../../Visit/NewVisit/hooks/useVisitList';
import ReusableAnteNatalTabs from './ReusableAnteNatalTabs';
import { anteNatalRoutes } from './ReusableAnteNatalTabs';
import { useDoctorTriage } from '../../../DoctorsModule/Triage/hooks/useTriage';
import { PatientInformation } from '../../../DoctorsModule/PatientInfo/PatientInformation';

/**
 * Component that returns the Ante Natal Triage Details
 * @param {*} param0 
 * @returns 
 */
const AnteNatalTriage = ({actions, snackbars, match}) => {
    const {patient_number, labrequest_id, scan_request_id, visit_id, patient_type,patient_admission_id, anc_id, anc_status} = match.params;
    const arr = anteNatalRoutes(patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id, anc_id, anc_status);
    const [takeVitals, setTakeVitals] = useState(false);
    const {state, triage, closeSnackbar} = useDoctorTriage(actions,{ visit_id, patient_number,patient_type});

    const { handleSubmitTriage, triageProps,  handlePatientDetails, isTriage, handleEditTriage} = useVisitList(actions);

    const {tabular, handleChangeTabular, tableData, graphData,
        expanded,handleChange} = useObservationChart(actions, visit_id, isTriage);

    const {patient_name, age, gender, address, phone} = state;
    const tr = !triage ? [] : triage;
    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;
    const {openBar, type, message} = snackbars;
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const doctor_name = `${!_token.first_name ? "" : _token.first_name} ${!_token.last_name ? "" : _token.last_name}`;

    const [openDialog, setOpenDialog] = useState(false);
    const {triageList, triage:propsTriage, setSubmitted:setSubmitTriage,
        isEditTriage, setEditTriage, triage_element, setTriageElement,
        setAllMetrics, allMetrics, clearTriageDetails, setSaveTriage,
    } = triageProps;
    
    const arr1 = triageList.filter(metric=>metric.metric_id !== 6).some(metric=>metric.metric_value);
    const arr2 = propsTriage.some(metric => metric.metric_value);
    const IskipTriage = (arr1 && arr2);

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handlePatientDetails({patient_number, visit_id, labrequest_id, scan_request_id, patient_type,patient_admission_id});
    };

    const handleTakeVitals = (e) => {
        setTakeVitals(true);
        setSubmitTriage(false);
        handleOpenDialog();
    }

    const handleSubmitTriageSurgery = (event) => {
        handleSubmitTriage(event);
        //if (IskipTriage) {
        setTakeVitals(false);
        handleCloseDialog();
        //}
    }

    const handleOpenEditTriage = (item) => {
        setEditTriage(true);
        const dt = item.data ? item.data : [];
        setAllMetrics(dt);
        handleOpenDialog('Triage');
    }

    const handleCloseEditTriage = (event) => {
        setEditTriage(false);
        clearTriageDetails();
        handleEditTriage(event);
        handleCloseDialog('Triage');
    }

    return (
        <div className='journals' data-testid="ante-natal-triage">
            <PageTitle title="Triage"/>
            <SubHeader title="Ante Natal" subTitle={`Triage`}  doctor_name={doctor_name}>
            <div className="header-icon-margin-right-zero"><img src="/images/antenatal-blue.png" className="icon-resize surgery-icon-tabs ante-natal-green"/></div>
        </SubHeader>
            <ReusableAnteNatalTabs components={arr}>
            <CustomDialog title={!isEditTriage ? "Save Triage" : "Edit Triage"} open={openDialog} handleClose={handleCloseDialog} maxWidth="md" isPaperStyle={true}>
                {takeVitals ?
                    <div className='okay-try'>
                        <Triage {...{...triageProps, patient_number,patient:{patient_name, age, gender}}}/>
                        <button type="submit" disabled={false} onClick={handleSubmitTriageSurgery} className="btn btn-sm sms-btn">{'Save Triage'}</button>
                    </div>                
                : isEditTriage ?
                    <div className='okay-try'>
                        <Triage {...{...triageProps, patient_number,patient:{patient_name, age, gender}}}/>
                        <button type="submit" disabled={false} onClick={handleCloseEditTriage} className="btn btn-sm sms-btn">{'Edit Triage'}</button>
                    </div>                
                : null
                }
            </CustomDialog>

                <PatientInformation phone={phone} gender={gender} age={age} patient_name={patient_name}
                                    address={address} patient_number={patient_number}
                                    date={dateConvert()}/>
                <div className="patient-triage">
                    <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                    {tableData && tableData.length > 0 ?
                        <Card className='p-2'>
                            <ReloadObservationTriageChart {...{ tabular, handleChangeTabular, tableData, graphData, closeSnackbar, expanded,
                                handleChange, handleOpenEditTriage, isDoctorScreen:true }}/>
                        </Card>
                    :null}
                    <button type="submit" className="btn btn-sm sms-btn" onClick={handleTakeVitals} style={{marginTop:"10px", marginBottom:"10px"}}>Add Triage</button>
                </div>
            </ReusableAnteNatalTabs>
        </div>

    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(AnteNatalTriage);
