import React, {useEffect, useState} from "react";
import axios from "axios";
import {config} from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {CenteredContainer, ErrorMessage, RightAlignedContainer} from "../../../Utils/styledComponents";
import {LoadingGif} from "../../../Utils/Loader";
import {PreviousVisitContainer} from "../../../Utils/Lists/PreviousVisitContainer";
import {VisitDate, VisitType} from "../../../DoctorsModule/PatientPreviousVisits";
import {history} from "../../../../Helpers/history";
import CustomDialog from "../../../Utils/Dialogs/CustomDialog";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Label from "../../../Utils/FormInputs/Label";
import {ResizableTextarea} from "../../../Utils/FormInputs/Textarea";
import {dateStringConvert} from "../../../Utils/ConvertDate";


export const useReceiveDispatchLabStock = (props) => {
    const {actions,  setOpenDialog:setOpeReceive, to_store} =props
    const [loading, setLoading] = useState('idle');
    const [dispatchList, setList] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [openDialog, setOpenDialog] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')
    const [reason, setReason] = useState('')
    const [dispatch_id, setDispatchId] = useState('')

    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = _token.user_roles_id ? _token.user_roles_id : ''

    const isResolved = isSubmitted === 'resolved'



    useEffect(() => {
        // if(!pharmacy_store){
        //     return
        // }
        setLoading('loading');
        axios.post(`${config.smsUrl}/cinventory/pharmacy_dispatch_list`,{status:0, to_store}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const cats = !dt.data ? [] : dt.data;
            const arr = cats.map(item=>({
                ...item, open:false, products:[]
            }))
            setList(arr);
            setLoading('success');
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error');
        })
    }, [isResolved]);

    const handleOpenItem = (_id) =>{
        axios.post(`${config.smsUrl}/cinventory/pharmacy_dispatch_items`, {
            dispatch_id:_id
        }).then(res => {
            const data = res.data;
            const all_data = !data.data ? [] : data.data;
            const arr = dispatchList.map(item=>{
                if(item.product_dispacth_id === _id){
                    return  {...item, open:!item.open, products:all_data}
                }
                return item
            })
            setList(arr)
        }).catch(e => {
            logoutErrorMessage(e, null, actions)
        })
    }

    const handleChangeReason = (event) =>{
        setReason(event.target.value)
    }

    const handleOpenDialog =(_id) => () =>{
        setOpenDialog(true)
        setDispatchId(_id)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }


    const handleCancel = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(reason){
            setIsSubmitted('pendingCancel')
            axios.post(`${config.smsUrl}/cinventory/cancel_dispatch`, {received_by:user_roles_id, dispatch_id, dispatch_details:reason }).then(()=>{
                setSubmitted(false);
                actions.snackbarActions.snackSuccess('Dispatch dispatch successfully')
                setOpeReceive(false)
                history.push('/pharmacydispatchlist')
                setIsSubmitted('resolvedCancel')
            }).catch(err=>{
                setSubmitted(false)
                errorMessages(err, null, actions)
                setIsSubmitted('rejectedCancel')
            })
        }

    }




    const handleSubmit = (event, _id) =>{
        event.preventDefault();
        setSubmitted(true);
        setIsSubmitted('pending')
        axios.post(`${config.smsUrl}/cinventory/receive_pharmacy_dispatch`, {received_by:user_roles_id, dispatch_id:_id }).then(()=>{
            setSubmitted(false);
            actions.snackbarActions.snackSuccess('Products received successfully')
            setIsSubmitted('resolved')
            setOpeReceive(false)
        }).catch(err=>{
            setSubmitted(false)
            errorMessages(err, null, actions)
            setIsSubmitted('rejected')
        })
    }

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPending = isSubmitted === 'pending'
  
    const isPendingCancel = isSubmitted === 'isPendingCancel'

    return {
        isResolvedDispatch:isResolved,
        receiveStorePharmacy:(
            <React.Fragment>
                <CustomDialog open={openDialog} handleClose={handleCloseDialog} title='Cancel Dispatch'>
                    <form onSubmit={handleCancel}>
                        <Form.Group as={Col}>
                            <Label name='Please provide a reason for canceling' htmlFor="reason" type/>
                            <ResizableTextarea value={reason} id="reason" submitted={submitted} onChange={handleChangeReason} rows={3} placeholder="Write reason...."/>
                            {submitted && !reason ? <ErrorMessage>This field is required</ErrorMessage>: null}
                        </Form.Group>
                        <RightAlignedContainer>
                            <button type="submit" disabled={isPendingCancel} className="btn btn-sm sms-btn  mr-3"  id="continue_cancel">
                                {isPendingCancel ? "Canceling...":"Continue"}</button>
                            <button type="button"  onClick={handleCloseDialog} className="btn btn-sm sms-gray-btn  mr-3"  id="cancel_dialog">
                                No</button>
                        </RightAlignedContainer>
                    </form>
                </CustomDialog>
                {isLoading ? <CenteredContainer><LoadingGif/></CenteredContainer>: null}
                {isSuccess ? dispatchList.length > 0 ?
                    dispatchList.map((item, idx)=>{
                        const visitTitle = (
                            <>
                                <VisitType>Dispatch# {item.product_dispacth_id}</VisitType>
                                <VisitDate>Date: <strong>{dateStringConvert(item.date_dispacthed)}</strong></VisitDate>
                            </>
                        )
                     
                        return (
                            <PreviousVisitContainer  idx={idx} open={item.open} key={item.product_dispacth_id} name={visitTitle}
                                                    handleOpen={()=>handleOpenItem(item.product_dispacth_id)} >
                                <form autoComplete="off" className='p-3' onSubmit={e=>handleSubmit(e,item.product_dispacth_id)}>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-sm profile-table strike-table">
                                            <thead>
                                            <tr>
                                                <td width="300">Product</td>
                                                <td width="200">Batch#</td>
                                                <td width="200">Quantity</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {item?.products.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.product_name}</td>
                                                        <td data-testid={`lab_batch_id-${index}`}>{item.batch_id}</td>
                                                        <td data-testid={`lab_quantity-${index}`}>{item.quantity}</td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="text-right mr-5">
                                        <button type="submit" disabled={isPending} className="btn btn-sm sms-btn mr-3"  id="receive-store-dispatch">
                                            {isPending ? "Receiving...":"Receive Dispatch"}</button>
                                        <button type="button"  onClick={handleOpenDialog(item.product_dispacth_id)} className="btn btn-sm sms-gray-btn  mr-3"  id="cancel-store-dispatch">
                                            Cancel</button>
                                    </div>
                                </form>
                            </PreviousVisitContainer>
                        )
                    })

                    : <p>No products dispatched from store</p>: null}
                {isError ? <CenteredContainer>
                    <p>The server did not return a valid response</p>
                    </CenteredContainer>: null}
            </React.Fragment>
        )
    }
};

