import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {dateConvert} from "../../../Utils/ConvertDate"

const useTestsByGender = (actions, component) => {
    const [loading, setLoading] = useState('idle');
    const [reports, setReports] = useState([]);
    const [allRecords, setAllRecords] = useState([]);
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert(),patient_type:'1'})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const csvRef = useRef();
    const {start_date, end_date,patient_type} = state;

    const url = {
        'lab':'number_of_lab_tests',
        'scan':'number_of_patient_scans'
    }

    useEffect(() => {
        setLoading('pending');
        const formData = new FormData();
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('patient_type', patient_type);
        axios.post(`${config.smsUrl}/creport/${url[component]}`, formData).then(res => {
            const data = res.data;
            const dt = !data ? [] : data;
            setReports(dt);
            setLoading('success')
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            setLoading('error')
        })

    }, []);

    const retrieveAllRecords = () => {
        return axios.post(`${config.smsUrl}/claboratory/number_of_lab_tests`)
    }

    const downloadCsv = () => {
        retrieveAllRecords().then(res => {
            const data = res.data;
            const dt = !data ? []: data;
            setAllRecords(dt);
            csvRef.current.link.click()
        }).catch((err) => {
            errorMessages(err, null, actions);
        })
    }

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setLoading('pending');
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('patient_type', patient_type);
            axios.post(`${config.smsUrl}/creport/${url[component]}`, formData).then(res => {
                const data = res.data;
                const dt = !data ? {} : data;
                setReports(dt);
                setLoading('success')
                setIsSubmitted('resolved')
            }).catch((err) => {
                logoutErrorMessage(err, null, actions);
                setLoading('error')
                setIsSubmitted('rejected')
            })
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = loading === 'rejected';
    return {
        reports, closeSnackbar, retrieveAllRecords, csvRef, downloadCsv,allRecords, isLoading, 
        isSuccess, isError, state,submitted,handleChange, handleSubmit, isPending, isRejected, isResolved
    }
}
export {useTestsByGender}