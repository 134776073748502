import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "../../Utils/smsTitle";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {ReusableBillTable, ProductsTable} from "../utils/ReusableTable";
import TextField from "../../Utils/FormInputs/TextField";
import Textarea from "../../Utils/FormInputs/Textarea"
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader"
import Label from "../../Utils/FormInputs/Label"
import SelectInput from '../../Utils/FormInputs/SelectInput';
import {Container, ErrorMessage} from "../../Utils/styledComponents"
import { useInPatientBillForm } from "./hooks/useInPatientBillForm";
import { formatDate, formatDateTime } from "../../Utils/ConvertDate";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import InputGroup from "react-bootstrap/InputGroup";
import {BlockNavigationComponent} from "../../Utils/ReusableComponents/BlockNavigationComponent";
import {history} from "../../../Helpers/history";




function InpatientBillForm(props) {
    const {
        payment_method,
        state,
        bedBill,
        services,
        products,
        isSubmitted,
        date,
        openPrint,
        paidAmount,
        submitted,
        handleChangePaidAmount,
        handleDeleteServices,
        handleChangeDate,
        handleChangeMethod,
        payTypes,
        handleSubmitBill,
        handleCloseReceipt,
        handleClickReceipt,
        closeSnackbar,
        handleChangeProductRate,
        handleChangeServiceRate,
        handleDeleteProduct, handleChangeProductQuantity,
        handleAddProduct, handleAddService, addProduct, addService,
        handleCloseAddService, handleCloseAddProduct,
        currencies, currency, handleChangeExchangeRate, handleChangeServiceCurrencyRate,
        exchangeRate, usdAmount, currencyTotal, handleChangeProductCurrencyRate,
        handleChangeCurrency, totals,
        renderService , renderPrescription,isBlocking,
        receiptTotals,
        paidReceipts
    } = useInPatientBillForm(props)
    const {openBar, type, message} = props.snackbars;
    const {patient_number, first_name, last_name, address,  admission_date} = state;
    const {status} = props.match.params;
    const title = {
        '1':'Make Deposit',
        '2':'Pay Bill'
    }


    const {total_amount,  balance} = totals

    const {total_amount_paid, remaining_balance} = receiptTotals

    const isBase = currency.split('-')[1]
    const currency_symbol = currency.split('-')[2]
    const isNotBase = isBase === 'false'
    const isBaseCurrency = isBase === 'true'

    const unpaid = ["Item", "Quantity","Rate", "Amount", "Action"]

    const productHeadData = ["Medicine Name", "Dosage", "Frequency", "No. of Days", "Quantity Administered", 
    "Rate", "Amount","Action"]

    const bedHeadData = ["Ward", "Room", "Bed No.", "Assign Date", "End Date","Days","Rate", "Amount"]

    return (
        <div data-testid="add-bill-component" className='journals'>
            <PageTitle title={title[status]}/>
            <SubHeader title="Billing" subTitle={title[status]}>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
            <BlockNavigationComponent
                when={isBlocking}
                shouldBlockNavigation={()=>{
                    return isBlocking;
                }}
                navigate={path=>history.push(path)}
            />
                <PrintDialog handleCancel={handleCloseReceipt} openDialog={openPrint} handleClose={handleCloseReceipt}
                             handleClick={handleClickReceipt} message="View receipt"
                             text="Would you like to confirm this payment"/>
                <CustomDialog open={addService} title="Add Service" handleClose={handleCloseAddService} maxWidth="md"  isContentOverflow={true} >
                    {renderService}
                </CustomDialog>
                <CustomDialog open={addProduct} title="Add Product" handleClose={handleCloseAddProduct} maxWidth="lg"  isContentOverflow={false}>
                    {renderPrescription}
                </CustomDialog>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <Container>
                    <BackButton to='/patientbillinglist' text='Billing List' data-testid="inpatient-bill-form-back-button"/>
                    <MiniHeader  title={title[status]}/>
                <form onSubmit={handleSubmitBill} autoComplete="off" style={{marginTop:'1.5rem'}}>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Admission Date"/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" value={formatDate(admission_date)} readOnly/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Patient No."/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" value={patient_number} readOnly/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Patient Name" />
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField type="text" value={`${!first_name ? "" : first_name} ${!last_name ? "" : last_name}`} readOnly/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Address" />
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <Textarea value={address} readOnly/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Payment Method"  type/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <SelectInput name='payment_method' submitted={submitted} value={payment_method}
                                                 onChange={handleChangeMethod} options={payTypes}
                                                 defaultOption="Select..."/>
                                </Col>
                            </Form.Group>
                            {/*<Form.Group as={Row}>*/}
                            {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                            {/*        <Label name="Currency" type/>*/}
                            {/*    </Col>*/}

                            {/*    <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                            {/*        <SelectInput submitted={submitted} value={currency}*/}
                            {/*                     onChange={handleChangeCurrency} options={currencies}*/}
                            {/*                     defaultOption="Select..."/>*/}

                            {/*    </Col>*/}
                            {/*</Form.Group>*/}
                            {/*{isNotBase ?*/}
                            {/*    <Form.Group as={Row} >*/}
                            {/*        <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                            {/*            <Label  name="Exchange Rate"  type/>*/}
                            {/*        </Col>*/}
                            {/*        <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                            {/*            <InputGroup className="">*/}
                            {/*                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                            {/*                <TextField submitted={submitted} type="number" value={exchangeRate} onChange={handleChangeExchangeRate}/>*/}
                            {/*            </InputGroup>*/}
                            {/*            <span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {currency.split('-')[2]} = {exchangeRate} USD)</span>*/}
                            {/*        </Col>*/}
                            {/*    </Form.Group> : null}*/}
                        </div>
                        <div className='col-lg-6'>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Date"  type/>
                                </Col>
                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField submitted={submitted} type="date" value={date} onChange={handleChangeDate}/>
                                    {submitted && !date ? <ErrorMessage>This is a required field</ErrorMessage>:null}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name="Total Bill"/>
                                </Col>

                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField name='Total_Bill' type="text" value={total_amount} readOnly/>
                                </Col>
                            </Form.Group>
                            {paidReceipts.length > 0 ? <>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Amount Paid"/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField name='amount_paid' type="text" value={total_amount_paid} readOnly/>
                                    </Col>
                                </Form.Group>
                                {/*<Form.Group as={Row}>*/}
                                {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                                {/*        <Label name="Outstanding Amount(USD)"/>*/}
                                {/*    </Col>*/}

                                {/*    <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                                {/*        <TextField name='outstanding_amount' type="text" value={remaining_balance} readOnly/>*/}
                                {/*    </Col>*/}
                                {/*</Form.Group>*/}
                            </>: null}
                            {/*{isNotBase ?*/}
                            {/*    <Form.Group as={Row}>*/}
                            {/*        <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                            {/*            <Label name={`Total Bill(${currency_symbol})`}/>*/}
                            {/*        </Col>*/}

                            {/*        <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                            {/*            <TextField type="text" value={currencyTotal} readOnly/>*/}
                            {/*        </Col>*/}
                            {/*    </Form.Group>: null}*/}
                                <Form.Group as={Row} >
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label  name={`Amount Received `}  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField submitted={submitted} type="number" value={paidAmount} onChange={handleChangePaidAmount}/>
                                        {/* {submitted && isBaseCurrency && paidAmount  < total_amount ? <ErrorMessage>Amount is less than the required amount</ErrorMessage> :
                                            submitted && isBaseCurrency && paidAmount > total_amount ? <ErrorMessage>Amount input is more than the required amount</ErrorMessage>:
                                                submitted && !paidAmount ? <ErrorMessage>This is a required field</ErrorMessage>:null} */}
                                    </Col>
                                </Form.Group>
                                {/*{isNotBase ?  <Form.Group as={Row} >*/}
                                {/*    <Col xs={12} sm={12} md={12} lg={12} xl={3}>*/}
                                {/*        <Label  name="Amount Received(USD)"/>*/}
                                {/*    </Col>*/}
                                {/*    <Col xs={12} sm={12} md={10} lg={10} xl={7}>*/}
                                {/*        <InputGroup className="mb-3">*/}
                                {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                                {/*            <TextField submitted={submitted} type="text" value={ isBaseCurrency ? paidAmount : usdAmount} style={{pointerEvents:'none'}}/>*/}

                                {/*        </InputGroup>*/}
                                {/*        /!* {submitted && status ==='2' &&  isNotBase && usdAmount && usdAmount < total_amount ? <ErrorMessage>Amount  is less than the required amount</ErrorMessage>:null} *!/*/}
                                {/*        {submitted && status ==='2' &&  isNotBase && usdAmount &&  usdAmount > total_amount ? <ErrorMessage>Amount  is more than the required amount</ErrorMessage>:null}*/}
                                {/*    </Col>*/}
                                {/*</Form.Group> :  null}*/}
                            <Form.Group as={Row}>
                                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                    <Label name={`Change  `}/>
                                </Col>

                                <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                    <TextField name='balance' type="text" value={balance} readOnly/>
                                </Col>
                            </Form.Group>
                            
                        </div>
                    </div>
                        <div className="other-billing-details mb-3">
                                    <div className="service-details">
                                        {services.length > 0 ?
                                            <ReusableBillTable services={services.map(item=>({...item,subTotal:item.amount}))} isInPatient={true}
                                                               handleDelete={handleDeleteServices}
                                                               headData={ unpaid.filter(item=>Boolean(item))}
                                                               title="Service(s)"
                                                               isCurrency={isNotBase}
                                                               handleChangeRate={handleChangeServiceRate}
                                                               handleChangeCurrencyRate={handleChangeServiceCurrencyRate}
                                                               >

                                            </ReusableBillTable> : null}
                                        {products.length > 0 ? <ProductsTable {...{handleChangeProductQuantity, handleDeleteProduct}}
                                                                              products={products} submitted={submitted} headData={productHeadData.filter(item=>Boolean(item))}
                                                               handleChangeProductRate={handleChangeProductRate} isInPatient={true}
                                                                              isCurrency={isNotBase}
                                                                              handleChangeProductCurrencyRate={handleChangeProductCurrencyRate}
                                                              >
                                                              </ProductsTable> : null}
                                    {bedBill.length > 0 &&
                                    <table className="table table-bordered table-sm billing-invoice-table">
                                        <thead>
                                            <tr>
                                                <td colSpan={8} align="center">Bed Payment</td>
                                            </tr>
                                            <tr>
                                                {bedHeadData.filter(item=>Boolean(item)).map((item, index) => (<td key={index}>{item}</td>))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {bedBill.map((bed, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{bed.ward_name}</td>
                                                    <td>{bed.room_name}</td>
                                                    <td>{bed.bed_number}</td>
                                                    <td>{formatDateTime(bed.assign_date)}</td>
                                                    <td>{formatDateTime(bed.discharge_date)}</td>
                                                    <td>{bed.quantity}</td>
                                                    {/*{isNotBase ? <td>{bed.currency_rate}</td>:null}*/}
                                                    <td>{bed.rate}</td>
                                                    {/*{isNotBase ? <td>{bed.currency_total}</td>: null}*/}
                                                    <td>{bed.amount}</td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>

                                    </table>}
                                    </div>
                        </div>
                        
                        <button type="button" onClick={handleAddProduct} className="btn btn-sm btn-primary mr-3"
                            disabled={isSubmitted === 'pending'}>Add Product</button>
                        <button type="button" onClick={handleAddService} className="btn btn-sm btn-secondary mr-3"
                            disabled={isSubmitted === 'pending'}>Add Service</button>
                        <button type="submit" className="btn btn-sm sms-info-btn"
                            disabled={isSubmitted === 'pending'}>{isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                </form>

            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(InpatientBillForm);
