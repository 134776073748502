import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {dateConvert} from "../../../Utils/ConvertDate"

const useMohReports = (actions) => {
    const [loading, setLoading] = useState('idle');
    const [reports, setReports] = useState({diagnosis:[], attendance:[], referrals:{}});
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert(), patient_type:'1'})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {start_date, end_date, patient_type} = state;
    const [without_icd_notes, setWithoutICDNotes] = useState({});

    const errorHandler = (reponse) =>{
        const res = reponse ? reponse : {};
        const value = res.value ? res.value : {};
        const data = value.data ? value.data : [];
        return data
    }

    useEffect(() => {

        setLoading('pending');
        const formData = new FormData();
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        formData.append('patient_type', patient_type);
        Promise.allSettled([
            axios.post(`${config.smsUrl}/creport/diagnosis_reports`, formData),
            axios.post(`${config.smsUrl}/creport/attendance_reports`, formData),
            axios.post(`${config.smsUrl}/creport/referral_reports`, formData),
            axios.post(`${config.smsUrl}/creport/notes_diagnoses`, formData)
          ])
          .then(([res1, res2, res3, res4]) => {
            setLoading('success')
            //diagnosis
            const diagnosis = errorHandler(res1)

            //diagnosis
            const attendance = errorHandler(res2)

            //referrals
            const referralResponse = res3 ? res3 : {};
            const referralValue = referralResponse.value ? referralResponse.value : {};
            const referrals = referralValue.data ? referralValue.data : {}
            setReports({diagnosis, attendance, referrals})
            
            // diagnosis notes
            const data = res4.value.data;
            const dt = !data ? {} : data;
            setWithoutICDNotes(dt);
          })
          .catch(err => {
            logoutErrorMessage(err, null, actions);
            setLoading('error')
          });
    }, []);

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setLoading('pending');
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            formData.append('patient_type', patient_type);
            Promise.allSettled([
                axios.post(`${config.smsUrl}/creport/diagnosis_reports`, formData),
                axios.post(`${config.smsUrl}/creport/attendance_reports`, formData),
                axios.post(`${config.smsUrl}/creport/referral_reports`, formData),
                axios.post(`${config.smsUrl}/creport/notes_diagnoses`, formData)
              ])
              .then(([res1, res2, res3, res4]) => {
                setIsSubmitted('rejected')
                setLoading('success')
                //diagnosis
                const diagnosis = errorHandler(res1)
    
                //diagnosis
                const attendance = errorHandler(res2)
    
                //referrals
                const referralResponse = res3 ? res3 : {};
                const referralValue = referralResponse.value ? referralResponse.value : {};
                const referrals = referralValue.data ? referralValue.data : {}
                setReports({diagnosis, attendance, referrals})  

                // diagnosis notes
                const data = res4.value.data;
                const dt = !data ? {} : data;
                setWithoutICDNotes(dt);

              })
              .catch(err => {
                logoutErrorMessage(err, null, actions);
                setLoading('error')
                setIsSubmitted('rejected')
              });
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = loading === 'rejected';

    // for the diagnosis notes
    const handleChangeNewPage = (page) => {
      setActiveTablePage(page);
    }

    // keep track of the page numbers in the table
    const [activeTablePage, setActiveTablePage] = useState(1);

    return {reports, closeSnackbar,  isLoading, isSuccess, isError, state,submitted,
        handleChange, handleSubmit, isPending, isRejected, isResolved,
        handleChangeNewPage, activeTablePage, without_icd_notes
      }
}
export {useMohReports}