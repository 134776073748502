import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import * as snackbarActions from '../../../actions/snackbarActions';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {useAccountsPayablesDetails} from "./hooks/useAccountsPayablesDetails";
import {LoadingGif} from "../../Utils/Loader";
import {formatDigits} from "../../Utils/formatNumbers";
import {ProductsPurchased} from "./ProductsPurchased";
import {HospitalAddress} from "../../Utils/HospitalAddress";
import {AccountingDrawer} from "../../Utils/AccountingUtils/AccountingDrawer";
import {SingleSupplierPayment} from "./SingleSupplierPayment";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {DataCell} from "../../Utils/Lists/CollapsibleList";
import styled from "@emotion/styled/macro";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {Container} from "../../Utils/styledComponents";




const useStyles = makeStyles((theme) => ({
    drawerWidth:{
        width: 600,
        padding:'16px'
    },
    table:{
        // width:'40%',
        marginTop:'20px'
    },
    paragraph:{
        fontSize:'15px',
        display:'inline-block',
        marginBottom:'0.8rem',
        marginRight:'16px'
    },
    icon: {
        width: '.9em',
        height: '.9em'
    },
    iconHolder: {
        minWidth: '38px '
    },
    item: {
        borderRight: '1px solid #c7c6c7',
        borderLeft: '1px solid #c7c6c7',
        height: '100vh',
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#c7c6c7',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    },
    pItem: {
        borderLeft: '1px solid #c7c6c7',
    },
    rounded: {
        borderRadius: 0
    },
    listText: {
        textAlign: 'right'
    },
    approved: {
        color: "green"
    },
    purchased: {
        color: "#1d5dd3"
    },
    canceled: {
        color: '#D9512C'
    },
    pending: {
        color: "#F29339"
    }
}));

const StyledCell = styled(DataCell)({
    border:0
})
const AccountsPayablesDetails = ({actions, snackbars, match}) => {
    const classes = useStyles();
    const  { products,invoice, isErrorProducts,  isLoadingProduct,
        isSuccessProducts,    closeSnackbar,pay_details,
        paymentProps} = useAccountsPayablesDetails(actions,match)
    const {productData} = products
    const {openBar, type, message} = snackbars;
    // const bulkPayment = productData.filter(item=>item.checked).length > 0
    const {openDialog, handleOpenDialog, handleCloseDialog, state} = paymentProps
    const {invoice_no} = state
    const details = pay_details ? pay_details : {}
    const balance = details.balance ? details.balance : 0
    const paid_amount = details.paid_amount ? details.paid_amount : 0
    const grand_total = details.grand_total ? details.grand_total : 0
    const currency = details.currency
    return (
        <div className='journals'>
            <PageTitle title="Accounts Payable Details"/>
            <SubHeader title="Reports" subTitle="Accounts Payable Details">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <MainSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            <AccountingDrawer open={openDialog} title={`Payment for Invoice# ${invoice_no}`} handleClose={handleCloseDialog} width={classes.drawerWidth}>
                <SingleSupplierPayment {...paymentProps}/>
            </AccountingDrawer>
            <Container  classes={{root: classes.rounded}}>
                {/*<Grid container spacing={0}>*/}
                {/*    <Grid item xs={6} md={4} classes={{item: classes.item}}>*/}
                {/*        <div  className="top-bar">*/}
                {/*            <BackButton text='Accounts Payable' to='/creditors/report'/>*/}
                {/*            /!*<button disabled={!bulkPayment} className='btn btn-sm pharmacy-info-btn'>Record Bulk Payment</button>*!/*/}
                {/*        </div>*/}
                {/*        {isLoadingSuppliers ? <div className='text-center'>*/}
                {/*            <LoadingGif/>*/}
                {/*        </div> : null}*/}
                {/*        {isSuccessSuppliers ?  supplierData.length > 0 ?*/}
                {/*            supplierData.map(item => {*/}
                {/*                const isActive = item.isActive === 'active'*/}
                {/*                const styles = {*/}
                {/*                    0: classes.pending,*/}
                {/*                    1: classes.approved,*/}
                {/*                    2: classes.canceled,*/}
                {/*                    3: classes.purchased*/}
                {/*                }*/}
                {/*                const text = {*/}
                {/*                    0: 'PENDING APPROVAL',*/}
                {/*                    1: 'APPROVED',*/}
                {/*                    2: 'CANCELED',*/}
                {/*                    3: 'FULFILLED'*/}
                {/*                }*/}

                {/*                const itemObj = {name:item.manufacturer_name,id:item.related_purchase,*/}
                {/*                    date:item.date_added ? formatDate(item.date_added): item.date_added,*/}
                {/*                    amount:item['Total Amount'] ? formatAmount(item['Total Amount']):item['Total Amount']*/}
                {/*                }*/}
                {/*                return(*/}
                {/*                    <ReportsItemList styles={styles} isActive={isActive} handleClick={()=>handleClickSupplier(item)}*/}
                {/*                                     text={text} item={itemObj}/>*/}
                {/*                )}) : <div className='text-center mt-3'>No records found</div>:null}*/}
                {/*        {isErrorSuppliers ? <div className='text-center mt-3'>The server did not return a valid response</div> : null}*/}

                {/*    </Grid>*/}
                {/*    <Grid item xs={6} md={8} classes={{item: classes.pItem}}>*/}

                        {isLoadingProduct ? <div className='text-center mt-5'><LoadingGif/></div>: null}
                        {isSuccessProducts ? productData.length > 0 ?  <>
                            <div className="top-bar">
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <div  className="mb-3">
                                            <BackButton text='Accounts Payable' to='/creditors/report'/>
                                            {/*<button disabled={!bulkPayment} className='btn btn-sm pharmacy-info-btn'>Record Bulk Payment</button>*/}
                                        </div>
                                        <p style={{fontSize:'18px', marginTop:'4px', marginBottom:0, marginLeft:0}}>Invoice#: <strong>{invoice}</strong></p>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className="text-right">
                                            <button type='button' style={{marginTop:'0.6rem'}} onClick={handleOpenDialog}
                                                    className="btn btn-sm sms-info-btn mr-2">Record Payment
                                            </button>
                                        </div>
                                        {/*<div className="text-right">*/}
                                        {/*    <div className='btn-group'>*/}
                                        {/*        <button type='button' onClick={downloadPdf}*/}
                                        {/*                className='btn btn-sm pharmacy-grey-btn'>*/}
                                        {/*            <PictureAsPdfOutlinedIcon classes={{root: classes.icon}}/>*/}
                                        {/*        </button>*/}
                                        {/*        <button type='button'*/}
                                        {/*                className='btn btn-sm pharmacy-grey-btn'>*/}
                                        {/*            <PrintOutlinedIcon classes={{root: classes.icon}}/>*/}
                                        {/*        </button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                            </div>

                            <div className="m-2">
                                <HospitalAddress/>
                                <ProductsPurchased products={productData}/>

                                <Table className={classes.table}>
                                    <TableBody>
                                        <TableRow>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell align='right'>Total Amount</StyledCell>
                                            <StyledCell ><strong>{currency} {formatDigits(grand_total)}</strong></StyledCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell align='right'>Payment Made</StyledCell>
                                            <StyledCell><strong>{formatDigits(paid_amount)}</strong></StyledCell>
                                        </TableRow>
                                        <TableRow>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell/>
                                            <StyledCell align='right'>Amount Due</StyledCell>
                                            <StyledCell><strong>{formatDigits(balance)}</strong></StyledCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </>: <div className='text-center mt-5'>No records found</div>:null }
                        {isErrorProducts ? <div className='text-center mt-5'>The server did not return a valid response</div> : null}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </Container>

        </div>
    )
}

function mapStateToProps(state) {

    return {
        snackbars: state.snackbar
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsPayablesDetails);