import React, {useEffect, useState} from "react"
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {convertDate, dateConvert} from "../../../Utils/ConvertDate";
import {history} from "../../../../Helpers/history";

function useRecordSupplierPayment(actions, obj) {
    const {products,invoice_no, payable_id, pay_details} = obj
    const [state, setState] = useState({payment_date:dateConvert(), payment_amount:0, notes:'',invoice_no:''})
    const [paid_through, setPaidThrough] = useState('')
    const [payment_method, setPaymentMethod] = useState('')
    const [accounts, setAccounts] = useState([])
    const [payTypes, setPayTypes] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState('idle')

    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/ledgers_under_category`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const arr = dt.data ? dt.data : []
            const list = arr.map(item => ({
                value: item.id,
                label: item.head_name
            }))
            setAccounts(list)
        }).catch(err => {
            errorMessages(err, null, actions)
        })
    }, [])


    // useEffect(() => {
    //     axios.get(`${config.epharmUrl}/Cinvoice/payment_types`).then(res => {
    //         const data = res.data;
    //         const dt = !data.data ? [] : data.data;
    //         const arr = dt.map(item=>({
    //             value:item.id,
    //             label:item.payment_type_name
    //         }))
    //         setPayTypes(arr);
    //     }).catch(error => {
    //         errorMessages(error, null, actions)
    //     })
    // }, [])

    const calculateSubTotal = (arr) => {
        return arr.reduce((a, item) => {
            return a + item.total_amount
        }, 0)
    }

    useEffect(()=>{
        if (products.length === 0 && pay_details){
            return
        }

        const details = pay_details ? pay_details : {}
        const balance = details.balance ? details.balance : 0

        const total_amount = calculateSubTotal(products)
        setState({...state, invoice_no, payment_amount: balance ? balance : total_amount})


    },[products, invoice_no])

    const handleChange = (event) =>{
        const {name, value} = event.target
        setState({...state,[name]:value})
    }

    const handleChangePaidThrough = (value) =>{
        setPaidThrough(value)
    }

    const handleChangePaymentMethod = (value) =>{
        setPaymentMethod(value)
    }

    const handleOpenDialog = () =>{
        setOpenDialog(true)
    }

    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmitted(true);
        const {payment_amount, payment_date}= state
        const credit_ledger = paid_through ? paid_through : {}
        if (payment_amount && payment_date && paid_through) {
            const data = {payable_id:payable_id,amount_paid:payment_amount,credit_ledger:credit_ledger.value ? credit_ledger.value:''}
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/csupplier/supplier_payables/payment`, data).then(() => {
                actions.snackbarActions.snackSuccess('Payment made successfully')
                setIsSubmitted('resolved')
                setSubmitted(false)
                setOpenDialog(false)
                setPaidThrough(null)
                history.push('/creditors/report')
                // setState({payment_date: ''})
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected')
            })
        }
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'

    const paymentProps =  {state, payment_method, paid_through, payTypes, accounts,openDialog, handleCloseDialog, handleOpenDialog,
    handleChangePaymentMethod, handleChangePaidThrough, handleChange, submitted, isPending, handleSubmit}

    return {paymentProps,isResolved}
}
export {useRecordSupplierPayment}