import React, {useEffect, useState} from 'react'
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from '../../../actions/snackbarActions'
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import { PLContainer,ProfitContainer} from '../../Utils/styledComponents';
import {CoaList, CustomCell } from '../../Utils/AccountingUtils/CoaList';
import { usePrint } from '../../Utils/Templates/usePrint';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons/faClipboardList';
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import {PrintPdfButtons} from "./PrintPdfButtons";
import hospitalAddress from "../../../HospitalAddress"
import Card  from '@material-ui/core/Card';
import { formatDate } from '../../Utils/ConvertDate';
import {NoTransaction} from "../ChartofAccounts/ReusableJournalTable";
import { LoadingGif } from '../../Utils/Loader';
import TableRow from "@material-ui/core/TableRow";
import TableHead from '@material-ui/core/TableHead';
import {useDates} from "../Expenses/hooks/useDates";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import {ReportsTreeView} from "./ReportsTreeView";
import {HospitalAddress} from "../../Utils/HospitalAddress";




const BalanceSheet = ({actions, snackbar}) => {

    const [data, setData] = useState(null);
    const [date, handleChangeDate] = useDates();
    const [loading, setLoading] = useState('idle')
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {from_date, to_date} = date;

    const formatData = (data={}) =>{
        let arr = []
        for (let item in data){
            const obj = {
                head_name: item,
                head_level:-1,
                id:item,
                children: data[item]
            }
            arr.push(obj)
        }
        return arr
    }

    useEffect(() => {
        const formData = new FormData();
        formData.append('start_date', from_date);
        formData.append('end_date', to_date)
        setLoading('pending')
        axios.get(`${config.smsUrl}/accounting/reports/get_balance_sheet`).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            setData(obj)
            setLoading('success')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
        })

    }, [])



    const handleSubmit = (event) =>{
        setSubmitted(true)
        event.preventDefault()
        const formData = new FormData();
        formData.append('start_date', from_date);
        formData.append('end_date', to_date)
        setLoading('pending')
        setIsSubmitted('pending')
        axios.post(`${config.smsUrl}/accounting/reports/get_balance_sheet`, formData).then(res => {
            const data = res.data;
            const dt = data ? data : {};
            const dat = dt.data ? dt.data : {}
            const obj = formatData(dat)
            console.log(obj)
            setData(obj)
            setLoading('success')
            setIsSubmitted('resolved')
        }).catch(err => {
            errorMessages(err, null, actions)
            setLoading('error')
            setIsSubmitted('rejected')
        })
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    }
    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';

    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = isSubmitted === 'rejected';


    const {open, variant, message} = snackbar;

    const balanceSheet = data ? data : [];

    const {image} = hospitalAddress

    const {componentRef, handlePrint} = usePrint( `Balance Sheet(${to_date})`)
    const header = (
        <TableHead>
            <TableRow>
                <CustomCell><strong></strong></CustomCell>
                <CustomCell align='center'><strong>Amount(UGX)</strong></CustomCell>
            </TableRow>
        </TableHead>
    )
    const printDoc = (
        <div  ref={componentRef}>
            <ProfitContainer>
                <HospitalAddress/>
                <table className="table table-sm table-borderless prescription-table">
                    <tbody>
                    <tr>
                        <td align='center'><h5><strong>Balance Sheet</strong></h5></td>
                    </tr>
                    <tr>
                        <td align='center'><h6><strong>As of {formatDate(to_date)}</strong></h6></td>
                    </tr>
                    </tbody>
                </table>
                <CoaList  {...{ data:balanceSheet, isPrint:true,header}}/>
                <p style={{textAlign: "left", fontStyle: "italic"}}  className="mpeke-footer">Powered by Mara Scientific</p>
            </ProfitContainer>
        </div>
    )

    return (
        <div className='journals'>
            <PageTitle title="Balance Sheet"/>
            <SubHeader title="Reports" subTitle="Balance Sheet">
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>

            <MainSnackbar message={message} open={open} handleCloseBar={closeSnackbar} variant={variant}/>


            <PLContainer>
                <div className="my-3">
                    <BackButton to='/reports/generalreports' text='General Reports'/>
                </div>
                {/*<AccountingDateFilter {...{from_date,to_date, handleChange:handleChangeDate,*/}
                {/*    submitted,handleSubmit,isPending}}/>*/}
                {isLoading ? <div className='text-center mt-5'>
                    <LoadingGif/>
                </div>:null}
                {isSuccess ? balanceSheet.length > 0 ?
                    <Card>
                        <div style={{padding:'8px 32px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                            <PrintPdfButtons   handlePrint={handlePrint}/>
                        </div>
                        <div style={{padding:'32px'}}>
                            <div  id="profit-loss-statement-pdf" style={{overflow: "hidden"}}>
                                <HospitalAddress/>
                                <table className="table table-sm table-borderless prescription-table">
                                    <tbody>
                                        <tr>
                                            <td align='center'><h5><strong>Balance Sheet</strong></h5></td>
                                        </tr>
                                        <tr>
                                            <td align='center'><h6><strong> As of {formatDate(to_date)}</strong></h6></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ReportsTreeView  {...{ data:balanceSheet, reports:true,header, start_date:from_date, end_date:to_date}}/>
                                <p style={{textAlign: "center", fontStyle: "italic"}}>Powered by Mara Scientific</p>

                            </div>

                        </div>
                    </Card>: <NoTransaction text='transactions'/>: null}
                {isError ? <div>The server did not return a valid response</div>:null}
                <div style={{display:'none'}}>
                    {printDoc}
                </div>
            </PLContainer>

            {/* </Container> */}
        </div>
    );
}

function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(BalanceSheet);
