import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { config } from '../../../../Helpers/env'
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {useNewExpenseItem} from "./useNewExpenseItem";


export const useRecordExpenses = (actions) => {
    const [openExpense, setOpenExpense] = useState(false);
    const [expenses, setExpenses] = useState([{expense_item:'', receipt_no: '', amount:'', usd_amount:0}])
    const [expenseItems, setExpenseItems] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [currencies, setCurrencies] = useState([])
    const [currency, setCurrency] = useState('')
    const [exchangeRate, setExchangeRate] = useState(0)
    const [isBlocking, setIsBlocking] = useState(false)
    const [isBlockDialog, setIsBlockDialog] = useState(false)

    const {itemProps} = useNewExpenseItem(actions)
    const {isResolved:isItemResolved} = itemProps


    useEffect(() => {
        (async () => {
            try {
                const res = await axios.get(`${config.smsUrl}/accounting/expense_types`)
                let _data = res.data
                let dt = _data.data ?? [];
                let exp_types = dt.map(i => ({label: i.expense_type_name, value: i.id}))
                setExpenseItems(exp_types)
            } catch (e) {
                // errorMessages(e, null, actions)
            }
        })();
    }, [isItemResolved])

    // get currencies
    useEffect(() => {
        axios.get(`${config.smsUrl}/accounting/currencies`).then(res => {
            const data = res.data;
            const all_data = !data ? {}: data;
            const arr = all_data.data ?? [];
            const list = arr.map(item=>({value:`${item.id}-${item.is_base_currency}-${item.currency_symbol}`, text:`${item.currency_symbol} ${item.currency_name}`}))
            setCurrencies(list)
        }).catch(err => {
            logoutErrorMessage(err, null, actions)
        })
    }, []);


    const calculateAmount = (rate) =>{
        const sumArr = expenses.map(item=>({
            ...item, usd_amount:+item.amount * +rate
        }))
        setExpenses(sumArr)
    }


    //Change currency
    const handleChangeCurrency = (event) =>{
        setCurrency(event.target.value)
        setIsBlocking(true)
    }

    //Change exchange rate
    const handleChangeExchangeRate = (event) =>{
        setExchangeRate(event.target.value)
        calculateAmount(event.target.value)
        setIsBlocking(true)
    }




    const handleChangeExpenseItem = (value, index) => {
        const arr = expenses.map((item, idx)=>{
            if(index === idx){
                return {...item, expense_item:value}
            }
            return item
        })
        setExpenses(arr)
        setIsBlocking(true)
    }

    const handleChangeExpense = (event, index) => {
        const {value,name} = event.target;
        const arr = expenses.map((item, idx)=>{
            if(index === idx){
                return {...item, [name]:value}
            }
            return item
        })
        setExpenses(arr)
        setIsBlocking(true)
    }

    const handleChangeAmount = (event, index) => {
        const {value} = event.target;
        const arr = expenses.map((item, idx)=>{
            if(index === idx){
                return {...item, amount: +value, usd_amount: +value * exchangeRate}
            }
            return item
        })
        setExpenses(arr)
        setIsBlocking(true)
    }

    const handleAddExpense = () =>{
        const obj = {expense_item:'', receipt_no: '', amount:''}
        setExpenses([...expenses, obj])
    }

    const handleRemoveExpense = (index) =>{
        const arr = expenses.filter((_, idx)=>index !== idx)
        setExpenses(arr)
    }


    const handleOpenExpense = () =>{
        setOpenExpense(true)
    }

    const handleCloseExpense = () =>{
        if(isBlocking){
            setIsBlockDialog(true)
        }else{
            setOpenExpense(false)
            setIsBlockDialog(false)
            setIsBlocking(false)
        }
    }



    const handleSubmitExpense = (e) => {
        e.preventDefault()
        const isBase = currency.split('-')[1]

        const isNotBase = isBase === 'false'

        const currency_id = currency.split('-')[0]
        const data = expenses.map(item=>{
            const exp = item.expense_item ? item.expense_item : {}
            const value = exp.value ? exp.value : ''
            const isNotBaseData = isNotBase ?  {currency_id: +currency_id, amount_in_currency: +item.amount,rate_input_by_cashier:+exchangeRate,
                amount_in_base_currency: +item.usd_amount, is_base_currency:isBase} : {currency_id: +currency_id,is_base_currency: isBase, amount: +item.amount}
            return{
                related_expense_type:value,
                receipt_no: item.receipt_no,
                // amount: +item.amount,
                ...{currency_id: 3,is_base_currency: "true", amount: +item.amount}
               // ...isNotBaseData
            }
        })
        const arr = expenses.every(item=>item.expense_item && item.amount)
        setSubmitted(true);
        if (arr) {
            setIsSubmitted('pending')
            axios.post(`${config.smsUrl}/accounting/expenses/add`, data).then(() => {
                actions.snackbarActions.snackSuccess('Expense recorded successfully')
                setIsSubmitted('resolved')
                const exps = expenses.map(item=>({expense_item:'', receipt_no:'', amount:'',...item}))
                setExpenses(exps)
                setCurrency('')
                setExchangeRate('')
                setOpenExpense(false)
            }).catch(err => {
                errorMessages(err, null, actions);
                setIsSubmitted('rejected')
            })
        }
    }

    const handleResetForm = () =>{
        setOpenExpense(false)
        setIsBlockDialog(false)
        setIsBlocking(false)
        const exps = expenses.map(item=>({...item,expense_item:'', receipt_no:'', amount:''}))
        setExpenses(exps)
        setCurrency('')
        setExchangeRate('')
    }

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

    const expenseProps = {openExpense, expenses, expenseItems, submitted, handleChangeExpenseItem,
        handleAddExpense, handleCloseExpense,handleOpenExpense, handleChangeExpense, handleSubmitExpense,
        handleRemoveExpense, isPending, isRejected, isResolved, itemProps, currency, currencies,
    handleChangeCurrency, handleChangeExchangeRate, exchangeRate, handleChangeAmount,
        isBlocking, isBlockDialog, setOpenExpense,setIsBlockDialog, setIsBlocking, handleResetForm}

    return {expenseProps}
}
