import React from "react";
import Row from "react-bootstrap/Row";
import Label from "../Utils/FormInputs/Label";
import Col from 'react-bootstrap/Col';
import { DateTimeFilter } from "../Utils/ReusableComponents/DateTimeFilter";
import SelectInput from "../Utils/FormInputs/SelectInput";
import styled from '@emotion/styled/macro';
import * as mq from "../../styles/media-queries";
import { ErrorMessage } from "../Utils/styledComponents";

const StyledForm  = styled.form({
    [mq.extraLarge]:{
        width:'80%'
    }
});

// is filter for date, department and operation Class
export const DDOFilterComponent = ({
    isFilterByDate, isFilterByDepartment, isFilterByOperationClass, filter_by, handleChange, departments,
    start_date, end_date, submitted, handleSubmit, isPending, department, filter_operation_class,
    }) => {

    const operationClasses = [
        { text: 'Minor', value: "minor" },
        { text: 'Major', value: "major" }
    ];

    return (
        <div className="surgery-filter">
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={4} >
                    <Row>
                        <Col xs={12} sm={2} md={2} lg={3} xl={2} className="textsurgery-align adjust-filter-by">
                            <Label name="Filter By:" />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={8}>
                            <SelectInput value={filter_by} onChange={handleChange} name="filter_by"
                                         options={[{value:'1', text:'Operation Date'},{value:'2', text:'Department'}, {value:'3', text:'Operation Class'}]}/>
                        </Col>
                    </Row>
                </Col>

                {isFilterByDate ? 
                    <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                        <DateTimeFilter {...{start_date, end_date, handleChange, submitted, handleSubmit, isPending, type:'date'}}/>
                    </Col>
                : null }

                {isFilterByDepartment ? 
                    <div className="surgery-filter-components" >

                        <Col sm={4} className="textsurgery-align">
                            <Label name='Department' type/>              
                        </Col>

                        <StyledForm onSubmit={handleSubmit} className="surgery-filter-components">
                            <Col>
                                <SelectInput defaultOption value={department} onChange={handleChange} name='department' options={departments} />
                                {submitted && !department ? <ErrorMessage>This is a required field</ErrorMessage>: null}   
                            </Col>
                            <Col>
                                <button type='submit' className='btn btn-sm sms-info-btn' disabled={isPending}>{isPending ? 'Searching...':'Search'}</button>  
                            </Col>
                        </StyledForm>

                    </div>
                : null}

                {isFilterByOperationClass ? 
                    <div className="surgery-filter-components">
                        <Col sm={4} className="textsurgery-align">
                            <Label name='Operation Class' type/>
                        </Col>

                        <StyledForm onSubmit={handleSubmit} className="surgery-filter-components">
                            <Col sm={6}>
                                <SelectInput name='filter_operation_class' value={filter_operation_class} onChange={handleChange} options={operationClasses} defaultOption/>
                                {submitted && !filter_operation_class ? <ErrorMessage>This is a required field</ErrorMessage>: null}
                            </Col>
                            <Col>
                                <button type='submit' className='btn btn-sm sms-info-btn' disabled={isPending}>{isPending ? 'Searching...':'Search'}</button>
                            </Col>  
                        </StyledForm>
                    </div>
                : null}

            </Row>

        </div>
    )
}