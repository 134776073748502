
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons/faHome";
import {faWheelchair} from "@fortawesome/free-solid-svg-icons/faWheelchair";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {faUserMd} from "@fortawesome/free-solid-svg-icons/faUserMd";
import {faVials} from "@fortawesome/free-solid-svg-icons/faVials";
import {faXRay} from "@fortawesome/free-solid-svg-icons/faXRay";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import {faUserFriends} from "@fortawesome/free-solid-svg-icons/faUserFriends";
import {faMortarPestle} from "@fortawesome/free-solid-svg-icons/faMortarPestle";
import {faCog} from "@fortawesome/free-solid-svg-icons/faCog";
import {faBed} from "@fortawesome/free-solid-svg-icons/faBed";
import {faCalendarCheck} from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import {faBookMedical} from "@fortawesome/free-solid-svg-icons/faBookMedical";
import {faHouseUser} from "@fortawesome/free-solid-svg-icons/faHouseUser";
import {faMoneyBill} from "@fortawesome/free-solid-svg-icons/faMoneyBill";

const sidebarModules = () =>{
    const perm = JSON.parse(sessionStorage.getItem('permission'));
    const permission = !perm ? {} : perm;

    //patient
    const patient_registration = !permission.patient_registration ? {} : permission.patient_registration;
    const patient_list = !permission.patient_list ? {} : permission.patient_list;

    // added fields for the surgery in the session storage that retrieves from the database
    // const surgery = !permission.new_visit ? {} : permission.new_visit;

    //visit
    const new_visit = !permission.new_visit ? {} : permission.new_visit;
    const admission_form = !permission.admission_form ? {} : permission.admission_form;
    const discharge_form = !permission.discharge_form ? {} : permission.discharge_form;
    const triage_metrics_list = !permission.discharge_form ? {} : permission.discharge_form;
    //doctor
    const waiting_list = !permission.waiting_list ? {} : permission.waiting_list;
    const doctor_schedule = !permission.schedule ? {} : permission.schedule;
    //pharmacy
    const stock_pharmacy = !permission.stock_pharmacy ? {} : permission.stock_pharmacy;
    const prescription_list = !permission.prescription_list ? {} : permission.prescription_list;
    const medicine_type = !permission.medicine_type ? {} : permission.medicine_type;
    const unit = !permission.unit ? {} : permission.unit;
    const requisition_pharmacy = !permission.requisition_pharmacy ? {} : permission.requisition_pharmacy;
    const dispatch_list_pharmacy = !permission.dispatch_list_pharmacy ? {} : permission.dispatch_list_pharmacy;
    //lab
    const lab_request = !permission.lab_request ? {} : permission.lab_request;
    const add_parameters = !permission.add_parameters ? {} : permission.add_parameters;
    const lab_type = !permission.lab_type ? {} : permission.lab_type;
    const add_sample_type = !permission.add_sample_type ? {} : permission.add_sample_type;
    //radiology
    const scan_requests = !permission.scan_requests ? {} : permission.scan_requests;

    //billing
    const billing_list = !permission.billing_list ? {} : permission.billing_list;
    const add_service = !permission.add_service ? {} : permission.add_service;
    const service_list = !permission.service_list ? {} : permission.service_list;
    const admitted_patients = !permission.admitted_patients ? {} : permission.admitted_patients;

    //hrm
    // const add_employee = !permission.add_employee ? {} : permission.add_employee;
    const employee_list = !permission.employee_list ? {} : permission.employee_list;

    //settings
    const add_role = !permission.add_role ? {} : permission.add_role;
    const assign_role = !permission.assign_role ? {} : permission.assign_role;
    const assigned_user_role = !permission.assigned_user_role ? {} : permission.assigned_user_role;
    const roles = !permission.roles ? {} : permission.roles;

    //bed manager
    const add_ward = !permission.add_ward ? {} : permission.add_ward;
    const ward_list = !permission.ward_list ? {} : permission.ward_list;
    const add_room = !permission.add_room ? {} : permission.add_room;
    const room_list = !permission.room_list ? {} : permission.room_list;
    const add_bed = !permission.add_bed ? {} : permission.add_bed;
    const bed_list = !permission.bed_list ? {} : permission.bed_list;
    const assign_bed = !permission.assign_bed ? {} : permission.assign_bed;
    const bed_assign_list = !permission.bed_assign_list ? {} : permission.bed_assign_list;
    const bed_transfer = !permission.bed_transfer ? {} : permission.bed_transfer;
    const bed_transfer_list = !permission.bed_transfer_list ? {} : permission.bed_transfer_list;

    //shifts & schedules
    const add_schedule = !permission.add_schedule ? {} : permission.add_schedule;
    const add_shift_category = !permission.add_shift_category ? {} : permission.add_shift_category;
    // const assign_shift = !permission.assign_shift ? {} : permission.assign_shift;
    const shedule_list = !permission.shedule_list ? {} : permission.shedule_list;
    const shift_list = !permission.shift_list ? {} : permission.shift_list;

    //inventory
    const add_supplier = !permission.add_supplier ? {} : permission.add_supplier;
    const make_requisition = !permission.make_requisition ? {} : permission.make_requisition;
    const products = !permission.products ? {} : permission.products;
    const purchase_orders = !permission.purchase_orders ? {} : permission.purchase_orders;
    const purchases = !permission.purchases ? {} : permission.purchases;
    const stock = !permission.stock ? {} : permission.stock;
    const dispatch_list_stock = !permission.product_dispatch_list ? {} : permission.product_dispatch_list;
    const requisition_list = !permission.requisition_list ? {} : permission.requisition_list;
    const suppliers = !permission.suppliers ? {} : permission.suppliers;


    //reports
    const death_reports = !permission.death_reports ? {} : permission.death_reports;
    const financial_reports = !permission.financial_reports ? {} : permission.financial_reports;
    const laboratory_reports = !permission.laboratory_reports ? {} : permission.laboratory_reports;
    const radiology_reports = !permission.radiology_reports ? {} : permission.radiology_reports;
    const inventory_reports = !permission.inventory_reports ? {} : permission.inventory_reports;


    //accounting
    const chart_of_accounts = !permission.chart_of_accounts ? {} : permission.chart_of_accounts;
    const fixed_assets = !permission.chart_of_accounts ? {} : permission.chart_of_accounts;

    //departments
    const departments = !permission.departments ? {} : permission.departments;

    //Patient Emergency
    const patient_emergency = !permission.patient_emergency ? {} : permission.patient_emergency;

    //Surgery
    const surgery = !permission.surgery ? {} : permission.surgery;

    //Queue
    const queue = !permission.queue ? {} : permission.queue;

    //antenatal

    const mother_child = permission.mother_child_health ? permission.mother_child_health : {}



    const arr = [{module_name:'Dashboard',  icon:<FontAwesomeIcon icon={faHome}/>, link:'/', modules:[], isVisible:true},
        {module_name:'Patients',  icon:<FontAwesomeIcon icon={faWheelchair}/>,  link:'/patientDetails/reception',
        isVisible:(patient_list.create || patient_list.read || patient_list.update ||
            patient_list.delete || patient_registration.create || patient_registration.read || patient_registration.update ||
            patient_registration.delete),modules:[]},
        {module_name:'Visits',  icon:<FontAwesomeIcon icon={faEdit}/>,
        modules:[{text:'Out Patient Visits', route:'/newvisit',isVisible:(new_visit.create || new_visit.read || new_visit.update ||
            new_visit.delete) },
        {text:'Previous Visits', route:`/previous_visits/${true}`,isVisible:(new_visit.create || new_visit.read || new_visit.update ||
                new_visit.delete)},
        {text:'Triage Waiting List', route:'/triagewaitinglist',isVisible:(new_visit.create || new_visit.read || new_visit.update ||
                    new_visit.delete) },
        {text:'Admission Form', route:'/admissionform', isVisible:(admission_form.create || admission_form.read || admission_form.update ||
            admission_form.delete)},
        // {text:'Discharge Form', route:'/dischargeform', isVisible:(discharge_form.create || discharge_form.read || discharge_form.update ||
        //     discharge_form.delete)},
        {text:'Triage Metrics List', route:'/triagemetrics', 
        isVisible:(triage_metrics_list.create || triage_metrics_list.read || triage_metrics_list.update ||
            triage_metrics_list.delete)},
        // adding the Surgery visit in the Visit module, used CRUD applications for the triage
        {text:'Surgery', route:'/surgeryvisit',
        isVisible: (surgery.create || surgery.read || surgery.update ||
            surgery.delete)}
        ]},

        {module_name:'Queue', link:'/queues/triage',  icon:<FontAwesomeIcon icon={faNotesMedical}/>,
            isVisible:(queue.create || queue.read || queue.update ||
                queue.delete), modules:[]},

        // adding the surgery module
        {
            module_name:'Surgery', icon:<img src="/images/surgery-22.png" alt="surgery" className="surgery-module-icon"/>,
            modules:[
                {text:'Current Surgeries', route:'/surgerymodule/currentsurgeries', 
                isVisible: (surgery.create || surgery.read || surgery.update || surgery.delete)
            },
            {
                text:'Past Surgeries', route: '/surgerymodule/pastsurgeries',
                isVisible: (surgery.create || surgery.read || surgery.update || surgery.delete)
            }
            ]
        },

        {module_name:'Doctor',  icon:<FontAwesomeIcon icon={faUserMd}/>,
        modules:[{text:'Waiting List', route:'/waitinglist',isVisible:(waiting_list.create || waiting_list.read || waiting_list.update ||
            waiting_list.delete) },
        {text:'Schedule', route:'/doctorschedule', isVisible:(doctor_schedule.create || doctor_schedule.read || doctor_schedule.update ||
            doctor_schedule.delete)},
            {text:'Patients', route:'/patientDetails/doctor', isVisible:(doctor_schedule.create || doctor_schedule.read || doctor_schedule.update ||
                doctor_schedule.delete)}]},

        {module_name:'Patient Emergency',  icon:<FontAwesomeIcon icon={faWheelchair}/>,
        modules:[
            {
                text:'Record Emergency', route:'/EmergencyPatient',isVisible:(patient_emergency.create || patient_emergency.read || patient_emergency.update ||
                patient_emergency.delete) 
            },
            {text:'Outpatient Prescriptions', route:'/out-patient-prescriptions',isVisible:(patient_emergency.create || patient_emergency.read || patient_emergency.update ||
                patient_emergency.delete) },
            {
                text:'Stock', route:'/emergencystock', isVisible:(patient_emergency.create || patient_emergency.read || patient_emergency.update || patient_emergency.delete)
            },
            // {
            //     text:'Dispatch List', route:'/emergencydispatchlist', isVisible:(patient_emergency.create || patient_emergency.read || patient_emergency.update || patient_emergency.delete)
            // },
        ]},

        {module_name:'Pharmacy',  icon:<FontAwesomeIcon icon={faMortarPestle}/>,
        modules:[{text:'Prescription List', route:'/medicalAnalysis/out-patient',isVisible:(prescription_list.create || prescription_list.read || prescription_list.update ||
            prescription_list.delete)},
        {text:'Stock', route:'/pharmacystock',isVisible:(stock_pharmacy.create || stock_pharmacy.read || stock_pharmacy.update ||
            stock_pharmacy.delete)},
        // {text:'Dispatch List', route:'/pharmacydispatchlist',isVisible:(dispatch_list_pharmacy.create || dispatch_list_pharmacy.read || dispatch_list_pharmacy.update ||
        //     dispatch_list_pharmacy.delete)}
        ]},

        {module_name:'Laboratory',  icon:<FontAwesomeIcon icon={faVials}/>,
        modules:[{text:'Lab Requests', route:'/labrequests',isVisible:(lab_request.create || lab_request.read || lab_request.update ||
            lab_request.delete) },
        {text:'Lab Settings', route:'/labsettings', isVisible:(add_parameters.create || add_parameters.read || add_parameters.update ||
            add_parameters.delete) || (lab_type.create || lab_type.read || lab_type.update ||
            lab_type.delete) || (add_sample_type.create || add_sample_type.read || add_sample_type.update ||
            add_sample_type.delete)},
            {text:'Stock', route:'/lab-stock-list', isVisible:(add_parameters.create || add_parameters.read || add_parameters.update ||
                    add_parameters.delete) || (lab_type.create || lab_type.read || lab_type.update ||
                    lab_type.delete) || (add_sample_type.create || add_sample_type.read || add_sample_type.update ||
                    add_sample_type.delete)}]},

            
        {module_name:'Radiology',  icon:<FontAwesomeIcon icon={faXRay}/>,
        modules:[{text:'Scan Requests', route:'/scanRadiology',isVisible:(scan_requests.create || scan_requests.read || scan_requests.update ||
            scan_requests.delete)}]},

        {
            module_name:'Mother-Child HealthCare',
            icon:<img src="/images/antenatal-white.png" className="surgery-module-icon"/>,
            modules:
                [
                    {text:'Ante Natal', route:'/anc_lists',isVisible:(mother_child.create || mother_child.read || mother_child.update || mother_child.delete)}
                ]
        },
        
        {module_name:'Billing',  icon:<FontAwesomeIcon icon={faCoins}/>,
        modules:[{text:'Billing List', route:'/paymentList',isVisible:(billing_list.create || billing_list.read || billing_list.update ||
            billing_list.delete)},
        {text:'Credit Customers', route:'/providers',isVisible:(billing_list.create || billing_list.read || billing_list.update ||
                billing_list.delete)},
        {text:'Service List', route:'/servicelist/1',isVisible:(service_list.create || service_list.read || service_list.update ||
            service_list.delete || add_service.create || add_service.read || add_service.update ||
            add_service.delete)},
        {text:'Medicine Purchases', route:'/purchaselist',isVisible:(purchases.create || purchases.read || purchases.update ||
            purchases.delete)}]},

        {module_name:'Accounting',  icon:<FontAwesomeIcon icon={faMoneyBill}/>,
        modules:[{text:'Chart of Accounts', route:'/accounting/coa',isVisible:(chart_of_accounts.create || chart_of_accounts.read || chart_of_accounts.update ||
            chart_of_accounts.delete)},
            {text:'Setup Accounts', route:'/accounting/setup',isVisible:(chart_of_accounts.create || chart_of_accounts.read || chart_of_accounts.update ||
                    chart_of_accounts.delete)},

            {text:'Journals', route:'/accounting/journals/account',isVisible:(chart_of_accounts.create || chart_of_accounts.read || chart_of_accounts.update ||
                chart_of_accounts.delete)},
        {text:'Expenses', route:'/accounting/expenses',isVisible:(chart_of_accounts.create || chart_of_accounts.read || chart_of_accounts.update ||
                    chart_of_accounts.delete)},
            {text:'Fixed Assets', route:'/accounting/fixedassets',isVisible:(chart_of_accounts.create || chart_of_accounts.read || chart_of_accounts.update ||
                    chart_of_accounts.delete)}
    ]},
    
    {module_name:'Ward Manager',  icon:<FontAwesomeIcon icon={faBed}/>,
    modules:[
    // {text:'Make Requisition', route:'/makerequisition/ward',isVisible:(make_requisition.create || make_requisition.read ||
    // make_requisition.update || make_requisition.delete)},
    {text:'Ward List', route:'/wardlist',isVisible:(add_ward.create || add_ward.read || add_ward.update ||
        add_ward.delete) || (ward_list.create || ward_list.read || ward_list.update ||
        ward_list.delete)},
    {text:'Room List', route:'/roomlist',isVisible:(add_room.create || add_room.read || add_room.update ||
        add_room.delete) || (room_list.create || room_list.read || room_list.update ||
        room_list.delete)},
    {text:'Bed List', route:'/bedlist',isVisible:(add_bed.create || add_bed.read || add_bed.update ||
        add_bed.delete) || (bed_list.create || bed_list.read || bed_list.update ||
        bed_list.delete)},
    {text:'Bed Assign List', route:'/bedassignlist',isVisible:(assign_bed.create || assign_bed.read || assign_bed.update ||
        assign_bed.delete) || (bed_assign_list.create || bed_assign_list.read || bed_assign_list.update ||
        bed_assign_list.delete)},
    {text:'Bed Transfer List', route:'/bedtransferlist',isVisible:(bed_transfer.create || bed_transfer.read || bed_transfer.update ||
        bed_transfer.delete) || (bed_transfer_list.create || bed_transfer_list.read || bed_transfer_list.update ||
        bed_transfer_list.delete)},]},

    {module_name:'In-patient Followup', link:'/patientroundlist',  icon:<FontAwesomeIcon icon={faNotesMedical}/>,
    isVisible:(admitted_patients.create || admitted_patients.read || admitted_patients.update ||
        admitted_patients.delete), modules:[]},

    {module_name:'Shifts & Schedule',  icon:<FontAwesomeIcon icon={faCalendarCheck}/>,
    modules:[{text:'Shift Categories', route:'/shiftcategorylist',isVisible:(add_shift_category.create || add_shift_category.read || add_shift_category.update ||
        add_shift_category.delete)},
    // {text:'Assign Shift', route:'/staffshift',isVisible:(assign_shift.create || assign_shift.read || assign_shift.update ||
    //     assign_shift.delete)},
    {text:'Shift List', route:'/shiftlist',isVisible:(shift_list.create || shift_list.read || shift_list.update ||
        shift_list.delete)},
    {text:'Schedule List', route:'/schedulelist',isVisible:(add_schedule.create || add_schedule.read || add_schedule.update ||
        add_schedule.delete) || (shedule_list.create || shedule_list.read || shedule_list.update ||
        shedule_list.delete)}]},
 
    {module_name:'Inventory/Stores',  icon:<FontAwesomeIcon icon={faShippingFast}/>,
    modules:[{text:'Products', route:'/productlist',isVisible:(products.create || products.read || products.update ||
        products.delete)},
    {text:'Purchase Orders', route:'/purchaseorderlist',isVisible:(purchase_orders.create || purchase_orders.read || purchase_orders.update ||
        purchase_orders.delete)},
    {text:'Stock', route:'/stocklist',isVisible:(stock.create || stock.read || stock.update ||
        stock.delete)},
    // {text:'Dispatch Products', route:'/add_dispatch',isVisible:(dispatch_list_stock.create || dispatch_list_stock.read || dispatch_list_stock.update ||
    //         dispatch_list_stock.delete)},
    {text:'Dispatch List', route:'/dispatchlist/new',isVisible: (dispatch_list_stock.create || dispatch_list_stock.read || dispatch_list_stock.update ||
            dispatch_list_stock.delete)},
    {text:'Requisition List', route:'/requisitionlist/1',isVisible:(requisition_list.create || requisition_list.read || requisition_list.update ||
        requisition_list.delete)},
    {text:'Unit', route:'/unitlist',isVisible:(dispatch_list_stock.create || dispatch_list_stock.read || dispatch_list_stock.update ||
            dispatch_list_stock.delete)},
    {text:'Medicine Type', route:'/typelist',isVisible:(medicine_type.create || medicine_type.read || medicine_type.update ||
        medicine_type.delete)},
     {text:'Suppliers', route:'/suppliers',isVisible:(add_supplier.create || add_supplier.read || add_supplier.update ||
        add_supplier.delete) || (suppliers.create || suppliers.read || suppliers.update ||
        suppliers.delete)},
        {text:'Return', route:'/returns/supplier',isVisible:(add_supplier.create || add_supplier.read || add_supplier.update ||
                add_supplier.delete) || (suppliers.create || suppliers.read || suppliers.update ||
                suppliers.delete)}
    ]},

        {module_name:'Human Resource',  icon:<FontAwesomeIcon icon={faUserFriends}/>,  link:'/employeelist',
            isVisible:(employee_list.create || employee_list.read || employee_list.update ||
        employee_list.delete),modules:[]},


    {module_name:'Departments',  icon:<FontAwesomeIcon icon={faHouseUser}/>, link:'/departments', isVisible:(departments.create || departments.read || departments.update ||
                    departments.delete),modules:[]},

    {module_name:'Reports',  icon:<FontAwesomeIcon icon={faBookMedical}/>, link:'/reports/generalreports', 
    isVisible:(death_reports.create || death_reports.read || death_reports.update ||
        death_reports.delete) || (laboratory_reports.create || laboratory_reports.read || laboratory_reports.update ||
        laboratory_reports.delete) || (radiology_reports.create || radiology_reports.read || radiology_reports.update ||
        radiology_reports.delete) || (financial_reports.create || financial_reports.read || financial_reports.update ||
        financial_reports.delete) || (inventory_reports.create || inventory_reports.read || inventory_reports.update ||
        inventory_reports.delete),modules:[]},

    {module_name:'Settings',  icon:<FontAwesomeIcon icon={faCog}/>,
    modules:[{text:'Roles', route:'/roles',isVisible:(roles.create || roles.read || roles.update ||
        roles.delete || add_role.create || add_role.read || add_role.update ||
        add_role.delete)},
    {text:'User Roles', route:'/userroles',isVisible:(assigned_user_role.create || assigned_user_role.read || assigned_user_role.update ||
        assigned_user_role.delete || assign_role.create || assign_role.read || assign_role.update ||
        assign_role.delete)},
        {text:'Company Details', route:'/companydetails',isVisible:(assigned_user_role.create || assigned_user_role.read || assigned_user_role.update ||
            assigned_user_role.delete || assign_role.create || assign_role.read || assign_role.update ||
            assign_role.delete)},
    // {text:'Company Details', route:'/'}
    ]},
    ]
    return arr
}
export {sidebarModules}
