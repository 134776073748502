import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import TextField from "../../Utils/FormInputs/TextField";
import { ErrorMessage } from "../../Utils/styledComponents";
import { CustomSelect, ReusabelSelect } from "../../Utils/FormInputs/SelectInput";
import InputGroup from "react-bootstrap/InputGroup";
import {DataCell, ModuleTable} from "../../Utils/Lists/CollapsibleList";
import TableRow from "@material-ui/core/TableRow";
import {StyledCell} from "../../DoctorsModule/doctorStyles";

const headData = [{item:'Service', toolTip:'Enter service name', isRequired:true},
    {item:'Cost',  toolTip:'Enter cost'}, {item:'Department'},{item:'Doctor Name'},{item:'Action'}]

export const ProcedureTable = ({procedures, services, submitted, handleAddProcedureRow, handleRemoveProcedureRow,
                                   docs,  handleChangeProcedureService,
                                   handleChangeDoctorMultipleProcedures}) => {

    return (
        <div className="table-responsive">
            <ModuleTable headData={headData}>
                {procedures && procedures?.map((procedure, index) => (
                    <TableRow
                        key={procedure.uuid}
                    >
                        <DataCell variant='250'>
                                <ReusabelSelect submitted={submitted}  onChange={(e)=>handleChangeProcedureService(e,index)} id='service'
                                                value={procedure.service} options={services} name='service' defaultOption={'Select'}
                                                data-test="service"/>
                                {(submitted && !procedure.service) &&
                                    <ErrorMessage>Service is required</ErrorMessage>}
                        </DataCell>
                        <DataCell variant='150'>
                            <InputGroup>
                                <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{procedure.currency_symbol}</InputGroup.Text>
                                <TextField  type="number" value={procedure.cost} style={{pointerEvents:'none'}}/>
                            </InputGroup>
                        </DataCell>
                        <DataCell variant='200'>
                            <TextField submitted={submitted}  id='visit_department'
                                       value={procedure.department_name} name='department_name'
                                       data-test="department_name" disabled={true}/>
                            {(submitted && procedure.department_name) &&
                                <ErrorMessage>Department is required</ErrorMessage>}
                        </DataCell>
                        <DataCell variant='200'>
                            <CustomSelect id='visit_doctor' onChange={(e)=>handleChangeDoctorMultipleProcedures(e, index)} name='doctor_name'
                                          value={procedure.doctor_name} options={docs.map(doctor => ({
                                value: doctor.user_roles_id,
                                label: `${doctor.first_name} ${doctor.last_name}`
                            }))}
                                          data-test="doctor_name"/>
                            {(submitted && procedure.doctor_name) &&
                                <ErrorMessage>Doctor is required</ErrorMessage>}
                        </DataCell>
                        <DataCell>
                            <button onClick={() => handleRemoveProcedureRow(index)} type="button"
                                    className="btn btn-sm sms-btn-dismiss" data-testid="remove-row">
                                <FontAwesomeIcon icon={faMinus}/></button>
                        </DataCell>

                    </TableRow>
                ))}
                <TableRow>
                    <StyledCell colSpan={headData.length}>
                        <button type="button" onClick={handleAddProcedureRow} className="btn btn-sm sms-gray-btn"
                                id="add-row">Add another line</button>
                    </StyledCell>
                </TableRow>
            </ModuleTable>
            {/*<table className={`table table-sm table-bordered billing-invoice-table procedure-table  ${palette.type === 'dark' &&*/}
            {/*'dark-table dark-table-bordered'}`}>*/}
            {/*    <thead>*/}
            {/*    <td width="200px" className={palette.type === 'dark' && 'dark-theme-color'}>Service<span className="help-block">*</span></td>*/}
            {/*    <td align='center' width="150px" className={palette.type === 'dark' && 'dark-theme-color'}>Cost</td>*/}
            {/*    <td align='center' width="150px" className={palette.type === 'dark' && 'dark-theme-color'}>Department</td>*/}
            {/*    <td align='center' width="150px" className={palette.type === 'dark' && 'dark-theme-color'}>Doctor Name</td>*/}
            {/*    <td align='center' width="80px"/>*/}
            {/*    </thead>*/}
            {/*    <tbody>*/}
            {/*    {procedures && procedures.map((procedure, index) => (*/}
            {/*        <tr key={index}>*/}
            {/*            <td> */}
            {/*            <Form.Group>*/}
            {/*                    <ReusabelSelect submitted={submitted}  onChange={(e)=>handleChangeProcedureService(e,index)} id='service'*/}
            {/*                                    value={procedure.service} options={services} name='service' defaultOption={'Select'}*/}
            {/*                                    data-test="service"/>*/}
            {/*                    {(submitted && !procedure.service) &&*/}
            {/*                    <ErrorMessage>Service is required</ErrorMessage>}*/}
            {/*              */}
            {/*            </Form.Group>*/}

            {/*                   </td>*/}
            {/*            <td>*/}
            {/*                <InputGroup>*/}
            {/*                    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{procedure.currency_symbol}</InputGroup.Text>*/}
            {/*                    <TextField  type="number" value={procedure.cost} style={{pointerEvents:'none'}}/>*/}
            {/*                </InputGroup>*/}
            {/*            </td>*/}

            {/*            <td>*/}
            {/*                <Form.Group>*/}
            {/*                    <TextField submitted={submitted}  id='visit_department'*/}
            {/*                                    value={procedure.department_name} name='department_name'*/}
            {/*                                    data-test="department_name" disabled={true}/>*/}
            {/*                    {(submitted && procedure.department_name) &&*/}
            {/*                    <ErrorMessage>Department is required</ErrorMessage>}*/}
            {/*                */}
            {/*                </Form.Group>*/}
            {/*            </td>*/}
            {/*                 <td>*/}
            {/*                        <CustomSelect id='visit_doctor' onChange={(e)=>handleChangeDoctorMultipleProcedures(e, index)} name='doctor_name'*/}
            {/*                                        value={procedure.doctor_name} options={docs.map(doctor => ({*/}
            {/*                            value: doctor.user_roles_id,*/}
            {/*                            label: `${doctor.first_name} ${doctor.last_name}`*/}
            {/*                        }))}*/}
            {/*                                        data-test="doctor_name"/>*/}
            {/*                        {(submitted && procedure.doctor_name) &&*/}
            {/*                        <ErrorMessage>Doctor is required</ErrorMessage>}*/}
            {/*                 </td>*/}

            {/*                   <td align='center'>*/}

            {/*                    <button onClick={() => handleRemoveProcedureRow(index)} type="button"*/}
            {/*                            className="btn btn-sm sms-btn-dismiss" data-testid="remove-row">*/}
            {/*                        <FontAwesomeIcon icon={faMinus}/>*/}
            {/*                    </button>*/}
            {/*            </td>*/}
            {/*        </tr>*/}
            {/*    ))}*/}

            {/*    <tr>*/}
            {/*        <td colSpan={3}>*/}
            {/*            <button type="button" onClick={handleAddProcedureRow} className="btn btn-sm sms-gray-btn"*/}
            {/*                    id="add-row">Add another line</button>*/}
            {/*        </td>*/}
            {/*    </tr>*/}
            {/*    </tbody>*/}
            {/*</table>*/}
        </div>
    )
}
