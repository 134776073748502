import React from "react";
import { useTheme } from "@material-ui/core/styles";
import {dateConvert} from "../components/Utils/ConvertDate";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const SubHeader = ({ title, subTitle, children , doctor_name = null, classTitle='', classSubTitle='', settingsButton = null}) => {
  const theme = useTheme();
  const them = !theme ? {} : theme;
  const palette = !them.palette ? {} : them.palette;

  return (
    <section className={`sub-header ${palette.type === "dark" && "dark-theme-border"}`}>
      <Row>
        <Col xs="auto" style={{paddingRight:"0px"}}>
          <div className="header-icon">{children}</div>
        </Col>
        
        <Col xs="auto"  style={{paddingLeft:"0px"}}>
          <h5 className={classTitle}>{title}</h5>
          <span className={classSubTitle}>{subTitle}</span>
        </Col>

        <Col>
        {doctor_name !== null ?
          <div>
                <p className="date-above date-doctor-style">Date: <strong>{dateConvert()}</strong></p>
                <p className="doctor-below date-doctor-style">Doctor: <strong>{doctor_name}</strong></p>
          </div>
        : null}
        </Col>
        
        {settingsButton !== null ?
          <div style={{float:'right'}}>
            {settingsButton}
          </div>
        : null}
      </Row>
    </section>
  );
};
