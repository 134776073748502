/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PageTitle from "../../Utils/smsTitle";
import Card from "@material-ui/core/Card";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import {titleCase} from "../../Users/addUser";
import {faNotesMedical} from "@fortawesome/free-solid-svg-icons/faNotesMedical";
import {useDischargeNote} from "./hooks/useDischargeNote";
import {dateStringConvert} from "../../Utils/ConvertDate";
import {splitNotes} from "../../Radiology/RadiologyReport";
import { MiniCenteredDiv, RightAlignedContainer,Button } from "../../Utils/styledComponents";
import {ItemsTable, PrintTemplate} from "../../Utils/Templates/PrintTemplate";
import { usePrint } from "../../Utils/Templates/usePrint";
import {table, tableBordered, tableMargin, smallUpperCase, detailsTable} from '../../../styles/tableStyles';
import * as colors from "../../../styles/colors"

const notesText = css`
    display: inline-block;
  margin-bottom: 0.8rem;
`

const titleText = css`
  display: inline-block;
  border-bottom: 1.99px solid ${colors.text00};
`

/**
 * Component that displays the discharge details of an admitted patient
 * @param {*} props 
 * @returns 
 */
export function DischargeNote(props) {
    const {discharge, discharging_office} = useDischargeNote(props)
    const {componentRef, handlePrint, getPageMargins}  = usePrint('Current visit Information')
    const discharge_info = discharge.discharge_info ? discharge.discharge_info : [];
    const prescription_info = discharge.discharge_prescription ?discharge.discharge_prescription: [];
    const prescription = prescription_info.length > 0 ? prescription_info.filter(item => Boolean(item)).filter(drug => drug.product_id) : [];
    const other_medicine = discharge.other_medicine ? discharge.other_medicine : [];
    const other_medicine_prescription = other_medicine.length > 0 ? other_medicine[0] : [];
    const headData = [{key:'drug',value: "Drug Name"}, {key:'quantity_prescribed',value: "Quantity Prescribed"},{key:'dosage',value: "Dosage"},
        {key:'frequency',value:"Frequency"}, {key:'no_of_days',value:"Number of Days"}, {key:'instructions',value: "Instructions"}];

    const patientDetails = (
        <>
            {discharge_info &&
                <table css={[table, tableBordered, tableMargin, detailsTable]}>
                    <thead >
                    <tr>
                        <td css={[smallUpperCase]} colSpan={5} align='left'><strong>Patient Details:</strong></td>
                    </tr>
                    </thead>
                    {discharge_info.map((d, index)=>(
                        <tr key={index}>
                            <td >
                                <span>Patient Name<br/><strong>{`${!d.p_first_name ? "" : d.p_first_name} ${!d.p_last_name ? "" : d.p_last_name}`}</strong></span>
                            </td>
                            <td >
                                <span>Date of Birth<br/><strong>{d.dob}</strong></span>
                            </td>
                            <td >
                                <span>Gender<br/><strong>{titleCase(d.p_gender)}</strong></span>
                            </td>
                            <td >
                                <span>Telephone<br/><strong>{d.p_phone_no}</strong></span>
                            </td>
                            <td >
                                <span>Address<br/><strong>{d.p_address}</strong></span>
                            </td>



                        </tr>
                    ))}
                </table>
            }
        </>
    )

    const otherDetails = (
        <>
            {discharge_info &&
                <table css={[table, tableBordered, tableMargin, detailsTable]}>
                <thead >
                        <tr>
                            <td css={[smallUpperCase]} colSpan={3} align='left'><strong>Discharge Details:</strong></td>
                        </tr>
                    </thead>
                {discharge_info.map((d, index)=>(
                    <tr key={index}>
                    <td >
                        <span>Admission Date<br/><strong>{d.admission_date ? dateStringConvert(d.admission_date) : ''}</strong></span>
                    </td>
                    <td >
                        <span>Discharge Date<br/><strong>{d.discharge_date ? dateStringConvert(d.discharge_date):''}</strong></span>
                    </td>
                    <td >
                        <span>Follow-up Date<br/><strong>{d.followup_date ? dateStringConvert(d.followup_date):''}</strong></span>
                    </td>
                    {/*<td className={theme.palette.type === 'dark' && 'dark-theme-color'}>*/}
                    {/*    <p>Receipt NO<br/><p/></p>*/}
                    {/*</td>*/}
                    

                </tr>
            ))}
                    
                </table>
            }
        </>
    )

    const guardianDetails = (
        <>
            {discharge_info &&
                <table css={[table, tableBordered, tableMargin, detailsTable]}>
                    <thead >
                    <tr>
                        <td css={[smallUpperCase]} colSpan={3} align='left'><strong>Guardian Details:</strong></td>
                    </tr>
                    </thead>
                    {discharge_info.map((d, index)=>(
                        <>
                            <tr key={index}>
                                <td colSpan={2} ><span>Guardian
                                Name :<strong>{d.attendant_name}</strong></span></td>
                                <td colSpan={3} ><span>Guardian
                                Address: <strong>{d.atendant_address}</strong></span></td>
                            </tr>
                            <tr>
                                <td colSpan={2} ><span>Guardian
                               Contact:<strong>{d.attendant_contact}</strong></span></td>
                                <td colSpan={3}/>
                            </tr>
                        </>
                    ))}
                </table>
            }
        </>
    )

    const dischargeDetails = (
        <>
            {patientDetails}
            {guardianDetails}
            {otherDetails}
        </>
    )

    const patientNotes = (
        <>
            {discharge_info &&
                <table css={[table, tableMargin]}>
                    {discharge_info.map((d, index)=>(
                        <tbody key={index}>
                            <tr>
                                <td>
                                    <span css={[titleText]}><strong>Diagnosis</strong></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span css={[notesText]}>{splitNotes(d.diagnosis)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span css={[titleText]}><strong>Clinical Presentation</strong></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span css={[notesText]}> {splitNotes(d.notes)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span css={[titleText]}><strong>Summary of Intervention</strong></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span>{splitNotes(d.investigations)}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span css={[titleText]}><strong>Recommendation on Discharge</strong></span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span css={[notesText]}>{splitNotes(d.recommendations)}</span>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
            }
        </>
    )
       
    const prescriptionDetails = (
        <>
            {prescription.length > 0 ?
                <ItemsTable {...{headData}}>
                    <tbody>
                        {prescription.map((drug, index) => (
                            <tr key={index}>
                                <td><span>{drug.product_name}</span></td>
                                <td><span>{drug.quantity_prescribed}</span></td>
                                <td><span>{drug.dosage}</span></td>
                                <td ><span>{drug.frequency}</span></td>
                                <td><span>{drug.number_of_days}</span></td>
                                <td><span>{drug.key_information}</span></td>
                            </tr>
                        ))}
                    </tbody>
                </ItemsTable>
            :null}

            {other_medicine_prescription.length > 0 ? 
                <>
                    <tr>
                        <td>
                            <span css={[titleText]}><strong>Other Prescription</strong></span>
                        </td>
                    </tr>
                    <ItemsTable {...{headData}}>
                    <tbody>
                        {other_medicine_prescription.map((drug, index) => {
                            const pscn = JSON.parse(drug);
                            return (
                                <tr key={index}>
                                    <td><span>{pscn.other_medicine}</span></td>
                                    <td><span>{pscn.quantity_prescribed}</span></td>
                                    <td><span>{pscn.dosage}</span></td>
                                    <td ><span>{pscn.frequency}</span></td>
                                    <td><span>{pscn.number_of_days}</span></td>
                                    <td><span>{pscn.key_information}</span></td>
                                </tr>
                            )}
                        )}
                    </tbody>
                    </ItemsTable>
                </>
            : null}
        </>
    )

    const footer = (
       <>
        <table css={[table, tableMargin]}>
            <tr>
                <td>
                    <span>Discharged By</span></td>
                <td align="center">
                    <span>Signature &amp; Stamp </span></td>
            </tr>
            <tr>
                <td width="350px" ><span
                    className={`doctor-stamp  mt-3 `}
                    id="doctor-stamp"><strong>{discharging_office}</strong></span></td>
                <td width="350px" align="right" ><span
                    className={`doctor-stamp  mt-3`}
                    id="doctor-stamp"/></td>
            </tr>
        </table>
        <p className="referral-paragraph referral-bold-paragraph">Please come with this discharge note on your next  visit</p>
       </>
    )
    
    return (
        <div>
            <PageTitle title="Discharge Note"/>
            <SubHeader title="Visit" subTitle="Discharge Note">
                <FontAwesomeIcon icon={faNotesMedical}/>
            </SubHeader>
            <MiniCenteredDiv>
                <RightAlignedContainer>
                <Button variant='orange' onClick={handlePrint}>Print</Button>
                </RightAlignedContainer>
                <Card className='mt-2 p-3'>
                    <div ref={componentRef} style={{padding:'3rem'}}>
                        <style>
                            {getPageMargins()}
                        </style>
                        <PrintTemplate title='Discharge Note' {...{patientNotes, dischargeDetails,prescriptionDetails, footer}}/>
                    </div>
                </Card>
            </MiniCenteredDiv>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(DischargeNote);
