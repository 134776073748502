import {useEffect, useRef, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {errorMessages} from "../../../../Helpers/ErrorMessages";
import {useRecordSupplierPayment} from "./useRecordSupplierPayment";

export const useAccountsPayablesDetails = (actions,match) =>{
    const {invoice_no} = match.params;
    const pdfRef = useRef();
    const [products, setProducts] = useState({productData:[], loading:'idle'});
    const [suppliers, setSuppliers] = useState({supplierData:[], loading:'idle'})
    const [invoice, setInvoice] = useState('')
    const [payable_id, setPayableId] = useState(invoice_no)
    const [pay_details, setPayDetails] = useState(null)
    const {loading:loadingProducts, productData} = products
    const {loading:loadingSuppliers} = suppliers
    const obj = {products:productData,pay_details, invoice_no:invoice, payable_id:payable_id}
    const {paymentProps, isResolved} = useRecordSupplierPayment(actions, obj)


    useEffect(() => {
        setSuppliers({...suppliers, loading: 'loading'})
        axios.get(`${config.smsUrl}/csupplier/supplier_payables?status=all`).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? [] : dt.data;
            const arrData = arr.map(item=>{
                if(item.id === +invoice_no){
                    return {...item, isActive:'active', checked:false, count:0}
                }
                return {...item, count:0}
            })
            setSuppliers({...suppliers,supplierData: arrData,  loading: 'success'})
            // setPayableId(inv_id)
        }).catch(err => {
            errorMessages(err,null,actions)
            setSuppliers({...suppliers, loading: 'error'})
        })
    }, [isResolved]);

    const retrieveProducts = (id) =>{
        setProducts({...products, loading: 'loading'})
        axios.post(`${config.smsUrl}/csupplier/supplier_payables/single`, {payable_id:id}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const arr = !dt.data ? {} : dt.data;
            const amounts = arr.pay_details ? arr.pay_details : {}
            const purchase = arr.purchase ? arr.purchase : []
            const details = arr.purchase_details ? arr.purchase_details : []
            const arrData = details.map(item=>({
                ...item, id:item.id
            }))
            setPayDetails({...amounts, ...purchase})
            setInvoice(arr?.purchase?.chalan_no)
            setProducts({...data,productData: arrData,  loading: 'success'})
        }).catch(err => {
            errorMessages(err,null,actions)
            setProducts({...products, loading: 'error'})
        })
    }


    useEffect(() => {
        if (!payable_id){
            return
        }
        retrieveProducts(payable_id)

    }, [payable_id,isResolved]);



    const handleCheckItem = (event, _id) =>{
        const {supplierData} = suppliers
        const arr = supplierData.map(item=>{
            if (_id === item.id){
                return {...item, checked:event.target.checked}
            }
            return item
        })

        setSuppliers({...suppliers, supplierData: arr})
    }

    const handleClickSupplier = (item) =>{
        const {id:_id, count} = item
        const {supplierData} = suppliers
        const arr = supplierData.map(item=>{
            if (_id === item.id){
                return {...item,  isActive:'active', count:item.count + 1}
            }
            return {...item,isActive:'inactive', count:0}
        })
        if (count <= 1){
            retrieveProducts(_id)
        }
        setPayableId(_id)
        setSuppliers({...suppliers, supplierData: arr})
    }



    const downloadPdf = () => {
        const input = pdfRef.current;
        html2canvas(input)
            .then((canvas) => {
                let imgData = canvas.toDataURL('image/png');
                let imgWidth = 210;
                let pageHeight = 297;
                let imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;

                let doc = new jsPDF('p', 'mm');
                let position = 0;

                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }
                doc.save("Purchase Order.pdf");
            })
        ;
    };


    const closeSnackbar = () =>{
        actions.snackbarActions.hideSnackbar()
    }

    const isLoadingProduct = loadingProducts === 'loading'
    const isSuccessProducts = loadingProducts === 'success'
    const isErrorProducts = loadingProducts === 'error'

    const isLoadingSuppliers = loadingSuppliers === 'loading'
    const isSuccessSuppliers = loadingSuppliers === 'success'
    const isErrorSuppliers = loadingSuppliers === 'error'


    return {suppliers, products,invoice, isErrorProducts, isErrorSuppliers, isLoadingSuppliers, isLoadingProduct,
    isSuccessSuppliers, isSuccessProducts, handleClickSupplier, handleCheckItem, downloadPdf, closeSnackbar,
    paymentProps, pay_details}
}