import React from "react";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PageTitle from "../../Utils/smsTitle";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions"
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import TextField from "../../Utils/FormInputs/TextField";
import {MiniHeader} from "../../Utils/Menu/MiniSubHeader"
import Label from "../../Utils/FormInputs/Label"
import {Container} from "../../Utils/styledComponents"
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { useRefundBill } from "./hooks/useRefundBill";
import { history } from "../../../Helpers/history";
import Alert from "@material-ui/lab/Alert";
import SelectInput from "../../Utils/FormInputs/SelectInput";
import InputGroup from "react-bootstrap/InputGroup";


const Table = ({children, title, headData=[]}) =>(
    <table className="table table-bordered table-sm billing-invoice-table  strike-table">
        <thead>
        <tr>
            <td align="center" colSpan={headData.length}>{title}</td>
        </tr>
        <tr>
            {headData.map((item, index) => (<td key={index}>{item}</td>))}
        </tr>
        </thead>
        <tbody>
            {children}
        </tbody>

    </table>
)


function RefundBill({actions, match, snackbars}) {
    const {state,isSubmitted, date, openPrint, submitted,products,totalAmounts,
        handleChangeDate, handleSubmitBill, handleCloseReceipt, closeSnackbar, services,
        handleOpenReceipt,handleRefundProduct, handleRefundService,
        currencies, currency, exchangeRate, handleChangeCurrency,
        handleChangeExchangeRate
    } = useRefundBill(actions,match)
    const {paid_amount, total_amount, total_refund,currency_total_refund,currency_total_amount,currency_paid_amount} = totalAmounts
    const {patient_number, patient_name} = state
    const {openBar, type, message} = snackbars;

    const isBase = currency.split('-')[1]
    const currency_symbol = currency.split('-')[2]
    const isNotBase = isBase === 'false'

    const isRefundService = services.length > 0 && services.some(item=>item.isRefund)
    const isRefundProduct = products.length > 0 && products.some(item=>item.isRefund)


    const servicesHeadData = ["Item", "Quantity", "Rate", "Amount", "Refund"]

    const productsHeadData = ["Medicine Name", "Dosage", "Frequency", "No. of Days",
        "Stock","Prescribed Quantity", "Rate", "Amount","Refund"]

    return (
        <div data-testid="add-bill-component" className='journals'>
            <PageTitle title='Refund Bill'/>
            <SubHeader title="Billing" subTitle='Refund Bill'>
                <FontAwesomeIcon icon={faCoins}/>
            </SubHeader>
           
                <PrintDialog handleCancel={()=>{history.push('/paymentList')}} openDialog={openPrint} handleClose={handleCloseReceipt}
                             handleClick={handleSubmitBill} message="Confirm Payment"
                             text="Would you like to confirm this payment"/>
                <MainSnackbar variant={type} handleCloseBar={closeSnackbar} open={openBar} message={message}/>
                <Container>
                     <BackButton to='/paymentList' text='Billing List' data-testid="refund-bill-back-button"/>
                    <MiniHeader  title='Refund Bill'/>
                    <form onSubmit={handleOpenReceipt} autoComplete="off" style={{marginTop:'1.5rem'}}>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Patient No."/>
                                    </Col>
                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={patient_number} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Patient Name"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <TextField type="text" value={patient_name} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Currency"  type/>
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                        <SelectInput submitted={submitted} value={currency}
                                                     onChange={handleChangeCurrency} options={currencies}
                                                     defaultOption="Select..." disabled/>

                                    </Col>
                                </Form.Group>
                                {isNotBase ?
                                    <Form.Group as={Row} >
                                        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                            <Label  name="Exchange Rate"  type/>
                                        </Col>

                                        <Col xs={12} sm={12} md={10} lg={10} xl={7}>
                                            {/*<InputGroup>*/}
                                            {/*    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                                                <TextField submitted={submitted} type="number" value={exchangeRate} onChange={handleChangeExchangeRate} readOnly/>
                                            {/*</InputGroup>*/}
                                            {/*<span style={{marginTop:'5px', display:'inline-block', fontWeight:700, color:'blue'}}>(1 {currency.split('-')[2]} = {exchangeRate} USD)</span>*/}
                                        </Col>
                                    </Form.Group> : null}
                            </div>
                            <div className='col-lg-6'>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name="Date" />
                                    </Col>

                                    <Col xs={12} sm={12} md={10} lg={10} xl={isNotBase ?  "9" : "7"}>
                                        <TextField type="date" value={date} onChange={handleChangeDate} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name= 'Total Bill' />
                                    </Col>
                                    <Col  xs={12} sm={12} md={10} lg={10} xl={"7"}>
                                        {/*{isNotBase ?  <Row>*/}
                                        {/*   <Col sm='6'>*/}
                                        {/*       <InputGroup>*/}
                                        {/*           <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                                        {/*           <TextField type="text" value={total_amount} readOnly/>*/}
                                        {/*       </InputGroup>*/}
                                        {/*   </Col>*/}
                                        {/*    <Col sm='6'>*/}
                                        {/*        <InputGroup>*/}
                                        {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>*/}
                                        {/*            <TextField type="text" value={currency_total_amount} readOnly/>*/}
                                        {/*        </InputGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row> : <InputGroup>*/}
                                        {/*    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                                        {/*    <TextField type="text" value={total_amount} readOnly/>*/}
                                        {/*</InputGroup>}*/}

                                        <TextField type="text" value={total_amount} readOnly/>
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name= 'Amount Paid' />
                                    </Col>

                                    <Col  xs={12} sm={12} md={10} lg={10} xl={"7"}>
                                        {/*{isNotBase ? <Row>*/}
                                        {/*    <Col sm='6'>*/}
                                        {/*        <InputGroup>*/}
                                        {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                                        {/*            <TextField type="text" value={paid_amount} readOnly/>*/}
                                        {/*        </InputGroup>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col sm='6'>*/}
                                        {/*        <InputGroup>*/}
                                        {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>*/}
                                        {/*            <TextField type="text" value={currency_paid_amount} readOnly/>*/}
                                        {/*        </InputGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>:   <InputGroup>*/}
                                        {/*    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                                        {/*    <TextField type="text" value={paid_amount} readOnly/>*/}
                                        {/*</InputGroup>}*/}

                                        <TextField type="text" value={paid_amount} readOnly/>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                                        <Label name= 'Total Refund'/>
                                    </Col>
                                    <Col  xs={12} sm={12} md={10} lg={10} xl={"7"}>
                                        {/*{isNotBase ? <Row>*/}
                                        {/*    <Col sm='6'>*/}
                                        {/*        <InputGroup>*/}
                                        {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                                        {/*            <TextField type="text" value={total_refund} readOnly/>*/}
                                        {/*        </InputGroup>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col sm='6'>*/}
                                        {/*        <InputGroup>*/}
                                        {/*            <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">{currency_symbol}</InputGroup.Text>*/}
                                        {/*            <TextField type="text" value={currency_total_refund} readOnly/>*/}
                                        {/*        </InputGroup>*/}
                                        {/*    </Col>*/}
                                        {/*</Row>:  <InputGroup>*/}
                                        {/*    <InputGroup.Text style={{borderRadius:'0.7rem 0 0 0.7rem'}} id="basic-addon1">USD</InputGroup.Text>*/}
                                        {/*    <TextField type="text" value={total_refund} readOnly/>*/}
                                        {/*</InputGroup>}*/}
                                        <TextField type="text" value={total_refund} readOnly/>
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="other-billing-details mb-3">
                            <div className="service-details">
                                {submitted && !(isRefundService || isRefundProduct) ?
                                    <div className='mb-3'>
                                        <Alert severity='error'>Please select at least one item to refund before saving</Alert>
                                    </div>
                                    : null}
                                {services.length > 0 ?
                                    <Table headData={servicesHeadData.filter(item=>Boolean(item))} title='Services'>
                                        {services.map((item,idx) => (
                                            <tr key={item.service_id}>
                                                <td>{item.service_name}</td>
                                                <td>{item.quantity}</td>
                                                {/*{ isNotBase ?  <td>{item.currency_rate}</td>: null}*/}
                                                <td>{item.rate}</td>
                                                {/*{ isNotBase ?  <td>*/}
                                                {/*    {item.currency_total}*/}
                                                {/*</td>: null}*/}
                                                <td >{item.subTotal}</td>
                                                <td id='refund'>
                                                    <Form.Check type='checkbox' checked={item.isRefund} onChange={handleRefundService(item.service_id)}/>
                                                </td>
                                            </tr>
                                        ))}
                                    </Table> : null}
                                {products.length > 0 ? <Table headData={productsHeadData.filter(item=>Boolean(item))} title='Products'>
                                {products.map((item, idx) => (<tr key={item.product_id}>
                                    <td>
                                        {item.product_name}
                                    </td>
                                    <td align="center">
                                        {item.dosage}
                                    </td>

                                    <td align="center">
                                        {item.frequency}
                                    </td>
                                    <td align="center">
                                        {item.number_of_days}
                                    </td>
                                    <td align="center">
                                        {item?.stock?.depart_stock_val}
                                    </td>
                                    <td>
                                        {item.quantity}
                                    </td>
                                    {/*{ isNotBase ?  <td>{item.currency_rate}</td>: null}*/}

                                    <td align="center">
                                        {item.rate}
                                    </td>

                                    {/*{ isNotBase ?  <td>*/}
                                    {/*    {item.currency_total}*/}
                                    {/*</td>: null}*/}

                                    <td align="center">
                                        {item.subTotal}
                                    </td>
                                    <td  id='refund'>
                                        <Form.Check type='checkbox' checked={item.isRefund} onChange={handleRefundProduct(item.product_id)}/>
                                    </td>
                                </tr>))}
                                </Table> : null}
                            </div>
                        </div>
                        <button id='save-refund' type="submit" className="btn btn-sm sms-info-btn"
                            disabled={isSubmitted === 'pending'}>
                                {isSubmitted === 'pending' ? "Saving..." : "Save"}</button>    
                    </form>

            </Container>
        </div>
    )
}

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions: {snackbarActions: bindActionCreators(snackbarActions, dispatch)}}
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundBill);
