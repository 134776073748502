import React from "react";
import {useState, useEffect} from "react";
import clsx from "clsx";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {Link, NavLink, Switch, withRouter} from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AppHeader from "./AppHeader";
import {routes} from "../routes";
import {PrivateRoute} from "../PrivateRoute";
import hospitalAddress from "../HospitalAddress"
import ErrorBoundary from "./ErrorBoundary";
import {ErrorFallBack} from "./ErrorFallBack";
import { Badge } from '@material-ui/core';
import axios from "axios";
import { config } from "../Helpers/env";
import { sidebarModules } from "../components/Utils/ReusableComponents/sidebarModules";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
export const drawerWidth = 230;

const useStyles = makeStyles(theme => ({
    root: {
        // display: "flex",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        borderBottom: theme.palette.type === "dark" ? "1px solid rgba(255, 255, 255, 0.12)" : "1px solid #DCDCDC",
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })

    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: "none"

    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
    },
    drawerOpen: {
        width: drawerWidth,
        color: 'white',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        color: 'white',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing(5) + 10,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(7) + 1
        }
    },
    nested: {
        paddingLeft: theme.spacing(7),
        color: theme.palette.type === 'dark' ? '#ffffff' : '#b9b9b9',
        paddingTop: '4px',
        paddingBottom: '4px'
    },
    nestedList: {
        paddingLeft: theme.spacing(12),
        color: theme.palette.type === 'dark' ? '#ffffff' : '#b9b9b9'
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        background: '#3e454c',
        justifyContent: "flex-end",
        padding: "0 5px",
        ...theme.mixins.toolbar
    },
    darkToolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 5px",
        ...theme.mixins.toolbar
    },
    contentBar: {
        ...theme.mixins.toolbar
    },
    navlinks: {
        display: "flex",
        marginLeft: "auto",
    },
    navtoolbar: {
        display: "flex",
        alignItems: "center"
    },
    content: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down("sm")]: {
            marginLeft: theme.spacing(7) + 1,
            width: `calc(100% - 50px)`
        },
    },
    contentShift : {
        marginLeft: '50px',
        width: `calc(100% - 50px)`
    },
    listItem: {
        padding: 0
    },
    list: {
        color: theme.palette.type === 'dark' ? '#ffffff' : '#b9b9b9',
        textDecoration: "none",
        '&:hover': {
            textDecoration: "none",
            color: "#ffffff",
            transition: ".5s ease",
            fontWeight: "500",
            borderLeft: "3px solid #d3d3d3"
        }
    },
    listMenu: {
        color: theme.palette.type === 'dark' ? '#ffffff' : '#373737',
        textDecoration: "none",
        '&:hover': {
            textDecoration: "none",
            transition: ".5s ease",
            fontWeight: "500",
            borderLeft: "3px solid #373737",
            color: '#373737'
        },  
    },
    listMenuNotification: {
        color: theme.palette.type === 'dark' ? '#ffffff' : '#373737',
        textDecoration: "none",
        width:'180px',
        '&:hover': {
            textDecoration: "none",
            transition: ".5s ease",
            fontWeight: "500",
            borderLeft: "3px solid #373737",
            color: '#373737'
        },  
    },
    icon: {
        fontSize: "15px",
        color: theme.palette.type === 'dark' ? '#ffffff' : "#c6c6c6",
        minWidth: "40px",
        '&:hover': {
            color: "#ffffff"
        }
    },
    popOverRoot: {
        pointerEvents: "none"
    },
    toolbarIcon: {
        color: "#b1b1b1"
    },
    listDiv: {
        padding: "10px 0"
    },
    menuColor: {
        backgroundColor: "#ffffff",
        '&.Mui-disabled': {
            opacity: 1,
            fontWeight: "bold",
            color:"#2c3136",
        },
    },
    text: {
        fontSize: "0.875rem",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    textMenuHead: {
        fontSize: "0.8rem",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    textMenu: {
        fontSize: "0.78rem",
        fontWeight: 400,
        lineHeight: 1.5,
        letterSpacing: "0.00938em",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    toggleIcon: {
        fontSize: "15px"
    },
    scrollBar: {
        background: theme.palette.type !== 'dark' && '#2c3136',
        '&::-webkit-scrollbar': {
            width: "5px",
            height: "5px"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.type === 'dark' ? '#4a4a4a' : '#495057',
            "-webkit-border-radius": "10px",
            borderRadius: "10px",
        }
    }
}));

function SideBar({type, onClick, palette}) {
    const classes = useStyles();
    const theme = useTheme();
    const miniDrawer = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(true);
    const [items, setItems] = useState(()=>sidebarModules())
    const [triage_waiting_list, setTriageWaitingList] = useState(0);
    const [isVisitModule, setIsVisitModule] = useState(false);

    const handleOpen = (index) =>{
        if (open) {
            const itemsArr = items.map((item,idx)=>{
            if(index === idx){
                if (item.module_name === 'Visits') { 
                    setIsVisitModule(true); 
                    const formData = new FormData();
                    formData.append('search', '');
                    axios.post(`${config.smsUrl}/cvisit/triage_queue/${0}`, formData).then(res=>{
                        const data = res.data;
                        const dt = !data ? {} : data;
                        const count = !dt.total_count ? 0 : dt.total_count;
                        setTriageWaitingList(count);
                    }).catch(err=>{
                        console.error("ERR: ", err);
                    })
                } else if (isVisitModule === true) { setIsVisitModule(false); }
                return {...item, isOpen:!item.isOpen}
            }else{
                return {...item, isOpen:false}
            }
            })
            setItems(itemsArr)
        }
    }

    const handleCloseCollapse = (module_name) => {
        if (module_name !== 'Visits' && isVisitModule) { setIsVisitModule(false); }
        const itemsArr = items.map((item)=>{
            return {...item, isOpen:false}
        })
        setItems(itemsArr)
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const [isContextMenu, setContextMenu] = useState({});

    useEffect(()=>{
        if(miniDrawer){
            setOpen(false)
        }else{
            setOpen(true)
        }
    },[miniDrawer])

    let currentlyHovering = false;
  
    function handleClick(event, item) {
        event.preventDefault();
        if (!open) {
            if (anchorEl !== event.currentTarget) {
                setAnchorEl(event.currentTarget);
                setContextMenu({...isContextMenu, [item.module_name]:true});
            }
        }
    }
  
    function handleHover() {
      currentlyHovering = true;
    }
  
    function handleClose(item) {
      setAnchorEl(null);
      const new_context = {};      
      
      Object.keys(isContextMenu).forEach(key => {
        new_context[key] = false;
      });
      
      setContextMenu(new_context);
    }
  
    function handleCloseHover(item) {
      currentlyHovering = false;
      setTimeout(() => {
        if (!currentlyHovering) {
          handleClose(item);
        }
      }, 50);
    }

    function handleDrawerOpen() {
        setOpen(true);
    }

    function handleDrawerClose() {
        // change all items isOpen to false
        const items_arr = items.map(it => {
            return {...it, isOpen:false}
        })
        setItems(items_arr);
        // then collapse the side bar
        setOpen(false);
    }

    const {width} = hospitalAddress;

    useEffect(() => {

        if (isVisitModule === true) {
            const interval = setInterval(()=>{
                const formData = new FormData();
                formData.append('search', '');
                axios.post(`${config.smsUrl}/cvisit/triage_queue/${0}`, formData).then(res=>{
                    const data = res.data;
                    const dt = !data ? {} : data;
                    const count = !dt.total_count ? 0 : dt.total_count;
                    setTriageWaitingList(count);
                }).catch(err=>{
                    console.error("ERR: ", err);
                })
            }, 30000);
            return ()=>clearInterval(interval);
        }

    }, [isVisitModule])

    return (
        <div className={classes.root}>
            <CssBaseline/>
            
            <AppHeader menuBtn={clsx(classes.menuButton, {[classes.hide]: open,})}
                       onClick={handleDrawerOpen} id="open-main-sidebar"
                       position="fixed" appBar={clsx(classes.appBar, {
                [classes.appBarShift]: open ,
            })} type={type} toggleSwitch={onClick} palette={palette}/>
            
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx(classes.scrollBar, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}
                open={open}
            >
            
                <div className={type === "light" ? classes.toolbar : classes.darkToolbar}>
                    <Link className='smsLogo' to='/' style={{textDecoration: 'none'}}>
                        <span className='responsive-logo'><img style={{width: width, margin: '-25% 0'}}
                                                                src='/images/smartmedlogo.svg'
                                                                alt="smartmedlogo"/></span>
                    </Link>
                    <IconButton onClick={handleDrawerClose} className={classes.toolbarIcon} id="collapse-main-sidebar">
                        {theme.direction === "rtl" ? <ChevronRightIcon classes={{root: classes.toggleIcon}}/> :
                            <ChevronLeftIcon classes={{root: classes.toggleIcon}}/>}
                    </IconButton>
                </div>
                
                <Divider/>

                <div className={classes.listDiv}/>

                {items.map((item, index)=>(
                          
                    <List className={classes.listItem} key={index}>

                        {item.modules.filter(it=>it.isVisible).length > 0 ?
                            <>
                                {isContextMenu[item.module_name] ?
                                    <Menu
                                        anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                                        transformOrigin={{vertical: 'center', horizontal: 'left'}}
                                        id="sidepop-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={(event)=>handleClose(item)}
                                        MenuListProps={{
                                        onMouseEnter: handleHover,
                                        onMouseLeave: (event)=> handleCloseHover(item),
                                        style: { pointerEvents: "auto" }
                                        }}
                                        getContentAnchorEl={null}
                                        PopoverClasses={{
                                        root: classes.popOverRoot
                                        }}
                                    >
                                        <MenuItem disabled={true} className={classes.menuColor}>
                                            <ListItemText style={{borderBottom: "2px solid #828282"}}>
                                                <Typography variant="body2" style={{ color: '#000000', lineHeight: 1.5, letterSpacing: "0.00938em",
                                                overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight:'bold', 
                                                fontSize: "15px"}}>{item.module_name}</Typography> 
                                            </ListItemText>
                                        </MenuItem>
                                        {item.modules.map((mods, id) => (
                                            <div>
                                                <NavLink key={id} id='dashboard-sidebar' to={mods.route} style={{textDecoration:'none'}}>
                                                    <MenuItem  onClick={(event)=>handleClose(item)} 
                                                    classes={{root: item.module_name === 'Visits' ? classes.listMenuNotification : classes.listMenu}}>
                                                        {mods.text === 'Triage Waiting List' ?
                                                            <ListItemText classes={{root: classes.textMenu}} disableTypography={true}>
                                                                <div style={{display:'flex'}}>
                                                                    {mods.text}
                                                                    <p style={{margin:'0px', marginLeft: '14px'}}><Badge badgeContent={triage_waiting_list} color="secondary"/></p>
                                                                </div>
                                                            </ListItemText>
                                                            :
                                                            <ListItemText classes={{root: classes.textMenu}} disableTypography={true}>{mods.text}</ListItemText>
                                                        }
                                                    </MenuItem>
                                                </NavLink>
                                            </div>
                                        ))}
                                    </Menu> 
                                : null}
                                <ListItem id='patient-sidebar' button onClick={()=>handleOpen(index)} on className={classes.list}>
                                    <ListItemIcon className={classes.icon}
                                        aria-owns={anchorEl ? "sidepop-menu" : undefined}
                                        aria-haspopup="true"
                                        onClick={(event)=>handleClick(event, item)}
                                        onMouseOver={(event)=>handleClick(event, item)}
                                        onMouseLeave={(event)=>handleCloseHover(item)}
                                    >
                                        {" "}
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText classes={{root: classes.text}} disableTypography={true}>{item.module_name}</ListItemText>
                                    {item.isOpen ? <ExpandMore classes={{root: classes.toggleIcon}}/> :
                                        <ChevronLeftIcon classes={{root: classes.toggleIcon}}/>}
                                </ListItem>
                            </>
                        : item.isVisible ?
                            <> 

                                {isContextMenu[item.module_name] ?
                                    <Menu
                                        anchorOrigin={{vertical: 'center', horizontal: 'right'}}
                                        transformOrigin={{vertical: 'center', horizontal: 'left'}}
                                        id="sidepop-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={(event)=>handleClose(item)}
                                        MenuListProps={{
                                        onMouseEnter: handleHover,
                                        onMouseLeave: (event)=> handleCloseHover(item),
                                        style: { pointerEvents: "auto" }
                                        }}
                                        getContentAnchorEl={null}
                                        PopoverClasses={{
                                        root: classes.popOverRoot
                                        }}
                                    >
                                        
                                        <NavLink id='dashboard-sidebar' to={item.link} style={{textDecoration:'none'}}>
                                            <MenuItem onClick={(event)=>handleClose(item)} classes={{root: classes.listMenu}}>
                                                <ListItemText classes={{root: classes.textMenuHead}} disableTypography={true}>{item.module_name}</ListItemText>
                                            </MenuItem>
                                        </NavLink>
                                    </Menu> 
                                    : null
                                }

                                <NavLink id='dashboard-sidebar' to={item.link} className="side-links" 
                                    activeClassName="side-active" onClick={()=>handleCloseCollapse(item.module_name)}>
                                    <ListItem button className={classes.list}>
                                        <ListItemIcon className={classes.icon}
                                            aria-owns={anchorEl ? "sidepop-menu" : undefined}
                                            aria-haspopup="true"
                                            onClick={(event)=>handleClick(event, item)}
                                            onMouseOver={(event)=>handleClick(event, item)}
                                            onMouseLeave={(event)=>handleCloseHover(item)}
                                        >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText classes={{root: classes.text}} disableTypography={true}>{item.module_name}</ListItemText>
                                    </ListItem>
                                </NavLink>
                            </>
                        :null}

                        <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                            {item.modules.map((nav, idx)=>(
                                <List component="div" disablePadding key={idx}>
                                    {nav.isVisible ? <NavLink to={nav.route} className="side-links" activeClassName="side-active">
                                        <ListItem button className={classes.nested}>
                                            {nav.text === 'Triage Waiting List' ?
                                                <ListItemText classes={{root: classes.text}} disableTypography={true}>
                                                    <div style={{display:'flex'}}>
                                                        {nav.text}
                                                        <p style={{margin:'0px', marginLeft: '12px'}}><Badge badgeContent={triage_waiting_list} color="secondary"/></p>
                                                    </div>
                                                </ListItemText>
                                                :
                                                <ListItemText classes={{root: classes.text}} disableTypography={true}>{nav.text}</ListItemText>
                                            }
                                        </ListItem>
                                    </NavLink>:null}
                                </List>
                            ))}
                        </Collapse>
                    </List>
                ))}
            </Drawer>

            <ErrorBoundary FallbackComponent={ErrorFallBack}>
                <main className={open ? classes.content : classes.contentShift}>
                    <div className={classes.contentBar}/>
                    <Switch>
                        {routes.map((route, index) => (
                            <PrivateRoute key={index} path={route.path} exact={route.exact}
                                          component={route.component}/>
                        ))}
                    </Switch>
                </main>
            </ErrorBoundary>
        </div>
    );
}


export default withRouter(SideBar);
