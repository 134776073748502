import React from "react"
import Form from "react-bootstrap/Form";
import {useTheme} from "@material-ui/core/styles";


const TextField = ({type,value,name,onChange, id, disabled, submitted=false, ...rest}) => {
  const theme = useTheme();
  const them = !theme ? {} : theme;
  const palette = !them.palette ? {} : them.palette;
  const isSubmitted = submitted && !value;
  return (
    <Form.Control style={{border: isSubmitted ? '1px solid red':''}} id={id} data-testid={id}  type={type} value={value} name={name} onChange={(e)=>onChange(e)}
                  className={`form__control ${palette.type === "dark" && 'form-control-dark'}`} disabled={disabled} {...rest}/>
  )
};
export default TextField;
