import {useEffect, useState} from "react";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {formatDateTime} from "../../../Utils/ConvertDate";
import {useAddFindings} from "./useAddFindings";


export const usePreviousFindings = (actions, visit_id, patient_type,patient_number) =>{
    const user = JSON.parse(sessionStorage.getItem('user'));
    const userObj = !user ? {} : user;
    const token = !userObj.token ? [] : userObj.token;
    const _token = !token[1] ? {} : token[1];
    const user_roles_id = !_token.user_roles_id ? "" : _token.user_roles_id;
    const [previousFindings, setPreviousFindings] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [submittedNotes, setSubmitted] = useState(false)
    const [loading, setLoading] = useState('idle')
    const {addNotesProps, isResolved:isAddResolved} = useAddFindings(actions,visit_id, patient_type,patient_number)


    const isResolved = isSubmitted === 'resolved';



    const groupById = (arr=[]) =>{
        let result = []
        let knowledge = {}
        arr.forEach(item =>{
            let id = item.id;
            if(id in knowledge){
                result[knowledge[id]].notes.push(item)
            }else {
                knowledge[id] = result.length
                result.push({
                    ...item,
                    id: id,
                    visit_id:item.visit_id,
                    date: item.start_time ? formatDateTime(item.start_time):'',
                    doctor_name:`${item.doctor_firstname ? item.doctor_firstname:''} ${item.doctor_lastname ? item.doctor_lastname : ''}`,
                    notes: item.notes,
                    done_by:item.done_by,
                    isEdit:item.done_by === user_roles_id,
                    displayInput:false
                })

            }
        })
        return result
    }


    const sortArr = (arr=[])=>{
        return arr.sort((a, b)=>new Date(b.start_time) - new Date(a.start_time))
    }



    useEffect(()=>{
        const formData = new FormData();
        formData.append('visit_id', visit_id);
        setLoading('loading')
        const url = patient_type === '1' ? 'cdoctor/retrieve_exam_notes' : 'cmedicationround/retrieve_previous_rounds'
        axios.post(`${config.smsUrl}/${url}`,formData).then(res=>{
            const data = res.data;
            let notes = []
            if(patient_type === '1'){
                const dt = !data ? [] : data;
                const sortedNotes = sortArr(dt)
                notes = sortedNotes.map(item=>({...item, notes:item.notes, id:item.examination_notes_id}))
            }
            if(patient_type === '2'){
                const dt = !data ? {} : data;
                const notesArr = dt.round_info ? dt.round_info : []
                const sortedArr = sortArr(notesArr)
                notes = sortedArr.map(item=>({...item, notes:item.findings, id:item.round_id}))

            }
            const arr = groupById(notes)
            const dataArr = arr.map((item, index)=>({...item, open:item.done_by === user_roles_id}))
            setPreviousFindings(dataArr)
            setLoading('success')
        }).catch(err=>{
            logoutErrorMessage(err, null, actions)
            setLoading('error')
        })
    },[isResolved,isAddResolved]);


    const handleOpen = (_id) =>{
        const arr = previousFindings.map(item=>{
            if(item.id === _id){
                return {...item, open:!item.open}
            }
            return item
        })
        setPreviousFindings(arr)
    }

    const handleEdit = (_id) =>{
        const arr = previousFindings.map(item=>{
            if(item.id === _id){
                return {...item, isEdit:false, displayInput:true}
            }
            return item
        })
        setPreviousFindings(arr)
    }


    const handleChangeNotes = (event, _id) =>{
        const {name, value} = event.target
        const arr = previousFindings.map(item=>{
            if(item.id === _id){
                return {...item, [name]:value}
            }
            return item
        })
        setPreviousFindings(arr)
    }

    const handleCancelEdit = (_id) =>{
        const arr = previousFindings.map(item=>{
            if(item.id === _id){
                return {...item, isEdit:true, displayInput:false}
            }
            return item
        })
        setPreviousFindings(arr)
    }



    const handleUpdateNotes = (event, item) =>{
        event.preventDefault();
        const {notes, id, done_by,physical_examination, past_medical_history, past_obstetric_history,
            family_history,complaints} = item;
        const id_key = patient_type === '1' ? 'examination_notes_id' : 'round_id'
        setSubmitted(true)
        const jsonData = {[id_key]:id, notes, user_roles_id:done_by, visit_id,
            physical_examination, past_medical_history, past_obstetric_history,
            family_history,complaints, chronic_illness:{},allergies:''}
        if(notes && physical_examination && complaints){
            const url = patient_type === '1' ? 'cdoctor/update_exam_notes' : 'cmedicationround/update_patient_round'
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/${url}`,jsonData).then(()=>{
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Findings edited successfully');
                const arr = previousFindings.map(row=>{
                    if(row.id === id) {
                        return {...item, isEdit:true, open:true}
                    }
                    return item
                })
                setPreviousFindings(arr)
                setSubmitted(false)
            }).catch(err=>{
                setIsSubmitted('rejected');
                errorMessages(err,null, actions)
            })
        }

    };

    const isLoading = loading === 'loading'
    const isSuccess = loading === 'success'
    const isError = loading === 'error'

    const isPendingEdit = isSubmitted === 'pending'


    return {previousFindings,  handleUpdateNotes, isLoading, isError,
        isPendingEdit, handleEdit, handleCancelEdit, handleChangeNotes,
        isSuccess, handleOpen, addNotesProps, submittedNotes, isAddResolved}
}