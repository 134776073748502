import  {useEffect, useState} from 'react';
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {errorMessages, logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {titleCase} from "../../../Users/addUser";
import {formatDate, formatDateTime} from "../../../Utils/ConvertDate";


export const useEditDoctorProcedure = (actions, match) => {
    const {visit_id, patient_type,previousProcedure} = match
    const initialState = [{
        procedure_id: '', service_name: '', invoice_no: '',bill_type:'',
        showProcedure: false, service_id: '', disabled: false, isError: false, bill_id: '',
        procedure_payment_id: '', isEdited: false, quantity: 1, rate: 0,status:0
    }]
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [all_procedures, setAllProcedures] = useState([]);
    const [procedures, setProcedures] = useState(initialState);
    const [submitted, setSubmitted] = useState(false)
    const [date, setDate] = useState('');
    const [removedInvoices, setRemovedInvoices] = useState([]);
    const [openEdit, setOpenEdit] = useState(false)




    useEffect(() => {
        if(!date){
            return
        }
        const dt = previousProcedure.filter(item=>item.pay_status === 0).map(prod => ({
            procedure_id: prod.procedure_id,
            service_name: prod.procedure_name,
            showProcedure: false,
            service_id: prod.service_id,
            invoice_no: prod.invoice_no,
            procedure_payment_id: prod.procedure_payment_id,
            rate:+prod.rate,
            quantity:prod.quantity,
            status:prod.status,
            bill_type:prod.bill_type,
            ...prod
        })).filter(procedure => procedure.service_id)
        const inPatient = dt.filter(item=>formatDateTime(item.date_created) === date)
        const outPatient = dt.filter(item=>formatDate(item.date_created) === date)
    
        const arr = patient_type === '1' ? outPatient : inPatient;
        setProcedures(arr)

    }, [previousProcedure, date]);

    const handleOpenEdit  = (_date) =>{
        setDate(_date)
        setOpenEdit(true)
    }

    const handleCloseEdit  = () =>{
        setOpenEdit(false)
    }


    const retrieveAllProcedures = (event, idx) => {
        const arr = procedures.map((prod, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('procedure_name', titleCase(event.target.value));
                prod.service_name = event.target.value;
                if (event.target.value !== '') {
                    axios.post(`${config.smsUrl}/cdoctor/autocomplete_search_procedure`, formData).then(res => {
                        const data = res.data;
                        const dt = !data ? [] : data;
                        if (dt[0] === "No Procedure Found") {
                            setAllProcedures([{value: '', label: 'No procedure found'}])
                        } else {
                            setAllProcedures(dt);
                        }
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                }
                prod.showProcedure = true;
                prod.isError = false;
                return prod
            }
            return prod
        })
        setProcedures(arr)
        setIsSubmitted('idle')
    };

    const getProcedureDetails = (event, idx) => {
        const arr = procedures.map((prod, index) => {
            if (index === idx) {
                const formData = new FormData();
                formData.append('procedure_id', event.value);
                prod.service_name = event.innerText;
                prod.procedure_id = event.value;
                if (event.value) {
                    axios.post(`${config.smsUrl}/cdoctor/get_service_id_by_procedure_id`, formData).then(res => {
                        const resp = res.data;
                        const data = !resp ? {} : resp;
                        const {service_id, cost} = data;
                        prod.service_id = service_id;
                        prod.rate = cost;
                    }).catch(error => {
                        logoutErrorMessage(error, null, actions)
                    });
                    prod.isError = false;
                    prod.showProcedure = false;
                    prod.isEdited = !!prod.bill_detail_id;
                    return prod
                } else {
                    prod.isError = true;
                    prod.showProcedure = false;
                    return prod
                }
            }
            return prod
        })
        setProcedures(arr)
    };



    const handleAddRow = () => {
        setProcedures([...procedures, ...initialState])
        setIsSubmitted('idle')
    };


    const removeRow = (index) => {
        const arr = procedures.filter((_, idx) => idx !== index)
        const removed = procedures.filter((_, idx) => idx === index).filter(item=>item.status === 0)
        setProcedures(arr);
        setRemovedInvoices(removed)
        setIsSubmitted('idle')
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        const arr = procedures.every(prod => prod.service_id && prod.procedure_id)
        const bill_type = procedures.map(({bill_type})=>bill_type)
        setSubmitted(true)
        const params = {
            visit_id, result_type: 1,
            invoices_added: procedures.filter(prod => (prod.procedure_id && prod.service_id
                && prod.isEdited) || !prod.procedure_payment_id).map(item => ({
                procedure_id: item.procedure_id,
                procedure_payment_id: item.procedure_payment_id,
                invoice_no: item.invoice_no ?? '',
                bill_type:bill_type[0] ? bill_type[0] : '',
              
                bill_details:{
                    service_id: item.service_id,
                    quantity: item.quantity,
                    rate: item.rate,
                    bill_id:item.bill_id,
                }
            })), invoices_removed: removedInvoices.filter(prod => prod.procedure_payment_id).map(item => ({
                procedure_id: item.procedure_id,
                procedure_payment_id: item.procedure_payment_id,
                bill_details:{
                    quantity: item.quantity,
                    rate: item.rate,
                    invoice_no: item.invoice_no ?? '',
                    service_id: item.service_id,
                    bill_id:item.bill_id,
                },
                quantity: item.quantity,
                rate: item.rate,
                invoice_no: item.invoice_no ?? ''
            }))
        }
        if (arr) {
            setIsSubmitted('pending');
            const url = patient_type === '1' ? 'update_procedure' : 'update_inpatient_procedure'
            axios.post(`${config.smsUrl}/cdoctor/update_procedure`, {...params}).then(() => {
                actions.snackbarActions.snackSuccess(`Procedure updated successfully`);
                setIsSubmitted('resolved');
                setSubmitted(false)
                setOpenEdit(false)
            }).catch(err => {
                errorMessages(err, null, actions)
                setIsSubmitted('rejected');
            })
        }
    };

    const isPending = isSubmitted === 'pending'
    const isResolved = isSubmitted === 'resolved'
    const isRejected = isSubmitted === 'rejected'

   

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const editProps = {all_procedures, procedures, previousProcedure, submitted,
        retrieveAllProcedures, getProcedureDetails, handleAddRow, removeRow, handleSubmit,
        closeSnackbar, openEdit, handleOpenEdit, handleCloseEdit, isResolved, isPending, isRejected
    }

    return {editProps}
}