import React, {useEffect, useState} from "react";
import * as snackbarActions from "../../../actions/snackbarActions"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import  {SortSelect} from "../../Accounting/Reports/SortSelect";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {logoutErrorMessage} from "../../../Helpers/ErrorMessages";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {MainSearchComponent} from "../MainSearch";
import {DataExportButton} from "../../Utils/Buttons/DataExportationButton";
import {convertMonth} from "../../Utils/ConvertDate";
import { exportPDF } from "../../Utils/downloadPDF";
import {useProductSalesReport} from "./CustomHooks/useProductSalesReport";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import CustomTable from "../../Utils/Tables/CustomTable";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {ReusableRouteTabs} from "../../Utils/Dialogs/ReusableTabs";
import {history} from "../../../Helpers/history";
import { formatAmount } from "../../Utils/formatNumbers";
import { BackButton } from "../../Utils/Buttons/DataExportationButton";
import { Link } from "react-router-dom";

const headData = [
    {id: 'sl', numeric: false, disablePadding: false, label: 'SL'},
    {id: 'product', numeric: false, disablePadding: false, label: 'Product'},
    {id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity Sold'},
    {id: 'price', numeric: false, disablePadding: false, label: 'Unit Price'},
    {id: 'amount', numeric: false, disablePadding: false, label: 'Amount'},
    {id: 'stock', numeric: false, disablePadding: false, label: 'Stock'}
];

const ProductSalesReport = ({actions, snackbars}) => {
    const obj =  {url:'sales_purchase',id:'type_id'}
    const {closeSnackbar, search_type, handleSubmit, data, count, customSearch, handleChangeActivePage,
        text, textType, year, date, handleChangeSearch, handleCustomSearch,category,
        handleChangeDate, handleChangeYear, handleIncrementYear, isSubmitted, submitted,
        loading,  inputType, csvRef,   handleChangeCategory, activePage
    } = useProductSalesReport(actions, obj)
    const [categories, setCategories] = useState([]);
    const [allRecords, setAllRecords] = useState([])
    const {start_date, end_date} = date;

    const dataArr = data.sales ?? []
    useEffect(() => {
        axios.get(`${config.smsUrl}/cpharmacy/type_list`).then(res => {
            const data = res.data;
            const dt = data ?? {};
            const all_types = dt.type_list ?? [];
            const arr = all_types.map(item => ({
                value: item.product_type_id,
                label: item.type_name
            })) 
            setCategories([{value:'', label:'All'},...arr]);
        }).catch(err => {
            logoutErrorMessage(err, null, actions);
        })
    }, [])

    const downloadPdf = () => {
        const {end_date, start_date} = date
        const time = search_type === '0' ? start_date : search_type === '1' ? convertMonth(start_date) :
            search_type === '2' ? year : `${start_date} to ${end_date}`;
        axios.post(`${config.smsUrl}/creport/sales_purchase`, null, {params: {
            start_date: start_date,
            end_date: end_date,
            date_type: 3,
            page:0,
            type_id: category.value ? category.value : '',
        }}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const sales = dt.sales ? dt.sales : []
            const total = sales[sales.length-1] ? sales[sales.length-1] : {};
            const totalAmount = total.grand_total_amount ? total.grand_total_amount : 0;
            exportPDF(`Product Sales (${time})`, [['Product', "Quantity Sold", "Rate", "Amount"]],
            sales.filter(item=>item.product_id).map((item) => [item.product_name, item.quantity_sold, formatAmount(item.rate), formatAmount(item.total_amount)]),
            `Product Sales (${time}).pdf`,[["Total Amount","", "", formatAmount(totalAmount)]]);
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            
        })
}

    const downloadCsv = () => {
        axios.post(`${config.smsUrl}/creport/sales_purchase`, null, {params: {
            start_date: start_date,
            end_date: end_date,
            date_type: 3,
            page:0,
            type_id: category.value ? category.value : '',
        }}).then(res => {
            const data = res.data;
            const dt = !data ? {} : data;
            const sales = dt.sales ? dt.sales : []
            setAllRecords(sales)
            csvRef.current.link.click()
        }).catch((err) => {
            logoutErrorMessage(err, null, actions);
            
        })

        
    }

    const headers = [{label: 'Product', key: 'product_name'}, {label: 'Quantity Sold', key: 'quantity_sold'},
        {label: 'Unit Price', key: 'rate'},{label: 'Amount', key: 'total_amount'}]
    const {openBar, type, message} = snackbars;
    const components = [
    {label: 'Sales by Service', path: '/reports/revenuebyprocedure'},
    {label: 'Sales by Product', path: '/accounting/productsales'},
]
    return (
        <div className='journals' data-testid="revenue-generated">
            <PageTitle title='Sales per Product'/>
            <SubHeader title="Reports" subTitle='Sales per Product'>
                <FontAwesomeIcon icon={faClipboardList}/>
            </SubHeader>
            <div style={{margin: '10px'}}>
                <BackButton text={'General Reports'} to={'/reports/generalreports'} data-testid="product-sales-report-back-button"/>
            </div>
            <ReusableRouteTabs value={history.location.pathname} tabHeading={components}/> 
        
            <MainSearchComponent text={text} type={textType} changeDate={handleChangeDate}
                                    handleSubmit={handleSubmit} isSubmitted={isSubmitted}
                                    changePeriod={handleChangeSearch} end_date={end_date}
                                    handleChangeValue={handleChangeYear} inputType={inputType}
                                    search_type={search_type} handleChangeYear={handleIncrementYear}
                                    start_date={start_date} year={year} submitted={submitted}>
                <DataExportButton headers={headers} title='Product Sales'
                                    csvRef={csvRef} data={allRecords} downLoadPdf={downloadPdf}
                                    exportCsv={downloadCsv}/>
            </MainSearchComponent>

            <div>
                <SortSelect categories={categories} category={category} handleChangeCategory={handleChangeCategory}
                            placeholder='Select product type...'/>
                <MainSnackbar variant={type} open={openBar} message={message} handleCloseBar={closeSnackbar}/>
                <CustomTable title="" handleChangeNextPage={handleChangeActivePage} term={customSearch}
                             records={10}
                             headData={headData} handler={handleCustomSearch} total_count={count}
                             activePage={activePage}
                              data={dataArr} colSpan={headData.length}
                              customPage>
                    {loading ? <TableBody><TableRow>
                        <TableCell colSpan={headData.length} align="center"><img src="/images/smsloader.gif"
                                                                   alt="loader"/></TableCell>
                    </TableRow></TableBody> : dataArr.length > 0 ?

                        <TableBody>
                            {dataArr.slice(0, 10).map((item, index) => {
                                    const cnt = ((activePage - 1) * 10) + index + 1;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                            className='mui-table-row'>
                                            <TableCell>{cnt}</TableCell>
                                            <TableCell>
                                               <Link to={{pathname:`/reports/productlog/reports/${item.product_id}`}}>{item.product_name}</Link> 
                                            </TableCell>
                                            <TableCell>{item.quantity_sold}</TableCell>
                                            <TableCell>{formatAmount(item.rate)}</TableCell>
                                            <TableCell>{(item.total_amount)}</TableCell>
                                            <TableCell>{item.pharmacy_stock}</TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody> : <TableBody><TableRow>
                            <TableCell colSpan={headData.length} align="center">No records found</TableCell>
                        </TableRow></TableBody>}
                </CustomTable>
            </div>
        </div>
    )
};

function mapStateToProps(state) {
    return {snackbars: state.snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSalesReport);
