import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as snackbarActions from "../../../actions/snackbarActions";
import MainSnackbar from "../../Utils/Snackbar/SmsSnackbar";
import {useAddPurchaseOrder} from "./hooks/useAddPurchaseOrder";
import PageTitle from "../../Utils/smsTitle";
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShippingFast} from "@fortawesome/free-solid-svg-icons/faShippingFast";
import PrintDialog from "../../Utils/Dialogs/PrintDialog";
import {Link} from "react-router-dom";
import {faListAlt} from "@fortawesome/free-solid-svg-icons/faListAlt";
import Card from "@material-ui/core/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Label from "../../Utils/FormInputs/Label";
import Col from "react-bootstrap/Col";
import {AutoCompleteControlled, AutoCompleteProducts} from "../../Utils/FormInputs/AutoCompleteInput";
import {CustomInput, CustomResizableTextarea} from "../CustomInput";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {faQuestionCircle} from "@fortawesome/free-regular-svg-icons/faQuestionCircle";
import TextField from "../../Utils/FormInputs/TextField";
import {ErrorMessage} from "../../Utils/styledComponents";
import {faMinus} from "@fortawesome/free-solid-svg-icons/faMinus";
import {useTheme} from "@material-ui/core/styles";
import CustomDialog from "../../Utils/Dialogs/CustomDialog";
import {SupplierForm} from "./AddEditSupplier";
import {BackButton} from "../../Utils/Buttons/DataExportationButton";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";


const AddPurchaseOrder = ({actions, snackbar, match:{params}}) =>{

    const theme = useTheme();
    const them = !theme ? {} : theme;
    const palette = !them.palette ? {} : them.palette;

    const {local_purchase_order_id} = params;

    const {rows, supplier, state, openDialog, submitted, isSubmitted, total_tax,handleChangePrice,
        sub_total, grand_total, suppliers, items,  handleSubmitPurchase,
        handleChangeSupplier,  handleAddRow, handleRemoveSpecificRow,
        handleChangeTax, retrieveProducts, clickProduct, handleChangeQuantity, handleCloseBar,
        handleCloseDialog, viewOrder, isPending, isResolved,
        openAddSupplier, supplierProps,  handleCloseSupplierDialog,
        handleOpenSupplierDialog} = useAddPurchaseOrder(actions, params)

    const {ship_to_address, delivery_date, shipping_terms, fob, comments} = state

    const {isPending:isPendingSupplier} = supplierProps
    const {type, message, openBar} = snackbar;
    const title = local_purchase_order_id ? 'Edit Purchase Order' : 'Add Purchase Order'
    return(
        <div data-testid="add-edit-purchase-order">
            <PageTitle title={title}/>
            <SubHeader title="Inventory" subTitle={title}>
                <FontAwesomeIcon icon={faShippingFast}/>
            </SubHeader>
            <MainSnackbar handleCloseBar={handleCloseBar} message={message} open={openBar} variant={type}/>
            <PrintDialog message="View Purchase Order" openDialog={openDialog} handleClose={handleCloseDialog}
                         text="Would you like to view and print this purchase order" handleClick={viewOrder}
                         handleCancel={handleCloseDialog}/>
            <CustomDialog open={openAddSupplier} title="Add Supplier" handleClose={handleCloseSupplierDialog} maxWidth="md">
                <SupplierForm {...supplierProps}>
                    <button type="submit" disabled={isPendingSupplier} className="btn btn-sm sms-btn">{isPendingSupplier ? 'Saving...' : 'Add Supplier'}</button>
                </SupplierForm>

            </CustomDialog>
            <div className='general-ledger-header'>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <BackButton to='/purchaseorderlist' text='Purchase Orders' data-testid="purchase-order-back-button"/>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="text-lg-right text-md-right text-sm-left text-xl-right">
                            <button type="button" id="quick-add-supplier" className="btn btn-sm sms-info-btn mb-2  line-button" onClick={handleOpenSupplierDialog}>
                                <FontAwesomeIcon icon={faPlus}/>Quick Add Supplier</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="add-bill-card">
                <Card className="p-3">
                    <h6 className={`visit-heading ${palette.type === "dark" && "dark-visit-heading"}`}>{title}</h6>
                    <form onSubmit={handleSubmitPurchase} autoComplete="off">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                        <Label name="Supplier"  type/>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                        <AutoCompleteControlled options={suppliers} submitted={submitted} handleChange={handleChangeSupplier}
                                                                errorMsg="Supplier is required" medicine_value={supplier}
                                                                value_text='value' label_text='label' testId='supplier_name' />
                                        {(submitted && !supplier) && <ErrorMessage>Supplier is required</ErrorMessage>}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                        <Label name="Shipping Address"  type htmlFor="ship_to_address"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <CustomResizableTextarea rows={3} inputValue={ship_to_address} submitted={submitted} isRequired name='ship_to_address' reset={isResolved}/>

                                        {/* {(submitted && !ship_to_address) && <ErrorMessage>Shipping address is required</ErrorMessage>} */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={2} xl={2}>
                                        <Form.Label htmlFor="fob">F.O.B<span className="ml-2">
                                            <OverlayTrigger
                                                trigger="hover"
                                                placement="right"
                                                overlay={
                                                    <Popover id="popover-positioned-right">
                                                        <Popover.Content>
                                                            Free on Board
                                                        </Popover.Content>
                                                    </Popover>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faQuestionCircle}/>
                                            </OverlayTrigger>
                                            </span>
                                        </Form.Label>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                    <CustomResizableTextarea rows={3} inputValue={fob}   name='fob' reset={isResolved}/>
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className="col-md-6">
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <Label name="Delivery Date"  type htmlFor="delivery_date"/>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                        <CustomInput submitted={submitted} inputValue={delivery_date} name='delivery_date' type='date' isRequired reset={isResolved} />

                                        {/* {(submitted && !delivery_date) && <ErrorMessage>Delivery date is required</ErrorMessage>} */}
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <Form.Label htmlFor='shipping_termes' >Shipping Terms &amp; Conditions<span className="ml-2">
                                        <OverlayTrigger
                                            trigger="hover"
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-positioned-right">
                                                    <Popover.Content>
                                                        Terms and conditions under which products should be shipped
                                                    </Popover.Content>
                                                </Popover>
                                            }
                                        >
                                           <FontAwesomeIcon icon={faQuestionCircle}/>
                                </OverlayTrigger>
                                </span></Form.Label>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>

                                    <CustomResizableTextarea rows={3} inputValue={shipping_terms}  name='shipping_termes' reset={isResolved}/>

                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row}>
                                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                                        <Form.Label htmlFor='comments'>Comments &amp; Special Instructions<span className="ml-2">
                                        <OverlayTrigger
                                            trigger="hover"
                                            placement="right"
                                            overlay={
                                                <Popover id="popover-positioned-right">
                                                    <Popover.Content>
                                                        Instructions on how products should be handled
                                                    </Popover.Content>
                                                </Popover>
                                            }
                                        >
                                           <FontAwesomeIcon icon={faQuestionCircle}/>
                                </OverlayTrigger>
                                </span></Form.Label>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                         <CustomResizableTextarea rows={3} inputValue={comments}   name='comments' reset={isResolved}/>

                                    </Col>
                                </Form.Group>

                            </div>
                        </div>
                        <div className="table-responsive">
                            <table
                                className={`table table-sm table-bordered ${palette.type === 'dark' && 'dark-table dark-table-bordered'}`}>
                                <thead>
                                <th className={palette.type === 'dark' && 'dark-theme-color'}>Product Name<span className="help-block">*</span>
                                </th>
                                <th className={palette.type === 'dark' && 'dark-theme-color'}>Qty<span className="help-block">*</span></th>
                                <th
                                    className={palette.type === 'dark' && 'dark-theme-color'}>Unit Cost<span className="help-block">*</span>
                                </th>
                                <th
                                    className={palette.type === 'dark' && 'dark-theme-color'}>Total
                                </th>
                                <th/>
                                </thead>
                                <tbody>
                                {rows.map((item, index) => (
                                    <tr key={item.uuid} id="drugs-row">
                                        <td>
                                            <AutoCompleteProducts testId={`product_name-${index}`} options={items} submitted={submitted} idx={index} handleChange={retrieveProducts} id="drug_name"
                                                                  medicine_value={{label:rows[index].product_name, value: rows[index].product_id, generic_name: rows[index].generic_name}}
                                                                  handleProductData={clickProduct} placeholder="Type Product Name" errorMsg="Enter Product Name"
                                                                  errorMsg_Valid={"Please enter a valid product"} valid_error_check={item.isError && submitted}
                                            />
                                        </td>
                                        <td><TextField  {...{submitted}} value={item.quantity} name="quantity"
                                                        onChange={(e) => handleChangeQuantity(e, index)} type="number"

                                                        data-testid="quantity" placeholder="Input quantity"/>
                                            {(submitted && !item.quantity) && <ErrorMessage>Quantity is required</ErrorMessage>}
                                        </td>
                                        <td><TextField  {...{submitted}} value={item.rate} name="rate" type="number" placeholder="Input unit cost"

                                                        id="rate" onChange={(e)=>handleChangePrice(e, index)}/>
                                            {(submitted && !item.rate) && <ErrorMessage>Price is required</ErrorMessage>}
                                        </td>
                                        <td><TextField value={item.total} name="dosage" type="text" disabled={true}

                                                       id="total_quantity"/></td>
                                        <td>
                                            <button onClick={() => handleRemoveSpecificRow(item.uuid)} type="button"
                                                    className="btn btn-sm sms-btn-dismiss" data-testid="remove-row">
                                                <FontAwesomeIcon icon={faMinus}/>
                                            </button>
                                        </td>
                                    </tr>))}
                                <tr>
                                    <td style={{borderRight:'1px solid #fff'}}>
                                        <button type="button" className="btn btn-sm sms-gray-btn" id="add-row"
                                                onClick={handleAddRow}>Add another line
                                        </button>
                                    </td>
                                    <td  colSpan={2} align="right">Sub Total</td>
                                    <td><TextField  type="text" disabled={true} name='sub_total' value={sub_total}/></td>
                                </tr>
                                <tr>
                                    <td colSpan={3} align="right">VAT %</td>
                                    <td><TextField type="text" onChange={e=>handleChangeTax(e, rows)}  value={total_tax} name="total_tax"/></td>
                                </tr>
                                <tr>
                                    <td colSpan={3} align="right">Grand Total</td>
                                    <td><TextField type="text"  disabled={true} name='grand_total'
                                                   value={grand_total}/></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="text-right mt-2">
                            <button type="reset" id="reset_form"
                                    className="sms-grey-btn btn btn-sm px-3 mr-2">Reset
                            </button>
                            <button type="submit" disabled={isPending}
                                    className="btn sms-btn btn-sm">{isSubmitted === 'pending' ? "Saving..." : "Save"}</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    )
};
function mapStateToProps(state) {
    const {snackbar} = state
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {actions:{snackbarActions:bindActionCreators(snackbarActions, dispatch)}}
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPurchaseOrder);
