/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react'
import { history } from '../../../Helpers/history';
import {SubHeader} from "../../../Containers/SubHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons/faCoins";
import PageTitle from "../../Utils/smsTitle";
import { Button, MiniCenteredDiv, RightAlignedContainer } from '../../Utils/styledComponents'
import { PrintTemplate, ItemsTable } from '../../Utils/Templates/PrintTemplate'
import { usePrint } from '../../Utils/Templates/usePrint';
import {useBillingReceipt} from "./hooks/useBillingReceipt";
import * as colors from '../../../styles/colors';
import { formatDateTime } from '../../Utils/ConvertDate';
import Card from '@material-ui/core/Card'
import { BackButton } from '../../Utils/Buttons/DataExportationButton';
import styled from "@emotion/styled/macro";
import Form from "react-bootstrap/Form";
import {CurrencyToggle, FxRate} from "../../Utils/CurrencyToggle";
import React from "react";




const textWeight = css`
    font-weight:600;
`



const StyledContainer = styled(RightAlignedContainer)({
    marginBottom:'5px'
})

function BillingReceipt(props) {
    const {receipt, services, state,  total_amount, cashier,
        exchangeRate, currencies, currency, handleChangeExchangeRate, handleChangeCurrency} = useBillingReceipt(props)
    const { receipt_no, date_created} = state;
    const {currency_total, usd_total,paid_amount,due_amount,
        currency_paid_amount,currency_due_amount} = total_amount

    const isBaseCurrency = currency === 'USD'
    const currency_symbol = !isBaseCurrency && exchangeRate ? currency : 'USD'

    const handleOnAfterPrint = () => {
        history.push('/paymentList');
    }

    const {componentRef, handlePrint,fontSize}  = usePrint(`BillingReceipt${receipt_no}`,handleOnAfterPrint)

    const headData = [{key:'name',value:'Item'}, {key:'quantity',value:'Quantity'},
        {key:'rate',value:`Rate`}, {key:'amount',value:`Amount`}]
  const patientDetails = (
      <tbody>
          <tr>
              <td><span>Date: </span> <span css={[textWeight]}>{formatDateTime(date_created)}</span></td>
              <td><span>Patient Name: </span> <span css={[textWeight]}>{`${!receipt.patient_firstname ? "" : receipt.patient_firstname} 
                                ${!receipt.patient_lastname ? "" : receipt.patient_lastname}`}
                </span> </td>


          </tr>
          <tr>
              <td><span>Cashier: </span> <span><strong>{cashier}</strong></span></td>
              <td><span>Contact: </span> <span css={[textWeight]}>{receipt.phone_no}</span></td>
          </tr>
          <tr>

              <td><span>Patient Number: </span> <span css={[textWeight]}>{receipt.patient_number}</span></td>
              <td><span>Address:</span> <span css={[textWeight]}>{receipt.address}</span></td>
          </tr>
      </tbody>
  )
  const itemDetails = (
    <ItemsTable {...{headData}}>
      <tbody>
      {services.map((item) => {
            return (
                <tr key={item.bill_id}>
                    {/* <td>{item.invoice_no}</td> */}
                    <td><span>{item.item_name}</span></td>
                    <td><span>{item.quantity}</span></td>
                    <td><span>{item.usd_rate }</span></td>
                    <td><span>{item.usd_total}</span></td>
                </tr>
            )
        })}
          <tr>
              <td colSpan={headData.length -1}  align='right'><span css={[textWeight]}>Total Amount</span></td>
              <td ><span css={[textWeight]}>{usd_total}</span></td>
          </tr>
          <tr>
              <td colSpan={headData.length -1}  align='right' ><span css={[textWeight]}>Amount Received</span></td>
              <td><span css={[textWeight]}>{paid_amount}</span></td>
          </tr>
          <tr>
              <td colSpan={headData.length -1}  align='right'  ><span css={[textWeight]}>Balance</span></td>
              <td><span css={[textWeight]}>{due_amount}</span></td>
          </tr>
      </tbody>
    </ItemsTable>
  )
  return (
      <div>
        <PageTitle title="Billing Receipt"/>
        <SubHeader title="Billing" subTitle="Billing receipt">
            <FontAwesomeIcon icon={faCoins}/>
        </SubHeader>
        <MiniCenteredDiv>
            <BackButton to='/paymentList' text='Billing List' data-testid="billing-receipt-back-button"/>
            <StyledContainer>
            
              <Button variant='orange' onClick={handlePrint}>Print</Button>
            </StyledContainer>
            <Card>
                {/*<div style={{padding:'8px 60px', borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>*/}
                {/*    <RightAlignedContainer>*/}
                {/*        <CurrencyToggle options={currencies} value={currency} handleChangeCurrency={handleChangeCurrency} />*/}
                {/*        { currency !== 'USD' ? <FxRate currency={currency !== 'USD' && currency} value={exchangeRate}  handleChangeRate={handleChangeExchangeRate} />: null}*/}
                {/*    </RightAlignedContainer>*/}
                {/*</div>*/}
                <div ref={componentRef} style={{padding:'60px'}} className="requisition-receipt">
                    <style>
                        {fontSize()}
                    </style>
                    <PrintTemplate title='RECEIPT' {...{patientDetails,itemDetails}}/>
                </div>
            </Card>
        </MiniCenteredDiv>
      </div>
  )
}

export default BillingReceipt