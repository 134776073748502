import React, {useRef} from "react";
import {usePreviousInpatientTreatment} from "./CustomHooks/usePreviousInpatientTreatment";
import {titleCase} from "../Users/addUser";
import ReactToPrint from "react-to-print";
import {bindActionCreators} from "redux";
import * as snackbarActions from "../../actions/snackbarActions";
import {connect} from "react-redux";
import SmsSnackbar from "../Utils/Snackbar/SmsSnackbar";
import {usePatientDetails} from "../DoctorsModule/ReferralNote/usePatientDetails";
import Card from '@material-ui/core/Card'

const style = {
    border: {
        borderTop: '1px solid #606060',
        borderBottom: '1px solid #606060'
    },
    image: {
        width: 130,
        border: '1px solid #e4e4e4',
        borderRadius: '60px',
        background: '#e4e4e4'
    },
    p: {
        marginTop: '15px 0 0 0'
    },
    parent: {
        textAlign: 'center',
        marginTop: '.7em'
    }
}

export const NoTransaction = ({text}) => {
    return (
        <div style={style.parent}>
            <img src="/images/prescription.png" alt='treatment records' style={style.image}/>
            <p className="text-center" style={style.p}>{text}</p>
        </div>
    )
}


const TreatmentHistory = ({actions, snackbar, match}) => {
    const {patient_admission_id, patient_number, visit_id} = match
    const {treatmentHistory, loading, closeSnackbar} = usePreviousInpatientTreatment(actions, patient_admission_id)
    const printRef = useRef()
    const {openBar, message, type} = snackbar
    const {patient} = usePatientDetails(actions, patient_number, visit_id);
    const {first_name, last_name} = patient
    return (
        <div className='mt-4'>
            <SmsSnackbar open={openBar} message={message} variant={type} handleCloseBar={closeSnackbar}/>
            {loading === 'pending' ? <div>Loading data....</div> :
                loading === 'resolved' && treatmentHistory.length > 0 ?
                    <div>
                        <div className='text-right'>
                            <ReactToPrint trigger={() =>
                                <button className="btn btn-sm sms-scan-btn mb-1">Print
                                </button>} content={() => printRef.current}/>
                        </div>
                        <div ref={printRef} id="treatment-print">
                            <Card className="general-card p-3">
                                <table className='table table-sm table-borderless prescription-table'>
                                    <tr>
                                        <td colSpan={2} align='center'><h6>TREATMENT CHART</h6></td>
                                    </tr>
                                </table>
                                <table
                                    className="table table-bordered  prescription-table profile-table bill-receipt-table">
                                    <thead>
                                    <tr>
                                        <td><p>Date</p></td>
                                        <td><p>Drug</p></td>
                                        <td><p>Dosage</p></td>
                                        <td><p>Route</p></td>
                                        <td><p>Quantity</p></td>
                                        <td><p>Time</p></td>
                                        <td><p>Comments</p></td>
                                        <td><p>Created By</p></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {treatmentHistory.filter(item => item.patient_medication_detail_id).map((item,) => (
                                        <tr key={item.patient_medication_detail_id}>
                                            <td>
                                                <p>{item.date_prescribed.split('T').slice(0, 1).join('')}</p>
                                            </td>
                                            <td>
                                                <p>{item.product_name}</p>
                                            </td>
                                            <td>
                                                <p>{item.dosage}</p>
                                            </td>
                                            <td>
                                                <p>{item.route}</p>
                                            </td>
                                            <td>
                                                <p>{item.quantity}</p>
                                            </td>
                                            <td>
                                                <p>{item.intake_time}</p>
                                            </td>
                                            <td>
                                                <p>{item.instructions ? titleCase(item.instructions) : ''}</p>
                                            </td>
                                            <td>
                                                <p>{item.first_name} {item.last_name}</p>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Card>
                        </div>
                    </div> : <NoTransaction text='No previous treatment records found'/>}
        </div>
    );
};


function mapStateToProps(state) {
    const {snackbar} = state;
    return {snackbar}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: {
            snackbarActions: bindActionCreators(snackbarActions, dispatch)
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(TreatmentHistory)
