import {useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../../Helpers/env";
import {logoutErrorMessage} from "../../../../Helpers/ErrorMessages";
import {dateConvert} from "../../../Utils/ConvertDate";

const SortTheDictionary = (surgers, category, moh_dict, departs) => {
    for (const [key, value] of Object.entries(surgers)) {
        
        let surg_cates = {};

        if (key in moh_dict) {
            // surgery values exist
            surg_cates = moh_dict[key];
            switch (category) {
                case "zero_28days":
                    surg_cates.zero_28days.female = value["F"];
                    surg_cates.zero_28days.male = value["M"];
                    break;

                case "twenty_nine_four_years":
                    surg_cates.twenty_nine_four_years.female = value["F"];
                    surg_cates.twenty_nine_four_years.male = value["M"];
                    break;

                case "five_years":
                    surg_cates.five_years.female = value["F"];
                    surg_cates.five_years.male = value["M"];
                    break;
        
                case "ten_19years":
                    surg_cates.ten_19years.female = value["F"];
                    surg_cates.ten_19years.male = value["M"];
                    break;

                case "twenty_years_plus":
                    surg_cates.twenty_years_plus.female = value["F"];
                    surg_cates.twenty_years_plus.male = value["M"];
                    break;
                
                default:
                    break;
            }

            surg_cates.total = surg_cates.total + value["F"] + value["M"];
            moh_dict[key] = surg_cates;

        } else {

            // create new surgery var
            const surgery_categories = {zero_28days:  {male:0, female:0}, twenty_nine_four_years:  {male:0, female:0}, 
                five_years:  {male:0, female:0}, ten_19years :  {male:0, female:0}, twenty_years_plus:  {male:0, female:0}, total:0};
 
            switch (category) {
                case "zero_28days":
                    surgery_categories.zero_28days.female = value["F"];
                    surgery_categories.zero_28days.male = value["M"];
                    break;

                case "twenty_nine_four_years":
                    surgery_categories.twenty_nine_four_years.female = value["F"];
                    surgery_categories.twenty_nine_four_years.male = value["M"];
                    break;

                case "five_years":
                    surgery_categories.five_years.female = value["F"];
                    surgery_categories.five_years.male = value["M"];
                    break;
        
                case "ten_19years":
                    surgery_categories.ten_19years.female = value["F"];
                    surgery_categories.ten_19years.male = value["M"];
                    break;

                case "twenty_years_plus":
                    surgery_categories.twenty_years_plus.female = value["F"];
                    surgery_categories.twenty_years_plus.male = value["M"];
                    break;

                default:
                    break;
            }

            surgery_categories.twenty_years_plus.female = value["F"];
            surgery_categories.twenty_years_plus.male = value["M"];
            surgery_categories.total = value["F"] + value["M"];
            surg_cates = surgery_categories;
            moh_dict[key] = surg_cates;

             if (value.department_name in departs) {
                // depart exists
                departs[value.department_name].actual_surgeries.push({[key]:surg_cates});
            } else {
                departs[value.department_name] = {
                    "actual_surgeries" :[
                        {[key] : surg_cates}
                    ]
                }
            }
        }
    }
}

const OrganiseData = (surgery_reports) => {

    let moh_dict = {};
    let departs = {};
    let checking = false;

    if (surgery_reports.length > 0) {
        const a0 = surgery_reports[0];
        const a0to28days = a0["a0to28days"];

        const a29 = surgery_reports[1];
        const a29daysto4Yrs = a29["a29daysto4Yrs"]; 
        
        const a5 = surgery_reports[2];
        const a5to9Yrs = a5["a5to9Yrs"];

        const a10 = surgery_reports[3];
        const a10to19Yrs = a10["a10to19Yrs"];

        const a20_plus = surgery_reports[4];
        const a20Yrsandabove = a20_plus['a20Yrsandabove'];

        checking = (a0to28days || a29daysto4Yrs || a5to9Yrs || a10to19Yrs || a20Yrsandabove) ? true : false;

        SortTheDictionary(a0to28days, "zero_28days", moh_dict, departs);
        SortTheDictionary(a29daysto4Yrs, "twenty_nine_four_years" , moh_dict, departs);
        SortTheDictionary(a5to9Yrs, "five_years", moh_dict, departs);
        SortTheDictionary(a10to19Yrs,  "ten_19years", moh_dict, departs);
        SortTheDictionary(a20Yrsandabove,  "twenty_years_plus", moh_dict, departs);
    }

    return {departs, checking};
}

const useSurgeryMOH = (actions) => {
    const [loading, setLoading] = useState('idle');
    const [surgery_reports, setReports] = useState({});
    const [state, setState] = useState({start_date:dateConvert(), end_date:dateConvert()})
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const {start_date, end_date} = state;
    const [checkForData, setCheckForData] = useState(false);

    useEffect(() => {

        setLoading('pending');
        const formData = new FormData();
        formData.append('start_date', start_date);
        formData.append('end_date', end_date);
        axios.post(`${config.smsUrl}/creport/surgery_report`, formData).then((res1) => {
            setLoading('success')
            const diagnosis = res1.data ? res1.data : {};
            const report = diagnosis.Surgery ? diagnosis.Surgery : [];
            const {departs, checking} = OrganiseData(report);

            setReports(departs);
            setSubmitted(false);
            setCheckForData(checking);
            setIsSubmitted('rejected')
            setLoading('success')
          })
          .catch(err => {
            logoutErrorMessage(err, null, actions);
            setLoading('error')
          });
    }, []);

    const handleChange = (event) =>{
        const {name, value} = event.target;
        setState({...state,[name]:value})
    }

    const handleSubmit = (event) =>{
        event.preventDefault();
        setSubmitted(true);
        if(start_date && end_date){
            setLoading('pending');
            setIsSubmitted('pending')
            const formData = new FormData();
            formData.append('start_date', start_date);
            formData.append('end_date', end_date);
            axios.post(`${config.smsUrl}/creport/surgery_report`, formData).then((res1) => {
                setLoading('success')
  
                //diagnosis
                const diagnosis = res1.data ? res1.data : {};
                const report = diagnosis.Surgery ? diagnosis.Surgery : [];
                const {departs, checking} = OrganiseData(report);

                setReports(departs);
                setSubmitted(false);
                setIsSubmitted('rejected')
                setCheckForData(checking);
                setLoading('success')
              })
              .catch(err => {
                logoutErrorMessage(err, null, actions);
                setLoading('error')
                setIsSubmitted('rejected')
              });
        }
    }

    const closeSnackbar = () => {
        actions.snackbarActions.hideSnackbar()
    };

    const isLoading = loading === 'pending';
    const isSuccess = loading === 'success';
    const isError = loading === 'error';
    
    const isPending = isSubmitted === 'pending';
    const isResolved = isSubmitted === 'resolved';
    const isRejected = loading === 'rejected';

    // for the diagnosis notes
    const handleChangeNewPage = (page) => {
      setActiveTablePage(page);
    }

    // keep track of the page numbers in the table
    const [activeTablePage, setActiveTablePage] = useState(1);

    return {surgery_reports, closeSnackbar,  isLoading, isSuccess, isError, state,submitted,
        handleChange, handleSubmit, isPending, isRejected, isResolved,
        handleChangeNewPage, activeTablePage, checkForData
    }
}

export {useSurgeryMOH}