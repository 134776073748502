import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import { config } from "../../../Helpers/env";
import {history} from "../../../Helpers/history";
import {errorMessages} from "../../../Helpers/ErrorMessages";
import { titleCase } from "../../Users/addUser";
import { logoutErrorMessage } from "../../../Helpers/ErrorMessages";
import {v4 as uuidv4} from 'uuid';
import { snackError } from "../../../actions/snackbarActions";


export const useAddServiceConsumables = (actions, submitted) => {

    // service consumables hooks
    const [product_consumables, setProductConsumables] = useState([{
        product_name:'', product_id:'', quantity:'', showItems: false, product_options:[], uuid:uuidv4(), unit:''
    }]);
    
    // function for handling the products table
    const handleAddProductRow = () => {
        const new_list = [
            ...product_consumables, 
            {
                product_name:'', product_id:'', quantity:'', product_options:[],
                showItems: false, uuid:uuidv4(), unit:''
            }
        ];
        setProductConsumables([...new_list]);
    }

    const handleRemoveProductRow = (uid) => {
        const new_list = product_consumables.filter((item)=> (item.uuid !== uid));
        setProductConsumables([...new_list])
    }

    const handleChangeProduct = (e, idx, item) => {
        let new_item = item;
        const {name, value} = e.target;
        new_item[name] = value;
        let new_list = product_consumables;
        new_list[idx] = new_item;
        setProductConsumables([...new_list]);
    }

    const getProductOptions = (e, index, item) => {
        const {name, value} = e.target;
        let new_item = item;
        new_item[name] = value;

        if (e.target.value === '') {
            new_item['product_options'] = [];
            new_item['showItems'] = false;
            let new_list = product_consumables;
            new_list[index] = new_item;
            setProductConsumables([...new_list]);
        } else {

            // make the api call
            // set the service_options
            const formData = new FormData();
            formData.append('product_name', titleCase(e.target.value));
            axios.post(`${config.smsUrl}/cinventory/product_search_by_name`, formData).then(res => {
                const dt = res.data?? [];
    
                if (dt.length === 1 && dt[0] === 'No Product Found') {
                    const data = [{
                        'product_name' :  'No Product Found',
                        'product_id' : -1
                    }];
        
                    new_item['product_options'] = data;
                    new_item['showItems'] = true;
    
                    let new_list = product_consumables;
        
                    new_list[index] = new_item;
                    setProductConsumables([...new_list]);
                } else {
                    const data = dt.map((it) => ({
                        'product_name' : it.label?? '',
                        'product_id' : it.value?? '',
                    }));
        
                    new_item['product_options'] = data;
                    new_item['showItems'] = true;
    
                    let new_list = product_consumables;
                    new_list[index] = new_item;
                    setProductConsumables([...new_list]);
                }    
            }).catch(err => {
                new_item['service_options'] = [];
                let new_list = product_consumables;
                new_list[index] = new_item;
                setProductConsumables([...new_list]);
                logoutErrorMessage(err,null,actions);
            })
        }
    } 

    const setProductDetails = (val, index, item) => {
        let new_item = item;
        new_item = {...new_item, ...val};
        new_item['showItems'] = false;
        new_item['product_options'] = [];
        let new_list = product_consumables;
        new_list[index] = new_item;
        setProductConsumables([...new_list]);
    }    
    
    const serviceConsumablesProps = {
        submitted, setProductConsumables,
        product_consumables, handleAddProductRow, handleRemoveProductRow, handleChangeProduct, getProductOptions, setProductDetails,
    }

    return {serviceConsumablesProps}

}


export const useAddService = (props) =>{
    const [state, setState] = useState({
        service_name: "", description: "", quantity: "", rate: "", status: '1',
    });
    const [submitted, setSubmitted] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState('idle');
    const [department, setDepartment] = useState('');
    const [service_type, setServiceType] = useState('');
    const {actions} = props;

    const [isServiceConsumables, setIsServiceConsumables]= useState(false);
    
    const handleConsumablesToggle = (event) => {
        setIsServiceConsumables(event.target.checked);
    };

    const {serviceConsumablesProps} = useAddServiceConsumables(actions, submitted);

    const {product_consumables, setProductConsumables} = serviceConsumablesProps;

    const retrieveDepartments = useCallback(() => {
        actions.departmentActions.loadDepartments();
    }, []);

    useEffect(() => {
        retrieveDepartments()
    }, []);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setState({...state, [name]: value});
    };

    const handleChangeDepartment = (value) => {
        setDepartment(value)
    };

    const handleChangeServiceType = (value) => {
        setServiceType(value)
        if (value.value === "Lab") {
            setDepartment({value: 7, label: 'Labs'})
        } else if (value.value === "Radiology") {
            setDepartment({value: 8, label: 'Scans and Radiology'})
        }else {
            setDepartment([])
        }
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const {service_name, rate,description,status} = state
        setSubmitted(true);
        const formData = new FormData();
        formData.append('service_name', service_name);
        formData.append('service_type', service_type.value);
        formData.append('description', description);
        formData.append('department_id', department.value);
        formData.append('quantity', state.rate);
        formData.append('status', state.status);

        let is_empty = false;
        const service_consumes = product_consumables.map((item) => { 
            if (item.product_id && item.product_name && item.quantity) {
                if ((service_type === 'Lab' && !item.unit)) {
                    is_empty = true;
                }
            } else {
                is_empty = true;
            }
            return {
                'product_id' : item.product_id,
                'product_name': item.product_name,
                'quantity' : item.quantity,
                'unit' : item.unit??''
        }})

        const params = {
            service_name,
            'service_type' : service_type.value ? service_type.value : '',
            description,
            'department_id' : department.value,
            'cost' : state.rate,
            'status' : parseInt(state.status),
            'is_service_consumables' : isServiceConsumables,
            'service_consumables' : service_consumes
        }
        
        const is_consumables_okay = isServiceConsumables ? (is_empty === false) : true;
        if (service_name && is_consumables_okay) {
            setIsSubmitted('pending');
            axios.post(`${config.smsUrl}/cbilling/create_service`, params).then(() => {
                setIsSubmitted('resolved');
                actions.snackbarActions.snackSuccess('Service Registered Successfully');
                history.push('/servicelist/1');
            }).catch(err => {
                setIsSubmitted('rejected');
                if (err.response) {
                    if (err.response.data.message && err.response.data.message  === "Service Already Exists") {
                        actions.snackbarActions.snackError("Service Already Exists");
                    }
                } else {
                    errorMessages(err, null, actions);
                }
            
                
            })
        }
    };

    const handleResetForm = () => {
        setState({
            service_name: "", description: "",
            quantity: "", rate: "", status: "", service_type: ''
        });
        setDepartment('');
        setSubmitted(false)

        // set the consumables to empty
        setProductConsumables([{
            product_name:'', product_id:'', quantity:'', unit:'', uuid:uuidv4()
        }]);
    };

    const handleCloseSnackbar = () => {
        const {actions} = props;
        actions.snackbarActions.hideSnackbar()
    };

    const consumablesObj = {...serviceConsumablesProps, service_type}

    return {state, submitted, isSubmitted, department, service_type, handleChange, handleChangeDepartment,
        handleChangeServiceType, handleResetForm, handleSubmitForm, handleCloseSnackbar,setState,
        isServiceConsumables, handleConsumablesToggle, consumablesObj}
}